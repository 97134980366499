/**
 * GoogleMapsApi関連の処理です。
 * @class googleMapsApiAction.ts
 * @constructor
 */

/**
 * 受け取った名称の住所を取得します。
 * 非同期処理の為、Promiseオブジェクトを返します。
 * @export
 */
export const getPlace = (name: string) => {
  return new Promise((resolve, reject) => {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: name }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        resolve(results);
      } else {
        reject(null);
      }
    });
  });
};
