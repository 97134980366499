import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Modal, Button } from "@material-ui/core";
import {
  selectFirstTenpoItem,
  selectSecondTenpoItem,
} from "../../features/tenpoStatusSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: "relative",
      top: "20%",
      left: "6%",
      width: "85%",
      height: "70%",
      fontSize: "42px",
      color: "white",
      backgroundColor: "red",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 100000,
      "&:focus": {
        outline: "none",
      },
    },
    button: {
      textAlign: "left",
      position: "absolute",
      bottom: "5%",
      right: "5%",
      color: "red",
      backgroundColor: "white",
    },
  })
);

const TenpoAlert: FC<{}> = () => {
  const firstTenpoItem = useSelector(selectFirstTenpoItem);
  const secondTenpoItem = useSelector(selectSecondTenpoItem);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const getAlert = () => {
    return (
      <div>
        <p>
          客数は個店情報ですので、
          <br />
          他人に見られないように表示変更してください。
        </p>
        <p>
          第３者と一緒に参照する場合は、
          <br />
          該当店のみの表示に変更してください。
        </p>
      </div>
    );
  };

  useEffect(() => {
    // 分析対象と比較対象の店舗が異なる時アラートを表示
    firstTenpoItem !== "" &&
      secondTenpoItem !== "" &&
      firstTenpoItem !== secondTenpoItem &&
      setOpen(true);
  }, [firstTenpoItem, secondTenpoItem]);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="graph-modal-title"
        aria-describedby="graph-modal-description"
      >
        <div className={classes.modal}>
          {getAlert()}
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => setOpen(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TenpoAlert;
