import axios from "axios";
import { getFirebaseAuthToken } from "../firebase";

// GETリクエスト
export const getRequest = async (
  url: string
  // params: any,
  // isLoading: boolean = true
) => {
  // try {
  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + (await getFirebaseAuthToken(false)),
    },
    // params,
  });
  // ステータス200番台のエラーハンドリング
  //   if (response.data.success !== true) {
  //     console.log("status 2XX Error");
  //     console.log(response.data.dataList);
  //     throw Error;
  //   }
  //   console.log(response.data.dataList);
  //   return response.data.dataList;
  // } catch (error) {
  //   console.log("error", error.response);
  //   throw error;
  // }
  return response.data.dataList;
};

// POSTリクエスト
export const postRequest = async (
  url: string,
  params: any,
  isLoading: boolean = true,
  isAuthed: boolean = true,
  isAuthUpdate: boolean = true
) => {
  try {
    const response = await axios.post(url, params, {
      headers: isAuthed
        ? {
            Authorization:
              "Bearer " + (await getFirebaseAuthToken(isAuthUpdate)),
              "Application-Name": "ofc-counseling",
          }
        : {},
    });
    // Todo暫定
    // ステータス200番台のエラーハンドリング
    // if (response.data.success !== true) {
    //   console.log("status 2XX Error");
    //   throw Error;
    // }
    return response;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

// PATCHリクエスト
export const patchRequest = async (
  url: string,
  params: string,
  isLoading: boolean = true
) => {
  try {
    const response = await axios.patch(url, params, {
      // headers: { Authorization: "Bearer " + (await getFirebaseAuthToken()) },
    });
    // ステータス200番台のエラーハンドリング
    if (response.data.success !== true) {
      console.log("status 2XX Error");
      throw Error;
    }
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

// DELETEリクエスト
export const deleteRequest = async (
  url: string,
  params: string,
  isLoading: boolean = true
) => {
  try {
    const response = await axios.delete(url, {
      data: params,
      // headers: { Authorization: "Bearer " + (await getFirebaseAuthToken()) },
    });
    // ステータス200番台のエラーハンドリング
    if (response.data.success !== true) {
      console.log("status 2XX Error");
      throw Error;
    }
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

/**
 * GoogleCloudStorageからトークンを用いてファイルダウンロードを行う
 *
 * @param url GoogleCloudStorageのファイル場所URL
 * @param token GoogleCloudStorageのトークン
 */
export const getGcsFile = async (gcsPath: string, token: string) => {
  try {
    const response = await axios.get(gcsPath + "?access_token=" + token);
    return response;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};
