/**
 * 共通で使用可能なデータをここに配置します
 * (SEJ店舗関係)
 *
 */

import { getGcsFile } from "../apis/axiosAction";
import { getFromGcsZip } from "../apis/depressAction";

// 既存店個店別の型
export interface EXISTING_SEJ_STORES_RECORD {
  // 各アプリケーション共通項目
  tenpo_code: string;
  tenpo_name: string;
  tenpo_address: string;
  longitude: string;
  latitude: string;
  contract_type: string;
  original_open_date: string;
}

// 既存店個店別の型のオブジェクトの集まり
export interface INTERFACE_STORE_DATA {
  [index: string]: EXISTING_SEJ_STORES_RECORD;
}

// 既存店の型
export interface INTERFACE_EXISTING_SEJ_STORES {
  store_data: Map<String, EXISTING_SEJ_STORES_RECORD>;
  count: number;
}

// 既存店の初期値
const initialExistingSejStores: INTERFACE_EXISTING_SEJ_STORES = {
  store_data: new Map<string, EXISTING_SEJ_STORES_RECORD>(),
  count: 0,
};

const existingSejStoresData = {
  // SEJ既存店舗
  existingSejStores: initialExistingSejStores,
};

// SEJ既存店舗情報の全量の情報を返す
export const getAllExistingSejStores = () => {
  return existingSejStoresData.existingSejStores;
};

// SEJ既存店舗のある1店舗のデータを返す
export const getExistingSejStore = (
  tenpoCode: string
): EXISTING_SEJ_STORES_RECORD | undefined => {
  return existingSejStoresData.existingSejStores.store_data.get(tenpoCode);
};

// ZONE別の型
export interface ZONE_RECORD {
  // 各アプリケーション共通項目
  zone_code: string;
  zone_name: string;
}

// ZONEの型のオブジェクトの集まり
export interface INTERFACE_ZONE_DATA {
  [index: string]: ZONE_RECORD;
}

// ZONEの型
export interface INTERFACE_ZONE {
  zone_data: INTERFACE_ZONE_DATA;
  count: number;
}

// DOの初期値
const initialZone: INTERFACE_ZONE = {
  zone_data: {},
  count: 0,
};

const zoneData = {
  // ZONE
  zone: initialZone,
};

// ZONE情報の全量の情報を返す
export const getAllZone = () => {
  return zoneData.zone;
};

// zoneCodeのあるZONEのデータを返す
export const getZone = (zoneCode: string): ZONE_RECORD => {
  return zoneData.zone.zone_data[zoneCode];
};

// DO別の型
export interface DO_RECORD {
  // 各アプリケーション共通項目
  do_code: string;
  do_name: string;
}

// DOの型のオブジェクトの集まり
export interface INTERFACE_DO_DATA {
  [index: string]: DO_RECORD;
}

// DOの型
export interface INTERFACE_DO {
  do_data: INTERFACE_DO_DATA;
  count: number;
}

// DOの初期値
const initialDo: INTERFACE_DO = {
  do_data: {},
  count: 0,
};

const doData = {
  // DO
  do: initialDo,
};

// DO情報の全量の情報を返す
export const getAllDo = () => {
  return doData.do;
};

// doCodeのあるDOのデータを返す
export const getDo = (doCode: string): DO_RECORD => {
  return doData.do.do_data[doCode];
};

// SEJ既存店舗全量を保存する
export const setExistingSejStores = (data: any) => {
  const recordData = []; //配列を用意
  const csvRecords = data.split(/\r\n|\r|\n/); //改行で分割
  const storeData: Map<String, EXISTING_SEJ_STORES_RECORD> = new Map();
  const zoneRecordData: any = {};
  const doRecordData: any = {};
  let count = 0;
  for (let i = 0; i < csvRecords.length; i++) {
    recordData[i] = csvRecords[i].split(",");
    if (recordData[i][0] === "") {
      continue;
    }
    const sotresRecord: EXISTING_SEJ_STORES_RECORD = {
      tenpo_code: recordData[i][0].padStart(6, "0"),
      tenpo_name: recordData[i][1],
      tenpo_address: recordData[i][2],
      longitude: recordData[i][9],
      latitude: recordData[i][10],
      contract_type: recordData[i][7],
      original_open_date: recordData[i][11],
    };
    storeData.set(recordData[i][0],sotresRecord);
    count++;
    const zoneRecord: ZONE_RECORD = {
      zone_code: recordData[i][3].padStart(3, "0"),
      zone_name: recordData[i][4],
    };
    zoneRecordData[recordData[i][3].padStart(3, "0")] = zoneRecord;
    const doRecord: DO_RECORD = {
      do_code: recordData[i][5].padStart(5, "0"),
      do_name: recordData[i][6],
    };
    doRecordData[recordData[i][5].padStart(5, "0")] = doRecord;
  }

  const result: INTERFACE_EXISTING_SEJ_STORES = {
    store_data: storeData,
    count,
  };
  existingSejStoresData.existingSejStores = result;
  //console.table(result.store_data);

  const count_zone: number = Object.keys(zoneRecordData).length;
  const zone: INTERFACE_ZONE = {
    zone_data: zoneRecordData,
    count: count_zone,
  };
  zoneData.zone = zone;
  //console.table(zone.zone_data);

  const count_do: number = Object.keys(doRecordData).length;
  const dodo: INTERFACE_DO = {
    do_data: doRecordData,
    count: count_do,
  };
  doData.do = dodo;
  //console.table(dodo.do_data);
  
};

export const initializeExistingSejStores = (token: string, func: any) => {
  getFromGcsZip(token, "/master/existing-sej-stores.zip", setExistingSejStores).then((res) => {
      if(res === "success"){
        func();
      }
    });
};
