import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";

const OneVisionDialog: React.FunctionComponent<{
  msg: string[];
  isOpen: boolean;
  doOK: any;
  doNo: any;
  isAlert: boolean;
  isClose?: boolean;
}> = ({ msg, isOpen, doOK, doNo, isAlert, isClose }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const doNoAction = () => {
    if (isClose) {
      doNo();
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => doNoAction()}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogContent>
          {msg.map((row: string) => (
            <Typography>{row}</Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => doOK()} variant={"contained"} color="primary">
            OK
          </Button>
          {isAlert ? (
            ""
          ) : (
            <Button onClick={() => doNo()} color="primary">
              キャンセル
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default OneVisionDialog;
