import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { createModalGoogleMap } from "./SelectMobakuGoogleMaps";
import MobakuMapsGeolocationButton from "./MobakuMapsGeolocationButton";
import MobakuMapsSelectedButton from "./MobakuMapsSelectedButton";
import MapsSearchBar from "../../Maps/MapComponents/MapsSearchBar";
import { Typography, Paper } from "@material-ui/core";
import CreateMarketAreaMenu from "../../OpView/MarketArea/CreateMarketAreaMenu";
import EditMarketAreaMenu from "../../OpView/MarketArea/EditMarketAreaMenu";
import SelectMobakuMapLayersButton from "./SelectMobakuMapLayersButton";
import {
  selectFirstCvrItem,
  selectSecondCvrItem,
  selectFirstMobakuItem,
  selectSecondMobakuItem,
} from "../../../features/tenpoStatusSlice";
import { selectExclusiveMobakuFlg } from "../../../features/marketAreaStatusSlice";
import {
  existingSejStoreLayerEvent,
  initMobakuExistingSejStores,
} from "../SelectMobakuMap/layers/existingSejStoreLayer";
import DeleteMarketAreaModal from "../MarketArea/DeleteMarketAreaModal";
import { initMobaku } from "./SelectMobakuMapItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ModalGoogleMaps: {
      position: "absolute",
      top: "16px",
      left: "16px",
      right: "16px",
      bottom: "16px",
      // height: "94.5%",
      // width: "94.5%",
    },
    icons: {
      position: "absolute",
      right: "25px",
      bottom: "120px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      height: "250px",
    },
    searchBar: {
      position: "absolute",
      right: "15px",
      [theme.breakpoints.up("md")]: {
        bottom: "655px",
      },
      [theme.breakpoints.up("lg")]: {
        bottom: "490px",
      },
    },
    paperL: {
      position: "absolute",
      left: "0%",
      margin: theme.spacing(1, 3, 3),
      alignItems: "center",
      backgroundColor: "rgba(255,255,255,0.8)",
    },
    paperR: {
      position: "absolute",
      right: "0%",
      margin: theme.spacing(1, 3, 3),
      alignItems: "center",
      backgroundColor: "rgba(255,255,255,0.8)",
    },
    text: {
      width: "100%",
    },
    label: {
      cursor: "pointer",
      display: "block",
    },
    checkBox: {
      cursor: "pointer",
    },
  })
);
export let isDisplaySejExistingStore: boolean = true,
  isDisplayCvrSejExistingStore: boolean = true,
  isDisplayConflictStore: boolean = true;

const SelectMobakuMaps: React.FC<{
  storeOrder: string;
  setOpen: any;
  dispatch: any;
  existMarketArea: boolean;
  graphType: string;
}> = ({ storeOrder, setOpen, dispatch, existMarketArea, graphType }) => {
  const classes = useStyles({});
  const cvrTenpoItem = useSelector(selectFirstCvrItem);
  const cvrMobakuItem = useSelector(selectSecondCvrItem);
  const firstMobakuItem = useSelector(selectFirstMobakuItem);
  const secondMobakuItem = useSelector(selectSecondMobakuItem);
  const exclusiveMobakuFlg = useSelector(selectExclusiveMobakuFlg);
  const [checkedSatellite, setCheckedSatellite] = React.useState(false);
  const [checkedMarketArea, setCheckedMarketArea] = React.useState(false);
  const [openSelectTenpo, setOpenSelectTenpo] = React.useState(false);
  const [firstInit, setFirstInit] = React.useState(true);
  const textLeft = "　表示のメッシュは500mメッシュです　";
  const textRight =
    "　選択中の店舗をクリックすると、範囲の作成/編集/選択ができます　";

  useEffect(() => {
    createModalGoogleMap(
      storeOrder,
      dispatch,
      existMarketArea,
      cvrTenpoItem,
      cvrMobakuItem,
      graphType
    );
  }, []);

  // 排他制御中はインフォウィンドウを開けなくして、モバ空マップを選択できなくする
  useEffect(() => {
    if (exclusiveMobakuFlg) {
      google.maps.event.removeListener(existingSejStoreLayerEvent);
      initMobaku(storeOrder, dispatch, graphType, exclusiveMobakuFlg);
    } else {
      setFirstInit(false);
      if (firstInit) return;
      initMobakuExistingSejStores(storeOrder, dispatch, graphType);
      initMobaku(storeOrder, dispatch, graphType, exclusiveMobakuFlg);
    }
  }, [exclusiveMobakuFlg]);

  // const handleSatelliteLayer = (flag: boolean) => {
  //   setCheckedSatellite(!flag);
  //   flag ? setRoadGoogleMap() : setHybridGoogleMap();
  // };

  return (
    <div>
      <div id="momap" className={classes.ModalGoogleMaps}></div>
      <div className={classes.paperL}>
        <Paper>
          <Typography className={classes.text}>{textLeft}</Typography>
        </Paper>
      </div>
      {graphType === "cvr" && (
        <div className={classes.paperR}>
          <Paper>
            <Typography className={classes.text}>{textRight}</Typography>
          </Paper>
        </div>
      )}
      <div className={classes.icons}>
        <MapsSearchBar graphType={graphType} isSelectMobaku={true} />
        <SelectMobakuMapLayersButton />
        <MobakuMapsGeolocationButton graphType={graphType} />
      </div>
      <MobakuMapsSelectedButton
        storeOrder={storeOrder}
        setOpen={setOpen}
        dispatch={dispatch}
        graphType={graphType}
      />
      <CreateMarketAreaMenu isMobaku={true} />
      <EditMarketAreaMenu isMobaku={true} />
      <DeleteMarketAreaModal isMobaku={false} />
    </div>
  );
};

export default SelectMobakuMaps;
