// @ts-nocheck
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectIsDeviceType } from "../../features/userSlice";
import { TableHead, TableBody, TableCell, TableRow } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CalendarWeather from "../OpView/Weather/CalendarWeather";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayOfWeek: {
      padding: "3px",
      fontSize: "15px",
      fontWeight: "bold",
      border: "none",
      borderRadius: "10%",
      cursor: "pointer",
      textAlign: "center",
      webkitUserSelect: "none",
      mozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
      "&:hover": {
        color: "white",
        background: "#ffa5008c",
      },
    },
    nextMonthDay: {
      padding: "3px",
      border: "none",
      borderRadius: "50%",
      color: "lightgray",
      cursor: "pointer",
      textAlign: "center",
      verticalAlign: "top",
      "&:hover": {
        background: "#e0e0e094",
      },
      webkitUserSelect: "none",
      mozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
    },
    prevMonthDay: {
      padding: "3px",
      border: "none",
      borderRadius: "50%",
      color: "lightgray",
      cursor: "pointer",
      textAlign: "center",
      verticalAlign: "top",
      "&:hover": {
        background: "#e0e0e094",
      },
      webkitUserSelect: "none",
      mozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
    },
    currentMonthDay: {
      padding: "3px",
      border: "none",
      borderRadius: "50%",
      textAlign: "center",
      verticalAlign: "top",
      cursor: "pointer",
      "&:hover": {
        background: "#e0e0e094",
      },
      webkitUserSelect: "none",
      mozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
    },
    invalidDay: {
      padding: "3px",
      border: "none",
      textAlign: "center",
      color: "lightgray",
      webkitUserSelect: "none",
      mozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
    },
    addCell: {
      padding: "1px",
      border: "none",
      textIndent: "-9999px",
    },
  })
);

const MultiDatePickerCalendar: FC<{
  year: number;
  month: number;
  holiday: string[];
  selectingDate: string[];
  setSelectingDate: any;
  selectingRangeDate: string[];
  mouseDownCell: any;
  mouseMoveCell: any;
  mouseUpCell: any;
  mouseDowned: boolean;
  arrangeDate: any;
  judgeInvalidDay: any;
  styleTrigger: any;
  isCancel: boolean;
  oneYearWeather: any;
}> = ({
  year,
  month,
  holiday,
  selectingDate,
  setSelectingDate,
  selectingRangeDate,
  mouseDownCell,
  mouseMoveCell,
  mouseUpCell,
  mouseDowned,
  arrangeDate,
  judgeInvalidDay,
  styleTrigger,
  isCancel,
  oneYearWeather,
}) => {
  const deviceType = useSelector(selectIsDeviceType);
  const classes = useStyles({});
  const calendar = [];
  const week = ["月", "火", "水", "木", "金", "土", "日"];
  let row;

  // カレンダーに表示する一か月分の日付を2次配列として作成
  const createCalendar = (year: number, month: number) => {
    // 日付のカウント
    let count = 0;
    // 月の最初の曜日
    let startDayOfWeek = new Date(year, month, 1).getDay();
    // 月曜始まり設定
    if (startDayOfWeek === 0) {
      startDayOfWeek = 6;
    } else {
      startDayOfWeek -= 1;
    }
    // 当月の最終日
    let currentMonthEndDate = new Date(year, month + 1, 0).getDate();
    // 前月の最終日
    let lastMonthEndDate = new Date(year, month, 0).getDate();
    // カレンダーの行数（切り上げ）
    row = Math.ceil((startDayOfWeek + currentMonthEndDate) / 7);

    for (var i = 0; i < row; i++) {
      // 週の配列を追加
      calendar.push([]);
      for (var j = 0; j < 7; j++) {
        // 1カラム単位で設定
        if (i === 0 && j < startDayOfWeek) {
          // 1行目で先月の日付を設定
          calendar[i].push(lastMonthEndDate - startDayOfWeek + j + 1);
        } else if (count >= currentMonthEndDate) {
          // 最終行で翌月の日付を設定
          count++;
          calendar[i].push(count - currentMonthEndDate);
        } else {
          count++;
          calendar[i].push(count);
        }
      }
    }
  };

  // 曜日選択
  const selectDayOfWeek = (index: number) => {
    const validDates = [];
    const paritialDayOfWeek = [];
    let arrangedDates = [];
    let hasAlldate = true;
    calendar.map((week, i) => {
      week.map(
        (day, j) =>
          j === index &&
          !judgeInvalidDay(day, i === row - 1 && day < 7, i === 0 && day > 7) &&
          !(i === 0 && day > 7) &&
          !(i === row - 1 && day < 7) &&
          validDates.push(day)
      );
    });

    validDates.forEach((date: number) => {
      paritialDayOfWeek.push(arrangeDate(date, ""));
    });
    paritialDayOfWeek.forEach((date: string) => {
      if (!selectingDate.includes(date)) {
        hasAlldate = false;
      }
    });
    if (hasAlldate) {
      arrangedDates = selectingDate.filter((val) => {
        return !paritialDayOfWeek.includes(val);
      });
    } else {
      const mergedDates = [...selectingDate, ...paritialDayOfWeek];
      arrangedDates = [...new Set(mergedDates)];
    }
    setSelectingDate(arrangedDates);
  };

  // 座標から対象の要素のクラスを取得し前月・次月・当月を判定する
  const getMonthType = (e: any) => {
    const className = document.elementFromPoint(
      e.touches[0].clientX,
      e.touches[0].clientY
    ).className;

    if (className.includes("prev")) {
      return "prev";
    } else if (className.includes("next")) {
      return "next";
    } else if (className.includes("current")) {
      return "";
    }
  };

  // 日付の選択・非選択に関するスタイルを制御
  const handleCellColor = (
    day: number,
    monthType: string,
    style: string,
    isCancel: boolean
  ) => {
    const date = arrangeDate(day, monthType);
    if (style === "color") {
      let color = "";
      if (!isCancel) {
        if (
          selectingRangeDate.current.includes(date) ||
          selectingDate.includes(date)
        ) {
          color = "white";
        } else {
          color = "";
        }
      } else {
        if (selectingDate.includes(date)) {
          color = "white";
        } else {
          color = "";
        }
      }
      return color;
    } else if (style === "bgColor") {
      let bgColor = "";
      if (!isCancel) {
        if (
          selectingRangeDate.current.includes(date) &&
          !selectingDate.includes(date)
        ) {
          bgColor = "#ffa5008c";
        } else if (selectingDate.includes(date)) {
          bgColor = "#ffa5008c";
        } else {
          bgColor = "";
        }
      } else {
        if (
          selectingRangeDate.current.includes(date) &&
          selectingDate.includes(date)
        ) {
          bgColor = "#e0e0e094";
        } else if (selectingDate.includes(date)) {
          bgColor = "#ffa5008c";
        } else {
          bgColor = "";
        }
      }
      return bgColor;
    }
  };

  // 週の行数を合わせる為に空白挿入
  const addRow = () => {
    const rowSet = <TableRow>{createBlankCell()}</TableRow>;
    if (row === 4) {
      return (
        <>
          {rowSet}
          {rowSet}
        </>
      );
    } else if (row === 5) {
      return rowSet;
    }
  };

  const createBlankCell = () => {
    let list = [];
    for (let i = 0; i < 7; i++) {
      list.push(
        <TableCell className={classes.addCell} colSpan={2} key={i}>
          0
          <CalendarWeather
            day={null}
            week={null}
            holiday={null}
            oneYearWeather={null}
            isPale={true}
            isInvalid={true}
            arrangeDate={arrangeDate}
            monthType={""}
          ></CalendarWeather>
        </TableCell>
      );
    }
    return list;
  };

  const createDayOfWeek = (day: string, index: number) => {
    let color = "gray";
    if (day === "土") {
      color = "blue";
    } else if (day === "日") {
      color = "red";
    }

    return (
      <TableCell
        key={day}
        className={classes.dayOfWeek}
        style={{ color: color }}
        onClick={() => {
          selectDayOfWeek(index);
        }}
        colSpan={2}
      >
        {day}
      </TableCell>
    );
  };

  createCalendar(year, month);

  return (
    <>
      <TableHead>
        <TableRow>
          {week.map((day: string, index: number) => {
            return createDayOfWeek(day, index);
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {calendar.map((week, i) => (
          <TableRow key={week.join("")}>
            {week.map((day, j) =>
              judgeInvalidDay(
                day,
                i === row - 1 && day < 7,
                i === 0 && day > 7
              ) ? (
                // 選択不可の日付
                <TableCell
                  className={classes.invalidDay}
                  key={`${i}${j}`}
                  arrangeDate
                  colSpan={2}
                >
                  <CalendarWeather
                    day={day}
                    week={j}
                    holiday={holiday}
                    oneYearWeather={oneYearWeather}
                    isPale={true}
                    isInvalid={true}
                    arrangeDate={arrangeDate}
                    monthType={""}
                    isNext={i === row - 1 && day < 7}
                  ></CalendarWeather>
                </TableCell>
              ) : i === 0 && day > 7 && deviceType ? (
                // 前月の日付(iPad)
                <TableCell
                  className={classes.prevMonthDay}
                  key={`${i}${j}`}
                  colSpan={2}
                  onTouchStart={() => {
                    mouseDownCell(day, "prev");
                  }}
                  onTouchMove={(e) => {
                    // onTouchMoveではonTouchStartの要素しか取得できないため、座標から最後に選択した要素を取得する
                    const dayFromEvent = Number(
                      document.elementFromPoint(
                        e.touches[0].clientX,
                        e.touches[0].clientY
                      ).textContent
                    );
                    const typeFromEvent = getMonthType(e);
                    mouseDowned && mouseMoveCell(dayFromEvent, typeFromEvent);
                  }}
                  onTouchEnd={() => {
                    mouseUpCell(day, "prev", deviceType);
                  }}
                  style={{
                    color: handleCellColor(day, "prev", "color", isCancel),
                    backgroundColor: handleCellColor(
                      day,
                      "prev",
                      "bgColor",
                      isCancel
                    ),
                  }}
                >
                  <CalendarWeather
                    day={day}
                    week={j}
                    holiday={holiday}
                    oneYearWeather={oneYearWeather}
                    isPale={true}
                    isInvalid={false}
                    arrangeDate={arrangeDate}
                    monthType={"prev"}
                  ></CalendarWeather>
                </TableCell>
              ) : i === 0 && day > 7 && !deviceType ? (
                // 前月の日付(GWPC)
                <TableCell
                  className={classes.prevMonthDay}
                  key={`${i}${j}`}
                  colSpan={2}
                  onMouseDown={() => {
                    mouseDownCell(day, "prev");
                  }}
                  onMouseMove={() => {
                    mouseDowned && mouseMoveCell(day, "prev");
                  }}
                  onMouseUp={() => {
                    mouseUpCell(day, "prev");
                  }}
                  style={{
                    color: handleCellColor(day, "prev", "color", isCancel),
                    backgroundColor: handleCellColor(
                      day,
                      "prev",
                      "bgColor",
                      isCancel
                    ),
                  }}
                >
                  <CalendarWeather
                    day={day}
                    week={j}
                    holiday={holiday}
                    oneYearWeather={oneYearWeather}
                    isPale={true}
                    isInvalid={false}
                    arrangeDate={arrangeDate}
                    monthType={"prev"}
                  ></CalendarWeather>
                </TableCell>
              ) : i === row - 1 && day < 7 && deviceType ? (
                // 次月の日付(iPad)
                <TableCell
                  className={classes.nextMonthDay}
                  key={`${i}${j}`}
                  colSpan={2}
                  onTouchStart={() => {
                    mouseDownCell(day, "next");
                  }}
                  onTouchMove={(e) => {
                    // onTouchMoveではonTouchStartの要素しか取得できないため、座標から最後に選択した要素を取得する
                    const dayFromEvent = Number(
                      document.elementFromPoint(
                        e.touches[0].clientX,
                        e.touches[0].clientY
                      ).textContent
                    );
                    const typeFromEvent = getMonthType(e);
                    mouseDowned && mouseMoveCell(dayFromEvent, typeFromEvent);
                  }}
                  onTouchEnd={(e) => {
                    mouseUpCell(day, "next", deviceType);
                  }}
                  style={{
                    color: handleCellColor(day, "next", "color", isCancel),
                    backgroundColor: handleCellColor(
                      day,
                      "next",
                      "bgColor",
                      isCancel
                    ),
                  }}
                >
                  <CalendarWeather
                    day={day}
                    week={j}
                    holiday={holiday}
                    oneYearWeather={oneYearWeather}
                    isPale={true}
                    isInvalid={false}
                    arrangeDate={arrangeDate}
                    monthType={"prev"}
                  ></CalendarWeather>
                </TableCell>
              ) : i === row - 1 && day < 7 && !deviceType ? (
                // 次月の日付(GWPC)
                <TableCell
                  className={classes.nextMonthDay}
                  key={`${i}${j}`}
                  colSpan={2}
                  onMouseDown={() => {
                    mouseDownCell(day, "next");
                  }}
                  onMouseMove={() => {
                    mouseDowned && mouseMoveCell(day, "next");
                  }}
                  onMouseUp={() => {
                    mouseUpCell(day, "next");
                  }}
                  style={{
                    color: handleCellColor(day, "next", "color", isCancel),
                    backgroundColor: handleCellColor(
                      day,
                      "next",
                      "bgColor",
                      isCancel
                    ),
                  }}
                >
                  <CalendarWeather
                    day={day}
                    week={j}
                    holiday={holiday}
                    oneYearWeather={oneYearWeather}
                    isPale={true}
                    isInvalid={false}
                    arrangeDate={arrangeDate}
                    monthType={"next"}
                  ></CalendarWeather>
                </TableCell>
              ) : deviceType ? (
                // 当月の日付(iPad)
                <TableCell
                  className={classes.currentMonthDay}
                  key={`${i}${j}`}
                  colSpan={2}
                  onTouchStart={() => {
                    mouseDownCell(day, "");
                  }}
                  onTouchMove={(e) => {
                    // onTouchMoveではonTouchStartの要素しか取得できないため、座標から最後に選択した要素を取得する
                    const dayFromEvent = Number(
                      document.elementFromPoint(
                        e.touches[0].clientX,
                        e.touches[0].clientY
                      ).textContent
                    );
                    const typeFromEvent = getMonthType(e);
                    mouseDowned && mouseMoveCell(dayFromEvent, typeFromEvent);
                  }}
                  onTouchEnd={(e) => {
                    mouseUpCell(day, "", deviceType);
                  }}
                  style={{
                    color: handleCellColor(day, "", "color", isCancel),
                    backgroundColor: handleCellColor(
                      day,
                      "",
                      "bgColor",
                      isCancel
                    ),
                  }}
                >
                  <CalendarWeather
                    day={day}
                    week={j}
                    holiday={holiday}
                    oneYearWeather={oneYearWeather}
                    isPale={false}
                    isInvalid={false}
                    arrangeDate={arrangeDate}
                    monthType={""}
                  ></CalendarWeather>
                </TableCell>
              ) : (
                // 当月の日付(GWPC)
                <TableCell
                  className={classes.currentMonthDay}
                  key={`${i}${j}`}
                  colSpan={2}
                  onMouseDown={() => {
                    mouseDownCell(day, "");
                  }}
                  onMouseMove={() => {
                    mouseDowned && mouseMoveCell(day, "");
                  }}
                  onMouseUp={() => {
                    mouseUpCell(day, "");
                  }}
                  style={{
                    color: handleCellColor(day, "", "color", isCancel),
                    backgroundColor: handleCellColor(
                      day,
                      "",
                      "bgColor",
                      isCancel
                    ),
                  }}
                >
                  <CalendarWeather
                    day={day}
                    week={j}
                    holiday={holiday}
                    oneYearWeather={oneYearWeather}
                    isPale={false}
                    isInvalid={false}
                    arrangeDate={arrangeDate}
                    monthType={""}
                  ></CalendarWeather>
                </TableCell>
              )
            )}
          </TableRow>
        ))}
        {addRow()}
      </TableBody>
    </>
  );
};

export default MultiDatePickerCalendar;
