import React, { useEffect, FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { DateTime } from "luxon";
import { CircularProgress, Select, MenuItem } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { postRequest } from "../../../apis/axiosAction";
import TSCheckDialog from "../TSCheckDialog";
import { selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";
import TSCheckManagerAnalysisTable from "./TSCheckManagerAnalysisTable";
import DatePicker from "../../common/DatePicker";
import { zonedTimeToUtc } from "date-fns-tz";
import { userLogging } from "../../../apis/userLog";
import TSCheckStoreScoreDataButton from "./TSCheckStoreScoreDataButton";
import { isDownloadPointData } from "../../../apis/privilege";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "70vh",
    width: "90vw",
    margin: "auto",
    // iPad縦
    "@media (min-width: 830px)": {
      height: "65vh",
    },
    // iPad横
    "@media (min-width: 1100px)": {
      height: "70vh",
    },
    // GWPC
    "@media (min-width: 1300px)": {
      height: "70vh",
    },
  },
  graphTable: {
    maxWidth: "80vw",
    backgroundColor: "#ffffff",
    border: "none",
    marginTop: "10px",
    marginBottom: "5px",
  },
  overLayer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    backgroundColor: "#CCC",
    opacity: "0.5",
    zIndex: 100000,
  },
  circularProgress: {
    position: "absolute",
    top: "35%",
    left: "45%",
    opacity: "0.8",
    color: "#ccc",
    zIndex: 99999,
  },
}));

// グラフの色リスト
const fillCodeList = [
  "#8884d8",
  "#82ca9d",
  "skyblue",
  "coral",
  "peru",
  "plum",
  "gold",
  "pink",
  "blue",
  "red",
];
const tableHeaderList2 = [
  "個人衛生",
  "ＦＦ原材料 管理",
  "販売管理",
  "温度管理",
  "商品鮮度管理",
  "表示関連 産地表示 アレルゲン表示",
  "設備・ 備品管理 クリンリネス",
  "衛生管理",
  "商品・売場・地区政策等",
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// 実行結果
const AnalysisResult: FC<{
  zoCode: string;
  doCode: string;
  baseData: DateTime | null;
  update: boolean;
  analysis: number;
}> = ({ zoCode, doCode, baseData, update, analysis }) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [tabValue, setTabValue] = React.useState(0);
  const [indexTabValue, setIndexTabValue] = React.useState(0);

  const [targetData, setTargetData] = React.useState<any[]>();
  const [targetGraphData, setTargetGraphData] = React.useState<any[]>();
  const [targetData0, setTargetData0] = React.useState<any[]>([]);
  const [targetData1, setTargetData1] = React.useState<any[]>([]);
  const [targetData2, setTargetData2] = React.useState<any[]>([]);
  const [targetData3, setTargetData3] = React.useState<any[]>([]);
  const [targetData4, setTargetData4] = React.useState<any[]>([]);
  const [targetData5, setTargetData5] = React.useState<any[]>([]);
  const [targetData6, setTargetData6] = React.useState<any[]>([]);
  const [targetData7, setTargetData7] = React.useState<any[]>([]);
  const [targetData8, setTargetData8] = React.useState<any[]>([]);
  const [targetData9, setTargetData9] = React.useState<any[]>([]);

  const [targetAverageData, setTargetAverageData] = React.useState<any>({});
  const [targetAverageData0, setTargetAverageData0] = React.useState<any>({});
  const [targetAverageData1, setTargetAverageData1] = React.useState<any>({});
  const [targetAverageData2, setTargetAverageData2] = React.useState<any>({});
  const [targetAverageData3, setTargetAverageData3] = React.useState<any>({});
  const [targetAverageData4, setTargetAverageData4] = React.useState<any>({});
  const [targetAverageData5, setTargetAverageData5] = React.useState<any>({});
  const [targetAverageData6, setTargetAverageData6] = React.useState<any>({});
  const [targetAverageData7, setTargetAverageData7] = React.useState<any>({});
  const [targetAverageData8, setTargetAverageData8] = React.useState<any>({});
  const [targetAverageData9, setTargetAverageData9] = React.useState<any>({});

  const [graphUserList, setGraphUserList] = React.useState<any[]>([]);

  const [workZoCode, setWorkZoCode] = React.useState(zoCode);
  const [workDoCode, setWorkDoCode] = React.useState(doCode);
  const [ofcId, setOfcId] = React.useState("");
  const [tenpoCode, setTenpoCode] = React.useState("");
  const [executeFlg, setExecuteFlg] = React.useState("");
  const [initFlg, setInigFlg] = React.useState(true);
  const [updateFlg, setUpdateFlg] = React.useState(true);
  const [workBaseData, setWorkBaseData] = React.useState(baseData);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [zoTabDisableFlg, setZoTabDisableFlg] = React.useState(true);
  const [doTabDisableFlg, setDoTabDisableFlg] = React.useState(true);
  const [ofcTabDisableFlg, setOfcTabDisableFlg] = React.useState(true);
  const [kobetuTabDisableFlg, setKobetuTabDisableFlg] = React.useState(true);

  const [tableHeaderList, setTableHeaderList] = React.useState<any[]>([]);
  const [headerName, setHeaderName] = React.useState("");

  const [tableList, setTableList] = React.useState<any[]>([
    { id: 1, name: "総合" },
  ]);

  const [selectedTable, setSelectedTable] = React.useState("1");
  const [selectedBaseDate, setSelectedBaseDate] =
    React.useState<DateTime | null>(null);
  const [baseMonth, setBaseMonth] = React.useState<string>("");

  // データ取得用useEffect
  useEffect(() => {
    changeTitles(tabValue);
    if (initFlg) {
      userLogging("店舗衛生点検表", "管理者分析#初期表示", "");
      setInigFlg(false);
      // admin-groupの場合のみ初回読み込み時に全検索を行う
      if (user.jobCategory !== "admin-group") {
        if (user.jobCategory === "zm") {
          changeTitles(1);
        }
        if (user.jobCategory === "dm") {
          changeTitles(2);
        }
        return;
      }
    }
    userLogging(
      "店舗衛生点検表",
      "管理者分析#データ取得条件",
      "タブ名[" +
        headerName +
        "別分析] ZO[" +
        workZoCode +
        "] DO[" +
        workDoCode +
        "] データ[" +
        workBaseData +
        "] OFC[" +
        ofcId +
        "] 店舗[" +
        tenpoCode +
        "]"
    );
    getTableData(
      String(tabValue + 1),
      workZoCode,
      workDoCode,
      workBaseData || DateTime.local(),
      ofcId,
      tenpoCode
    ).then((res: any) => {
      changeTitles(tabValue);
      let responseMap: any = res;
      userLogging(
        "店舗衛生点検表",
        "管理者分析#データ取得結果",
        JSON.stringify(responseMap)
      );
      if (responseMap) {
        setTargetData(responseMap.dataList);
      }
      updateDisableTab();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, updateFlg]);

  // 実行ボタン押下時用useEffect
  useEffect(() => {
    setTabValue(analysis);
    setIndexTabValue(analysis);
    if (user.jobCategory === "zm") {
      setIndexTabValue(analysis - 1);
    }
    if (user.jobCategory === "dm") {
      setIndexTabValue(analysis - 2);
    }
    if (zoCode !== "") {
      setWorkZoCode(zoCode);
    }
    if (doCode !== "") {
      setWorkDoCode(doCode);
    }
    // setOfcId("");
    // setTenpoCode("");
    setUpdateFlg(update);
    setWorkBaseData(baseData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  // 表示するヘッダ名の変更
  const changeTitles = (num: number) => {
    if (num === 0) {
      setHeaderName("ZO");
    }
    if (num === 1) {
      setHeaderName("DO");
    }
    if (num === 2) {
      setHeaderName("OFC");
    }
    if (num === 3) {
      setHeaderName("個店");
    }
  };

  //  タブのクリック処理
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setIndexTabValue(newValue);
    if (user.jobCategory === "zm") {
      newValue = newValue + 1;
    }
    if (user.jobCategory === "dm") {
      newValue = newValue + 2;
    }

    changeTitles(newValue);
    setTabValue(newValue);
  };

  const dialogClose = () => {
    setDialogFlg(false);
  };

  // タブの活性・非活性更新
  function updateDisableTab() {
    if (user.jobCategory === "admin-group") {
      setZoTabDisableFlg(false);
    }
    setDoTabDisableFlg(workZoCode ? false : true);
    setOfcTabDisableFlg(workDoCode ? false : true);
    setKobetuTabDisableFlg(ofcId ? false : true);
  }

  // 平均点計算
  function calcAverage(num: any, count: any) {
    if (!num) {
      return 0;
    }
    return Math.round(parseFloat(num) / parseFloat(count));
  }
  // 差分計算
  function calcDiff(num1: any, num2: any) {
    let mainScore = Number(num1) || 0;
    let diffScore = Number(num2) || 0;
    let result = 0;
    result = Math.round(mainScore) - Math.round(diffScore);
    result = Math.round(result);

    return result;
  }

  // 総数計算の対象判断
  const countTotalTarget = (score: any) => {
    let value: number = Number(score);
    if (value === 0) {
      return 0;
    } else {
      return 1;
    }
  };
  // 小数点以下を四捨五入
  const roundOffDecimal = (num: any) => {
    let value: number = Number(num);
    if (value) {
      return Math.round(value);
    }
    return 0;
  };

  // コード順に並べ変え
  function sortCode(dataMapList: any[]) {
    let mapList: any[] = dataMapList;

    if (tabValue === 2) {
      return mapList.sort(function (first, second) {
        if (first.sortDoCode > second.sortDoCode) {
          return 1;
        } else if (first.sortDoCode < second.sortDoCode) {
          return -1;
        } else {
          if (first.sortOfcCode > second.sortOfcCode) {
            return 1;
          } else if (first.sortOfcCode < second.sortOfcCode) {
            return -1;
          } else {
            return 0;
          }
        }
      });
    } else {
      return mapList.sort(function (first, second) {
        if (first.code > second.code) {
          return 1;
        } else if (first.code < second.code) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }

  // データ取得(API)
  const getData = (
    analysis: string,
    zoCode: string,
    doCode: string,
    target_date: DateTime,
    ofc_id: string,
    tenpo_code: string
  ) => {
    const apiParams = {
      "api-name": "analysis",
      target_date: target_date.toFormat("yyyy/MM/dd HH:mm:ss"),
      zo_code: zoCode,
      do_code: doCode,
      ofc_id: ofc_id,
      tenpo_code: tenpo_code,
      analysis: analysis,
    };

    let selectList: any[] = [];
    selectList.push({ id: 1, name: "総合" });
    for (let i = 0; i < tableHeaderList2.length; i++) {
      selectList.push({ id: i + 2, name: tableHeaderList2[i] });
    }
    setTableList(selectList);
    setExecuteFlg("1");
    const url = process.env.REACT_APP_GAE_API_URL + "tscheck/analysis";
    return postRequest(url, apiParams)
      .then((res) => {
        let dataList: any[] = res.data.dataList;
        setSelectedBaseDate(null);
        setBaseMonth("");
        if (dataList) {
          // 画面入力初期化
          setGraphUserList([]);
          setSelectedTable("1");
          for (let i = 0; i < dataList.length; i++) {
            let datajsList: any = dataList[i];
            let averageScoreBreakdown: any[] =
              datajsList.average_score_breakdown;

            let monthValue: any = datajsList.month_value[0].month;
            let headerList = [];
            // 合計値用インターフェイス
            interface totalMap {
              "1w": number;
              "2w": number;
              "3w": number;
              "4w": number;
              "5w": number;
              "6w": number;
            }
            const initTotalMap = () => {
              let map: totalMap = {
                "1w": 0,
                "2w": 0,
                "3w": 0,
                "4w": 0,
                "5w": 0,
                "6w": 0,
              };
              return map;
            };
            // 合計値加算
            const addTotals = (
              map: totalMap,
              num1: any,
              num2: any,
              num3: any,
              num4: any,
              num5: any,
              num6?: any
            ) => {
              map["1w"] += roundOffDecimal(num1);
              map["2w"] += roundOffDecimal(num2);
              map["3w"] += roundOffDecimal(num3);
              map["4w"] += roundOffDecimal(num4);
              map["5w"] += roundOffDecimal(num5);
              // 0week
              map["6w"] += roundOffDecimal(num6);
            };
            // 地区平均の作成
            const makeAverage = (
              totalScore: totalMap,
              countList: number[],
              headerList: any[]
            ) => {
              let averageMap: any = {
                name: "地区平均／" + averageTitle + "平均",
                code: "average",
              };
              // totalには5～1が逆に入っている 6は別
              let average1 = calcAverage(totalScore["1w"], countList[0]);
              let average2 = calcAverage(totalScore["2w"], countList[1]);
              let average3 = calcAverage(totalScore["3w"], countList[2]);
              let average4 = calcAverage(totalScore["4w"], countList[3]);
              let average5 = calcAverage(totalScore["5w"], countList[4]);
              let average6 = calcAverage(totalScore["6w"], countList[5]);
              averageMap[headerList[0]] = average1;
              averageMap[headerList[1]] = average2;
              averageMap[headerList[2]] = average3;
              averageMap[headerList[3]] = average4;
              averageMap[headerList[4]] = average5;
              averageMap["1wDiff"] = calcDiff(average6, average1);
              averageMap["2wDiff"] = calcDiff(average2, average1);
              averageMap["3wDiff"] = calcDiff(average3, average2);
              averageMap["4wDiff"] = calcDiff(average4, average3);
              averageMap["5wDiff"] = calcDiff(average5, average4);
              return averageMap;
            };

            const dataMapCreate = (
              dataMap: any,
              headerList: any[],
              no: number,
              resultMap: any
            ) => {
              let map: any = {
                name: dataMap.name,
                code: dataMap.code,
                sortDoCode: dataMap.sortDoCode,
                sortOfcCode: dataMap.sortOfcCode,
              };
              map[headerList[4]] = roundOffDecimal(
                resultMap.result1["score" + no]
              );
              map[headerList[3]] = roundOffDecimal(
                resultMap.result2["score" + no]
              );
              map[headerList[2]] = roundOffDecimal(
                resultMap.result3["score" + no]
              );
              map[headerList[1]] = roundOffDecimal(
                resultMap.result4["score" + no]
              );
              map[headerList[0]] = roundOffDecimal(
                resultMap.result5["score" + no]
              );
              map["5wDiff"] = calcDiff(
                resultMap.result1["score" + no],
                resultMap.result2["score" + no]
              );
              map["4wDiff"] = calcDiff(
                resultMap.result2["score" + no],
                resultMap.result3["score" + no]
              );
              map["3wDiff"] = calcDiff(
                resultMap.result3["score" + no],
                resultMap.result4["score" + no]
              );
              map["2wDiff"] = calcDiff(
                resultMap.result4["score" + no],
                resultMap.result5["score" + no]
              );
              map["1wDiff"] = calcDiff(
                resultMap.result5["score" + no],
                resultMap.result6["score" + no]
              );
              return map;
            };

            // 週名称の設定
            if (monthValue) {
              headerList.push(monthValue.month5);
              headerList.push(monthValue.month4);
              headerList.push(monthValue.month3);
              headerList.push(monthValue.month2);
              headerList.push(monthValue.month1);

              setTableHeaderList(headerList);
            }
            // 統合の設定
            let averageTitle = "";
            if (tabValue === 0) {
              averageTitle = "ZO";
            } else if (tabValue === 1) {
              averageTitle = "DO";
            } else if (tabValue === 2) {
              averageTitle = "OFC";
            } else if (tabValue === 3) {
              averageTitle = "個別";
            }
            let week1Count = 0;
            let week2Count = 0;
            let week3Count = 0;
            let week4Count = 0;
            let week5Count = 0;
            let week6Count = 0;

            // 項目別の設定
            let scoreList0: any[] = [];
            let scoreList1: any[] = [];
            let scoreList2: any[] = [];
            let scoreList3: any[] = [];
            let scoreList4: any[] = [];
            let scoreList5: any[] = [];
            let scoreList6: any[] = [];
            let scoreList7: any[] = [];
            let scoreList8: any[] = [];
            let scoreList9: any[] = [];
            let totalScore0: totalMap = initTotalMap();
            let totalScore1: totalMap = initTotalMap();
            let totalScore2: totalMap = initTotalMap();
            let totalScore3: totalMap = initTotalMap();
            let totalScore4: totalMap = initTotalMap();
            let totalScore5: totalMap = initTotalMap();
            let totalScore6: totalMap = initTotalMap();
            let totalScore7: totalMap = initTotalMap();
            let totalScore8: totalMap = initTotalMap();
            let totalScore9: totalMap = initTotalMap();

            for (let j = 0; j < averageScoreBreakdown.length; j++) {
              let name = averageScoreBreakdown[j].codeName.name;
              let code = averageScoreBreakdown[j].codeName.code;
              let sortDoCode = averageScoreBreakdown[j].codeName.sort_docode;
              let sortOfcCode = averageScoreBreakdown[j].codeName.sort_ofc_code;

              let result1 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result1
                  ? averageScoreBreakdown[j].result1
                  : "";
              let result2 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result2
                  ? averageScoreBreakdown[j].result2
                  : "";
              let result3 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result3
                  ? averageScoreBreakdown[j].result3
                  : "";
              let result4 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result4
                  ? averageScoreBreakdown[j].result4
                  : "";
              let result5 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result5
                  ? averageScoreBreakdown[j].result5
                  : "";
              let result6 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result6
                  ? averageScoreBreakdown[j].result6
                  : "";
              let map: any = { name, code, sortDoCode, sortOfcCode };
              let resultMap = {
                result1,
                result2,
                result3,
                result4,
                result5,
                result6,
              };

              week1Count += countTotalTarget(result5.recordCount1);
              week2Count += countTotalTarget(result4.recordCount1);
              week3Count += countTotalTarget(result3.recordCount1);
              week4Count += countTotalTarget(result2.recordCount1);
              week5Count += countTotalTarget(result1.recordCount1);
              week6Count += countTotalTarget(result6.recordCount1);
              
              addTotals(
                totalScore0,
                result5.score0,
                result4.score0,
                result3.score0,
                result2.score0,
                result1.score0
              );
              addTotals(
                totalScore1,
                result5.score1,
                result4.score1,
                result3.score1,
                result2.score1,
                result1.score1
              );
              addTotals(
                totalScore2,
                result5.score2,
                result4.score2,
                result3.score2,
                result2.score2,
                result1.score2
              );
              addTotals(
                totalScore3,
                result5.score3,
                result4.score3,
                result3.score3,
                result2.score3,
                result1.score3
              );
              addTotals(
                totalScore4,
                result5.score4,
                result4.score4,
                result3.score4,
                result2.score4,
                result1.score4
              );
              addTotals(
                totalScore5,
                result5.score5,
                result4.score5,
                result3.score5,
                result2.score5,
                result1.score5
              );
              addTotals(
                totalScore6,
                result5.score6,
                result4.score6,
                result3.score6,
                result2.score6,
                result1.score6
              );
              addTotals(
                totalScore7,
                result5.score7,
                result4.score7,
                result3.score7,
                result2.score7,
                result1.score7
              );
              addTotals(
                totalScore8,
                result5.score8,
                result4.score8,
                result3.score8,
                result2.score8,
                result1.score8
              );
              addTotals(
                totalScore9,
                result5.score9,
                result4.score9,
                result3.score9,
                result2.score9,
                result1.score9
              );

              scoreList0.push(dataMapCreate(map, headerList, 0, resultMap));
              scoreList1.push(dataMapCreate(map, headerList, 1, resultMap));
              scoreList2.push(dataMapCreate(map, headerList, 2, resultMap));
              scoreList3.push(dataMapCreate(map, headerList, 3, resultMap));
              scoreList4.push(dataMapCreate(map, headerList, 4, resultMap));
              scoreList5.push(dataMapCreate(map, headerList, 5, resultMap));
              scoreList6.push(dataMapCreate(map, headerList, 6, resultMap));
              scoreList7.push(dataMapCreate(map, headerList, 7, resultMap));
              scoreList8.push(dataMapCreate(map, headerList, 8, resultMap));
              scoreList9.push(dataMapCreate(map, headerList, 9, resultMap));
            }

            // 統合の地区平均
            let countList: any[] = [];
            countList.push(week1Count);
            countList.push(week2Count);
            countList.push(week3Count);
            countList.push(week4Count);
            countList.push(week5Count);
            countList.push(week6Count);
            let averageMap = makeAverage(totalScore0, countList, headerList);
            setTargetAverageData0(averageMap);
            setTargetAverageData(averageMap);
            
            setTargetAverageData1(
              makeAverage(totalScore1, countList, headerList)
            );
            setTargetAverageData2(
              makeAverage(totalScore2, countList, headerList)
            );
            setTargetAverageData3(
              makeAverage(totalScore3, countList, headerList)
            );
            setTargetAverageData4(
              makeAverage(totalScore4, countList, headerList)
            );
            setTargetAverageData5(
              makeAverage(totalScore5, countList, headerList)
            );
            setTargetAverageData6(
              makeAverage(totalScore6, countList, headerList)
            );
            setTargetAverageData7(
              makeAverage(totalScore7, countList, headerList)
            );
            setTargetAverageData8(
              makeAverage(totalScore8, countList, headerList)
            );
            setTargetAverageData9(
              makeAverage(totalScore9, countList, headerList)
            );

            setTargetData0(sortCode(scoreList0));
            setTargetData1(sortCode(scoreList1));
            setTargetData2(sortCode(scoreList2));
            setTargetData3(sortCode(scoreList3));
            setTargetData4(sortCode(scoreList4));
            setTargetData5(sortCode(scoreList5));
            setTargetData6(sortCode(scoreList6));
            setTargetData7(sortCode(scoreList7));
            setTargetData8(sortCode(scoreList8));
            setTargetData9(sortCode(scoreList9));

            return {
              weekList: scoreList0,
              headerList,
              averageMap,
            };
          }
        }
      })
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
      })
      .finally(() => {
        setExecuteFlg("");
      });
  };

  // グラフ用の値を補足
  const getTableData = async (
    analysis: string,
    zoCode: string,
    doCode: string,
    target_date: DateTime,
    ofc_id: string,
    tenpo_code: string
  ) => {
    let resultMap: any = {};
    let result = await getData(
      analysis,
      zoCode,
      doCode,
      target_date,
      ofc_id,
      tenpo_code
    );
    let dataList: any[] = [];
    if (result) {
      resultMap = result;
      dataList = result.weekList;
      let headerList = result.headerList;
      let averageMap = result.averageMap;
      changeGraphData(dataList, headerList, averageMap);
    }
    resultMap["dataList"] = dataList;

    return resultMap;
  };

  // グラフの設定
  function changeGraphData(
    dataList: any[],
    headerList: any[],
    averageMap: any
  ) {
    let weekMap_1: any = {};
    let weekMap_2: any = {};
    let weekMap_3: any = {};
    let weekMap_4: any = {};
    let weekMap_5: any = {};
    let graphDataList = [];

    if (dataList) {
      for (let i = 0; i < dataList.length; i++) {
        weekMap_1[dataList[i].name] = dataList[i][headerList[0]];
        weekMap_2[dataList[i].name] = dataList[i][headerList[1]];
        weekMap_3[dataList[i].name] = dataList[i][headerList[2]];
        weekMap_4[dataList[i].name] = dataList[i][headerList[3]];
        weekMap_5[dataList[i].name] = dataList[i][headerList[4]];
      }
      if (averageMap) {
        weekMap_1[averageMap.name] = averageMap[headerList[0]];
        weekMap_2[averageMap.name] = averageMap[headerList[1]];
        weekMap_3[averageMap.name] = averageMap[headerList[2]];
        weekMap_4[averageMap.name] = averageMap[headerList[3]];
        weekMap_5[averageMap.name] = averageMap[headerList[4]];
      }
    }

    for (let j = 0; j < headerList.length; j++) {
      if (j === 0) weekMap_1["week"] = headerList[j];
      if (j === 1) weekMap_2["week"] = headerList[j];
      if (j === 2) weekMap_3["week"] = headerList[j];
      if (j === 3) weekMap_4["week"] = headerList[j];
      if (j === 4) weekMap_5["week"] = headerList[j];
    }
    graphDataList.push(weekMap_1);
    graphDataList.push(weekMap_2);
    graphDataList.push(weekMap_3);
    graphDataList.push(weekMap_4);
    graphDataList.push(weekMap_5);

    setTargetGraphData(graphDataList);
  }

  // ZO名、DO名、OFC名のクリック
  const moveClick = (code: string) => {
    let minusIndex = 0;
    if (user.jobCategory === "zm") {
      minusIndex = 1;
    }
    if (user.jobCategory === "dm") {
      minusIndex = 2;
    }
    if (tabValue === 0) {
      setWorkZoCode(code);
      // 下層タブのクリア
      setWorkDoCode("");
      setOfcId("");
      setTenpoCode("");
      setIndexTabValue(1 - minusIndex);
      setTabValue(1);
    }
    if (tabValue === 1) {
      setWorkDoCode("00" + code);
      setOfcId("");
      setTenpoCode("");
      setIndexTabValue(2 - minusIndex);
      setTabValue(2);
    }
    if (tabValue === 2) {
      setOfcId(code);
      setTenpoCode("");
      setIndexTabValue(3 - minusIndex);
      setTabValue(3);
    }
    if (tabValue === 3) {
      setTenpoCode(code);
      setIndexTabValue(4 - minusIndex);
      setTabValue(4);
    }
  };

  // 選択ボタンのクリック
  const selectClick = (code: string, name: string) => {
    let addFlg = true;
    let userList = [];
    let cnt = 0;
    for (let i = 0; i < graphUserList.length; i++) {
      cnt += 1;
      if (code === graphUserList[i].code) {
        addFlg = false;
        continue;
      }
      userList.push(graphUserList[i]);
    }
    let colorNumber = cnt % 10;
    if (addFlg) {
      userList.push({ code, name, fill: fillCodeList[colorNumber] });
    }
    setGraphUserList(userList);
  };

  //　表示区分のセレクトボックス変更時の処理
  const doSelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let val: string = String(event.target.value);
    setSelectedTable(val);
    if (val === "1") {
      setTargetAverageData(targetAverageData0);
      setTargetData(targetData0);
      changeGraphData(targetData0, tableHeaderList, targetAverageData0);
    }
    if (val === "2") {
      setTargetAverageData(targetAverageData1);
      setTargetData(targetData1);
      changeGraphData(targetData1, tableHeaderList, targetAverageData1);
    }
    if (val === "3") {
      setTargetAverageData(targetAverageData2);
      setTargetData(targetData2);
      changeGraphData(targetData2, tableHeaderList, targetAverageData2);
    }
    if (val === "4") {
      setTargetAverageData(targetAverageData3);
      setTargetData(targetData3);
      changeGraphData(targetData3, tableHeaderList, targetAverageData3);
    }
    if (val === "5") {
      setTargetAverageData(targetAverageData4);
      setTargetData(targetData4);
      changeGraphData(targetData4, tableHeaderList, targetAverageData4);
    }
    if (val === "6") {
      setTargetAverageData(targetAverageData5);
      setTargetData(targetData5);
      changeGraphData(targetData5, tableHeaderList, targetAverageData5);
    }
    if (val === "7") {
      setTargetAverageData(targetAverageData6);
      setTargetData(targetData6);
      changeGraphData(targetData6, tableHeaderList, targetAverageData6);
    }
    if (val === "8") {
      setTargetAverageData(targetAverageData7);
      setTargetData(targetData7);
      changeGraphData(targetData7, tableHeaderList, targetAverageData7);
    }
    if (val === "9") {
      setTargetAverageData(targetAverageData8);
      setTargetData(targetData8);
      changeGraphData(targetData8, tableHeaderList, targetAverageData8);
    }
    if (val === "10") {
      setTargetAverageData(targetAverageData9);
      setTargetData(targetData9);
      changeGraphData(targetData9, tableHeaderList, targetAverageData9);
    }

    setTabValue(tabValue);
  };

  // 基準週の取得
  function getFirstDayOfWeek(target_date: string, addDate: number) {
    // 日付取得
    let today = zonedTimeToUtc(target_date, "Asia/Tokyo");
    let this_year = today.getFullYear();
    let this_month = today.getMonth();
    let date = today.getDate();
    let day_num = today.getDay();
    // Sunday - Saturday : 0 - 6
    if (day_num === 0) {
      day_num = 7;
    }
    let this_monday = date - day_num + 1 + addDate;

    //月曜日の年月日
    let firstDayOfWeek = new Date(this_year, this_month, this_monday);
    let month = firstDayOfWeek.getMonth() + 1;
    let year = firstDayOfWeek.getFullYear();
    if (!month || !year) {
      return "";
    }
    //文字列を作成
    return year + "年" + month + "月";
  }

  // 基準日の選択
  const handleBaseDate = (date: DateTime | null) => {
    if (date) {
      let baseMonth = getFirstDayOfWeek(date.toFormat("yyyyMMdd"), 0);
      setBaseMonth(baseMonth);
      updateBaseDate(date);
    } else {
      setSelectedTable("1");
      updataBaseDataInit();
      setBaseMonth("");
    }
    setSelectedBaseDate(date);
    return date;
  };

  // 基準日を空白にした際の初期化処理
  function updataBaseDataInit() {
    const resetBase = (dataList: any[]) => {
      let data: any[] = [];
      for (let i = 0; i < dataList.length; i++) {
        dataList[i]["baseMonthScore"] = "";
        dataList[i]["1wDiffBase"] = "";
        dataList[i]["2wDiffBase"] = "";
        dataList[i]["3wDiffBase"] = "";
        dataList[i]["4wDiffBase"] = "";
        dataList[i]["5wDiffBase"] = "";
        data.push(dataList[i]);
      }
      return data;
    };

    const resetAverage = (dataMap: any) => {
      let averageMap = dataMap;
      averageMap["baseMonthScore"] = "";
      averageMap["1wDiffBase"] = "";
      averageMap["2wDiffBase"] = "";
      averageMap["3wDiffBase"] = "";
      averageMap["4wDiffBase"] = "";
      averageMap["5wDiffBase"] = "";
      return dataMap;
    };
    setTargetData0(resetBase(targetData0));
    setTargetData1(resetBase(targetData1));
    setTargetData2(resetBase(targetData2));
    setTargetData3(resetBase(targetData3));
    setTargetData4(resetBase(targetData4));
    setTargetData5(resetBase(targetData5));
    setTargetData6(resetBase(targetData6));
    setTargetData7(resetBase(targetData7));
    setTargetData8(resetBase(targetData8));
    setTargetData9(resetBase(targetData9));

    setTargetAverageData0(resetAverage(targetAverageData0));
    setTargetAverageData1(resetAverage(targetAverageData1));
    setTargetAverageData2(resetAverage(targetAverageData2));
    setTargetAverageData3(resetAverage(targetAverageData3));
    setTargetAverageData4(resetAverage(targetAverageData4));
    setTargetAverageData5(resetAverage(targetAverageData5));
    setTargetAverageData6(resetAverage(targetAverageData6));
    setTargetAverageData7(resetAverage(targetAverageData7));
    setTargetAverageData8(resetAverage(targetAverageData8));
    setTargetAverageData9(resetAverage(targetAverageData9));
  }

  // 基準週による比較値更新
  function updateBaseDate(baseDate: DateTime) {
    userLogging("店舗衛生点検表", "管理者分析#基準日選択", baseDate.toString());
    const apiParams = {
      "api-name": "analysis",
      target_date: baseDate.toFormat("yyyy/MM/dd HH:mm:ss"),
      zo_code: workZoCode,
      do_code: workDoCode,
      ofc_id: ofcId,
      tenpo_code: tenpoCode,
      analysis: String(tabValue + 1),
      is_onemonth: true,
    };
    setExecuteFlg("2");
    const url = process.env.REACT_APP_GAE_API_URL + "tscheck/analysis";
    return postRequest(url, apiParams)
      .then((res) => {
        setSelectedTable("1");
        const breakDownDataMap = (data: any[], code: any, score: number) => {
          let dataMap: any = {};
          for (let i = 0; i < data.length; i++) {
            if (code === data[i].code) {
              dataMap = data[i];
            }
          }
          dataMap["baseMonthScore"] = roundOffDecimal(score);
          return updateDiff(score, dataMap, tableHeaderList);
        };

        const updateDiff = (
          baseScore: number,
          targetMap: any,
          tableHeaderList: any[]
        ) => {
          let dataMap: any = targetMap;

          dataMap["1wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[0]],
            baseScore
          );
          dataMap["2wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[1]],
            baseScore
          );
          dataMap["3wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[2]],
            baseScore
          );
          dataMap["4wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[3]],
            baseScore
          );
          dataMap["5wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[4]],
            baseScore
          );
          return dataMap;
        };

        const getUpdateAverage = (
          data: any,
          totalScore: number | string,
          count: number
        ) => {
          let averageMap = data;
          averageMap["baseMonthScore"] = calcAverage(totalScore, count);
          updateDiff(calcAverage(totalScore, count), averageMap, tableHeaderList)
          return averageMap;
        };

        // main処理
        let dataList: any[] = res.data.dataList;
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let datajsList: any = dataList[i];
            let breakdownScoreList: any[] = datajsList.average_score_breakdown;

            let weekList: any[] = [];
            let week1Count = 0;
            weekList = sortCode(weekList);
            setTargetData0(weekList);
            setTargetData(weekList);

            let breakDownList_0: any[] = [];
            let breakDownList_1: any[] = [];
            let breakDownList_2: any[] = [];
            let breakDownList_3: any[] = [];
            let breakDownList_4: any[] = [];
            let breakDownList_5: any[] = [];
            let breakDownList_6: any[] = [];
            let breakDownList_7: any[] = [];
            let breakDownList_8: any[] = [];
            let breakDownList_9: any[] = [];
            let breakDownData_0 = targetData0;
            let breakDownData_1 = targetData1;
            let breakDownData_2 = targetData2;
            let breakDownData_3 = targetData3;
            let breakDownData_4 = targetData4;
            let breakDownData_5 = targetData5;
            let breakDownData_6 = targetData6;
            let breakDownData_7 = targetData7;
            let breakDownData_8 = targetData8;
            let breakDownData_9 = targetData9;
            let totalScore0 = 0;
            let totalScore1 = 0;
            let totalScore2 = 0;
            let totalScore3 = 0;
            let totalScore4 = 0;
            let totalScore5 = 0;
            let totalScore6 = 0;
            let totalScore7 = 0;
            let totalScore8 = 0;
            let totalScore9 = 0;

            for (let j = 0; j < breakdownScoreList.length; j++) {
              let code = breakdownScoreList[j].codeName.code;
              let result1 =
                breakdownScoreList[j] && breakdownScoreList[j].result1
                  ? breakdownScoreList[j].result1
                  : "";

              week1Count += countTotalTarget(result1.recordCount1);
              totalScore0 += Number(result1.score0);
              totalScore1 += Number(result1.score1);
              totalScore2 += Number(result1.score2);
              totalScore3 += Number(result1.score3);
              totalScore4 += Number(result1.score4);
              totalScore5 += Number(result1.score5);
              totalScore6 += Number(result1.score6);
              totalScore7 += Number(result1.score7);
              totalScore8 += Number(result1.score8);
              totalScore9 += Number(result1.score9);
              breakDownList_0.push(
                breakDownDataMap(breakDownData_0, code, result1.score0)
              );
              breakDownList_1.push(
                breakDownDataMap(breakDownData_1, code, result1.score1)
              );
              breakDownList_2.push(
                breakDownDataMap(breakDownData_2, code, result1.score2)
              );
              breakDownList_3.push(
                breakDownDataMap(breakDownData_3, code, result1.score3)
              );
              breakDownList_4.push(
                breakDownDataMap(breakDownData_4, code, result1.score4)
              );
              breakDownList_5.push(
                breakDownDataMap(breakDownData_5, code, result1.score5)
              );
              breakDownList_6.push(
                breakDownDataMap(breakDownData_6, code, result1.score6)
              );
              breakDownList_7.push(
                breakDownDataMap(breakDownData_7, code, result1.score7)
              );
              breakDownList_8.push(
                breakDownDataMap(breakDownData_8, code, result1.score8)
              );
              breakDownList_9.push(
                breakDownDataMap(breakDownData_9, code, result1.score9)
              );
            }

            let averageMap = targetAverageData0;
            let averageBaseScore = calcAverage(totalScore0, week1Count);
            averageMap["baseMonthScore"] = averageBaseScore;

            setTargetAverageData(
              updateDiff(averageBaseScore, averageMap, tableHeaderList)
            );
            setTargetAverageData0(
              updateDiff(averageBaseScore, averageMap, tableHeaderList)
            );
            setTargetAverageData1(
              getUpdateAverage(targetAverageData1, totalScore1, week1Count)
            );
            setTargetAverageData2(
              getUpdateAverage(targetAverageData2, totalScore2, week1Count)
            );
            setTargetAverageData3(
              getUpdateAverage(targetAverageData3, totalScore3, week1Count)
            );
            setTargetAverageData4(
              getUpdateAverage(targetAverageData4, totalScore4, week1Count)
            );
            setTargetAverageData5(
              getUpdateAverage(targetAverageData5, totalScore5, week1Count)
            );
            setTargetAverageData6(
              getUpdateAverage(targetAverageData6, totalScore6, week1Count)
            );
            setTargetAverageData7(
              getUpdateAverage(targetAverageData7, totalScore7, week1Count)
            );
            setTargetAverageData8(
              getUpdateAverage(targetAverageData8, totalScore8, week1Count)
            );
            setTargetAverageData9(
              getUpdateAverage(targetAverageData9, totalScore9, week1Count)
            );

            setTargetData(sortCode(breakDownList_0));
            setTargetData0(sortCode(breakDownList_0));
            setTargetData1(sortCode(breakDownList_1));
            setTargetData2(sortCode(breakDownList_2));
            setTargetData3(sortCode(breakDownList_3));
            setTargetData4(sortCode(breakDownList_4));
            setTargetData5(sortCode(breakDownList_5));
            setTargetData6(sortCode(breakDownList_6));
            setTargetData7(sortCode(breakDownList_7));
            setTargetData8(sortCode(breakDownList_8));
            setTargetData9(sortCode(breakDownList_9));
          }
        }
      })
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
      })
      .finally(() => {
        setExecuteFlg("");
      });
  }

  // タブ内表示
  function tableResult(num: number) {
    return (
      <>
        <Grid style={{ width: "85vw" }}>
          <Grid
            container
            style={{
              padding: "10px",
            }}
          >
            <Grid item style={{ paddingRight: "15px" }}>
              <Select
                style={{
                  minWidth: 320,
                }}
                onChange={(event) => doSelChange(event)}
                value={selectedTable}
              >
                {tableList.map((rowItem) => (
                  <MenuItem value={rowItem.id}>{rowItem.name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item>
              <Grid container>
                <Typography>基準日：</Typography>
                <DatePicker
                  selectedDate={selectedBaseDate}
                  dateFunc={(date: DateTime | null) => {
                    handleBaseDate(date);
                  }}
                  format={"yyyy年MM月dd日"}
                  minDate={DateTime.fromFormat("20000101", "yyyyMMdd")}
                  mainColor={"#4dc0b2"}
                />
              </Grid>
            </Grid>
          </Grid>

          {tabValue === 0 &&
          (isDownloadPointData(user) ||
            process.env.REACT_APP_MODE === "develop") ? (
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              style={{ margin: "3px" }}
            >
              <TSCheckStoreScoreDataButton />
            </Grid>
          ) : (
            ""
          )}

          {targetData && targetData.length > 0 ? (
            <>
              <TSCheckManagerAnalysisTable
                data={targetData}
                averageData={targetAverageData}
                headerName={headerName}
                tableHeaderList={tableHeaderList}
                tabValue={tabValue}
                graphUserList={graphUserList}
                baseMonth={baseMonth}
                onClickMove={(code) => moveClick(code)}
                onClickSelect={(code, name) => selectClick(code, name)}
              />

              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.graphTable}
              >
                <ResponsiveContainer width={"100%"} height={400}>
                  <LineChart
                    data={targetGraphData}
                    margin={{ top: 5, right: 40, bottom: 60, left: 0 }}
                    stackOffset="none"
                    height={400}
                  >
                    <XAxis dataKey="week" interval={0} />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                    <ReferenceLine y={0} stroke="#000" />
                    {graphUserList.map((item: any) => (
                      <Line dataKey={item.name} stroke={item.fill} />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  }

  return (
    <Grid>
      {executeFlg === "2" ? ( // 検索中のぐるぐる表示
        <>
          <CircularProgress className={classes.circularProgress} size={120} />
          <Grid className={classes.overLayer}></Grid>
        </>
      ) : (
        ""
      )}
      <AppBar
        position="static"
        style={{
          padding: "0",
          margin: "auto",
          width: "90vw",
          outlineColor: "#3e3e3e",
          backgroundColor: "#f2f2f2",
          color: "#3e3e3e",
        }}
      >
        <Tabs
          variant="standard"
          value={indexTabValue}
          onChange={handleChange}
          aria-label="nav tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#37ab9d",
              height: "10px",
            },
          }}
        >
          {user.jobCategory === "admin-group" ? (
            <Tab
              label="ZO別分析"
              disabled={executeFlg === "1" || zoTabDisableFlg}
            />
          ) : (
            ""
          )}
          {user.jobCategory === "admin-group" || user.jobCategory === "zm" ? (
            <Tab
              label="DO別分析"
              disabled={executeFlg === "1" || doTabDisableFlg}
            />
          ) : (
            ""
          )}
          <Tab
            label="OFC別分析"
            disabled={executeFlg === "1" || ofcTabDisableFlg}
          />
          <Tab
            label="個店別分析"
            disabled={executeFlg === "1" || kobetuTabDisableFlg}
          />
        </Tabs>
      </AppBar>
      {executeFlg === "1" ? ( // 検索中のぐるぐる表示
        <>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CircularProgress
              size={"40vh"}
              style={{
                color: "#ccc",
                top: "30vh",
                position: "absolute",
              }}
            />
          </Grid>
        </>
      ) : (
        <>
          {user.jobCategory === "admin-group" ? (
            <TabPanel value={indexTabValue} index={0}>
              <Grid className={classes.root}>{tableResult(0)}</Grid>
            </TabPanel>
          ) : (
            ""
          )}
          {user.jobCategory === "admin-group" || user.jobCategory === "zm" ? (
            <TabPanel value={tabValue} index={1}>
              <Grid className={classes.root}>{tableResult(1)}</Grid>
            </TabPanel>
          ) : (
            ""
          )}
          <TabPanel value={tabValue} index={2}>
            <Grid className={classes.root}>{tableResult(2)}</Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Grid className={classes.root}>{tableResult(3)}</Grid>
          </TabPanel>
        </>
      )}
      {/* ダイアログ */}
      <TSCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </Grid>
  );
};
export default AnalysisResult;
