import React, { useEffect, useRef, FC } from "react";
import { DateTime } from "luxon";
import {
  Button,
  TextField,
  Radio,
  Checkbox,
  Grid,
  Typography,
  Snackbar,
  SnackbarContent,
  Select,
  MenuItem,FormGroup,
} from "@material-ui/core";
import { IconButton, CircularProgress } from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  VerticalAlignTop,
  VerticalAlignBottom,
} from "@material-ui/icons";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RemoveIcon from "@material-ui/icons/Remove";
import Avatar from "@material-ui/core/Avatar";
import { getFirstFlg, getFixedFlg } from "../../Header/Header";
import DatePicker from "../../common/DatePicker";
import TSCheckModalButton from "./TSCheckModalButton";
import TSCheckModalManual from "./TSCheckModalManual";
import TSCheckSelect from "./TSCheckSelect";
import {
  getTSCheckData,
  TS_CHECK_RECORD,
  updateTSCheck,
  updateTSCheckFirestore,
  TS_CHECK_DATA,
  TS_CHECK_BIG_RECORD,
  createNewRecord,
  updateTSCheckAddValue,
  TS_CHECK_ADD_BIG_RECORD,
  deleteAddItems,
  sortAddItems,
  updateTSCheckAddItem,
  TS_CHECK_ADD_RECORD,
} from "./TSCheckTableDataOperation";
import { updateScore } from "./TSCheckCalculation";
import TSCheckDialog from "../TSCheckDialog";

import { stylesTSCheckTable } from "./StylesTSCheckRegister";
import { userLogging } from "../../../apis/userLog";
import TSCheckTemplateModalButton from "./TSCheckTemplateModalButton";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import TSCheckModalLastScore from "./TSCheckModalLastScore";
import TimeDatePicker from "../../common/TimeDatePicker";

// 画面項目の変更フラグ
let changeFlg = false;
export function getChangeFlg() {
  return changeFlg;
}
export function setChangeFlg(flg: boolean) {
  changeFlg = flg;
}
// 天候リスト
const weatherList = [
  { name: "晴れ", code: "1" },
  { name: "曇り", code: "2" },
  { name: "雨", code: "3" },
  { name: "雪", code: "4" },
];

// 曜日の取得
const getDayOfWeekText = (datetime: DateTime | null) => {
  if (!datetime) {
    return;
  }
  let weekDayArray: string[] = [];
  weekDayArray = ["月", "火", "水", "木", "金", "土", "日"];
  const dayOfWeek = datetime.weekday;
  return weekDayArray[dayOfWeek - 1];
};

// 作成日付(旧承認日)
let isApprove: number = 0;
export function isApproveDate() {
  return isApprove;
}
function checkApproveDate(dayStr: string | null, lastApproveDay: string) {
  isApprove =
    lastApproveDay !== ""
      ? dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid
        ? dayStr >= lastApproveDay
          ? 1
          : 0
        : -1
      : dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid
      ? 1
      : -1;
}
// 作成時間
let isApproveHourInput: number = 0;
export function isApproveHour() {
  return isApproveHourInput;
}
function checkApproveHour(hourStr: string) {
  let hourNum :number = Number(hourStr);
  if (hourNum || (hourStr && hourNum === 0)) {
    isApproveHourInput = hourNum;
  } else {
    isApproveHourInput = -1;
  }
}

// 最初の読み込み時に追い越しが走らないようにするためのフラグ
let initailizeFlag: boolean;
// クリックされたことを判定するカウント
let clickCount = 0;
// 合計スコア（saveTenpoCheck用）
let totalScore = 0;
// 作成日（saveTenpoCheck用）
let saveApprovalDate: DateTime | null;

const TSCheckTable: FC<{
  tenpoCode: string;
  tsCheckId: string;
  lastTSCheckId: string;
}> = ({ tenpoCode, tsCheckId, lastTSCheckId }) => {
  const classes = stylesTSCheckTable();

  const [approvalDate, setApprovalDate] = React.useState<DateTime | null>(null);
  const [lastApprovalDate, setLastApprovalDate] = React.useState("");
  const [lastDateTitle, setLastDateTitle] = React.useState("");

  const [click, setClick] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [addValue, setAddValue] = React.useState("");
  const [delCkList, setDelCkList] = React.useState([""]);
  const [deleteMode, setDeleteMode] = React.useState(false);
  const [sortMode, setSortMode] = React.useState(false);
  const [checkedId, setCheckedId] = React.useState("");
  const user = useSelector(selectUser);

  const [rowItemList, setRowItemList] = React.useState<TS_CHECK_BIG_RECORD[]>(
    []
  );
  const [addRowItemList, setAddRowItemList] = React.useState<
    TS_CHECK_ADD_BIG_RECORD[]
  >([]);
  const [selectedTenpoCode, setSelectedTenpoCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [scrollSwitch, setScrollSwitch] = React.useState(false);
  const [userPrivilege, setUserPrivilege] = React.useState("");
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [approveWeather, setApproveWeather] = React.useState("");
  const [inputHour, setInputHour] = React.useState("");
  const [approveHour, setApproveHour] = React.useState("");

  // 天候
  const weatherChangeDo = (event: React.ChangeEvent<{ value: unknown }>) => {
    setApproveWeather(event.target.value as string);
    setChangeFlg(true);
  };

  // 時間入力フィールド
  const InputHourField: React.FC<{
    hour: string;
    blurEvent: (manHour: string) => void;
    fixedFlg: boolean;
  }> = ({ hour, blurEvent, fixedFlg }) => {
    const [inputHour, setInputHour] = React.useState(hour);
    const changeHoure = (date: DateTime | null) => {
      if (date) {
        setInputHour(String(date.hour));
        setChangeFlg(true);
        blurEvent(String(date.hour));
      }
    };

    return (
      <>
        {!fixedFlg ? (
          <FormGroup row>
              <TimeDatePicker
                timeFunc={(date: DateTime | null) => {
                  changeHoure(date);
                }}
                timeValue={inputHour}
                width="100px"
                views={["hours"]}
                format="H時"
              />
          </FormGroup>
        ) : (
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            {inputHour ? inputHour + "時" : ""}
          </Typography>
        )}
      </>
    );
  };

  const blurHour = (hour: string) => {
    setInputHour(hour);
    setApproveHour(String(hour));
    checkApproveHour(hour);
    setChangeFlg(true);
  };

  const alertClick = () => {
    setOpenAlert(!openAlert);
  };

  const dialogClose = () => {
    setDialogFlg(false);
  };

  useEffect(() => {
    setRowItemList([]);
    setAddRowItemList([]);
    setApprovalDate(null);
    saveApprovalDate = null;
    setChangeFlg(false);
    initailizeFlag = false;
    if (getFirstFlg()) {
      setSelectedTenpoCode("");
    } else {
      setSelectedTenpoCode(tenpoCode);
    }
  }, [tenpoCode, getFirstFlg()]);

  useEffect(() => {
    setLoading(true);
    userLogging("店舗衛生点検表", "新規作成#初期表示", tsCheckId);
    setUserPrivilege(user.jobCategory);

    setRowItemList([]);
    setAddRowItemList([]);
    initailizeFlag = false;
    setSelectedTenpoCode(tenpoCode);
    getTSCheckData(
      tenpoCode,
      tsCheckId,
      lastTSCheckId,
      getFixedFlg(),
      user.jobCategory
    ).then((res: TS_CHECK_DATA) => {
      setApprovalDate(
        res.approve_day
          ? DateTime.fromFormat(res.approve_day, "yyyyMMdd")
          : DateTime.local()
      );
      saveApprovalDate = res.approve_day
        ? DateTime.fromFormat(res.approve_day, "yyyyMMdd")
        : DateTime.local();
      checkApproveDate(
        res.approve_day ? res.approve_day : "",
        res.last_approve_day
      );
      if (res.approve_day) {
        setChangeFlg(false);
      } else {
        setChangeFlg(true);
      }
      if (res.approve_weather) {
        setApproveWeather(res.approve_weather);
      } else {
        setApproveWeather("");
      }
      checkApproveHour(res.approve_hour);
      if (res.approve_hour) {
        setInputHour(res.approve_hour);
        setApproveHour(res.approve_hour);
      } else {
        setInputHour("");
        setApproveHour("");
      }
      setLastApprovalDate(res.last_approve_day);
      setLastDateTitle(
        res.last_approve_day
          ? DateTime.fromFormat(res.last_approve_day, "yyyyMMdd").toFormat(
              "MM月dd日"
            )
          : "-"
      );
      setRowItemList(res.base_value);
      setAddRowItemList(res.add_value);
      initailizeFlag = true;
      handleClick();
      setLoading(false);
    });
  }, [tsCheckId]);

  useEffect(() => {
    scrollBottomRef?.current?.scrollIntoView(false);
  }, [scrollSwitch]);

  useEffect(() => {
    // 店舗衛生点検表(基本項目)の値に変更があった時に呼び出されるReactHooks
    // ボタン（○、×、-、削除）が押下される度にも呼び出される。scoreの計算と保存
    if (
      (Array.isArray(addRowItemList) && addRowItemList.length === 0) ||
      (Array.isArray(rowItemList) && rowItemList.length === 0) ||
      !initailizeFlag
    )
      return;
    if (getFixedFlg()) {
      setLoading(false);
      return;
    }
    // 保存のたびにtotalScoreを計算
    async function updateTeatAsync() {
      const res = await updateScore(rowItemList, addRowItemList);
      totalScore = res;
      // saveTSCheck();
      updateTSCheckAddItem(addRowItemList, selectedTenpoCode, userPrivilege);
      setLoading(false);
    }
    updateTeatAsync();
  }, [rowItemList, addRowItemList, clickCount]);

  const handleClick = () => {
    setClick((prev) => !click);
  };

  const handleApprovalDate = (date: DateTime | null) => {
    if (!initailizeFlag) return date;
    setChangeFlg(true);
    checkApproveDate(date ? date.toFormat("yyyyMMdd") : "", lastApprovalDate);
    setApprovalDate(date);
    saveApprovalDate = date;
    saveTSCheck();
    updateTSCheckAddItem(addRowItemList, selectedTenpoCode, userPrivilege);
    return date;
  };

  const initApproveDate = () => {
    checkApproveDate(
      approvalDate ? approvalDate.toFormat("yyyyMMdd") : "",
      lastApprovalDate
    );
    if (approvalDate !== null) return approvalDate;
    let date = DateTime.local();
    setApprovalDate(date);
    saveApprovalDate = date;
    return date;
  };
  // ボタン押下処理
  const clickButton = (row: any, n: number) => {
    if (getFixedFlg()) return;

    setChangeFlg(true);
    if (n === 1) {
      if (row.button_value !== "1") {
        row.button_value = "1";
        row.user_radio_comment = "";
        row.user_radio_target = "";
        row.user_ng_list = [];
      }
    }
    if (n === 2) {
      if (row.button_value !== "2") {
        row.button_value = "2";
      }
    }
    if (n === 3) {
      if (row.button_value !== "3") {
        row.button_value = "3";
        row.user_radio_comment = "";
        row.user_radio_target = "";
        row.user_ng_list = [];
      }
    }
    handleClick();
    ++clickCount;
    if (row.big_item_id !== 9) {
      setRowItemList(updateTSCheck(rowItemList, row));
    } else {
      setAddRowItemList(updateTSCheckAddValue(addRowItemList, row));
    }
  };

  // ×ボタン押下処理
  const clickNgButton = (row: any) => {
    if (getFixedFlg()) return;

    setChangeFlg(true);
    if (row.button_value !== "2") {
      row.button_value = "2";
    } else if (!row.user_ng_list || row.user_ng_list.length === 0) {
      row.button_value = "0";
    }
    handleClick();
    ++clickCount;
    if (row.big_item_id !== 9) {
      setRowItemList(updateTSCheck(rowItemList, row));
    } else {
      setAddRowItemList(updateTSCheckAddValue(addRowItemList, row));
    }
  };

  // 追加項目の行番号をソート
  function sortAddRow() {
    let rowNum: number = 0;
    for (let i = 0; i < addRowItemList[0].rows.length; i++) {
      addRowItemList[0].rows[i].small_item_id = rowNum;
      rowNum++;
    }
    return;
  }
  // 削除対象全チェック判定
  function ckAllDelCehck() {
    for (let i = 0; i < addRowItemList[0].rows.length; i++) {
      if (delCkList.indexOf(addRowItemList[0].rows[i].key) === -1) {
        return false;
      }
    }
    return true;
  }

  // 削除モード削除対象全チェック
  const clickAllDelCehck = () => {
    let dcList = [];
    if (!ckAllDelCehck()) {
      for (let i = 0; i < addRowItemList[0].rows.length; i++) {
        dcList.push(addRowItemList[0].rows[i].key);
      }
    }
    setDelCkList(dcList);
    handleClick();
  };
  // 削除モード削除対象チェック
  const clickDelCehck = (key: string) => {
    let dcList = delCkList;
    if (delCkList.includes(key)) {
      dcList = [];
      for (let i = 0; i < delCkList.length; i++) {
        if (delCkList[i] !== key) {
          dcList.push(delCkList[i]);
        }
      }
    } else {
      dcList.push(key);
    }
    setDelCkList(dcList);
    handleClick();
  };

  // 上移動
  const upClick = () => {
    setChangeFlg(true);
    if (checkedId) {
      userLogging("店舗衛生点検表", "新規作成#並び替え上移動", checkedId);
      const obj = sortAddItems(addRowItemList, true, checkedId);
      setCheckedId(obj.nextKey);
      saveTSCheck();
      updateTSCheckAddItem(
        obj.addRowItemList,
        selectedTenpoCode,
        userPrivilege
      );
      handleClick();
    }
  };
  // 下移動
  const lowClick = () => {
    setChangeFlg(true);
    if (checkedId) {
      userLogging("店舗衛生点検表", "新規作成#並び替え下移動", checkedId);
      const obj = sortAddItems(addRowItemList, false, checkedId);
      setCheckedId(obj.nextKey);
      saveTSCheck();
      updateTSCheckAddItem(
        obj.addRowItemList,
        selectedTenpoCode,
        userPrivilege
      );
      handleClick();
    }
  };
  // 追加ボタンクリック
  const addClick = () => {
    if (addRowItemList[0].rows.length > 19) {
      setDialogMessage(["20項目を超えるため追加できません。"]);
      setDialogFlg(true);
      return;
    }
    if (addValue !== "") {
      userLogging("店舗衛生点検表", "新規作成#追加ボタン処理", addValue);
      const newRecord: TS_CHECK_ADD_RECORD = createNewRecord(
        addValue,
        addRowItemList[0],
        [],
        ""
      );
      const newAddRecord: TS_CHECK_ADD_BIG_RECORD[] = updateTSCheckAddValue(
        addRowItemList,
        newRecord,
        true
      );
      setAddRowItemList(newAddRecord);
      updateTSCheckAddItem(addRowItemList, selectedTenpoCode, userPrivilege);
      saveTSCheck();
      setAddValue("");
      setChangeFlg(true);
    }
    sortAddRow();
    handleClick();
    setScrollSwitch(!scrollSwitch);
  };

  // テンプレートによる項目追加
  const onTemplateClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    templeteItems: any[]
  ) => {
    if (addRowItemList[0].rows.length + templeteItems.length > 20) {
      setDialogMessage(["20項目を超えるため追加できません。"]);
      setDialogFlg(true);
      return;
    }
    let addRowItem: any[] = [];
    for (let i = 0; i < templeteItems.length; i++) {
      let addFlg = true;
      if (addRowItemList[0]) {
        for (let j = 0; j < addRowItemList[0].rows.length; j++) {
          if (templeteItems[i].name === addRowItemList[0].rows[j].small_title) {
            addFlg = false;
            break;
          }
        }
      }
      if (addFlg) {
        addRowItem.push(templeteItems[i]);
      }
    }
    for (let i = 0; i < addRowItem.length; i++) {
      const newRecord: TS_CHECK_ADD_RECORD = createNewRecord(
        addRowItem[i].name,
        addRowItemList[0],
        addRowItem[i].photo_filepath_gcs,
        addRowItem[i].user_input_comment
        // addRowItem[i].uuid
      );
      const newAddRecord: TS_CHECK_ADD_BIG_RECORD[] = updateTSCheckAddValue(
        addRowItemList,
        newRecord,
        true
      );
      setAddRowItemList(newAddRecord);
    }
    updateTSCheckAddItem(addRowItemList, selectedTenpoCode, userPrivilege);
    saveTSCheck();
    setChangeFlg(true);

    sortAddRow();
    handleClick();
    setScrollSwitch(!scrollSwitch);
  };

  //削除ボタン処理
  const deleteClick = () => {
    if (delCkList.length > 0) {
      setChangeFlg(true);
    }
    userLogging(
      "店舗衛生点検表",
      "新規作成#削除ボタン処理",
      JSON.stringify(delCkList)
    );
    const result: TS_CHECK_ADD_BIG_RECORD[] = deleteAddItems(
      delCkList,
      addRowItemList
    );
    setAddRowItemList(result);
    saveTSCheck();
    updateTSCheckAddItem(addRowItemList, selectedTenpoCode, userPrivilege);

    if (addRowItemList && addRowItemList[0].rows.length === 0) {
      setDeleteMode(false);
    }
    setDelCkList([]);
    sortAddRow();
    handleClick();
    ++clickCount;
  };
  // 追加モードへ
  const addModeClick = () => {
    userLogging("店舗衛生点検表", "新規作成#項目追加", "");
    if (getFixedFlg()) return;

    setAddMode((prev) => !prev);
    setDeleteMode(false);
    setSortMode(false);
  };
  // 削除モードへ
  const deleteModeClick = () => {
    userLogging("店舗衛生点検表", "新規作成#項目削除", "");
    if (getFixedFlg()) return;

    setDelCkList([]);
    setDeleteMode((prev) => !prev);
    setAddMode(false);
    setSortMode(false);
  };
  // 並べ替えモードへ
  const sortModeClick = () => {
    userLogging("店舗衛生点検表", "新規作成#並び替え", "");
    if (getFixedFlg()) return;

    setCheckedId("");
    setSortMode((prev) => !prev);
    setAddMode(false);
    setDeleteMode(false);
  };
  // 保存ボタンクリック処理
  const clickConfirmButton = () => {
    userLogging("店舗衛生点検表", "新規作成#保存ボタン", "");
    if (getFixedFlg()) return;

    if (!initailizeFlag) return;
    //保存処理
    setChangeFlg(false);
    setAddMode(false);
    setDeleteMode(false);
    setSortMode(false);
    saveTSCheck();
    updateTSCheckAddItem(addRowItemList, selectedTenpoCode, userPrivilege);
    handleClick();
  };
  const onPhotoClick = (
    row: TS_CHECK_RECORD,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    comment: string,
    filePath: string[]
  ) => {
    userLogging("店舗衛生点検表", "新規作成#写真ダイアログ保存ボタン", "");
    row.photo_filepath_gcs = filePath;
    row.user_input_comment = comment;
    saveTSCheck();
    updateTSCheckAddItem(addRowItemList, selectedTenpoCode, userPrivilege);
    handleClick();
  };

  // 画面スクロール
  const moveScroll = (fieldName: string, bottomFlg?: boolean) => {
    let element = document?.getElementById(fieldName);
    if (element !== null) {
      if (bottomFlg) {
        element?.scrollIntoView(false);
      } else {
        element.scrollIntoView();
      }
    }
  };

  // 重要項目は背景を黄色に設定
  const importantStyle = { backgroundColor: "#FFE600" };
  const importantRegex = RegExp(/◎|○/);

  // 項目追加入力フィールド
  const InputAddItemField: React.FC<{
    comment: string;
    blurEvent: (comment: string) => void;
  }> = ({ comment, blurEvent }) => {
    const [inputComment, setInputComment] = React.useState(String(comment));
    const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
      setInputComment(event.target.value as string);
    };
    const blurComment = () => {
      blurEvent(String(inputComment));
    };

    return (
      <TextField
        label="追加したい項目"
        placeholder="項目名を入力"
        size="small"
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.addText}
        value={inputComment}
        onBlur={() => blurComment()}
        onChange={(event) => changeComment(event)}
      />
    );
  };

  // 保存処理
  const saveTSCheck = () => {
    if (getFixedFlg()) return;

    sortAddRow();
    updateTSCheckFirestore(
      rowItemList,
      addRowItemList,
      tsCheckId,
      totalScore,
      lastTSCheckId,
      selectedTenpoCode,
      saveApprovalDate ? saveApprovalDate.toFormat("yyyyMMdd") : "",
      lastApprovalDate ? lastApprovalDate : "",
      userPrivilege,
      approveWeather,
      approveHour
    );
  };

  function getCheckRecord(isAddRow: boolean, rowItem: any, index: number) {
    return (
      <>
        {Object.keys(rowItem.rows).length !== 0 ? (
          <>
            {/* 大項目名↓ここから */}
            <Grid container justify="center" className={classes.header}>
              {isAddRow ? (
                <>
                  {getFixedFlg() ? (
                    <Grid container justify="center">
                      <Typography variant="h4">{rowItem.big_title}</Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid container item xs={7}>
                        <Grid container justify="flex-end">
                          <Typography variant="h4">
                            {rowItem.big_title}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={5}>
                        <Grid container justify="flex-end">
                          <Grid item className={classes.buttonSpace}>
                            <Button
                              variant={deleteMode ? "outlined" : "contained"}
                              onClick={() => deleteModeClick()}
                            >
                              項目削除
                            </Button>
                          </Grid>
                          <Grid item className={classes.buttonSpace}>
                            <Button
                              variant={sortMode ? "outlined" : "contained"}
                              onClick={() => sortModeClick()}
                            >
                              並べ替え
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <Grid container justify="center">
                  <Typography variant="h4">{rowItem.big_title}</Typography>
                </Grid>
              )}
            </Grid>
            {/* 大項目名↑ここまで */}
            <Grid container className={classes.tabBase}>
              {/* タイトル行↓ここから */}
              <Grid container>
                {isAddRow && deleteMode ? (
                  <>
                    <Grid item xs={1}>
                      <Grid item className={classes.columnHeader}>
                        <Grid
                          container
                          className={classes.headerHeight}
                          justify="center"
                          alignItems="center"
                        >
                          <Checkbox
                            style={{ color: "black" }}
                            onChange={() => clickAllDelCehck()}
                            checked={ckAllDelCehck()}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item className={classes.columnHeader}>
                        <Grid
                          container
                          className={classes.headerHeight}
                          justify="center"
                          alignItems="center"
                        >
                          <Typography variant="h6">チェック項目</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={7}>
                    <Grid item className={classes.columnHeader}>
                      <Grid
                        container
                        className={classes.headerHeight}
                        justify="center"
                        alignItems="center"
                      >
                        <Typography variant="h6">チェック項目</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={1}>
                  <Grid item className={classes.columnHeader}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      <Typography variant="h6">前回</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid item className={classes.columnHeader}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      <Typography variant="h6">今回</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid item className={classes.columnHeader}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      <Typography variant="h6">添付</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* タイトル行↑ここまで */}
              {/* データ行↓ここから */}
              <Grid container>
                {rowItem.rows.map((row: any, indexRowItem: number) => (
                  <>
                    {/* チェック項目↓ここから */}
                    <Grid item xs={7} key={row.key + "_caption"}>
                      {isAddRow && sortMode ? (
                        <>
                          <Grid
                            item
                            className={classes.columnResult}
                            key={row.key + "_caption_A"}
                          >
                            <Grid
                              container
                              className={classes.columnHeight}
                              alignItems="center"
                            >
                              <Radio
                                id="sort"
                                name="sort"
                                onClick={() => setCheckedId(row.key)}
                                checked={
                                  row.key + "" === checkedId ? true : false
                                }
                              />
                              <Typography>{row.small_title}</Typography>
                            </Grid>
                          </Grid>
                        </>
                      ) : isAddRow && deleteMode ? (
                        <>
                          <Grid
                            item
                            className={classes.columnResult}
                            key={row.key + "_caption_B"}
                          >
                            <Grid
                              container
                              className={classes.columnHeight}
                              alignItems="center"
                            >
                              <Checkbox
                                onChange={() => clickDelCehck(row.key)}
                                checked={
                                  delCkList.includes(row.key) ? true : false
                                }
                              />
                              <Typography>{row.small_title}</Typography>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          item
                          className={classes.columnCaption}
                          key={row.key + "_caption_C"}
                          style={importantRegex.test(row.small_title) ? importantStyle : {}}
                        >
                          <Grid
                            container
                            className={classes.columnHeight}
                            justify="center"
                            alignItems="center"
                            key={row.key + "_caption_C"}
                          >
                            <TSCheckModalManual
                              title={row.small_title}
                              filePath={row.manual}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {/* チェック項目↑ここまで */}
                    {/* 前回↓ここから */}
                    <Grid item xs={1} key={row.key + "_result"}>
                      <Grid item className={classes.columnResult}>
                        <Grid
                          container
                          className={classes.columnHeight}
                          justify="center"
                          alignItems="center"
                          style={importantRegex.test(row.small_title) ? importantStyle : {}}
                        >
                          {row.last_button_value === "1" ? (
                            <RadioButtonUnchecked />
                          ) : row.last_button_value === "2" ? (
                            <>
                              <TSCheckModalLastScore
                                title={row.small_title}
                                comment={row.last_user_input_comment || ""}
                                photoFilePath={
                                  row.last_photo_filepath_gcs || []
                                }
                              />
                            </>
                          ) : row.last_button_value === "3" ? (
                            <RemoveIcon />
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* 前回↑ここまで */}
                    {/* 今回↓ここから */}
                    <Grid item xs={3} key={row.key + "_check"}>
                      <Grid item className={classes.columnData}>
                        <Grid container>
                          <Grid item xs={4}>
                            <Grid
                              container
                              className={classes.columnHeight}
                              justify="center"
                              alignItems="center"
                            >
                              <Avatar
                                className={
                                  classes.gray +
                                  " " +
                                  (row.button_value === "1" ? classes.mark : "")
                                }
                                onClick={() => console.log("a1")}
                              >
                                <IconButton onClick={() => clickButton(row, 1)}>
                                  <RadioButtonUnchecked />
                                </IconButton>
                              </Avatar>
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid
                              container
                              className={classes.columnHeight}
                              justify="center"
                              alignItems="center"
                            >
                              <TSCheckSelect
                                record={row}
                                reasonChange={(row) => clickNgButton(row)}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid
                              container
                              className={classes.columnHeight}
                              justify="center"
                              alignItems="center"
                            >
                              <Avatar
                                className={
                                  classes.gray +
                                  " " +
                                  (row.button_value === "3" ? classes.mark : "")
                                }
                              >
                                <IconButton onClick={() => clickButton(row, 3)}>
                                  <RemoveIcon />
                                </IconButton>
                              </Avatar>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* 今回↑ここまで */}
                    {/* 添付↓ここから */}
                    <Grid item xs={1} key={row.key + "_upload"}>
                      <Grid className={classes.columnData}>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          key={
                            row.key +
                            row.user_input_comment +
                            row.photo_filepath_gcs[0]
                          }
                        >
                          <TSCheckModalButton
                            title={row.small_title}
                            comment={row.user_input_comment}
                            photoFilePath={row.photo_filepath_gcs}
                            onClickSave={(e, comment, filePath) =>
                              onPhotoClick(row, e, comment, filePath)
                            }
                            tenpoCode={selectedTenpoCode}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* 添付↑ここまで */}
                  </>
                ))}
              </Grid>
              {/* データ行↑ここまで */}
            </Grid>
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <>
      {/* メインコンテンツ↓ここから */}
      <Grid container className={classes.contentFrame}>
        {loading && <div className={classes.overLayer}></div>}
        {loading && (
          <CircularProgress className={classes.circularProgress} size={120} />
        )}
        {rowItemList && rowItemList.length > 0 ? (
          <>
            <Grid id="topField" />
            {/* 作成日↓ここから */}
            <Grid container>
              <Grid item lg={6} md={5} sm={4}></Grid>
              <Grid item lg={6} md={7} sm={8}>
                <Grid container justify="center" alignItems="center">
                  <Grid item lg={2} md={2} sm={2}>
                    <Typography variant="h6">作成日</Typography>
                  </Grid>
                  <Grid item justify="center" alignItems="center">
                    {getFixedFlg() ? (
                      approvalDate ? (
                        <Typography variant="h6">
                          {approvalDate.toFormat("yyyy/MM/dd") +
                            "(" +
                            getDayOfWeekText(approvalDate) +
                            ")"}
                        </Typography>
                      ) : (
                        ""
                      )
                    ) : (
                      <DatePicker
                        selectedDate={initApproveDate()}
                        dateFunc={(date: DateTime | null) => {
                          handleApprovalDate(date);
                        }}
                        format={
                          "yyyy年MM月dd日" +
                          "(" +
                          getDayOfWeekText(approvalDate) +
                          ")"
                        }
                        minDate={DateTime.fromFormat(
                          lastApprovalDate,
                          "yyyyMMdd"
                        )}
                        mainColor={"#4dc0b2"}
                      />
                    )}
                  </Grid>
                  <Grid item justify="center" alignItems="center">
                    <InputHourField
                      hour={inputHour}
                      blurEvent={blurHour}
                      fixedFlg={getFixedFlg()}
                    />
                  </Grid>
                  <Grid item justify="flex-end" alignItems="center">
                    <Select
                      value={approveWeather}
                      onChange={(e) => {
                        weatherChangeDo(e);
                      }}
                      style={{
                        minWidth: 70,
                        marginLeft: "10px",
                        color: "#3e3e3e",
                      }}
                      readOnly={getFixedFlg()}
                    >
                      {weatherList.map((row, index) => (
                        <MenuItem value={row.code} key={index}>
                          {row.code !== "" ? (
                            row.name
                          ) : (
                            <em>　　　　　　　　　　　　　　　　　</em>
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={9} md={8} sm={8}>
                <Grid container justify="flex-end" alignItems="center">
                  <Typography variant="h6">前回作成日</Typography>
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={4}>
                <Grid container justify="center" alignItems="center">
                  <Typography variant="h6">{lastDateTitle}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* 作成日↑ここまで */}
          </>
        ) : (
          ""
        )}
        {/* チェック表↓ここから */}
        <Grid container justify="center">
          <Grid>
            <Typography />
          </Grid>
          {/* 通常項目 */}
          {rowItemList.map((rowItem, index) =>
            getCheckRecord(false, rowItem, index)
          )}
          <Grid id="bottomField" />
          {/* 追加項目 */}
          {addRowItemList.map((rowItem, index) =>
            getCheckRecord(true, rowItem, index)
          )}
        </Grid>
        {getFixedFlg() ? (
          ""
        ) : (
          <Grid
            container
            justify="flex-start"
            className={classes.addbuttonSpace}
            ref={scrollBottomRef}
          >
            <>
              <Grid item xs={4}>
                {!addMode ? (
                  ""
                ) : (
                  <>
                    <InputAddItemField
                      comment={addValue}
                      blurEvent={setAddValue}
                    />
                    <Button
                      variant="contained"
                      onClick={() => addClick()}
                      className={classes.editButton}
                    >
                      追加
                    </Button>
                  </>
                )}
                {!deleteMode ? (
                  ""
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => deleteClick()}
                      className={classes.editButton}
                    >
                      削除
                    </Button>
                  </>
                )}
                {!sortMode ? (
                  ""
                ) : (
                  <>
                    <IconButton
                      className={classes.upDownButton}
                      onClick={() => upClick()}
                    >
                      <ExpandLess />
                    </IconButton>
                    <IconButton
                      className={classes.upDownButton}
                      onClick={() => lowClick()}
                    >
                      <ExpandMore />
                    </IconButton>
                  </>
                )}
              </Grid>

              <Grid item xs={8}>
                <Grid container justify="flex-end">
                  <Grid item className={classes.buttonSpace}>
                    <TSCheckTemplateModalButton
                      onClickOK={(e, recordList) =>
                        onTemplateClick(e, recordList)
                      }
                    />
                  </Grid>
                  <Button
                    variant={addMode ? "outlined" : "contained"}
                    onClick={() => addModeClick()}
                  >
                    項目追加
                  </Button>
                </Grid>
              </Grid>
            </>
          </Grid>
        )}
        {/* チェック表↑ここまで */}
      </Grid>
      {/* メインコンテンツ↑ここまで */}
      {/* コンテンツフッター↓ここから */}
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.footer}
      >
        <IconButton
          className={classes.upDownButton}
          style={{ color: "#ffffff", backgroundColor: "#00CC5B" }}
          onClick={() => moveScroll("topField")}
        >
          <VerticalAlignTop></VerticalAlignTop>
        </IconButton>
        <IconButton
          className={classes.upDownButton}
          style={{ color: "#ffffff", backgroundColor: "#00CC5B" }}
          onClick={() => moveScroll("bottomField")}
        >
          <VerticalAlignBottom></VerticalAlignBottom>
        </IconButton>
        {getFixedFlg() ? (
          ""
        ) : (
          <>
            <Button
              variant={"contained"}
              size="small"
              color={changeFlg ? "secondary" : "primary"}
              onClick={() => {
                clickConfirmButton();
                alertClick();
              }}
            >
              保存
            </Button>
            <Snackbar
              open={openAlert}
              autoHideDuration={5000}
              onClose={alertClick}
            >
              <SnackbarContent
                style={{ backgroundColor: "#333333", color: "white" }}
                message={
                  <span style={{ fontSize: "16px" }}>
                    {
                      "提出（印刷）タブをクリックして店舗衛生点検表を提出してください。"
                    }
                  </span>
                }
              />
            </Snackbar>
          </>
        )}
        {/* ダイアログ */}
        <TSCheckDialog
          msg={dialogMessage}
          isOpen={dialogFlg}
          doOK={dialogClose}
          doNo={dialogClose}
          isAlert={true}
        />
      </Grid>
      {/* コンテンツフッター↑ここまで */}
    </>
  );
};
export default TSCheckTable;
