import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  ComposedChart,
  Line,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      padding: "5px",
      border: "1px solid lightgray",
      backgroundColor: "rgba(255,255,255,0.9)",
      pointerEvents: "all",
      borderRadius: "5%",
    },
    customTooltipContent: {
      listStyle: "none",
    },
  })
);

const GraphContentZennenhiTenpoMobaku: React.FC<{
  data: any;
  graphType: string;
  baseAxis: number;
  firstDate: string;
  today: string;
}> = ({ data, graphType, baseAxis, firstDate, today }) => {
  const classes = useStyles({});
  const [activeTooltip, setActiveTooltip] = useState(true);
  let maxLineNum = 0;
  let maxBarNum = 0;
  let barTicksArray: any[] = [];

  const calcMaxLineNum = () => {
    let maxNum = 0;
    if (graphType === "tenpo") {
      data.forEach((value: any) => {
        let tempMax = Math.max(
          value.firstTenpoRealNumForGraph,
          value.secondTenpoRealNumForGraph
        );
        maxNum = tempMax ? Math.max(maxNum, tempMax) : maxNum;
      });
    } else if (graphType === "mobaku") {
      data.forEach((value: any) => {
        let tempMax = Math.max(
          value.firstMobakuRatioLastYear,
          value.secondMobakuRatioLastYear
        );
        maxNum = tempMax ? Math.max(maxNum, tempMax) : maxNum;
      });
    }
    return maxNum;
  };

  const calcMaxBarNum = () => {
    let maxNum = 0;
    data.forEach((value: any) => {
      if (!isNaN(value.ratioDiff)) {
        maxNum = Math.max(maxNum, Math.abs(value.ratioDiff));
      }
    });
    return Math.round(maxNum * 100) / 100;
  };

  const setTicksValues = () => {
    maxLineNum = calcMaxLineNum();
    maxBarNum = calcMaxBarNum();

    barTicksArray = [
      Math.round(-maxBarNum),
      Math.round(-maxBarNum * 0.75),
      Math.round(-maxBarNum * 0.5),
      Math.round(-maxBarNum * 0.25),
      0,
      Math.round(maxBarNum * 0.25),
      Math.round(maxBarNum * 0.5),
      Math.round(maxBarNum * 0.75),
      Math.round(maxBarNum),
    ];
  };
  const CustomTooltip = ({ active, payload, label }: any) => {
    let unit = "";
    if (baseAxis === 0) {
      unit = "時";
    } else if (baseAxis === 3) {
      unit = "月";
    }
    if (active && payload && payload.length && activeTooltip) {
      return (
        <div className={classes.customTooltip}>
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={() => setActiveTooltip(false)} size={"small"}>
              <HighlightOffIcon />
            </IconButton>
          </div>
          <li className={classes.customTooltipContent}>{`${label} ${unit}`}</li>
          {payload.map((payload: any, index: any) => {
            let newName = "";
            let color;
            switch (payload.name) {
              case "firstMobakuRatioLastYear":
                newName = "分析対象モバ空前年比";
                color = "#0070C0";
                break;
              case "secondMobakuRatioLastYear":
                newName = "比較対象モバ空前年比";
                color = "#00B050";
                break;
              case "firstTenpoRatioLastYear":
                newName = "分析対象客数前年比";
                color = "#0070C0";
                break;
              case "secondTenpoRatioLastYear":
                newName = "比較対象客数前年比";
                color = "#00B050";
                break;
              case "ratioDiff":
                newName = "前年比差";
                color = "gray";
                break;
            }
            return (
              <li
                className={classes.customTooltipContent}
                style={{ color: color }}
                key={index.toString()}
              >
                {newName + "：" + payload.value}
              </li>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const makeFutureGraph = () => {
    const selectedDate = convertDate(firstDate);
    const twoWeekAgoDate = new Date(
      selectedDate.setDate(selectedDate.getDate() - 14)
    );
    const todayDate = convertDate(today);
    let dateDiff =
      (todayDate.getTime() - convertDate(firstDate).getTime()) / 86400000;
    if (todayDate > twoWeekAgoDate) {
      for (let d = 15 + dateDiff; d < 29; d++) {
        data[d].firstMobakuRatioLastYear = "-";
        data[d].secondMobakuRatioLastYear = "-";
        data[d].firstTenpoRatioLastYear = "-";
        data[d].secondTenpoRatioLastYear = "-";
        data[d].diffForGraph = "-";
        data[d].ratioDiff = "-";
      }
    }
  };

  const convertDate = (date: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const dateStr = `${year}/${month}/${day}`;
    return new Date(dateStr);
  };

  const handleActiveTooltip = () => {
    setActiveTooltip(true);
  };

  if (data) {
    baseAxis === 1 && data.length === 29 && makeFutureGraph();
    setTicksValues();
  }

  return (
    <ResponsiveContainer maxHeight={290}>
      <ComposedChart
        data={data}
        margin={{ top: 20, right: 20, left: 40, bottom: -15 }}
      >
        <XAxis
          dataKey="date"
          padding={{ right: 15, left: 15 }}
          tickFormatter={() => {
            return "";
          }}
        />
        {/* 前年比のY軸 */}
        <YAxis
          yAxisId={1}
          domain={[0, 200]}
          tickCount={9}
          tick={{ fontSize: 14 }}
          allowDataOverflow={true}
          label={{
            value: "前年比（％）",
            angle: -90,
            dx: -50,
          }}
        />
        {/* 前年比差のY軸 */}
        <YAxis
          yAxisId={3}
          orientation={"right"}
          domain={[-maxBarNum, maxBarNum]}
          tickCount={9}
          ticks={barTicksArray}
          tick={{ fontSize: 14 }}
          allowDataOverflow={true}
          label={{
            value: "前年比差（％）",
            angle: -90,
            dx: 30,
          }}
        />
        <Tooltip trigger="click" content={<CustomTooltip />} />
        <ReferenceLine yAxisId={1} y={100} stroke="black" />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar
          yAxisId={3}
          dataKey="ratioDiff"
          barSize={6}
          fill="#C0C0C0"
          animationEasing="ease-out"
          animationDuration={300}
          onClick={() => {
            setActiveTooltip(true);
          }}
        />
        <Line
          yAxisId={1}
          dataKey="firstMobakuRatioLastYear"
          stroke="#0070C0"
          strokeWidth={3}
          type="linear"
          animationEasing="ease-out"
          animationDuration={300}
          hide={graphType === "tenpo"}
          onClick={() => {
            setActiveTooltip(true);
          }}
          activeDot={{ onClick: handleActiveTooltip }}
        />
        <Line
          yAxisId={1}
          dataKey="secondMobakuRatioLastYear"
          stroke="#00B050"
          strokeWidth={3}
          type="linear"
          animationEasing="ease-out"
          animationDuration={300}
          hide={graphType === "tenpo"}
          onClick={() => {
            setActiveTooltip(true);
          }}
          activeDot={{ onClick: handleActiveTooltip }}
        />
        <Line
          yAxisId={1}
          dataKey="firstTenpoRatioLastYear"
          stroke="#0070C0"
          strokeWidth={3}
          type="linear"
          animationEasing="ease-out"
          animationDuration={300}
          hide={graphType === "mobaku"}
          onClick={() => {
            setActiveTooltip(true);
          }}
          activeDot={{ onClick: handleActiveTooltip }}
        />
        <Line
          yAxisId={1}
          dataKey="secondTenpoRatioLastYear"
          stroke="#00B050"
          strokeWidth={3}
          type="linear"
          animationEasing="ease-out"
          animationDuration={300}
          hide={graphType === "mobaku"}
          onClick={() => {
            setActiveTooltip(true);
          }}
          activeDot={{ onClick: handleActiveTooltip }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default GraphContentZennenhiTenpoMobaku;
