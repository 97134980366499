/**
 * geolocation関連の処理です。
 * @class geolocationAction.ts
 * @constructor
 */

// 現在地緯度経度情報取得
export const getGeolocation = () => {
  const options = {
    enableHighAccuracy: true, // enableHighAccuracyは、GPSの精度でtrueかfalseをセットする trueだと精度が高くなる
    timeout: 1000, //timeoutは、タイムアウト時間でミリ秒単位
    maximumAge: 0, // maximumAgeは、キャッシュ有効時間でミリ秒で表す 0の場合、常に最新の情報を取得する
  };
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};
