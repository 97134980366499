import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { createGoogleMap, getGoogleMapsObject } from "./GoogleMaps";
import {
  existingSejStoreLayerEvent,
  initExistingSejStores,
} from "./layers/existingSejStoreLayer";
import MapsSearchBar from "./MapComponents/MapsSearchBar";
import MapsLayersButton from "./MapComponents/MapsLayersButton";
import MapsGeolocationButton from "./MapComponents/MapsGeolocationButton";
import MapsModalLooker from "./MapComponents/MapsModalLooker";
import { userLogging } from "../../apis/userLog";
import { isFirebaseAuth } from "../../firebase";
import { useLocation } from "react-router-dom";
import CreateMarketAreaMenu from "../OpView/MarketArea/CreateMarketAreaMenu";
import EditMarketAreaMenu from "../OpView/MarketArea/EditMarketAreaMenu";
import ToastMessage from "../OpView/MarketArea/ToastMessage";
import MobakuManual from "../OpView/Mnual/MobakuManual";
import ShowMarketAreaButton from "../OpView/MarketArea/ShowMarketAreaButton";
import HideMarketArea from "../OpView/MarketArea/HideMarketArea";
import DeleteMarketAreaModal from "../OpView//MarketArea/DeleteMarketAreaModal";
import { selectExclusiveFlg } from "../../features/marketAreaStatusSlice";
import { selectUser } from "../../features/userSlice";
import SelectGraph from "../OpView/SelectGraph";
import CsvUploadButton from "../OpView/CsvUploadButton";

const vh = window.innerHeight * 0.995;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    googleMaps: {
      height: vh,
      width: "100%",
    },
    icons: {
      position: "absolute",
      right: "10px",
      bottom: "110px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      height: "330px",
    },
    leftIcons: {
      position: "absolute",
      left: "10px",
      bottom: "110px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
    },
    label: {
      cursor: "pointer",
      display: "block",
    },
  })
);

export let userInfo: any;

const Maps: React.FC = (props: any) => {
  const timerValue = 86400;
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const exclusiveFlg = useSelector(selectExclusiveFlg);
  const user = useSelector(selectUser);
  const stateValue: String = location.state as String;
  const [count, setCount] = useState(timerValue);

  const eventListeners = [
    "scroll",
    "resize",
    "click",
    "contextmenu",
    "wheel",
    "keypress",
    "mousedown",
    "mousemove",
    "touchstart",
    "touchmove",
    "touchend",
    "touchend",
  ];

  useEffect(() => {
    userInfo = user;
    // 参照権限のないユーザーをリダイレクト（URL直遷移対策）
    if (process.env.NODE_ENV !== "development") {
      if (
        !userInfo.apps?.includes("ofc-counselling") &&
        userInfo.jobCategory !== "admin-group"
      ) {
        window.location.href = process.env.REACT_APP_DIGITAL_TOOL!;
      }
    }

    createGoogleMap(stateValue, dispatch);
    userLogging("OPVIEW", "OPVIEW＃OPVIEWログイン", "");

    // 画面操作時にタイマーをリセットするイベントを設定
    eventListeners.forEach((event) => {
      document.addEventListener(event, () => {
        setCount(timerValue);
      });
    });
  }, []);

  // 1時間ごとに操作の有無をチェックし、24時間操作がなければログアウトする
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      if (count === 0) {
        window.location.href = process.env.REACT_APP_DIGITAL_TOOL!;
        return;
      }
    }
    const interval = setInterval(() => {
      setCount(count - 3600);
    }, 3600000);
    return () => clearInterval(interval);
  }, [count]);

  // 排他制御中はインフォウィンドウとストリートビューを非表示にする
  useEffect(() => {
    const mapObj = getGoogleMapsObject();
    if (mapObj) {
      if (exclusiveFlg) {
        google.maps.event.removeListener(existingSejStoreLayerEvent);
        mapObj.setOptions({ streetViewControl: false });
      } else {
        initExistingSejStores(dispatch);
        mapObj.setOptions({ streetViewControl: true });
      }
    }
  }, [exclusiveFlg]);

  if (!isFirebaseAuth()) {
    return <></>;
  }
  return (
    <div>
      <div id="map" className={classes.googleMaps}></div>
      {/* ↓画面右のアイコン群↓ */}
      <div className={classes.icons}>
        <MapsSearchBar />
        <MapsLayersButton />
        <MobakuManual />
        <ShowMarketAreaButton />
        <HideMarketArea />
        <SelectGraph />
        <MapsGeolocationButton />
        <MapsModalLooker />
      </div>
      {/* ↑画面右のアイコン群↑ */}
      <DeleteMarketAreaModal isMobaku={false} />
      <ToastMessage />
      <CreateMarketAreaMenu isMobaku={false} />
      <EditMarketAreaMenu isMobaku={false} />
      <div className={classes.leftIcons}>
        <CsvUploadButton />
      </div>
    </div>
  );
};

export default Maps;
