/**
 * ログイン関係を扱うクラスです。
 * @class login.js
 * @constructor
 */
 import { auth } from "../../firebase";

export const tokenLogin = (url:string):void => {
  const token:string = getParam("token", url);
  if( token !== ""){
    auth.signInWithCustomToken(token)
    .then((userCredential) => {
      console.log(userCredential)
    })
    .catch((error) => {
      console.log(error)
    });
  }
}

const getParam = (name: string, url: string): string => {
  if (!url) url = window.location.href;
  name = name.replace(/[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return "";
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};