import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: "inlineBlock",
      // transition: "0.1s all",
      borderRadius: "8px",
      fontSize: "18px",
      textAlign: "center",
      width: "100%",
      height: "40px",
      padding: "2px",
      cursor: "pointer",
      textDecoration: "none",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      "&:active": {},
      webkitAppearance: "none",
    },
  })
);

const GraphButton: React.FC<{
  onClick: any;
  text: string;
  color: string;
}> = ({ onClick, text, color }) => {
  const classes = useStyles({});

  const getBGColor = () => {
    if (color === "blue") {
      return "#90c8e2";
    } else if (color === "green") {
      return "#a5d17e";
    }
  };
  const getBorder = () => {
    if (color === "blue") {
      return "5px solid #0000FF";
    } else if (color === "green") {
      return "4px solid #00B050";
    }
  };

  return (
    <button
      onClick={onClick}
      className={classes.button}
      style={{
        color: "#ffffff",
        backgroundColor: getBGColor(),
        border: getBorder(),
      }}
    >
      {text}
    </button>
  );
};

export default GraphButton;
