import React, { FC } from "react";
import { Button } from "@material-ui/core";
import { postRequest } from "../../../apis/axiosAction";
import Encoding from "encoding-japanese";
import { DateTime } from "luxon";

const TSCheckStoreScoreDataButton: FC<{}> = () => {
  const [executeFlg, setExecuteFlg] = React.useState(false);
  const [executeFlg2, setExecuteFlg2] = React.useState(false);

  // 個店毎の得点推移
  const getTenpoCheckData = async () => {
    let date: any = DateTime.local().toFormat("yyyyMMdd");

    const apiParams = {
      "api-name": "storeTransitionData",
      target_date: "selectedBaseDate",
    };
    const url =
      process.env.REACT_APP_GAE_API_URL + "tscheck/storeTransitionData";

    setExecuteFlg(true);
    return postRequest(url, apiParams)
      .then((res) => {
        let headerRow: any[] = [
          "ZOコード",
          "ZO",
          "DOコード",
          "DO",
          "OFCコード",
          "OFC",
          "店舗コード",
          "店舗名",
        ];

        let dataList: any[] = res.data.dataList;
        let sortDataList: any[] = [];
        if (dataList) {
          let resultList = dataList[0].resultList;
          let monthList = dataList[0].monthList;

          for (let j = 0; j < monthList.length; j++) {
            headerRow.push(monthList[j].name.slice(1));
          }
          for (let i = 0; i < resultList.length; i++) {
            let sortData: any = {};
            let result: any = resultList[i];
            sortData.zocode = result.zocode;
            sortData.zoname = result.zoname;
            sortData.docode = result.docode;
            sortData.doname = result.doname;
            sortData.ofc_code = result.ofc_code;
            sortData.ofc_name = result.ofc_name;
            sortData.tenpo_code = result.tenpo_code;
            sortData.tenpo_name = result.tenpo_name;
            for (let j = 0; j < monthList.length; j++) {
              sortData[monthList[j].name] = result[monthList[j].name];
            }
            sortDataList.push(sortData);
          }
        }

        const dataRows = sortDataList.map(({ tableData, ...row }) => {
          return Object.values(row);
        });

        const delimiter = ",";
        const data: any = [headerRow, ...dataRows]
          .map((e: any) => e.join(delimiter))
          .join("\n");

        let fileName = "TSCheckStoreTransitionData_" + date;

        const exportFileName = `${fileName}.csv`;

        let convertData: any = new Uint8Array(
          Encoding.convert(Encoding.stringToCode(data), "SJIS", "UNICODE")
        );
        const a = document.createElement("a");
        a.href = URL.createObjectURL(
          new Blob([convertData], { type: "text/csv" })
        );
        a.setAttribute("download", exportFileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => {
        setExecuteFlg(false);
      });
  };

  // 個店毎評価一覧出力
  const getStoreScoreData = async () => {
    let date: any = DateTime.local().toFormat("yyyyMMdd");
    const apiParams = {
      "api-name": "storeEvaluationData",
      target_date: "selectedBaseDate",
    };
    const url =
      process.env.REACT_APP_GAE_API_URL + "tscheck/storeEvaluationData";

    setExecuteFlg2(true);
    return postRequest(url, apiParams)
      .then((res) => {
        let headerRow: any[] = [
          "ZOコード",
          "ZO",
          "DOコード",
          "DO",
          "OFCコード",
          "OFC",
          "店舗コード",
          "店舗名",
        ];

        for (let i = 1; i <= 70; i++) {
          headerRow.push("No" + i);
          headerRow.push("No" + i + "添付");
        }

        let dataList: any[] = res.data.dataList;
        let sortDataList: any[] = [];
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let sortData: any = {};
            let result: any = dataList[i];
            sortData.zocode = result.zocode;
            sortData.zoname = result.zoname;
            sortData.docode = result.docode;
            sortData.doname = result.doname;
            sortData.ofc_code = result.ofc_code;
            sortData.ofc_name = result.ofc_name;
            sortData.tenpo_code = result.tenpo_code;
            sortData.tenpo_name = result.tenpo_name;
            for (let j = 1; j <= 70; j++) {
              sortData["_" + j] = result["_" + j];
              sortData["_" + j + "p"] = result["_" + j + "p"];
            }
            sortDataList.push(sortData);
          }
        }

        const dataRows = sortDataList.map(({ tableData, ...row }) => {
          return Object.values(row);
        });

        const delimiter = ",";
        const data: any = [headerRow, ...dataRows]
          .map((e: any) => e.join(delimiter))
          .join("\n");

        let fileName = "TSCheckStoreEvaluationData_" + date;

        const exportFileName = `${fileName}.csv`;

        let convertData: any = new Uint8Array(
          Encoding.convert(Encoding.stringToCode(data), "SJIS", "UNICODE")
        );
        const a = document.createElement("a");
        a.href = URL.createObjectURL(
          new Blob([convertData], { type: "text/csv" })
        );
        a.setAttribute("download", exportFileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => {
        setExecuteFlg2(false);
      });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ width: "220px", marginRight: "10px" }}
        onClick={() => getTenpoCheckData()}
        disabled={executeFlg ? true : false}
      >
        {!executeFlg ? "CSV出力（個店毎の得点推移）" : "CSV出力中"}
      </Button>

      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ width: "200px" }}
        onClick={() => getStoreScoreData()}
        disabled={executeFlg2 ? true : false}
      >
        {!executeFlg2 ? "CSV出力（個店毎評価一覧）" : "CSV出力中"}
      </Button>
    </>
  );
};
export default TSCheckStoreScoreDataButton;
