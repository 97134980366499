import React, { FC } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid, Typography } from "@material-ui/core";
import InfoTip from "../../common/InfoTip";
import { selectImageToken } from "../../../features/userSlice";

function getModalStyle() {
  return {
    top: "5%",
    left: "5%",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      width: "50%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

const TSCheckModalManual: FC<{
  title: string;
  filePath?: string[];
}> = ({ title, filePath }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const imageToken = useSelector(selectImageToken);
  const handleOpen = () => {
    if (filePath && filePath.length > 0) {
      setOpen(true);
    }
  };

  function countText(text: string) {
    let count = 0;
    if (text) {
      for (let i = 0; i < text.length; i++) {
        let c = text.charCodeAt(i);
        if (
          (c >= 0x0 && c < 0x81) ||
          c == 0xf8f0 ||
          (c >= 0xff61 && c < 0xffa0) ||
          (c >= 0xf8f1 && c < 0xf8f4)
        ) {
          count += 1;
        } else {
          count += 2;
        }
      }
    }
    return count;
  }

  let imgFileList: string[] = [];
  // const imageUrl =
  //   process.env.REACT_APP_CLOUD_STORAGE_URL +
  //   "tenpo-check-manual/tenpo_check_1-1-1.png" +
  //   "?access_token=" +
  //   imageToken;
  if (filePath && filePath.length > 0) {
    for (let i = 0; i < filePath.length; i++) {
      imgFileList.push(
        process.env.REACT_APP_CLOUD_STORAGE_URL +
          filePath[i] +
          "?access_token=" +
          imageToken
      );
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container onClick={handleOpen}>
        <Typography
          style={countText(title) > 180 ? { fontSize: "0.78em" } : {}}
        >
          {title}
          {imgFileList && imgFileList.length > 0 ? (
          <InfoTip
            iconType="info"
            infoText=""
            placement="top"
            noTitleFlg={true}
          />
        ) : (
          ""
        )}
        </Typography>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ height: "95vh", overflowY: "auto" }}
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 id="simple-modal-title">{title}</h3>
          {imgFileList
            ? imgFileList.map((res: any) => (
                <div>
                  <img alt="" src={res} width="100%" height="100%" />
                </div>
              ))
            : ""}
        </div>
      </Modal>
    </>
  );
};
export default TSCheckModalManual;
