/**
 * 地図上の項目を管理するコントローラーです。
 * 下記の地図上項目を管理します
 * 「ピン・メモ」、「既存店」
 * @class MapItems.js
 * @constructor
 */

import {
  getModalGoogleMapsObject,
  getGMapsCenterPoint,
  getGoogleMapsZoom,
} from "./SelectMobakuGoogleMaps";
import {
  updateSelectingCvrMobakuItem,
  updateSelectingFirstMobakuItem,
  updateSelectingSecondMobakuItem,
  updateFirstMobakuItem,
  updateSecondMobakuItem,
  updateSecondCvrItem,
  updateFirstMobakuName,
  updateSecondMobakuName,
} from "../../../features/tenpoStatusSlice";
import {
  selectingCvrMobakuItemRef,
  selectingMobakuFirstItemRef,
  selectingMobakuSecondItemRef,
  cvrMobakuItemRef,
  mobakuFirstItemRef,
  mobakuSecondItemRef,
} from "../../OpView/SelectMobakuMap/MobakuMapsSelectedButton";
import // cvrTenpoItem,
// cvrMobakuItem,
// selectedFirstMobakuName,
// selectedSecondMobakuName,
"../SelectStoreMenu";
import {
  unvisibleMapsStyleSettings,
  conflictStoreLayerMapsStyleSettings,
  mobakuMapsStyleSettings,
  cvrMobakuMapsStyleSettings,
} from "./MobakuMapStylesSettings";
import { getGeoFireStore } from "../../../apis/geoFireXAction";
import { point2distance } from "../../../apis/turfAction";
import { hankaku2Zenkaku } from "../SelectStoreMenu";
import { callMapItemInnerData } from "./operation/mobakuMapInnerDataOperation";
import {
  initMobakuExistingSejStores,
  existingSejStoreInfoWindow,
  existingSejStoreLayer,
  cvrExistingSejStoreLayer,
  existingSejStoreData,
  existingSejStoreLayerMapsStyleSettings,
  cvrExistingSejStoreLayerMapsStyleSettings,
} from "./layers/existingSejStoreLayer";
import {
  initConflictStores,
  conflictStoreInfoWindow,
  conflictStoreLayer,
  conflictStoreData,
} from "./layers/conflictStoreLayer";
import { unselectMarketArea } from "../MarketArea/PolygonDrawing";

export let mobakuLayer: google.maps.Data;
export let cvrMobakuLayer: google.maps.Data;
export let mobakuLayerEvent: google.maps.MapsEventListener;
export let cvrMobakuLayerEvent: google.maps.MapsEventListener;
let mobakuLayerInfoWindow = new Map();
let cvrMobakuLayerInfoWindow = new Map();
let subscriptionMobaku: any = [];
let lastQueriedLatLng: any = null; // クエリ結果の初期値
let dividedCitiesArraies: any = ["ALL"];

let mobakuData: any;
// レイヤ初期化
export const initGoogleMapsLayers = (
  storeOrder: string,
  dispatch: any,
  graphType: string
): void => {
  initMobakuExistingSejStores(storeOrder, dispatch, graphType);
  initConflictStores();
  callMapItemInnerData(getGMapsCenterPoint());
  callMapItemMove(graphType);
};

// 地図操作
export const callMapItemMove = (
  graphType: string,
  isForceUpdate: boolean = false
) => {
  callMapItemGeoFireStore(
    getGMapsCenterPoint(),
    getGoogleMapsZoom(),
    graphType
  );
  callMapItemInnerData(
    getGMapsCenterPoint(),
    getGoogleMapsZoom(),
    isForceUpdate
  );
};

// export const updateMapItemGeoFireStore = () => {
//   callMapItemGeoFireStore(getGMapsCenterPoint());
// };

// Firestoreの呼出
export const callMapItemGeoFireStore = (
  latLng: any,
  zoomLevel = 22,
  graphType: string
) => {
  if (graphType !== "cvr" && graphType !== "mobaku") return;
  const layerType = graphType === "cvr" ? cvrMobakuLayer : mobakuLayer;
  let searchLength = 3;
  const searchDistance = 1,
    largeAreaZoomLevel = 15;
  if (
    lastQueriedLatLng !== null &&
    point2distance(latLng, lastQueriedLatLng) / 1000 < searchDistance
  ) {
    return;
  }
  if (largeAreaZoomLevel > zoomLevel) {
    searchLength = 4.5;
  }
  dividedCitiesArraies.forEach((conditions: any, index: number) => {
    subscriptionMobaku[index] = getGeoFireStore(
      getLayerValue(layerType, "fsDocumentName"),
      latLng.lat(),
      latLng.lng(),
      searchLength,
      layerType,
      subscriptionMobaku[index],
      cbFuncMobaku,
      false,
      conditions
    );
  });
  lastQueriedLatLng = latLng;
};

// 地図上の項目の表示管理(非表示時→表示化、表示時→非表示化)
export const manageDisplayLayer = (
  layerValue: any,
  flg: boolean,
  graphType: string
): void => {
  if (!flg) {
    // 既に表示されている場合非表示化する
    layerValue.setStyle(unvisibleMapsStyleSettings);
    // 表示されているInfoWindowを消す
    let infoWindow = getLayerValue(layerValue, "infoWindow");
    infoWindow.forEach(function (infoWindow: any) {
      infoWindow.close();
    });
    infoWindow.clear();
  } else {
    // 表示を行う場合既存項目を初期化した上で再表示する
    layerValue.forEach(function (feature: any) {
      layerValue.remove(feature);
    });
    layerValue.addGeoJson(getLayerValue(layerValue, "geojson"));
    layerValue.setStyle(getLayerValue(layerValue, "mapStyle"));
  }
  callMapItemMove(graphType, true);
};

const cbFuncMobaku = (geojson: any, updateLayer: any, conditions: any) => {
  mobakuData = geojson;
  updateFireStoreLayer(updateLayer);
};

// FireStoreの地図上の項目の表示アップデート(非表示時→表示化、表示時→非表示化)
export function updateFireStoreLayer(layerValue: any) {
  // 押下確認はボタンのステータスを参照する
  // if (getLayerValue(layerValue, "isPushedLayerButton")) {
  // 表示を行う場合既存項目を初期化した上で更新する
  // 暫定:商圏分析除外時(既存店/競合店の表示制御がないため)
  // if (
  //   layerValue === marketAreaLayer &&
  //   !getLayerValue(layerValue, "isPushedLayerButton")
  // ) {
  //   return;
  // }
  if (layerValue === undefined) return;
  layerValue.forEach(function (feature: any) {
    layerValue.remove(feature);
  });
  layerValue.addGeoJson(getLayerValue(layerValue, "geojson"));
  layerValue.setStyle(getLayerValue(layerValue, "mapStyle"));
}

export function updateMeshCode(
  selectingMobakuCode: string[],
  newMeshCode: string
) {
  let updatedMeshCode = selectingMobakuCode;
  if (selectingMobakuCode[0] === "market-area") {
    updatedMeshCode = [newMeshCode];
  } else if (!selectingMobakuCode.includes(newMeshCode)) {
    updatedMeshCode = [...selectingMobakuCode, newMeshCode];
  } else if (selectingMobakuCode.includes(newMeshCode)) {
    updatedMeshCode = selectingMobakuCode.filter(
      (value: string, index: number) => value !== newMeshCode
    );
  }
  return updatedMeshCode;
}

// export function updateMeshName(selectedMeshName: string, meshName: string) {
//   let updatedMeshName = "";
//   if (!selectedMeshName.split("mobaku-").includes(meshName)) {
//     updatedMeshName = "mobaku-" + meshName;
//   }
//   selectedMeshName
//     .split("mobaku-")
//     .forEach(function (value: string, index: number) {
//       if (value !== "" && value !== meshName && value !== "店舗の範囲") {
//         updatedMeshName = updatedMeshName + "mobaku-" + value;
//       }
//     });
//   return updatedMeshName;
// }
// 商圏情報初期処理
export const initMobaku = (
  storeOrder: string,
  dispatch: any,
  graphType: string,
  exclusiveFlg: boolean
): void => {
  // CVRグラフとモバ空比較グラフでモバ空レイヤを分ける(GoogleMapオブジェクトは共通)
  if (graphType === "cvr") {
    if (cvrMobakuLayer) {
      manageDisplayLayer(cvrMobakuLayer, false, graphType);
    }
    cvrMobakuLayer = new google.maps.Data({
      map: getModalGoogleMapsObject(),
      style: {
        visible: false,
      },
    });
    updateFireStoreLayer(cvrMobakuLayer);
    // クリックイベントの登録
    cvrMobakuLayerEvent = cvrMobakuLayer.addListener(
      "click",
      function (event: any) {
        if (exclusiveFlg) return;
        if (!cvrMobakuLayerInfoWindow.has(event.feature)) {
          cvrMobakuLayerInfoWindow.set(
            event.feature,
            new google.maps.InfoWindow({
              // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
              maxWidth: 300,
            })
          );
        }
        selectMobaku(
          event.feature.getProperty("attribution").key_code,
          event.feature.getProperty("attribution").center_address
        );
        updateFireStoreLayer(cvrMobakuLayer);
      }
    );
  } else if (graphType === "mobaku") {
    if (mobakuLayer) {
      manageDisplayLayer(mobakuLayer, false, graphType);
    }
    mobakuLayer = new google.maps.Data({
      map: getModalGoogleMapsObject(),
      style: {
        visible: false,
      },
    });
    updateFireStoreLayer(mobakuLayer);
    // クリックイベントの登録
    mobakuLayerEvent = mobakuLayer.addListener("click", function (event: any) {
      if (!mobakuLayerInfoWindow.has(event.feature)) {
        mobakuLayerInfoWindow.set(
          event.feature,
          new google.maps.InfoWindow({
            // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
            maxWidth: 300,
          })
        );
      }
      selectMobaku(
        event.feature.getProperty("attribution").key_code,
        event.feature.getProperty("attribution").center_address
      );
      updateFireStoreLayer(mobakuLayer);
    });
  }

  const selectMobaku = (meshCode: string, meshName: string) => {
    if (meshName === undefined || meshName === "") {
      meshName = hankaku2Zenkaku(meshCode);
    }
    if (graphType === "cvr") {
      let updateCode = updateMeshCode(selectingCvrMobakuItemRef, meshCode);
      unselectMarketArea(dispatch);
      dispatch(
        updateSelectingCvrMobakuItem({
          selectingCvrMobakuItem: updateCode,
        })
      );
    } else if (graphType === "mobaku") {
      if (storeOrder === "first") {
        let updateCode = updateMeshCode(selectingMobakuFirstItemRef, meshCode);
        dispatch(
          updateSelectingFirstMobakuItem({
            selectingFirstMobakuItem: updateCode,
          })
        );
      } else if (storeOrder === "second") {
        let updateCode = updateMeshCode(selectingMobakuSecondItemRef, meshCode);
        dispatch(
          updateSelectingSecondMobakuItem({
            selectingSecondMobakuItem: updateCode,
          })
        );
      }
    }
  };
};

// レイヤ別の値をそれぞれ返す
export function getLayerValue(
  layerValue: any,
  typeName: string,
  layerName: string = ""
): any {
  // 既存店レイヤー
  if (layerValue === existingSejStoreLayer && typeName === "geojson") {
    return existingSejStoreData;
  }
  if (layerValue === cvrExistingSejStoreLayer && typeName === "geojson") {
    return existingSejStoreData;
  }
  if (layerValue === existingSejStoreLayer && typeName === "mapStyle") {
    return existingSejStoreLayerMapsStyleSettings;
  }
  if (layerValue === cvrExistingSejStoreLayer && typeName === "mapStyle") {
    return cvrExistingSejStoreLayerMapsStyleSettings;
  }
  if (layerValue === existingSejStoreLayer && typeName === "fsDocumentName") {
    return "existing_sej_stores";
  }
  if (
    layerValue === cvrExistingSejStoreLayer &&
    typeName === "fsDocumentName"
  ) {
    return "existing_sej_stores";
  }
  if (layerValue === existingSejStoreLayer && typeName === "infoWindow") {
    return existingSejStoreInfoWindow;
  }
  // 競合店レイヤー
  if (layerValue === conflictStoreLayer && typeName === "geojson") {
    return conflictStoreData;
  }
  if (layerValue === conflictStoreLayer && typeName === "mapStyle") {
    return conflictStoreLayerMapsStyleSettings;
  }
  if (layerValue === conflictStoreLayer && typeName === "fsDocumentName") {
    return "conflict_stores";
  }
  if (layerValue === conflictStoreLayer && typeName === "infoWindow") {
    return conflictStoreInfoWindow;
  }
  // モバ空レイヤー
  if (layerValue === mobakuLayer && typeName === "geojson") {
    return mobakuData;
  }
  if (layerValue === cvrMobakuLayer && typeName === "geojson") {
    return mobakuData;
  }
  if (layerValue === mobakuLayer && typeName === "mapStyle") {
    return mobakuMapsStyleSettings;
  }
  if (layerValue === cvrMobakuLayer && typeName === "mapStyle") {
    return cvrMobakuMapsStyleSettings;
  }
  if (layerValue === mobakuLayer && typeName === "fsDocumentName") {
    return "mesh_500m";
  }
  if (layerValue === cvrMobakuLayer && typeName === "fsDocumentName") {
    return "mesh_500m";
  }
  if (layerValue === mobakuLayer && typeName === "infoWindow") {
    return mobakuLayerInfoWindow;
  }
  if (layerValue === cvrMobakuLayer && typeName === "infoWindow") {
    return cvrMobakuLayerInfoWindow;
  }
}
