// @ts-nocheck
import React, { FC } from "react";
import { createMuiTheme } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CalendarToday from "@material-ui/icons/CalendarToday";
import { DateTime } from "luxon";
import luxonUtils from "@date-io/luxon";

let color = "orange";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: "20px",
    },
    letter: {
      color: "black",
      "&::before": {
        borderBottom: "1px solid black",
      },
    },
  })
);

const StylesDateUi = withStyles({
  root: {
    color: "black",
  },
})(CalendarToday);

class ExtendsDateTime extends luxonUtils {
  weekDayArray: string[] = [];
  constructor() {
    super();
    this.weekDayArray = ["月", "火", "水", "木", "金", "土", "日"];
  }
  //日付ピッカーツールバーの年表示機能
  getYearText(datetime: DateTime) {
    return this.format(datetime, "yyyy年");
  }
  //日付ピッカーツールバーの月日表示機能
  getDatePickerHeaderText(datetime: DateTime) {
    const dayOfWeek = datetime.weekday;
    return this.format(
      datetime,
      "M月d日(" + this.weekDayArray[dayOfWeek - 1] + ")"
    );
  }
  //日付ピッカー日付カレンダー内ヘッダー表示機能
  getCalendarHeaderText(datetime: DateTime) {
    return this.format(datetime, "yyyy年 M月");
  }
  //日付ピッカーカレンダー内曜日表示機能
  getWeekdays() {
    return this.weekDayArray;
  }
}

const DatePicker: FC<{
  selectedDate: DateTime | null;
  dateFunc: any;
  format?: any;
  maxDate?: any;
  minDate?: any;
  backgroundColor?: string;
  mainColor?: string;
  width?: any
}> = ({
  selectedDate,
  dateFunc,
  format,
  maxDate,
  minDate,
  backgroundColor,
  mainColor,
  width
}) => {
  const classes = useStyles();
  if (mainColor) {
    color = mainColor;
  } else {
    color = "orange";
  }

  const materialTheme = createMuiTheme({
    overrides: {
      MuiInput: {
        underline: {
          "&:after": {
            borderBottom: "0px solid",
          },
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: color,
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: color,
          "&:hover": {
            backgroundColor: color,
          },
        },
        dayDisabled: {
          color: "lightgray",
        },
        current: {
          color: color,
        },
      },
      MuiPickersYear: {
        root: {
          "&:active": {
            color: color,
          },
        },
        yearSelected: {
          color: color,
          "&:active": {
            color: color,
          },
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: color,
        },
      },
      MuiButton: {
        textPrimary: {
          color: color,
        },
      },
    },
  });

  if (!format) {
    format = "yyyy/MM/dd";
  }
  if (!minDate) {
    minDate = DateTime.fromFormat("20210701", "yyyyMMdd");
  }
  if (!maxDate) {
    DateTime.fromFormat(DateTime.local().toString(), "yyyyMMdd");
  }
  return (
    <div>
      <MuiPickersUtilsProvider libInstance={DateTime} utils={ExtendsDateTime}>
        <MuiThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            format={format}
            value={selectedDate}
            style={width ? { width: width } : {}}
            onChange={(date) => dateFunc(date)}
            TextFieldComponent={(props) => (
              <TextField
                color="red"
                style={{ backgroundColor: backgroundColor || "" }}
                {...props}
              />
            )}
            keyboardIcon={<StylesDateUi />}
            cancelLabel="閉じる"
            okLabel="決定"
            placeholder="YYYY/MM/DD"
            className={classes.root}
            minDate={minDate}
            maxDate={maxDate}
            //disableFuture={true}
            minDateMessage={"入力された日付は選択できません"}
            maxDateMessage={"入力された日付は選択できません"}
            invalidDateMessage={"無効な日付の形式です"}
          />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;
