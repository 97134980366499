import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Header, {
  getFirstFlg,
  getFixedFlg,
  getInitTenpoCode,
  setFirstFlg,
} from "../../Header/Header";
import TenpoCheckTab from "./TenpoCheckTab";
import { getTenpoFirestoreData } from "./TenpoCheckTableDataOperation";
import { Redirect } from "react-router-dom";
import { isFirebaseAuth } from "../../../firebase";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { isOpenRegistPage } from "../../../apis/privilege";
import { postRequest } from "../../../apis/axiosAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRoot: {
      flexGrow: 1,
    },
    header: {
      flexGrow: 1,
    },
    headerToolbar: {
      // ヘッダーの縦の調整はminHeight を再設定する
      [theme.breakpoints.down("sm")]: {
        minHeight: "36px",
      },
      [theme.breakpoints.up("md")]: {
        minHeight: "48px",
      },
    },
    root: {
      height: "100vh",
    },
    gridMargin: {
      padding: "1%",
    },
  })
);

// 対象店舗確認ID
let initTenpoCheckId: string = "";
let initLastTenpoCheckId: string = "";
/** データセット */
export function setTenpoCheckId(id: any) {
  initTenpoCheckId = id;
}
export function getTenpoCheckId() {
  return initTenpoCheckId;
}
/** データセット */
export function setLastTenpoCheckId(id: any) {
  initLastTenpoCheckId = id;
}
export function getLastTenpoCheckId() {
  return initLastTenpoCheckId;
}

const TenpoCheck: React.FC = (props: any) => {
  const classes = useStyles();

  const [tenpoCode, setTenpoCode] = React.useState(getInitTenpoCode());
  const [tenpoCheckId, setTenpoCheckId] = React.useState("");
  const [lastTenpoCheckId, setLastTenpoCheckId] = React.useState("");
  const tenpoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTenpoCode(event.target.value as string);
    setTenpoCheckId("");
    setLastTenpoCheckId("");
  };
  const [updateFlg, setUpdateFlg] = React.useState("");
  const user = useSelector(selectUser);

  useEffect(() => {}, []);
  useEffect(() => {
    if (tenpoCode === "" || getFirstFlg()) {
      setFirstFlg(false);
      setTenpoCode("");
      setTenpoCheckId("");
      setLastTenpoCheckId("");
      return;
    }
    if (initTenpoCheckId === "") {
      // 次回の店舗確認表のデータを作成
      const apiParams = {
        tenpoCode: tenpoCode,
        jobCategory: user.jobCategory,
      };
      const url = process.env.REACT_APP_GAE_API_URL + "tenpocheck/check-info";
      postRequest(url, apiParams)
        .then(async (res: any) => {
          setTenpoCheckId(res.data.dataList[0]);
          setLastTenpoCheckId(res.data.dataList[1]);
        });

    } else {
      setTenpoCheckId(initTenpoCheckId);
      setLastTenpoCheckId(initLastTenpoCheckId);
    }
    setFirstFlg(false);
  }, [tenpoCode, updateFlg, getFirstFlg()]);

  // 画面表示可否
  if (!isFirebaseAuth()) {
    return <></>;
  }
  if (
    (!isOpenRegistPage(user) && !getFixedFlg()) ||
    (tenpoCode === "" && getFixedFlg())
  ) {
    return <Redirect to="/home" />;
  }

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item xs={12}>
        <Header titleName={"店舗確認表"} tenpoChange={(e) => tenpoChange(e)} />
        <Grid item xs={12} className={classes.gridMargin}>
          {tenpoCode === "" ? (
            <Grid container justify="center">
              右上の店舗名より新規作成する店舗を選択してください。
            </Grid>
          ) : (
            <TenpoCheckTab
              tenpoCode={tenpoCode}
              tenpoCheckId={tenpoCheckId}
              lastTenpoCheckId={lastTenpoCheckId}
              setUpdateFlg={setUpdateFlg}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TenpoCheck;
