import React, { FC } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { selectImageToken } from "../../../features/userSlice";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Avatar } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { selectExclusiveFlg } from "../../../features/marketAreaStatusSlice";
import { userLogging } from "../../../apis/userLog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      right: "10%",
      width: "75%",
      height: "95%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 2, 1),
      overflowY: "scroll",
      "&:focus": {
        outline: "none",
      },
    },
    infoButton: {
      cursor: "pointer",
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "20px",
      width: "20px",
      padding: "10px",
    },
    closeButton: {
      position: "fixed",
      right: "12%",
      "&:hover": {
        backgroundColor: "lightgray",
      },
    },
  })
);

const MobakuManual: FC<{}> = () => {
  const classes = useStyles();
  const exclusiveFlg = useSelector(selectExclusiveFlg);
  const [open, setOpen] = React.useState(false);
  const imageToken = useSelector(selectImageToken);
  const tempFilePath = "opview-manual/general/manual";
  const text = "OPVIEWの利用マニュアルを表示する";
  const slideNum = 72;
  let imgFileList: string[] = [];
  for (let i = 1; i <= slideNum; i++) {
    imgFileList.push(
      process.env.REACT_APP_CLOUD_STORAGE_URL +
        tempFilePath +
        String(i) +
        ".webp" +
        "?access_token=" +
        imageToken
    );
  }

  const handleOpen = () => {
    if (tempFilePath) {
      setOpen(true);
      userLogging("OPVIEW", "OPVIEW＃iボタン", "");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {imgFileList && imgFileList.length > 0 ? (
        <Tooltip
          title={<span style={{ fontSize: "16px" }}>{text}</span>}
          placement="left"
          disableTouchListener
        >
          <Avatar
            className={classes.infoButton + " map-item-shadow"}
            style={exclusiveFlg ? { pointerEvents: "none" } : {}}
            onClick={() => handleOpen()}
            alt=""
            src={`${window.location.origin}/image/icons/info_icon.png`}
          />
        </Tooltip>
      ) : (
        ""
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ height: "95vh" }}
      >
        <div className={classes.paper}>
          <div>
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                setOpen(false);
              }}
              size={"small"}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <h3 id="simple-modal-title"></h3>
          {imgFileList
            ? imgFileList.map((res: any, index: number) => (
                <div key={index}>
                  <img alt="" src={res} width="100%" height="100%" />
                </div>
              ))
            : ""}
        </div>
      </Modal>
    </>
  );
};
export default MobakuManual;
