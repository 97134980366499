import React, { FC } from "react";
import { useSelector } from "react-redux";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Modal,
  Grid,
  TextareaAutosize,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { selectImageToken } from "../../../features/userSlice";
import { getFirestoreDocData } from "../../../apis/firestoreAction";
import { TENPO_CHECK_RECORD } from "../Register/TenpoCheckTableDataOperation";
import { commentReason } from "../Register/TenpoCheckResultPrint";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPane: {
      position: "absolute",
      paddingTop: "2%",
      paddingLeft: "5%",
      maxWidth: "600px",
      height: "80%",
    },
    paper: {
      // height: "100%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    titleGrid: {
      height: "7vh",
      color: "white",
      backgroundColor: "#4dc0b2",
    },
    titleTypography: {
      fontWeight: "bold",
      fontSize: "15px",
    },
    textCommentArea: {
      width: "220px",
      // iPad縦
      "@media (min-width: 830px)": {
        width: "200px",
      },
      // iPad横
      "@media (min-width: 1100px)": {
        width: "200px",
      },
      // GWPC
      "@media (min-width: 1200px)": {
        width: "220px",
      },
    },
  })
);

const TenpoCheckManagerAnalysisNgButton: FC<{
  id: string;
  bigItemId: string;
  smallItemId: string;
}> = ({ id, bigItemId, smallItemId }) => {
  const classes = useStyles();
  const [modalComment, setModalComment] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [filePath, setFilePath] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 項目名クリック
  const clickCloseIcon = (id: string) => {
    if (!id) {
      return;
    }
    getTenpoCheckData(id).then((res: any) => {
      setModalComment(res.comment);
      setFilePath(res.photo);
      setTitle(res.title);
      handleOpen();
    });
  };
  const getTenpoCheckData = async (id: string) => {
    let baseItemValues: any = await getFirestoreDocData(
      "tenpo_check_records",
      id
    );
    let recordKey = "records-" + bigItemId + "-" + smallItemId;
    let photo: any[] = [];
    let comment = "";
    let title = "";
    if (baseItemValues) {
      const baseItemValue: any = baseItemValues;
      Object.keys(baseItemValue.records)
        .sort()
        .forEach(function (key) {
          if (key === recordKey) {
            let record: TENPO_CHECK_RECORD = baseItemValue.records[key];
            comment += commentReason(record);
            if (comment) {
              comment += "\n";
            }
            comment += record.user_input_comment;
            photo = record.photo_filepath_gcs;
            title = record.small_title;
          }
        });
    }
    return { photo, comment, title };
  };

  const imageToken = useSelector(selectImageToken);
  function getImageFile(imagePath: string) {
    if (imagePath === undefined || imagePath === "") {
      return `${window.location.origin}/image/noimage.png`;
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      imagePath +
      "?access_token=" +
      imageToken
    );
  }

  return (
    <>
      <IconButton
        onClick={(e) => {
          clickCloseIcon(id);
        }}
      >
        <CloseIcon style={{ fontSize: "16px" }} />
      </IconButton>
      {/*  モーダルここから↓*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalPane}
      >
        <Grid container className={classes.paper} style={{ overflowY: "auto" }}>
          {/*  タイトルここから↓*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.titleGrid}
          >
            <Typography className={classes.titleTypography}>{title}</Typography>
          </Grid>
          <Grid
            container
            style={{
              marginBottom: "5px",
              display: "flex",
              alignSelf: "flex-start",
            }}
          >
            {/*  写真ここから↓*/}
            <Grid item>
              <Grid container justify="center" style={{ paddingTop: "10px" }}>
                <img
                  alt=""
                  src={getImageFile(filePath[0])}
                  height="200"
                  style={{
                    width: "200px",
                    WebkitAppearance: "none",
                  }}
                />
              </Grid>
            </Grid>
            {/*  写真ここまで↑*/}
            {/*  その他コメントここから↓*/}
            <Grid item>
              <TextareaAutosize
                className={classes.textCommentArea}
                rowsMax={16}
                aria-label="store-input-comment-area"
                rowsMin={16}
                maxLength={4000}
                placeholder={"その他コメント"}
                value={modalComment}
                style={{
                  height: "190px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  margin: "10px",
                }}
              />
            </Grid>
            {/*  その他コメントここまで↑*/}
          </Grid>
        </Grid>
      </Modal>
      {/*  モーダル↑ここまで*/}
    </>
  );
};
export default TenpoCheckManagerAnalysisNgButton;
