import React, { FC, useRef, forwardRef } from "react";
import Encoding from "encoding-japanese";
import { useSelector } from "react-redux";
import { selectIsDeviceType } from "../../../features/userSlice";
import MaterialTable from "material-table";
import { localizationJapanese } from "../../common/MatelialTableLocalizationJapanese";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { userLogging } from "../../../apis/userLog";
import { Grid, TablePagination } from "@material-ui/core";

const TSCheckFilingStatusConfirmResult: FC<{
  aggregationType: boolean;
  data: any[];
  columns: any[];
  defaultSize: number;
  csvColumns: any[];
}> = ({ aggregationType, data, columns, defaultSize, csvColumns }) => {
  // デバイスタイプの取得
  const deviceType = useSelector(selectIsDeviceType);
  const tableRef: any = useRef();

  const customColumns = (baseColumns: any[]) => {
    let resultColumns: any[] = [];
    for (let column of baseColumns) {
      if (column.field === "name") {
        resultColumns.push({
          title: column.title,
          field: column.field,
          cellStyle: column.cellStyle,
          customSort: (a: any, b: any) => {
            if (a.docode > b.docode) {
              return 1;
            } else if (a.docode < b.docode) {
              return -1;
            } else {
              if (a.code > b.code) {
                return 1;
              } else if (a.code < b.code) {
                return -1;
              } else {
                return 0;
              }
            }
          },
        });
      } else {
        resultColumns.push(column);
      }
    }
    return resultColumns;
  };

  const downloadCsv = (data: any, fileName: string) => {
    userLogging("店舗衛生点検表♯提出状況確認#CSVダウンロード", fileName);
    const exportFileName = `${fileName}.csv`;

    let convertData: any = new Uint8Array(
      Encoding.convert(Encoding.stringToCode(data), "SJIS", "UNICODE")
    );
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([convertData], { type: "text/csv" }));
    a.setAttribute("download", exportFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <Grid
      container
      justify="center"
      alignItems="flex-start"
      style={{ width: "100%", height: "80vh" }}
    >
      <MaterialTable
        title="提出状況一覧"
        tableRef={tableRef}
        data={data}
        columns={customColumns(columns)}
        style={{ width: "90%" }}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              defaultValue={defaultSize}
              rowsPerPageOptions={[5, 10, 15]}
            />
          ),
        }}
        icons={{
          Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
          Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Delete: forwardRef((props, ref) => (
            <DeleteOutline {...props} ref={ref} />
          )),
          DetailPanel: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
          )),
          Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
          Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
          Filter: forwardRef((props, ref) => (
            <FilterList {...props} ref={ref} />
          )),
          FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
          )),
          LastPage: forwardRef((props, ref) => (
            <LastPage {...props} ref={ref} />
          )),
          NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
          )),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
          )),
          ResetSearch: forwardRef((props, ref) => (
            <Clear {...props} ref={ref} />
          )),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
          SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
          )),
          ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
          )),
          ViewColumn: forwardRef((props, ref) => (
            <ViewColumn {...props} ref={ref} />
          )),
        }}
        localization={localizationJapanese}
        options={{
          pageSize: defaultSize,
          maxBodyHeight: "65vh",
          padding: "dense",
          draggable: false,
          search: false,
          // 対象外の場合はcsv出力ボタン非表示
          exportButton: !deviceType
            ? {
                csv: true,
                pdf: false,
              }
            : false,
          headerStyle: {
            backgroundColor: "#4dc0b2",
            color: "#ffffff",
            whiteSpace: "nowrap",
            paddingLeft: "14px",
            paddingRight: "14px",
          },
          rowStyle: {
            whiteSpace: "nowrap",
          },
          exportCsv: (columns, data) => {
            const headerRow = csvColumns.map((col) => col.title);
            const dataRows = data.map(({ tableData, ...row }) => {
              let exportRow: any = {};
              for (let key in row) {
                if (key !== "code" && key !== "docode") {
                  exportRow[key] = row[key];
                }
              }
              return Object.values(exportRow);
            });
            const { exportDelimiter } = tableRef.current
              ? tableRef.current.props.options
              : "";
            const delimiter = exportDelimiter ? exportDelimiter : ",";
            const csvContent = [headerRow, ...dataRows]
              .map((e) => e.join(delimiter))
              .join("\n");
            const csvFileName = tableRef.current.props.title
              ? tableRef.current.props.title
              : "filingStatus";

            downloadCsv(csvContent, csvFileName);
          },
        }}
      ></MaterialTable>
    </Grid>
  );
};

export default TSCheckFilingStatusConfirmResult;
