import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  selectSelectingCvrMobakuItem,
  selectSelectingFirstMobakuItem,
  selectSelectingSecondMobakuItem,
  selectSecondCvrItem,
  selectFirstMobakuItem,
  selectSecondMobakuItem,
  updateFirstCvrItem,
  updateSecondCvrItem,
  updateFirstMobakuItem,
  updateSecondMobakuItem,
  updateCvrMobakuName,
  updateFirstMobakuName,
  updateSecondMobakuName,
  updateSelectingCvrMobakuItem,
  updateSelectingFirstMobakuItem,
  updateSelectingSecondMobakuItem,
} from "../../../features/tenpoStatusSlice";
import {
  mobakuLayer,
  cvrMobakuLayer,
  updateFireStoreLayer,
} from "./SelectMobakuMapItems";
import { unselectMarketArea } from "../MarketArea/PolygonDrawing";
import { getFirestoreData } from "../../../apis/firestoreAction";
import { hankaku2Zenkaku } from "../SelectStoreMenu";
import { selectExclusiveMobakuFlg } from "../../../features/marketAreaStatusSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      position: "absolute",
      right: "10%",
      bottom: "6%",
      border: "none",
    },
    button: {
      borderRadius: "10%",
      border: "none",
      margin: "2px",
      height: "40px",
      width: "80px",
      fontWeight: "bold",
      backgroundColor: "#35ab63",
      "&:hover": {
        backgroundColor: "#2e8b57",
      },
    },
  })
);

export let selectingCvrMobakuItemRef: string[] = [];
export let selectingMobakuFirstItemRef: string[] = [];
export let selectingMobakuSecondItemRef: string[] = [];
export let cvrMobakuItemRef: string[] = [];
export let mobakuFirstItemRef: string[] = [];
export let mobakuSecondItemRef: string[] = [];

const MobakuMapsSelectedButton: React.FC<{
  storeOrder: string;
  setOpen: any;
  dispatch: any;
  graphType: string;
}> = ({ storeOrder, setOpen, dispatch, graphType }) => {
  const classes = useStyles({});
  // 地図上で選択している（決定前）モバ空メッシュコード
  const selectingCvrMobakuItem = useSelector(selectSelectingCvrMobakuItem);
  const selectingMobakuFirstItem = useSelector(selectSelectingFirstMobakuItem);
  const selectingMobakuSecondItem = useSelector(
    selectSelectingSecondMobakuItem
  );
  // 選択して決定したモバ空メッシュコード
  const cvrMobakuItem = useSelector(selectSecondCvrItem);
  const mobakuFirstItem = useSelector(selectFirstMobakuItem);
  const mobakuSecondItem = useSelector(selectSecondMobakuItem);
  const exclusiveMobakuFlg = useSelector(selectExclusiveMobakuFlg);
  const [disabled, isDisabled] = React.useState(false);

  // tsファイルで利用するモバ空関連の変数
  useEffect(() => {
    selectingCvrMobakuItemRef = selectingCvrMobakuItem;
    selectingMobakuFirstItemRef = selectingMobakuFirstItem;
    selectingMobakuSecondItemRef = selectingMobakuSecondItem;
    cvrMobakuItemRef = cvrMobakuItem;
    mobakuFirstItemRef = mobakuFirstItem;
    mobakuSecondItemRef = mobakuSecondItem;
  }, [
    selectingCvrMobakuItem,
    selectingMobakuFirstItem,
    selectingMobakuSecondItem,
    cvrMobakuItem,
    mobakuFirstItem,
    mobakuSecondItem,
  ]);

  useEffect(() => {
    let selectCount = 0;
    if (graphType === "cvr") {
      if (selectingCvrMobakuItem[0] === "market-area") {
        selectCount = 1;
      } else {
        selectCount = selectingCvrMobakuItem.length;
      }
    } else if (graphType === "mobaku") {
      if (storeOrder === "first") {
        selectCount = selectingMobakuFirstItem.length;
      } else if (storeOrder === "second") {
        selectCount = selectingMobakuSecondItem.length;
      }
    }
    if (selectCount < 1) {
      isDisabled(true);
    } else {
      isDisabled(false);
    }
  }, [
    selectingCvrMobakuItem,
    selectingMobakuFirstItem,
    selectingMobakuSecondItem,
  ]);

  function selectMobaku() {
    if (graphType === "cvr") {
      dispatch(
        updateSecondCvrItem({
          secondCvrItem: selectingCvrMobakuItemRef,
        })
      );
      const isMarketArea =
        selectingCvrMobakuItemRef[0] === "market-area" ? true : false;
      setMobakuName(
        selectingCvrMobakuItemRef[0],
        graphType,
        storeOrder,
        isMarketArea
      );
    } else if (graphType === "mobaku") {
      if (storeOrder === "first") {
        dispatch(
          updateFirstMobakuItem({
            firstMobakuItem: selectingMobakuFirstItemRef,
          })
        );
        setMobakuName(
          selectingMobakuFirstItemRef[0],
          graphType,
          storeOrder,
          false
        );
      } else if (storeOrder === "second") {
        dispatch(
          updateSecondMobakuItem({
            secondMobakuItem: selectingMobakuSecondItemRef,
          })
        );
        setMobakuName(
          selectingMobakuSecondItemRef[0],
          graphType,
          storeOrder,
          false
        );
      }
    }
    setOpen(false);
  }

  // 選択中のメッシュをクリアしてスタイルを当てなおす
  const resetMobaku = async () => {
    if (graphType === "cvr") {
      if (cvrMobakuItem[0] === "market-area") {
        await unselectMarketArea(dispatch);
      }
      await dispatch(
        updateSelectingCvrMobakuItem({
          selectingCvrMobakuItem: [],
        })
      );
    } else if (graphType === "mobaku") {
      if (storeOrder === "first") {
        await dispatch(
          updateSelectingFirstMobakuItem({
            selectingFirstMobakuItem: [],
          })
        );
      } else if (storeOrder === "second") {
        await dispatch(
          updateSelectingSecondMobakuItem({
            selectingSecondMobakuItem: [],
          })
        );
      }
    }
    const updateLayer = () => {
      if (graphType === "cvr") {
        updateFireStoreLayer(cvrMobakuLayer);
      } else if (graphType === "mobaku") {
        updateFireStoreLayer(mobakuLayer);
      }
    };
    // ipadでレイヤ更新処理がreduxの更新を追い越してしまうため遅延処理
    const sleepTime: number = 100;
    setTimeout(updateLayer, sleepTime);
  };

  const setMobakuName = async (
    meshCode: string,
    graphType: string,
    order: string,
    isMarketArea: boolean
  ) => {
    let mobakuName: string;
    if (order === "first") {
      await getFirestoreData(
        "mesh_500m",
        "attribution.key_code",
        meshCode
      ).then((response: any) => {
        mobakuName = response.attribution.center_address;
        if (mobakuName === undefined || mobakuName === "") {
          mobakuName = hankaku2Zenkaku(meshCode);
        }
        dispatch(
          updateFirstMobakuName({
            firstMobakuName: mobakuName,
          })
        );
      });
    } else {
      if (graphType === "cvr") {
        if (!isMarketArea) {
          await getFirestoreData(
            "mesh_500m",
            "attribution.key_code",
            meshCode
          ).then((response: any) => {
            mobakuName = response.attribution.center_address;
            if (mobakuName === undefined || mobakuName === "") {
              mobakuName = hankaku2Zenkaku(meshCode);
            }
            dispatch(
              updateCvrMobakuName({
                cvrMobakuName: mobakuName,
              })
            );
          });
        } else {
          mobakuName = "店舗の範囲";
          dispatch(
            updateCvrMobakuName({
              cvrMobakuName: mobakuName,
            })
          );
        }
      } else if (graphType === "mobaku") {
        await getFirestoreData(
          "mesh_500m",
          "attribution.key_code",
          meshCode
        ).then((response: any) => {
          mobakuName = response.attribution.center_address;
          if (mobakuName === undefined || mobakuName === "") {
            mobakuName = hankaku2Zenkaku(meshCode);
          }
          dispatch(
            updateSecondMobakuName({
              secondMobakuName: mobakuName,
            })
          );
        });
      }
    }
  };

  return (
    <div className={classes.buttonGroup}>
      <button
        className={classes.button}
        onClick={() => resetMobaku()}
        disabled={disabled || exclusiveMobakuFlg}
        style={{
          color:
            disabled || exclusiveMobakuFlg ? "rgba(16, 16, 16, 0.3)" : "white",
          cursor: disabled || exclusiveMobakuFlg ? "" : "pointer",
        }}
      >
        クリア
      </button>
      <button
        className={classes.button}
        onClick={() => selectMobaku()}
        disabled={disabled || exclusiveMobakuFlg}
        style={{
          color:
            disabled || exclusiveMobakuFlg ? "rgba(16, 16, 16, 0.3)" : "white",
          cursor: disabled || exclusiveMobakuFlg ? "" : "pointer",
        }}
      >
        決定
      </button>
    </div>
  );
};

export default MobakuMapsSelectedButton;
