import {
  TENPO_CHECK_ADD_BIG_RECORD,
  TENPO_CHECK_BIG_RECORD,
} from "./TenpoCheckTableDataOperation";

// scoreの計算とリストへの格納
export const updateScore = async (
  rowItemList: TENPO_CHECK_BIG_RECORD[],
  addRowItemList: TENPO_CHECK_ADD_BIG_RECORD[]
) => {
  let circleCount = 0;
  let totalCount = 0;
  let score = 0;
  let totalScoreFew = 0;
  // ボタンのカウント
  for (let i = 0; i < rowItemList.length; i++) {
    for (let j = 0; j < rowItemList[i].rows.length; j++) {
      if (
        rowItemList[i].rows[j].button_value === "1" ||
        rowItemList[i].rows[j].button_value === "2"
      ) {
        ++totalCount;
      }

      if (rowItemList[i].rows[j].button_value === "1") {
        ++circleCount;
      }
    }
  }

  // 計算
  if (totalCount !== 0) {
    // 1項目の得点
    score = Math.round(100 / totalCount);
    const base = 0.001; // 小数点第三位で四捨五入
    let totalScore =
      Math.round((circleCount * (100 / totalCount)) / base) * base;
    // 小数点第三位以下が出ることがあるので以下を切り捨てる
    totalScoreFew = Math.floor(totalScore * Math.pow(10, 3)) / Math.pow(10, 3);
  }
  // リストへの格納
  for (let i = 0; i < rowItemList.length; i++) {
    for (let j = 0; j < rowItemList[i].rows.length; j++) {
      if (rowItemList[i].rows[j].button_value === "1") {
        rowItemList[i].rows[j].score = String(score);
      } else if (rowItemList[i].rows[j].button_value === "2") {
        rowItemList[i].rows[j].score = "0";
      } else {
        rowItemList[i].rows[j].score = "-1";
      }
    }
  }

  for (let i = 0; i < addRowItemList[0].rows.length; i++) {
    if (addRowItemList[0].rows[i].button_value === "1") {
      addRowItemList[0].rows[i].score = String(score);
    } else if (addRowItemList[0].rows[i].button_value === "2") {
        addRowItemList[0].rows[i].score = "0";
    } else {
      addRowItemList[0].rows[i].score = "-1";
    }
  }
  // 合計得点を返す
  return totalScoreFew;
};

// 合計得点の計算
export const calculationTotalScore = (
  rowItemList: TENPO_CHECK_BIG_RECORD[],
  addRowItemList: TENPO_CHECK_ADD_BIG_RECORD[]
) => {
  let circleCount = 0;
  let totalCount = 0;
  let totalScoreFew = 0;
  // ボタンのカウント
  for (let i = 0; i < rowItemList.length; i++) {
    for (let j = 0; j < rowItemList[i].rows.length; j++) {
      if (
        rowItemList[i].rows[j].button_value === "1" ||
        rowItemList[i].rows[j].button_value === "2"
      ) {
        ++totalCount;
      }

      if (rowItemList[i].rows[j].button_value === "1") {
        ++circleCount;
      }
    }
  }

  // 計算
  if (totalCount !== 0) {
    const base = 0.1; // 小数点第二位で四捨五入
    let totalScore =
      Math.round((circleCount * (100 / totalCount)) / base) * base;
    // 小数点第二位以下が出ることがあるので以下を切り捨てる
    totalScoreFew = Math.floor(totalScore * Math.pow(10, 3)) / Math.pow(10, 3);
  }
  // 合計得点を返す
  return totalScoreFew;
};
