/**
 * 小数点第一位で四捨五入した値を返す
 *  */
export function roundScore(targetNum: any) {
  let targeNumber = Number(targetNum);
  if (!targeNumber) {
    return targetNum;
  }
  const base = 0.1; // 小数点第二位で四捨五入
  let roundScore = Math.round(targetNum / base) * base;
  // 小数点第二位以下を切り捨て
  return Math.floor(roundScore * Math.pow(10, 3)) / Math.pow(10, 3);
}
