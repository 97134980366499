import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Modal,
  Tooltip,
  Typography,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { isTrainingUser } from "../../apis/privilege";
import { postRequest } from "../../apis/axiosAction";
import firebase from "firebase/app";
import "firebase/storage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
    paper: {
      position: "fixed",
      inset: 0,
      margin: "auto",
      width: "680px",
      height: "150px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 2, 1),
      "&:focus": {
        outline: "none",
      },
      borderRadius: "5px",
    },
    header: {
      borderBottom: "1px solid #dee2e6",
      padding: "8px",
      display: "flex",
    },
    formGroup: {
      padding: "8px",
    },
    inputFileName: {
      padding: "5px",
      width: "450px",
      height: "20px",
    },
    selectFileButton: {
      margin: "0 30px",
      cursor: "pointer",
      width: "130px",
      height: "30px",
      padding: "8px",
      backgroundColor: "#00cc5b",
      border: "none",
      borderRadius: "5px",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#33d67c",
      },
    },
    uploadButton: {
      width: "120px",
      height: "40px",
      padding: "8px",
      backgroundColor: "#00cc5b",
      border: "none",
      borderRadius: "5px",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#33d67c",
      },
    },
    disabledUploadButton: {
      width: "120px",
      height: "40px",
      padding: "8px",
      color: "#FFF",
      background: "#AAA",
    },
    closeButton: {
      margin: "0 0 0 auto",
      "&:hover": {
        backgroundColor: "lightgray",
      },
    },
    dialogCloseButton: {
      backgroundColor: "#00cc5b",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#33d67c",
      },
    },
  })
);

const CsvUploadButton: React.FC = () => {
  const classes = useStyles();
  const user: any = useSelector(selectUser);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<FileList | null>(null);
  const [fileName, setfileName] = React.useState("");
  const [dialog, setDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [isUploaded, setIsUploaded] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setfileName("");
  };
  const handleOpenDialog = (message: string) => {
    setDialogMessage(message);
    setDialog(true);
  };
  const handleCloseDialog = () => {
    setDialog(false);
    if (isUploaded) {
      // ファイルアップロード完了済みの場合はモーダルを閉じる
      handleClose();
    }
  };
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploaded(false);
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files);
      setfileName(files[0].name);
    } else {
      setFile(null);
      setfileName("");
    }
  };
  // csvのアップロード後にBigQueryへの取り込みAPIを呼ぶ
  const callDataTransportApi = async (filePath: string) => {
    const api_url: string = process.env.REACT_APP_FC_TRAINING_API!;
    const param = {
      type_name: "fc_basic_training_master",
      file_path: filePath,
    };
    await postRequest(api_url, param);
  };
  const uploadStorage = (uploadFileList: FileList | null) => {
    if (!uploadFileList || uploadFileList.length === 0) {
      return;
    }

    // FC基礎トレーニング_OPVIEW権限セットアップシート_yyyymmdd
    const matchName =
      /^FC基礎トレーニング_OPVIEW権限セットアップシート_\d{8}\.csv$/;

    let fileObject: any = {};

    // ファイル名検査
    for (let i = 0; i < uploadFileList.length; i++) {
      let inputFileName = uploadFileList[i].name;
      if (inputFileName.match(matchName)) {
        fileObject[i] = uploadFileList[i];
      } else {
        const message = "ファイル名の形式が正しくありません。";
        handleOpenDialog(message);
        return;
      }
    }
    // ファイル名を生成
    const date = new Date();
    const uploadFileName =
      fileObject[0].name.slice(0, -4) +
      date.getHours().toString().padStart(2, "0") +
      date.getMinutes().toString().padStart(2, "0") +
      date.getSeconds().toString().padStart(2, "0") +
      ".csv";
    const uploadFilePath = "fc-basic-training-master/" + uploadFileName;

    let storageRef = firebase.storage().ref();
    const uploadFunc = async () => {
      for (let key in fileObject) {
        let uploadRef = storageRef.child(uploadFilePath);
        // ファイルアップロード
        await uploadRef
          .put(fileObject[key])
          .then((snapshot) => {
            setIsUploaded(true);
            const finishMsg = "ファイルアップロードが完了しました。";
            handleOpenDialog(finishMsg);
            callDataTransportApi(uploadFilePath);
          })
          .catch((error) => {
            console.log(error);
            const errorMsg = "ファイルアップロードに失敗しました。";
            handleOpenDialog(errorMsg);
          });
      }
    };
    uploadFunc();
  };
  return (
    <>
      {user.jobCategory === "admin-group" || isTrainingUser(user) ? (
        <>
          <Tooltip
            title={
              <span style={{ fontSize: "16px" }}>
                {"CSVファイルをアップロードする"}
              </span>
            }
            placement="left"
            disableTouchListener
          >
            <IconButton
              className={classes.button}
              onClick={() => handleOpen()}
              size={"small"}
            >
              <UploadFileIcon />
            </IconButton>
          </Tooltip>
          <Modal open={open} onClose={handleClose}>
            <div className={classes.paper}>
              <div className={classes.header}>
                <Typography variant="h5">CSVファイルアップロード</Typography>
                <IconButton
                  className={classes.closeButton}
                  onClick={() => handleClose()}
                  size={"small"}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.formGroup}>
                <input
                  type="text"
                  value={fileName}
                  className={classes.inputFileName}
                  disabled={true}
                />
                <label className={classes.selectFileButton}>
                  <input
                    type="file"
                    onChange={onChangeFile}
                    style={{ display: "none" }}
                  />
                  ファイルを選択
                </label>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  className={
                    file === null
                      ? classes.disabledUploadButton
                      : classes.uploadButton
                  }
                  onClick={() => uploadStorage(file)}
                  disabled={file === null}
                >
                  アップロード
                </Button>
              </div>
            </div>
          </Modal>
          <Dialog open={dialog}>
            <DialogContent>{dialogMessage}</DialogContent>
            <DialogActions>
              <Button
                className={classes.dialogCloseButton}
                onClick={handleCloseDialog}
              >
                閉じる
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CsvUploadButton;
