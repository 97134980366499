import React, { useEffect, FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { DateTime } from "luxon";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import { Link } from "@material-ui/core";
import { postRequest } from "../../../apis/axiosAction";
import OneVisionDialog from "../OneVisionDialog";
import { userLogging } from "../../../apis/userLog";
import OneVisionStoreScoreDataButton from "./OneVisionStoreScoreDataButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "60vh",
    width: "90%",
    margin: "auto",
    backgroundColor: "#ffffff",
  },

  dataTable1: {
    width: "400px",
  },
  dataTable2: {
    width: "400px",
  },
  dataTableHead: {
    backgroundColor: "#e6e6fa",
    border: "5px",
    padding: "0 0",
  },

  dataTableHeadCell: {
    textAlign: "center",
    border: "1px solid #aaa",
    padding: "0 0",
    fontSize: "12px",
  },
  dataTableBody: {
    backgroundColor: "#ffffff",
  },
  dataTableCell: {
    textAlign: "right",
    border: "1px solid #aaa",
    paddingTop: "0",
    paddingBottom: "0",
    fontSize: "12px",
  },
  graphTable: {
    width: "300px",
    backgroundColor: "#ffffff",
    border: "none",
  },
}));

interface LinkTabProps {
  label?: string;
  href?: string;
}
// グラフの色リスト
const fillCodeList = ["#8884d8", "#82ca9d", "skyblue", "coral", "peru", "plum"];

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const tableHeaderList1 = ["1w", "2w", "3w", "4w", "5w"];
const tableHeaderList2 = [
  "店内",
  "店外・バックヤード事務所",
  "酒類不当販売・法定表示物・免許品",
  "設備・什器",
  "フレンドリー",
  "感染症対策",
];

let dialogMessage: any[] = [];
let dialogFlg = false;
const dialogClose = () => {
  dialogFlg = false;
};

const getData = (
  analysis: string,
  zoCode: string,
  doCode: string,
  target_date: DateTime,
  ofc_code: string,
  tenpo_code: string
) => {
  userLogging("OneVision", "管理者分析#データ取得条件", 
  "タブNo.[" + analysis + 
  "別分析] ZO[" + zoCode + 
  "] DO[" + doCode + 
  "] 基準日[" + target_date +
  "] OFC[" + ofc_code + 
  "] 店舗[" + tenpo_code + "]");
const apiParams = {
    "api-name": "analysis",
    target_date: target_date.toFormat("yyyy/MM/dd HH:mm:ss"),
    zo_code: zoCode,
    do_code: doCode,
    ofc_code: ofc_code,
    tenpo_code: tenpo_code,
    analysis: analysis,
  };

  const url = process.env.REACT_APP_GAE_API_URL + "onevision/analysis";
  return postRequest(url, apiParams)
    .then((res) => {
      let dataList: any[] = res.data.dataList;
      userLogging("OneVision", "管理者分析#データ取得結果", JSON.stringify(dataList));
      if (dataList) {
        for (let i = 0; i < dataList.length; i++) {
          let datajsList: any = dataList[i];
          let averageScoreWeek: any[] = datajsList.average_score_week;
          let averageScoreBreakdown: any[] = datajsList.average_score_breakdown;
          //TODOヘッダーリストの変更
          // let tableHeaderList1 = dataList.headerList;
          // let tableHeaderList2 = dataList.headerList2;

          let weekList = [];
          let breakdownList1 = [];
          let breakdownList2 = [];
          for (let j = 0; j < averageScoreWeek.length; j++) {
            let name = averageScoreWeek[j].name;
            let code = averageScoreWeek[j].code;
            let resMap: any = {
              name,
              code,
            };
            resMap[tableHeaderList1[0]] = averageScoreWeek[j].score1;
            resMap[tableHeaderList1[1]] = averageScoreWeek[j].score2;
            resMap[tableHeaderList1[2]] = averageScoreWeek[j].score3;
            resMap[tableHeaderList1[3]] = averageScoreWeek[j].score4;
            resMap[tableHeaderList1[4]] = averageScoreWeek[j].score5;

            weekList.push(resMap);
          }

          for (let j = 0; j < averageScoreBreakdown.length; j++) {
            let name = averageScoreBreakdown[j].name;
            let code = averageScoreBreakdown[j].code;
            let result1 = averageScoreBreakdown[j].result1;
            let result2 = averageScoreBreakdown[j].result2;
            let data3: any = {
              name,
              code,
            };
            data3[tableHeaderList2[0]] = result1.score1;
            data3[tableHeaderList2[1]] = result1.score2;
            data3[tableHeaderList2[2]] = result1.score3;
            data3[tableHeaderList2[3]] = result1.score4;
            data3[tableHeaderList2[4]] = result1.score5;
            data3[tableHeaderList2[5]] = result1.score6;

            breakdownList1.push(data3);
            let data4: any = {
              name,
              code,
            };
            data4[tableHeaderList2[0]] = result2.score1;
            data4[tableHeaderList2[1]] = result2.score2;
            data4[tableHeaderList2[2]] = result2.score3;
            data4[tableHeaderList2[3]] = result2.score4;
            data4[tableHeaderList2[4]] = result2.score5;
            data4[tableHeaderList2[5]] = result2.score6;
            breakdownList2.push(data4);
          }
          return { weekList, breakdownList1, breakdownList2 };
        }
      }
    })
    .catch((r) => {
      dialogMessage = [String(r)];
      dialogFlg = true;
    })
    .finally(() => {});
};

function subStrNum(a: string, b: string) {
  return Number(a) - Number(b);
}
const getTableData = async (
  analysis: string,
  zoCode: string,
  doCode: string,
  target_date: DateTime,
  ofc_code: string,
  tenpo_code: string
) => {
  let resultMap: any = {};
  let result = await getData(
    analysis,
    zoCode,
    doCode,
    target_date,
    ofc_code,
    tenpo_code
  );
  //TODO先月日付
  let lastTargetDate: DateTime = target_date;
  let lastResult = await getData(
    analysis,
    zoCode,
    doCode,
    lastTargetDate,
    ofc_code,
    tenpo_code
  );
  resultMap = result;
  let dataList1: any[] = [];
  let dataList2: any[] = [];
  let dataList3: any[] = [];
  let dataList4: any[] = [];
  if (lastResult && result) {
    dataList1 = result.weekList;
    dataList3 = result.breakdownList1;
    let lastDataList: any[] = lastResult.weekList;
    let bdList2: any[] = result.breakdownList2;
    for (let i = 0; i < lastDataList.length; i++) {
      for (let j = 0; j < dataList1.length; j++) {
        if (lastDataList[i].code === dataList1[j].code) {
          let name = lastDataList[j].name;
          let code = lastDataList[j].code;
          let resMap: any = {
            name,
            code,
          };

          for (let k = 0; k < tableHeaderList1.length; k++) {
            if (k === 0) {
              let score = subStrNum(
                dataList1[j][tableHeaderList1[k]],
                lastDataList[i][tableHeaderList1[tableHeaderList1.length - 1]]
              );
              resMap[tableHeaderList1[k]] = score;
            } else {
              let score = subStrNum(
                dataList1[j][tableHeaderList1[k]],
                dataList1[j][tableHeaderList1[k - 1]]
              );
              resMap[tableHeaderList1[k]] = score;
            }
          }
          dataList2.push(resMap);
        }
      }
    }

    for (let i = 0; i < dataList3.length; i++) {
      for (let j = 0; j < bdList2.length; j++) {
        if (dataList3[i].code === bdList2[j].code) {
          let name = dataList3[j].name;
          let code = dataList3[j].code;
          let resMap: any = {
            name,
            code,
          };
          for (let k = 0; k < tableHeaderList2.length; k++) {
            let score = subStrNum(
              bdList2[j][tableHeaderList2[k]],
              dataList3[i][tableHeaderList2[k]]
            );
            resMap[tableHeaderList2[k]] = score;
          }

          dataList4.push(resMap);
        }
      }
    }
  }
  resultMap["dataList1"] = dataList1;
  resultMap["dataList2"] = dataList2;
  resultMap["dataList3"] = dataList3;
  resultMap["dataList4"] = dataList4;

  return resultMap;
};
// 検索結果
const AnalysisResult: FC<{
  zoCode: string;
  doCode: string;
  baseData: DateTime | null;
}> = ({ zoCode, doCode, baseData }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const [targetData1, setTargetData1] = React.useState<any[]>();
  const [targetData2, setTargetData2] = React.useState<any[]>();
  const [targetData3, setTargetData3] = React.useState<any[]>();
  const [targetData4, setTargetData4] = React.useState<any[]>();

  const [workDoCode, setWorkDoCode] = React.useState(doCode);
  const [ofcCode, setOfcCode] = React.useState("");
  const [tenpoCode, setTenpoCode] = React.useState("");

  let title1 = "【ZO別平均得点率】";
  let title2 = "【ZO別平均得点率の改善度】";
  let title3 = "【ZO別項目別平均得点率】";
  let title4 = "【ZO別項目別平均得点率の改善度】";
  let headerName = "ZO";
  let graphItemList1: { header: string; fill: string }[] = [];
  for (let i = 0; i < tableHeaderList1.length; i++) {
    graphItemList1.push({ header: tableHeaderList1[i], fill: fillCodeList[i] });
  }
  let graphItemList2: { header: string; fill: string }[] = [];
  for (let i = 0; i < tableHeaderList2.length; i++) {
    graphItemList2.push({ header: tableHeaderList2[i], fill: fillCodeList[i] });
  }

  useEffect(() => {
    getTableData(
      String(value),
      zoCode,
      workDoCode,
      baseData || DateTime.local(),
      ofcCode,
      tenpoCode
    ).then((res: any) => {
      let responseMap: any = res;
      setTargetData1(responseMap.dataList1);
      setTargetData2(responseMap.dataList2);
      setTargetData3(responseMap.dataList3);
      setTargetData4(responseMap.dataList4);
    });
  }, [value, zoCode, workDoCode, ofcCode, baseData, tenpoCode]);

  // 項目名クリック
  const moveClick = (code: string) => {
    if (value === 0) {
      setWorkDoCode(code);
      setValue(1);
    }
    if (value === 1) {
      setOfcCode(code);
      setValue(2);
    }
    if (value === 2) {
      setTenpoCode(code);
      setValue(3);
    }
  };

  // タブ内表示
  function tableResult(num: number) {
    if (num === 1) {
      title1 = "【DO別平均得点率】";
      title2 = "【DO別平均得点率の改善度】";
      title3 = "【DO別項目別平均得点率】";
      title4 = "【DO別項目別平均得点率の改善度】";
      headerName = "DO";
    }
    if (num === 2) {
      title1 = "【OFC別平均得点率】";
      title2 = "【OFC別平均得点率の改善度】";
      title3 = "【OFC別項目別平均得点率】";
      title4 = "【OFC別項目別平均得点率の改善度】";
      headerName = "OFC";
    }
    if (num === 3) {
      title1 = "【個店別平均得点率】";
      title2 = "【個店別平均得点率の改善度】";
      title3 = "【個店別項目別平均得点率】";
      title4 = "【個店別項目別平均得点率の改善度】";
      headerName = "個店";
    }

    return (
      <>
        <Table style={{ width: "75vw" }}>
          
        <OneVisionStoreScoreDataButton />
          <TableBody>
            <TableRow>
              {targetData1 ? (
                <>
                  <TableCell
                    style={{ verticalAlign: "top", textAlign: "start" }}
                  >
                    {title1}
                    <Table className={classes.dataTable1}>
                      <TableHead className={classes.dataTableHead}>
                        <TableCell className={classes.dataTableHeadCell}>
                          {headerName}
                        </TableCell>
                        {tableHeaderList1.map((row) => (
                          <TableCell className={classes.dataTableHeadCell}>
                            {row}
                          </TableCell>
                        ))}
                      </TableHead>
                      <TableBody className={classes.dataTableBody}>
                        {targetData1.map((row) => (
                          <TableRow>
                            <TableCell className={classes.dataTableCell}>
                              {value !== 3 ? (
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() => moveClick(row.code)}
                                  color="primary"
                                >
                                  {row.name}
                                </Link>
                              ) : (
                                row.name
                              )}
                            </TableCell>
                            {tableHeaderList1.map((itemName) => (
                              <TableCell className={classes.dataTableCell}>
                                {row[itemName]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell>
                    <TableCell className={classes.graphTable}>
                      <BarChart
                        width={400}
                        height={300}
                        data={targetData1}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                        stackOffset="none"
                      >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <ReferenceLine y={0} stroke="#000" />
                        {graphItemList1.map((item: any) => (
                          <Bar dataKey={item.header} fill={item.fill} />
                        ))}
                      </BarChart>
                    </TableCell>
                  </TableCell>
                </>
              ) : (
                ""
              )}
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              {targetData2 ? (
                <>
                  <TableCell
                    style={{ verticalAlign: "top", textAlign: "start" }}
                  >
                    {title2}
                    <Table className={classes.dataTable1}>
                      <TableHead className={classes.dataTableHead}>
                        <TableCell className={classes.dataTableHeadCell}>
                          {headerName}
                        </TableCell>
                        {tableHeaderList1.map((row) => (
                          <TableCell className={classes.dataTableHeadCell}>
                            {row}
                          </TableCell>
                        ))}
                      </TableHead>
                      <TableBody className={classes.dataTableBody}>
                        {targetData2.map((row) => (
                          <TableRow>
                            <TableCell className={classes.dataTableCell}>
                              {value !== 3 ? (
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() => moveClick(row.code)}
                                  color="primary"
                                >
                                  {row.name}
                                </Link>
                              ) : (
                                row.name
                              )}
                            </TableCell>
                            {tableHeaderList1.map((itemName) => (
                              <TableCell className={classes.dataTableCell}>
                                {row[itemName]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell>
                    <TableCell className={classes.graphTable}>
                      <BarChart
                        width={400}
                        height={300}
                        data={targetData2}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <ReferenceLine y={0} stroke="#000" />
                        {graphItemList1.map((item: any) => (
                          <Bar dataKey={item.header} fill={item.fill} />
                        ))}
                      </BarChart>
                    </TableCell>
                  </TableCell>
                </>
              ) : (
                ""
              )}
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              {targetData3 ? (
                <>
                  <TableCell
                    style={{ verticalAlign: "top", textAlign: "start" }}
                  >
                    {title3}
                    <Table className={classes.dataTable2}>
                      <TableHead className={classes.dataTableHead}>
                        <TableCell
                          className={classes.dataTableHeadCell}
                          style={{ minWidth: "90px", maxWidth: "120px" }}
                        >
                          {headerName}
                        </TableCell>
                        {tableHeaderList2.map((row) => (
                          <TableCell className={classes.dataTableHeadCell}>
                            {row}
                          </TableCell>
                        ))}
                      </TableHead>
                      <TableBody className={classes.dataTableBody}>
                        {targetData3.map((row) => (
                          <TableRow>
                            <TableCell className={classes.dataTableCell}>
                              {value !== 3 ? (
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() => moveClick(row.code)}
                                  color="primary"
                                >
                                  {row.name}
                                </Link>
                              ) : (
                                row.name
                              )}
                            </TableCell>
                            {tableHeaderList2.map((itemName) => (
                              <TableCell className={classes.dataTableCell}>
                                {row[itemName]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell>
                    <TableCell className={classes.graphTable}>
                      <BarChart
                        width={400}
                        height={300}
                        data={targetData3}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend align="left" />
                        <ReferenceLine y={0} stroke="#000" />
                        {graphItemList2.map((item: any) => (
                          <Bar
                            dataKey={item.header}
                            stackId="a"
                            fill={item.fill}
                          />
                        ))}
                      </BarChart>
                    </TableCell>
                  </TableCell>
                </>
              ) : (
                ""
              )}
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              {targetData4 ? (
                <>
                  <TableCell
                    style={{ verticalAlign: "top", textAlign: "start" }}
                  >
                    {title4}
                    <Table className={classes.dataTable2}>
                      <TableHead className={classes.dataTableHead}>
                        <TableCell
                          className={classes.dataTableHeadCell}
                          style={{ minWidth: "90px" }}
                        >
                          {headerName}
                        </TableCell>
                        {tableHeaderList2.map((row) => (
                          <TableCell className={classes.dataTableHeadCell}>
                            {row}
                          </TableCell>
                        ))}
                      </TableHead>
                      <TableBody className={classes.dataTableBody}>
                        {targetData4.map((row) => (
                          <TableRow>
                            <TableCell className={classes.dataTableCell}>
                              {value !== 3 ? (
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() => moveClick(row.code)}
                                  color="primary"
                                >
                                  {row.name}
                                </Link>
                              ) : (
                                row.name
                              )}
                            </TableCell>
                            {tableHeaderList2.map((itemName) => (
                              <TableCell className={classes.dataTableCell}>
                                {row[itemName]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell>
                    <TableCell className={classes.graphTable}>
                      <BarChart
                        width={400}
                        height={300}
                        data={targetData4}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend align="left" />
                        <ReferenceLine y={0} stroke="#000" />
                        {graphItemList2.map((item: any) => (
                          <Bar dataKey={item.header} fill={item.fill} />
                        ))}
                      </BarChart>
                    </TableCell>
                  </TableCell>
                </>
              ) : (
                ""
              )}
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  }

  return (
    <Grid>
      <AppBar
        position="static"
        style={{ padding: "0", margin: "auto", width: "75vw" }}
      >
        <Tabs
          variant="standard"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab label="ZO内分析" href="/zo" {...a11yProps(0)} />
          <LinkTab label="DO内分析" href="/do" {...a11yProps(1)} />
          <LinkTab label="OFC分析" href="/ofc" {...a11yProps(2)} />
          <LinkTab label="個店分析" href="/koten" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableContainer className={classes.root}>
          {tableResult(0)}
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer className={classes.root}>
          {tableResult(1)}
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableContainer className={classes.root}>
          {tableResult(2)}
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableContainer className={classes.root}>
          {tableResult(3)}
        </TableContainer>
      </TabPanel>

      <OneVisionDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
        isClose={true}
      />
    </Grid>
  );
};
export default AnalysisResult;
