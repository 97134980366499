import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  Theme,
  createStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import {
  updateTenpoName,
  updateTenpoCode,
  selectTenpoLatLng,
  updateCreateMarketAreaFlg,
  updateCompletePolygonFlg,
  selectTenpoName,
  selectTenpoCode,
  selectCreateMarketAreaFlg,
  selectCompletePolygonFlg,
  selectMarketAreaValue,
  updateExclusiveFlg,
  updateExclusiveMobakuFlg,
} from "../../../features/marketAreaStatusSlice";
import {
  removePolygonPoint,
  drawPolygon,
  clearPolygonSettings,
  registMarketArea,
  cancelDrawingPolygon,
} from "../../OpView/MarketArea/PolygonDrawing";
import {
  initExistingSejStores,
  setUnvisibleMapsStyle,
  selectedTenpoCode,
} from "../../Maps/layers/existingSejStoreLayer";
import {
  initMobakuExistingSejStores,
  setUnvisibleMobakuMapsStyle,
} from "../../OpView/SelectMobakuMap/layers/existingSejStoreLayer";
import { selectUser } from "../../../features/userSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "fixed",
      top: "2%",
      left: "12%",
      width: "80%",
      height: "8%",
      "&:focus": {
        outline: "none",
      },
      borderRadius: "3%",
      display: "flex",
      alignItems: "center",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttons: {
      textAlign: "center",
    },
    button: {
      textAlign: "center",
      margin: "0px 7px",
      fontWeight: "bold",
    },
    tenpoName: {
      textAlign: "center",
    },
    caution: {
      color: "#e60012",
      textAlign: "center",
    },
  })
);

export let tenpoCodeRef: string = "";

const CreateMarketAreaMenu: React.FC<{ isMobaku: boolean }> = ({
  isMobaku,
}) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const tenpoName = useSelector(selectTenpoName);
  const tenpoCode = useSelector(selectTenpoCode);
  const tenpoLatLng = useSelector(selectTenpoLatLng);
  const displayFlg = useSelector(selectCreateMarketAreaFlg);
  const completePolygonFlg = useSelector(selectCompletePolygonFlg);
  const area = useSelector(selectMarketAreaValue);
  const user = useSelector(selectUser);

  useEffect(() => {
    tenpoCodeRef = tenpoCode;
  }, [tenpoCode]);

  const buttonTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#00CC5B",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#E60012",
        contrastText: "#FFFFFF",
      },
    },
  });

  // キャンセルボタン押下時
  const handleClose = () => {
    dispatch(
      updateCreateMarketAreaFlg({
        createMarketAreaFlg: false,
      })
    );
    dispatch(
      updateCompletePolygonFlg({
        completePolygonFlg: false,
      })
    );
    dispatch(
      updateTenpoName({
        tenpoName: "",
      })
    );
    dispatch(
      updateTenpoCode({
        tenpoCode: "",
      })
    );
    cancelDrawingPolygon(dispatch, isMobaku);
    // 既存店レイヤ初期化
    if (isMobaku) {
      dispatch(
        updateExclusiveMobakuFlg({
          exclusiveMobakuFlg: false,
        })
      );
      setUnvisibleMobakuMapsStyle();
      initMobakuExistingSejStores("second", dispatch, "cvr");
    } else {
      dispatch(
        updateExclusiveFlg({
          exclusiveFlg: false,
        })
      );
      setUnvisibleMapsStyle();
      initExistingSejStores(dispatch);
    }
  };

  // 登録ボタン押下時
  const handleRegister = async () => {
    try {
      dispatch(
        updateCreateMarketAreaFlg({
          createMarketAreaFlg: false,
        })
      );
      dispatch(
        updateTenpoName({
          tenpoName: "",
        })
      );
      dispatch(
        updateTenpoCode({
          tenpoCode: "",
        })
      );
      // 既存店レイヤ初期化
      if (isMobaku) {
        dispatch(
          updateExclusiveMobakuFlg({
            exclusiveMobakuFlg: false,
          })
        );
        setUnvisibleMobakuMapsStyle();
        initMobakuExistingSejStores("second", dispatch, "cvr");
      } else {
        dispatch(
          updateExclusiveFlg({
            exclusiveFlg: false,
          })
        );
        setUnvisibleMapsStyle();
        initExistingSejStores(dispatch);
      }
      // 作成したポリゴンをfirestoreに登録
      await registMarketArea(true, user);
    } catch (e) {
      return;
    }
  };

  return displayFlg ? (
    <Paper className={classes.paper} id={"marketAreaMenu"}>
      <ThemeProvider theme={buttonTheme}>
        <Grid container className={classes.container}>
          <Grid item xs={2}>
            <Button
              className={classes.button}
              color={"default"}
              variant="contained"
              onClick={() => {
                clearPolygonSettings();
                handleClose();
              }}
            >
              キャンセル
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.tenpoName}>
              {tenpoName}店の範囲作成
            </Typography>
            <Typography variant="subtitle2" className={classes.caution}>
              ※範囲は加盟店様との目標として設定してください
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2" className={classes.tenpoName}>
              面積：
              <br />
              {area}㎡
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.buttons}>
            {!completePolygonFlg ? (
              <div>
                <Button
                  className={classes.button}
                  color={"primary"}
                  variant="contained"
                  onClick={() => {
                    removePolygonPoint(isMobaku, dispatch);
                  }}
                >
                  1つ戻る
                </Button>
                <Button
                  className={classes.button}
                  color={"primary"}
                  variant="contained"
                  onClick={() => {
                    drawPolygon(
                      dispatch,
                      selectedTenpoCode,
                      tenpoLatLng,
                      isMobaku
                    );
                  }}
                >
                  やり直す
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  className={classes.button}
                  color={"primary"}
                  variant="contained"
                  onClick={() => {
                    handleRegister();
                  }}
                >
                  登録
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </Paper>
  ) : (
    <></>
  );
};

export default CreateMarketAreaMenu;
