import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { getPlace } from "../../../apis/googleMapsApiAction";
import { moveGMapsPoint } from "../GoogleMaps";
import { moveMobakuGMapsPoint } from "../../OpView/SelectMobakuMap/SelectMobakuGoogleMaps";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { userLogging } from "../../../apis/userLog";
import { Tooltip } from "@material-ui/core";
import {
  selectExclusiveFlg,
  selectExclusiveMobakuFlg,
} from "../../../features/marketAreaStatusSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
    searchMenu: {
      width: "320px",
      height: "52px",
      border: "solid thin #ddd",
      borderRadius: "4px",
      zIndex: 100,
      backgroundColor: "#fff",
      padding: "2px",
    },
    searchTextField: {
      margin: "2px 0px 2px 2px",
    },
    searchButton: {
      padding: "5px 10px",
      margin: "2px",
      backgroundColor: "#35ab63",
      "&:hover": {
        background: "#2e8b57",
      },
      fontSize: "12px",
      color: "white",
      height: "40px",
    },
    closeButton: {
      width: "12px",
    },
    allowIcon: {
      maxWidth: "initial",
    },
  })
);

const MapsSearchBar: React.FC<{
  graphType?: string;
  isSelectMobaku?: boolean;
}> = ({ graphType = "", isSelectMobaku = false }) => {
  const exclusiveFlg = useSelector(selectExclusiveFlg);
  const exclusiveMobakuFlg = useSelector(selectExclusiveMobakuFlg);
  const [searchMode, setSearchMode] = React.useState(false);
  const [searchWord, setSearchWord] = React.useState("");
  const classes = useStyles({});
  useEffect(() => {}, []);
  const handleSearchMenu = (flag: boolean) => {
    setSearchMode(flag);
  };
  const handleSearchButton = () => {
    getPlace(searchWord).then((result: any) => {
      isSelectMobaku
        ? moveMobakuGMapsPoint(
            result[0].geometry.location.lat(),
            result[0].geometry.location.lng(),
            graphType
          )
        : moveGMapsPoint(
            result[0].geometry.location.lat(),
            result[0].geometry.location.lng()
          );
      userLogging("OPVIEW", "OPVIEW＃虫眼鏡（検索）", searchWord);
    });
  };

  return (
    <>
      {searchMode ? (
        <>
          <Grid container className={classes.searchMenu}>
            <Grid container item xs={8}>
              <TextField
                className={classes.searchTextField}
                value={searchWord}
                label="目的地で探す"
                variant="outlined"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchWord(e.target.value);
                }}
              />
            </Grid>
            <Grid container item xs={3}>
              <Button
                className={classes.searchButton}
                onClick={() => handleSearchButton()}
              >
                検索する
              </Button>
            </Grid>
            <Grid container item xs={1}>
              <IconButton
                className={classes.closeButton}
                onClick={() => handleSearchMenu(false)}
              >
                <ArrowBackIosRoundedIcon
                  className={classes.allowIcon}
                  fontSize="small"
                />
              </IconButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <Tooltip
          title={<span style={{ fontSize: "16px" }}>{"目的地を検索する"}</span>}
          placement="right"
          disableTouchListener
        >
          <IconButton
            aria-label="検索"
            className={classes.button + " map-item-shadow"}
            style={
              exclusiveFlg || exclusiveMobakuFlg
                ? { pointerEvents: "none" }
                : {}
            }
            onClick={() => handleSearchMenu(true)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default MapsSearchBar;
