import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  CircularProgress,
  Modal,
  OutlinedInput,
} from "@material-ui/core";
import Header from "../../Header/Header";
import DatePicker from "../../common/DatePicker";
import TSCheckSearchResult, {
  setTSCheckSearchData,
} from "./TSCheckSearchResult";
import { selectUser } from "../../../features/userSlice";
import {
  updateTenpoCode,
  updateTenpoName,
  updateZoCode,
  updateZoName,
  updateDoCode,
  updateDoName,
  updateOfcMailAddress,
  updateOfcName,
  updateTsCheckDataList,
  selectTsCheckDataList,
} from "../../organisms/search/TenpoCheckSearchSlice";
import {
  stylesSearchTenpo,
  stylesSearchZO,
  stylesSearchDO,
  stylesSearchOFC,
} from "./StylesTSCheckSearch";
import {
  getAllExistingSejStores,
  getAllZone,
  getAllDo,
  EXISTING_SEJ_STORES_RECORD,
} from "../../../data/existingSejStores";
import { getEmployee } from "../../../data/employeeOfc";
import { postRequest } from "../../../apis/axiosAction";
import {
  getAuthTenpoCheck,
  onlyArea,
} from "../../organisms/search/specification/UsageAuthority/AuthTenpoCheck";
import SearchTenpo from "../../organisms/search/searchTenpo/SearchTenpo";
import SearchZO from "../../organisms/search/searchZO/SearchZO";
import SearchDO from "../../organisms/search/searchDO/SearchDO";
import SearchOFC from "../../organisms/search/searchOFC/SearchOFC";
import { userLogging } from "../../../apis/userLog";
import TSCheckDialog from "../TSCheckDialog";
import { isFirebaseAuth } from "../../../firebase";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
  },

  modalPane: {
    position: "absolute",
    paddingTop: "2%",
    paddingLeft: "15%",
    width: "60vw",
    minWidth: "600px",
    height: "90vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  contentFrame: {
    marginTop: "10px",
    height: "85vh",
    overflowY: "auto",
  },
  searchArea: {
    border: "solid",
    flex: 1,
    margin: "auto",
    alignItems: "center",
    width: "60vw",
    backgroundColor: "#fff",
    paddingBottom: "15px",
  },
  searchItem: {
    marginTop: "5px",
    marginLeft: "10px",
    marginBottom: "10px",
  },
  unCheckButton: {
    color: "black",
    backgroundColor: "#F2F2F2",
    "&:hover": {},
  },
  checkedButton: {
    color: "#ffffff",
    outlineColor: "#3e3e3e",
    backgroundColor: "#4dc0b2",
    "&:hover": {
      backgroundColor: "#4ab5a1",
    },
  },
  searchButton: {
    color: "#ffffff",
    backgroundColor: "#4dc0b2",
    fontSize: "20px",
    marginLeft: "4vw",
    "&:hover": {
      backgroundColor: "#4ab5a1",
    },
  },
  tableBottom: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: "1px",
    width: "80vw",
  },
}));
const TenpoSearch: React.FC = (props: any) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const searchResultList = useSelector(selectTsCheckDataList);
  const [click, setClick] = React.useState(false);
  const [jitan, setJitan] = React.useState(false);
  const [lowerPoint, setLowerPoint] = React.useState("");
  const [upperPoint, setUpperPoint] = React.useState("");
  const [zoCode, setZoCode] = React.useState("");
  const [doCode, setDoCode] = React.useState("");
  const [ofcMailAddress, setOfcMailAddress] = React.useState("");
  const [tenpoCode, setTenpoCode] = React.useState([""]);
  const [searchFlg, setSearchFlg] = React.useState("");
  const [searchButtonFlg, setSearchButtonFlg] = React.useState(false);
  const zoneMaster = getAllZone().zone_data;
  const doMaster = getAllDo().do_data;
  const dispatch = useDispatch();
  const storeMaster: Map<String, EXISTING_SEJ_STORES_RECORD> = getAllExistingSejStores().store_data;
  const [createdDateFrom, setCreatedDateFrom] = React.useState<DateTime | null>(
    null
  );
  const [createdDateTo, setCreatedDateTo] = React.useState<DateTime | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  const dialogClose = () => {
    setDialogFlg(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleBaseStartDate = (date: DateTime | null) => {
    setCreatedDateFrom(date);
  };

  const handleBaseEndDate = (date: DateTime | null) => {
    setCreatedDateTo(date);
  };

  const jitanCheck = (flag: boolean) => {
    setJitan(flag);
  };

  const handleClick = () => {
    setClick((prev) => !click);
  };

  // 初期表示処理
  useEffect(() => {
    userLogging("店舗衛生点検", "データ検索#初期表示", "");
    dispatch(
      updateTenpoName({
        tenpoName: "",
      })
    );
    dispatch(
      updateZoCode({
        zoCode: "",
      })
    );
    dispatch(
      updateZoName({
        zoName: "",
      })
    );
    dispatch(
      updateDoCode({
        doCode: "",
      })
    );
    dispatch(
      updateDoName({
        doName: "",
      })
    );
    dispatch(
      updateOfcMailAddress({
        ofcMailAddress: "",
      })
    );
    dispatch(
      updateOfcName({
        ofcName: "",
      })
    );

    let nowDate: DateTime = DateTime.local();
    let lastDate: DateTime = nowDate.minus({ day: 7 });
    setCreatedDateFrom(lastDate);
    setCreatedDateTo(nowDate);

    if (searchResultList.length === 0) {
      // 初期検索
      if (
        user.jobCategory === "ofc" ||
        user.jobCategory === "dm" ||
        user.jobCategory === "zm"
      ) {
        doSearch(true);
      }
    } else {
      setTSCheckSearchData(sortResultRecords(searchResultList));
      setSearchFlg("1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 検索ボタンの制御
  useEffect(() => {
    if (
      tenpoCode[0] !== "" ||
      zoCode !== "" ||
      doCode !== "" ||
      ofcMailAddress !== ""
    ) {
      setSearchButtonFlg(true);
    } else {
      setSearchButtonFlg(false);
    }
  }, [tenpoCode, zoCode, doCode, ofcMailAddress]);

  if (!isFirebaseAuth()) {
    return <></>;
  }
  // 検索ボタン押下時処理
  const doSearch = (initFlag?: boolean) => {
    if (initFlag && user.zoCode.length > 1) return;
    // 当年客数をAPIから取得
    let apiParams = {
      "api-name": "search",
      jitan_flag: jitan ? "1" : "0",
      tenpo_code: tenpoCode,
      zo_code: zoCode,
      do_code: doCode,
      ofc_address: ofcMailAddress,
      createdate_from: createdDateFrom,
      createdate_to: createdDateTo,
      lower_point: lowerPoint,
      upper_point: upperPoint,
    };
    let nowDate: DateTime = DateTime.local();
    let lastDate: DateTime = nowDate.minus({ day: 7 });

    // 初期検索
    if (initFlag === true) {
      // 初期検索
      if (user.jobCategory === "ofc") {
        jitanCheck(true);
        apiParams.createdate_from = lastDate;
        apiParams.createdate_to = nowDate;
        apiParams.jitan_flag = "1";
        apiParams.tenpo_code = user.tenpoCode;
      } else if (user.jobCategory === "dm") {
        apiParams.createdate_from = lastDate;
        apiParams.createdate_to = nowDate;
        apiParams.jitan_flag = "0";
        apiParams.tenpo_code = user.tenpoCode;
        saveStatusBottun(
          "",
          "",
          "",
          "",
          user.doCode[0],
          doMaster[user.doCode[0]].do_name,
          "",
          ""
        );
      } else if (user.jobCategory === "zm") {
        apiParams.createdate_from = lastDate;
        apiParams.createdate_to = nowDate;
        apiParams.jitan_flag = "0";
        apiParams.zo_code = user.zoCode[0];
        saveStatusBottun(
          "",
          "",
          user.zoCode[0],
          zoneMaster[user.zoCode[0]].zone_name,
          "",
          "",
          "",
          ""
        );
      }
    }
    if (ofcMailAddress) {
      if (user.jobCategory === "dm") {
        apiParams.do_code = user.doCode[0];
      } else {
        apiParams.zo_code = getEmployee(ofcMailAddress).zocode;
      }
    }
    userLogging("店舗衛生点検", "データ検索#検索", JSON.stringify(apiParams));
    setSearchFlg("2");
    const url = process.env.REACT_APP_GAE_API_URL + "tscheck/search";
    postRequest(url, apiParams)
      .then((res) => {
        let dataList: any[] = res.data.dataList;
        let searchResultRecords = [];
        setTSCheckSearchData([]);
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let zoName = "";
            let doName = "";
            let tenpoName = "";
            if (zoneMaster[dataList[i].zo_code.padStart(3, "0")]) {
              zoName =
                zoneMaster[dataList[i].zo_code.padStart(3, "0")].zone_name;
            } else {
              zoName = dataList[i].zo_code.padStart(3, "0");
            }
            if (doMaster[dataList[i].do_code.padStart(5, "0")]) {
              doName =
                doMaster[dataList[i].do_code.padStart(5, "0")].do_name;
            } else {
              doName = dataList[i].do_code.padStart(5, "0");
            }
            // 店舗レコードを内部データから取得
            let storeRecord = storeMaster.get([dataList[i].tenpo_code.padStart(6, "0")].toString());
            if (storeRecord) {
              tenpoName = storeRecord.tenpo_name;
            } else {
              tenpoName = dataList[i].tenpo_name;
            }
            let date: string = DateTime.fromFormat(
              dataList[i].create_date,
              "yyyyMMdd hh:mm:ss"
            ).toFormat("yyyy-MM-dd");
            searchResultRecords.push({
              doc_id: dataList[i].doc_id,
              jtan_flag: dataList[i].jtan_flag,
              tenpo_code: dataList[i].tenpo_code,
              tenpoName: tenpoName,
              zoName: zoName,
              doName: doName,
              create_date: date,
              employeeName: dataList[i].employee_name,
            });
          }
        }
        dispatch(updateTsCheckDataList({ tsCheckDataList: searchResultRecords }));
        setSearchFlg("1");
        setTSCheckSearchData(sortResultRecords(searchResultRecords));
        handleClick();
      })
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
      });

    handleClose();
  };

  function sortResultRecords(records: any[]) {
    let mapList: any[] = [];
    for (let i = 0;i< records.length;i++) {
      mapList.push(records[i]);
    }
    if (mapList) {
      return mapList.sort(function (first, second) {
        if (first.create_date > second.create_date) {
          return -1;
        } else if (first.create_date < second.create_date) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      return mapList;
    }
  }

  // 検索ボタン押下時処理
  function saveStatusBottun(
    argTenpoCode: string,
    argTenpoName: string,
    argZoCode: string,
    argZoName: string,
    argDoCode: string,
    argDoName: string,
    argOfcMailAddress: string,
    argOfcName: string
  ) {
    dispatch(
      updateTenpoCode({
        tenpoCode: argTenpoCode,
      })
    );
    dispatch(
      updateTenpoName({
        tenpoName: argTenpoName,
      })
    );
    dispatch(
      updateZoCode({
        zoCode: argZoCode,
      })
    );
    dispatch(
      updateZoName({
        zoName: argZoName,
      })
    );
    dispatch(
      updateDoCode({
        doCode: argDoCode,
      })
    );
    dispatch(
      updateDoName({
        doName: argDoName,
      })
    );
    dispatch(
      updateOfcMailAddress({
        ofcMailAddress: argOfcMailAddress,
      })
    );
    dispatch(
      updateOfcName({
        ofcName: argOfcName,
      })
    );
    setTenpoCode([argTenpoCode]);
    setZoCode(argZoCode);
    setDoCode(argDoCode);
    setOfcMailAddress(argOfcMailAddress);
  }

  const changeLowerPoint = (event: React.ChangeEvent<{ value: unknown }>) => {
    let numValue = event.target.value as number;
    if (!numValue && numValue !== 0) {
      setLowerPoint("");
      return;
    } else if (numValue < 0) {
      setLowerPoint("0");
      return;
    } else if (numValue > 100) {
      setLowerPoint("100");
      return;
    }
    setLowerPoint(String(numValue));
  };
  const changeUpperPoint = (event: React.ChangeEvent<{ value: unknown }>) => {
    let numValue = event.target.value as number;
    if (!numValue && numValue !== 0) {
      setUpperPoint("");
      return;
    } else if (numValue < 0) {
      setUpperPoint("0");
      return;
    } else if (numValue > 100) {
      setUpperPoint("100");
      return;
    }
    setUpperPoint(String(numValue));
  };

  function clickSearch(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleOpen();
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && searchButtonFlg) {
      doSearch();
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalPane}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
      >
        <Grid container className={classes.searchArea}>
          {/*検索欄*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#37AB9D",
              fontWeight: "bolder",
              textAlign: "center",
              height: "40px",
            }}
          >
            条件変更
          </Grid>
          {/*L1*/}
          <Grid container>
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                fontWeight: "bolder",
              }}
            >
              絞込み
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.searchItem}
            justify="center"
            alignItems="center"
          >
            <Grid item lg={2} md={3} sm={3}>
              <Button
                variant="contained"
                className={
                  jitan ? classes.checkedButton : classes.unCheckButton
                }
                onClick={() => jitanCheck(true)}
              >
                自担当
              </Button>
            </Grid>
            <Grid item lg={2} md={3} sm={3}>
              <Button
                variant="contained"
                className={
                  !jitan ? classes.checkedButton : classes.unCheckButton
                }
                onClick={() => jitanCheck(false)}
              >
                すべて
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              borderTop: "1px solid #aaa",
            }}
          >
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                fontWeight: "bolder",
              }}
            >
              得点率
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.searchItem}
          >
            <Grid item xs={3}>
              <Grid container justify="flex-end">
                <OutlinedInput
                  type="number"
                  multiline={false}
                  endAdornment={
                    <span style={{ width: "80px", fontSize: "12px" }}>％以上</span>
                  }
                  style={{ width: "150px" }}
                  value={lowerPoint}
                  onChange={(e) => changeLowerPoint(e)}
                ></OutlinedInput>
              </Grid>
            </Grid>

            <Grid item xs={1}>
              <Grid container justify="center"></Grid>
            </Grid>

            <Grid item xs={3}>
              <Grid container justify="flex-start">
                <OutlinedInput
                  type="number"
                  multiline={false}
                  endAdornment={
                    <span style={{ width: "80px", fontSize: "12px" }}>％以下</span>
                  }
                  style={{ width: "150px" }}
                  value={upperPoint}
                  onChange={(e) => changeUpperPoint(e)}
                ></OutlinedInput>
              </Grid>
            </Grid>
          </Grid>

          {/*L2*/}
          <Grid
            container
            style={{
              borderTop: "1px solid #aaa",
            }}
          >
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                fontWeight: "bolder",
              }}
            >
              店番・店名
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.searchItem}
            justify="center"
            alignItems="center"
          >
            <SearchTenpo
              useStyles={stylesSearchTenpo}
              disabled={false}
              firstView={false}
              toggleStatus={false}
              searchMode={getAuthTenpoCheck(user.jobCategory).search}
              onClickFunc={(tenpoCode: string, tenpoName: string) => {
                saveStatusBottun(tenpoCode, tenpoName, "", "", "", "", "", "");
              }}
            />
          </Grid>
          {/*L3*/}
          {getAuthTenpoCheck(user.jobCategory).search < onlyArea ? (
            ""
          ) : (
            <>
              <Grid
                container
                style={{
                  borderTop: "1px solid #aaa",
                }}
              >
                <Grid
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  ZOを選択
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.searchItem}
                justify="center"
                alignItems="center"
              >
                <SearchZO
                  useStyles={stylesSearchZO}
                  disabled={
                    getAuthTenpoCheck(user.jobCategory).search < onlyArea
                  }
                  firstView={false}
                  toggleStatus={false}
                  searchMode={0}
                  onClickFunc={(zoCode: string, zoName: string) => {
                    saveStatusBottun("", "", zoCode, zoName, "", "", "", "");
                  }}
                />
              </Grid>
            </>
          )}
          {/*L4*/}
          {getAuthTenpoCheck(user.jobCategory).search < onlyArea ? (
            ""
          ) : (
            <>
              <Grid
                container
                style={{
                  borderTop: "1px solid #aaa",
                }}
              >
                <Grid
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  DOを選択
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.searchItem}
                justify="center"
                alignItems="center"
              >
                <SearchDO
                  useStyles={stylesSearchDO}
                  disabled={
                    getAuthTenpoCheck(user.jobCategory).search < onlyArea
                  }
                  firstView={false}
                  toggleStatus={false}
                  searchMode={getAuthTenpoCheck(user.jobCategory).search}
                  onClickFunc={(doCode: string, doName: string) => {
                    saveStatusBottun("", "", "", "", doCode, doName, "", "");
                  }}
                />
              </Grid>
            </>
          )}
          {getAuthTenpoCheck(user.jobCategory).search < onlyArea ? (
            ""
          ) : (
            <>
              <Grid
                container
                style={{
                  borderTop: "1px solid #aaa",
                }}
              >
                <Grid
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  {user.jobCategory === "dm"
                    ? "ＯＦＣ、ＤＭを選択"
                    : "ＯＦＣ、ＤＭ、ＺＭを選択"}
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.searchItem}
                justify="center"
                alignItems="center"
              >
                <SearchOFC
                  useStyles={stylesSearchOFC}
                  disabled={
                    getAuthTenpoCheck(user.jobCategory).search < onlyArea
                  }
                  firstView={false}
                  toggleStatus={false}
                  searchMode={getAuthTenpoCheck(user.jobCategory).search}
                  onClickFunc={(
                    doCode: string,
                    doName: string,
                    ofcMailAddress: string,
                    ofcName: string
                  ) => {
                    saveStatusBottun(
                      "",
                      "",
                      "",
                      "",
                      doCode,
                      doName,
                      ofcMailAddress,
                      ofcName
                    );
                  }}
                  searchDocode={doCode}
                />
              </Grid>
            </>
          )}
          {/*L5*/}
          <Grid
            container
            style={{
              borderTop: "1px solid #aaa",
            }}
          >
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                fontWeight: "bolder",
              }}
            >
              期間を選択
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.searchItem}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Grid container justify="flex-end">
                <DatePicker
                  selectedDate={createdDateFrom}
                  dateFunc={(date: DateTime | null) => {
                    handleBaseStartDate(date);
                  }}
                  maxDate={DateTime.fromFormat("99991231", "yyyyMMdd")}
                  mainColor={"#4dc0b2"}
                />
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Grid container justify="flex-start">
                ～
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container justify="flex-start">
                <DatePicker
                  selectedDate={createdDateTo}
                  dateFunc={(date: DateTime | null) => {
                    handleBaseEndDate(date);
                  }}
                  maxDate={DateTime.fromFormat("99991231", "yyyyMMdd")}
                  mainColor={"#4dc0b2"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.searchItem}
            justify="center"
            alignItems="center"
          >
            <Button
              className={classes.searchButton}
              variant="contained"
              color="primary"
              disabled={!searchButtonFlg}
              onClick={() => doSearch()}
            >
              検索
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* メインコンテンツ */}
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Header
            titleName={"店舗衛生点検検索"}
            modalCondition={(e) => clickSearch(e)}
          />

          <Grid
            container
            justify={"center"}
            className={classes.contentFrame}
            id="serchResult"
          >
            {searchFlg === "1" ? (
              <TSCheckSearchResult />
            ) : searchFlg === "2" ? (
              <>
                <Grid container className={classes.tableBottom}>
                  <Grid container justify="center" alignItems="center">
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <CircularProgress
                        size={"40vh"}
                        style={{
                          color: "#ccc",
                          top: "30vh",
                          position: "absolute",
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container justify="center">
                右上の虫眼鏡アイコンから検索条件を指定してください。
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* ダイアログ */}
      <TSCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </>
  );
};

export default TenpoSearch;
