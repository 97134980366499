import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { TENPOSTATUS } from "./interface";

const initialTenpoStatus: TENPOSTATUS = {
  // tenpoCode: "",
  // tenpoName: "",
  // tenpoCheckComment: "",
  tenpoCheckPhoroUrl: "",
  firstCvrItem: "",
  secondCvrItem: [],
  firstMobakuItem: [],
  secondMobakuItem: [],
  spareMobakuItem: [],
  firstTenpoItem: "",
  secondTenpoItem: "",
  cvrMobakuName: "",
  firstMobakuName: "",
  secondMobakuName: "",
  selectingCvrMobakuItem: [],
  selectingFirstMobakuItem: [],
  selectingSecondMobakuItem: [],
  cvrProcessFlag: false,
  initializeGraphValue: 0,
};

export const tenpoStatusSlice = createSlice({
  name: "tenpoStatus",
  initialState: {
    tenpoStatus: initialTenpoStatus,
  },
  reducers: {
    // updateTenpoCheckComment: (state, action) => {
    //   state.tenpoStatus.tenpoCheckComment = action.payload.tenpoCheckComment;
    // },
    updateTenpoCheckPhoroUrl: (state, action) => {
      state.tenpoStatus.tenpoCheckPhoroUrl = action.payload.tenpoCheckPhoroUrl;
    },
    updateFirstCvrItem: (state, action) => {
      state.tenpoStatus.firstCvrItem = action.payload.firstCvrItem;
    },
    updateSecondCvrItem: (state, action) => {
      state.tenpoStatus.secondCvrItem = action.payload.secondCvrItem;
    },
    updateFirstMobakuItem: (state, action) => {
      state.tenpoStatus.firstMobakuItem = action.payload.firstMobakuItem;
    },
    updateSecondMobakuItem: (state, action) => {
      state.tenpoStatus.secondMobakuItem = action.payload.secondMobakuItem;
    },
    updateSpareMobakuItem: (state, action) => {
      state.tenpoStatus.spareMobakuItem = action.payload.spareMobakuItem;
    },
    updateFirstTenpoItem: (state, action) => {
      state.tenpoStatus.firstTenpoItem = action.payload.firstTenpoItem;
    },
    updateSecondTenpoItem: (state, action) => {
      state.tenpoStatus.secondTenpoItem = action.payload.secondTenpoItem;
    },
    updateCvrMobakuName: (state, action) => {
      state.tenpoStatus.cvrMobakuName = action.payload.cvrMobakuName;
    },
    updateFirstMobakuName: (state, action) => {
      state.tenpoStatus.firstMobakuName = action.payload.firstMobakuName;
    },
    updateSecondMobakuName: (state, action) => {
      state.tenpoStatus.secondMobakuName = action.payload.secondMobakuName;
    },
    updateSelectingCvrMobakuItem: (state, action) => {
      state.tenpoStatus.selectingCvrMobakuItem =
        action.payload.selectingCvrMobakuItem;
    },
    updateSelectingFirstMobakuItem: (state, action) => {
      state.tenpoStatus.selectingFirstMobakuItem =
        action.payload.selectingFirstMobakuItem;
    },
    updateSelectingSecondMobakuItem: (state, action) => {
      state.tenpoStatus.selectingSecondMobakuItem =
        action.payload.selectingSecondMobakuItem;
    },
    updateCvrProcessFlag: (state, action) => {
      state.tenpoStatus.cvrProcessFlag = action.payload.cvrProcessFlag;
    },
    updateInitializeGraphValue: (state, action) => {
      state.tenpoStatus.initializeGraphValue =
        action.payload.initializeGraphValue;
    },
    // clearTenpoStatus: (state) => {
    //   state.tenpoStatus = initialTenpoStatus;
    // },
  },
});

export const selectTenpoStatus = (state: RootState) => state.tenpoStatus;
// export const selectTenpoCode = (state: RootState) =>
//   state.tenpoStatus.tenpoStatus.tenpoCode;
export const selectFirstCvrItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.firstCvrItem;
export const selectSecondCvrItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.secondCvrItem;
export const selectFirstMobakuItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.firstMobakuItem;
export const selectSecondMobakuItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.secondMobakuItem;
export const selectSpareMobakuItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.spareMobakuItem;
export const selectFirstTenpoItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.firstTenpoItem;
export const selectSecondTenpoItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.secondTenpoItem;
export const selectCvrMobakuName = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.cvrMobakuName;
export const selectFirstMobakuName = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.firstMobakuName;
export const selectSecondMobakuName = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.secondMobakuName;
export const selectSelectingCvrMobakuItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.selectingCvrMobakuItem;
export const selectSelectingFirstMobakuItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.selectingFirstMobakuItem;
export const selectSelectingSecondMobakuItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.selectingSecondMobakuItem;
export const selectCvrProcessFlag = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.cvrProcessFlag;
export const selectInitializeGraphValue = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.initializeGraphValue;
export default tenpoStatusSlice.reducer;
export const {
  // updateTenpoCheckComment,
  updateTenpoCheckPhoroUrl,
  updateFirstCvrItem,
  updateSecondCvrItem,
  updateFirstMobakuItem,
  updateSecondMobakuItem,
  updateSpareMobakuItem,
  updateFirstTenpoItem,
  updateSecondTenpoItem,
  updateCvrMobakuName,
  updateFirstMobakuName,
  updateSecondMobakuName,
  updateSelectingCvrMobakuItem,
  updateSelectingFirstMobakuItem,
  updateSelectingSecondMobakuItem,
  updateCvrProcessFlag,
  updateInitializeGraphValue,
  // updateTenpoFirstView,
  // clearTenpoStatus,
} = tenpoStatusSlice.actions;
