import React, { useEffect, useRef, FC } from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Modal,
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from "@material-ui/core";

import { getFixedFlg } from "../../Header/Header";
import CloseIcon from "@material-ui/icons/Close";
import { Add } from "@material-ui/icons";
import {
  deleteTsCheckTemplate,
  getTemplateData,
  TEMPLATE_ITEM,
  TS_CHECK_ITEM_TEMPLATE,
  updateTSCheckTemplate,
} from "./TSCheckTableDataOperation";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { v4 as uuidv4 } from "uuid";
import TSCheckDialog from "../TSCheckDialog";
import { userLogging } from "../../../apis/userLog";
import TsCheckModalButton from "./TSCheckModalButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPane: {
      position: "absolute",
      paddingTop: "2%",
      paddingLeft: "20%",
      width: "60%",
      height: "60%",
      overflowX: "hidden",
    },
    modalPane2: {
      position: "absolute",
      paddingTop: "2%",
      paddingLeft: "20%",
      width: "60%",
      height: "100%",
      overflowX: "hidden",
    },
    paper: {
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paper2: {
      height: "62%",
      minHeight: "60%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    titleGrid: {
      height: "7vh",
      color: "white",
      backgroundColor: "#00AC4C",
    },
    titleTypography: {
      fontWeight: "bold",
    },
    buttonSpace: {
      paddingRight: "15px",
    },
    greenButton: {
      backgroundColor: "#00CC5B",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#00CC5B",
      },
    },
    grayButton: {
      backgroundColor: "#C4C4C4",
      color: "#333333",
      "&:hover": {
        backgroundColor: "#C4C4C4",
      },
    },
    redButton: {
      backgroundColor: "#E60012",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#E60012",
      },
    },
  })
);

const TSCheckTemplateModalButton: FC<{
  onClickOK?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    recordList: any[]
  ) => void;
}> = ({ onClickOK }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [itemText, setItemText] = React.useState("");
  const [editFlg, setEditFlg] = React.useState(false);

  const user = useSelector(selectUser);
  const [targetTemplateTitle, setTargetTemplateTitle] = React.useState("");
  const [templateList, setTemplateList] = React.useState<
    TS_CHECK_ITEM_TEMPLATE[]
  >([]);
  const [targetTemplate, setTargetTemplate] =
    React.useState<TS_CHECK_ITEM_TEMPLATE>();
  const [tmpTargetTemplate, setTmpTargetTemplate] =
    React.useState<TS_CHECK_ITEM_TEMPLATE>();
  const [registedTemplate, setRegistedTemplate] =
    React.useState<TS_CHECK_ITEM_TEMPLATE>();

  const [click, setClick] = React.useState(false);
  const [limited, isLimited] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [reload, setReload] = React.useState(false);

  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [dialogFlg2, setDialogFlg2] = React.useState(false);

  // テンプレート削除
  const deleteTemplate = () => {
    if (targetTemplate) {
      deleteTsCheckTemplate(targetTemplate.doc_id).then(() => {
        setReload(true);
        setTargetTemplateTitle("");
        setTmpTargetTemplate(undefined);
        setLoad(!load);
        refreshItemString();
        setItemText("");
        setTargetTemplate(undefined);
      });
    }
    setDialogFlg2(false);
  };

  const dialogClose = () => {
    setDialogFlg(false);
  };
  const dialogClose2 = () => {
    setDialogFlg2(false);
  };

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [scrollSwitch, setScrollSwitch] = React.useState(false);

  useEffect(() => {
    refreshItemString();
    setClick(!click);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetTemplate]);

  // データ取得
  useEffect(() => {
    let conditions: any[] = [];
    if (user.doCode) {
      for (const code of user.doCode) {
        conditions.push(code);
      }
    }

    getTemplateData(conditions).then((response: TS_CHECK_ITEM_TEMPLATE[]) => {
      let tmplateList: any[] = [];

      for (const res of response) {
        tmplateList.push(res);
        // 登録時のリロード処理
        if (targetTemplate && targetTemplate.doc_id === res.doc_id) {
          setTargetTemplate(res);
          if (reload && registedTemplate) {
            let ckResult = true;
            if (registedTemplate.template_name !== res.template_name) {
              ckResult = false;
            }
            if (
              registedTemplate.small_items.length !== res.small_items.length
            ) {
              ckResult = false;
            } else {
              for (let i = 0; i < registedTemplate.small_items.length; i++) {
                if (
                  registedTemplate.small_items[i].name !==
                  res.small_items[i].name
                ) {
                  ckResult = false;
                  break;
                }
              }
            }

            if (ckResult) {
              setRegistedTemplate(undefined);
              setReload(false);
            } else {
              setLoad(!load);
            }
          } else {
            setReload(false);
          }
        }
      }
      setTemplateList(tmplateList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  useEffect(() => {
    scrollBottomRef?.current?.scrollIntoView(false);
  }, [scrollSwitch]);

  const handleOpen = () => {
    setLoad(!load);
    setTargetTemplateTitle("");
    setTargetTemplate(undefined);
    setItemText("");
    setOpen(true);
  };

  // テンプレート名編集
  const changeTemplateTitle = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let comment = event.target.value as string;
    if (comment && Number(comment.length) > 100) {
      comment = comment.substring(0, 100);
    }
    setTargetTemplateTitle(comment);
    setClick(!click);
  };

  // テンプレート項目名編集
  const changeTemplateItem = (
    event: React.ChangeEvent<{ value: unknown }>,
    row: TEMPLATE_ITEM
  ) => {
    let comment = event.target.value as string;
    if (comment && Number(comment.length) > 100) {
      comment = comment.substring(0, 100);
    }
    row.name = comment;
    setClick(!click);
  };

  // テンプレート登録・編集入力内容チェック
  const checkInputTemplate = () => {
    if (!targetTemplateTitle) {
      return false;
    }
    if (tmpTargetTemplate) {
      if (tmpTargetTemplate.small_items.length < 1) {
        return false;
      }

      for (const item of tmpTargetTemplate.small_items) {
        if (!item.name) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  };
  // 登録・更新ボタン
  const handleRegist = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!checkInputTemplate()) {
      setDialogMessage(["未入力項目があります。入力内容を確認してください"]);
      setDialogFlg(true);
      return;
    }
    if (tmpTargetTemplate) {
      sortIdTmp();
      let docId = "";
      let inputId = "";
      let inputConditions: string[] = [];
      let inputBigItemId = 9;
      let newFlg = false;
      // 更新の場合
      if (editFlg) {
        inputId = tmpTargetTemplate.id;
        docId = tmpTargetTemplate.doc_id;
        inputConditions = tmpTargetTemplate.conditions;
        inputBigItemId = Number(tmpTargetTemplate.big_item_id);
      } else {
        // 登録の場合
        newFlg = true;
        let firstDoCode = "";
        if (user.doCode) {
          for (const code of user.doCode) {
            firstDoCode = code;
            inputConditions.push(code);
          }
        }
        let uuid = uuidv4();
        inputId = firstDoCode + "-" + uuid;
        docId = firstDoCode + "-" + uuid;
      }
      const registTargetTemplate: TS_CHECK_ITEM_TEMPLATE = {
        id: inputId,
        doc_id: docId,
        template_name: targetTemplateTitle,
        big_item_id: inputBigItemId,
        big_item_name: "商品・売場・地区政策等",
        conditions: inputConditions,
        small_items: tmpTargetTemplate.small_items,
        info: tmpTargetTemplate.info,
      };
      setRegistedTemplate(registTargetTemplate);
      updateTSCheckTemplate(registTargetTemplate, newFlg).then(() => {
        setReload(true);
        setTargetTemplate(registTargetTemplate);
        setTmpTargetTemplate(registTargetTemplate);
        setLoad(!load);
        refreshItemString();
        setOpen2(false);
      });
    }
  };

  // 反映ボタン
  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let addRowItemList: any[] = [];
    if (targetTemplate) {
      for (const item of targetTemplate.small_items) {
        addRowItemList.push({
          name: item.name,
          uuid: item.uuid,
          photo_filepath_gcs: item.photo_filepath_gcs,
          user_input_comment: item.user_input_comment,
        });
      }
      if (onClickOK) {
        onClickOK(e, addRowItemList);
      }
      setOpen(false);
      setTargetTemplate(initTemplate());
    }
  };
  // キャンセル処理
  const handleClose = () => {
    if (open2) {
      if (targetTemplate) {
        setTmpTargetTemplate(targetTemplate);
        setTargetTemplateTitle(targetTemplate.template_name);
      }
      setOpen2(false);
    } else if (open) {
      setOpen(false);
      setTargetTemplate(initTemplate());
    }
  };

  // テンプレート選択
  const doSelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let value = event.target.value;
    for (let i = 0; i < templateList.length; i++) {
      if (templateList[i].id === value) {
        setTargetTemplateTitle(templateList[i].template_name);
        setTargetTemplate(templateList[i]);
        break;
      }
    }
  };

  const refreshItemString = () => {
    let itemString = "";
    if (targetTemplate) {
      for (let i = 0; i < targetTemplate.small_items.length; i++) {
        if (itemString) {
          itemString += "\n";
        }
        itemString += "・" + targetTemplate.small_items[i].name;
      }
    }
    setItemText(itemString);
  };

  // 追加
  const clickAdd = () => {
    if (tmpTargetTemplate) {
      tmpTargetTemplate.small_items.push({ id: 0, name: "", uuid: uuidv4(), photo_filepath_gcs: [], user_input_comment: "" });
      if (tmpTargetTemplate.small_items.length > 19) {
        isLimited(true);
      }
    }
    sortIdTmp();
    setClick(!click);
    setScrollSwitch(!scrollSwitch);
  };
  // 削除
  const clickDelete = (row: TEMPLATE_ITEM) => {
    if (tmpTargetTemplate) {
      let smallItem = [];
      for (let i = 0; i < tmpTargetTemplate.small_items.length; i++) {
        if (tmpTargetTemplate.small_items[i].id !== row.id) {
          smallItem.push(tmpTargetTemplate.small_items[i]);
        }
      }
      tmpTargetTemplate.small_items = smallItem;
      if (tmpTargetTemplate.small_items.length < 20) {
        isLimited(false);
      }
    }
    sortIdTmp();
    setClick(!click);
  };

  function sortIdTmp() {
    const newUuid = () => {
      return String(Math.random);
    };
    if (tmpTargetTemplate) {
      let smallItems: TEMPLATE_ITEM[] = [];
      for (let i = 0; i < tmpTargetTemplate.small_items.length; i++) {
        smallItems.push({
          id: i + 1,
          name: tmpTargetTemplate.small_items[i].name,
          uuid: tmpTargetTemplate.small_items[i].uuid || newUuid(),
          photo_filepath_gcs: [],
          user_input_comment: "",
        });
        tmpTargetTemplate.small_items[i].id = i + 1;
      }
    }
  }

  const initTemplate = () => {
    let template: TS_CHECK_ITEM_TEMPLATE = {
      id: "",
      doc_id: "",
      template_name: "",
      conditions: [],
      big_item_id: 0,
      big_item_name: "",
      small_items: [],
      info: {
        create_date: null,
        create_user: "",
        update_date: null,
        update_user: "",
      },
    };
    return template;
  };
  // 新規登録ボタン
  const clickNew = () => {
    let initTemp = initTemplate();
    let itemList: TEMPLATE_ITEM[] = [{ id: 0, name: "", uuid: uuidv4(), photo_filepath_gcs: [], user_input_comment: "" }];
    initTemp.small_items = itemList;
    setTargetTemplateTitle("");
    setTmpTargetTemplate(initTemp);
    setEditFlg(false);
    setOpen2(true);
    isLimited(false);
  };
  // 編集ボタン
  const clickEdit = () => {
    if (targetTemplate) {
      let tmpSmallItems: TEMPLATE_ITEM[] = [];

      for (const item of targetTemplate.small_items) {
        tmpSmallItems.push(item);
      }
      let tmpTemp: TS_CHECK_ITEM_TEMPLATE = {
        id: targetTemplate.id,
        doc_id: targetTemplate.doc_id,
        template_name: targetTemplate.template_name,
        conditions: targetTemplate.conditions,
        big_item_id: targetTemplate.big_item_id,
        big_item_name: targetTemplate.big_item_name,
        small_items: tmpSmallItems,
        info: targetTemplate.info,
      };
      setTmpTargetTemplate(tmpTemp);
      setEditFlg(true);
      setOpen2(true);
      isLimited(tmpSmallItems.length >= 20);
    }
  };
  // テンプレート削除ボタン
  const clickDeleteTemplate = () => {
    if (targetTemplate) {
      setDialogMessage([
        "選択中のテンプレートを削除します。",
        "よろしいですか？",
      ]);
      setDialogFlg2(true);
      return;
    }
  };

    const onPhotoClick = (
      row: TEMPLATE_ITEM,
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      comment: string,
      filePath: string[]
    ) => {
      userLogging("店舗衛生点検表", "テンプレート#写真ダイアログ保存ボタン", "");
      row.photo_filepath_gcs = filePath;
      row.user_input_comment = comment;
    };

  return (
    <>
      {/*  ボタンここから↓*/}
      <Button variant="contained" onClick={handleOpen}>
        テンプレート
      </Button>
      {/*  ボタンここまで↑*/}

      {/*  登録・更新モーダルここから↓*/}
      <Modal
        open={open2}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalPane2}
      >
        <Grid container className={classes.paper2}>
          {/* 登録画面エラーダイアログ */}
          <TSCheckDialog
            msg={dialogMessage}
            isOpen={dialogFlg}
            doOK={dialogClose}
            doNo={dialogClose}
            isAlert={true}
          />
          {/*  ヘッダータイトルここから↓*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.titleGrid}
          >
            <Typography variant="h6" className={classes.titleTypography}>
              {editFlg ? "テンプレート編集" : "テンプレート新規登録"}
            </Typography>
          </Grid>
          {/*  ヘッダータイトルここまで↑*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              display: "flex",
              alignSelf: "flex-start",
              paddingTop: "10px",
            }}
          >
            <TextField
              placeholder="テンプレート名を入力"
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "300px" }}
              value={targetTemplateTitle}
              onChange={(e) => changeTemplateTitle(e)}
            />
          </Grid>
          <Grid
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              width: "100%",
              height: "50%",
            }}
          >
            {tmpTargetTemplate
              ? tmpTargetTemplate.small_items.map((row) => (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{
                      display: "flex",
                      alignSelf: "flex-start",
                      paddingTop: "10px",
                    }}
                  >
                    <Grid item xs={1}>
                      <IconButton onClick={() => clickDelete(row)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        placeholder="項目名を入力"
                        size="small"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ width: "45vw" }}
                        value={row.name}
                        onChange={(e) => changeTemplateItem(e, row)}
                      />
                    </Grid>
                    <Grid item xs={1} key={row.id + "_upload"}>
                      <Grid container justify="center" alignItems="center">
                        <TsCheckModalButton
                          title={row.name}
                          comment={
                            row.user_input_comment ? row.user_input_comment : ""
                          }
                          photoFilePath={
                            row.photo_filepath_gcs ? row.photo_filepath_gcs : []
                          }
                          onClickSave={(e, comment, filePath) =>
                            onPhotoClick(row, e, comment, filePath)
                          }
                          tenpoCode={row.uuid}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              : ""}
            <Grid container justify="center" ref={scrollBottomRef}></Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              display: "flex",
              alignSelf: "flex-start",
              paddingTop: "10px",
            }}
          >
            <IconButton
              onClick={clickAdd}
              disabled={limited}
              className={classes.greenButton}
              style={
                limited ? { color: "#fff", backgroundColor: "#C4C4C4" } : {}
              }
            >
              <Add></Add>
            </IconButton>
          </Grid>
          <Grid container justify="center">
            <Typography variant="caption" display="block" gutterBottom>
              ※最大20項目まで追加可能です。
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={6}>
              <Grid container justify="center">
                <Button
                  style={{ width: "20vw" }}
                  variant="contained"
                  fullWidth={true}
                  onClick={handleClose}
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="center">
                {getFixedFlg() ? (
                  ""
                ) : (
                  <Button
                    style={{ width: "20vw" }}
                    variant="contained"
                    fullWidth={true}
                    onClick={(e) => handleRegist(e)}
                    color="primary"
                    className={classes.greenButton}
                  >
                    {editFlg ? "更新" : "登録"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      {/*  登録・更新モーダル↑ここまで*/}

      {/*  テンプレート選択モーダルここから↓*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalPane}
      >
        <Grid container className={classes.paper}>
          {/* 削除確認ダイアログ */}
          <TSCheckDialog
            msg={dialogMessage}
            isOpen={dialogFlg2}
            doOK={deleteTemplate}
            doNo={dialogClose2}
            isAlert={false}
          />
          {/*  ヘッダータイトルここから↓*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.titleGrid}
          >
            <Typography variant="h6" className={classes.titleTypography}>
              テンプレート選択
            </Typography>
          </Grid>
          {/*  ヘッダータイトルここまで↑*/}

          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              display: "flex",
              alignSelf: "flex-start",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              style={{
                paddingLeft: "20px",
              }}
              lg={5}
              md={5}
              sm={12}
            >
              <Select
                style={{
                  minWidth: 320,
                }}
                onChange={(event) => doSelChange(event)}
                value={targetTemplate ? targetTemplate.id : ""}
                disabled={templateList.length !== 0 ? false : true}
              >
                {templateList.map((rowItem) => (
                  <MenuItem value={rowItem.id}>
                    {rowItem.template_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Grid container style={{ paddingTop: "5px" }}>
                <Grid
                  item
                  style={{
                    paddingLeft: "20px",
                  }}
                >
                  <Button
                    style={{ width: "100px" }}
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    className={classes.buttonSpace + " " + classes.greenButton}
                    onClick={() => clickEdit()}
                    disabled={targetTemplate ? false : true}
                  >
                    編集
                  </Button>
                </Grid>
                <Grid
                  item
                  style={{
                    paddingLeft: "20px",
                  }}
                >
                  <Button
                    style={{ width: "100px" }}
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    className={classes.buttonSpace + " " + classes.greenButton}
                    onClick={() => clickNew()}
                  >
                    新規登録
                  </Button>
                </Grid>


                <Grid
                  item
                  style={{
                    paddingLeft: "20px",
                  }}
                ></Grid>
                <Grid
                  item
                  style={{
                    paddingLeft: "20px",
                  }}
                >
                  <Button
                    style={{ width: "100px" }}
                    variant="contained"
                    fullWidth={true}
                    className={classes.buttonSpace + " " + classes.redButton}
                    onClick={() => clickDeleteTemplate()}
                    disabled={targetTemplate ? false : true}
                  >
                    削除
                  </Button>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justify="center"
            alignItems="flex-start"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              width: "100%",
              height: "40%",
            }}
          >
            <TextareaAutosize
              value={itemText}
              aria-label="store-input-comment-area"
              rowsMin={8}
              style={{
                width: "93%",
                fontSize: "16px",
                borderRadius: "8px",
                margin: "10px",
              }}
            />
          </Grid>

          <Grid container justify="center">
            <Grid item xs={6}>
              <Grid container justify="center">
                <Button
                  style={{ width: "20vw" }}
                  variant="contained"
                  fullWidth={true}
                  onClick={handleClose}
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="center">
                {getFixedFlg() ? (
                  ""
                ) : (
                  <Button
                    style={{ width: "20vw" }}
                    className={classes.greenButton}
                    variant="contained"
                    fullWidth={true}
                    onClick={(e) => handleSave(e)}
                    color="primary"
                    disabled={targetTemplate ? false : true}
                  >
                    項目反映
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      {/*  テンプレート選択モーダル↑ここまで*/}
    </>
  );
};
export default TSCheckTemplateModalButton;
