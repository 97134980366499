import React, { useEffect } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import {
  Tooltip,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { Grid, TableBody, TableHead, TableRow } from "@material-ui/core";
import {
  getOneVisionData,
  ONEVISIONTOTALSCORE,
} from "./OneVisionDataOperation";
import { roundScore } from "./OneVisionCalculation";
import { userLogging } from "../../../apis/userLog";

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    paddingBottom: "20px",
  },
  cell: {
    border: "none",
  },
  minTableCell: {
    paddingTop: "0",
    paddingBottom: "0",
    border: "1px solid #aaa",
  },
  right: {
    textAlign: "right",
  },
}));

const OneVisionTabGraphs: React.FC<{
  tenpoCode: string;
  oneVisionId: string;
  lastOneVisionId: string;
}> = ({ tenpoCode, oneVisionId, lastOneVisionId }) => {
  const classes = useStyles();
  const [scoreItemList, setScoreItemList] = React.useState<
    ONEVISIONTOTALSCORE[]
  >([]);
  const [graphData, setGraphData] = React.useState<any[]>([]);

  useEffect(() => {
    let weekScores: any = [];
    let graphData: any[] = [];
    userLogging("OneVision", "集計#初期表示", oneVisionId);
    getOneVisionData(tenpoCode, oneVisionId, lastOneVisionId).then(
      (res: any) => {
        weekScores = res.score_data.scores;
        setScoreItemList(weekScores);
        if (!lastOneVisionId || lastOneVisionId === "") {
          for (let i = 0; i < weekScores.length; i++) {
            if (Number(weekScores[i].no) && Number(weekScores[i].no) !== 0) {
              graphData.push({
                name: weekScores[i].name,
                今回: roundScore(weekScores[i].score),
              });
            }
          }
          setGraphData(graphData);
        }
      }
    );
    let lastWeekScores: any[] = [];
    if (lastOneVisionId && lastOneVisionId !== "") {
      getOneVisionData(tenpoCode, lastOneVisionId, "").then((res: any) => {
        lastWeekScores = res.score_data.scores;
        for (let i = 0; i < weekScores.length; i++) {
          let lastScore = 0;
          if (lastWeekScores.length > 0) {
            for (let j = 0; j < lastWeekScores.length; j++) {
              if (lastWeekScores[j].name === weekScores[i].name) {
                lastScore = lastWeekScores[j].score;
              }
            }
          }
          if (Number(weekScores[i].no) && Number(weekScores[i].no) !== 0) {
            graphData.push({
              name: weekScores[i].name,
              前回: roundScore(lastScore),
              今回: roundScore(weekScores[i].score),
            });
          }
        }
        setGraphData(graphData);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenpoCode, oneVisionId, lastOneVisionId]);

  // ReactStrinctModeでエラーが出ているけどいったん保留 React.StrictMode → React.Fragment にすれば直る
  return (
    <>
      {scoreItemList ? (
        <>
          <Grid container justify="center">
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              xs
              lg={5}
              md={8}
              sm={8}
            >
              <Table>
                <TableHead style={{ backgroundColor: "whitesmoke" }}>
                  <TableCell colSpan={2} className={classes.minTableCell}>
                    各項目の小計（1項目20点満点）
                  </TableCell>
                  <TableCell
                    className={classes.minTableCell}
                    style={{ textAlign: "center", minWidth: "40px" }}
                  >
                    点数
                  </TableCell>
                </TableHead>
                <TableBody>
                  {scoreItemList.map((row) => (
                    <>
                      <TableRow>
                        <TableCell className={classes.minTableCell}>
                          {row.no}
                        </TableCell>
                        <TableCell className={classes.minTableCell}>
                          {row.name}{" "}
                        </TableCell>
                        <TableCell
                          className={classes.minTableCell + " " + classes.right}
                        >
                          {roundScore(row.score)} 点
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              xs
              lg={6}
              md={12}
              sm={12}
            >
              <ResponsiveContainer width={600} height={300}>
                <RadarChart
                  cx="50%"
                  cy="50%"
                  outerRadius="80%"
                  data={graphData}
                  style={{ fontSize: "15px" }}
                >
                  <PolarGrid />
                  <PolarAngleAxis dataKey="name" />
                  <PolarRadiusAxis domain={[0, 20]} tickCount={6} />
                  <Radar dataKey="前回" stroke="#ed7d31" fillOpacity={0} />
                  <Radar dataKey="今回" stroke="#5b9bd5" fillOpacity={0} />
                  {/* <Radar
                    dataKey="score"
                    stroke="#5b9bd5"
                    fill="red"
                    fillOpacity={0.5}
                  /> */}
                  <Tooltip />
                </RadarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default OneVisionTabGraphs;
