/**
 * TenpoCheckTable.tsxのソースコード量が多くなっていたので
 * データ処理に関して別ファイルに分割した
 *
 */

import {
  deleteFirestoreDoc,
  getFirestoreDataWhereArrayAny,
  getFirestoreDocData,
  writeFirestoreDoc,
} from "../../../apis/firestoreAction";
import { v4 as uuidv4 } from "uuid";
import { firestore, getFirebaseUserInfo } from "../../../firebase";
import { zonedTimeToUtc } from "date-fns-tz";
import { userLogging } from "../../../apis/userLog";

// 店舗確認表個別項目の型
export interface TENPO_CHECK_DATA {
  tenpo_code: string;
  id: string;
  last_time_id: string;
  zone_code: string;
  do_code: string;
  approve_day: string;
  complete_date: string;
  last_approve_day: string;
  update_user: string;
  approve_weather: string;
  approve_hour:string;
  // 基本項目
  base_value: Array<TENPO_CHECK_BIG_RECORD>;
  // 追加項目
  add_value: Array<TENPO_CHECK_ADD_BIG_RECORD>;
}

// 店舗確認表の大項目単位の値の型
export interface TENPO_CHECK_BIG_RECORD {
  big_title: string;
  big_item_id: number;
  rows: Array<TENPO_CHECK_RECORD>;
}

// 店舗確認表の追加項目の大項目単位の値の型(拡張)
export interface TENPO_CHECK_ADD_BIG_RECORD extends TENPO_CHECK_BIG_RECORD {
  maximum_small_item_id: number;
  rows: Array<TENPO_CHECK_ADD_RECORD>;
}

// 店舗確認表の小項目単位の値の型
export interface TENPO_CHECK_RECORD {
  key: string; // 「2-3」のような「{大項目}-{小項目}」の形式
  big_item_id: number;
  small_title: string;
  small_item_id: number;
  score: string;
  button_value: string;
  last_score: string;
  last_button_value: string;
  photo_filepath_gcs: string[];
  manual: string[];
  user_input_comment: string;
  user_radio_comment: string;
  user_radio_target: string;
  reason_comment_list: SELECTMAP[];
  reason_target_list: SELECTMAP[];
  reason_comment_map_list: REASONMAP[];
  user_ng_list: any[];
  last_user_input_comment?: string;
  last_photo_filepath_gcs?: string[];
}
// 店舗確認表の詳細項目の値の型
export interface REASONMAP {
  value: string;
  comment_list: SELECTMAP[];
}
// 店舗確認表の選択項目の値の型
export interface SELECTMAP {
  name: string;
  value: string;
}
// 店舗確認表のNG項目の値の型
export interface USERNGMAP {
  user_radio_comment: string;
  user_radio_target: string;
}
// 店舗確認表の追加項目の大項目単位の値の型(拡張)
export interface TENPO_CHECK_ADD_RECORD extends TENPO_CHECK_RECORD {
  uuid: string;
}

// 店舗確認表のテンプレートの型
export interface TENPO_CHECK_ITEM_TEMPLATE {
  id: string;
  doc_id: string;
  template_name: string;
  conditions: string[];
  big_item_id: number;
  big_item_name: string;
  small_items: Array<TEMPLATE_ITEM>;
  info: {
    create_date: any;
    create_user: string;
    update_date: any;
    update_user: string;
  };
}
// テンプレート内の追加項目の型
export interface TEMPLATE_ITEM {
  id: number;
  name: string;
  uuid: string;
  photo_filepath_gcs: string[];
  user_input_comment: string;
}

//Todo 追加項目
const addItemBigRecordId: string = "008";
const addItemBigRecordDmId: string = "008_dm";
const addItemBigRecordZmId: string = "008_zm";

// Firestoreより3レコード取得して結果をマージし、UIに表示するデータを作る
export const getTenpoCheckData = async (
  tenpoCode: string,
  docId: string,
  lastDocId: string,
  fixedFlag: boolean,
  userPrivilege: string
) => {
  // 1:3レコード取得する
  // 大項目番号1～7の共通大項目の場合
  const baseItemValues = await getFirestoreDocData(
    "tenpo_check_items",
    "000000_basedata"
  );
  const addItemValues = await getFirestoreDocData(
    "tenpo_check_items",
    tenpoCode
  );
  let retDocId = null;
  if (docId) {
    retDocId = await getFirestoreDocData("tenpo_check_records", docId);
    if (!retDocId) {
      // firestoreからデータが取得できないとき（初回でデータがない場合）必要な情報を以下作成
      const baseItemValue: any = baseItemValues;
      let smallValueArray: Array<TENPO_CHECK_RECORD> = [];
      let bigValueArray: Array<TENPO_CHECK_BIG_RECORD> = [];
      Object.keys(baseItemValue.attribution)
        .sort()
        .forEach(function (key) {
          const bigValue: TENPO_CHECK_BIG_RECORD = {
            big_title: baseItemValue.attribution[key].big_item_name,
            big_item_id: baseItemValue.attribution[key].big_item_id,
            // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
            rows: makeBigRecordArray(
              baseItemValue.attribution[key].big_item_id,
              baseItemValue.attribution[key].small_items,
              smallValueArray,
              false
            ),
          };
          bigValueArray.push(bigValue);
        });
      let records: any = {};
      for (const bigRecord of bigValueArray) {
        for (const smallRecord of bigRecord.rows) {
          const keyValue: string = "records-" + smallRecord.key;
          records[keyValue] = smallRecord;
        }
      }
      let attribution: any = {
        approve_day: "",
        last_approve_day: "",
      };
      let update_date: any = {
        update_user: "",
      };
      retDocId = {
        records: records,
        attribution: attribution,
        update_date: update_date,
      };
    }
  }
  const docIdData: any = retDocId;
  let lastWeek: any = null;
  if (docIdData) {
    lastWeek = await getJsonlastWeeks(docIdData.docId);
  }
  const lastWeekRecords = lastWeek;
  return editData(
    baseItemValues,
    addItemValues,
    docIdData,
    tenpoCode,
    docId,
    lastDocId,
    fixedFlag,
    lastWeekRecords,
    userPrivilege || ""
  );
};

//前回の追加項目のuuidを検索して返す
function getLastAddItem(lastRecords: any, currentRecords: any) {
  let lastWeekRecord: any = null;
  const keys = lastRecords ? sortObject(lastRecords) : [];
  Object.keys(keys)
  .sort()
  .forEach(function (key: any) {
    //追加項目のuuidを検索
    if (keys[key]["uuid"] === currentRecords["uuid"]) {
      lastWeekRecord = keys[key];
    }
  });
  return lastWeekRecord;
}

const editData = (
  baseItemValues: any,
  addItemValues: any,
  docIdData: any,
  tenpoCode: string,
  docId: string,
  lastDocId: string,
  fixedFlag: boolean,
  lastWeekRecords: any,
  userPrivilege: string
): TENPO_CHECK_DATA => {
  let completeFlg = false;
  if (docIdData && docIdData.attribution.complete_date) {
    completeFlg = true;
  }
  const data: TENPO_CHECK_DATA = {
    tenpo_code: tenpoCode,
    id: docId,
    last_time_id: lastDocId,
    zone_code: "",
    do_code: "",
    approve_day: docIdData ? docIdData.attribution.approve_day : "",
    complete_date: docIdData ? docIdData.attribution.complete_date : "",
    last_approve_day: docIdData ? docIdData.attribution.last_approve_day : "",
    update_user: docIdData ? docIdData.update_date.update_user : "",
    approve_weather: docIdData ? docIdData.attribution.approve_weather : "",
    approve_hour:docIdData ? docIdData.attribution.approve_hour : "",
    base_value: [],
    add_value: [],
  };
  let smallValueArray: Array<TENPO_CHECK_RECORD> = [];
  let smallAddValueArray: Array<TENPO_CHECK_ADD_RECORD> = [];
  const keys = docIdData ? sortObject(docIdData["records"]) : [];
  Object.keys(keys)
    .sort()
    .forEach(function (key: any) {
      let recordKey = "records-" + keys[key]["key"];
      let lastWeekScore: string = "";
      let lastWeekButton: string = "";
      let lastUserInputComment: string = "";
      let lastPhotoFilepathGcs: string[] = [];
      if (lastWeekRecords[recordKey]) {
        lastWeekScore = lastWeekRecords[recordKey]["score"];
        lastWeekButton = lastWeekRecords[recordKey]["button_value"];
        lastUserInputComment = lastWeekRecords[recordKey]["user_input_comment"];
        lastPhotoFilepathGcs = lastWeekRecords[recordKey]["photo_filepath_gcs"];
      }
      if (keys[key]["big_item_id"] !== 8) {
        const smallValue: TENPO_CHECK_RECORD = {
          key: keys[key]["key"],
          big_item_id: keys[key]["big_item_id"],
          small_title: keys[key]["small_title"],
          small_item_id: keys[key]["small_item_id"],
          score: keys[key]["score"],
          button_value: keys[key]["button_value"],
          last_score: lastWeekScore,
          last_button_value: lastWeekButton,
          photo_filepath_gcs: keys[key]["photo_filepath_gcs"],
          manual: [],
          user_input_comment: keys[key]["user_input_comment"],
          user_radio_comment: keys[key]["user_radio_comment"],
          user_radio_target: keys[key]["user_radio_target"] || "",
          reason_comment_list: keys[key]["reason_comment_list"] || [],
          reason_target_list: keys[key]["reason_target_list"] || [],
          reason_comment_map_list: keys[key]["reason_comment_map_list"] || [],
          user_ng_list: keys[key]["user_ng_list"] || [],
          last_user_input_comment: lastUserInputComment,
          last_photo_filepath_gcs: lastPhotoFilepathGcs,
        };
        smallValueArray.push(smallValue);
      } else {
        // 前回の追加項目のuuidを検索して返す
        let lastWeekRecord = getLastAddItem(lastWeekRecords, keys[key]);
        //追加項目のuuidが合致した場合、同じbigItem-SmallItemとしてデータを保存
        if (lastWeekRecord !== null) {
          lastWeekScore = lastWeekRecord["score"];
          lastWeekButton = lastWeekRecord["button_value"];
          lastUserInputComment = lastWeekRecord["user_input_comment"];
          lastPhotoFilepathGcs = lastWeekRecord["photo_filepath_gcs"];
        }
        //uuidが合致しなかったものはないものとして扱う
        else {
          lastWeekScore = "";
          lastWeekButton = "";
          lastUserInputComment = "";
          lastPhotoFilepathGcs = [];
        }
        const smallValue: TENPO_CHECK_ADD_RECORD = {
          key: keys[key]["key"],
          big_item_id: keys[key]["big_item_id"],
          small_title: keys[key]["small_title"],
          small_item_id: keys[key]["small_item_id"],
          score: keys[key]["score"],
          button_value: keys[key]["button_value"],
          last_score: lastWeekScore,
          last_button_value: lastWeekButton,
          photo_filepath_gcs: keys[key]["photo_filepath_gcs"],
          manual: [],
          user_input_comment: keys[key]["user_input_comment"],
          user_radio_comment: keys[key]["user_radio_comment"],
          user_radio_target: keys[key]["user_radio_target"] || "",
          reason_comment_list: keys[key]["reason_comment_list"] || [],
          reason_target_list: keys[key]["reason_target_list"] || [],
          reason_comment_map_list: keys[key]["reason_comment_map_list"] || [],
          uuid: keys[key]["uuid"],
          user_ng_list: keys[key]["user_ng_list"] || [],
          last_user_input_comment: lastUserInputComment,
          last_photo_filepath_gcs: lastPhotoFilepathGcs,
        };
        smallAddValueArray.push(smallValue);
      }
    });

  let bigValueArray: Array<TENPO_CHECK_BIG_RECORD> = [];
  let bigValueAddArray: Array<TENPO_CHECK_ADD_BIG_RECORD> = [];
  // 大項目(共通大項目が10を超えるとソートが崩れるので注意!)
  Object.keys(baseItemValues.attribution)
    .sort()
    .forEach(function (key) {
      const bigValue: TENPO_CHECK_BIG_RECORD = {
        big_title: baseItemValues.attribution[key].big_item_name,
        big_item_id: baseItemValues.attribution[key].big_item_id,
        // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
        rows: makeBigRecordArray(
          baseItemValues.attribution[key].big_item_id,
          baseItemValues.attribution[key].small_items,
          smallValueArray,
          completeFlg
        ),
      };
      bigValueArray.push(bigValue);
    });
  // 追加項目
  const bigAddValue = (): TENPO_CHECK_ADD_BIG_RECORD => {
    let addItemUserPrivilege = userPrivilege;
    if (fixedFlag) {
      addItemUserPrivilege = docIdData
        ? docIdData.attribution.user_privilege
        : "";
    }
    let itemBigRecordId = addItemBigRecordId;
    if (addItemUserPrivilege === "dm") {
      itemBigRecordId = addItemBigRecordDmId;
    } else if (addItemUserPrivilege === "zm") {
      itemBigRecordId = addItemBigRecordZmId;
    }
    if (fixedFlag) {
      return {
        big_title: addItemValues.attribution[itemBigRecordId]["big_item_name"],
        big_item_id: addItemValues.attribution[itemBigRecordId]["big_item_id"],
        maximum_small_item_id: getAddValuesMaxId(
          addItemValues.attribution[itemBigRecordId]["small_items"]
        ),
        // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
        rows: smallAddValueArray,
      };
    } else if (
      addItemValues &&
      addItemValues.attribution[itemBigRecordId] &&
      addItemValues.attribution[itemBigRecordId]["big_item_id"]
    ) {
      return {
        big_title: addItemValues.attribution[itemBigRecordId]["big_item_name"],
        big_item_id: addItemValues.attribution[itemBigRecordId]["big_item_id"],
        maximum_small_item_id: getAddValuesMaxId(
          addItemValues.attribution[itemBigRecordId]["small_items"]
        ),
        // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
        rows: linkItemArray(smallAddValueArray, itemBigRecordId, addItemValues),
      };
    } else {
      const temp: TENPO_CHECK_ADD_BIG_RECORD = {
        big_title: "商品・売場・地区政策等",
        big_item_id: 8,
        maximum_small_item_id: 0,
        rows: [],
      };
      const addItem = {
        big_item_name: "商品・売場・地区政策等",
        big_item_id: 8,
        maximum_small_item_id: 0,
        small_items: linkItemArray([], itemBigRecordId, addItemValues),
      };
      const objects: any = {};
      let recordItem = {};
      let recordItemDm = {};
      let recordItemZm = {};
      if (addItemValues) {
        recordItem = addItemValues["attribution"][addItemBigRecordId] || {};
        recordItemDm = addItemValues["attribution"][addItemBigRecordDmId] || {};
        recordItemZm = addItemValues["attribution"][addItemBigRecordZmId] || {};
      }
      if (userPrivilege === "dm") {
        objects["attribution"] = {
          "008": recordItem,
          "008_dm": addItem,
          "008_zm": recordItemZm,
        };
      } else if (userPrivilege === "zm") {
        objects["attribution"] = {
          "008": recordItem,
          "008_dm": recordItemDm,
          "008_zm": addItem,
        };
      } else {
        objects["attribution"] = {
          "008": addItem,
          "008_dm": recordItemDm,
          "008_zm": recordItemZm,
        };
      }
      if (!fixedFlag) {
        userLogging("店舗確認表", "新規作成#tenpo_check_itemsテーブル追加", JSON.stringify(objects));
        writeFirestoreDoc("tenpo_check_items", tenpoCode, objects);
      }
      return temp;
    }
  };
  bigValueAddArray.push(bigAddValue());
  data["base_value"] = bigValueArray;
  data["add_value"] = bigValueAddArray;
  return data;
};

// 追加項目の項目連携
const linkItemArray = (
  smallAddValueArray: Array<TENPO_CHECK_ADD_RECORD>,
  itemBigRecordId: string,
  addItemValues: any
) => {
  if (!addItemValues || !addItemValues["attribution"][itemBigRecordId]) {
    return [];
  }
  let resultArray: Array<TENPO_CHECK_ADD_RECORD> = [];
  let smallItems = addItemValues["attribution"][itemBigRecordId]["small_items"];
  if (smallItems) {
    for (let i = 0; i < smallItems.length; i++) {
      let recordFlg = false;
      let keyNum = Number(i) + 1;
      for (const val of smallAddValueArray) {
        if (smallItems[i].uuid === val.uuid) {
          recordFlg = true;
          val.key = "8-" + keyNum;
          val.small_item_id = keyNum;
          resultArray.push(val);
          break;
        }
      }
      if (!recordFlg) {
        const smallValue: TENPO_CHECK_ADD_RECORD = {
          key: "8-" + keyNum,
          big_item_id: 8,
          small_title: smallItems[i].name,
          small_item_id: keyNum,
          score: "",
          button_value: "",
          last_score: "",
          last_button_value: "",
          photo_filepath_gcs: [],
          manual: [],
          user_input_comment: "",
          user_radio_comment: "",
          user_radio_target: "",
          reason_comment_map_list:[],
          reason_comment_list: [],
          reason_target_list: [],
          uuid: smallItems[i]["uuid"],
          user_ng_list: [],
        };
        resultArray.push(smallValue);
      }
    }
  }
  return resultArray;
};

// レコードを作る関数
const makeBigRecordArray = (
  bigItemId: string,
  smallItems: any,
  smallValueArray: Array<TENPO_CHECK_RECORD>,
  completeFlg: boolean
): Array<TENPO_CHECK_RECORD> => {
  const searchRecord = (
    bigItemId: string,
    smallItemId: string,
    smallItemTitle: string,
    manual?: any[],
    reason_comment_list?: any[],
    reason_target_list?: any[],
    reason_comment_map_list?: any[]
  ) => {
    const key = bigItemId + "-" + smallItemId;
    for (const row of smallValueArray) {
      if (row["key"] === key) {
        row["small_title"] = smallItemTitle;
        if (manual) {
          row["manual"] = manual;
        }
        if (reason_comment_list) {
          row["reason_comment_list"] = reason_comment_list;
        }
        if (!completeFlg && reason_comment_map_list) {
          row["reason_comment_map_list"] = reason_comment_map_list;
        }
        if (reason_target_list) {
          row["reason_target_list"] = reason_target_list;
        }
        return row;
      }
    }
    const tmp: TENPO_CHECK_RECORD = {
      key,
      big_item_id: Number(bigItemId),
      small_title: smallItemTitle,
      small_item_id: Number(smallItemId),
      score: "0",
      button_value: "0",
      last_score: "0",
      last_button_value: "0",
      photo_filepath_gcs: [],
      manual: [],
      user_input_comment: "",
      user_radio_comment: "",
      user_radio_target: "",
      reason_comment_list: [],
      reason_target_list: [],
      reason_comment_map_list: [],
      user_ng_list: [],
    };
    return tmp;
  };
  let array: Array<TENPO_CHECK_RECORD> = [];
  for (const row of smallItems) {
    const val = searchRecord(
      bigItemId,
      row.id,
      row.name,
      row.manual,
      row.reason_comment_list,
      row.reason_target_list,
      row.reason_comment_map_list
    );
    if (val !== undefined) {
      array.push(val);
    }
  }
  return array;
};

// レコードを作る関数
const makeAddBigRecordArray = (
  bigItemId: string,
  smallItems: any,
  smallValueArray: Array<TENPO_CHECK_ADD_RECORD>
): Array<TENPO_CHECK_ADD_RECORD> => {
  const searchRecord = (
    bigItemId: string,
    smallItemId: string,
    smallItemTitle: string,
    uuid: string
  ) => {
    const key = bigItemId + "-" + smallItemId;
    for (const row of smallValueArray) {
      if (row["uuid"] === uuid) {
        row["small_title"] = smallItemTitle;
        row["uuid"] = uuid;
        return row;
      }
    }
    const tmp: TENPO_CHECK_ADD_RECORD = {
      key,
      big_item_id: Number(bigItemId),
      small_title: smallItemTitle,
      small_item_id: Number(smallItemId),
      score: "0",
      button_value: "0",
      last_score: "0",
      last_button_value: "0",
      photo_filepath_gcs: [],
      manual: [],
      user_input_comment: "",
      user_radio_comment: "",
      user_radio_target: "",
      reason_comment_list: [],
      reason_target_list: [],
      reason_comment_map_list: [],
      uuid: "",
      user_ng_list: [],
    };
    return tmp;
  };
  let array: Array<TENPO_CHECK_ADD_RECORD> = [];
  for (const row of smallItems) {
    const val = searchRecord(bigItemId, row.id, row.name, row.uuid);
    if (val !== undefined) {
      array.push(val);
    }
  }
  return array;
};

// 追加項目の小項目の最大の値を取得する
const getAddValuesMaxId = (smallItems: any): number => {
  let maxNumber = 0;
  if (smallItems) {
    for (const row of smallItems) {
      if (row.id > maxNumber) maxNumber = row.id;
    }
  }
  return maxNumber;
};

// 配列の順番を整えるため、ここだけ連想配列キーを「xx-yy(例:02-11)」のような形にする
const sortObject = (objects: any) => {
  let newObj: any = {};
  Object.keys(objects).forEach(function (key) {
    const tempKey =
      ("00" + objects[key]["big_item_id"]).slice(-2) +
      ("00" + objects[key]["small_item_id"]).slice(-2);
    newObj[tempKey] = objects[key];
  });
  return newObj;
};

/**
 * 店舗確認表の変更項目を差分更新し、最終的にReactHooksに保存する
 * @param tenpoCheckData 店舗確認表(基本項目)の全てのデータ
 * @returns
 */
export const updateTenpoCheck = (
  tenpoCheckData: TENPO_CHECK_BIG_RECORD[],
  newData: TENPO_CHECK_RECORD
): TENPO_CHECK_BIG_RECORD[] => {
  const newTenpoCheckData: TENPO_CHECK_BIG_RECORD[] = [];
  for (const bigRecord of tenpoCheckData) {
    let newArray: TENPO_CHECK_RECORD[] = [];
    for (const record of bigRecord.rows) {
      if (record.key === newData.key) {
        newArray.push(newData);
      } else {
        newArray.push(record);
      }
    }
    bigRecord.rows = newArray;
    newTenpoCheckData.push(bigRecord);
  }
  return newTenpoCheckData;
};

/**
 * 店舗確認表の変更項目を差分更新し、最終的にReactHooksに保存する
 * @param tenpoCheckData 店舗確認表(追加項目)の全てのデータ
 * @param newNumber -1の場合は新規追加して最後尾にpushする
 * @returns
 */
export const updateTenpoCheckAddValue = (
  tenpoCheckData: TENPO_CHECK_ADD_BIG_RECORD[],
  newData: TENPO_CHECK_ADD_RECORD,
  isNew: boolean = false
) => {
  const newTenpoCheckData: TENPO_CHECK_ADD_BIG_RECORD[] = [];
  for (const bigRecord of tenpoCheckData) {
    let newArray: TENPO_CHECK_ADD_RECORD[] = [];
    for (const addRecord of bigRecord.rows) {
      if (addRecord.key === newData.key && !isNew) {
        newArray.push(newData);
      } else {
        newArray.push(addRecord);
      }
    }
    if (isNew) {
      newArray.push(newData);
    }
    bigRecord.rows = newArray;
    newTenpoCheckData.push(bigRecord);
  }
  return newTenpoCheckData;
};

/**
 * 店舗確認表の値を受け取り、Firestoreの更新を行う
 * @param basicItemList 基本項目の配列
 * @param addRowItemList 追加項目の配列
 *
 */
export const updateTenpoCheckFirestore = (
  rowItemList: Array<TENPO_CHECK_BIG_RECORD>,
  addRowItemList: Array<TENPO_CHECK_BIG_RECORD>,
  tenpoCheckId: string,
  totalScore: number,
  tenpoCheckLastTimeId: string,
  selectedTenpoCode: string,
  approveDay: string,
  lastApproveDay: string,
  userPrivilege: string,
  approveWeather: string,
  approveHour:string
) => {
  if (
    (Array.isArray(rowItemList) && rowItemList.length === 0) ||
    tenpoCheckId === ""
  ) {
    // 初回読み込みで呼ばれてしまうので空であれば終了する
    return;
  }
  const objects: any = {};
  objects["attribution"] = {
    conditions: ["ALL"],
    last_time_id: tenpoCheckLastTimeId,
    tenpo_code: selectedTenpoCode,
    total_score: String(totalScore),
    approve_day: approveDay,
    last_approve_day: lastApproveDay,
    user_privilege: userPrivilege,
    approve_weather: approveWeather,
    approve_hour: approveHour
  };
  objects["id"] = tenpoCheckId;
  objects["docId"] = tenpoCheckId;
  objects["update_date"] = {
    update_date: firestore.FieldValue.serverTimestamp(),
    update_user: getFirebaseUserInfo().uid,
  };
  let records: any = {};
  for (const bigRecord of rowItemList) {
    for (const smallRecord of bigRecord.rows) {
      const keyValue: string = "records-" + smallRecord.key;
      records[keyValue] = smallRecord;
    }
  }
  for (const bigRecord of addRowItemList) {
    for (const smallRecord of bigRecord.rows) {
      const keyValue: string = "records-" + smallRecord.key;
      records[keyValue] = smallRecord;
    }
  }
  objects["records"] = records;
  userLogging(
    "店舗確認表", "新規作成#tenpo_check_recordsテーブル更新",
    JSON.stringify(objects)
  );
  writeFirestoreDoc("tenpo_check_records", tenpoCheckId, objects);
};

// 追加ボタン押下時の新規レコード追加処理
export const createNewRecord = (
  small_title: string,
  addRowItemLArray: TENPO_CHECK_ADD_BIG_RECORD,
  photo_filepath_gcs: string[],
  user_input_comment: string,
  uuid?: string
): TENPO_CHECK_ADD_RECORD => {
  const big_item_id = 8;
  let maxSmallId = 0;
  for (const row of addRowItemLArray.rows) {
    if (Number(row.key.split("-")[1]) > maxSmallId) {
      maxSmallId = Number(row.key.split("-")[1]);
    }
  }
  const new_small_item_id = maxSmallId + 1;
  const val: TENPO_CHECK_ADD_RECORD = {
    key: big_item_id + "-" + new_small_item_id,
    big_item_id: big_item_id,
    small_title: small_title,
    small_item_id: Number(new_small_item_id),
    score: "0",
    button_value: "0",
    last_score: "0",
    last_button_value: "0",
    photo_filepath_gcs: photo_filepath_gcs ? photo_filepath_gcs : [],
    manual: [],
    user_input_comment: user_input_comment ? user_input_comment : "",
    user_radio_comment: "",
    user_radio_target: "",
    reason_comment_list: [],
    reason_target_list: [],
    reason_comment_map_list: [],
    uuid: uuidv4(),
    user_ng_list: [],
  };
  return val;
};

// 削除ボタン押下時
export const deleteAddItems = (
  deleteClickList: string[],
  addRowItemList: TENPO_CHECK_ADD_BIG_RECORD[]
) => {
  let resultList = [];
  for (const addRecord of addRowItemList[0].rows) {
    if (!deleteClickList.includes(addRecord.key)) {
      resultList.push(addRecord);
    }
  }
  addRowItemList[0].rows = resultList;
  return addRowItemList;
};

// ソートボタン押下時
export const sortAddItems = (
  addRowItemList: TENPO_CHECK_ADD_BIG_RECORD[],
  isUp: boolean,
  key: string
) => {
  // 上から順番を取得する
  let array: TENPO_CHECK_ADD_RECORD[] = addRowItemList[0].rows;
  let order: number = 0;
  for (const addRecord of array) {
    if (key === addRecord.key) {
      // 0始まりの順番
      break;
    }
    order++;
  }
  let nextOrderKey: string = key;
  // 前に移動
  if (isUp) {
    let x = order,
      y = order - 1;
    if (y < 0) {
      y = order;
    }
    let temp = array[order];
    const xKey = array[x].key;
    const yKey = array[y].key;
    nextOrderKey = yKey;
    array[x] = array[y];
    array[y] = temp;
    array[x].key = xKey;
    array[y].key = yKey;
  } else if (isUp === false) {
    let x = order,
      y = order + 1;
    if (array.length === y) {
      y = order;
    }
    let temp = array[order];
    const xKey = array[x].key;
    const yKey = array[y].key;
    nextOrderKey = yKey;
    array[x] = array[y];
    array[y] = temp;
    array[x].key = xKey;
    array[y].key = yKey;
  }
  addRowItemList[0].rows = array;
  return { addRowItemList, nextKey: nextOrderKey };
};

export const getTenpoFirestoreData = async (tenpoCode: string) => {
  const data = await getFirestoreDocData("existing_sej_stores", tenpoCode).then(
    (response: any) => {
      return response;
    }
  );
  return data;
};

// 追加項目のFirestoreデータ更新を行う
export const updateTenpoCheckAddItem = async (
  addRowItemList: TENPO_CHECK_ADD_BIG_RECORD[],
  tenpoCode: string,
  userPrivilege: string
) => {
  let addItem = [];
  for (const row of addRowItemList[0].rows) {
    addItem.push({
      id: row.key.split("-")[1],
      name: row.small_title,
      uuid: row.uuid,
    });
  }
  // 店舗確認表項目の取得を行う
  const addItemValues = await getFirestoreDocData(
    "tenpo_check_items",
    tenpoCode
  ).then((response: any) => {
    return response;
  });

  let itemBigRecordId = addItemBigRecordId;
  if (userPrivilege === "dm") {
    itemBigRecordId = addItemBigRecordDmId;
  } else if (userPrivilege === "zm") {
    itemBigRecordId = addItemBigRecordZmId;
  }
  addItemValues["attribution"][itemBigRecordId]["small_items"] = addItem;
  userLogging("店舗確認表", "新規作成#tenpo_check_itemsテーブル更新", JSON.stringify(addItemValues));

  writeFirestoreDoc("tenpo_check_items", tenpoCode, addItemValues);
};

const FORMAT = "YYYY/MM/DD HH:mm:ss";

export function getFirstDayOfWeek(target_date: string, addDate: number) {
  // let targetYear = target_date.substring(0, 4);
  // let targetMonth = target_date.substring(4, 6);
  // let targetDate = target_date.substring(6, 8);
  // let today = new Date(targetYear + "/" + targetMonth + "/" + targetDate + " 00:00:00");
  // 日付取得
  let today = zonedTimeToUtc(target_date, "Asia/Tokyo");
  let this_year = today.getFullYear();
  let this_month = today.getMonth();
  let date = today.getDate();
  let day_num = today.getDay();
  // Sunday - Saturday : 0 - 6
  if (day_num === 0) {
    day_num = 7;
  }
  let this_monday = date - day_num + 1 + addDate;

  //月曜日の年月日
  let firstDayOfWeek = new Date(this_year, this_month, this_monday);
  let year = firstDayOfWeek.getFullYear() + "";
  let month =
    firstDayOfWeek.getMonth() + 1 < 10
      ? "0" + (firstDayOfWeek.getMonth() + 1)
      : firstDayOfWeek.getMonth() + 1 + "";
  let day =
    firstDayOfWeek.getDate() < 10
      ? "0" + firstDayOfWeek.getDate()
      : firstDayOfWeek.getDate();
  //文字列を作成
  return year + month + day;
}

async function getJsonlastWeeks(last_time_id: string) {
  let retDocItem: any = null;
  let retRecords: any = {};
  let base_week: string = "";
  // 過去1週分取得する
  let n: number = 1;
  for (let i: number = 0; i < n; ) {
    try {
      if (last_time_id) {
        retDocItem = await getFirestoreDocData(
          "tenpo_check_records",
          last_time_id
        );
        if (retDocItem) {
          if (base_week === "") {
            base_week = getFirstDayOfWeek(
              retDocItem.attribution.approve_day,
              0
            );
          }
          let target_week = getFirstDayOfWeek(
            retDocItem.attribution.approve_day,
            0
          );
          let last_weeks = getFirstDayOfWeek(base_week, -7 * i);
          let complete_date = retDocItem.attribution.complete_date;
          last_time_id = retDocItem.attribution.last_time_id;
          if (complete_date && last_weeks !== target_week) {
            retRecords = retDocItem.records;
            i++;
          }
        }
      } else {
        break;
      }
    } catch {
      break;
    }
  }
  return retRecords;
}

// テンプレート一覧取得
export const getTemplateData = async (conditions: string[]) => {
  let templateArray: TENPO_CHECK_ITEM_TEMPLATE[] = [];
  if (conditions.length === 0) {
    return templateArray;
  }
  const getTmpTempData = async (con: string[]) => {
    await getFirestoreDataWhereArrayAny(
      "tenpo_check_items_template",
      "attribution.conditions",
      con
    ).then((response: any) => {
      response.forEach((res: any) => {
        let data = res.data();
        let docID = data.doc_id;
        let skipFlg = false;
        for (let i = 0; i < templateArray.length; i++) {
          if (docID === templateArray[i].doc_id) {
            skipFlg = true;
          }
        }
        if (skipFlg) {
          return;
        }
        const templateValue: TENPO_CHECK_ITEM_TEMPLATE = {
          id: data.id,
          doc_id: data.doc_id,
          template_name: data.attribution.template_name,
          big_item_id: data.attribution.big_item_id,
          big_item_name: data.attribution.big_item_name,
          conditions: data.attribution.conditions,
          small_items: data.attribution.small_items,
          info: data.info,
        };
        templateArray.push(templateValue);
      });
      return templateArray;
    });
  };

  if (conditions && conditions.length > 10) {
    let workConditions = [];
    for (let i = 0; i < conditions.length; i++) {
      workConditions.push(conditions[i]);
      if (workConditions.length % 10 === 0 || i + 1 === conditions.length) {
        await getTmpTempData(workConditions);
        workConditions = [];
      }
    }
  } else {
    await getTmpTempData(conditions);
  }
  return templateArray;
};

// テンプレートのFirestoreデータ更新を行う
export const updateTenpoCheckTemplate = async (
  templateItem: TENPO_CHECK_ITEM_TEMPLATE,
  newFlg?: boolean
) => {
  const objects: any = {};
  objects["attribution"] = {
    big_item_id: templateItem.big_item_id,
    big_item_name: templateItem.big_item_name,
    template_name: templateItem.template_name,
    conditions: templateItem.conditions,
    small_items: templateItem.small_items,
  };
  objects["id"] = templateItem.id;
  objects["doc_id"] = templateItem.doc_id;
  objects["info"] = {
    create_date: newFlg
      ? firestore.FieldValue.serverTimestamp()
      : templateItem.info
      ? templateItem.info.create_date
      : null,
    create_user: newFlg
      ? getFirebaseUserInfo().uid
      : templateItem.info
      ? templateItem.info.create_user
      : "",
    update_date: firestore.FieldValue.serverTimestamp(),
    update_user: getFirebaseUserInfo().uid,
  };

  userLogging("店舗確認表", "新規作成#tenpo_check_items_templateテーブル更新", JSON.stringify(objects));
  writeFirestoreDoc("tenpo_check_items_template", templateItem.doc_id, objects);
};

// テンプレートのFirestoreデータ削除を行う
export const deleteTenpoCheckTemplate = async (doc_id: string) => {
  deleteFirestoreDoc("tenpo_check_items_template", doc_id);
};
