import React, { FC, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Typography, Box, Grid } from "@material-ui/core";
import { getFixedFlg } from "../../Header/Header";
import TSCheckTable, { isApproveDate, isApproveHour } from "./TSCheckTable";
import TSCheckResultGraphs from "./TSCheckResultGraphs";
import TSCheckResultPrint from "./TSCheckResultPrint";
import { getChangeFlg } from "./TSCheckTable";
import TSCheckDialog from "../TSCheckDialog";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
      style={{
        fontSize: "20px",
      }}
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  graphsPane: {
    height: "70vh",
    overflowY: "auto",
  },
}));

const TenpoCheckTab: FC<{
  tenpoCode: string;
  tsCheckId: string;
  lastTSCheckId: string;
  setUpdateFlg: any;
}> = ({ tenpoCode, tsCheckId, lastTSCheckId, setUpdateFlg }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  const dialogClose = () => {
    setDialogFlg(false);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (getChangeFlg()) {
      setDialogMessage(["保存されていません。保存ボタンを押してください。"]); //旧承認日
      setDialogFlg(true);
      return;
    }
    if (!getChangeFlg() && newValue !== 0) {
      if (!getFixedFlg() && isApproveDate() === 0) {
        setDialogMessage(["作成日に前回作成日以降の日付を入力してください"]); //旧承認日
        setDialogFlg(true);
        return;
      }
      if (!getFixedFlg() && isApproveDate() === -1) {
        setDialogMessage(["作成日に正しい日付を入力してください"]); //旧承認日
        setDialogFlg(true);
        return;
      }
      if (!getFixedFlg() && isApproveHour() === -1) {
        setDialogMessage(["作成時間を入力してください"]);
        setDialogFlg(true);
        return;
      }
    }
    setValue(newValue);
  };

  // 店番変更時のタブリセット
  useEffect(() => {
    setValue(0);
  }, [tenpoCode]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#37ab9d",
              height: "10px",
            },
          }}
          style={{
            backgroundColor: "#f2f2f2",
            color: "#3e3e3e",
          }}
        >
          <LinkTab label="チェック" href="/check" {...a11yProps(0)} />
          <LinkTab label="集計" href="/summery" {...a11yProps(1)} />
          <LinkTab label="提出（印刷）" href="/print" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TSCheckTable
          tenpoCode={tenpoCode}
          tsCheckId={tsCheckId}
          lastTSCheckId={lastTSCheckId}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container justify="center" className={classes.graphsPane}>
          <TSCheckResultGraphs
            tenpoCode={tenpoCode}
            tsCheckId={tsCheckId}
            lastTSCheckId={lastTSCheckId}
          />
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TSCheckResultPrint
          tenpoCode={tenpoCode}
          tsCheckId={tsCheckId}
          lastTSCheckId={lastTSCheckId}
          setUpdateFlg={setUpdateFlg}
        />
      </TabPanel>

      {/* ダイアログ */}
      <TSCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </div>
  );
};
export default TenpoCheckTab;
