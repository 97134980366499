import {
  getFirestoreDocData,
  writeFirestoreDoc,
} from "../../../apis/firestoreAction";
import { firestore, getFirebaseUserInfo } from "../../../firebase";
import { userLogging } from "../../../apis/userLog";
import { zonedTimeToUtc } from "date-fns-tz";

// ONEVISION個別項目の型
interface ONEVISIONDATA {
  tenpo_code: string;
  id: string;
  last_time_id: string;
  last_date: string;
  zone_code: string;
  do_code: string;
  approve_day: string;
  last_approve_day: string;
  update_user: string;
  agreement_day: string;
  last_agreement_day: string;
  regulation_day: string;
  last_regulation_day: string;
  rows?: Array<ONEVISIONBIGRECORD>;
  other_data: ONEVISIONOTHERDATA;
  score_data: ONEVISIONSCOREDATA;
}

// ONEVISIONの大項目単位の値の型
export interface ONEVISIONBIGRECORD {
  big_title: string;
  big_item_id: number;
  big_item_score_no: string;
  big_item_score_name: string;
  score_pattern: string;
  rows: Array<ONEVISIONRECORD>;
}

// ONEVISIONの小項目単位の値の型
export interface ONEVISIONRECORD {
  key: string; // 「2-3」のような「{大項目}-{小項目}」の形式
  big_item_id: number;
  small_title1: string;
  small_title2: string;
  small_item_id: number;
  score: string;
  score_pattern: Array<SCOREMAP>;
  last_score: string;
  photo_filepath_gcs: string[];
}
// ONEVISIONの項目の点数の値の型
export interface SCOREMAP {
  id: number;
  name: string;
  value: string;
}

// ONEVISIONの合計得点の値の型
interface ONEVISIONSCOREDATA {
  total_score?: string;
  scores?: Array<ONEVISIONTOTALSCORE>;
}
// ONEVISIONの項目ごとの合計点数の値の型
export interface ONEVISIONTOTALSCORE {
  no: string;
  name: string;
  score: string;
}
// ONEVISIONの大項目単位の値の型
export interface ONEVISIONOTHERDATA {
  task?: string;
  measures?: string;
  last_necessary_man_hour_date?: number;
  last_necessary_man_hour_week?: number;
  last_shortage_man_hour_date?: number;
  last_shortage_man_hour_week?: number;
  last_employee?: number;
  last_recruit?: number;
  last_retire?: number;
  necessary_man_hour_date?: number;
  necessary_man_hour_week?: number;
  shortage_man_hour_date?: number;
  shortage_man_hour_week?: number;
  employee?: number;
  recruit?: number;
  retire?: number;
}
// Firestoreより2レコード取得して結果をマージする
export const getOneVisionData = async (
  tenpoCode: string,
  docId: string,
  lastDocId: string
) => {
  // 1:2レコード取得する
  // 大項目番号1～7の共通大項目の場合
  const baseItemValues = await getFirestoreDocData(
    "one_vision_items",
    "000000_basedata"
  ).then((response: any) => {
    return response;
  });
  const docIdData = docId
    ? await getFirestoreDocData("one_vision_records", docId).then(
        (response: any) => {
          return response;
        }
      )
    : null;

  let lastMonth: any = null;
  if (docIdData) {
    lastMonth = await getJsonlastMonths(docIdData.docId);
  }
  const lastMonthRecords = lastMonth;
  return editData(baseItemValues, docIdData, tenpoCode, docId, lastDocId, lastMonthRecords);
};
const editData = (
  baseItemValues: any,
  docIdData: any,
  tenpoCode: string,
  docId: string,
  lastDocId: string,
  lastMonthRecords: any
) => {
  const data: ONEVISIONDATA = {
    tenpo_code: tenpoCode,
    id: docId,
    last_time_id: lastDocId,
    last_date: docIdData ? docIdData.attribution.last_date : "",
    zone_code: "",
    do_code: "",
    approve_day: docIdData ? docIdData.attribution.approve_day : "",
    last_approve_day: docIdData ? docIdData.attribution.last_approve_day : "",
    update_user: docIdData ? docIdData.update_date.update_user : "",
    agreement_day: docIdData ? docIdData.attribution.agreement_day : "",
    last_agreement_day: docIdData
      ? docIdData.attribution.last_agreement_day
      : "",
    regulation_day: docIdData ? docIdData.attribution.regulation_day : "",
    last_regulation_day: docIdData
      ? docIdData.attribution.last_regulation_day
      : "",
    other_data: {},
    score_data: {},
    rows: [],
  };
  let smallValueArray: Array<ONEVISIONRECORD> = [];
  const keys = docIdData ? sortObject(docIdData["records"]) : [];
  Object.keys(keys)
    .sort()
    .forEach(function (key: any) {
      let recordKey = "records-" + keys[key]["key"];
      let lastMonthScore = ""
      if (lastMonthRecords && lastMonthRecords[recordKey]) {
        lastMonthScore = lastMonthRecords[recordKey]["score"];
      }
      const smallValue: ONEVISIONRECORD = {
        key: keys[key]["key"],
        big_item_id: keys[key]["big_item_id"],
        small_title1: keys[key]["small_title1"],
        small_title2: keys[key]["small_title2"],
        small_item_id: keys[key]["small_item_id"],
        score: keys[key]["score"],
        score_pattern: keys[key]["score_pattern"],
        last_score: lastMonthScore,
        photo_filepath_gcs: keys[key]["photo_filepath_gcs"],
      };
      smallValueArray.push(smallValue);
    });

  let othersValue: ONEVISIONOTHERDATA = {};
  const othersKeys = docIdData ? docIdData["other_data"] : {};
  if (othersKeys) {
    othersValue = {
      task: othersKeys["task"] || "",
      measures: othersKeys["measures"] || "",
      last_necessary_man_hour_date:
        othersKeys["last_necessary_man_hour_date"] || 0,
      last_necessary_man_hour_week:
        othersKeys["last_necessary_man_hour_week"] || 0,
      last_shortage_man_hour_date:
        othersKeys["last_shortage_man_hour_date"] || 0,
      last_shortage_man_hour_week:
        othersKeys["last_shortage_man_hour_week"] || 0,
      last_employee: othersKeys["last_employee"] || 0,
      last_recruit: othersKeys["last_recruit"] || 0,
      last_retire: othersKeys["last_retire"] || 0,
      necessary_man_hour_date: othersKeys["necessary_man_hour_date"] || 0,
      necessary_man_hour_week: othersKeys["necessary_man_hour_week"] || 0,
      shortage_man_hour_date: othersKeys["shortage_man_hour_date"] || 0,
      shortage_man_hour_week: othersKeys["shortage_man_hour_week"] || 0,
      employee: othersKeys["employee"] || 0,
      recruit: othersKeys["recruit"] || 0,
      retire: othersKeys["retire"] || 0,
    };
  }
  data["other_data"] = othersValue;

  let bigValueArray: Array<ONEVISIONBIGRECORD> = [];
  let scoresValueArray: Array<ONEVISIONTOTALSCORE> = [];
  const scoreMap = docIdData ? docIdData["score_data"] : {};
  const scoreKeys = scoreMap ? scoreMap["scores"] : [];
  if (scoreKeys) {
    Object.keys(scoreKeys)
      .sort()
      .forEach(function (key: any) {
        const smallValue: ONEVISIONTOTALSCORE = {
          no: scoreKeys[key]["no"],
          name: scoreKeys[key]["name"],
          score: scoreKeys[key]["score"],
        };
        scoresValueArray.push(smallValue);
      });
  }
  data["score_data"] = {
    total_score: scoreMap ? scoreMap["total_score"] : "0",
    scores: scoresValueArray,
  };

  // 大項目(共通大項目が10を超えるとソートが崩れるので注意!)
  Object.keys(baseItemValues.attribution)
    .sort()
    .forEach(function (key) {
      const bigValue: ONEVISIONBIGRECORD = {
        big_title: baseItemValues.attribution[key].big_item_name,
        big_item_id: baseItemValues.attribution[key].big_item_id,
        big_item_score_no: baseItemValues.attribution[key].big_item_score_no,
        big_item_score_name:
          baseItemValues.attribution[key].big_item_score_name,
        score_pattern: baseItemValues.attribution[key].score_pattern,
        // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
        rows: makeBigRecordArray(
          baseItemValues.attribution[key].big_item_id,
          baseItemValues.attribution[key].small_items,
          smallValueArray,
          baseItemValues.attribution[key].score_pattern
        ),
      };
      bigValueArray.push(bigValue);
    });
  data["rows"] = bigValueArray;
  return data;
};

const makeBigRecordArray = (
  bigItemId: string,
  smallItems: any,
  smallValueArray: Array<ONEVISIONRECORD>,
  score_pattern: Array<SCOREMAP>
) => {
  const searchRecord = (
    bigItemId: string,
    smallItemId: string,
    smallItemTitle1: string,
    smallItemTitle2: string,
    smallScorePattern: any,
    smallPhotoFilePathGcs: Array<any>
  ) => {
    const key = bigItemId + "-" + smallItemId;
    smallItemTitle2 = smallItemTitle2 || "";
    for (const row of smallValueArray) {
      if (row["key"] === key) {
        row["small_title1"] = smallItemTitle1;
        row["small_title2"] = smallItemTitle2;
        row["score_pattern"] = smallScorePattern || score_pattern;
        row["photo_filepath_gcs"] = smallPhotoFilePathGcs || [];
        return row;
      }
    }
    const tmp: ONEVISIONRECORD = {
      key,
      big_item_id: Number(bigItemId),
      small_title1: smallItemTitle1,
      small_title2: smallItemTitle2,
      small_item_id: Number(smallItemId),
      score: "",
      score_pattern: smallScorePattern || score_pattern,
      last_score: "",
      photo_filepath_gcs: smallPhotoFilePathGcs || [],
    };
    return tmp;
  };
  let array: Array<ONEVISIONRECORD> = [];
  for (const row of smallItems) {
    const val = searchRecord(
      bigItemId,
      row.id,
      row.name,
      row.name2,
      row.score_pattern,
      row.photo_filepath_gcs
    );
    if (val !== undefined) {
      array.push(val);
    }
  }
  return array;
};

async function getJsonlastMonths(last_time_id: string) {
  let retDocItem: any = null;
  let retRecords: any = {};
  let base_month: string = "";
  // 過去1カ月分取得する
  let n: number = 1;
  for (let i: number = 0; i < n; ) {
    try {
      if (last_time_id) {
        retDocItem = await getFirestoreDocData(
          "one_vision_records",
          last_time_id
        );
        if (retDocItem) {
          if (base_month === "") {
            base_month = getFirstDayOfMonth(
              retDocItem.attribution.approve_day,
              0
            );
          }
          let target_month = getFirstDayOfMonth(
            retDocItem.attribution.approve_day,
            0
          );
          let last_month = getFirstDayOfMonth(base_month, -1 * i);
          let complete_date = retDocItem.attribution.complete_date;
          last_time_id = retDocItem.attribution.last_time_id;
          if (complete_date && last_month !== target_month) {
            retRecords = retDocItem.records;
            i++;
          }
        }
      } else {
        break;
      }
    } catch {
      break;
    }
  }
  return retRecords;
}

export function getFirstDayOfMonth(target_date: string, addMonth: number) {
  // 日付取得
  let today = zonedTimeToUtc(target_date, "Asia/Tokyo");
  let this_year = today.getFullYear();
  let this_month = today.getMonth();

  // 年月
  let firstDayOfMonth = new Date(this_year, this_month + addMonth, 1);
  let year = firstDayOfMonth.getFullYear() + "";
  let month = firstDayOfMonth.getMonth() + 1 < 10
        ? "0" + (firstDayOfMonth.getMonth() + 1)
        : firstDayOfMonth.getMonth() + 1 + "";
  //文字列を作成
  return year + month + "01";
}

// 配列の順番を整えるため、ここだけ連想配列キーを「xx-yy(例:02-11)」のような形にする
const sortObject = (objects: any) => {
  let newObj: any = {};
  Object.keys(objects).forEach(function (key) {
    const tempKey =
      ("00" + objects[key]["big_item_id"]).slice(-2) +
      ("00" + objects[key]["small_item_id"]).slice(-2);
    newObj[tempKey] = objects[key];
  });
  return newObj;
};

/**
 * OneVisionの変更項目を差分更新し、最終的にReactHooksに保存する
 * @param oneVisionData OneVisionのオブジェクト
 * @returns
 */
export const updateOneVision = (
  oneVisionData: any,
  newData: ONEVISIONRECORD
) => {
  const objects: any = {};
  const array: any = [];
  for (const [key, obj] of Object.entries(oneVisionData)) {
    if (key === newData.key) {
      objects[key] = newData;
    } else {
      objects[key] = obj;
    }
    array.push(objects[key]);
  }
  return array;
};

/**
 * OneVisionの値を受け取り、Firestoreの更新を行う
 */
export const updateOneVisionFirestore = (
  oneVisionData: Array<ONEVISIONBIGRECORD>,
  oneVisionId: string,
  oneVisionLastTimeId: string,
  selectedTenpoCode: string,
  oneVisionOtherData: ONEVISIONOTHERDATA,
  approveDay: string,
  lastApproveDay: string,
  userPrivilege: string,
  agreementDay: string,
  regulationDay: string,
  lastAgreementDate: string,
  lastRegulationDate: string
) => {
  if (
    (Array.isArray(oneVisionData) && oneVisionData.length === 0) ||
    oneVisionId === ""
  ) {
    // 初回読み込みで呼ばれてしまうので空であれば終了する
    return;
  }
  const objects: any = {};
  objects["attribution"] = {
    conditions: ["ALL"],
    last_time_id: oneVisionLastTimeId,
    tenpo_code: selectedTenpoCode,
    approve_day: approveDay,
    last_approve_day: lastApproveDay,
    user_privilege: userPrivilege,
    agreement_day: agreementDay,
    regulation_day: regulationDay,
    last_agreement_day: lastAgreementDate,
    last_regulation_day: lastRegulationDate,
  };
  objects["id"] = oneVisionId;
  objects["docId"] = oneVisionId;
  objects["update_date"] = {
    update_date: firestore.FieldValue.serverTimestamp(),
    update_user: getFirebaseUserInfo().uid,
  };
  objects["other_data"] = oneVisionOtherData;

  let records: any = {};
  let scores: Array<ONEVISIONTOTALSCORE> = [];
  let allTotalScore: number = 0;
  for (const bigRecord of oneVisionData) {
    let totalScore: number = 0;
    for (const smallRecord of bigRecord.rows) {
      const keyValue: string = "records-" + smallRecord.key;
      records[keyValue] = smallRecord;
      if (smallRecord["score"]) {
        totalScore += Number(smallRecord["score"]);
      }
    }
    if (Number(bigRecord.big_item_score_no)) {
      let rate = bigRecord.rows.length / 10;
      totalScore = totalScore / rate;
    }
    allTotalScore += totalScore;
    scores.push({
      no: bigRecord.big_item_score_no,
      name: bigRecord.big_item_score_name,
      score: String(totalScore),
    });
  }
  objects["records"] = records;

  objects["score_data"] = {
    scores: scores,
    total_score: String(allTotalScore),
  };
  userLogging(
    "OneVision",
    "新規作成#one_vision_recordsテーブル更新",
    JSON.stringify(objects)
  );

  writeFirestoreDoc("one_vision_records", oneVisionId, objects);
};
export const getTenpoFirestoreData = async (tenpoCode: string) => {
  const data = await getFirestoreDocData("existing_sej_stores", tenpoCode).then(
    (response: any) => {
      return response;
    }
  );
  return data;
};
