import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Route, BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Auth from "./components/Auth/Auth";
import Home from "./components/Home/Home";
import Maps from "./components/Maps/Maps";
import TenpoCheck from "./components/TenpoCheck/Register/TenpoCheck";
import TenpoCheckSearch from "./components/TenpoCheck/Search/TenpoCheckSearch";
import TenpoCheckFilingStatusConfirm from "./components/TenpoCheck/FilingStatusConfirm/TenpoCheckFilingStatusConfirm";
import TenpoCheckManagerAnalysis from "./components/TenpoCheck/ManagerAnalysis/TenpoCheckManagerAnalysis";
import OneVision from "./components/OneVision/Register/OneVision";
import OneVisionManagerAnalysis from "./components/OneVision/ManagerAnalysis/OneVisionManagerAnalysis";
import TSCheck from "./components/TSCheck/Register/TSCheck";
import TSCheckSearch from "./components/TSCheck/Search/TSCheckSearch";
import TSCheckFilingStatusConfirm from "./components/TSCheck/FilingStatusConfirm/TSCheckFilingStatusConfirm";
import { tokenLogin } from "./components/Auth/Login";
import OneVisionSearch from "./components/OneVision/Search/OneVisionSearch";
import OneVisionFilingStatusConfirm from "./components/OneVision/FilingStatusConfirm/OneVisionFilingStatusConfirm";
import TSCheckManagerAnalysis from "./components/TSCheck/ManagerAnalysis/TSCheckManagerAnalysis";

tokenLogin(window.location.href);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <>
          <Route exact path="/login" component={Auth} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/maps" component={Maps} />
          <Route exact path="/tenpo-check" component={TenpoCheck} />
          <Route
            exact
            path="/tenpo-check-search"
            component={TenpoCheckSearch}
          />
          <Route
            exact
            path="/tenpo-check-filing-status-confirm"
            component={TenpoCheckFilingStatusConfirm}
          />
          <Route
            exact
            path="/tenpo-check-manager-analysis"
            component={TenpoCheckManagerAnalysis}
          />
          <Route exact path="/one-vision" component={OneVision} />
          <Route exact path="/one-vision-search" component={OneVisionSearch} />
          <Route
            exact
            path="/one-vision-filing-status-confirm"
            component={OneVisionFilingStatusConfirm}
          />
          <Route
            exact
            path="/one-vision-manager-analysis"
            component={OneVisionManagerAnalysis}
          />
          <Route exact path="/ts-check" component={TSCheck} />
          <Route exact path="/ts-check-search" component={TSCheckSearch} />
          <Route
            exact
            path="/ts-check-filing-status-confirm"
            component={TSCheckFilingStatusConfirm}
          />
          <Route
            exact
            path="/ts-check-manager-analysis"
            component={TSCheckManagerAnalysis}
          />
          <Route path="/" component={App} />
        </>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
