import { innerDataExistingSejStoreLayer } from "../../Maps/layers/existingSejStoreLayer";
import { displayPolygons, updateDsiplayFlgMarketArea } from "./PolygonDrawing";
import { getGoogleMapsObject } from "../../Maps/GoogleMaps";
import {
  getFirestoreAllData,
  getFirestoreDataWhereArray,
} from "../../../apis/firestoreAction";
import { getGMapsCenterPoint, getGoogleMapsZoom } from "../../Maps/GoogleMaps";
import { displayMarketAreaFlag } from "../../OpView/MarketArea/ShowMarketAreaButton";
import {
  isTrainingUser,
  isTsUser,
  isFTraineeUser,
} from "../../../apis/privilege";

let user: any;
export function setUserInfo(userInfo: any) {
  user = userInfo;
}
export async function distributionDisplayMarketArea(isForce: boolean) {
  if (displayMarketAreaFlag === false) return;
  //  マップの中心座標
  let latLng = getGMapsCenterPoint();
  //  マップのズームレベル
  let zoomLevel = getGoogleMapsZoom();
  let searchLength: number = 3;
  const largeAreaZoomLevel = 15;
  if (largeAreaZoomLevel > zoomLevel) {
    searchLength = 4.5;
  }
  // 表示範囲内の店舗情報を取得
  const features = innerDataExistingSejStoreLayer(
    latLng,
    searchLength
  ).features;
  let tenpoCodeList: string[] = [];
  // 表示範囲内の店舗コードリストを作成
  for (let index in features) {
    tenpoCodeList.push(
      getMarketAreaDocId(features[index].properties["tenpo_code"])
    );
  }
  let key = "";
  let items = "attribution.conditions";
  // zm用検索キー作成
  if (user.jobCategory === "zm") {
    key = "ZO_" + user.zoCode;
  }
  // dm用検索キー作成
  else if (user.jobCategory === "dm") {
    key = "DO_" + user.doCode;
  }
  // ofc用検索キー作成
  else if (user.jobCategory === "ofc") {
    key = "OFCID_" + user.employeeNo;
  }
  // admin-group用検索キー作成
  else if (user.jobCategory === "admin-group") {
    key = user.employeeNo;
  }
  // トレーナー用検索キー作成
  else if (isTrainingUser(user)) {
    key = "TRAINER_ID_" + user.employeeNo;
  }
  // Fトレ生用検索キー作成
  else if (isFTraineeUser(user)) {
    key = "FC_TRAINEE_ID_" + user.employeeNo;
  }
  // 直営店社員用検索キー作成
  else if (isTsUser(user)) {
    key = "TS_TRAINEE_ID_" + user.employeeNo;
  } else {
    key = "";
  }
  // firestoreから対象の店舗の任意商圏の情報を取得する
  let marketAreaValues: any;
  await getFirestoreDataWhereArray("market_area", items, key).then(
    (response: any) => {
      marketAreaValues = response;
    }
  );
  // 検索結果有無
  if (marketAreaValues) {
    marketAreaValues.forEach((mapValue: any) => {
      // 検索結果有無
      if (mapValue) {
        let marketAreaValue = mapValue.data();
        // 検索結果を表示範囲の店舗コードでフィルタリングして商圏を表示する
        if (tenpoCodeList.includes(marketAreaValue.doc_id)) {
          if (isForce) {
            updateDsiplayFlgMarketArea(marketAreaValue.doc_id, "1");
            marketAreaValue.isDisplay = "1";
          }
          const polygon = JSON.parse(marketAreaValue.feature);
          if (marketAreaValue.isDisplay !== "0") {
            displayPolygons(
              polygon,
              getGoogleMapsObject(),
              marketAreaValue.doc_id,
              false
            );
          }
        }
      }
    });
  }
}
export const getMarketAreaDocId = (tenpoCodeParam: string) => {
  if (tenpoCodeParam.length !== 6) {
    // 店舗コード以外はそのまま返却
    return tenpoCodeParam;
  }
  // admin
  if (user.jobCategory === "admin-group") {
    return tenpoCodeParam + "_ADMIN_" + user.employeeNo;
  }
  // トレーナー
  else if (isTrainingUser(user)) {
    return tenpoCodeParam + "_TRAINER_" + user.employeeNo;
  }
  // Fトレ生
  else if (isFTraineeUser(user)) {
    return tenpoCodeParam + "_FC_TRAINEE_" + user.employeeNo;
  }
  // 直営店社員
  else if (isTsUser(user)) {
    return tenpoCodeParam + "_TS_TRAINEE_" + user.employeeNo;
  } else {
    return tenpoCodeParam;
  }
};
