/**
 * GeoFireXを経由したFirestoreの処理です
 * Firebase認証は別箇所でログイン済であることを前提とします
 * @class FluxActionGeoFireX.js
 * @constructor
 */
import firebase from "firebase/app";
import * as geofirex from "geofirex";
import { toGeoJSON } from "geofirex";
import { db } from "../firebase";
import { convertGeoJsonFeaturesFromGeoFireJson } from "./turfAction";

/**
 * Firestoreよりドキュメントのデータを取得し、
 * geofirex内でGeoJSONに変換して値をstoreに設定する
 * @export
 * @param {*} documentName Firestoreのドキュメント名
 * @param {*} centerLat 緯度
 * @param {*} centerLon 経度
 * @param {*} searchLength 検索範囲の半径(km)
 * @param {*} callbackFunc コールバック関数 セッタで値をstoreに保持し、更新を行う
 * @param {*} isPointOnly (デフォルト引数)ポイント以外を含む場合は自動GeoJSON変換を行わずにFirestoreのfeatureよりGeoJSONを作成する
 * @param {*} conditions (デフォルト引数)検索条件
 */
export function getGeoFireStore(
  documentName: string,
  centerLat: number,
  centerLon: number,
  searchLength: number,
  updateLayer: google.maps.Data,
  subscription: any,
  callbackFunc: any,
  isPointOnly: boolean = true,
  conditions: any = null
) {
  // @ts-ignore
  const geo = geofirex.init(firebase);
  let m,
    center = geo.point(centerLat, centerLon);
  // クエリが変わるので以前のFirestore検索のサブスクライブを解除する
  if (subscription !== null) {
    // Todo 暫定 コメントアウト
    // subscription.unsubscribe();
  }
  // Todo ALLは暫定
  if (conditions === null || conditions.length === 0 || conditions === "ALL") {
    m = db.collection(documentName);
  }
  //検索条件設定
  else {
    m = db
      .collection(documentName)
      .where("attribution.conditions", "array-contains-any", conditions);
  }
  // 設定された中心地点から指定距離(km)の座標を検索する
  let query = geo.query(m).within(center, searchLength, "geography");
  if (isPointOnly) {
    // Firestoreデータ自動更新(ポイントのみ)
    subscription = query
      .pipe(toGeoJSON("geography", true))
      .subscribe((geoj) => {
        callbackFunc(geoj, updateLayer, conditions);
      });
  } else {
    // Firestoreデータ自動更新(ライン・ポリゴンを含むのでGeoJSON変換を行う)
    subscription = query.pipe().subscribe((geoj) => {
      callbackFunc(
        convertGeoJsonFeaturesFromGeoFireJson(geoj),
        updateLayer,
        conditions
      );
    });
  }
  return subscription;
}

export function addGeoFireStore() {
  const documentName = "test2",
    lat = 35.1,
    lon = 135.1;
  // @ts-ignore
  const geo = geofirex.init(firebase);
  let center = geo.point(lat, lon);
  let m = db.collection(documentName);
  const map = {
    geography: {
      geohash: center.geohash,
      geopoint: center.geopoint,
    },
    //   feature: f,
  };
  // firestoreに登録
  m.add(map);
}
