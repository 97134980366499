import React, { forwardRef, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  clickNewTenpo,
  clickNewOneVision,
  clickNewTs,
  URL_ENQUIRY,
  URL_Q_A,
} from "../Home/Home";
import {
  isOpenRegistPage,
  isOpenStatusConfirmPage,
  isOpenManagerAnalysisPage,
  isOpenTSCheck,
  isLocal,
  isDownloadPointData,
} from "../../apis/privilege";
import { selectUser } from "../../../src/features/userSlice";
import { auth } from "../../firebase";
import MenuIcon from "@material-ui/icons/Menu";
import { Grid, Typography } from "@material-ui/core";
import { userLogging } from "../../apis/userLog";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

type Anchor = "left";
const imgDir = `${window.location.origin}/image/icons/`;

const tenpoNewItem = {
  title: "新規作成",
  id: "tenpo-check",
  icon: "new_icon.png",
};
const tenpoSearchItem = {
  title: "データ検索",
  id: "tenpo-check-search",
  icon: "search_icon.png",
};
const tenpoStatusConfirmItem = {
  title: "提出状況確認",
  id: "tenpo-check-filing-status-confirm",
  icon: "confirm_icon.png",
};
const tenpoManagerAnalysisItem = {
  title: "管理者分析",
  id: "tenpo-check-manager-analysis",
  icon: "analysis_icon.png",
};
const onevisionNewItem = {
  title: "新規作成",
  id: "one-vision",
  icon: "new_icon.png",
};
const onevisionSearchItem = {
  title: "データ検索",
  id: "one-vision-search",
  icon: "search_icon.png",
};
const onevisionStatusConfirmItem = {
  title: "提出状況確認",
  id: "one-vision-filing-status-confirm",
  icon: "confirm_icon.png",
};
const onevisionManagerAnalysisItem = {
  title: "管理者分析",
  id: "one-vision-manager-analysis",
  icon: "analysis_icon.png",
};
const tsNewItem = {
  title: "新規作成",
  id: "ts-check",
  icon: "new_icon.png",
};
const tsSearchItem = {
  title: "データ検索",
  id: "ts-check-search",
  icon: "search_icon.png",
};
const tsStatusConfirmItem = {
  title: "提出状況確認",
  id: "ts-check-filing-status-confirm",
  icon: "confirm_icon.png",
};
const tsManagerAnalysisItem = {
  title: "管理者分析",
  id: "ts-check-manager-analysis",
  icon: "analysis_icon.png",
};

let tenpoCheckItems = [
  tenpoNewItem,
  tenpoSearchItem,
  tenpoStatusConfirmItem,
  // tenpoManagerAnalysisItem,
];

let oneVisionItems = [
  onevisionNewItem,
  onevisionSearchItem,
  onevisionStatusConfirmItem,
  // onevisionManagerAnalysisItem,
];

let tsCheckItems = [
  tsNewItem,
  tsSearchItem,
  tsStatusConfirmItem,
  tsManagerAnalysisItem,
];

export interface SwipTempDrawerHandles {
  openDrawer(): void;
}

export const SwipeableTemporaryDrawer = forwardRef<SwipTempDrawerHandles>(
  (props, ref) => {
    const classes = useStyles();
    const history = useHistory();
    const [state, setState] = React.useState({
      left: false,
    });
    const user: any = useSelector(selectUser);
    tenpoCheckItems = [];
    oneVisionItems = [];
    tsCheckItems = [];
    // 新規登録
    if (isOpenRegistPage(user)) {
      tenpoCheckItems.push(tenpoNewItem);
      oneVisionItems.push(onevisionNewItem);
      if (process.env.REACT_APP_MODE === "develop" || isOpenTSCheck(user)) {
        tsCheckItems.push(tsNewItem);
      }
    }
    // 検索
    tenpoCheckItems.push(tenpoSearchItem);
    oneVisionItems.push(onevisionSearchItem);
    if (process.env.REACT_APP_MODE === "develop" || isOpenTSCheck(user)) {
      tsCheckItems.push(tsSearchItem);
    }
    // 提出状況確認
    if (isOpenStatusConfirmPage(user)) {
      tenpoCheckItems.push(tenpoStatusConfirmItem);
      oneVisionItems.push(onevisionStatusConfirmItem);
      if (process.env.REACT_APP_MODE === "develop" || isOpenTSCheck(user)) {
        tsCheckItems.push(tsStatusConfirmItem);
      }
    }
    // 管理者分析
    if (isOpenManagerAnalysisPage(user)) {
      tenpoCheckItems.push(tenpoManagerAnalysisItem);
      if (process.env.REACT_APP_MODE === "develop" || 
        isDownloadPointData(user)) {
        oneVisionItems.push(onevisionManagerAnalysisItem);
      }
      tsCheckItems.push(tsManagerAnalysisItem);
    }

    useImperativeHandle(ref, () => ({
      openDrawer() {
        setState({ ...state, left: true });
      },
    }));
    const signOut = () => {
      auth
        .signOut()
        .then(() => {})
        .catch((error) => {
          console.log(`logout error (${error})`);
        });
    };

    const toggleDrawer =
      (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };
    const list = (anchor: Anchor) => (
      <div
        className={clsx(classes.list)}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          <ListItem>
            <ListItemIcon>
              <img
                src={imgDir + "tenpo_check_icon.png"}
                alt=""
                style={{
                  backgroundColor: "black",
                  height: "20px",
                  marginRight: "10px",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={"店舗確認表"}
              primaryTypographyProps={{
                variant: "inherit",
              }}
            />
          </ListItem>
          {tenpoCheckItems.map((item) => (
            <ListItem
              button
              key={item.id}
              onClick={() => {
                if (item.id === "tenpo-check") {
                  clickNewTenpo();
                }
                history.push(item.id);
                userLogging("店舗確認表", "サイドメニュー", item.title);
              }}
            >
              <ListItemIcon>
                <img
                  src={imgDir + item.icon}
                  alt=""
                  style={{ height: "20px", paddingRight: "10px" }}
                />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>
              <img
                src={imgDir + "one_vision_icon.png"}
                alt=""
                style={{
                  backgroundColor: "black",
                  height: "20px",
                  marginRight: "10px",
                }}
              />
            </ListItemIcon>
            <ListItemText primary={"ONEVISION"} />
          </ListItem>
          {oneVisionItems.map((item) => (
            <ListItem
              button
              key={item.id}
              onClick={() => {
                if (item.id === "one-vision") {
                  clickNewOneVision();
                }
                history.push(item.id);
                userLogging("OneVision", "サイドメニュー", item.title);
              }}
            >
              <ListItemIcon>
                <img
                  src={imgDir + item.icon}
                  alt=""
                  style={{ height: "20px", paddingRight: "10px" }}
                />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}

          <Divider />
          {process.env.REACT_APP_MODE === "develop" || isOpenTSCheck(user) ? (
            //TODO 北海道ZO及びadmin-groupのみ開放
            <>
              <ListItem>
                <ListItemIcon>
                  <img
                    src={imgDir + "ts_check_icon.png"}
                    alt=""
                    style={{
                      backgroundColor: "black",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"店舗衛生点検表"}
                  primaryTypographyProps={{
                    variant: "inherit",
                  }}
                />
              </ListItem>
              {tsCheckItems.map((item) => (
                <ListItem
                  button
                  key={item.id}
                  onClick={() => {
                    if (item.id === "ts-check") {
                      clickNewTs();
                    }
                    history.push(item.id);
                    userLogging("店舗衛生点検表", "サイドメニュー", item.title);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={imgDir + item.icon}
                      alt=""
                      style={{ height: "20px", paddingRight: "10px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              ))}
              <Divider />
            </>
          ) : (
            ""
          )}

          <ListItem
            button
            key="manual"
            onClick={() => {
              window.open(URL_Q_A, "_blank");
              userLogging("その他", "サイドメニュー", "Q＆A");
            }}
          >
            <ListItemIcon>
              <img
                // src={imgDir + "logout_icon.png"}
                alt=""
                style={{ height: "20px", paddingRight: "10px" }}
              />
            </ListItemIcon>
            <ListItemText primary={"Q＆A"} />
          </ListItem>
          <ListItem
            button
            key="manual"
            onClick={() => {
              window.open(URL_ENQUIRY, "_blank");
              userLogging("その他", "サイドメニュー", "マニュアル・問い合わせ");
            }}
          >
            <ListItemIcon>
              <img
                // src={imgDir + "logout_icon.png"}
                alt=""
                style={{ height: "20px", paddingRight: "10px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontSize: "13px" }}>
                  マニュアル・問い合わせ
                </Typography>
              }
            />
          </ListItem>

          <Divider />
          {process.env.REACT_APP_MODE === "develop" ? (
            <>
            <ListItem
              button
              key="logout"
              onClick={() => {
                signOut();
                history.push("login");
                userLogging("共通", "サイドメニュー", "ログアウト");
              }}
            >
              <ListItemIcon>
                <img
                  src={imgDir + "logout_icon.png"}
                  alt=""
                  style={{ height: "20px", paddingRight: "10px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"ログアウト"} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={user.mailAddress}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={user.uid}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={user.jobCategory}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={JSON.stringify(user.zoCode)}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={JSON.stringify(user.doCode)}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
          </ListItem>
          </>
        ) : (
            ""
          )}
        </List>
      </div>
    );

    return (
      <React.Fragment>
        <Grid style={{ display: "flex" }}>
          <Button onClick={toggleDrawer("left", true)}>
            <MenuIcon></MenuIcon>
          </Button>
          <img
            src={`${window.location.origin}/image/sejlogo.png`}
            alt="SEJアイコン"
            style={{ height: "7vh" }}
          />
        </Grid>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
      </React.Fragment>
    );
  }
);
