import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import TenpoCheckResultGraphs from "./TenpoCheckResultGraphs";
import { selectImageToken, selectUser } from "../../../features/userSlice";
import { openWebSite } from "../../../apis/util";
import { postRequest } from "../../../apis/axiosAction";
import {
  getTenpoCheckData,
  TENPO_CHECK_DATA,
  TENPO_CHECK_BIG_RECORD,
  TENPO_CHECK_ADD_BIG_RECORD,
  TENPO_CHECK_RECORD,
} from "./TenpoCheckTableDataOperation";
import { DateTime } from "luxon";
import {
  getFixedFlg,
  setFixedFlg,
  setInitTenpoCode,
} from "../../Header/Header";
import { stylesTenpoCheckTable } from "./StylesTenpoCheckRegister";
import { setLastTenpoCheckId, setTenpoCheckId } from "../Register/TenpoCheck";
import { userLogging } from "../../../apis/userLog";
import TenpoCheckDialog from "../TenpoCheckDialog";

let pdfFlg = false;
export { pdfFlg };
let updateFlg = 0;

const buttonNamePrint = "印刷（PDFダウンロード）";
const buttonNameExcute = "登録（PDF作成）";
const OTHER_VALUE: string = "99";

export function commentReason(row: TENPO_CHECK_RECORD) {
  let comment = "";
  const addComment = (
    row: TENPO_CHECK_RECORD,
    target: string,
    comment: string,
    targetFlg: boolean
  ) => {
    let workComment = "";
    if (row.reason_target_list && target && targetFlg) {
      for (let i = 0; i < row.reason_target_list.length; i++) {
        if (target === row.reason_target_list[i].value) {
          workComment += row.reason_target_list[i].name + " ";
        }
      }
    }
    if (OTHER_VALUE === target) {
      workComment += "その他 ";
    }
    if (
      row.reason_comment_map_list &&
      row.reason_comment_map_list.length > 0 &&
      comment
    ) {
      for (let i = 0; i < row.reason_comment_map_list.length; i++) {
        if (target === row.reason_comment_map_list[i].value) {
          let commentList = row.reason_comment_map_list[i].comment_list;
          for (let j = 0; j < commentList.length; j++) {
            if (comment === commentList[j].value) {
              workComment += commentList[j].name + " ";
            }
          }
        }
      }
    } else if (row.reason_comment_list && comment) {
      for (let i = 0; i < row.reason_comment_list.length; i++) {
        if (comment === row.reason_comment_list[i].value) {
          workComment += row.reason_comment_list[i].name + " ";
        }
      }
    }
    return workComment;
  };

  comment += addComment(
    row,
    row.user_radio_target,
    row.user_radio_comment,
    true
  );
  if (row.user_ng_list) {
    let targetNo = "";
    for (let i = 0; i < row.user_ng_list.length; i++) {
      let targetFlg = false;
      if (row.user_ng_list[i].user_radio_target !== targetNo) {
        targetNo = row.user_ng_list[i].user_radio_target;
        targetFlg = true;
      }
      comment += addComment(
        row,
        row.user_ng_list[i].user_radio_target,
        row.user_ng_list[i].user_radio_comment,
        targetFlg
      );
    }
  }

  return comment;
}

// 天候リスト
const weatherList = [
  { name: "晴れ", code: "1" },
  { name: "曇り", code: "2" },
  { name: "雨", code: "3" },
  { name: "雪", code: "4" },
];

// 曜日の取得
const getDayOfWeekText = (datetime: DateTime | null) => {
  if (!datetime) {
    return;
  }
  let weekDayArray: string[] = [];
  weekDayArray = ["月", "火", "水", "木", "金", "土", "日"];
  const dayOfWeek = datetime.weekday;
  return weekDayArray[dayOfWeek - 1];
};

const TenpoCheckResultPrint: React.FC<{
  tenpoCode: string;
  tenpoCheckId: string;
  lastTenpoCheckId: string;
  setUpdateFlg: any;
}> = ({ tenpoCode, tenpoCheckId, lastTenpoCheckId, setUpdateFlg }) => {
  const user = useSelector(selectUser);
  const [rowItemList, setRowItemList] = React.useState<
    TENPO_CHECK_BIG_RECORD[]
  >([]);
  const [addRowItemList, setAddRowItemList] = React.useState<
    TENPO_CHECK_ADD_BIG_RECORD[]
  >([]);
  const classes = stylesTenpoCheckTable();
  const [approvalDate, setApprovalDate] = React.useState<DateTime | null>(
    DateTime.local()
  );
  const [convertedApprovalDate, setConvertedApprovalDate] = React.useState<
    string | null
  >(null);
  const [newPdfURL, setNewPdfURL] = React.useState("");
  const [pdfNewButtonName, setPdfNewButtonName] =
    React.useState(buttonNameExcute);
  const [click, setClick] = React.useState(false);
  const [creatWait, setCreatWait] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [alertFlg, setAlertFlg] = React.useState(false);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [dialogNum, setDialogNum] = React.useState(0);
  const [approveWeatherName, setApproveWeatherName] = React.useState("");
  const [approveHour, setApproveHour] = React.useState("");

  // ダイアログ表示
  const dialogCancel = () => {
    if (dialogNum === 2) {
      setPdfNewButtonName(buttonNameExcute);
      setCreatWait(false);
      handleClick();
      setLoading(false);
    }
    setDialogFlg(false);
  };
  const dialogOK = () => {
    if (!alertFlg) {
      if (dialogNum === 1) {
        setPdfNewButtonName("PDF作成中…");
        setCreatWait(true);
        handlePdfPrint();
      } else if (dialogNum === 2) {
        pdfPrintUpdate();
      }
    }
    setDialogFlg(false);
  };

  const handleClick = () => {
    setClick((prev) => !click);
  };

  function checkInputTenpoCheck() {
    let result = true;
    for (let i = 0; i < rowItemList.length; i++) {
      for (let j = 0; j < rowItemList[i].rows.length; j++) {
        if (
          !rowItemList[i].rows[j].button_value ||
          rowItemList[i].rows[j].button_value === "0"
        ) {
          result = false;
        }
      }
    }
    for (let i = 0; i < addRowItemList.length; i++) {
      for (let j = 0; j < addRowItemList[i].rows.length; j++) {
        if (
          !addRowItemList[i].rows[j].button_value ||
          addRowItemList[i].rows[j].button_value === "0"
        ) {
          result = false;
        }
      }
    }
    return result;
  }

  const history = useHistory();
  function clickReference(
    tenpoCode: string,
    tenpoCheckId: string,
    lastTenpoCheckId: string
  ) {
    setFixedFlg(true);
    setLastTenpoCheckId(lastTenpoCheckId);
    setTenpoCheckId(tenpoCheckId);
    setInitTenpoCode(tenpoCode);
    // 画面遷移
    history.push("tenpo-check");
  }

  /**
   * PDFボタン押下時のイベント
   * @param type:number 1:新方式 2:旧方式
   */
  function clickPdfPrint(type: number) {
    if (creatWait) {
      return;
    }
    if (type === 1 && newPdfURL) {
      openWebSite(pdfURL(true));
      return;
    }
    if (!checkInputTenpoCheck()) {
      setAlertFlg(true);
      setDialogMessage([
        "未入力項目があります。",
        "入力内容を確認してください",
      ]);
      setDialogFlg(true);
      return;
    }
    if (!convertedApprovalDate) {
      setAlertFlg(true);
      setDialogMessage(["作成日を入力してください"]); // 旧承認日
      setDialogFlg(true);
      return;
    }
    if (!approveHour) {
      setAlertFlg(true);
      setDialogMessage(["作成時間を入力してください"]);
      setDialogFlg(true);
      return;
    }

    if (getFixedFlg()) {
      // setPdfOldButtonName("PDF作成中…");
      setPdfNewButtonName("PDF作成中…");
      setCreatWait(true);
      handlePdfPrint();
    } else {
      setAlertFlg(false);
      setDialogNum(1);
      setDialogMessage([
        "作成日 " +
          (approvalDate ? approvalDate.toFormat("yyyy年MM月dd日") : "") +
          " でPDF作成します。",
        "PDFの出力後に編集は行えません。",
        "よろしいですか？",
      ]);
      setDialogFlg(true);
      return;
    }
  }

  // 入力値比較
  const checkLatestRecord = (base: any[], check: any[]) => {
    for (let i = 0; i < base.length; i++) {
      if (base[i].rows.length !== check[i].rows.length) {
        return false;
      }
      for (let j = 0; j < base[i].rows.length; j++) {
        let baseRow = base[i].rows[j];
        let checkRow = check[i].rows[j];
        if (
          baseRow.big_item_id !== checkRow.big_item_id ||
          baseRow.button_value !== checkRow.button_value ||
          baseRow.score !== checkRow.score ||
          baseRow.user_input_comment !== checkRow.user_input_comment ||
          baseRow.user_radio_comment !== checkRow.user_radio_comment ||
          baseRow.user_radio_target !== checkRow.user_radio_target ||
          baseRow.small_item_id !== checkRow.small_item_id ||
          baseRow.small_title !== checkRow.small_title
        ) {
          return false;
        }
        for (let k = 0; k < baseRow.photo_filepath_gcs.length; k++) {
          if (
            baseRow.photo_filepath_gcs[k] !== checkRow.photo_filepath_gcs[k]
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const handlePdfPrint = async () => {
    setLoading(true);

    await getTenpoCheckData(
      tenpoCode,
      tenpoCheckId,
      lastTenpoCheckId,
      getFixedFlg(),
      user.jobCategory
    ).then((res: TENPO_CHECK_DATA) => {
      let ckRowItemList: TENPO_CHECK_BIG_RECORD[] = res.base_value;
      let ckAddRowItemList: TENPO_CHECK_ADD_BIG_RECORD[] = res.add_value;

      // 画面の最新状態との比較
      if (
        convertedApprovalDate !== res.approve_day ||
        !checkLatestRecord(rowItemList, ckRowItemList) ||
        !checkLatestRecord(addRowItemList, ckAddRowItemList)
      ) {
        // setPdfOldButtonName("PDF作成(旧書式)");
        setPdfNewButtonName(buttonNameExcute);
        setCreatWait(false);
        handleClick();
        setLoading(false);
        setAlertFlg(true);
        setDialogNum(3);
        setDialogMessage([
          "既に登録されています。",
          "新たにデータ登録する場合は左上のメニューから「新規作成」を、",
          "作成されたデータを見る場合は左上のメニューからデータ検索してください。",
        ]);
        setDialogFlg(true);
        return;
      } else {
        // 次回の店舗確認表のデータを作成
        const apiParams = {
          id: tenpoCheckId,
          approve_day: convertedApprovalDate,
          newFlag: true,
        };
        const url = process.env.REACT_APP_GAE_API_URL + "tenpocheck/data";
        setCreatWait(true);
        postRequest(url, apiParams)
          .then(async (res) => {
            // 同一店舗同一週有りの場合、上書きの確認メッセージ表示
            if (res.data.dataList[0] === "SameStoreSameWeek") {
              setLoading(false);
              setCreatWait(false);
              setAlertFlg(false);
              setDialogNum(2);
              setDialogMessage(["上書き保存されますがよろしいですか？"]);
              setDialogFlg(true);
              return;
            }
            // 完了済みのレコードの場合
            else if (res.data.dataList[0] === "AlreadyCompleted") {
              setPdfNewButtonName(buttonNameExcute);
              setCreatWait(false);
              handleClick();
              setLoading(false);
              setAlertFlg(true);
              setDialogNum(3);
              setDialogMessage([
                "既に登録されています。",
                "新たにデータ登録する場合は左上のメニューから「新規作成」を、",
                "作成されたデータを見る場合は左上のメニューからデータ検索してください。",
              ]);
              setDialogFlg(true);
              return;
            }
            // 新規作成の場合の処理（同一店舗同一週無し）
            // const newObj = JSON.parse(res.data.dataList[0]);
            setNewPdfURL(pdfURL(true));
            setPdfNewButtonName(buttonNamePrint);
            setCreatWait(false);
            handleClick();
            setLoading(false);
            updateFlg++;
            setUpdateFlg(updateFlg);
            clickReference(tenpoCode, tenpoCheckId, lastTenpoCheckId);
          })
          .catch((r) => {
            setCreatWait(false);
            setLoading(false);
            setAlertFlg(true);
            setDialogMessage([String(r)]);
            setDialogFlg(true);
          });
      }
    });
  };

  const pdfPrintUpdate = async () => {
    setLoading(true);
    setCreatWait(true);
    const url = process.env.REACT_APP_GAE_API_URL + "tenpocheck/data";
    const apiParams2 = {
      id: tenpoCheckId,
      approve_day: convertedApprovalDate,
      newFlag: false,
    };
    await postRequest(url, apiParams2).then((res2) => {
      setNewPdfURL(pdfURL(true));
      setPdfNewButtonName(buttonNamePrint);
      setCreatWait(false);
      handleClick();
      setLoading(false);
      updateFlg++;
      setUpdateFlg(updateFlg);
      clickReference(tenpoCode, tenpoCheckId, lastTenpoCheckId);
    });
    // 処理を終了する
    return;
  };

  const imageToken = useSelector(selectImageToken);
  function getImageFile(imagePath: string) {
    if (imagePath === undefined || imagePath === "") {
      return "";
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      imagePath +
      "?access_token=" +
      imageToken
    );
  }

  function pdfURL(isNewFormat: boolean) {
    const pdfDir = process.env.REACT_APP_GCS_DIR_PDF
      ? process.env.REACT_APP_GCS_DIR_PDF + "/"
      : "pdftest/";
    let suffix = "_old.pdf";
    if (isNewFormat) {
      suffix = "_general.pdf";
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      pdfDir +
      tenpoCheckId +
      suffix +
      "?access_token=" +
      imageToken
    );
  }

  useEffect(() => {
    setNewPdfURL("");
    setPdfNewButtonName(buttonNameExcute);
  }, [tenpoCode]);

  useEffect(() => {
    setApprovalDate(null);
    setConvertedApprovalDate("");
    setLoading(true);
    userLogging("店舗確認表", "印刷#初期表示", tenpoCheckId);
    getTenpoCheckData(
      tenpoCode,
      tenpoCheckId,
      lastTenpoCheckId,
      getFixedFlg(),
      user.jobCategory
    ).then((res: TENPO_CHECK_DATA) => {
      setApprovalDate(
        res.approve_day
          ? DateTime.fromFormat(res.approve_day, "yyyyMMdd")
          : null
      );
      for (const weather of weatherList) {
        if (weather.code === res.approve_weather) {
          setApproveWeatherName(weather.name);
          break;
        }
      }
      if (res.approve_hour) {
        setApproveHour(res.approve_hour);
      }
      setConvertedApprovalDate(res.approve_day);
      setRowItemList(res.base_value);
      setAddRowItemList(res.add_value);
      if (getFixedFlg()) {
        setNewPdfURL(pdfURL(true));
        setPdfNewButtonName(buttonNamePrint);
      }
      setLoading(false);
    });
  }, [tenpoCheckId]);

  function getCheckRecord(rowItem: any, index: number) {
    return (
      <>
        {Object.keys(rowItem.rows).length !== 0 ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.header}>
                {rowItem.big_title}
              </Typography>
            </Grid>
            {rowItem.rows.map((row: any, indexRowItem: number) => (
              <>
                <Grid container>
                  <Typography gutterBottom>
                    判定：
                    {row.button_value === "1"
                      ? "◯"
                      : row.button_value === "2"
                      ? "✕"
                      : "--"}
                    ：{row.small_title}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item lg={2} md={4} sm={4}>
                    {(row.photo_filepath_gcs[0] !== undefined &&
                      row.photo_filepath_gcs[0] !== "") ||
                    (row.user_input_comment !== undefined &&
                      row.user_input_comment !== "") ||
                    (commentReason(row) !== undefined &&
                      commentReason(row) !== "") ? (
                      getImageFile(row.photo_filepath_gcs[0]) ? (
                        <img
                          src={getImageFile(row.photo_filepath_gcs[0])}
                          alt={row.small_title}
                          height="200"
                          style={{
                            width: "200px",
                            WebkitAppearance: "none",
                          }}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={10} md={8} sm={8}>
                    {commentReason(row) !== undefined &&
                    commentReason(row) !== "" ? (
                      <Grid item className={classes.commentGrid}>
                        <TextField
                          fullWidth
                          label="指摘ポイント"
                          id="outlined-multiline-static"
                          aria-label="store-input-comment-area"
                          multiline
                          value={commentReason(row)}
                          variant="outlined"
                          inputProps={{
                            readOnly: true,
                          }}
                          className={classes.commentText}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    {row.user_input_comment !== undefined &&
                    row.user_input_comment !== "" ? (
                      <Grid item className={classes.commentGrid}>
                        <TextField
                          fullWidth
                          label="コメント"
                          id="outlined-multiline-static"
                          aria-label="store-input-comment-area"
                          multiline
                          value={row.user_input_comment}
                          variant="outlined"
                          inputProps={{
                            readOnly: true,
                          }}
                          className={classes.commentText}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </>
            ))}
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <>
      <Grid container className={classes.contentFrame}>
        <Grid container justify="center" alignItems="center">
          {loading && <div className={classes.overLayer}></div>}
          {loading && (
            <CircularProgress className={classes.circularProgress} size={120} />
          )}
          <Grid item xs={7}>
            <Grid container justify="flex-end" alignItems="center">
              <Typography variant="h6">作成日</Typography>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container justify="center" alignItems="center">
              {approvalDate ? (
                <Typography variant="h6">
                  {approvalDate.toFormat("yyyy年MM月dd日") +
                    "(" +
                    getDayOfWeekText(approvalDate) +
                    ") " +
                    approveHour +
                    (approveHour ? "時 " : " ") +
                    approveWeatherName}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <TenpoCheckResultGraphs
            tenpoCode={tenpoCode}
            tenpoCheckId={tenpoCheckId}
            lastTenpoCheckId={lastTenpoCheckId}
          />
        </Grid>
        <Grid container justify="center">
          {/* 2.カテゴリ別 確認状況↑ここまで */}
          {/* 項目↓ここから */}
          {rowItemList.map((rowItem, index) => getCheckRecord(rowItem, index))}
          {/* 項目↑ここまで */}
          {/* 追加項目↓ここから */}
          {addRowItemList.map((rowItem, index) =>
            getCheckRecord(rowItem, index)
          )}
          {/* 追加項目↑ここまで */}
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            userLogging("店舗確認表", "印刷#帳票出力", "");
            clickPdfPrint(1);
          }}
        >
          {pdfNewButtonName}
          {newPdfURL ? <DownloadIcon /> : ""}
        </Button>
      </Grid>

      {/* ダイアログ */}
      <TenpoCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogOK}
        doNo={dialogCancel}
        isAlert={alertFlg}
      />
    </>
  );
};

export default TenpoCheckResultPrint;
