import React, { useEffect, FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { DateTime } from "luxon";
import { CircularProgress, Select, MenuItem, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { postRequest } from "../../../apis/axiosAction";
import TenpoCheckDialog from "../TenpoCheckDialog";
import { selectUser } from "../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import TenpoCheckManagerAnalysisTable from "./TenpoCheckManagerAnalysisTable";
import DatePicker from "../../common/DatePicker";
import { zonedTimeToUtc } from "date-fns-tz";
import { userLogging } from "../../../apis/userLog";
import { getFirestoreDocData } from "../../../apis/firestoreAction";
import TenpoCheckStoreScoreDataButton from "./TenpoCheckStoreScoreDataButton";
import { isDownloadPointData } from "../../../apis/privilege";
import { getTemplateData } from "../Register/TenpoCheckTableDataOperation";
import {
  updateTenpoCheckManagerTab,
  updateTenpoCheckManagerMap,
} from "../../organisms/search/TenpoCheckSearchSlice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "70vh",
    width: "90vw",
    margin: "auto",
    // iPad縦
    "@media (min-width: 830px)": {
      height: "65vh",
    },
    // iPad横
    "@media (min-width: 1100px)": {
      height: "70vh",
    },
    // GWPC
    "@media (min-width: 1300px)": {
      height: "70vh",
    },
  },
  graphTable: {
    maxWidth: "80vw",
    backgroundColor: "#ffffff",
    border: "none",
    marginTop: "10px",
    marginBottom: "5px",
  },
  overLayer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    backgroundColor: "#CCC",
    opacity: "0.5",
    zIndex: 100000,
  },
  circularProgress: {
    position: "absolute",
    top: "35%",
    left: "45%",
    opacity: "0.8",
    color: "#ccc",
    zIndex: 99999,
  },
}));

// グラフの色リスト
const fillCodeList = [
  "#8884d8",
  "#82ca9d",
  "skyblue",
  "coral",
  "peru",
  "plum",
  "gold",
  "pink",
  "blue",
  "red",
];
const tableHeaderList2 = [
  "店外",
  "店内・バックルーム・事務所",
  "酒類不当廉売・酒類法定表示物、免許品販売時の年齢確認",
  "設備・什器",
  "フレンドリー確認事項",
  "店舗留置き商品",
  "感染症対策",
  "商品・売場・地区政策等",
];

const initSmallTitle: any = { id: 1, name: "全体" };

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// 実行結果
const AnalysisResult: FC<{
  zoCode: string;
  doCode: string;
  baseData: DateTime | null;
  update: boolean;
  analysis: number;
  initTabValue: number;
  initTableMap: any;
}> = ({
  zoCode,
  doCode,
  baseData,
  update,
  analysis,
  initTabValue,
  initTableMap,
}) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [tabValue, setTabValue] = React.useState(
    initTabValue ? initTabValue : 0
  );
  const dispatch = useDispatch();
  const [indexTabValue, setIndexTabValue] = React.useState(
    initTabValue ? initTabValue : 0
  );

  const [targetData, setTargetData] = React.useState<any[]>();
  const [targetGraphData, setTargetGraphData] = React.useState<any[]>();
  const [targetData0, setTargetData0] = React.useState<any[]>([]);
  const [targetData1, setTargetData1] = React.useState<any[]>([]);
  const [targetData2, setTargetData2] = React.useState<any[]>([]);
  const [targetData3, setTargetData3] = React.useState<any[]>([]);
  const [targetData4, setTargetData4] = React.useState<any[]>([]);
  const [targetData5, setTargetData5] = React.useState<any[]>([]);
  const [targetData6, setTargetData6] = React.useState<any[]>([]);
  const [targetData7, setTargetData7] = React.useState<any[]>([]);
  const [targetData8, setTargetData8] = React.useState<any[]>([]);

  const [targetSmallData, setTargetSmallData] = React.useState<any[]>([]);
  const [targetSmallAverageData, setTargetSmallAverageData] =
    React.useState<any>({});

  const [targetAverageData, setTargetAverageData] = React.useState<any>({});
  const [targetAverageData0, setTargetAverageData0] = React.useState<any>({});
  const [targetAverageData1, setTargetAverageData1] = React.useState<any>({});
  const [targetAverageData2, setTargetAverageData2] = React.useState<any>({});
  const [targetAverageData3, setTargetAverageData3] = React.useState<any>({});
  const [targetAverageData4, setTargetAverageData4] = React.useState<any>({});
  const [targetAverageData5, setTargetAverageData5] = React.useState<any>({});
  const [targetAverageData6, setTargetAverageData6] = React.useState<any>({});
  const [targetAverageData7, setTargetAverageData7] = React.useState<any>({});
  const [targetAverageData8, setTargetAverageData8] = React.useState<any>({});

  const [graphUserList, setGraphUserList] = React.useState<any[]>([]);

  const [workZoCode, setWorkZoCode] = React.useState(zoCode);
  const [workDoCode, setWorkDoCode] = React.useState(doCode);
  const [ofcId, setOfcId] = React.useState("");
  const [tenpoCode, setTenpoCode] = React.useState("");
  const [executeFlg, setExecuteFlg] = React.useState("");
  const [initFlg, setInigFlg] = React.useState(true);
  const [updateFlg, setUpdateFlg] = React.useState(true);
  const [workBaseData, setWorkBaseData] = React.useState(baseData);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [zoTabDisableFlg, setZoTabDisableFlg] = React.useState(true);
  const [doTabDisableFlg, setDoTabDisableFlg] = React.useState(true);
  const [ofcTabDisableFlg, setOfcTabDisableFlg] = React.useState(true);
  const [kobetuTabDisableFlg, setKobetuTabDisableFlg] = React.useState(true);

  const [tableHeaderList, setTableHeaderList] = React.useState<any[]>([]);
  const [headerName, setHeaderName] = React.useState("");
  const [tableSubmissionHeaderList, setTableSubmissionHeaderList] =
    React.useState<string[]>([]);

  const [tableList, setTableList] = React.useState<any[]>([
    { id: 1, name: "総合" },
  ]);
  const [smallTitleList1, setSmallTitleList1] = React.useState<any[]>([]);
  const [smallTitleList2, setSmallTitleList2] = React.useState<any[]>([]);
  const [smallTitleList3, setSmallTitleList3] = React.useState<any[]>([]);
  const [smallTitleList4, setSmallTitleList4] = React.useState<any[]>([]);
  const [smallTitleList5, setSmallTitleList5] = React.useState<any[]>([]);
  const [smallTitleList6, setSmallTitleList6] = React.useState<any[]>([]);
  const [smallTitleList7, setSmallTitleList7] = React.useState<any[]>([]);
  const [smallTitleList, setSmallTitleList] = React.useState<any[]>([
    initSmallTitle,
  ]);
  const [selectedSmallFlg, setSelectedSmallFlg] = React.useState(false);
  const [templateItemList, setTemplateItemList] = React.useState<any[]>([]);
  const [templateSmallItemList, setTemplateSmallItemList] = React.useState<
    any[]
  >([]);
  const [templatesMap, setTemplatesMap] = React.useState<any>({});

  const [selectedTable, setSelectedTable] = React.useState("1");
  const [selectedSmallTitle, setSelectedSmallTitle] = React.useState("1");
  const [selectedTemplateSmallTitle, setSelectedTemplateSmallTitle] =
    React.useState("0");

  const [selectedBaseDate, setSelectedBaseDate] =
    React.useState<DateTime | null>(null);
  const [selectedSmallBaseDate, setSelectedSmallBaseDate] =
    React.useState<DateTime | null>(null);
  const [baseWeek, setBaseWeek] = React.useState<string>("");
  const [smallBaseWeek, setSmallBaseWeek] = React.useState<string>("");

  const [allDoFlg, setAllDoFlg] = React.useState(false);

  const cloneListMap = (list: any) => {
    let newList: any[] = [];
    for (const map of list) {
      newList.push(cloneMap(map));
    }
    return newList;
  };
  const cloneMap = (map: any) => {
    let newMap: any = {};
    Object.keys(map).forEach(function (key) {
      newMap[key] = map[key];
    });
    return newMap;
  };
  // データ取得用useEffect
  useEffect(() => {
    changeTitles(tabValue);
    getSmallTitles();
    getTemplateTitles();
    if (initFlg) {
      userLogging("店舗確認表", "管理者分析#初期表示", "");
      setInigFlg(false);

      if (initTabValue && initTableMap) {
        setTargetData(cloneListMap(initTableMap["targetList0"]));
        setTargetData0(cloneListMap(initTableMap["targetList0"]));
        setTargetData1(cloneListMap(initTableMap["targetList1"]));
        setTargetData2(cloneListMap(initTableMap["targetList2"]));
        setTargetData3(cloneListMap(initTableMap["targetList3"]));
        setTargetData4(cloneListMap(initTableMap["targetList4"]));
        setTargetData5(cloneListMap(initTableMap["targetList5"]));
        setTargetData6(cloneListMap(initTableMap["targetList6"]));
        setTargetData7(cloneListMap(initTableMap["targetList7"]));
        setTargetData8(cloneListMap(initTableMap["targetList8"]));

        setTargetAverageData(cloneMap(initTableMap["targetAverageData0"]));
        setTargetAverageData0(cloneMap(initTableMap["targetAverageData0"]));
        setTargetAverageData1(cloneMap(initTableMap["targetAverageData1"]));
        setTargetAverageData2(cloneMap(initTableMap["targetAverageData2"]));
        setTargetAverageData3(cloneMap(initTableMap["targetAverageData3"]));
        setTargetAverageData4(cloneMap(initTableMap["targetAverageData4"]));
        setTargetAverageData5(cloneMap(initTableMap["targetAverageData5"]));
        setTargetAverageData6(cloneMap(initTableMap["targetAverageData6"]));
        setTargetAverageData7(cloneMap(initTableMap["targetAverageData7"]));
        setTargetAverageData8(cloneMap(initTableMap["targetAverageData8"]));
        setTableSubmissionHeaderList(initTableMap["tableSubmissionHeaderList"]);
        setTableHeaderList(initTableMap["tableHeaderList"]);
        setTableList(initTableMap["tableList"]);
        setWorkZoCode(initTableMap["workZoCode"]);
        setWorkDoCode(initTableMap["workDoCode"]);
        setOfcId(initTableMap["ofcId"]);
        setTenpoCode(initTableMap["tenpoCode"]);
        setTemplateItemList(cloneListMap(initTableMap["templateItemList"]));
        setTemplatesMap(cloneMap(initTableMap["templatesMap"]));
        setTemplateSmallItemList(
          cloneListMap(initTableMap["templateSmallItemList"])
        );
        setAllDoFlg(initTableMap["allDoFlg"]);
        setIndexTabValue(initTableMap["indexTabValue"]);

        changeGraphData(
          initTableMap["targetList0"],
          initTableMap["tableHeaderList"],
          initTableMap["targetAverageData0"]
        );
        updateDisableTab();
        getTemplateTitles(initTableMap["workDoCode"]);
        return;
      }

      // admin-groupの場合のみ初回読み込み時に全検索を行う
      if (user.jobCategory !== "admin-group") {
        if (user.jobCategory === "zm") {
          changeTitles(1);
        }
        if (user.jobCategory === "dm") {
          changeTitles(2);
        }
        return;
      }
    }

    userLogging(
      "店舗確認表",
      "管理者分析#データ取得条件",
      "タブ名[" +
        headerName +
        "別分析] ZO[" +
        workZoCode +
        "] DO[" +
        workDoCode +
        "] データ[" +
        workBaseData +
        "] OFC[" +
        ofcId +
        "] 店舗[" +
        tenpoCode +
        "]"
    );
    getTableData(
      String(tabValue + 1),
      workZoCode,
      workDoCode,
      workBaseData || DateTime.local(),
      ofcId,
      tenpoCode
    ).then((res: any) => {
      changeTitles(tabValue);
      let responseMap: any = res;
      userLogging(
        "店舗確認表",
        "管理者分析#データ取得結果",
        JSON.stringify(responseMap)
      );
      if (responseMap) {
        setTargetData(responseMap.dataList);
      }
      updateDisableTab();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, updateFlg]);

  // 詳細項目一覧取得
  const getSmallTitles = () => {
    getFirestoreDocData("tenpo_check_items", "000000_basedata").then(
      (response: any) => {
        let initItem = initSmallTitle;
        let smallItems1: any[] = [initItem];
        let smallItems2: any[] = [initItem];
        let smallItems3: any[] = [initItem];
        let smallItems4: any[] = [initItem];
        let smallItems5: any[] = [initItem];
        let smallItems6: any[] = [initItem];
        let smallItems7: any[] = [initItem];

        Object.keys(response.attribution)
          .sort()
          .forEach(function (key) {
            let smallItems: any[] = response.attribution[key].small_items;
            let bigItemId = response.attribution[key].big_item_id;
            for (const row of smallItems) {
              let id = Number(row.id) + 1;
              let name = row.name;
              if (bigItemId === "1") smallItems1.push({ id: id, name: name });
              if (bigItemId === "2") smallItems2.push({ id: id, name: name });
              if (bigItemId === "3") smallItems3.push({ id: id, name: name });
              if (bigItemId === "4") smallItems4.push({ id: id, name: name });
              if (bigItemId === "5") smallItems5.push({ id: id, name: name });
              if (bigItemId === "6") smallItems6.push({ id: id, name: name });
              if (bigItemId === "7") smallItems7.push({ id: id, name: name });
            }
          });
        setSmallTitleList1(smallItems1);
        setSmallTitleList2(smallItems2);
        setSmallTitleList3(smallItems3);
        setSmallTitleList4(smallItems4);
        setSmallTitleList5(smallItems5);
        setSmallTitleList6(smallItems6);
        setSmallTitleList7(smallItems7);
        return;
      }
    );
  };

  // 詳細項目一覧取得
  const getTemplateTitles = (docode?: any) => {
    const conditions: string[] = docode
      ? [docode]
      : workDoCode
      ? [workDoCode]
      : [];
    getTemplateData(conditions).then((response: any[]) => {
      // テンプレートの各小項目プルダウン
      const templatesMap: any = {};
      for (const templateItem of response) {
        templatesMap[templateItem.id] = {
          id: templateItem.id,
          name: templateItem.template_name,
          smallItems: templateItem.small_items,
        };
      }

      // テンプレート一覧プルダウン
      const templateItems: any[] = (Object.entries(templatesMap) as any[]).map(
        ([key, value]) => ({
          id: key,
          name: value.name,
        })
      );

      setTemplateItemList([initSmallTitle, ...templateItems]);
      setTemplatesMap(templatesMap);
    });
  };

  // 実行ボタン押下時用useEffect
  useEffect(() => {
    if (initTabValue !== 0 && initFlg) {
      return;
    }
    setTabValue(analysis);
    setIndexTabValue(analysis);
    if (user.jobCategory === "zm") {
      setIndexTabValue(analysis - 1);
    }
    if (user.jobCategory === "dm") {
      setIndexTabValue(analysis - 2);
    }
    if (zoCode !== "") {
      setWorkZoCode(zoCode);
    }
    if (doCode !== "") {
      setWorkDoCode(doCode);
    }
    // setOfcId("");
    // setTenpoCode("");
    setUpdateFlg(update);
    setWorkBaseData(baseData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  // 表示するヘッダ名の変更
  const changeTitles = (num: number) => {
    if (num === 0) {
      setHeaderName("ZO");
    }
    if (num === 1) {
      setHeaderName("DO");
    }
    if (num === 2) {
      setHeaderName("OFC");
    }
    if (num === 3) {
      setHeaderName("個店");
    }
  };

  //  タブのクリック処理
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setIndexTabValue(newValue);
    if (user.jobCategory === "zm") {
      newValue = newValue + 1;
    }
    if (user.jobCategory === "dm") {
      newValue = newValue + 2;
    }

    changeTitles(newValue);
    setTabValue(newValue);
  };

  const dialogClose = () => {
    setDialogFlg(false);
  };

  // タブの活性・非活性更新
  function updateDisableTab() {
    if (user.jobCategory === "admin-group") {
      setZoTabDisableFlg(false);
    }
    setDoTabDisableFlg(workZoCode ? false : true);
    setOfcTabDisableFlg(workDoCode ? false : true);
    setKobetuTabDisableFlg(ofcId ? false : true);
  }

  // 平均点計算
  function calcAverage(num: any, count: any) {
    if (!num) {
      return 0;
    }
    return Math.round(parseFloat(num) / parseFloat(count));
  }

  // パーセント計算
  function calcPercent(num: any, count: any) {
    if (!num) {
      return 0;
    }
    return Math.round((parseFloat(num) / parseFloat(count)) * 100);
  }
  // 差分計算
  function calcDiff(num1: any, num2: any) {
    let mainScore: number = Number(num1) || 0;
    let diffScore: number = Number(num2) || 0;
    let result: number = 0;
    result = Math.round(mainScore) - Math.round(diffScore);
    result = Math.round(result);

    return result;
  }
  // コード順に並べ変え
  function sortCode(dataMapList: any[]) {
    let mapList: any[] = dataMapList;

    if (tabValue === 2) {
      return mapList.sort(function (first, second) {
        if (first.sortDoCode > second.sortDoCode) {
          return 1;
        } else if (first.sortDoCode < second.sortDoCode) {
          return -1;
        } else {
          if (first.sortOfcCode > second.sortOfcCode) {
            return 1;
          } else if (first.sortOfcCode < second.sortOfcCode) {
            return -1;
          } else {
            return 0;
          }
        }
      });
    } else if (tabValue === 3) {
      return mapList.sort(function (first, second) {
        if (first.sortOfcCode > second.sortOfcCode) {
          return 1;
        } else if (first.sortOfcCode < second.sortOfcCode) {
          return -1;
        } else {
          if (first.code > second.code) {
            return 1;
          } else if (first.code < second.code) {
            return -1;
          } else {
            return 0;
          }
        }
      });
    } else {
      return mapList.sort(function (first, second) {
        if (first.code > second.code) {
          return 1;
        } else if (first.code < second.code) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }
  // 総数計算の対象判断
  const countTotalTarget = (score: any) => {
    let value: number = Number(score);
    if (value === 0) {
      return 0;
    } else {
      return 1;
    }
  };
  // 小数点以下を四捨五入
  const roundOffDecimal = (num: any) => {
    let value: number = Number(num);
    if (value) {
      return Math.round(value);
    }
    return 0;
  };

  // データ取得(API)
  const getData = (
    analysis: string,
    zoCode: string,
    doCode: string,
    target_date: DateTime,
    ofc_id: string,
    tenpo_code: string
  ) => {
    let workAnalysis = String(analysis);
    if (allDoFlg && workAnalysis === "4") {
      workAnalysis = "5";
    }
    const apiParams = {
      "api-name": "analysis",
      target_date: target_date.toFormat("yyyy/MM/dd HH:mm:ss"),
      zo_code: workZoCode,
      do_code: workDoCode,
      ofc_id: ofc_id,
      tenpo_code: tenpo_code,
      analysis: workAnalysis,
    };

    let selectList: any[] = [];
    selectList.push({ id: 1, name: "総合" });
    for (let i = 0; i < tableHeaderList2.length; i++) {
      selectList.push({ id: i + 2, name: tableHeaderList2[i] });
    }
    setTableList(selectList);
    setExecuteFlg("1");
    const url = process.env.REACT_APP_GAE_API_URL + "tenpocheck/analysis";
    return postRequest(url, apiParams)
      .then((res) => {
        let dataList: any[] = res.data.dataList;
        let dispatchDateMap: any = {};

        setSelectedBaseDate(null);
        setSelectedSmallBaseDate(null);
        setBaseWeek("");
        setSmallBaseWeek("");
        if (dataList) {
          // 画面入力初期化
          setGraphUserList([]);
          setSelectedTable("1");
          setSelectedSmallTitle("1");
          setSmallTitleList([initSmallTitle]);
          setSelectedSmallFlg(false);
          for (let i = 0; i < dataList.length; i++) {
            let datajsList: any = dataList[i];
            let averageScoreBreakdown: any[] =
              datajsList.average_score_breakdown;

            let weekValue: any = datajsList.week_value[0].week;
            let submissionWeekValue: any =
              datajsList.week_value[0].submission_week;
            let headerList = [];
            // 合計値用インターフェイス
            interface totalMap {
              "1w": number;
              "2w": number;
              "3w": number;
              "4w": number;
              "5w": number;
              "6w": number;
            }
            const initTotalMap = () => {
              let map: totalMap = {
                "1w": 0,
                "2w": 0,
                "3w": 0,
                "4w": 0,
                "5w": 0,
                "6w": 0,
              };
              return map;
            };
            // 合計値加算
            const addTotals = (
              map: totalMap,
              num1: any,
              num2: any,
              num3: any,
              num4: any,
              num5: any,
              num6?: any
            ) => {
              map["1w"] += roundOffDecimal(num1);
              map["2w"] += roundOffDecimal(num2);
              map["3w"] += roundOffDecimal(num3);
              map["4w"] += roundOffDecimal(num4);
              map["5w"] += roundOffDecimal(num5);
              // 0week
              map["6w"] += roundOffDecimal(num6);
            };
            // 地区平均の作成
            const makeAverage = (
              totalScore: totalMap,
              countList: number[],
              headerList: any[]
            ) => {
              let averageMap: any = {
                name: "地区平均／" + averageTitle + "平均",
                code: "average",
              };
              // totalには5～1が逆に入っている 6は別
              let average1 = calcAverage(totalScore["1w"], countList[0]);
              let average2 = calcAverage(totalScore["2w"], countList[1]);
              let average3 = calcAverage(totalScore["3w"], countList[2]);
              let average4 = calcAverage(totalScore["4w"], countList[3]);
              let average5 = calcAverage(totalScore["5w"], countList[4]);
              let average6 = calcAverage(totalScore["6w"], countList[5]);

              averageMap[headerList[0]] = average1;
              averageMap[headerList[1]] = average2;
              averageMap[headerList[2]] = average3;
              averageMap[headerList[3]] = average4;
              averageMap[headerList[4]] = average5;
              averageMap["1wDiff"] = calcDiff(average6, average1);
              averageMap["2wDiff"] = calcDiff(average2, average1);
              averageMap["3wDiff"] = calcDiff(average3, average2);
              averageMap["4wDiff"] = calcDiff(average4, average3);
              averageMap["5wDiff"] = calcDiff(average5, average4);
              return averageMap;
            };

            const dataMapCreate = (
              dataMap: any,
              headerList: any[],
              no: number,
              resultMap: any
            ) => {
              let map: any = {
                name: dataMap.name,
                code: dataMap.code,
                sortDoCode: dataMap.sortDoCode,
                sortOfcCode: dataMap.sortOfcCode,
                ofcName: dataMap.ofcName,
              };

              map[headerList[4]] = roundOffDecimal(
                resultMap.result1["score" + no]
              );
              map[headerList[3]] = roundOffDecimal(
                resultMap.result2["score" + no]
              );
              map[headerList[2]] = roundOffDecimal(
                resultMap.result3["score" + no]
              );
              map[headerList[1]] = roundOffDecimal(
                resultMap.result4["score" + no]
              );
              map[headerList[0]] = roundOffDecimal(
                resultMap.result5["score" + no]
              );
              map["5wDiff"] = calcDiff(
                resultMap.result1["score" + no],
                resultMap.result2["score" + no]
              );
              map["4wDiff"] = calcDiff(
                resultMap.result2["score" + no],
                resultMap.result3["score" + no]
              );
              map["3wDiff"] = calcDiff(
                resultMap.result3["score" + no],
                resultMap.result4["score" + no]
              );
              map["2wDiff"] = calcDiff(
                resultMap.result4["score" + no],
                resultMap.result5["score" + no]
              );
              map["1wDiff"] = calcDiff(
                resultMap.result5["score" + no],
                resultMap.result6["score" + no]
              );
              return map;
            };

            // 週名称の設定
            if (weekValue) {
              headerList.push(weekValue.week5 + "週");
              headerList.push(weekValue.week4 + "週");
              headerList.push(weekValue.week3 + "週");
              headerList.push(weekValue.week2 + "週");
              headerList.push(weekValue.week1 + "週");

              setTableHeaderList(headerList);
              dispatchDateMap["tableHeaderList"] = headerList;
            }
            // 提出数用週
            if (submissionWeekValue) {
              let list: string[] = [];
              list.push(submissionWeekValue.week5);
              list.push(submissionWeekValue.week4);
              list.push(submissionWeekValue.week3);
              list.push(submissionWeekValue.week2);
              list.push(submissionWeekValue.week1);
              setTableSubmissionHeaderList(list);
              dispatchDateMap["tableSubmissionHeaderList"] = list;
            }

            // 統合の設定
            let averageTitle = "";
            if (tabValue === 0) {
              averageTitle = "ZO";
            } else if (tabValue === 1) {
              averageTitle = "DO";
            } else if (tabValue === 2) {
              averageTitle = "OFC";
            } else if (tabValue === 3) {
              averageTitle = "個別";
            }
            let totalScore0: totalMap = initTotalMap();
            let week1Count = 0;
            let week2Count = 0;
            let week3Count = 0;
            let week4Count = 0;
            let week5Count = 0;
            let week6Count = 0;

            // 項目別の設定
            let scoreList0: any[] = [];
            let scoreList1: any[] = [];
            let scoreList2: any[] = [];
            let scoreList3: any[] = [];
            let scoreList4: any[] = [];
            let scoreList5: any[] = [];
            let scoreList6: any[] = [];
            let scoreList7: any[] = [];
            let scoreList8: any[] = [];
            let totalScore1: totalMap = initTotalMap();
            let totalScore2: totalMap = initTotalMap();
            let totalScore3: totalMap = initTotalMap();
            let totalScore4: totalMap = initTotalMap();
            let totalScore5: totalMap = initTotalMap();
            let totalScore6: totalMap = initTotalMap();
            let totalScore7: totalMap = initTotalMap();
            let totalScore8: totalMap = initTotalMap();

            for (let j = 0; j < averageScoreBreakdown.length; j++) {
              let name = averageScoreBreakdown[j].codeName.name;
              let code = averageScoreBreakdown[j].codeName.code;
              let sortDoCode = averageScoreBreakdown[j].codeName.sort_docode;
              let sortOfcCode = averageScoreBreakdown[j].codeName.sort_ofc_code;
              let ofcName = averageScoreBreakdown[j].codeName.ofc_name;
              const submissionWeek = averageScoreBreakdown[j].submissionWeek;

              let result1 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result1
                  ? averageScoreBreakdown[j].result1
                  : "";
              let result2 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result2
                  ? averageScoreBreakdown[j].result2
                  : "";
              let result3 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result3
                  ? averageScoreBreakdown[j].result3
                  : "";
              let result4 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result4
                  ? averageScoreBreakdown[j].result4
                  : "";
              let result5 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result5
                  ? averageScoreBreakdown[j].result5
                  : "";
              let result6 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result6
                  ? averageScoreBreakdown[j].result6
                  : "";
              let map: any = { name, code, sortDoCode, sortOfcCode, ofcName };
              let resultMap = {
                result1,
                result2,
                result3,
                result4,
                result5,
                result6,
              };

              week1Count += countTotalTarget(result5.recordCount1);
              week2Count += countTotalTarget(result4.recordCount1);
              week3Count += countTotalTarget(result3.recordCount1);
              week4Count += countTotalTarget(result2.recordCount1);
              week5Count += countTotalTarget(result1.recordCount1);
              week6Count += countTotalTarget(result6.recordCount1);

              addTotals(
                totalScore0,
                result5.score0,
                result4.score0,
                result3.score0,
                result2.score0,
                result1.score0
              );
              addTotals(
                totalScore1,
                result5.score1,
                result4.score1,
                result3.score1,
                result2.score1,
                result1.score1
              );
              addTotals(
                totalScore2,
                result5.score2,
                result4.score2,
                result3.score2,
                result2.score2,
                result1.score2
              );
              addTotals(
                totalScore3,
                result5.score3,
                result4.score3,
                result3.score3,
                result2.score3,
                result1.score3
              );
              addTotals(
                totalScore4,
                result5.score4,
                result4.score4,
                result3.score4,
                result2.score4,
                result1.score4
              );
              addTotals(
                totalScore5,
                result5.score5,
                result4.score5,
                result3.score5,
                result2.score5,
                result1.score5
              );
              addTotals(
                totalScore6,
                result5.score6,
                result4.score6,
                result3.score6,
                result2.score6,
                result1.score6
              );
              addTotals(
                totalScore7,
                result5.score7,
                result4.score7,
                result3.score7,
                result2.score7,
                result1.score7
              );
              addTotals(
                totalScore8,
                result5.score8,
                result4.score8,
                result3.score8,
                result2.score8,
                result1.score8
              );
              if (tabValue === 2 || tabValue === 3) {
                scoreList0.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 0, resultMap),
                    submissionWeek
                  )
                );
                scoreList1.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 1, resultMap),
                    submissionWeek
                  )
                );
                scoreList2.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 2, resultMap),
                    submissionWeek
                  )
                );
                scoreList3.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 3, resultMap),
                    submissionWeek
                  )
                );
                scoreList4.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 4, resultMap),
                    submissionWeek
                  )
                );
                scoreList5.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 5, resultMap),
                    submissionWeek
                  )
                );
                scoreList6.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 6, resultMap),
                    submissionWeek
                  )
                );
                scoreList7.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 7, resultMap),
                    submissionWeek
                  )
                );
                scoreList8.push(
                  Object.assign(
                    dataMapCreate(map, headerList, 8, resultMap),
                    submissionWeek
                  )
                );
              } else {
                scoreList0.push(dataMapCreate(map, headerList, 0, resultMap));
                scoreList1.push(dataMapCreate(map, headerList, 1, resultMap));
                scoreList2.push(dataMapCreate(map, headerList, 2, resultMap));
                scoreList3.push(dataMapCreate(map, headerList, 3, resultMap));
                scoreList4.push(dataMapCreate(map, headerList, 4, resultMap));
                scoreList5.push(dataMapCreate(map, headerList, 5, resultMap));
                scoreList6.push(dataMapCreate(map, headerList, 6, resultMap));
                scoreList7.push(dataMapCreate(map, headerList, 7, resultMap));
                scoreList8.push(dataMapCreate(map, headerList, 8, resultMap));
              }
            }

            let countList: any[] = [];
            countList.push(week1Count);
            countList.push(week2Count);
            countList.push(week3Count);
            countList.push(week4Count);
            countList.push(week5Count);
            countList.push(week6Count);
            // 地区平均
            let averageMap0 = makeAverage(totalScore0, countList, headerList);
            let averageMap1 = makeAverage(totalScore1, countList, headerList);
            let averageMap2 = makeAverage(totalScore2, countList, headerList);
            let averageMap3 = makeAverage(totalScore3, countList, headerList);
            let averageMap4 = makeAverage(totalScore4, countList, headerList);
            let averageMap5 = makeAverage(totalScore5, countList, headerList);
            let averageMap6 = makeAverage(totalScore6, countList, headerList);
            let averageMap7 = makeAverage(totalScore7, countList, headerList);
            let averageMap8 = makeAverage(totalScore8, countList, headerList);
            setTargetAverageData(averageMap0);
            setTargetAverageData0(averageMap0);
            setTargetAverageData1(averageMap1);
            setTargetAverageData2(averageMap2);
            setTargetAverageData3(averageMap3);
            setTargetAverageData4(averageMap4);
            setTargetAverageData5(averageMap5);
            setTargetAverageData6(averageMap6);
            setTargetAverageData7(averageMap7);
            setTargetAverageData8(averageMap8);

            setTargetData0(sortCode(scoreList0));
            setTargetData1(sortCode(scoreList1));
            setTargetData2(sortCode(scoreList2));
            setTargetData3(sortCode(scoreList3));
            setTargetData4(sortCode(scoreList4));
            setTargetData5(sortCode(scoreList5));
            setTargetData6(sortCode(scoreList6));
            setTargetData7(sortCode(scoreList7));
            setTargetData8(sortCode(scoreList8));

            dispatchDateMap["targetList0"] = cloneListMap(sortCode(scoreList0));
            dispatchDateMap["targetList1"] = cloneListMap(sortCode(scoreList1));
            dispatchDateMap["targetList2"] = cloneListMap(sortCode(scoreList2));
            dispatchDateMap["targetList3"] = cloneListMap(sortCode(scoreList3));
            dispatchDateMap["targetList4"] = cloneListMap(sortCode(scoreList4));
            dispatchDateMap["targetList5"] = cloneListMap(sortCode(scoreList5));
            dispatchDateMap["targetList6"] = cloneListMap(sortCode(scoreList6));
            dispatchDateMap["targetList7"] = cloneListMap(sortCode(scoreList7));
            dispatchDateMap["targetList8"] = cloneListMap(sortCode(scoreList8));
            dispatchDateMap["targetAverageData0"] = cloneMap(averageMap0);
            dispatchDateMap["targetAverageData1"] = cloneMap(averageMap1);
            dispatchDateMap["targetAverageData2"] = cloneMap(averageMap2);
            dispatchDateMap["targetAverageData3"] = cloneMap(averageMap3);
            dispatchDateMap["targetAverageData4"] = cloneMap(averageMap4);
            dispatchDateMap["targetAverageData5"] = cloneMap(averageMap5);
            dispatchDateMap["targetAverageData6"] = cloneMap(averageMap6);
            dispatchDateMap["targetAverageData7"] = cloneMap(averageMap7);
            dispatchDateMap["targetAverageData8"] = cloneMap(averageMap8);
            dispatchDateMap["tableList"] = tableList;
            dispatchDateMap["workZoCode"] = workZoCode;
            dispatchDateMap["workDoCode"] = workDoCode;
            dispatchDateMap["ofcId"] = ofcId;
            dispatchDateMap["tenpoCode"] = tenpoCode;
            dispatchDateMap["templateItemList"] =
              cloneListMap(templateItemList);
            dispatchDateMap["templatesMap"] = cloneMap(templatesMap);
            dispatchDateMap["templateSmallItemList"] = cloneListMap(
              templateSmallItemList
            );
            dispatchDateMap["allDoFlg"] = allDoFlg;
            dispatchDateMap["indexTabValue"] = indexTabValue;

            dispatch(
              updateTenpoCheckManagerMap({
                tenpoCheckManagerMap: dispatchDateMap,
              })
            );

            dispatch(
              updateTenpoCheckManagerTab({
                tenpoCheckManagerTab: tabValue,
              })
            );

            return {
              weekList: scoreList0,
              headerList,
              averageMap: averageMap0,
            };
          }
        }
      })
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
      })
      .finally(() => {
        setExecuteFlg("");
      });
  };

  // グラフ用の値を補足
  const getTableData = async (
    analysis: string,
    zoCode: string,
    doCode: string,
    target_date: DateTime,
    ofc_id: string,
    tenpo_code: string
  ) => {
    let resultMap: any = {};
    let result = await getData(
      analysis,
      zoCode,
      doCode,
      target_date,
      ofc_id,
      tenpo_code
    );
    let dataList: any[] = [];
    if (result) {
      resultMap = result;
      dataList = result.weekList;
      let headerList = result.headerList;
      let averageMap = result.averageMap;
      changeGraphData(dataList, headerList, averageMap);
    }
    resultMap["dataList"] = dataList;

    return resultMap;
  };

  // グラフの設定
  function changeGraphData(
    dataList: any[],
    headerList: any[],
    averageMap: any
  ) {
    let weekMap_1: any = {};
    let weekMap_2: any = {};
    let weekMap_3: any = {};
    let weekMap_4: any = {};
    let weekMap_5: any = {};
    let graphDataList = [];

    if (dataList) {
      for (let i = 0; i < dataList.length; i++) {
        weekMap_1[dataList[i].name] = dataList[i][headerList[0]];
        weekMap_2[dataList[i].name] = dataList[i][headerList[1]];
        weekMap_3[dataList[i].name] = dataList[i][headerList[2]];
        weekMap_4[dataList[i].name] = dataList[i][headerList[3]];
        weekMap_5[dataList[i].name] = dataList[i][headerList[4]];
      }
      if (averageMap) {
        weekMap_1[averageMap.name] = averageMap[headerList[0]];
        weekMap_2[averageMap.name] = averageMap[headerList[1]];
        weekMap_3[averageMap.name] = averageMap[headerList[2]];
        weekMap_4[averageMap.name] = averageMap[headerList[3]];
        weekMap_5[averageMap.name] = averageMap[headerList[4]];
      }
    }

    for (let j = 0; j < headerList.length; j++) {
      if (j === 0) weekMap_1["week"] = headerList[j];
      if (j === 1) weekMap_2["week"] = headerList[j];
      if (j === 2) weekMap_3["week"] = headerList[j];
      if (j === 3) weekMap_4["week"] = headerList[j];
      if (j === 4) weekMap_5["week"] = headerList[j];
    }
    graphDataList.push(weekMap_1);
    graphDataList.push(weekMap_2);
    graphDataList.push(weekMap_3);
    graphDataList.push(weekMap_4);
    graphDataList.push(weekMap_5);

    setTargetGraphData(graphDataList);
  }

  // ZO名、DO名、OFC名のクリック
  const moveClick = (code: string) => {
    let minusIndex = 0;
    setAllDoFlg(false);
    if (user.jobCategory === "zm") {
      minusIndex = 1;
    }
    if (user.jobCategory === "dm") {
      minusIndex = 2;
    }
    if (tabValue === 0) {
      setWorkZoCode(code);
      // 下層タブのクリア
      setWorkDoCode("");
      setOfcId("");
      setTenpoCode("");
      setIndexTabValue(1 - minusIndex);
      setTabValue(1);
    }
    if (tabValue === 1) {
      setWorkDoCode("00" + code);
      setOfcId("");
      setTenpoCode("");
      setIndexTabValue(2 - minusIndex);
      setTabValue(2);
    }
    if (tabValue === 2) {
      setOfcId(code);
      setTenpoCode("");
      setIndexTabValue(3 - minusIndex);
      setTabValue(3);
    }
    if (tabValue === 3) {
      setTenpoCode(code);
      setIndexTabValue(4 - minusIndex);
      setTabValue(4);
    }
  };

  // ZO名、DO名、OFC名のクリック
  const moveClickDMAll = () => {
    let minusIndex = 0;
    setAllDoFlg(true);
    if (user.jobCategory === "zm") {
      minusIndex = 1;
    }
    if (user.jobCategory === "dm") {
      minusIndex = 2;
    }
    if (tabValue === 2) {
      // setTenpoCode(code);
      setIndexTabValue(3 - minusIndex);
      setTabValue(3);
    }
  };

  // 選択ボタンのクリック
  const selectClick = (code: string, name: string) => {
    let addFlg = true;
    let userList = [];
    let cnt = 0;
    for (let i = 0; i < graphUserList.length; i++) {
      cnt += 1;
      if (code === graphUserList[i].code) {
        addFlg = false;
        continue;
      }
      userList.push(graphUserList[i]);
    }
    let colorNumber = cnt % 10;
    if (addFlg) {
      userList.push({ code, name, fill: fillCodeList[colorNumber] });
    }
    setGraphUserList(userList);
  };

  //　表示区分のセレクトボックス変更時の処理
  const doSelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let val: string = String(event.target.value);
    setSelectedTable(val);
    setSelectedSmallTitle("1");
    setSelectedSmallFlg(false);
    if (val === "1") {
      setTargetAverageData(targetAverageData0);
      setTargetData(targetData0);
      setSmallTitleList([initSmallTitle]);
      changeGraphData(targetData0, tableHeaderList, targetAverageData0);
    }
    if (val === "2") {
      setTargetAverageData(targetAverageData1);
      setTargetData(targetData1);
      setSmallTitleList(smallTitleList1);
      changeGraphData(targetData1, tableHeaderList, targetAverageData1);
    }
    if (val === "3") {
      setTargetAverageData(targetAverageData2);
      setTargetData(targetData2);
      setSmallTitleList(smallTitleList2);
      changeGraphData(targetData2, tableHeaderList, targetAverageData2);
    }
    if (val === "4") {
      setTargetAverageData(targetAverageData3);
      setTargetData(targetData3);
      setSmallTitleList(smallTitleList3);
      changeGraphData(targetData3, tableHeaderList, targetAverageData3);
    }
    if (val === "5") {
      setTargetAverageData(targetAverageData4);
      setTargetData(targetData4);
      setSmallTitleList(smallTitleList4);
      changeGraphData(targetData4, tableHeaderList, targetAverageData4);
    }
    if (val === "6") {
      setTargetAverageData(targetAverageData5);
      setTargetData(targetData5);
      setSmallTitleList(smallTitleList5);
      changeGraphData(targetData5, tableHeaderList, targetAverageData5);
    }
    if (val === "7") {
      setTargetAverageData(targetAverageData6);
      setTargetData(targetData6);
      setSmallTitleList(smallTitleList6);
      changeGraphData(targetData6, tableHeaderList, targetAverageData6);
    }
    if (val === "8") {
      setTargetAverageData(targetAverageData7);
      setTargetData(targetData7);
      setSmallTitleList(smallTitleList7);
      changeGraphData(targetData7, tableHeaderList, targetAverageData7);
    }
    if (val === "9") {
      setTargetAverageData(targetAverageData8);
      setTargetData(targetData8);
      if (tabValue === 3) {
        setSmallTitleList(templateItemList);
      } else {
        setSmallTitleList([initSmallTitle]);
      }
      changeGraphData(targetData8, tableHeaderList, targetAverageData8);
    }

    setTabValue(tabValue);
  };

  //　小項目区分のセレクトボックス変更時の処理
  const doSmallSelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let val: string = String(event.target.value);
    setSelectedSmallTitle(val);
    if (val === "1") {
      setSelectedSmallFlg(false);
      if (selectedTable === "1") {
        setTargetAverageData(targetAverageData0);
        setTargetData(targetData0);
        changeGraphData(targetData0, tableHeaderList, targetAverageData0);
      }
      if (selectedTable === "2") {
        setTargetAverageData(targetAverageData1);
        setTargetData(targetData1);
        changeGraphData(targetData1, tableHeaderList, targetAverageData1);
      }
      if (selectedTable === "3") {
        setTargetAverageData(targetAverageData2);
        setTargetData(targetData2);
        changeGraphData(targetData2, tableHeaderList, targetAverageData2);
      }
      if (selectedTable === "4") {
        setTargetAverageData(targetAverageData3);
        setTargetData(targetData3);
        changeGraphData(targetData3, tableHeaderList, targetAverageData3);
      }
      if (selectedTable === "5") {
        setTargetAverageData(targetAverageData4);
        setTargetData(targetData4);
        changeGraphData(targetData4, tableHeaderList, targetAverageData4);
      }
      if (selectedTable === "6") {
        setTargetAverageData(targetAverageData5);
        setTargetData(targetData5);
        changeGraphData(targetData5, tableHeaderList, targetAverageData5);
      }
      if (selectedTable === "7") {
        setTargetAverageData(targetAverageData6);
        setTargetData(targetData6);
        changeGraphData(targetData6, tableHeaderList, targetAverageData6);
      }
      if (selectedTable === "8") {
        setTargetAverageData(targetAverageData7);
        setTargetData(targetData7);
        changeGraphData(targetData7, tableHeaderList, targetAverageData7);
      }
      if (selectedTable === "9") {
        setTargetAverageData(targetAverageData8);
        setTargetData(targetData8);
        changeGraphData(targetData8, tableHeaderList, targetAverageData8);
      }
    } else {
      if (selectedTable === "9") {
        if (templatesMap[val]) {
          setTemplateSmallItemList(templatesMap[val].smallItems);
          setSelectedTemplateSmallTitle("0");
        }
      } else {
        setSmallBaseWeek("");
        setSelectedSmallBaseDate(null);
        setSelectedSmallFlg(true);
        getSmallData(
          workBaseData || DateTime.local(),
          Number(selectedTable) - 1,
          Number(val) - 1
        );
        changeGraphData([], tableHeaderList, "");
      }
    }
  };

  //　テンプレート小項目区分のセレクトボックス変更時の処理
  const doTemplateSmallSelChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let val: string = String(event.target.value);
    setSelectedTemplateSmallTitle(val);
    setSelectedSmallFlg(true);
    if (val) {
      setSmallBaseWeek("");
      setSelectedSmallBaseDate(null);
      setSelectedSmallFlg(true);
      const selectRow: any[] = templateSmallItemList.filter(
        (rowItem) => rowItem.id === Number(val)
      );

      getSmallData(
        workBaseData || DateTime.local(),
        Number(selectedTable) - 1,
        Number(val) - 1,
        selectRow[0].name
      );
      changeGraphData([], tableHeaderList, "");
    }
  };

  // 基準週の取得
  function getFirstDayOfWeek(target_date: string, addDate: number) {
    // 日付取得
    let today = zonedTimeToUtc(target_date, "Asia/Tokyo");
    let this_year = today.getFullYear();
    let this_month = today.getMonth();
    let date = today.getDate();
    let day_num = today.getDay();
    // Sunday - Saturday : 0 - 6
    if (day_num === 0) {
      day_num = 7;
    }
    let this_monday = date - day_num + 1 + addDate;

    //月曜日の年月日
    let firstDayOfWeek = new Date(this_year, this_month, this_monday);
    let month = firstDayOfWeek.getMonth() + 1;
    let day = firstDayOfWeek.getDate();
    if (!month || !day) {
      return "";
    }
    //文字列を作成
    return month + "月" + day + "日週";
  }

  // 基準日の選択
  const handleBaseDate = (date: DateTime | null) => {
    if (selectedSmallFlg) {
      if (date) {
        let baseWeek = getFirstDayOfWeek(date.toFormat("yyyyMMdd"), 0);
        setSmallBaseWeek(baseWeek);
        getSmallBaseDate(date);
      } else {
        updataSmallBaseDataInit();
        setSmallBaseWeek("");
      }
      setSelectedSmallBaseDate(date);
      return date;
    } else {
      if (date) {
        let baseWeek = getFirstDayOfWeek(date.toFormat("yyyyMMdd"), 0);
        setBaseWeek(baseWeek);
        updateBaseDate(date);
      } else {
        setSelectedTable("1");
        setSmallTitleList([initSmallTitle]);
        updataBaseDataInit();
        setBaseWeek("");
      }
      setSelectedBaseDate(date);
      setSelectedSmallBaseDate(null);
      return date;
    }
  };

  // 基準日を空白にした際の初期化処理
  function updataBaseDataInit() {
    const resetBase = (dataList: any[]) => {
      let data: any[] = [];
      for (let i = 0; i < dataList.length; i++) {
        dataList[i]["baseWeekScore"] = "";
        dataList[i]["1wDiffBase"] = "";
        dataList[i]["2wDiffBase"] = "";
        dataList[i]["3wDiffBase"] = "";
        dataList[i]["4wDiffBase"] = "";
        dataList[i]["5wDiffBase"] = "";
        data.push(dataList[i]);
      }
      return data;
    };

    const resetAverage = (dataMap: any) => {
      let averageMap = dataMap;
      averageMap["baseWeekScore"] = "";
      averageMap["1wDiffBase"] = "";
      averageMap["2wDiffBase"] = "";
      averageMap["3wDiffBase"] = "";
      averageMap["4wDiffBase"] = "";
      averageMap["5wDiffBase"] = "";
      return dataMap;
    };
    setTargetData0(resetBase(targetData0));
    setTargetData1(resetBase(targetData1));
    setTargetData2(resetBase(targetData2));
    setTargetData3(resetBase(targetData3));
    setTargetData4(resetBase(targetData4));
    setTargetData5(resetBase(targetData5));
    setTargetData6(resetBase(targetData6));
    setTargetData7(resetBase(targetData7));
    setTargetData8(resetBase(targetData8));

    setTargetAverageData0(resetAverage(targetAverageData0));
    setTargetAverageData1(resetAverage(targetAverageData1));
    setTargetAverageData2(resetAverage(targetAverageData2));
    setTargetAverageData3(resetAverage(targetAverageData3));
    setTargetAverageData4(resetAverage(targetAverageData4));
    setTargetAverageData5(resetAverage(targetAverageData5));
    setTargetAverageData6(resetAverage(targetAverageData6));
    setTargetAverageData7(resetAverage(targetAverageData7));
    setTargetAverageData8(resetAverage(targetAverageData8));
  }

  // 小項目表示で基準日を空白にした際の初期化処理
  function updataSmallBaseDataInit() {
    const resetBase = (dataList: any[]) => {
      let data: any[] = [];
      for (let i = 0; i < dataList.length; i++) {
        dataList[i]["baseWeekScore"] = "";
        data.push(dataList[i]);
      }
      return data;
    };
    const resetAverage = (dataMap: any) => {
      let averageMap = dataMap;
      averageMap["baseWeekScore"] = "";
      averageMap["1wDiffBase"] = "";
      averageMap["2wDiffBase"] = "";
      averageMap["3wDiffBase"] = "";
      averageMap["4wDiffBase"] = "";
      averageMap["5wDiffBase"] = "";
      return dataMap;
    };
    setTargetSmallData(resetBase(targetSmallData));
    setTargetSmallAverageData(resetAverage(targetSmallAverageData));
  }

  // 小項目の情報取得
  function getSmallData(
    target_date: DateTime,
    bigItemId: any,
    smallId: any,
    templateItemName?: string
  ) {
    let workAnalysis = String(tabValue + 1);
    if (allDoFlg && workAnalysis === "4") {
      workAnalysis = "5";
    }
    const apiParams = {
      "api-name": "analysis",
      target_date: target_date.toFormat("yyyy/MM/dd HH:mm:ss"),
      zo_code: workZoCode,
      ofc_id: ofcId,
      do_code: workDoCode,
      analysis: workAnalysis,
      big_item_id: String(bigItemId),
      small_item_id: String(smallId),
      is_oneweek: false,
      template_item_name: templateItemName,
    };
    setExecuteFlg("2");
    // スコアのパーセント表示フラグ
    let percentFlg = false;
    if (workAnalysis !== "4" && workAnalysis !== "5") {
      percentFlg = true;
    }
    const url = process.env.REACT_APP_GAE_API_URL + "tenpocheck/analysis-small";
    return postRequest(url, apiParams)
      .then((res) => {
        let dataList: any[] = res.data.dataList;
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let datajsList: any = dataList[i];
            let averageScoreBreakdown: any[] =
              datajsList.average_score_breakdown;
            let weekValue: any = datajsList.week_value[0];
            let submissionWeekValue: any = datajsList.submission_week;

            // 添付画像、コメント表示フラグ設定
            const createAttachedIconMap = (resultMap: any): any => {
              const photoFlagStr: string = "photo_flag";
              const commentFlagStr: string = "comment_flag";
              const weekNum: number = 5;
              const map: Map<string, string> = new Map<string, string>();
              // resultMapのresult\dは値が大きいものほど過去のため、
              for (let i = 1; i <= weekNum; i++) {
                const result = resultMap[`result${weekNum - i + 1}`];
                map.set(`${photoFlagStr}${i}`, result[photoFlagStr]);
                map.set(`${commentFlagStr}${i}`, result[commentFlagStr]);
              }
              return Object.fromEntries(map);
            }

            let weekList: any[] = [];
            let headerList = [];
            const dataMapCreate = (
              dataMap: any,
              headerList: any[],
              no: number,
              resultMap: any
            ) => {
              let map: any = {
                name: dataMap.name,
                code: dataMap.code,
                sortDoCode: dataMap.sortDoCode,
                sortOfcCode: dataMap.sortOfcCode,
                ofcName: dataMap.ofcName,
              };
              map["id5"] = resultMap.result1["id"];
              map["id4"] = resultMap.result2["id"];
              map["id3"] = resultMap.result3["id"];
              map["id2"] = resultMap.result4["id"];
              map["id1"] = resultMap.result5["id"];
              map[headerList[4]] = roundOffDecimal(
                resultMap.result1["score" + no]
              );
              map[headerList[3]] = roundOffDecimal(
                resultMap.result2["score" + no]
              );
              map[headerList[2]] = roundOffDecimal(
                resultMap.result3["score" + no]
              );
              map[headerList[1]] = roundOffDecimal(
                resultMap.result4["score" + no]
              );
              map[headerList[0]] = roundOffDecimal(
                resultMap.result5["score" + no]
              );

              map["5wDiff"] = calcDiff(
                resultMap.result1["score" + no],
                resultMap.result2["score" + no]
              );
              map["4wDiff"] = calcDiff(
                resultMap.result2["score" + no],
                resultMap.result3["score" + no]
              );
              map["3wDiff"] = calcDiff(
                resultMap.result3["score" + no],
                resultMap.result4["score" + no]
              );
              map["2wDiff"] = calcDiff(
                resultMap.result4["score" + no],
                resultMap.result5["score" + no]
              );
              map["1wDiff"] = calcDiff(
                resultMap.result5["score" + no],
                resultMap.result6["score" + no]
              );
              return map;
            };

            // 合計値用インターフェイス
            interface totalMap {
              "1w": number;
              "2w": number;
              "3w": number;
              "4w": number;
              "5w": number;
              "6w": number;
            }
            const initTotalMap = () => {
              let map: totalMap = {
                "1w": 0,
                "2w": 0,
                "3w": 0,
                "4w": 0,
                "5w": 0,
                "6w": 0,
              };
              return map;
            };
            // 合計値加算
            const addTotals = (
              map: totalMap,
              num1: any,
              num2: any,
              num3: any,
              num4: any,
              num5: any,
              num6?: any
            ) => {
              map["1w"] += roundOffDecimal(num1);
              map["2w"] += roundOffDecimal(num2);
              map["3w"] += roundOffDecimal(num3);
              map["4w"] += roundOffDecimal(num4);
              map["5w"] += roundOffDecimal(num5);
              // 0week
              map["6w"] += roundOffDecimal(num6);
            };

            // main処理
            let totalCalMap1: any = { score: 0, count: 0 };
            let totalCalMap2: any = { score: 0, count: 0 };
            let totalCalMap3: any = { score: 0, count: 0 };
            let totalCalMap4: any = { score: 0, count: 0 };
            let totalCalMap5: any = { score: 0, count: 0 };
            let totalCalMap6: any = { score: 0, count: 0 };
            const countTotalMap = (score: any, totalmap: any) => {
              if (score >= 0) totalmap.count += 1;
              if (score > 0) totalmap.score += 1;
            };
            // 週名称の設定
            if (weekValue) {
              headerList.push(weekValue.week5 + "週");
              headerList.push(weekValue.week4 + "週");
              headerList.push(weekValue.week3 + "週");
              headerList.push(weekValue.week2 + "週");
              headerList.push(weekValue.week1 + "週");
              setTableHeaderList(headerList);
            }
            // 提出数用週
            if (submissionWeekValue && submissionWeekValue.length > 0) {
              let list: string[] = [];
              list.push(submissionWeekValue[0].week5);
              list.push(submissionWeekValue[0].week4);
              list.push(submissionWeekValue[0].week3);
              list.push(submissionWeekValue[0].week2);
              list.push(submissionWeekValue[0].week1);
              setTableSubmissionHeaderList(list);
            }
            weekList = sortCode(weekList);
            // 項目別の設定
            let scoreList: any[] = [];
            let totalAveragePercentMap: totalMap = initTotalMap();

            let week1PercentCount = 0;
            let week2PercentCount = 0;
            let week3PercentCount = 0;
            let week4PercentCount = 0;
            let week5PercentCount = 0;
            let week6PercentCount = 0;
            let week1Count = 0;
            let week2Count = 0;
            let week3Count = 0;
            let week4Count = 0;
            let week5Count = 0;
            let week6Count = 0;

            for (let j = 0; j < averageScoreBreakdown.length; j++) {
              let name = averageScoreBreakdown[j].name;
              let code = averageScoreBreakdown[j].code;
              let sortDoCode = averageScoreBreakdown[j].sort_docode;
              let sortOfcCode = averageScoreBreakdown[j].sort_ofc_code;
              let ofcName = averageScoreBreakdown[j].ofc_name;
              let result1 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result1
                  ? averageScoreBreakdown[j].result1
                  : "";
              let result2 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result2
                  ? averageScoreBreakdown[j].result2
                  : "";
              let result3 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result3
                  ? averageScoreBreakdown[j].result3
                  : "";
              let result4 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result4
                  ? averageScoreBreakdown[j].result4
                  : "";
              let result5 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result5
                  ? averageScoreBreakdown[j].result5
                  : "";
              let result6 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result6
                  ? averageScoreBreakdown[j].result6
                  : "";
              let submissionWeekValue: any =
                averageScoreBreakdown[j].submissionWeek;
              let map: any = { name, code, sortDoCode, sortOfcCode, ofcName };

              week1PercentCount += countTotalTarget(result1["recordCount1"]);
              week2PercentCount += countTotalTarget(result2["recordCount1"]);
              week3PercentCount += countTotalTarget(result3["recordCount1"]);
              week4PercentCount += countTotalTarget(result4["recordCount1"]);
              week5PercentCount += countTotalTarget(result5["recordCount1"]);
              week6PercentCount += countTotalTarget(result6["recordCount1"]);
              week1Count = result1["recordCount1"];
              week2Count = result2["recordCount1"];
              week3Count = result3["recordCount1"];
              week4Count = result4["recordCount1"];
              week5Count = result5["recordCount1"];
              week6Count = result6["recordCount1"];
              countTotalMap(result1["score1"], totalCalMap1);
              countTotalMap(result2["score1"], totalCalMap2);
              countTotalMap(result3["score1"], totalCalMap3);
              countTotalMap(result4["score1"], totalCalMap4);
              countTotalMap(result5["score1"], totalCalMap5);
              countTotalMap(result6["score1"], totalCalMap6);

              addTotals(
                totalAveragePercentMap,
                result1["score1"],
                result2["score1"],
                result3["score1"],
                result4["score1"],
                result5["score1"],
                result6["score1"]
              );

              let resultMap = {
                result1,
                result2,
                result3,
                result4,
                result5,
                result6,
              };
              scoreList.push(
                tabValue === 2
                  ? Object.assign(
                    dataMapCreate(map, headerList, 1, resultMap),
                    submissionWeekValue
                  )
              : tabValue === 3
                  ? Object.assign(
                    dataMapCreate(map, headerList, 1, resultMap),
                    createAttachedIconMap(resultMap)
                  )
                  : dataMapCreate(map, headerList, 1, resultMap)
              );
            }

            // 統合の設定
            let averageTitle = "";
            if (tabValue === 0) {
              averageTitle = "ZO";
            } else if (tabValue === 1) {
              averageTitle = "DO";
            } else if (tabValue === 2) {
              averageTitle = "OFC";
            } else if (tabValue === 3) {
              averageTitle = "個別";
            }
            // 地区平均の作成
            let averageMap: any = {
              name: "地区平均／" + averageTitle + "平均",
              code: "average",
            };
            // totalには5～1が逆に入っている
            let average1 = calcPercent(totalCalMap1.score, week1Count);
            let average2 = calcPercent(totalCalMap2.score, week2Count);
            let average3 = calcPercent(totalCalMap3.score, week3Count);
            let average4 = calcPercent(totalCalMap4.score, week4Count);
            let average5 = calcPercent(totalCalMap5.score, week5Count);
            let average6 = calcPercent(totalCalMap6.score, week6Count);

            if (percentFlg) {
              average1 = roundOffDecimal(
                Number(totalAveragePercentMap["1w"]) / week1PercentCount
              );
              average2 = roundOffDecimal(
                Number(totalAveragePercentMap["2w"]) / week2PercentCount
              );
              average3 = roundOffDecimal(
                Number(totalAveragePercentMap["3w"]) / week3PercentCount
              );
              average4 = roundOffDecimal(
                Number(totalAveragePercentMap["4w"]) / week4PercentCount
              );
              average5 = roundOffDecimal(
                Number(totalAveragePercentMap["5w"]) / week5PercentCount
              );
              average6 = roundOffDecimal(
                Number(totalAveragePercentMap["6w"]) / week6PercentCount
              );
              averageMap["1wDiff"] = calcDiff(average5, average6);
              averageMap["2wDiff"] = calcDiff(average4, average5);
              averageMap["3wDiff"] = calcDiff(average3, average4);
              averageMap["4wDiff"] = calcDiff(average2, average3);
              averageMap["5wDiff"] = calcDiff(average1, average2);
            }

            averageMap[headerList[0]] = average5;
            averageMap[headerList[1]] = average4;
            averageMap[headerList[2]] = average3;
            averageMap[headerList[3]] = average2;
            averageMap[headerList[4]] = average1;

            setTargetSmallAverageData(averageMap);
            setTargetSmallData(sortCode(scoreList));
            return {
              weekList,
              headerList,
            };
          }
        }
      })
      .finally(() => {
        setExecuteFlg("");
      });
  }

  // 小項目での基準日情報取得
  function getSmallBaseDate(baseDate: DateTime) {
    let workAnalysis = String(tabValue + 1);
    if (allDoFlg && workAnalysis === "4") {
      workAnalysis = "5";
    }
    const apiParams = {
      "api-name": "analysis",
      target_date: baseDate.toFormat("yyyy/MM/dd HH:mm:ss"),
      ofc_id: ofcId,
      zo_code: workZoCode,
      do_code: workDoCode,
      analysis: workAnalysis,
      big_item_id: String(Number(selectedTable) - 1),
      small_item_id: String(
        selectedTable === "9" && selectedSmallTitle !== "1"
          ? Number(selectedTemplateSmallTitle) - 1
          : Number(selectedSmallTitle) - 1
      ),
      is_oneweek: true,
    };
    setExecuteFlg("2");
    // スコアのパーセント表示フラグ
    let percentFlg = false;
    if (workAnalysis !== "4" && workAnalysis !== "5") {
      percentFlg = true;
    }
    const url = process.env.REACT_APP_GAE_API_URL + "tenpocheck/analysis-small";
    return postRequest(url, apiParams)
      .then((res) => {
        const breakDownDataMap = (
          data: any[],
          code: any,
          score: number,
          id: any
        ) => {
          let dataMap: any = {};
          for (let i = 0; i < data.length; i++) {
            if (code === data[i].code) {
              dataMap = data[i];
            }
          }
          dataMap["baseWeekScore"] = roundOffDecimal(score);
          dataMap["baseId"] = id;
          if (percentFlg) {
            return updateDiff(score, dataMap, tableHeaderList);
          }
          return dataMap;
        };

        const updateDiff = (
          baseScore: number,
          targetMap: any,
          tableHeaderList: any[]
        ) => {
          let dataMap: any = targetMap;

          dataMap["1wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[0]],
            baseScore
          );
          dataMap["2wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[1]],
            baseScore
          );
          dataMap["3wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[2]],
            baseScore
          );
          dataMap["4wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[3]],
            baseScore
          );
          dataMap["5wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[4]],
            baseScore
          );
          return dataMap;
        };

        // main処理
        let totalScore0 = 0;
        let totalCount0 = 0;
        let dataList: any[] = res.data.dataList;
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let datajsList: any = dataList[i];

            let averageScoreBreakdown: any[] =
              datajsList.average_score_breakdown;
            let weekList: any[] = [];
            weekList = sortCode(weekList);

            let breakDownList: any[] = [];
            let breakDownData = targetSmallData;
            let breakDownAverageData = targetSmallAverageData;
            let totalBaseScore = 0;
            let week1Count = 0;
            for (let j = 0; j < averageScoreBreakdown.length; j++) {
              let code = averageScoreBreakdown[j].code;
              let result1 =
                averageScoreBreakdown[j] && averageScoreBreakdown[j].result1
                  ? averageScoreBreakdown[j].result1
                  : "";
              let id = result1.id;
              totalCount0 = result1.recordCount1;
              if (result1["score1"] > 0) totalScore0 += 1;
              totalBaseScore += Number(result1.score1);
              week1Count += countTotalTarget(result1.recordCount1);
              const attachedIconMap: {
                photo_flag_base: string;
                comment_flag_base: string;
              } = {
                photo_flag_base: result1.photo_flag,
                comment_flag_base: result1.comment_flag,
              };
              breakDownList.push(
                Object.assign(
                  breakDownDataMap(breakDownData, code, result1.score1, id),
                  attachedIconMap
                )
              );
            }
            // totalには5～1が逆に入っている
            let average0 = calcPercent(totalScore0, totalCount0);

            if (percentFlg) {
              average0 = roundOffDecimal(Number(totalBaseScore) / week1Count);
              updateDiff(average0, breakDownAverageData, tableHeaderList);
            }
            breakDownAverageData["baseWeekScore"] = average0;
            setTargetSmallAverageData(breakDownAverageData);
            setTargetSmallData(sortCode(breakDownList));
          }
        }
      })
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
      })
      .finally(() => {
        setExecuteFlg("");
      });
  }

  // 基準週による比較値更新
  function updateBaseDate(baseDate: DateTime) {
    userLogging("店舗確認表", "管理者分析#基準日選択", baseDate.toString());
    let workAnalysis = String(tabValue + 1);
    if (allDoFlg && workAnalysis === "4") {
      workAnalysis = "5";
    }
    const apiParams = {
      "api-name": "analysis",
      target_date: baseDate.toFormat("yyyy/MM/dd HH:mm:ss"),
      zo_code: workZoCode,
      do_code: workDoCode,
      ofc_id: ofcId,
      tenpo_code: tenpoCode,
      analysis: workAnalysis,
      is_oneweek: true,
    };
    setExecuteFlg("2");
    const url = process.env.REACT_APP_GAE_API_URL + "tenpocheck/analysis";
    return postRequest(url, apiParams)
      .then((res) => {
        setSelectedTable("1");
        setSmallTitleList([initSmallTitle]);
        const breakDownDataMap = (data: any[], code: any, score: number) => {
          let dataMap: any = {};
          for (let i = 0; i < data.length; i++) {
            if (code === data[i].code) {
              dataMap = data[i];
            }
          }
          dataMap["baseWeekScore"] = roundOffDecimal(score);
          return updateDiff(score, dataMap, tableHeaderList);
        };

        const updateDiff = (
          baseScore: number,
          targetMap: any,
          tableHeaderList: any[]
        ) => {
          let dataMap: any = targetMap;

          dataMap["1wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[0]],
            baseScore
          );
          dataMap["2wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[1]],
            baseScore
          );
          dataMap["3wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[2]],
            baseScore
          );
          dataMap["4wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[3]],
            baseScore
          );
          dataMap["5wDiffBase"] = calcDiff(
            dataMap[tableHeaderList[4]],
            baseScore
          );
          return dataMap;
        };

        const getUpdateAverage = (
          data: any,
          totalScore: number | string,
          count: number
        ) => {
          let averageMap = data;
          averageMap["baseWeekScore"] = calcAverage(totalScore, count);
          updateDiff(
            calcAverage(totalScore, count),
            averageMap,
            tableHeaderList
          );
          return averageMap;
        };

        // main処理
        let dataList: any[] = res.data.dataList;
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let datajsList: any = dataList[i];
            let breakdownScoreList: any[] = datajsList.average_score_breakdown;

            let week1Count = 0;
            let breakDownList_0: any[] = [];
            let breakDownList_1: any[] = [];
            let breakDownList_2: any[] = [];
            let breakDownList_3: any[] = [];
            let breakDownList_4: any[] = [];
            let breakDownList_5: any[] = [];
            let breakDownList_6: any[] = [];
            let breakDownList_7: any[] = [];
            let breakDownList_8: any[] = [];
            let breakDownData_0 = targetData0;
            let breakDownData_1 = targetData1;
            let breakDownData_2 = targetData2;
            let breakDownData_3 = targetData3;
            let breakDownData_4 = targetData4;
            let breakDownData_5 = targetData5;
            let breakDownData_6 = targetData6;
            let breakDownData_7 = targetData7;
            let breakDownData_8 = targetData8;
            let totalScore0 = 0;
            let totalScore1 = 0;
            let totalScore2 = 0;
            let totalScore3 = 0;
            let totalScore4 = 0;
            let totalScore5 = 0;
            let totalScore6 = 0;
            let totalScore7 = 0;
            let totalScore8 = 0;

            for (let j = 0; j < breakdownScoreList.length; j++) {
              let code = breakdownScoreList[j].codeName.code;
              let result1 =
                breakdownScoreList[j] && breakdownScoreList[j].result1
                  ? breakdownScoreList[j].result1
                  : "";

              week1Count += countTotalTarget(result1.recordCount1);
              totalScore0 += Number(result1.score0);
              totalScore1 += Number(result1.score1);
              totalScore2 += Number(result1.score2);
              totalScore3 += Number(result1.score3);
              totalScore4 += Number(result1.score4);
              totalScore5 += Number(result1.score5);
              totalScore6 += Number(result1.score6);
              totalScore7 += Number(result1.score7);
              totalScore8 += Number(result1.score8);
              breakDownList_0.push(
                breakDownDataMap(breakDownData_0, code, result1.score0)
              );
              breakDownList_1.push(
                breakDownDataMap(breakDownData_1, code, result1.score1)
              );
              breakDownList_2.push(
                breakDownDataMap(breakDownData_2, code, result1.score2)
              );
              breakDownList_3.push(
                breakDownDataMap(breakDownData_3, code, result1.score3)
              );
              breakDownList_4.push(
                breakDownDataMap(breakDownData_4, code, result1.score4)
              );
              breakDownList_5.push(
                breakDownDataMap(breakDownData_5, code, result1.score5)
              );
              breakDownList_6.push(
                breakDownDataMap(breakDownData_6, code, result1.score6)
              );
              breakDownList_7.push(
                breakDownDataMap(breakDownData_7, code, result1.score7)
              );
              breakDownList_8.push(
                breakDownDataMap(breakDownData_8, code, result1.score8)
              );
            }

            let averageMap = getUpdateAverage(
              targetAverageData0,
              totalScore0,
              week1Count
            );
            setTargetAverageData(averageMap);
            setTargetAverageData0(averageMap);
            setTargetAverageData1(
              getUpdateAverage(targetAverageData1, totalScore1, week1Count)
            );
            setTargetAverageData2(
              getUpdateAverage(targetAverageData2, totalScore2, week1Count)
            );
            setTargetAverageData3(
              getUpdateAverage(targetAverageData3, totalScore3, week1Count)
            );
            setTargetAverageData4(
              getUpdateAverage(targetAverageData4, totalScore4, week1Count)
            );
            setTargetAverageData5(
              getUpdateAverage(targetAverageData5, totalScore5, week1Count)
            );
            setTargetAverageData6(
              getUpdateAverage(targetAverageData6, totalScore6, week1Count)
            );
            setTargetAverageData7(
              getUpdateAverage(targetAverageData7, totalScore7, week1Count)
            );
            setTargetAverageData8(
              getUpdateAverage(targetAverageData8, totalScore8, week1Count)
            );

            setTargetData(sortCode(breakDownList_0));
            setTargetData0(sortCode(breakDownList_0));
            setTargetData1(sortCode(breakDownList_1));
            setTargetData2(sortCode(breakDownList_2));
            setTargetData3(sortCode(breakDownList_3));
            setTargetData4(sortCode(breakDownList_4));
            setTargetData5(sortCode(breakDownList_5));
            setTargetData6(sortCode(breakDownList_6));
            setTargetData7(sortCode(breakDownList_7));
            setTargetData8(sortCode(breakDownList_8));
          }
        }
      })
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
      })
      .finally(() => {
        setExecuteFlg("");
      });
  }

  // タブ内表示
  function tableResult(num: number) {
    return (
      <>
        <Grid style={{ width: "85vw" }}>
          <Grid
            container
            style={{
              padding: "10px",
            }}
          >
            <Grid item style={{ paddingRight: "15px" }}>
              <Select
                style={{
                  minWidth: 320,
                  maxWidth: 320,
                }}
                onChange={(event) => doSelChange(event)}
                value={selectedTable}
              >
                {tableList.map((rowItem) => (
                  <MenuItem value={rowItem.id}>{rowItem.name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item style={{ paddingRight: "15px" }}>
              <Select
                style={{
                  minWidth: tabValue !== 2 ? 320 : 300,
                  maxWidth: tabValue !== 2 ? 320 : 300,
                }}
                onChange={(event) => doSmallSelChange(event)}
                value={selectedSmallTitle}
              >
                {smallTitleList.map((rowItem) => (
                  <MenuItem value={rowItem.id}>{rowItem.name}</MenuItem>
                ))}
              </Select>
            </Grid>

            {tabValue === 3 &&
            selectedTable === "9" &&
            selectedSmallTitle !== "1" ? (
              <Grid item style={{ paddingRight: "15px" }}>
                <Select
                  style={{
                    minWidth: 320,
                    maxWidth: 320,
                  }}
                  onChange={(event) => doTemplateSmallSelChange(event)}
                  value={selectedTemplateSmallTitle}
                >
                  {templateSmallItemList.map((rowItem) => (
                    <MenuItem value={rowItem.id}>{rowItem.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : (
              ""
            )}

            <Grid item>
              <Grid container>
                <Typography>基準日：</Typography>
                <DatePicker
                  selectedDate={
                    !selectedSmallFlg ? selectedBaseDate : selectedSmallBaseDate
                  }
                  width={180}
                  dateFunc={(date: DateTime | null) => {
                    handleBaseDate(date);
                  }}
                  format={"yyyy年MM月dd日"}
                  minDate={DateTime.fromFormat("20000101", "yyyyMMdd")}
                  mainColor={"#4dc0b2"}
                />
              </Grid>
            </Grid>
            {tabValue === 0 &&
            (isDownloadPointData(user) ||
              process.env.REACT_APP_MODE === "develop") ? (
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                style={{ margin: "3px" }}
              >
                <TenpoCheckStoreScoreDataButton />
              </Grid>
            ) : (
              ""
            )}

            {tabValue === 2 ? (
              <>
                <Grid item style={{ paddingRight: "10px" }}>
                  <Button
                    variant="contained"
                    color={"primary"}
                    onClick={() => moveClickDMAll()}
                  >
                    DO内個店別分析
                  </Button>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
          {targetData && targetData.length > 0 ? (
            <>
              <TenpoCheckManagerAnalysisTable
                data={targetData}
                averageData={targetAverageData}
                headerName={headerName}
                tableHeaderList={tableHeaderList}
                tableSubmissionHeaderList={tableSubmissionHeaderList}
                tabValue={tabValue}
                graphUserList={graphUserList}
                baseWeek={!selectedSmallFlg ? baseWeek : smallBaseWeek}
                onClickMove={(code) => moveClick(code)}
                onClickSelect={(code, name) => selectClick(code, name)}
                smallFlg={selectedSmallFlg}
                smallData={targetSmallData}
                smallAverageData={targetSmallAverageData}
                bigItemId={String(Number(selectedTable) - 1)}
                smallItemId={String(
                  selectedTable === "9" && selectedSmallTitle !== "1"
                    ? Number(selectedTemplateSmallTitle)
                    : Number(selectedSmallTitle) - 1
                )}
                selectedTable={selectedTable}
              />
              {!selectedSmallFlg ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={classes.graphTable}
                >
                  <ResponsiveContainer width={"100%"} height={400}>
                    <LineChart
                      data={targetGraphData}
                      margin={{ top: 5, right: 40, bottom: 60, left: 0 }}
                      stackOffset="none"
                      height={400}
                    >
                      <XAxis dataKey="week" interval={0} />
                      <YAxis domain={[0, 100]} />
                      <Tooltip />
                      <Legend verticalAlign="top" />
                      <ReferenceLine y={0} stroke="#000" />
                      {graphUserList.map((item: any) => (
                        <Line dataKey={item.name} stroke={item.fill} />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </Grid>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  }

  return (
    <Grid>
      {executeFlg === "2" ? ( // 検索中のぐるぐる表示
        <>
          <CircularProgress className={classes.circularProgress} size={120} />
          <Grid className={classes.overLayer}></Grid>
        </>
      ) : (
        ""
      )}
      <AppBar
        position="static"
        style={{
          padding: "0",
          margin: "auto",
          width: "90vw",
          outlineColor: "#3e3e3e",
          backgroundColor: "#f2f2f2",
          color: "#3e3e3e",
        }}
      >
        <Tabs
          variant="standard"
          value={indexTabValue}
          onChange={handleChange}
          aria-label="nav tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#37ab9d",
              height: "10px",
            },
          }}
        >
          {user.jobCategory === "admin-group" ? (
            <Tab
              label="ZO別分析"
              disabled={executeFlg === "1" || zoTabDisableFlg}
            />
          ) : (
            ""
          )}
          {user.jobCategory === "admin-group" || user.jobCategory === "zm" ? (
            <Tab
              label="DO別分析"
              disabled={executeFlg === "1" || doTabDisableFlg}
            />
          ) : (
            ""
          )}
          <Tab
            label="OFC別分析"
            disabled={executeFlg === "1" || ofcTabDisableFlg}
          />
          <Tab
            label="個店別分析"
            disabled={executeFlg === "1" || kobetuTabDisableFlg}
          />
        </Tabs>
      </AppBar>
      {executeFlg === "1" ? ( // 検索中のぐるぐる表示
        <>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CircularProgress
              size={"40vh"}
              style={{
                color: "#ccc",
                top: "30vh",
                position: "absolute",
              }}
            />
          </Grid>
        </>
      ) : (
        <>
          {user.jobCategory === "admin-group" ? (
            <TabPanel value={indexTabValue} index={0}>
              <Grid className={classes.root}>{tableResult(0)}</Grid>
            </TabPanel>
          ) : (
            ""
          )}
          {user.jobCategory === "admin-group" || user.jobCategory === "zm" ? (
            <TabPanel value={tabValue} index={1}>
              <Grid className={classes.root}>{tableResult(1)}</Grid>
            </TabPanel>
          ) : (
            ""
          )}
          <TabPanel value={tabValue} index={2}>
            <Grid className={classes.root}>{tableResult(2)}</Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Grid className={classes.root}>{tableResult(3)}</Grid>
          </TabPanel>
        </>
      )}
      {/* ダイアログ */}
      <TenpoCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </Grid>
  );
};
export default AnalysisResult;
