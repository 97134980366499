import { DateTime } from "luxon";
import { postRequest } from "../../../apis/axiosAction";
import { db } from "../../../firebase";

const common_api_url: string = process.env.REACT_APP_COMMON_API!;

// レイヤ初期化
// 選択している店舗・モバ空メッシュから最も近いアメダスの天気情報を取得
export const getWeatherInfo = async (
  order: string,
  selectedItem: any,
  selectedDate: string[],
  graphType: string,
  reserveItem?: any
) => {
  let latLng;
  const apiName = "tenki-jisseki";
  const yesterday = [DateTime.local().minus({ day: 1 }).toFormat("yyyyMMdd")];
  // const selectedDate = order === "first" ? firstDate : secondDate;
  await getLatLng(order, graphType, selectedItem, reserveItem).then((value) => {
    latLng = value;
  });
  // カレンダー用の天気情報（400日分）用
  const paramOneYear = {
    "api-name": apiName,
    target_date: yesterday,
    lat_lng: latLng,
    is_year: true,
  };
  // グラフ用の天気情報（選択した日付のみ）用
  const paramSelectedDay = {
    "api-name": apiName,
    target_date: selectedDate,
    lat_lng: latLng,
    is_year: false,
  };
  const [resOneYear, resSelectedDay] = await Promise.all([
    postRequest(common_api_url, paramOneYear),
    postRequest(common_api_url, paramSelectedDay),
  ]);
  const oneYearWeather = resOneYear.data.dataList
    ? JSON.parse(resOneYear.data.dataList[0]).tenki_jisseki_list
    : [];
  const selectedDayWeather = resSelectedDay.data.dataList
    ? JSON.parse(resSelectedDay.data.dataList[0]).tenki_jisseki_list[0]
    : {};

  const weatherInfo = {
    oneYearWeather: oneYearWeather,
    selectedDayWeather: selectedDayWeather,
  };

  return weatherInfo;
  // if (order === "first") {
  //   setFirstOneYearWeather(oneYearWeather);
  //   setFirstDayWeather(selectedDayWeather);
  // } else if (order === "second") {
  //   setSecondOneYearWeather(oneYearWeather);
  //   setSecondDayWeather(selectedDayWeather);
  // }
};

// 選択している店舗・モバ空の緯度経度をfirestoreから取得
const getLatLng = async (
  order: string,
  graphType: string,
  selectedItem: any,
  reserveItem?: any
) => {
  let latLng;
  if (graphType === "cvr") {
    if (order === "first") {
      latLng = await getTenpoLatLng("existing_sej_stores", selectedItem);
    } else {
      if (selectedItem[0] === "market-area") {
        // ★★★任意商圏のときは分析対象の店舗の天気を参照
        latLng = await getTenpoLatLng("existing_sej_stores", reserveItem);
      } else if (selectedItem.length !== 0) {
        await getMobakuLatLng("mesh_500m", selectedItem).then((docs) => {
          latLng = docs;
        });
      }
    }
  } else if (graphType === "mobaku") {
    await getMobakuLatLng("mesh_500m", selectedItem).then((docs) => {
      latLng = docs;
    });
  } else if (graphType === "tenpo") {
    latLng = await getTenpoLatLng("existing_sej_stores", selectedItem);
  }
  return latLng;
};

// 選択している店舗の緯度経度を取得
const getTenpoLatLng = async (collection: string, itemCode: string) => {
  const docRef = await db.collection(collection).doc(itemCode).get();
  const docs = docRef.data();
  const tempObj = {
    lat: docs?.geography.geopoint._lat,
    lng: docs?.geography.geopoint._long,
  };
  const latLng = [tempObj];
  return latLng;
};

// 選択しているモバ空メッシュの緯度経度を取得
const getMobakuLatLng = async (collection: string, itemCode: Array<string>) => {
  const latLng: any[] = [];
  let tempObj;
  await db
    .collection(collection)
    .where("attribution.key_code", "in", itemCode)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        tempObj = {
          lat: doc.data().geography.geopoint._lat,
          lng: doc.data().geography.geopoint._long,
        };
        latLng.push(tempObj);
      });
    });
  return latLng;
};
