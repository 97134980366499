interface dataIf {
  date: string;
  firstTenpoRealNumForGraph?: number;
  firstTenpoRealNumForTable?: string;
  firstTenpoRealNumLastYearForGraph?: number;
  firstTenpoRealNumLastYearForTable?: string;
  firstDiffLastYear?: number;
  firstTenpoRatioLastYear?: number;
  secondTenpoRealNumForGraph?: number;
  secondTenpoRealNumForTable?: string;
  secondTenpoRealNumLastYearForGraph?: number;
  secondTenpoRealNumLastYearForTable?: string;
  secondDiffLastYear?: number;
  secondTenpoRatioLastYear?: number;
  firstMobakuRealNumForGraph?: number;
  firstMobakuRealNumForTable?: string;
  firstMobakuRealNumLastYearForGraph?: number;
  firstMobakuRealNumLastYearForTable?: string;
  firstMobakuRatioLastYear?: number;
  secondMobakuRealNumForGraph?: number;
  secondMobakuRealNumForTable?: string;
  secondMobakuRealNumLastYearForGraph?: number;
  secondMobakuRealNumLastYearForTable?: string;
  secondMobakuRatioLastYear?: number;
  diffForGraph?: number;
  diffForTable?: string;
  ratioDiff?: number;
  conversionRateForGraph?: number;
  conversionRateForTable?: string;
  conversionRateLastYearForGraph?: number;
  conversionRateLastYearForTable?: string;
}

// グラフまたは表コンポーネントに渡すデータセットのフォーマットを作成
export const initData = (index: number) => {
  const tempArr: any[] = [];
  for (let i = 0; i < index; i++) {
    let tempObj: dataIf = { date: "" };
    tempObj.firstTenpoRealNumForGraph = 0;
    tempObj.firstTenpoRealNumForTable = "";
    tempObj.firstTenpoRealNumLastYearForGraph = 0;
    tempObj.firstTenpoRealNumLastYearForTable = "";
    tempObj.firstDiffLastYear = 0;
    tempObj.firstTenpoRatioLastYear = 0;
    tempObj.secondTenpoRealNumForGraph = 0;
    tempObj.secondTenpoRealNumForTable = "";
    tempObj.secondTenpoRealNumLastYearForGraph = 0;
    tempObj.secondTenpoRealNumLastYearForTable = "";
    tempObj.secondDiffLastYear = 0;
    tempObj.secondTenpoRatioLastYear = 0;
    tempObj.firstMobakuRealNumForGraph = 0;
    tempObj.firstMobakuRealNumForTable = "";
    tempObj.firstMobakuRealNumLastYearForGraph = 0;
    tempObj.firstMobakuRealNumLastYearForTable = "";
    tempObj.firstMobakuRatioLastYear = 0;
    tempObj.secondMobakuRealNumForGraph = 0;
    tempObj.secondMobakuRealNumForTable = "";
    tempObj.secondMobakuRealNumLastYearForGraph = 0;
    tempObj.secondMobakuRealNumLastYearForTable = "";
    tempObj.secondMobakuRatioLastYear = 0;
    tempObj.diffForGraph = 0;
    tempObj.diffForTable = "";
    tempObj.ratioDiff = 0;
    tempObj.conversionRateForGraph = 0;
    tempObj.conversionRateForTable = "";
    tempObj.conversionRateLastYearForGraph = 0;
    tempObj.conversionRateLastYearForTable = "";
    tempArr.push(tempObj);
  }
  return tempArr;
};
