import React, { useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Modal,
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  IconButton,
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

import { uploadPhotoGoogleCloudStorage } from "../../../apis/photoAction";
import { updateTenpoCheckPhoroUrl } from "../../../features/tenpoStatusSlice";
import { selectImageToken } from "../../../features/userSlice";
import { getFixedFlg } from "../../Header/Header";
import TSCheckDialog from "../TSCheckDialog";
import { userLogging } from "../../../apis/userLog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadGrid: {
      height: "9vh",
    },
    noUpload: {
      color: "#f2f2f2",
    },
    uploaded: {
      color: "#37ab9d",
    },
    modalPane: {
      position: "absolute",
      paddingTop: "2%",
      paddingLeft: "5%",
      width: "84%",
      height: "80%",
    },
    paper: {
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    titleGrid: {
      height: "7vh",
      color: "white",
      backgroundColor: "#4dc0b2",
    },
    titleTypography: {
      fontWeight: "bold",
    },
    textCommentArea: {
      width: "220px",
      // iPad縦
      "@media (min-width: 830px)": {
        width: "200px",
      },
      // iPad横
      "@media (min-width: 1100px)": {
        width: "200px",
      },
      // GWPC
      "@media (min-width: 1200px)": {
        width: "220px",
      },
    },
  })
);

// 文字列のサイズ幅算出（半角文字0.5,全角文字1として計算）
function countText(comment: any) {
  let count = 0;
  for (let i = 0; i < comment.length; i++) {
    let c = comment.charCodeAt(i);
    if (
      (c >= 0x0 && c < 0x81) ||
      c === 0xf8f0 ||
      (c >= 0xff61 && c < 0xffa0) ||
      (c >= 0xf8f1 && c < 0xf8f4)
    ) {
      count += 0.5;
    } else {
      count += 1;
    }
  }
  return count;
}

const TSCheckModalButton: FC<{
  title: string;
  comment?: string;
  photoFilePath: string[];

  onClickSave?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    comment: string,
    photoFilePath: string[]
  ) => void;
  tenpoCode?: string;
}> = ({ title, comment, photoFilePath, onClickSave, tenpoCode }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalComment, setModalComment] = React.useState(comment);
  const [initComment, setInitComment] = React.useState(comment);
  const [initFilePath, setInitFilePath] = React.useState(photoFilePath);
  const [filePath, setFilePath] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  const dialogClose = () => {
    setDialogFlg(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setFilePath(photoFilePath);
  }, [photoFilePath]);

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (getFixedFlg()) {
      setOpen(false);
      return;
    }
    let loComment = modalComment || "";
    const loFilePath = filePath || [];

    setFilePath(loFilePath);
    setInitFilePath(loFilePath);
    setInitComment(loComment);

    if (onClickSave) {
      onClickSave(e, loComment, loFilePath);
    }
    setOpen(false);
  };
  const handleClose = () => {
    if (loading) {
      return;
    }
    setModalComment(initComment);
    setFilePath(initFilePath);
    setOpen(false);
  };
  const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (getFixedFlg()) return;
    let comment = event.target.value as string;
    if (comment && Number(comment.length) > 2000) {
      comment = comment.substring(0, 2000);
    }
    setModalComment(comment);
  };
  const processUploadPhoto = async (e: any) => {
    const fileName = tenpoCode + "-" + uuidv4();
    const result = await uploadPhotoGoogleCloudStorage(
      e,
      "tenpo-check-photo/",
      fileName
    );
    if (!result) {
      if (result === "") {
        setDialogMessage(["画像のアップロードに失敗しました"]);
        setDialogFlg(true);
      }
    } else {
      setFilePath([String("tenpo-check-photo/" + fileName)]);
    }
    return [result, fileName];
  };

  const handleUploadPhoto = (e: any) => {
    setLoading(true);
    if (getFixedFlg()) return;
    processUploadPhoto(e).then((res) => {
      dispatch(
        updateTenpoCheckPhoroUrl({
          tenpoCheckPhoroUrl: res[0],
        })
      );
      setLoading(false);
    });
  };
  const imageToken = useSelector(selectImageToken);
  const loadingGif = `${window.location.origin}/image/icon_loader.gif`;
  function getImageFile(imagePath: string) {
    if (imagePath === undefined || imagePath === "") {
      return `${window.location.origin}/image/noimage.png`;
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      imagePath +
      "?access_token=" +
      imageToken
    );
  }
  function isUploaded(argFilePath: string, argComment: any): any {
    if (
      (argFilePath && argFilePath !== "") ||
      (argComment && argComment !== "")
    ) {
      return classes.uploaded;
    }
    return classes.noUpload;
  }

  function deleteFile(filePath: any) {
    userLogging("店舗衛生点検表", "チェック#写真削除", filePath);
    setFilePath([]);
  }

  return (
    <>
      {/*  カメラボタンここから↓*/}
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.uploadGrid}
      >
        <IconButton
          component="span"
          onClick={handleOpen}
          className={isUploaded(filePath[0], modalComment)}
        >
          <PhotoCamera fontSize="large" />
        </IconButton>
      </Grid>
      {/*  カメラボタンここまで↑*/}
      {/*  モーダルここから↓*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalPane}
      >
        <Grid container className={classes.paper} style={{ overflowY: "auto" }}>
          {/*  タイトルここから↓*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.titleGrid}
          >
            <Typography
              variant="h6"
              className={classes.titleTypography}
              style={countText(title) > 50 ? { fontSize: "0.80em" } : {}}
            >
              {title}
            </Typography>
          </Grid>
          {/*  タイトルここまで↑*/}
          <Grid
            container
            style={{
              marginBottom: "5px",
              display: "flex",
              alignSelf: "flex-start",
            }}
          >
            {/*  写真をアップロードここから↓*/}
            <Grid item lg={3} md={4} sm={4}>
              <Grid container justify="center" style={{ paddingTop: "10px" }}>
                {loading ? (
                  <img
                    alt=""
                    src={loadingGif}
                    style={{
                      paddingTop: "5px",
                      position: "absolute",
                    }}
                  />
                ) : (
                  ""
                )}
                <img
                  alt=""
                  src={getImageFile(filePath[0])}
                  height="200"
                  style={{
                    width: "200px",
                    WebkitAppearance: "none",
                  }}
                />
              </Grid>
              <Grid container justify="center" style={{ paddingTop: "10px" }}>
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo-name"
                    type="file"
                    onChange={(e) => {
                      handleUploadPhoto(e);
                      e.target.value = "";
                    }}
                    disabled={getFixedFlg()}
                  />
                  {getFixedFlg() ? (
                    ""
                  ) : (
                    <Button variant="contained" component="span">
                      写真をアップロード
                    </Button>
                  )}
                </label>

                {!getFixedFlg() && filePath[0] ? (
                  <IconButton
                    onClick={(e) => {
                      deleteFile(filePath[0]);
                    }}
                    size={"small"}
                    style={{ marginLeft: "8px" }}
                  >
                    <Delete fontSize={"default"} />
                  </IconButton>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {/*  写真をアップロードここまで↑*/}
            {/*  その他コメントここから↓*/}
            <Grid item lg={9} md={8} sm={8} style={{ height: "45vh" }}>
              <Grid container justify="center">
                <Grid item>
                  <TextareaAutosize
                    className={classes.textCommentArea}
                    rowsMax={16}
                    aria-label="store-input-comment-area"
                    rowsMin={16}
                    maxLength={4000}
                    placeholder={
                      getFixedFlg()
                        ? "その他コメント"
                        : "その他コメントがあればご入力ください"
                    }
                    value={modalComment}
                    onChange={(e) => {
                      changeComment(e);
                    }}
                    style={{
                      height: "190px",
                      fontSize: "16px",
                      borderRadius: "8px",
                      margin: "10px",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>※印刷時</Typography>
                  <Typography
                    style={countText(modalComment) > 30 ? { color: "red" } : {}}
                  >
                    1頁目の表示は全角30文字で切れます
                  </Typography>
                  <Typography>2～3頁目の表示は全角144文字で切れます</Typography>
                </Grid>
              </Grid>
            </Grid>
            {/*  その他コメントここまで↑*/}
          </Grid>
          {/*  ボタン↓ここから*/}
          <Grid container justify="center">
            <Grid item xs={6}>
              <Grid container justify="center">
                <Button
                  style={{ width: "30vw" }}
                  variant="contained"
                  fullWidth={true}
                  onClick={handleClose}
                  disabled={loading}
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="center">
                {getFixedFlg() ? (
                  ""
                ) : (
                  <Button
                    style={{ width: "30vw" }}
                    variant="contained"
                    fullWidth={true}
                    onClick={(e) => handleSave(e)}
                    color="primary"
                    disabled={loading}
                  >
                    保存
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/*  ボタン↑ここまで*/}
          {/* ダイアログ */}
          <TSCheckDialog
            msg={dialogMessage}
            isOpen={dialogFlg}
            doOK={dialogClose}
            doNo={dialogClose}
            isAlert={true}
          />
        </Grid>
      </Modal>
      {/*  モーダル↑ここまで*/}
    </>
  );
};
export default TSCheckModalButton;
