/**
 * 地図上の項目を管理するコントローラーです。
 * 下記の地図上項目を管理します
 * 「ピン・メモ」、「既存店」
 * @class MapItems.js
 * @constructor
 */

import { getGMapsCenterPoint, getGoogleMapsZoom } from "./GoogleMaps";
import {
  unvisibleMapsStyleSettings,
  conflictStoreLayerMapsStyleSettings,
} from "./MapStylesSettings";
import { callMapItemInnerData } from "./operation/mapInnerDataOperation";
import {
  initExistingSejStores,
  existingSejStoreInfoWindow,
  existingSejStoreLayer,
  existingSejStoreData,
  existingSejStoreLayerMapsStyleSettings,
} from "./layers/existingSejStoreLayer";
import {
  initConflictStores,
  conflictStoreInfoWindow,
  conflictStoreLayer,
  conflictStoreData,
} from "./layers/conflictStoreLayer";
import { distributionDisplayMarketArea } from "../OpView/MarketArea/MarketAreaControl";

// import {
//   initMarketArea,
//   marketAreaLayerInfoWindow,
//   marketAreaLayer,
//   marketAreaData,
//   marketAreaLayerMapsStyleSettings,
// } from "./layers/marketAreaLayer";

// import { getGeoFireStore } from "../../apis/geoFireXAction";
// import { point2distance } from "../../apis/turfAction";
// import { auth } from "../../firebase";

// let isMarketAreaLayerDisplay: boolean = false;

// let existingSejStoreInfoWindow = new Map(),
//   subscriptionExistingSejStore: any = [];
// let conflictStoreInfoWindow = new Map(),
//   subscriptionConflictStore: any = [];
// export let marketAreaLayer: google.maps.Data;
// let marketAreaLayerInfoWindow = new Map(),
//   subscriptionMarketArea: any = [],
//   isMarketAreaLayerDisplay: boolean = false;
// let lastQueriedLatLng: any = null; // クエリ結果の初期値
// let dividedCitiesArraies: any = ["ALL"];

// TEMP
// let existingSejStoreData: any,
//   conflictStoreData: any,
//   marketAreaData: any,
//   marketAreaLayerConditions = ["NONE"];

// レイヤ初期化
export const initGoogleMapsLayers = (dispatch: any): void => {
  initExistingSejStores(dispatch);
  initConflictStores();
  callMapItemInnerData(getGMapsCenterPoint());
};

// 地図操作
export const callMapItemMove = (isForceUpdate: boolean = false) => {
  // callMapItemGeoFireStore(getGMapsCenterPoint(), getGoogleMapsZoom(), isForceUpdate);
  if (!isForceUpdate) {
    distributionDisplayMarketArea(false);
  }
  callMapItemInnerData(
    getGMapsCenterPoint(),
    getGoogleMapsZoom(),
    isForceUpdate
  );
};
// export const updateMapItemGeoFireStore = () => {
//   callMapItemGeoFireStore(getGMapsCenterPoint());
// };

// // Firestoreの呼出
// export const callMapItemGeoFireStore = (latLng: any, zoomLevel = 22) => {
//   let searchLength = 3;
//   const searchDistance = 1,
//     largeAreaZoomLevel = 15;
//   if (
//     lastQueriedLatLng !== null &&
//     point2distance(latLng, lastQueriedLatLng) / 1000 < searchDistance
//   ) {
//     return;
//   }
//   if (largeAreaZoomLevel > zoomLevel) {
//     searchLength = 4.5;
//   }
//   dividedCitiesArraies.forEach((conditions: any, index: number) => {
//     subscriptionExistingSejStore[index] = getGeoFireStore(
//       getLayerValue(existingSejStoreLayer, "fsDocumentName"),
//       latLng.lat(),
//       latLng.lng(),
//       searchLength,
//       existingSejStoreLayer,
//       subscriptionExistingSejStore[index],
//       cbFuncExistingSejStores,
//       true,
//       conditions
//     );
//     subscriptionConflictStore[index] = getGeoFireStore(
//       getLayerValue(conflictStoreLayer, "fsDocumentName"),
//       latLng.lat(),
//       latLng.lng(),
//       searchLength,
//       conflictStoreLayer,
//       subscriptionConflictStore[index],
//       cbFuncConflictStores,
//       true,
//       conditions
//     );
//     subscriptionMarketArea[index] = getGeoFireStore(
//       getLayerValue(marketAreaLayer, "fsDocumentName"),
//       latLng.lat(),
//       latLng.lng(),
//       searchLength,
//       marketAreaLayer,
//       subscriptionMarketArea[index],
//       cbFuncMarketArea,
//       false,
//       marketAreaLayerConditions
//     );

//     // subscriptionPinMemoLayer[index] = getGeoFireStore(
//     //   getLayerValue(pinMemoLayer, "fsDocumentName"),
//     //   latLng.lat(),
//     //   latLng.lng(),
//     //   searchLength,
//     //   pinMemoLayer,
//     //   subscriptionPinMemoLayer[index],
//     //   cbFuncPinMemo,
//     //   false,
//     //   conditions
//     // );
//   });
//   lastQueriedLatLng = latLng;
// };

// 地図上の項目の表示管理(非表示時→表示化、表示時→非表示化)
export const manageDisplayLayerFromName = (
  layerValueName: string,
  flag: boolean
): void => {
  // isMarketAreaLayerDisplay = flag;
  manageDisplayLayer(getLayerValue(null, "layerValue", layerValueName), flag);
};

// 地図上の項目の表示管理(非表示時→表示化、表示時→非表示化)
export const manageDisplayLayer = (layerValue: any, flg: boolean): void => {
  if (!flg) {
    // 既に表示されている場合非表示化する
    layerValue.setStyle(unvisibleMapsStyleSettings);
    // 表示されているInfoWindowを消す
    let infoWindow = getLayerValue(layerValue, "infoWindow");
    infoWindow.forEach(function (infoWindow: any) {
      infoWindow.close();
    });
    infoWindow.clear();
  } else {
    // 表示を行う場合既存項目を初期化した上で再表示する
    layerValue.forEach(function (feature: any) {
      layerValue.remove(feature);
    });
    layerValue.addGeoJson(getLayerValue(layerValue, "geojson"));
    layerValue.setStyle(getLayerValue(layerValue, "mapStyle"));
  }
  callMapItemMove(true);
};

// const cbFuncExistingSejStores = (
//   geojson: any,
//   updateLayer: any,
//   conditions: any
// ) => {
//   existingSejStoreData = geojson;
//   // setExistingSejStore(
//   //   replaceNewGeoJson(getExistingSejStore(), geojson, conditions)
//   // );
//   updateFireStoreLayer(updateLayer);
// };

// const cbFuncConflictStores = (
//   geojson: any,
//   updateLayer: any,
//   conditions: any
// ) => {
//   conflictStoreData = geojson;
//   // setExistingSejStore(
//   //   replaceNewGeoJson(getExistingSejStore(), geojson, conditions)
//   // );
//   updateFireStoreLayer(updateLayer);
// };

// const cbFuncMarketArea = (geojson: any, updateLayer: any, conditions: any) => {
//   marketAreaData = geojson;
//   // initConflictStores();
//   // setExistingSejStore(
//   //   replaceNewGeoJson(getExistingSejStore(), geojson, conditions)
//   // );
//   updateFireStoreLayer(updateLayer);
// };

// FireStoreの地図上の項目の表示アップデート(非表示時→表示化、表示時→非表示化)
export function updateFireStoreLayer(layerValue: any) {
  // 押下確認はボタンのステータスを参照する
  // if (getLayerValue(layerValue, "isPushedLayerButton")) {
  // 表示を行う場合既存項目を初期化した上で更新する
  // 暫定:商圏分析除外時(既存店/競合店の表示制御がないため)
  // if (
  //   layerValue === marketAreaLayer &&
  //   !getLayerValue(layerValue, "isPushedLayerButton")
  // ) {
  //   return;
  // }
  if (layerValue === undefined) return;
  layerValue.forEach(function (feature: any) {
    layerValue.remove(feature);
  });
  layerValue.addGeoJson(getLayerValue(layerValue, "geojson"));
  layerValue.setStyle(getLayerValue(layerValue, "mapStyle"));
}

// 既存店舗初期処理
// export const initExistingSejStores = (): void => {
//   existingSejStoreLayer = new google.maps.Data({
//     map: getGoogleMapsObject(),
//     style: {
//       visible: false,
//     },
//   });
//   // クリックイベントの登録
//   existingSejStoreLayer.addListener("click", function (event: any) {
//     if (!existingSejStoreInfoWindow.has(event.feature)) {
//       existingSejStoreInfoWindow.set(
//         event.feature,
//         new google.maps.InfoWindow({
//           // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
//           maxWidth: 300,
//         })
//       );
//       // 表示位置
//       existingSejStoreInfoWindow.get(event.feature).setPosition(event.latLng);
//       // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
//       existingSejStoreInfoWindow
//         .get(event.feature)
//         .setContent(
//           applyHtmlTemplate(existingSejStoreInfoWindowHtml, [
//             event.feature.getProperty("attribution").tenpo_code,
//             event.feature.getProperty("attribution").tenpo_name,
//             event.feature.getProperty("attribution").original_open_date,
//             event.feature.getProperty("attribution").contract_type,
//           ])
//         );

//       existingSejStoreInfoWindow
//         .get(event.feature)
//         .setOptions({ pixelOffset: new google.maps.Size(0, -25) });
//       // InfowWindowを表示
//       existingSejStoreInfoWindow.get(event.feature).open(getGoogleMapsObject());
//       existingSejStoreInfoWindow
//         .get(event.feature)
//         .addListener("domready", () => {
//           // infowindowのZindex変更イベント
//           // $(
//           //   "#" +
//           //     event.feature.getProperty("attribution").tenpo_code +
//           //     "-infowindow"
//           // ).on("click", () => {
//           //   setZIndex(existingSejStoreInfoWindow.get(event.feature));
//           // });
//           // $("#testButtonInfoWindow")
//           //   .off()
//           //   .on("click", () => {
//           //     // Todo暫定ルート検索
//           //     getRoute();
//           //   });
//         });
//       existingSejStoreInfoWindow
//         .get(event.feature)
//         .addListener("closeclick", function () {
//           existingSejStoreInfoWindow.delete(event.feature);
//         });
//     } else {
//       existingSejStoreInfoWindow.get(event.feature).close();
//       existingSejStoreInfoWindow.delete(event.feature);
//     }
//   });
//   // スタイル指定（最初は非表示）
//   existingSejStoreLayer.setStyle(unvisibleMapsStyleSettings);
//   manageDisplayLayer(existingSejStoreLayer, true);
// };

// 競合店舗初期処理
// export const initConflictStores = (): void => {
//   conflictStoreLayer = new google.maps.Data({
//     map: getGoogleMapsObject(),
//     style: {
//       visible: false,
//     },
//   });
//   // クリックイベントの登録
//   conflictStoreLayer.addListener("click", function (event: any) {
//     if (!conflictStoreInfoWindow.has(event.feature)) {
//       conflictStoreInfoWindow.set(
//         event.feature,
//         new google.maps.InfoWindow({
//           // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
//           maxWidth: 300,
//         })
//       );
//       // 表示位置
//       conflictStoreInfoWindow.get(event.feature).setPosition(event.latLng);
//       const isExist = (val: string): string => {
//         if (val === "1") {
//           return "有";
//         }
//         return "";
//       };
//       // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
//       conflictStoreInfoWindow
//         .get(event.feature)
//         .setContent(
//           applyHtmlTemplate(conflictStoreInfoWindowHtml, [
//             event.feature.getProperty("attribution").store_name,
//             event.feature.getProperty("attribution").business_hours,
//             isExist(
//               event.feature.getProperty("attribution").handle_category_atm
//             ),
//             isExist(
//               event.feature.getProperty("attribution").handle_category_liquor
//             ),
//             isExist(
//               event.feature.getProperty("attribution").handle_category_parking
//             ),
//             isExist(
//               event.feature.getProperty("attribution").handle_category_tobacco
//             ),
//           ])
//         );

//       conflictStoreInfoWindow
//         .get(event.feature)
//         .setOptions({ pixelOffset: new google.maps.Size(0, -30) });
//       // InfowWindowを表示
//       conflictStoreInfoWindow.get(event.feature).open(getGoogleMapsObject());
//       conflictStoreInfoWindow.get(event.feature).addListener("domready", () => {
//         // infowindowのZindex変更イベント
//         // $(
//         //   "#" +
//         //     event.feature.getProperty("attribution").tenpo_code +
//         //     "-infowindow"
//         // ).on("click", () => {
//         //   setZIndex(existingSejStoreInfoWindow.get(event.feature));
//         // });
//         // $("#testButtonInfoWindow")
//         //   .off()
//         //   .on("click", () => {
//         //     // Todo暫定ルート検索
//         //     getRoute();
//         //   });
//       });
//       conflictStoreInfoWindow
//         .get(event.feature)
//         .addListener("closeclick", function () {
//           conflictStoreInfoWindow.delete(event.feature);
//         });
//     } else {
//       conflictStoreInfoWindow.get(event.feature).close();
//       conflictStoreInfoWindow.delete(event.feature);
//     }
//   });
//   // スタイル指定（最初は非表示）
//   conflictStoreLayer.setStyle(unvisibleMapsStyleSettings);
//   manageDisplayLayer(conflictStoreLayer, true);
// };

// 商圏情報初期処理
// export const initMarketArea = (): void => {
//   marketAreaLayer = new google.maps.Data({
//     map: getGoogleMapsObject(),
//     style: {
//       visible: false,
//     },
//   });
//   // クリックイベントの登録
//   // marketAreaLayer.addListener("click", function (event: any) {
//   //   if (!marketAreaLayerInfoWindow.has(event.feature)) {
//   //     marketAreaLayerInfoWindow.set(
//   //       event.feature,
//   //       new google.maps.InfoWindow({
//   //         // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
//   //         maxWidth: 300,
//   //       })
//   //     );
//   //     // 表示位置
//   //     marketAreaLayerInfoWindow.get(event.feature).setPosition(event.latLng);
//   //     const isExist = (val: string): string => {
//   //       if (val === "1") {
//   //         return "有";
//   //       }
//   //       return "";
//   //     };
//   //     // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
//   //     marketAreaLayerInfoWindow
//   //       .get(event.feature)
//   //       .setContent(
//   //         applyHtmlTemplate(conflictStoreInfoWindowHtml, [
//   //           event.feature.getProperty("attribution").store_name,
//   //           event.feature.getProperty("attribution").business_hours,
//   //           isExist(
//   //             event.feature.getProperty("attribution").handle_category_atm
//   //           ),
//   //           isExist(
//   //             event.feature.getProperty("attribution").handle_category_liquor
//   //           ),
//   //           isExist(
//   //             event.feature.getProperty("attribution").handle_category_parking
//   //           ),
//   //           isExist(
//   //             event.feature.getProperty("attribution").handle_category_tobacco
//   //           ),
//   //         ])
//   //       );

//   //       marketAreaLayerInfoWindow
//   //       .get(event.feature)
//   //       .setOptions({ pixelOffset: new google.maps.Size(0, -30) });
//   //     // InfowWindowを表示
//   //     marketAreaLayerInfoWindow.get(event.feature).open(getGoogleMapsObject());
//   //     marketAreaLayerInfoWindow.get(event.feature).addListener("domready", () => {
//   //       // infowindowのZindex変更イベント
//   //       // $(
//   //       //   "#" +
//   //       //     event.feature.getProperty("attribution").tenpo_code +
//   //       //     "-infowindow"
//   //       // ).on("click", () => {
//   //       //   setZIndex(existingSejStoreInfoWindow.get(event.feature));
//   //       // });
//   //       // $("#testButtonInfoWindow")
//   //       //   .off()
//   //       //   .on("click", () => {
//   //       //     // Todo暫定ルート検索
//   //       //     getRoute();
//   //       //   });
//   //     });
//   //     marketAreaLayerInfoWindow
//   //       .get(event.feature)
//   //       .addListener("closeclick", function () {
//   //         conflictStoreInfoWindow.delete(event.feature);
//   //       });
//   //   } else {
//   //     marketAreaLayerInfoWindow.get(event.feature).close();
//   //     marketAreaLayerInfoWindow.delete(event.feature);
//   //   }
//   // });
//   // スタイル指定（最初は非表示）
//   marketAreaLayer.setStyle(unvisibleMapsStyleSettings);
// };

// レイヤ別の値をそれぞれ返す
export function getLayerValue(
  layerValue: any,
  typeName: string,
  layerName: string = ""
): any {
  // 既存店レイヤー
  if (layerValue === existingSejStoreLayer && typeName === "geojson") {
    return existingSejStoreData;
  }
  if (layerValue === existingSejStoreLayer && typeName === "mapStyle") {
    return existingSejStoreLayerMapsStyleSettings;
  }
  if (layerValue === existingSejStoreLayer && typeName === "fsDocumentName") {
    return "existing_sej_stores";
  }
  if (layerValue === existingSejStoreLayer && typeName === "infoWindow") {
    return existingSejStoreInfoWindow;
  }
  // 競合店レイヤー
  if (layerValue === conflictStoreLayer && typeName === "geojson") {
    return conflictStoreData;
  }
  if (layerValue === conflictStoreLayer && typeName === "mapStyle") {
    return conflictStoreLayerMapsStyleSettings;
  }
  if (layerValue === conflictStoreLayer && typeName === "fsDocumentName") {
    return "conflict_stores";
  }
  if (layerValue === conflictStoreLayer && typeName === "infoWindow") {
    return conflictStoreInfoWindow;
  }
  // // 商圏レイヤー
  // if (layerValue === marketAreaLayer && typeName === "geojson") {
  //   return marketAreaData;
  // }
  // if (layerValue === marketAreaLayer && typeName === "mapStyle") {
  //   return marketAreaLayerMapsStyleSettings;
  // }
  // if (layerValue === marketAreaLayer && typeName === "fsDocumentName") {
  //   return "market_area_walk_5min";
  // }
  // if (layerValue === marketAreaLayer && typeName === "infoWindow") {
  //   return marketAreaLayerInfoWindow;
  // }
  // if (layerValue === marketAreaLayer && typeName === "isPushedLayerButton") {
  //   return isMarketAreaLayerDisplay;
  // }
  // if (layerName === "marketArea" && typeName === "layerValue") {
  //   return marketAreaLayer;
  // }
}
