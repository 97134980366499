/**
 * mapInnerDataOperation.js
 * 内部で保持したデータの処理を行います
 */
import { point2distance } from "../../../../apis/turfAction";
import {
  isDisplaySejExistingStore,
  isDisplayConflictStore,
  isDisplayCvrSejExistingStore,
} from "../SelectMobakuMaps";
import {
  existingSejStoreLayer,
  cbFuncExistingSejStores,
  innerDataExistingSejStoreLayer,
  cvrExistingSejStoreLayer,
} from "../layers/existingSejStoreLayer";
import {
  conflictStoreLayer,
  cbFuncConflictStores,
  innerDataConflictStore,
} from "../layers/conflictStoreLayer";

let lastQueriedLatLng: any = null;

// 内部データ処理の呼出
export const callMapItemInnerData = (
  latLng: any,
  zoomLevel = 22,
  isForceUpdate: boolean = false
) => {
  let searchLength: number = 3;
  const searchDistance = 1,
    largeAreaZoomLevel = 15;
  if (
    zoomLevel !== 22 &&
    lastQueriedLatLng !== null &&
    point2distance(latLng, lastQueriedLatLng) / 1000 < searchDistance &&
    !isForceUpdate
  ) {
    return;
  }
  if (largeAreaZoomLevel > zoomLevel) {
    searchLength = 4.5;
  }

  if (isDisplaySejExistingStore) {
    callInnerData(
      cbFuncExistingSejStores,
      existingSejStoreLayer,
      innerDataExistingSejStoreLayer(latLng, searchLength)
    );
  }
  if (isDisplayCvrSejExistingStore) {
    callInnerData(
      cbFuncExistingSejStores,
      cvrExistingSejStoreLayer,
      innerDataExistingSejStoreLayer(latLng, searchLength)
    );
  }
  if (isDisplayConflictStore) {
    callInnerData(
      cbFuncConflictStores,
      conflictStoreLayer,
      innerDataConflictStore(latLng, searchLength)
    );
  }

  lastQueriedLatLng = latLng;
};

const callInnerData = (callbackFunc: any, layerValue: any, geojson: any) => {
  callbackFunc(geojson, layerValue, []);
};
