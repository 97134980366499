import React, { FC, useEffect, useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  Grid,
  Button,
} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import { useSelector } from "react-redux";
import { selectUser } from "../../../src/features/userSlice";
import {
  SwipTempDrawerHandles,
  SwipeableTemporaryDrawer,
} from "../common/SwipeableTemporaryDrawer";
import { EXISTING_SEJ_STORES_RECORD, getAllExistingSejStores } from "../../data/existingSejStores";
import { userLogging } from "../../apis/userLog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "#3e3e3e",
    },
    headerTitle: {
      backgroundColor: "#ffffff",
    },
    logoutButton: {
      color: "white",
      backgroundColor: "#1ba649",
    },
    toolbar: {
      // ヘッダーの縦の調整はminHeight を再設定する
      [theme.breakpoints.down("sm")]: {
        minHeight: "36px",
      },
      [theme.breakpoints.up("md")]: {
        minHeight: "48px",
      },
    },
    rightItems: {
      marginLeft: "auto",
    },
  })
);

// 初期表示店舗コード
let initTenpoCode = "";
export function setInitTenpoCode(code: any) {
  initTenpoCode = code;
}
export function getInitTenpoCode() {
  return initTenpoCode;
}
// 編集不可フラグ
let fixedFlg: boolean = false;
export function getFixedFlg() {
  return fixedFlg;
}
export function setFixedFlg(flg: boolean) {
  fixedFlg = flg;
}

// 初期表示フラグ
let firstFlg: boolean = false;
export function getFirstFlg() {
  return firstFlg;
}
export function setFirstFlg(flg: boolean) {
  firstFlg = flg;
}

function setSelectItem(code: string, name: string) {
  return { code, name };
}

const ButtonAppBar: FC<{
  titleName: string;
  tenpoChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  modalCondition?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}> = ({ titleName, tenpoChange, modalCondition }) => {
  const classes = useStyles();
  const user: any = useSelector(selectUser);
  const storeMaster: Map<String, EXISTING_SEJ_STORES_RECORD> = getAllExistingSejStores().store_data;
  const [tenpoCode, setTenpoCode] = React.useState(initTenpoCode);
  const swipTempDrawerRef = useRef<SwipTempDrawerHandles>(null);
  let tenpoList = [setSelectItem("", "")];
  for (let i = 0; i < user.tenpoCode.length; i++) {
    // 店舗レコードを内部データから取得
    let storeRecord = storeMaster.get(user.tenpoCode[i]);
    if (storeRecord) {
      tenpoList.push(
        setSelectItem(
          user.tenpoCode[i],
          storeRecord.tenpo_name
        )
      );
    }
  }
  let storeRecordSearch = storeMaster.get(tenpoCode);
  // データ検索時に担当店以外の店舗名が表示できるようにする
  if (storeRecordSearch) {
    if (!user.tenpoCode.includes(tenpoCode)) {
      tenpoList.push(
        setSelectItem(tenpoCode, storeRecordSearch.tenpo_name)
      );
    }
  }

  function openSwipTempDrawer() {
    swipTempDrawerRef.current?.openDrawer();
  }
  const modalConditionDo = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (modalCondition) {
      modalCondition(event);
    }
  };

  // 店番変更時のタブリセット
  useEffect(() => {
    if (!firstFlg) return;
    setTenpoCode("");
  }, [firstFlg]);

  const tenpoChangeDo = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTenpoCode(event.target.value as string);
    if (tenpoChange) {
      tenpoChange(event);
    }
  };

  return (
    <AppBar position="static" className={classes.headerTitle}>
      <Toolbar className={classes.toolbar}>
        <Grid item lg={8} md={7} sm={6} style={{ display: "flex" }}>
          <Grid container justify="flex-start" alignItems="center">
            <SwipeableTemporaryDrawer ref={swipTempDrawerRef} />
            <Typography variant="h6" className={classes.title}>
              {titleName}
            </Typography>
          </Grid>
        </Grid>
        {tenpoChange ? (
          <>
            <Grid item lg={1} md={1} sm={1}>
              <Grid container justify="center" alignItems="center">
                <Typography variant="h6" className={classes.title}>
                  店舗名
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg={3} md={4} sm={5}>
              <Select
                value={tenpoCode}
                onChange={(e) => {
                  tenpoChangeDo(e);
                  userLogging("共通", "店舗名選択", e.target.value as string);
                }}
                style={{
                  minWidth: 320,
                  color: "#3e3e3e",
                }}
                readOnly={getFixedFlg()}
              >
                {tenpoList.map((row, index) => (
                  <MenuItem value={row.code} key={index}>
                    {row.code !== "" ? (
                      row.name
                    ) : (
                      <em>　　　　　　　　　　　　　　　　　</em>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        ) : (
          ""
        )}
        {modalCondition ? (
          <>
            <Grid item lg={2} md={2} sm={2}></Grid>
            <Grid item lg={1} md={1} sm={1}>
              <Button onClick={(e) => modalConditionDo(e)}>
                <Search style={{ color: "#4DC0B2" }}></Search>
              </Button>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Toolbar>
    </AppBar>
  );
};
export default ButtonAppBar;
