import React, { useEffect, FC } from "react";
import {  useSelector } from "react-redux";

import CloseIcon from "@material-ui/icons/Close";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Modal,
  Grid,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";

import { selectImageToken } from "../../../features/userSlice";
import { getFixedFlg } from "../../Header/Header";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadGrid: {
      height: "9vh",
    },
    noUpload: {
      color: "#f2f2f2",
    },
    uploaded: {
      color: "#37ab9d",
    },
    modalPane: {
      position: "absolute",
      paddingTop: "2%",
      paddingLeft: "5%",
      width: "84%",
      height: "80%",
    },
    paper: {
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    titleGrid: {
      height: "7vh",
      color: "white",
      backgroundColor: "#4dc0b2",
    },
    titleTypography: {
      fontWeight: "bold",
    },
  })
);
const TenpoCheckModalLastScore: FC<{
  title: string;
  comment?: string;
  photoFilePath: string[];
}> = ({
  title,
  comment,
  photoFilePath,
}) => {
  const classes = useStyles();
  const [modalComment, setModalComment] = React.useState(comment);
  const [filePath, setFilePath] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setFilePath(photoFilePath);
  }, [photoFilePath]);

  const handleClose = () => {
    setOpen(false);
  };
  const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (getFixedFlg()) return;
    let comment = event.target.value as string;
    if (comment && Number(comment.length) > 2000) {
      comment = comment.substring(0, 2000);
    }
    setModalComment(comment);
  };

  const imageToken = useSelector(selectImageToken);
  function getImageFile(imagePath: string) {
    if (imagePath === undefined || imagePath === "") {
      return `${window.location.origin}/image/noimage.png`;
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      imagePath +
      "?access_token=" +
      imageToken
    );
  }
  function isUploaded(argFilePath: string, argComment: any): any {
    if (
      (argFilePath && argFilePath !== "") ||
      (argComment && argComment !== "")
    ) {
      return classes.uploaded;
    }
    return classes.noUpload;
  }

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.uploadGrid}
      >
        <Grid
          onClick={handleOpen}
          className={isUploaded(filePath[0], modalComment)}
        >
          <CloseIcon style={{ color: "red" }} />
        </Grid>
      </Grid>
      {/*  モーダルここから↓*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalPane}
      >
        <Grid container className={classes.paper} style={{ overflowY: "auto" }}>
          {/*  タイトルここから↓*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.titleGrid}
          >
            <Typography variant="h6" className={classes.titleTypography}>
              {title}
            </Typography>
          </Grid>
          {/*  タイトルここまで↑*/}
          <Grid
            container
            style={{
              marginBottom: "5px",
              display: "flex",
              alignSelf: "flex-start",
            }}
          >
            {/*  写真ここから↓*/}
            <Grid item lg={3} md={4} sm={4}>
              <Grid container justify="center" style={{ paddingTop: "10px" }}>
                <img
                  alt=""
                  src={getImageFile(filePath[0])}
                  height="200"
                  style={{
                    width: "200px",
                    WebkitAppearance: "none",
                  }}
                />
              </Grid>
            </Grid>
            {/*  写真ここまで↑*/}
            {/*  その他コメントここから↓*/}
            <Grid item lg={9} md={8} sm={8} style={{ height: "45vh" }}>
              <TextareaAutosize
                rowsMax={16}
                aria-label="store-input-comment-area"
                rowsMin={16}
                maxLength={4000}
                placeholder={
                    "その他コメント"
                }
                value={modalComment}
                onChange={(e) => {
                  changeComment(e);
                }}
                style={{
                  width: "93%",
                  fontSize: "16px",
                  borderRadius: "8px",
                  margin: "10px",
                }}
              />
            </Grid>
            {/*  その他コメントここまで↑*/}
          </Grid>
        </Grid>
      </Modal>
      {/*  モーダル↑ここまで*/}
    </>
  );
};
export default TenpoCheckModalLastScore;
