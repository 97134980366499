// @ts-nocheck
import React, { FC, useEffect } from "react";
import MultiDatePickerCalendar from "../common/MultiDatePickerCalendar";
import {
  Modal,
  Table,
  TableContainer,
  Box,
  TextField,
  Button,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CalendarToday from "@material-ui/icons/CalendarToday";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import CalendarManual from "../OpView/Mnual/CalendarManual";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendar: {
      position: "absolute",
      right: "25%",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "5px",
      boxShadow: theme.shadows[3],
      "&:focus": {
        outline: "none",
      },
      // iPad縦
      "@media (min-width: 750px)": {
        top: "3%",
        width: "50%",
        height: "640px",
      },
      // iPad横
      "@media (min-width: 1150px)": {
        top: "5%",
        width: "46%",
        height: "85%",
      },
      // GWPC
      "@media (min-width: 1200px)": {
        top: "1%",
        width: "41%",
        height: "620px",
      },
    },
    header: {
      backgroundColor: "orange",
      height: "8%",
      alignItems: "center",
      borderRadius: "5px 5px 0 0",
    },
    body: {
      margin: "0 2% 2%",
    },
    table: {
      borderCollapse: "separate",
    },
    yearMonth: {
      fontSize: "30px",
      color: "#fff",
    },
    jumpIcon: {
      color: "#fff",
    },
    calendarButtons: {
      margin: "10px 0px",
      textAlign: "center",
    },
    calendarButton: {
      color: "#fff",
      backgroundColor: "#ffa5008c",
      "&:hover": {
        backgroundColor: "orange",
      },
      fontWeight: "bold",
      textAlign: "center",
    },
    manual: {
      marginRight: "5%",
    },
    closeButton: {
      color: "#fff",
    },
    closeButtonWrapper: {
      height: "100%",
    },
    note: {
      color: "red",
      textAlign: "center",
    },
  })
);

const MultiDatePicker: FC<{
  dateFunc: any;
  oneYearWeather: any;
  holiday: string[];
  firstDate?: string[];
  timeCompFlag?: boolean;
  timeCompFlagFunc?: any;
}> = ({
  dateFunc,
  oneYearWeather,
  holiday,
  firstDate,
  timeCompFlag,
  timeCompFlagFunc,
}) => {
  const classes = useStyles({});
  const today =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_MODE === "develop"
      ? new Date(2021, 10, 1, 1, 0, 0)
      : new Date();
  const [year, setYear] = React.useState<number>(today.getFullYear());
  const [month, setMonth] = React.useState<number>(today.getMonth());
  const [open, setOpen] = React.useState(false);
  const selectedDate = React.useRef([]);
  const [selectingDate, setSelectingDate] = React.useState([]);
  const [selectingMode, setSelectingMode] = React.useState<string>("");
  const selectingRangeDate = React.useRef([]);
  const [mouseDowned, setMouseDowned] = React.useState(false);
  const [mouseMoved, setMouseMoved] = React.useState(false);
  // 期間選択でリアルタイムにスタイルを当てるために逐次レンダリングを起こす
  const [styleTrigger, setStyleTrigger] = React.useState(false);
  let rangeStart = React.useRef("");
  let beforeEndDate = React.useRef("");

  // 初期表示
  useEffect(() => {
    // 前日取得(ローカル開発環境時なら2021/10/1固定)
    let date;
    if (
      process.env.NODE_ENV === "development" ||
      process.env.REACT_APP_MODE === "develop"
    ) {
      date = new Date(2021, 9, 31, 1, 0, 0);
    } else {
      date = new Date();
      date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1,
        0,
        0
      );
    }

    // 月と日はゼロパディング
    const tempMonth = ("0" + (date.getMonth() + 1).toString()).slice(-2);
    const tempDate = ("0" + date.getDate().toString()).slice(-2);
    date = `${date.getFullYear().toString()}${tempMonth}${tempDate}`;
    setSelectingDate([date]);
    selectedDate.current = [date];

    // 当日が1日の場合前月を初期設定
    if (today.getDate() === 1) {
      if (month === 0) {
        setMonth(11);
      } else {
        setMonth(month - 1);
      }
    }
    if (timeCompFlag) {
      selectedDate.current = firstDate;
      setSelectingDate(firstDate);
      timeCompFlagFunc();
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectingDate(selectedDate.current);
    setOpen(false);
  };

  // 日付をクリックしたときの選択・非選択を制御
  const mouseDownCell = (day: number, monthType: string) => {
    setMouseDowned(true);
    rangeStart.current = arrangeDate(day, monthType);
    selectingDate.includes(rangeStart.current)
      ? setSelectingMode("cancel")
      : setSelectingMode("select");
  };

  // onMouseDownイベント中のonMouseMoveイベントを制御
  const mouseMoveCell = (day: number, monthType: string) => {
    if (isNaN(day)) return;
    setMouseMoved(true);
    const rangeEnd = arrangeDate(day, monthType);
    if (beforeEndDate.current >= rangeStart.current) {
      if (rangeEnd <= rangeStart.current) {
        selectingRangeDate.current = [];
      }
    } else if (beforeEndDate.current < rangeStart.current) {
      if (rangeEnd > rangeStart.current) {
        selectingRangeDate.current = [];
      }
    }
    const betweenDates = getBetweenDates(rangeStart.current, rangeEnd, "move");
    selectingRangeDate.current = betweenDates;
    setStyleTrigger(!styleTrigger);
    beforeEndDate.current = rangeEnd;
  };

  // onMouseUpイベント時の選択・非選択を制御
  const mouseUpCell = (
    day: number,
    monthType: string,
    isIpad: boolean = false
  ) => {
    if (isIpad) {
      const addDate = mouseMoved
        ? selectingRangeDate.current
        : [rangeStart.current];
      if (selectingMode === "select") {
        const mergedDates = [...selectingDate, ...addDate];
        const arrangedDates = [...new Set(mergedDates)];
        setSelectingDate(arrangedDates);
      } else if (selectingMode === "cancel") {
        const arrangedDates = getCanceledDates(selectingDate, addDate);
        setSelectingDate(arrangedDates);
      }
    } else {
      setMouseDowned(false);
      selectingRangeDate.current = [];
      const rangeEnd = arrangeDate(day, monthType);
      const betweenDates = getBetweenDates(rangeStart.current, rangeEnd, "up");
      if (selectingMode === "select") {
        // 既に選択されている日付と期間で選択した日付の重複を削除
        const mergedDates = [...selectingDate, ...betweenDates];
        const arrangedDates = [...new Set(mergedDates)];
        setSelectingDate(arrangedDates);
      } else if (selectingMode === "cancel") {
        const arrangedDates = getCanceledDates(selectingDate, betweenDates);
        setSelectingDate(arrangedDates);
      }
    }
    setMouseMoved(false);
    selectingRangeDate.current = [];
  };

  // 前月・当月・次月のずれを調整してYYYYMMDDを返す
  const arrangeDate = (day: number, monthType: string) => {
    if (!day) return;
    let tempMonth;
    let tempYear = year;
    if (monthType === "prev") {
      if (month === 0) {
        tempYear = year - 1;
        tempMonth = 12;
      } else {
        tempMonth = month;
      }
    } else if (monthType === "next") {
      if (month === 11) {
        tempYear = year + 1;
        tempMonth = 1;
      } else {
        tempMonth = month + 2;
      }
    } else {
      tempMonth = month + 1;
    }
    // 月と日はゼロパディング
    tempMonth = ("0" + tempMonth.toString()).slice(-2);
    day = ("0" + day.toString()).slice(-2);
    // 選択した日付をYYYYMMDDに変換
    return `${tempYear.toString()}${tempMonth}${day}`;
  };

  // 月変更時のカレンダー作成
  const changeMonth = (n: number) => () => {
    const nextMonth = month + n;
    if (11 < nextMonth) {
      setMonth(0);
      setYear(year + 1);
    } else if (nextMonth < 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(nextMonth);
    }
  };

  const showDate = () => {
    if (selectedDate.current.length === 0) {
      return "";
    } else if (selectedDate.current.length === 1) {
      return formatDateString(selectedDate.current[0]);
    } else {
      return "複数日選択中";
    }
  };

  // "yyyymmdd"を"yyyy-mm-dd"に変換する
  const formatDateString = (date: string) => {
    const formatedDate =
      date.slice(0, 4) + "/" + date.slice(4, 6) + "/" + date.slice(6);
    return formatedDate;
  };

  // 選択した開始日から終了日までの日付を取得
  const getBetweenDates = (start: string, end: string, type: string) => {
    const startDate = new Date(formatDateString(start));
    const endDate = new Date(formatDateString(end));
    let dates = [];
    let addDate;

    if (startDate <= endDate) {
      const theDate = new Date(startDate);
      while (theDate <= endDate) {
        addDate = new Date(theDate);
        let y = addDate.getFullYear();
        let m = ("00" + (addDate.getMonth() + 1)).slice(-2);
        let d = ("00" + addDate.getDate()).slice(-2);
        addDate = `${y}${m}${d}`;
        dates = [...dates, addDate];
        theDate.setDate(theDate.getDate() + 1);
      }
    } else {
      const theDate = new Date(endDate);
      while (theDate <= startDate) {
        addDate = new Date(theDate);
        let y = addDate.getFullYear();
        let m = ("00" + (addDate.getMonth() + 1)).slice(-2);
        let d = ("00" + addDate.getDate()).slice(-2);
        addDate = `${y}${m}${d}`;
        dates = [...dates, addDate];
        theDate.setDate(theDate.getDate() + 1);
      }
    }

    return dates;
  };

  // 選択中の期間からキャンセルする期間を削除した期間を返す
  const getCanceledDates = (selectingDate: [], selectingRangeDate: []) => {
    let dates = [];
    const start = Math.min(...selectingRangeDate);
    const end = Math.max(...selectingRangeDate);
    if (start <= end) {
      dates = selectingDate.filter((value) => {
        return end < value || value < start;
      });
    } else {
      dates = selectingDate.filter((value) => {
        return start < value || value < end;
      });
    }
    return dates;
  };

  // 選択できない日を判定
  const judgeInvalidDay = (
    day: number,
    isNextMonth: boolean,
    isPrevMonth: boolean
  ) => {
    const minDate = "20200901";
    let y = today.getFullYear();
    let m = ("00" + (today.getMonth() + 1)).slice(-2);
    let d = ("00" + today.getDate()).slice(-2);
    const maxDate = `${y}${m}${d}`;
    let tempThisMonth = "";
    if (isNextMonth) {
      tempThisMonth = ("0" + (month + 2).toString()).slice(-2);
    } else if (isPrevMonth) {
      tempThisMonth = ("0" + month.toString()).slice(-2);
    } else {
      tempThisMonth = ("0" + (month + 1).toString()).slice(-2);
    }
    const tempToday = ("0" + day.toString()).slice(-2);
    const date = `${year}${tempThisMonth}${tempToday}`;
    if (Number(date) >= Number(maxDate) || Number(minDate) > Number(date)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box>
        <TextField
          InputProps={{
            startAdornment: (
              <IconButton onClick={handleOpen} color={"inherit"}>
                <CalendarToday />
              </IconButton>
            ),
            readOnly: true,
          }}
          variant="standard"
          value={showDate()}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        onMouseUp={() => {
          setMouseDowned(false);
          selectingRangeDate.current = [];
        }}
        aria-labelledby="multi-datepicker-title"
        aria-describedby="multi-datepicker-description"
      >
        <div className={classes.calendar}>
          <Grid container className={classes.header}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <IconButton
                className={classes.jumpIcon}
                onClick={changeMonth(-1)}
              >
                <NavigateBefore fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.yearMonth} align={"center"}>
                {`${year}年 ${month + 1}月`}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton className={classes.jumpIcon} onClick={changeMonth(1)}>
                <NavigateNext fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={1} className={classes.closeButtonWrapper}>
              <div>
                <IconButton
                  className={classes.closeButton}
                  onClick={() => setOpen(false)}
                  size={"small"}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <div className={classes.note}>
            <Typography variant="caption">
              日付選択前に、クリアボタンを押してください
            </Typography>
          </div>
          {/* カレンダー本体 */}
          <div className={classes.body}>
            <TableContainer style={{ overflow: "hidden" }}>
              <Table
                className={classes.table}
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <MultiDatePickerCalendar
                  year={year}
                  month={month}
                  holiday={holiday}
                  selectingDate={selectingDate}
                  setSelectingDate={(dates: string[]) =>
                    setSelectingDate(dates)
                  }
                  selectingRangeDate={selectingRangeDate}
                  mouseDownCell={mouseDownCell}
                  mouseMoveCell={mouseMoveCell}
                  mouseUpCell={mouseUpCell}
                  mouseDowned={mouseDowned}
                  arrangeDate={arrangeDate}
                  judgeInvalidDay={judgeInvalidDay}
                  styleTrigger={styleTrigger}
                  isCancel={selectingMode === "cancel"}
                  oneYearWeather={oneYearWeather}
                />
              </Table>
            </TableContainer>
            {/* 閉じる・決定 */}
            <div className={classes.calendarButtons}>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  <Button
                    className={classes.calendarButton}
                    variant="text"
                    onClick={() => {
                      setSelectingDate([]);
                    }}
                    disabled={selectingDate.length === 0}
                  >
                    クリア
                  </Button>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                  <Button
                    className={classes.calendarButton}
                    variant="text"
                    onClick={() => {
                      selectedDate.current = selectingDate;
                      dateFunc(selectingDate);
                      handleClose();
                    }}
                    disabled={selectingDate.length === 0}
                  >
                    決定
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <div className={classes.manual}>
                    <CalendarManual></CalendarManual>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MultiDatePicker;
