import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import tenpoStatusReducer from "../features/tenpoStatusSlice";
import marketAreaStatusReducer from "../features/marketAreaStatusSlice";
import tenpoCheckConditionsReducer from "../components/organisms/search/TenpoCheckSearchSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    tenpoStatus: tenpoStatusReducer,
    marketAreaStatus: marketAreaStatusReducer,
    tenpoCheckCondition: tenpoCheckConditionsReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
