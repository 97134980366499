import React, { FC, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { getWeatherIcon } from "./WeatherIcon";
import "../../../index.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    day: {
      fontSize: "14px",
      margin: "0px",
    },
    data: {
      fontSize: "9px",
      margin: "0px",
    },
  })
);

const CalendarWeather: FC<{
  day: number;
  week: number;
  holiday: string[];
  oneYearWeather: any;
  isPale: boolean;
  isInvalid: boolean;
  arrangeDate: any;
  monthType: string;
  isNext: boolean;
}> = ({
  day,
  week,
  holiday,
  oneYearWeather,
  isPale,
  isInvalid,
  arrangeDate,
  monthType,
  isNext,
}) => {
  const classes = useStyles();
  const path = `${window.location.origin}/image/icons/weather/`;
  const [iconName, setIconName] = React.useState("000");
  const [maxTemp, setMaxTemp] = React.useState("");
  const [minTemp, setMinTemp] = React.useState("");
  const [rainfall, setRainfall] = React.useState("");
  const [holidayType, setHolidayType] = React.useState("");
  useEffect(() => {
    if (isNext) {
      monthType = "next";
    }
    initWeather();
    if (!isInvalid && week === 5) {
      setHolidayType("blue");
    } else if (
      !isInvalid &&
      (week === 6 || holiday?.includes(arrangeDate(day, monthType)))
    ) {
      setHolidayType("red");
    }
  }, []);

  const initWeather = () => {
    oneYearWeather?.map((obj: any) => {
      if (obj?.date === arrangeDate(day, monthType)) {
        setIconName(
          obj?.weather_code ? getWeatherIcon(obj?.weather_code) : "000"
        );
        setMaxTemp(obj?.max_temp);
        setMinTemp(obj?.min_temp);
        obj?.rainfall !== 999 ? setRainfall(obj?.rainfall) : setRainfall("-");
      }
    });
  };

  return (
    <div
      className={classes.root}
      style={isPale ? { opacity: "0.6" } : undefined}
    >
      <p className={classes.day} style={{ color: holidayType }}>
        {day}
      </p>
      <a className={"calendarItem"} href="#">
        <img src={path + "1000.webp"} className={"item" + iconName} alt={""} />
      </a>

      <p className={classes.data}>
        <span style={{ color: "red" }}>{maxTemp}</span>/
        <span style={{ color: "blue" }}>{minTemp}</span>
      </p>
      <p className={classes.data}>{rainfall + "mm"}</p>
    </div>
  );
};

export default CalendarWeather;
