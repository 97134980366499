import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  Theme,
  createStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Tooltip,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SelectStoreMenu, {
  hankaku2Zenkaku,
  latlngToMeshcode,
} from "./SelectStoreMenu";
import SelectMobakuMenu from "./SelectMobakuMenu";
import MultiDatePicker from "../common/MultiDatePicker";
import { DateTime } from "luxon";
import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import GraphWeather from "./Weather/GraphWeather";
import { postRequest } from "../../apis/axiosAction";
import {
  selectFirstCvrItem,
  selectSecondCvrItem,
  selectSpareMobakuItem,
  updateCvrMobakuName,
  updateFirstMobakuItem,
  updateFirstTenpoItem,
  updateSelectingFirstMobakuItem,
  updateFirstCvrItem,
  updateSecondCvrItem,
  updateSecondTenpoItem,
  updateSelectingCvrMobakuItem,
  updateSpareMobakuItem,
} from "../../features/tenpoStatusSlice";
import {
  EXISTING_SEJ_STORES_RECORD,
  getAllExistingSejStores,
} from "../../data/existingSejStores";
import { db } from "../../firebase";
import { initData } from "./DataFormat";
import {
  getFirestoreData,
  getFirestoreDocData,
} from "../../apis/firestoreAction";
import { selectExclusiveFlg } from "../../features/marketAreaStatusSlice";
import GraphContentCVR from "./GraphContentCVR";
import GraphContentZennenhiCVR from "./GraphContentZennenhiCVR";
import TableContentCVR from "./TableContentCVR";
import TableContentZennenhiCVR from "./TableContentZennenhiCVR";
import { getWeatherInfo } from "./Weather/weatherApi";
import { apiRequest } from "../../apis/util";
import { getMarketAreaDocId } from "./MarketArea/MarketAreaControl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graphBox: {
      position: "relative",
      left: "8px",
      height: "100%",
      overflowY: "scroll",
      overflowX: "hidden",
    },
    closeButton: {
      position: "absolute",
      left: "96%",
      "&:hover": {
        backgroundColor: "lightgray",
      },
    },
    errorDialogTitle: {
      textAlign: "right",
      padding: "0px",
    },
    errorDialogContent: {
      color: "red",
      textAlign: "center",
    },
    titlePaperFirst: {
      padding: "10px 0px",
      margin: "10px 0px",
      textAlign: "center",
      backgroundColor: "#0000FF",
      color: "#FFF",
    },
    titlePaperSecond: {
      padding: "10px 0px",
      margin: "10px 0px",
      textAlign: "center",
      backgroundColor: "#00B050",
      color: "#FFF",
    },
    circularProgress: {
      position: "absolute",
      top: "35%",
      left: "45%",
      opacity: "0.8",
      color: "#CCC",
    },
    overLayer: {
      position: "relative",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
    },
    errorDialog: {
      position: "fixed",
      width: "38%",
      height: "20%",
      top: "30%",
      left: "31%",
      backgroundColor: "#FFF",
      border: "solid 1px #CCC",
      borderRadius: "10px",
      padding: "16px",
      opacity: 1,
      zIndex: 100,
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      "&:focus": {
        outline: "none",
      },
    },
    errorDialogCloseButton: {
      position: "absolute",
      top: "4px",
      right: "3%",
    },
    backLayer: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 100,
    },
  })
);
let initSelectFirstCvrItem = "";

export function setStoreCodeCvrGraphAnalysis(storeCode: any) {
  initSelectFirstCvrItem = storeCode;
}

const CvrGraphAnalysis: React.FC<{
  firstView: boolean;
  handleFirstView: any;
  openTenpoSelect: boolean;
  handleOpenTenpoSelect: any;
  holiday: string[];
  key: number;
}> = ({
  firstView,
  handleFirstView,
  openTenpoSelect,
  handleOpenTenpoSelect,
  holiday,
  key: number,
}) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  // const common_api_url: string = process.env.REACT_APP_COMMON_API!;
  // const common_api_url =
  //   "http://localhost:19001/outside/call-common-process-api";
  const tenpoFirstName: Map<String, EXISTING_SEJ_STORES_RECORD> =
    getAllExistingSejStores().store_data;
  const tenpoSecondName: Map<String, EXISTING_SEJ_STORES_RECORD> =
    getAllExistingSejStores().store_data;
  const graphFirstCvrItem: string = useSelector(selectFirstCvrItem);
  const graphFirstItem: string = graphFirstCvrItem
    ? graphFirstCvrItem
    : initSelectFirstCvrItem;
  const graphSecondItem: string[] = useSelector(selectSecondCvrItem);
  const spareMobakuItem: string[] = useSelector(selectSpareMobakuItem);
  const logFlag = React.useRef(false);
  const initDate = React.useRef<string[]>([]);
  const isDoubleUpdate = React.useRef(false);
  const axisChange = React.useRef(false);
  const [loading, setLoading] = React.useState(false);
  const [errorDialog, setErrorDialog] = React.useState(false);
  const [initGraph, setInitGraph] = React.useState(false);
  const [data, setData] = React.useState<any[]>();
  // const [tenpoCode, setTenpoCode] = React.useState<string>("");
  // const [mobakuCode, setMobakuCode] = React.useState<string[]>([]);
  const [yearOnYear, setYearOnYear] = React.useState(false);
  const [yearOnYearMode, setYearOnYearMode] = React.useState(false);
  const [tableOpen, setTableOpen] = React.useState("none");
  const [firstDate, setFirstDate] = React.useState<string[]>([]);
  const [secondDate, setSecondDate] = React.useState<string[]>([]);
  const [firstDayWeather, setFirstDayWeather] = React.useState({});
  const [secondDayWeather, setSecondDayWeather] = React.useState({});
  const [firstOneYearWeather, setFirstOneYearWeather] = React.useState([]);
  const [secondOneYearWeather, setSecondOneYearWeather] = React.useState([]);
  const [baseAxis, setBaseAxis] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState(<></>);
  const [radioAxisMode, setRadioAxisMode] = React.useState<string>("timeComp");
  const [radioYearMode, setRadioYearMode] = React.useState<string>("thisComp");
  const [timeCompFlag, setTimeCompFlag] = React.useState<boolean>(false);
  let tempCurrentYearDiffNum: number = 0;
  let tempLastYearDiffNum: number = 0;
  const tempData = React.useRef<any[]>([]);

  const blueTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#DCE6F2",
        contrastText: "#101213",
      },
      secondary: {
        main: "#0000FF",
        contrastText: "#FFF",
      },
    },
  });

  const radioTheme = createMuiTheme({
    overrides: {
      MuiRadio: {
        root: {
          color: "#57bbb4",
        },
        colorSecondary: {
          "&$checked": {
            color: "#57bbb4",
          },
        },
      },
    },
  });

  function errorMsgNoData() {
    setErrorMessage(
      <div>
        対象のデータが存在しません。
        <br />
        日付または分析・比較対象を再度選択してください。
      </div>
    );
  }

  // 初期表示
  useEffect(() => {
    //  前日取得(ローカル開発環境時なら2021/10/31固定)
    const date =
      process.env.NODE_ENV === "development" ||
      process.env.REACT_APP_MODE === "develop"
        ? DateTime.local(2021, 10, 31, 1, 0, 0)
        : DateTime.local().minus({ day: 1 });
    initDate.current = [
      DateTime.local(date.year, date.month, date.day, 0, 0).toFormat(
        "yyyyMMdd"
      ),
    ];
    setFirstDate(initDate.current);
    setSecondDate(initDate.current);
    // グラフおよび表に渡すデータセットの初期化（時間軸）
    tempData.current = initData(24);
    if (initSelectFirstCvrItem) {
      selectStore(initSelectFirstCvrItem);
      initSelectFirstCvrItem = "";
    }
  }, []);

  // 分析対象の店舗が変更されたときにグラフ描画データを更新
  useEffect(() => {
    isDoubleUpdate.current = true;
    setTargetTenpo();
  }, [graphFirstItem]);

  // 比較対象のモバ空メッシュが変更されたときにグラフ描画データを更新
  useEffect(() => {
    setTargetMobaku();
  }, [graphSecondItem]);

  // 分析対象の日付が変更されたときにグラフ描画データを更新
  useEffect(() => {
    if (baseAxis === 0) {
      setTargetTenpo();
    } else {
      isDoubleUpdate.current = true;
      handleYearAndAxisMode();
    }
  }, [firstDate]);

  // 比較対象の日付が変更されたときにグラフ描画データを更新
  useEffect(() => {
    if (!axisChange.current) {
      setTargetMobaku();
    }
  }, [secondDate]);

  // 当年/前年モード、軸モードが変更されたときにグラフ描画データを更新
  useEffect(() => {
    isDoubleUpdate.current = !isSingleSelect() && true;
    handleYearAndAxisMode();
  }, [yearOnYear, baseAxis]);

  const selectStore = async (tenpoCode: string) => {
    // 店舗レコードを内部データから取得
    let storeRecord = tenpoFirstName.get(initSelectFirstCvrItem);
    let lat: number = Number(storeRecord?.latitude);
    let lon: number = Number(storeRecord?.longitude);
    const meshCode = latlngToMeshcode(lat, lon);
    // if (itemOrder === "first") {
    //   if (graphType === "cvr") {
    //     if (tempFirstView.current) {
    //       initGraphItems();
    //     }
    const secondCvrItem = (await getExisitMarketArea(tenpoCode))
      ? ["market-area"]
      : [meshCode];
    dispatch(
      updateSpareMobakuItem({
        spareMobakuItem: [meshCode],
      })
    );
    dispatch(
      updateFirstCvrItem({
        firstCvrItem: tenpoCode,
      })
    );
    dispatch(
      updateSecondCvrItem({
        secondCvrItem: secondCvrItem,
      })
    );
    dispatch(
      updateSelectingCvrMobakuItem({
        selectingCvrMobakuItem: secondCvrItem,
      })
    );

    setMobakuName(secondCvrItem[0]);
    //   } else if (graphType === "tenpo") {
    //     dispatch(
    //       updateFirstTenpoItem({
    //         firstTenpoItem: tenpoCode,
    //       })
    //     );
    //   }
    // } else if (itemOrder === "second") {
    //   dispatch(
    //     updateSecondTenpoItem({
    //       secondTenpoItem: tenpoCode,
    //     })
    //   );
    //   setMobakuName(meshCode, graphType, itemOrder);
    // }
  };
  // 分析対象で選択した店舗に任意商圏が設定されているか判定
  const getExisitMarketArea = async (tenpoCode: string) => {
    let ret = false;
    if (tenpoCode) {
      const marketAreaValue = await getFirestoreDocData(
        "market_area",
        getMarketAreaDocId(tenpoCode)
      ).then((response: any) => {
        return response;
      });
      if (marketAreaValue !== undefined) {
        ret = true;
      }
    }
    return ret;
  };

  // 分析対象の店舗が変更されたときの処理
  const setTargetTenpo = async (isModeChange: boolean = false) => {
    // 日付が変更された時に選択されていない方は処理を行わない
    if (graphFirstItem === "" || firstDate.length === 0) {
      return;
    }
    // 日、週、月比較時の複数日付チェック
    if (checkMultiSelectDate(firstDate, baseAxis)) return;
    // グラフの初期表示
    setInitGraph(true);
    setLoading(true);
    // 天気情報を取得
    const weatherInfo = await getWeatherInfo(
      "first",
      graphFirstItem,
      firstDate,
      "cvr"
    );
    setFirstOneYearWeather(weatherInfo.oneYearWeather);
    setFirstDayWeather(weatherInfo.selectedDayWeather);

    // 当年・前年客数をAPIで取得
    const apiRes = await kyakusuRequest(graphFirstItem, firstDate);

    try {
      // APIで取得したデータから客数の当年実数を抽出
      const kyakusuArrCurrentYear = getKyakusuArr(apiRes, false);
      // 客数の当年実数等をグラフ用データに追加
      const graphData = addKyakusuData(kyakusuArrCurrentYear, "first");

      if (!yearOnYear) {
        setGraphItemCurrentYear(graphData);
        // !isModeChange && displayItemLog();
      } else {
        // 前年比モード時の処理
        // APIで取得したデータから客数の前年実数を抽出
        const kyakusuArrLastYear = getKyakusuArr(apiRes, true);
        // 客数の前年実数等をグラフ用データに追加
        const graphData = addKyakusuDataLastYear(kyakusuArrLastYear, "first");

        setGraphItemLastYear(graphData);
        // !isModeChange && displayItemLog();
      }
    } catch (e) {
      initKyakusuData("first");
      setLoading(false);
      errorMsgNoData();
      setErrorDialog(true);
      isDoubleUpdate.current = false;
    }
  };

  // 比較対象のモバ空が変更されたときの処理
  const setTargetMobaku = async (isModeChange: boolean = false) => {
    // 日付が変更された時に選択されていない方は処理を行わない
    if (graphSecondItem.length === 0 || secondDate.length === 0) {
      return;
    }
    // 日、週、月比較時の複数日付チェック
    if (checkMultiSelectDate(firstDate, baseAxis)) return;
    // グラフの初期表示
    setInitGraph(true);
    setLoading(true);
    // 天気情報を取得
    const weatherInfo = await getWeatherInfo(
      "second",
      graphSecondItem,
      secondDate,
      "cvr",
      graphFirstItem
    );
    setSecondOneYearWeather(weatherInfo.oneYearWeather);
    setSecondDayWeather(weatherInfo.selectedDayWeather);

    // 当年・前年モバ空データをAPIで取得
    const apiRes = await mobakuRequest(graphSecondItem, secondDate);
    try {
      // APIで取得したデータからモバ空の当年実数を抽出
      const mobakuArrCurrentYear = getMobakuArr(apiRes, false);
      // モバ空の当年実数等をグラフ用データに追加
      const graphData = addMobakuData(mobakuArrCurrentYear, "second");

      if (!yearOnYear) {
        setGraphItemCurrentYear(graphData);
        // !isModeChange && displayItemLog();
      } else {
        // 前年比モード時の処理
        // APIで取得したデータからモバ空の前年実数を抽出
        const mobakuArrLastYear = getMobakuArr(apiRes, true);
        // モバ空の前年実数等をグラフ用データに追加
        const graphData = addMobakuDataLastYear(mobakuArrLastYear, "second");

        setGraphItemLastYear(graphData);
        // !isModeChange && displayItemLog();
      }
    } catch (e) {
      initMobakuData("second");
      setLoading(false);
      errorMsgNoData();
      setErrorDialog(true);
      isDoubleUpdate.current = false;
    }
  };

  // 客数API呼び出し
  const kyakusuRequest = async (tenpoCode: string, date: string[]) => {
    // setLoading(true);
    let param: any = {
      "api-name": "kyakusu-summary",
      store_code: tenpoCode,
    };
    const targetDate = baseAxis === 0 ? date : firstDate;
    return apiRequest(param, targetDate, yearOnYear, baseAxis);
  };

  // モバ空API呼び出し
  const mobakuRequest = async (mobakuCode: string[], date: string[]) => {
    // setLoading(true);
    const isMarketArea = mobakuCode[0] === "market-area";
    let res: any;
    let param: any;
    if (isMarketArea) {
      res = await getFirestoreDocData(
        "market_area",
        getMarketAreaDocId(graphFirstItem)
      );
      param = {
        "api-name": "optional-area",
        geometry: JSON.parse(res.feature).geometry,
        class: "1",
      };
    } else {
      param = {
        "api-name": "mobaku-summary",
        class: "1",
        mesh_code: mobakuCode,
      };
    }
    const targetDate = baseAxis === 0 ? date : firstDate;
    return apiRequest(param, targetDate, yearOnYear, baseAxis);
  };

  // APIのレスポンスから客数データを抽出
  const getKyakusuArr = (apiRes: any, isYearOnYear: boolean) => {
    const tempArr =
      apiRes.data.dataList !== null && !isYearOnYear
        ? JSON.parse(apiRes.data.dataList[0]).customer_performance_list
        : JSON.parse(apiRes.data.dataList[0])
            .customer_performance_last_year_list;
    if (tempArr.length !== 0) {
      const kyakusuArr = tempArr.map((obj: any) => {
        let timeValue;
        let customerCount = Math.round(obj.customer_count);
        if (baseAxis === 0) {
          // HHの形に整形
          timeValue = Number(obj.target_datetime.substr(0, 2)).toString();
        } else if (baseAxis === 1) {
          if (obj.target_datetime !== "-1") {
            // MM/DDの形に整形
            const tempValue = obj.target_datetime.substr(4, 4).toString();
            const str1 = Number(tempValue.slice(0, 2));
            const str2 = Number(tempValue.slice(2));
            timeValue = str1 + "/\r\n" + str2;
          } else {
            // MIS前年日がない未来日の場合日付が"-1"で返ってくる
            timeValue = "-1";
          }
        } else if (baseAxis === 2) {
          timeValue =
            obj.target_datetime.slice(4, 6).replace(/^0+/, "") +
            "/" +
            obj.target_datetime.slice(6, 8).replace(/^0+/, "") +
            "週";
        } else if (baseAxis === 3) {
          timeValue = obj.target_datetime.slice(4, 6).replace(/^0+/, "") + "月";
          if (timeValue === "1月") {
            timeValue = obj.target_datetime.slice(0, 4) + "年" + timeValue;
          }
        }
        const tempObj = {
          time: timeValue,
          customerCount: customerCount,
        };
        return tempObj;
      });
      // 月軸のAPIレスポンスが逆に入っているバグの暫定対応
      if (baseAxis === 3 && kyakusuArr.length === 12) {
        let tempKyakusuArr = kyakusuArr.map((obj: any) => {
          return obj.customerCount;
        });
        tempKyakusuArr.reverse();
        for (let i = 0; i < 12; i++) {
          kyakusuArr[i].customerCount = tempKyakusuArr[i];
        }
      }
      return kyakusuArr;
    } else {
      const tempObj = {
        time: "-1",
        population: "-",
      };
      let length = 24;
      if (baseAxis === 1) {
        length = 29;
      } else if (baseAxis === 2 || baseAxis === 3) {
        length = 12;
      }
      const kyakusuArr = [];
      for (let i = 0; i < length; i++) {
        kyakusuArr.push(tempObj);
      }
      return kyakusuArr;
    }
  };

  // APIのレスポンスからモバ空データを抽出
  const getMobakuArr = (apiRes: any, isYearOnYear: boolean) => {
    const tempArr =
      apiRes.data.dataList !== null && !isYearOnYear
        ? JSON.parse(apiRes.data.dataList[0]).population_movement_list
        : JSON.parse(apiRes.data.dataList[0])
            .population_movement_last_year_list;
    if (tempArr.length !== 0) {
      const mobakuArr = tempArr.map((obj: any) => {
        let timeValue;
        let population = obj.population;
        if (baseAxis === 0) {
          // HHの形に整形
          timeValue = Number(obj.target_datetime.substr(0, 2)).toString();
        } else if (baseAxis === 1) {
          if (obj.target_datetime !== "-1") {
            // MM/DDの形に整形
            const tempValue = obj.target_datetime.substr(4, 4).toString();
            const str1 = Number(tempValue.slice(0, 2)).toString();
            const str2 = Number(tempValue.slice(2)).toString();
            timeValue = str1 + "/\r\n" + str2;
          } else {
            // MIS前年日がない未来日の場合日付が"-1"で返ってくる
            timeValue = "-1";
          }
        } else if (baseAxis === 2) {
          timeValue =
            obj.target_datetime.slice(4, 6).replace(/^0+/, "") +
            "/" +
            obj.target_datetime.slice(6, 8).replace(/^0+/, "") +
            "週";
        } else if (baseAxis === 3) {
          timeValue = obj.target_datetime.slice(4, 6).replace(/^0+/, "") + "月";
          if (timeValue === "1月") {
            timeValue = obj.target_datetime.slice(0, 4) + "年" + timeValue;
          }
        }
        const tempObj = {
          time: timeValue,
          population: population,
        };
        return tempObj;
      });
      // 月軸のAPIレスポンスが逆に入っているバグの暫定対応
      if (baseAxis === 3 && mobakuArr.length === 12) {
        let tempPopArr = mobakuArr.map((obj: any) => {
          return obj.population;
        });
        tempPopArr.reverse();
        for (let i = 0; i < 12; i++) {
          mobakuArr[i].population = tempPopArr[i];
        }
      }
      return mobakuArr;
    } else {
      const tempObj = {
        time: "-1",
        population: "-",
      };
      let length = 24;
      if (baseAxis === 1) {
        length = 29;
      } else if (baseAxis === 2 || baseAxis === 3) {
        length = 12;
      }
      const mobakuArr = [];
      for (let i = 0; i < length; i++) {
        mobakuArr.push(tempObj);
      }
      return mobakuArr;
    }
  };

  // 客数の当年グラフ用データを追加
  const addKyakusuData = (arr: any, order: string) => {
    let dataFormat: any[] = [];
    if (axisChange.current) {
      // 軸の変更時はデータフォーマットを初期化
      dataFormat = initData(arr.length);
      axisChange.current = false;
    } else {
      dataFormat = tempData.current;
    }

    arr.forEach((value: any, index: number) => {
      dataFormat[index].date = value?.time;
      // 客数当年実数を追加
      dataFormat[index].firstTenpoRealNumForGraph = value.customerCount;
      dataFormat[index].firstTenpoRealNumForTable = new Intl.NumberFormat(
        "en"
      ).format(value.customerCount);
      // 当年コンバージョンレートを追加
      dataFormat[index].conversionRateForGraph =
        dataFormat[index]?.firstTenpoRealNumForGraph !== 0 &&
        dataFormat[index]?.secondMobakuRealNumForGraph !== 0
          ? roundDecimalForGraph(
              dataFormat[index]?.firstTenpoRealNumForGraph /
                dataFormat[index]?.secondMobakuRealNumForGraph
            )
          : "-";
      dataFormat[index].conversionRateForTable =
        dataFormat[index]?.firstTenpoRealNumForGraph !== 0 &&
        dataFormat[index]?.secondMobakuRealNumForGraph !== 0
          ? roundDecimalForTable(
              dataFormat[index]?.firstTenpoRealNumForGraph /
                dataFormat[index]?.secondMobakuRealNumForGraph
            )
          : "-";
    });
    tempData.current = dataFormat;
    return dataFormat;
  };

  // 客数の前年比グラフ用データを追加
  const addKyakusuDataLastYear = (arr: any, order: string) => {
    let dataFormat: any[] = [];
    if (axisChange.current) {
      // 軸の変更時はデータフォーマットを初期化
      dataFormat = initData(arr.length);
      axisChange.current = false;
    } else {
      dataFormat = tempData.current;
    }
    arr.forEach((value: any, index: number) => {
      // 客数前年実数を追加
      dataFormat[index].firstTenpoRealNumLastYearForGraph =
        value?.time !== "-1" ? value.customerCount : "-";
      dataFormat[index].firstTenpoRealNumLastYearForTable =
        value?.time !== "-1"
          ? new Intl.NumberFormat("en").format(value.customerCount)
          : "-";

      tempLastYearDiffNum =
        dataFormat[index]?.firstTenpoRealNumForGraph -
        dataFormat[index]?.firstTenpoRealNumLastYearForGraph;
      // 客数前年実数差を追加
      dataFormat[index].firstDiffLastYear = tempLastYearDiffNum
        ? new Intl.NumberFormat("en").format(tempLastYearDiffNum)
        : "-";
      // 客数前年比を追加
      dataFormat[index].firstTenpoRatioLastYear =
        dataFormat[index]?.firstTenpoRealNumForGraph !== 0 &&
        dataFormat[index]?.firstTenpoRealNumLastYearForGraph !== 0
          ? Math.round(
              (dataFormat[index]?.firstTenpoRealNumForGraph /
                dataFormat[index]?.firstTenpoRealNumLastYearForGraph) *
                100
            ).toLocaleString()
          : "-";
      // 客数前年比差を追加
      dataFormat[index].ratioDiff =
        dataFormat[index].firstTenpoRatioLastYear -
        dataFormat[index].secondTenpoRatioLastYear;

      if (
        dataFormat[index]?.firstTenpoRealNumLastYearForGraph !== 0 &&
        dataFormat[index]?.firstTenpoRealNumLastYearForGraph !== "-" &&
        dataFormat[index]?.secondMobakuRealNumLastYearForGraph !== 0 &&
        dataFormat[index]?.secondMobakuRealNumLastYearForGraph !== "-"
      ) {
        // 前年コンバージョンレートを追加
        dataFormat[index].conversionRateLastYearForGraph = roundDecimalForGraph(
          dataFormat[index]?.firstTenpoRealNumLastYearForGraph /
            dataFormat[index]?.secondMobakuRealNumLastYearForGraph
        );
        dataFormat[index].conversionRateLastYearForTable = roundDecimalForTable(
          dataFormat[index]?.firstTenpoRealNumLastYearForGraph /
            dataFormat[index]?.secondMobakuRealNumLastYearForGraph
        );
        // コンバージョンレートの前年差を追加
        dataFormat[index].ratioDiff = (
          Math.round(
            (dataFormat[index].conversionRateForGraph -
              dataFormat[index].conversionRateLastYearForGraph) *
              100
          ) / 100
        ).toFixed(1);
      } else {
        // 前年コンバージョンレートを追加
        dataFormat[index].conversionRateLastYearForGraph = "-";
        dataFormat[index].conversionRateLastYearForTable = "-";
        // コンバージョンレートの前年差を追加
        dataFormat[index].ratioDiff = "-";
      }
    });
    tempData.current = dataFormat;
    return dataFormat;
  };

  // モバ空の当年グラフ用データを追加
  const addMobakuData = (arr: any, order: string) => {
    let dataFormat: any[] = [];
    if (axisChange.current) {
      // 軸の変更時はデータフォーマットを初期化
      dataFormat = initData(arr.length);
      axisChange.current = false;
    } else {
      dataFormat = tempData.current;
    }
    arr.forEach((value: any, index: number) => {
      dataFormat[index].date = value?.time;
      // モバ空当年実数を追加
      dataFormat[index].secondMobakuRealNumForGraph = value?.population;
      dataFormat[index].secondMobakuRealNumForTable = (
        value?.population / 1000
      ).toFixed(1);
      // 当年コンバージョンレートを追加
      dataFormat[index].conversionRateForGraph =
        dataFormat[index]?.firstTenpoRealNumForGraph !== 0 &&
        dataFormat[index]?.secondMobakuRealNumForGraph !== 0
          ? roundDecimalForGraph(
              dataFormat[index]?.firstTenpoRealNumForGraph /
                dataFormat[index]?.secondMobakuRealNumForGraph
            )
          : "-";
      dataFormat[index].conversionRateForTable =
        dataFormat[index]?.firstTenpoRealNumForGraph !== 0 &&
        dataFormat[index]?.secondMobakuRealNumForGraph !== 0
          ? roundDecimalForTable(
              dataFormat[index]?.firstTenpoRealNumForGraph /
                dataFormat[index]?.secondMobakuRealNumForGraph
            )
          : "-";
    });
    tempData.current = dataFormat;
    return dataFormat;
  };

  // モバ空の前年グラフ用データを追加
  const addMobakuDataLastYear = (arr: any, order: string) => {
    let dataFormat: any[] = [];
    if (axisChange.current) {
      // 軸の変更時はデータフォーマットを初期化
      dataFormat = initData(arr.length);
      axisChange.current = false;
    } else {
      dataFormat = tempData.current;
    }
    arr.forEach((value: any, index: number) => {
      // モバ空前年実数を追加
      dataFormat[index].secondMobakuRealNumLastYearForGraph =
        value?.time !== "-1" ? value.population : "-";
      dataFormat[index].secondMobakuRealNumLastYearForTable =
        value?.time !== "-1" ? (value.population / 1000).toFixed(1) : "-";

      tempLastYearDiffNum =
        dataFormat[index]?.secondMobakuRealNumForGraph -
        dataFormat[index]?.secondMobakuRealNumLastYearForGraph;
      // モバ空前年実数差を追加
      dataFormat[index].secondDiffLastYear = tempLastYearDiffNum
        ? (tempLastYearDiffNum / 1000).toFixed(1)
        : "-";
      // モバ空前年比を追加
      dataFormat[index].secondMobakuRatioLastYear =
        dataFormat[index]?.secondMobakuRealNumForGraph !== 0 &&
        dataFormat[index]?.secondMobakuRealNumLastYearForGraph !== 0
          ? Math.round(
              (dataFormat[index]?.secondMobakuRealNumForGraph /
                dataFormat[index]?.secondMobakuRealNumLastYearForGraph) *
                100
            )
          : "-";

      // モバ空前年比差を追加
      dataFormat[index].ratioDiff = (
        dataFormat[index].firstMobakuRatioLastYear -
        dataFormat[index].secondMobakuRatioLastYear
      ).toFixed(1);

      if (
        dataFormat[index]?.firstTenpoRealNumLastYearForGraph !== 0 &&
        dataFormat[index]?.firstTenpoRealNumLastYearForGraph !== "-" &&
        dataFormat[index]?.secondMobakuRealNumLastYearForGraph !== 0 &&
        dataFormat[index]?.secondMobakuRealNumLastYearForGraph !== "-"
      ) {
        // 前年コンバージョンレートを追加
        dataFormat[index].conversionRateLastYearForGraph = roundDecimalForGraph(
          dataFormat[index]?.firstTenpoRealNumLastYearForGraph /
            dataFormat[index]?.secondMobakuRealNumLastYearForGraph
        );
        dataFormat[index].conversionRateLastYearForTable = roundDecimalForTable(
          dataFormat[index]?.firstTenpoRealNumLastYearForGraph /
            dataFormat[index]?.secondMobakuRealNumLastYearForGraph
        );
        // コンバージョンレー前年差を追加
        dataFormat[index].ratioDiff = (
          Math.round(
            (dataFormat[index].conversionRateForGraph -
              dataFormat[index].conversionRateLastYearForGraph) *
              100
          ) / 100
        ).toFixed(1);
      } else {
        dataFormat[index].conversionRateLastYearForGraph = "-";
        dataFormat[index].conversionRateLastYearForTable = "-";
        // コンバージョンレート前年差を追加
        dataFormat[index].ratioDiff = "-";
      }
    });
    tempData.current = dataFormat;
    return dataFormat;
  };

  // 客数のグラフ・テーブルデータを初期化
  const initKyakusuData = (order: string) => {
    tempData.current?.forEach((data: any, index: number) => {
      if (order === "first") {
        data.firstTenpoRealNumForGraph =
          data.hasOwnProperty("firstTenpoRealNumForGraph") && 0;
        data.firstTenpoRealNumForTable =
          data.hasOwnProperty("firstTenpoRealNumForTable") && "";
        data.firstTenpoRealNumLastYearForGraph =
          data.hasOwnProperty("firstTenpoRealNumLastYearForGraph") && 0;
        data.firstTenpoRealNumLastYearForTable =
          data.hasOwnProperty("firstTenpoRealNumLastYearForTable") && "";
        data.firstDiffLastYear = data.hasOwnProperty("firstDiffLastYear") && 0;
        data.firstTenpoRatioLastYear =
          data.hasOwnProperty("firstTenpoRatioLastYear") && 0;
      } else {
        data.secondTenpoRealNumForGraph =
          data.hasOwnProperty("secondTenpoRealNumForGraph") && 0;
        data.secondTenpoRealNumForTable =
          data.hasOwnProperty("secondTenpoRealNumForTable") && "";
        data.secondTenpoRealNumLastYearForGraph =
          data.hasOwnProperty("secondTenpoRealNumLastYearForGraph") && 0;
        data.secondTenpoRealNumLastYearForTable =
          data.hasOwnProperty("secondTenpoRealNumLastYearForTable") && "";
        data.secondDiffLastYear =
          data.hasOwnProperty("secondDiffLastYear") && 0;
        data.secondTenpoRatioLastYear =
          data.hasOwnProperty("secondTenpoRatioLastYear") && 0;
      }
      data.diffForGraph = data.hasOwnProperty("diffForGraph") && 0;
      data.diffForTable = data.hasOwnProperty("diffForTable") && "";
      data.conversionRateForGraph =
        data.hasOwnProperty("conversionRateForGraph") && 0;
      data.conversionRateForTable =
        data.hasOwnProperty("conversionRateForTable") && "";
      data.ratioDiff = data.hasOwnProperty("ratioDiff") && 0;
      data.conversionRateLastYearForGraph =
        data.hasOwnProperty("conversionRateLastYearForGraph") && 0;
      data.conversionRateLastYearForTable =
        data.hasOwnProperty("conversionRateLastYearForTable") && "";
    });
    setData(tempData.current);
  };

  // モバ空のグラフ・テーブルデータを初期化
  const initMobakuData = (order: string) => {
    tempData.current?.forEach((data: any, index: number) => {
      if (order === "first") {
        data.firstMobakuRealNumForGraph =
          data.hasOwnProperty("firstMobakuRealNumForGraph") && 0;
        data.firstMobakuRealNumForTable =
          data.hasOwnProperty("firstMobakuRealNumForTable") && "";
        data.firstMobakuRealNumLastYearForGraph =
          data.hasOwnProperty("firstMobakuRealNumLastYearForGraph") && 0;
        data.firstMobakuRealNumLastYearForTable =
          data.hasOwnProperty("firstMobakuRealNumLastYearForTable") && "";
        data.firstDiffLastYear = data.hasOwnProperty("firstDiffLastYear") && 0;
        data.firstMobakuRatioLastYear =
          data.hasOwnProperty("firstMobakuRatioLastYear") && 0;
      } else {
        data.secondMobakuRealNumForGraph =
          data.hasOwnProperty("secondMobakuRealNumForGraph") && 0;
        data.secondMobakuRealNumForTable =
          data.hasOwnProperty("secondMobakuRealNumForTable") && "";
        data.secondMobakuRealNumLastYearForGraph =
          data.hasOwnProperty("secondMobakuRealNumLastYearForGraph") && 0;
        data.secondMobakuRealNumLastYearForTable =
          data.hasOwnProperty("secondMobakuRealNumLastYearForTable") && "";
        data.secondDiffLastYear =
          data.hasOwnProperty("secondDiffLastYear") && 0;
        data.secondMobakuRatioLastYear =
          data.hasOwnProperty("secondMobakuRatioLastYear") && 0;
      }
      data.diffForGraph = data.hasOwnProperty("diffForGraph") && 0;
      data.diffForTable = data.hasOwnProperty("diffForTable") && "";
      data.conversionRateForGraph =
        data.hasOwnProperty("conversionRateForGraph") && 0;
      data.conversionRateForTable =
        data.hasOwnProperty("conversionRateForTable") && "";
      data.ratioDiff = data.hasOwnProperty("ratioDiff") && 0;
      data.conversionRateLastYearForGraph =
        data.hasOwnProperty("conversionRateLastYearForGraph") && 0;
      data.conversionRateLastYearForTable =
        data.hasOwnProperty("conversionRateLastYearForTable") && "";
    });
    setData(tempData.current);
  };

  // 当年データのグラフ設定
  const setGraphItemCurrentYear = (graphData: any) => {
    if (!isDoubleUpdate.current) {
      setData(graphData);
      // 当年のグラフコンポーネントに切り替え
      setYearOnYearMode(false);
      setLoading(false);
      // 初期店舗選択時の処理
      if (firstView) {
        const mobakuItem =
          graphSecondItem[0] === "market-area"
            ? spareMobakuItem
            : graphSecondItem;
        dispatch(
          updateFirstTenpoItem({
            firstTenpoItem: graphFirstItem,
          })
        );
        dispatch(
          updateFirstMobakuItem({
            firstMobakuItem: mobakuItem,
          })
        );
        dispatch(
          updateSelectingFirstMobakuItem({
            selectingFirstMobakuItem: mobakuItem,
          })
        );
        setMobakuName(graphSecondItem[0]);
      }
    } else {
      isDoubleUpdate.current = false;
    }
  };

  const setMobakuName = async (meshCode: string) => {
    let mobakuName: string;
    if (meshCode !== "market-area") {
      await getFirestoreData(
        "mesh_500m",
        "attribution.key_code",
        meshCode
      ).then((response: any) => {
        mobakuName = response.attribution.center_address;
        if (mobakuName === undefined || mobakuName === "") {
          mobakuName = hankaku2Zenkaku(meshCode);
        }
        dispatch(
          updateCvrMobakuName({
            cvrMobakuName: mobakuName,
          })
        );
      });
    } else {
      mobakuName = "店舗の範囲";
      dispatch(
        updateCvrMobakuName({
          cvrMobakuName: mobakuName,
        })
      );
    }
  };

  // 前年データのグラフ設定
  const setGraphItemLastYear = (graphData: any) => {
    if (!isDoubleUpdate.current) {
      setData(graphData);
      // 前年のグラフコンポーネントに切り替え
      setYearOnYearMode(true);
      setLoading(false);
    } else {
      isDoubleUpdate.current = false;
    }
  };

  // 軸モード、当年/前年モードの切り替え
  const handleYearAndAxisMode = () => {
    setTargetTenpo(true);
    setTargetMobaku(true);
    // logFlag.current && displayItemLog();
    logFlag.current = true;
  };

  const isSingleSelect = () => {
    if (graphFirstItem === "" || graphSecondItem.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  function checkMultiSelectDate(date: string[], axis: number) {
    if (date.length !== 1 && axis !== 0) {
      let modeCaption = "";
      if (axis === 1) {
        modeCaption = "日別比較";
      } else if (axis === 2) {
        modeCaption = "週別比較";
      } else if (axis === 3) {
        modeCaption = "月別比較";
      }
      setErrorMessage(
        <div>
          {modeCaption}では複数日付を選択できません。
          <br />
          日付を再度選択してください。
        </div>
      );
      setErrorDialog(true);
      // ローディングアイコン表示
      setLoading(false);
      return true;
    }
    return false;
  }

  const handleRadioClick = (event: any, axis: number) => {
    if (checkMultiSelectDate(firstDate, axis)) {
      setBaseAxis(0);
      return;
    } else {
      axisChange.current = true;
      setSecondDate(firstDate);
      setRadioAxisMode(event.target.value);
      setBaseAxis(axis);
    }
  };

  // CVRの小数点２桁目を四捨五入して%表示
  const roundDecimalForGraph = (value: number) => {
    return Math.round(value * 100 * 100) / 100;
  };

  // CVRの小数点２桁目を四捨五入して小数点1桁までを%表示（toFixedで文字型になる）
  const roundDecimalForTable = (value: number) => {
    return (Math.round(value * 100 * 10) / 10).toFixed(1);
  };

  const timeCompFlagFunc = () => {
    setTimeCompFlag(false);
  };

  return (
    <>
      {loading && (
        <CircularProgress className={classes.circularProgress} size={120} />
      )}
      <div
        className={classes.overLayer}
        style={
          loading
            ? { backgroundColor: "#CCC", opacity: "0.5", pointerEvents: "none" }
            : {}
        }
      >
        {errorDialog && (
          <div className={classes.backLayer}>
            <div className={classes.errorDialog}>
              <div className={classes.errorDialogCloseButton}>
                <IconButton
                  onClick={() => setErrorDialog(false)}
                  size={"small"}
                >
                  <HighlightOffIcon />
                </IconButton>
              </div>
              <Typography variant="h6" style={{ color: "red" }}>
                {errorMessage}
              </Typography>
            </div>
          </div>
        )}
        <div className={classes.graphBox}>
          {/* 日付選択・天気情報 */}
          <Grid container>
            <Grid item xs={9}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={2}>
                  <Paper className={classes.titlePaperFirst}>分析対象</Paper>
                </Grid>
                <Grid item xs={3}>
                  <MultiDatePicker
                    dateFunc={(dates: string[]) => {
                      setFirstDate(dates);
                    }}
                    oneYearWeather={firstOneYearWeather}
                    holiday={holiday}
                  />
                </Grid>
                <Grid item xs={1}>
                  <GraphWeather dayWeather={firstDayWeather}></GraphWeather>
                </Grid>
                {/* 時間軸の時以外は表示しない */}
                {baseAxis === 0 && (
                  <>
                    <Grid item xs={2}>
                      <Paper className={classes.titlePaperSecond}>
                        比較対象
                      </Paper>
                    </Grid>
                    <Grid item xs={3}>
                      <MultiDatePicker
                        dateFunc={(dates: string[]) => {
                          setSecondDate(dates);
                        }}
                        oneYearWeather={secondOneYearWeather}
                        holiday={holiday}
                        firstDate={firstDate}
                        timeCompFlag={timeCompFlag}
                        timeCompFlagFunc={timeCompFlagFunc}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <GraphWeather
                        dayWeather={secondDayWeather}
                      ></GraphWeather>
                    </Grid>
                  </>
                )}
              </Grid>
              {/* メッシュ・店舗選択ボタン */}
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ color: "#0000FF" }}>
                    ▼下のボタンからCVRを確認したい店舗を選んでください
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ color: "#00B050" }}>
                    ▼下のボタンからCVRの算出に使用するエリアを選んでください
                  </Typography>
                </Grid>
              </Grid>
              {/* メッシュ・店舗選択ボタン */}
              <Grid container alignItems="center" spacing={2}>
                <ThemeProvider theme={blueTheme}>
                  <Grid item xs={4}>
                    <SelectStoreMenu
                      itemOrder={"first"}
                      itemValue={graphFirstItem}
                      graphType={"cvr"}
                      openTenpoSelect={openTenpoSelect}
                      handleOpenTenpoSelect={handleOpenTenpoSelect}
                      firstView={firstView}
                      handleFirstView={handleFirstView}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                </ThemeProvider>
                <Grid item xs={4}>
                  <SelectMobakuMenu
                    itemOrder={"second"}
                    mobakuItem={graphSecondItem}
                    graphType={"cvr"}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </Grid>
            {/* 軸・モード切り替えラジオボタン */}
            <Grid item xs={3}>
              <Grid container justify="flex-end">
                <Grid item xs={5}>
                  <ThemeProvider theme={radioTheme}>
                    <FormControl>
                      <FormLabel id="comparisonGroupLabel"></FormLabel>
                      <RadioGroup
                        aria-labelledby="comparisonGroupLabel"
                        defaultValue="timeComp"
                        name="comparisonGroup"
                        value={radioAxisMode}
                      >
                        <FormControlLabel
                          value="timeComp"
                          control={
                            <Radio
                              size="small"
                              onClick={(event) => {
                                setTimeCompFlag(true);
                                handleRadioClick(event, 0);
                              }}
                            />
                          }
                          label={
                            <Typography variant="caption">
                              時間帯別比較
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="dayComp"
                          control={
                            <Radio
                              size="small"
                              onClick={(event) => {
                                handleRadioClick(event, 1);
                              }}
                            />
                          }
                          label={
                            <Typography variant="caption">日別比較</Typography>
                          }
                        />
                        <FormControlLabel
                          value="weekComp"
                          control={
                            <Radio
                              size="small"
                              onClick={(event) => {
                                handleRadioClick(event, 2);
                              }}
                            />
                          }
                          label={
                            <Typography variant="caption">週比較</Typography>
                          }
                        />
                        <FormControlLabel
                          value="monthComp"
                          control={
                            <Radio
                              size="small"
                              onClick={(event) => {
                                handleRadioClick(event, 3);
                              }}
                            />
                          }
                          label={
                            <Typography variant="caption">月比較</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={5}>
                  <ThemeProvider theme={radioTheme}>
                    <FormControl>
                      <FormLabel id="comparisonModeLabel"></FormLabel>
                      <RadioGroup
                        aria-labelledby="comparisonModeLabel"
                        defaultValue="thisComp"
                        name="comparisonMode"
                        value={radioYearMode}
                      >
                        <FormControlLabel
                          value="thisComp"
                          control={
                            <Radio
                              size="small"
                              onClick={() => {
                                setYearOnYear(false);
                                setRadioYearMode("thisComp");
                              }}
                            />
                          }
                          label={
                            <Typography variant="caption">当年ﾓｰﾄﾞ</Typography>
                          }
                        />
                        <FormControlLabel
                          value="preComp"
                          control={
                            <Radio
                              size="small"
                              onClick={() => {
                                setYearOnYear(true);
                                setRadioYearMode("preComp");
                              }}
                            />
                          }
                          label={
                            <Typography variant="caption">前年ﾓｰﾄﾞ</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {yearOnYearMode ? (
            <GraphContentZennenhiCVR
              data={data}
              baseAxis={baseAxis}
              firstDate={firstDate[0]}
              today={initDate.current[0]}
            />
          ) : (
            <GraphContentCVR
              data={data}
              initGraph={initGraph}
              baseAxis={baseAxis}
              firstDate={firstDate[0]}
              today={initDate.current[0]}
            />
          )}
          {initGraph &&
            (yearOnYearMode ? (
              <Grid container>
                <Grid item xs={12}>
                  <TableContentZennenhiCVR
                    data={data}
                    tableOpen={tableOpen}
                    baseAxis={baseAxis}
                    firstDate={firstDate[0]}
                    holiday={holiday}
                    today={initDate.current[0]}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <TableContentCVR
                    data={data}
                    baseAxis={baseAxis}
                    firstDate={firstDate[0]}
                    holiday={holiday}
                    today={initDate.current[0]}
                  />
                </Grid>
              </Grid>
            ))}
          {yearOnYearMode &&
            (tableOpen ? (
              <IconButton onClick={() => setTableOpen("")}>
                <AddCircleOutlineIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton onClick={() => setTableOpen("none")}>
                <RemoveCircleOutlineIcon fontSize="small" />
              </IconButton>
            ))}
        </div>
      </div>
    </>
  );
};

export default CvrGraphAnalysis;
