import axios from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "./firebase";
import {
  login,
  logout,
  setIsDeviceType,
  updateImageToken,
  updateNotification,
  selectIsIos,
} from "./features/userSlice";
import {
  getFirestoreDocData,
  snapshotFirestoreDocData,
} from "./apis/firestoreAction";
import { useDeviceType } from "./apis/util";
import { postRequest, getRequest } from "./apis/axiosAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import { userLogging } from "./apis/userLog";
import { initializeExistingSejStores } from "./data/existingSejStores";
import { initializeEmployee } from "./data/employeeOfc";
import { JOB_CATEGORY_CODE_OFC } from "./apis/privilege";
import firebase from "firebase/app";
import { getFirebaseAuthToken } from "./firebase";
import { initializeInnerData } from "./components/Maps/layers/conflictStoreLayer";
import {
  getInitTenpoCode,
  setInitTenpoCode,
  setFixedFlg,
  setFirstFlg,
} from "./components/Header/Header";
// import { setOpenGraphAnalysis, setStoreCodeGraphAnalysis } from "./components/OpView/GraphAnalysis";
import {
  setOneVisionId,
  setLastOneVisionId,
} from "./components/OneVision/Register/OneVision";
import { setTenpoCheckId } from "./components/TenpoCheck/Register/TenpoCheck";
import { getTenpoFirestoreData } from "./components/TenpoCheck/Register/TenpoCheckTableDataOperation";
import { openGraphsShortCut } from "./components/OpView/SelectGraph";
import { setStoreCodeCvrGraphAnalysis } from "./components/OpView/CvrGraphAnalysis";

interface pathState {
  urlPath: string;
  state: string;
}

let mapsShotcutValue: string = "";

// URLのパス及びパラメータの調整を行う
const setUrlPath = (): pathState => {
  const url: URL = new URL(window.location.href);
  // URLパス対応(ログイン時とトップの場合はhomeへ進める)
  const path =
    url.pathname === "/login" || url.pathname === "/" ? "home" : url.pathname;
  const initial = "initial";
  const maps = "maps";

  // パスが「maps」の場合特殊対応を行う
  if (url.pathname === maps || url.pathname === "/" + maps) {
    window.history.replaceState("", "", "/");
    const mapVaule: pathState = { urlPath: maps, state: initial };
    return mapVaule;
  }
  // 本番環境の場合OPViewを向ける
  // 「maps」の場合の特殊対応を行わない場合うまくいかないためこの順序としている
  if (
    process.env.REACT_APP_MODE === "production" ||
    process.env.REACT_APP_MODE === "production-staging"
  ) {
    const mapVaule: pathState = { urlPath: maps, state: "start-mode" };
    return mapVaule;
  }
  // URLパラメータよりトークン項目を削除する
  const params: URLSearchParams = url.searchParams;
  params.delete("token");
  let parameter = "";
  params.forEach(function (value, key) {
    // ショートカット処理START
    // 店舗確認表 新規作成
    if (
      url.searchParams.toString().includes("tenpo-check-newversion") &&
      key === "tenpocode"
    ) {
      setFirstFlg(true);
      setFixedFlg(false);
      setInitTenpoCode(value);
      parameter = "";
    }
    // 店舗確認表 最新の店舗別の画面へ移動
    else if (
      url.searchParams.toString().includes("tenpo-check-latest") &&
      key === "tenpocode"
    ) {
      getTenpoFirestoreData(value).then((res: any) => {
        // 検索結果あり
        if (res) {
          //  ofcデータあり
          if (res.attribution.tenpo_check.ofc) {
            //  OFC登録データあり
            if (res.attribution.tenpo_check.ofc.last_time_id) {
              setInitTenpoCode(value);
              setTenpoCheckId(res.attribution.tenpo_check.ofc.last_time_id);
              setFixedFlg(true);
            }
            //  IDが空の場合（登録データなし、もしくはSOFCの可能性あり）
            else {
              setInitTenpoCode(value);
              setTenpoCheckId(res.attribution.tenpo_check.dm.last_time_id);
              setFixedFlg(true);
            }
          }
        }
      });
      parameter = "";
    }
    // ONEVISION 新規作成
    else if (
      url.searchParams.toString().includes("onevision-newversion") &&
      key === "tenpocode"
    ) {
      setFirstFlg(true);
      setFixedFlg(false);
      setInitTenpoCode(value);
      parameter = "";
    }
    // ONEVISION  最新の店舗別の画面へ移動
    else if (
      url.searchParams.toString().includes("onevision-latest") &&
      key === "tenpocode"
    ) {
      getTenpoFirestoreData(value).then((res: any) => {
        if (res.attribution.one_vision.ofc.ofc) {
          setOneVisionId(res.attribution.one_vision.ofc.last_time_id);
        } else {
          setOneVisionId(res.attribution.one_vision.last_time_id);
        }
      });
      parameter = "";
    }
    // OPVIEW
    else if (
      url.searchParams.toString().includes("opviewgraph") &&
      key === "tenpocode"
    ) {
      const val: pathState = { urlPath: maps, state: initial };
      parameter = "";
      return val;
    }
    // ショートカット処理END
    else {
      parameter = key + "=" + value + "&";
    }
  });
  if (parameter !== "") {
    parameter = parameter.slice(0, -1);
    const val: pathState = {
      urlPath: path + "?" + parameter,
      state: initial,
    };
    return val;
  } else {
    const val: pathState = { urlPath: path, state: initial };
    return val;
  }
};

const startMenu: pathState = setUrlPath();

// 初期処理のハンドリングに用いる
let isFinishUserInfo: boolean = false;
let isTenpoMaster: boolean = false;

const App: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [isAnotherLoginLoading, setIsAnotherLoginLoading] =
    React.useState(false);
  const history = useHistory();
  // デバイスタイプの取得
  const isiOS = useSelector(selectIsIos);
  // デバイス・OSの判定を呼び出し
  useDeviceType();
  useEffect(() => {
    // ショートカット処理 START
    const url: URL = new URL(window.location.href);
    // パラメーター付きのrootパスの場合
    if (url.pathname === "/" && url.searchParams.toString() !== "") {
      // 店舗確認表 新規作成
      if (url.searchParams.toString().includes("tenpo-check-newversion")) {
        window.location.href = url.pathname + "tenpo-check?" + url.searchParams;
        return;
      }
      // 店舗確認表 最新の店舗別の画面へ移動
      else if (url.searchParams.toString().includes("tenpo-check-latest")) {
        window.location.href = url.pathname + "tenpo-check?" + url.searchParams;
        return;
      }
      // ONEVISION 新規作成
      else if (url.searchParams.toString().includes("onevision-newversion")) {
        window.location.href = url.pathname + "one-vision?" + url.searchParams;
        return;
      }
      // ONEVISION  最新の店舗別の画面へ移動
      else if (url.searchParams.toString().includes("onevision-latest")) {
        window.location.href = url.pathname + "one-vision?" + url.searchParams;
        return;
      }
      // OPView
      else if (url.searchParams.toString().includes("opviewgraph")) {
        url.searchParams.forEach(function (value: string, key: string) {
          if (key === "tenpocode") {
            mapsShotcutValue = value;
          }
        });
      }
    }
    const unSub = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // 初期処理
        initialOperation(authUser);
      } else {
        if (process.env.REACT_APP_MODE === "develop") {
          // 開発モードのみログイン画面へ遷移可能とする
          props.history.push("login");
        } else {
          // Cookie経由認証
          callCookieAuthentication()
            .then((result) => {
              if (result.data.dataList[0] === "") {
                // セッションより認証トークンを取得できなかった場合
                // dispatch(logout());
                // dispatch(clearTenpoCheckCondition());
                // dispatch(clearTenpoStatus());
                // window.location.href = "https://sej-sso-ms365.an.r.appspot.com/";
              } else {
                // セッションより認証トークンを取得できた場合
                // カスタム認証を行う
                firebase
                  .auth()
                  .signInWithCustomToken(result.data.dataList[0])
                  .then(() => {
                    console.log("custom");
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    });
    return () => {
      unSub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.history]);

  // 初期処理
  const initialOperation = async (authUser: any): Promise<void> => {
    // ユーザーデータの取得
    setIsAnotherLoginLoading(true);
    userLogging("共通", "ログイン処理", "");
    // Cookie設定・更新
    callCustomFirebaseAuthentication();
    //callCookieUpdate();
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: true,
      })
    );

    // 初期処理の終了フロー
    const setTenpoMasterFirstOperationFinish = () => {
      isTenpoMaster = true;
      setFirstOperationFinish();
    };

    // 初期処理の終了フロー
    const setFirstOperationFinish = () => {
      let token: string = "";
      if (isFinishUserInfo && isTenpoMaster) {
        // GoogleCloudStorage取得用のトークンとメンテナンス通知の情報を取得する
        const funcUpdateAllUsers = (doc: any) => {
          token = doc.data().token;
          dispatch(
            updateImageToken({
              imageToken: token,
            })
          );
          dispatch(
            updateNotification({
              notification: doc.data().notification_contents,
            })
          );
          // レイヤデータを裏で取得する
          initializeInnerData(token);
        };
        // Firestoreの変更通知スナップショットのハンドリング
        snapshotFirestoreDocData(
          "application_info",
          "all_users",
          funcUpdateAllUsers
        );
        //  ウェイト表示解除
        setIsAnotherLoginLoading(false);
        if (mapsShotcutValue) {
          openGraphsShortCut();
          setStoreCodeCvrGraphAnalysis(mapsShotcutValue);
          mapsShotcutValue = "";
          history.push("maps");
          return;
        }
        //  メニュー表示
        props.history.push({
          pathname: startMenu.urlPath,
          state: startMenu.state,
        });
      }
    };
    // FirestoreよりGoogleCloudStorageトークンを取得して店舗マスタを読み込む
    getFirestoreDocData("application_info", "all_users")
      .then((doc: any) => {
        initializeExistingSejStores(
          doc.token,
          setTenpoMasterFirstOperationFinish
        );
        // ユーザー情報APIの呼び出し
        postRequest(process.env.REACT_APP_COMMON_API!, {
          "api-name": "user-info",
        })
          .then((response: any) => {
            const json = JSON.parse(response.data.dataList[0]);
            dispatch(
              login({
                uid: authUser.uid,
                displayName: authUser.displayName,
                jobCategory: json.job_category,
                jobCategoryCodeOA: json.job_category_code_OA,
                departmentCodeOA: json.department_code_OA,
                mailAddress: json.mail_address,
                zoCode: json.zo_code,
                doCode: json.do_code,
                tenpoCode: json.tenpo_code,
                employeeNo: json.employee_no,
                opviewSofcFlag: json.opview_sofc_flag,
                tenpoCodeSpecialList: json.tenpo_code_special_list,
                apps: json.apps,
              })
            );
            // 担当店以外のショートカットは無効
            if (
              json.job_category !== "admin-group" &&
              !json.tenpo_code.includes(getInitTenpoCode())
            ) {
              setInitTenpoCode("");
            }
            if (
              json.job_category !== "admin-group" &&
              !json.tenpo_code.includes(mapsShotcutValue)
            ) {
              mapsShotcutValue = "";
            }
            // 社員情報取得
            if (
              json.job_category === "admin-group" ||
              json.job_category === "gm" ||
              json.job_category === "ops" ||
              json.job_category === "zm" ||
              json.job_category === "dm"
            ) {
              //admin-group と zm は zocode の IN検索。
              //admin-group は zo‗code が空文字なので zocode in ("") で結果的に全件検索になる。
              let colname = "zocode";
              let invalue = json.zo_code;
              //空配列を回避
              if (Array.isArray(invalue) && invalue.length === 0) {
                invalue = [""];
              }
              //dm は docode の IN検索。;
              if (json.job_category === "dm") {
                colname = "docode";
                invalue = json.do_code;
              }
              let jobcodes = [JOB_CATEGORY_CODE_OFC];
              initializeEmployee(colname, invalue, jobcodes);
            }
            isFinishUserInfo = true;
            setFirstOperationFinish();
          })
          .catch((error) => {
            console.error("Error App initialOperation1: ", error);
            userLogging("共通", "社員情報取得エラー", JSON.stringify(error));
            setIsAnotherLoginLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error App initialOperation2: ", error);
        userLogging("共通", "店舗情報取得エラー", JSON.stringify(error));
      });
  };

  // Firebaseカスタム認証
  const callCustomFirebaseAuthentication = async () => {
    const url: string = process.env.REACT_APP_GAE_API_URL + "auth/token";
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + (await getFirebaseAuthToken(false)),
      },
    });
    // カスタム認証を行う
    firebase
      .auth()
      .signInWithCustomToken(response.data.dataList[0])
      .then(() => {
        console.log("custom");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Cookie経由認証
  const callCookieAuthentication = async () => {
    const url: string = process.env.REACT_APP_GAE_API_URL + "auth/session-auth";
    return await axios.get(url);
  };

  //TODO 不要なら削除
  // Cookie設定・更新
  const callCookieUpdate = async () => {
    const url: string =
      process.env.REACT_APP_GAE_API_URL + "auth/session-update";
    return await getRequest(url);
  };
  //TODO 不要なら削除

  return (
    <>
      {isAnotherLoginLoading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            size={"40vh"}
            style={{
              color: "#ccc",
              top: "30vh",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
