import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    greenButton: {
      backgroundColor: "#00CC5B",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#00CC5B",
      },
    },
  })
);

const TSCheckDialog: React.FunctionComponent<{
  msg: string[];
  isOpen: boolean;
  doOK: any;
  doNo: any;
  isAlert: boolean;
  isClose?: boolean;
}> = ({ msg, isOpen, doOK, doNo, isAlert, isClose }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const doNoAction = () => {
    if (isClose) {
      doNo();
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => doNoAction()}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogContent>
          {msg.map((row: string) => (
            <Typography>{row}</Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => doOK()}
            variant={"contained"}
            className={classes.greenButton}
          >
            OK
          </Button>
          {isAlert ? (
            ""
          ) : (
            <Button onClick={() => doNo()} variant={"contained"}>
              キャンセル
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default TSCheckDialog;
