/**
 * Turf.js関連の処理です。
 * @class turfAction.ts.js
 * @constructor
 */

/**
 * GeoFireXだけではライン・ポリゴン変換ができないため、GeoFireXで取得したJSON配列をGeoJSON化します
 * @param {*} jsonArray GeoFireXで取得したデータ
 */
export const convertGeoJsonFeaturesFromGeoFireJson = (jsonArray: any) => {
  let featureArray = []; // 格納用の配列
  for (let jsonObjectItem in jsonArray) {
    const jsonFeature = JSON.parse(jsonArray[jsonObjectItem]["feature"]);
    //console.log(jsonArray[jsonObjectItem]["attribution"]["key_code"]);
    //console.log(jsonArray[jsonObjectItem]["feature"]);
    const geometry: any = {
      type: jsonFeature.geometry.type,
      coordinates: jsonFeature.geometry.coordinates,
    };
    const attribution = {
      attribution: jsonArray[jsonObjectItem]["attribution"],
    };
    featureArray.push(turf.feature(geometry, attribution));
  }
  return turf.featureCollection(featureArray);
};

/**
 * ２地点間の距離をメートルで返します。1ｍ以下切り捨て
 */
export const point2distance = (from: any, to: any) => {
  const turfPointFrom = turf.point([from.lng(), from.lat()]);
  const turfPointTo = turf.point([to.lng(), to.lat()]);
  return Math.floor(turf.distance(turfPointFrom, turfPointTo) * 1000);
};

/**
 * 作成したturfのポイントの配列より指定半径(km)の円の内側のポイントをGeoJSONで返す
 * @param array:turfのポイントの配列
 *
 *
 */
 export const calcPointsWithinCircle = (
  array: any,
  lng: number,
  lat: number,
  radius: number = 3,
  steps: number = 5
) => {
  const center: any = [lng, lat];
  const options: any = { steps, units: "kilometers" };
  // @ts-ignore エラーが出るため回避
  return turf.pointsWithinPolygon(
    turf.featureCollection(array),
    turf.circle(center, radius, options)
  );
};