import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const stylesSearchTenpo = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      left: "23%",
      width: "620px",
      height: "80%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    listPaper: {
      height: "87%",
      overflow: "auto",
      padding: "0px 0px",
      marginTop: "25px",
    },
    searchButton: {
      width: "620px",
      marginLeft: "15px",
    },
    searchTextField: {
      padding: "0px 2px",
    },
    selectButton: {
      width: "100%",
      height: "38px",
      textAlign: "left",
      marginLeft: "10px",
      marginTop: "2px",
      fontSize: "24px",
    },
  })
);

export const stylesSearchZO = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      left: "23%",
      width: "280px",
      height: "80%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    listPaper: {
      height: "87%",
      overflow: "auto",
      padding: "0px 0px",
      marginTop: "25px",
    },
    searchButton: {
      width: "280px",
      marginLeft: "15px",
    },
    searchTextField: {
      padding: "0px 2px",
    },
    selectButton: {
      width: "100%",
      height: "38px",
      textAlign: "left",
      marginLeft: "10px",
      marginTop: "2px",
      fontSize: "24px",
    },
    button: {
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
    disabled: {},
  })
);

export const stylesSearchDO = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      left: "23%",
      width: "280px",
      height: "80%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    listPaper: {
      height: "87%",
      overflow: "auto",
      padding: "0px 0px",
      marginTop: "25px",
    },
    searchButton: {
      width: "280px",
      marginLeft: "15px",
    },
    searchTextField: {
      padding: "0px 2px",
    },
    selectButton: {
      width: "100%",
      height: "38px",
      textAlign: "left",
      marginLeft: "10px",
      marginTop: "2px",
      fontSize: "24px",
    },
    button: {
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
  })
);
