import { tenpoCodeRef } from "../OpView/MarketArea/CreateMarketAreaMenu";

/**
 * マップ関係の設定値を保持します
 * @class MapStylesSettings.ts
 * @constructor
 */

export const zIndexOrder = {
  mobaku: 10,
  existingSejStoreIcon: 410,
  conflictStoreIcon: 402,
};

/**
 * テンプレートにパラメータを埋め込んだ文字列を返します。
 * 脱jQuery化の際にHTMLエンコード処理を廃しています
 * @method applyTemplate
 * @param {String} template テンプレート
 * @param {Array} params パラメータ
 * @return {String} テンプレートにパラメータを埋め込んだ文字列
 */
export function applyHtmlTemplate(template: string, params: string[]) {
  const formatRe = /\{(\d+)\}/g;
  return template.replace(formatRe, function (match, index) {
    const val = params[index];
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  });
}

export const unvisibleMapsStyleSettings = { visible: false };
export const existingSejStoreLayerMapsStyleSettings = function (feature: any) {
  if (
    feature.getProperty("attribution").tenpo_code !== undefined &&
    tenpoCodeRef === feature.getProperty("attribution").tenpo_code
  ) {
    return {
      icon:
        "./image/icons/sej_selected_3d_o.png?v=" +
        new Date().getUTCMilliseconds(),
      visible: true,
      zIndex: zIndexOrder.existingSejStoreIcon,
    };
  } else {
    return {
      icon: "./image/icons/sej_normal.png?v=" + new Date().getUTCMilliseconds(),
      visible: true,
      zIndex: zIndexOrder.existingSejStoreIcon,
    };
  }
};

export const existingSejStoreInfoWindowHtml =
  '<div class="info-window-padding"' +
  "<div >店番:{0}</div>" +
  "<div >店名:{1}</div>" +
  "<div >開店日:{2}</div>" +
  "<div >タイプ:{3}</div>" +
  "</div>";

const setConflictIconPath = (
  bland_id: string,
  isTobacco: string,
  isNewShop: string
) => {
  if (isTobacco === "1" && isNewShop === "1") {
    return getCvsBrandCodeValue(bland_id).iconPathBase + "_normal.png";
  } else if (isTobacco === "1") {
    return getCvsBrandCodeValue(bland_id).iconPathBase + "_normal.png";
  } else if (isNewShop === "1") {
    return getCvsBrandCodeValue(bland_id).iconPathBase + "_normal.png";
  } else {
    return getCvsBrandCodeValue(bland_id).iconPathBase + "_normal.png";
  }
};

export const conflictStoreLayerMapsStyleSettings = function (feature: any) {
  return {
    icon: setConflictIconPath(
      feature.getProperty("bland_id"),
      feature.getProperty("handle_category_tobacco"),
      feature.getProperty("isNewShop")
    ),
    visible: true,
    zIndex: zIndexOrder.conflictStoreIcon,
  };
};

export const conflictStoreInfoWindowHtml =
  '<div class="info-window-padding">' +
  '<button class="close-button" id="{0}-close">×</button>' +
  "<div >店名:{0}　</div>" +
  "<div >営業時間:{1}</div>" +
  "<div >たばこ:{5}</div>" +
  "<div >酒:{3}</div>" +
  "<div >駐車場:{4}</div>" +
  "<div >ATM:{2}</div>" +
  "</div>";

// 競合店舗のコード管理
const cvsBrandCodeMasterFunc = (blandId: number) => {
  if (blandId === 2) {
    return {
      blandName: "familymart",
      blandJapaneseName: "ファミリーマート",
      iconPathBase: "./image/icons/familymart",
    };
  } else if (blandId === 3) {
    return {
      blandName: "lawson",
      blandJapaneseName: "ローソン",
      iconPathBase: "./image/icons/lawson",
    };
  } else if (blandId === 4) {
    return {
      blandName: "lawson100",
      blandJapaneseName: "ローソンストア100",
      iconPathBase: "./image/icons/lawson",
    };
  } else if (blandId === 5) {
    return {
      blandName: "familymartExclamation",
      blandJapaneseName: "ファミマ！！",
      iconPathBase: "./image/icons/familymart",
    };
  } else if (blandId === 6) {
    return {
      blandName: "CircleK",
      blandJapaneseName: "サークルK",
      iconPathBase: "./image/icons/conflict",
    };
  } else if (blandId === 7) {
    return {
      blandName: "sunkus",
      blandJapaneseName: "サンクス",
      iconPathBase: "./image/icons/conflict",
    };
  } else if (blandId === 9) {
    return {
      blandName: "ministop",
      blandJapaneseName: "ミニストップ",
      iconPathBase: "./image/icons/ministop",
    };
  } else if (blandId === 10) {
    return {
      blandName: "saveOn",
      blandJapaneseName: "セーブオン",
      iconPathBase: "./image/icons/conflict",
    };
  } else if (blandId === 11) {
    return {
      blandName: "seicomart",
      blandJapaneseName: "セイコーマート",
      iconPathBase: "./image/icons/seicomart",
    };
  } else if (blandId === 13) {
    return {
      blandName: "poplar",
      blandJapaneseName: "ポプラ",
      iconPathBase: "./image/icons/poplar",
    };
  } else if (blandId === 14) {
    return {
      blandName: "c-store",
      blandJapaneseName: "コミュニティ・ストア",
      iconPathBase: "./image/icons/c-store",
    };
  } else if (blandId === 15) {
    return {
      blandName: "dailyYamazaki",
      blandJapaneseName: "デイリーヤマザキ",
      iconPathBase: "./image/icons/dailyYamazaki",
    };
  } else if (blandId === 16) {
    return {
      blandName: "threeF",
      blandJapaneseName: "スリーエフ",
      iconPathBase: "./image/icons/conflict",
    };
  } else if (blandId === 18) {
    return {
      blandName: "piago",
      blandJapaneseName: "ｍｉｎｉピアゴ",
      iconPathBase: "./image/icons/piago",
    };
  } else if (blandId === 20) {
    return {
      blandName: "poplar-cvs",
      blandJapaneseName: "生活彩家",
      iconPathBase: "./image/icons/poplarcvs",
    };
  } else if (blandId === 120) {
    return {
      blandName: "naturallawson",
      blandJapaneseName: "ナチュラルローソン",
      iconPathBase: "./image/icons/lawson",
    };
  } else {
    return {
      blandName: "",
      blandJapaneseName: "",
      iconPathBase: "./image/icons/conflict",
    };
  }
};

// 競合店舗のコードの値を取得する
export const getCvsBrandCodeValue = (blandId: string) => {
  return cvsBrandCodeMasterFunc(Number(blandId));
};
