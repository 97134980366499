import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

export const stylesTenpoCheckTable = makeStyles({
  gray: {
    color: "#c0c0c0",
    backgroundColor: "#e6e6e6",
  },
  mark: {
    color: "#fff",
    backgroundColor: green[500],
    boxShadow: "0px 0px 0px 3px #000 inset",
  },
  ngMark: {
    color: "#fff",
    backgroundColor: red[500],
    boxShadow: "0px 0px 0px 3px #000 inset",
  },
  lastCell: {
    color: "#c0c0c0",
  },
  infoTip: {
    marginLeft: "18px",
  },
  contentFrame: {
    overflowY: "auto",
    height: "68vh",
    // iPad縦
    "@media (min-width: 830px)": {
      height: "78vh",
    },
    // iPad横
    "@media (min-width: 1100px)": {
      height: "72vh",
    },
    // GWPC
    "@media (min-width: 1300px)": {
      height: "65vh",
    },
  },
  overLayer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    backgroundColor: "#CCC",
    opacity: "0.5",
    zIndex: 100000,
  },
  circularProgress: {
    position: "absolute",
    top: "35%",
    left: "45%",
    opacity: "0.8",
    color: "#ccc",
  },
  header: {
    paddingTop: "15px",
    paddingLeft: "15px",
    marginLeft: "0px",
    marginBottom: "10px",
    backgroundColor: "#ffffff",
  },
  footer: {
    paddingTop: "8px",
  },
  tabBase: {
    backgroundColor: "#d9d9d9",
    padding: "2px",
  },
  columnHeader: {
    color: "white",
    outlineColor: "#3e3e3e",
    backgroundColor: "#4dc0b2",
    margin: "2px",
  },
  columnCaption: {
    color: "#3e3e3e",
    backgroundColor: "#d8e8e6",
    paddingLeft: "15px",
    margin: "2px",
  },
  columnResult: {
    color: "#3e3e3e",
    backgroundColor: "#d8e8e6",
    verticalAlign: "middle",
    margin: "2px",
  },
  columnData: {
    color: "#3e3e3e",
    backgroundColor: "#ffffff",
    margin: "2px",
  },
  printHeader: {
    color: "white",
    outlineColor: "#3e3e3e",
    backgroundColor: "#4dc0b2",
  },
  columnPanel: {
    color: "#3e3e3e",
    backgroundColor: "#d8e8e6",
    height: "100%",
    padding: "10px",
  },
  columnBorder: {
    margin: "2px",
  },
  columnHeight: {
    height: "9vh",
  },
  headerHeight: {
    height: "7vh",
  },
  printHeight: {
    height: "100%",
  },
  createDate: {
    textAlign: "right",
    paddingRight: "10px",
  },
  button: {
    color: "#fff",
    backgroundColor: "#ef942a",
    fontSize: "20px",
    width: "30vw",
    minWidth: "200px",
    height: "5vh",
    margin: "10px",
    "&:hover": {
      background: "#ef942a",
    },
  },
  card: {
    margin: 3,
  },
  commentGrid: {
    padding: "4px",
  },
  commentText: {
    fontSize: "16px",
    borderRadius: "8px",
  },
  buttonSpace: {
    paddingRight: "15px",
  },
  addbuttonSpace: {
    padding: "15px",
  },
  addText: {
    padding: "0px",
    width: "20vw",
    margin: "0px",
  },
  editButton: {
    color: "white",
    backgroundColor: "#ef942a",
  },
  upDownButton: {
    color: "white",
    backgroundColor: "#ef942a",
    "&:hover": {
      backgroundColor: "#e38511",
    },
    marginRight: "1vw",
  },
});
