import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

import { setFixedFlg, setInitTenpoCode } from "../../Header/Header";
import { setOneVisionId, setLastOneVisionId } from "../Register/OneVision";
import { userLogging } from "../../../apis/userLog";

const useStyles = makeStyles((theme: Theme) => ({
  tableBase: {
    marginTop: "20px",
    backgroundColor: "#d9d9d9",
    padding: "1px",
    width: "80vw",
  },
  tableBottom: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: "1px",
    width: "80vw",
  },
  columnHeader: {
    color: "white",
    outlineColor: "#3e3e3e",
    backgroundColor: "#4dc0b2",
    margin: "2px",
  },
  columnResult: {
    color: "#3e3e3e",
    backgroundColor: "#ffffff",
    verticalAlign: "middle",
    margin: "2px",
  },
  headerHeight: {
    height: "7vh",
  },
  selectLine: {
    "&:hover": {
      textDecoration: "underline",
      opacity: "1",
      cursor: "pointer",
      backgroundColor: "#d8e8e6 !important",
    },
  },
  selectSort: {
    "&:hover": {
      textDecoration: "underline",
      opacity: "1",
      cursor: "pointer",
    },
  },
}));

// 検索結果表示リスト
let resultList: any[] = [];

// データ初期化
initData();
function initData() {
  resultList = [];
}

/** データセット */
export function setOneVisionSearchData(data: any) {
  resultList = data;
}

// 検索結果
export default function SearchResult() {
  const classes = useStyles();
  const history = useHistory();
  const [sortingKey, setSortingKey] = React.useState<String>("");

  const clickReference = (
    tenpoCode: string,
    docId: string,
    lastDocId: string
  ) => {
    // TODO 検索後の確認表の表示は店舗確認表の方もまだできておらずONEVISION側と作りも少し違うので一旦保留中

    setFixedFlg(true);
    setOneVisionId(docId);
    setLastOneVisionId(lastDocId);
    setInitTenpoCode(tenpoCode);
    // 画面遷移
    history.push("one-vision");
  };

  // ソート
  const clickSort = (sortKey: string) => {
    let mapList: any[] = [];
    for (let i = 0; i < resultList.length; i++) {
      mapList.push(resultList[i]);
    }

    let asc = true;
    if (sortingKey === sortKey) {
      asc = false;
    }
    resultList = mapList.sort(function (first, second) {
      if (first[sortKey] > second[sortKey]) {
        return asc ? 1 : -1;
      } else if (first[sortKey] < second[sortKey]) {
        return asc ? -1 : 1;
      } else {
        return 0;
      }
    });
    if (asc) {
      setSortingKey(sortKey);
    } else {
      setSortingKey("");
    }
  };

  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (resultList && resultList.length > 0) {
    return (
      <>
        <Grid item>
          <Grid container className={classes.tableBase}>
            {/* ヘッダー */}
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ position: "sticky", top: 0, backgroundColor: "#d9d9d9" }}
            >
              <Grid item lg={1} md={1} sm={1}>
                <Grid item className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight + " " + classes.selectSort}
                    justify="center"
                    alignItems="center"
                    onClick={() => clickSort("jtan_flag")}
                  >
                    自担当
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <Grid item className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight + " " + classes.selectSort}
                    justify="center"
                    alignItems="center"
                    onClick={() => clickSort("employeeName")}
                  >
                    作成者
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={1} md={1} sm={1}>
                <Grid item className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight + " " + classes.selectSort}
                    justify="center"
                    alignItems="center"
                    onClick={() => clickSort("tenpo_code")}
                  >
                    店番
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <Grid item className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight + " " + classes.selectSort}
                    justify="center"
                    alignItems="center"
                    onClick={() => clickSort("tenpoName")}
                  >
                    店舗名
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <Grid item className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight + " " + classes.selectSort}
                    justify="center"
                    alignItems="center"
                    onClick={() => clickSort("zoCode")}
                  >
                    ZO
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <Grid item className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight + " " + classes.selectSort}
                    justify="center"
                    alignItems="center"
                    onClick={() => clickSort("doCode")}
                  >
                    DO
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <Grid item className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight + " " + classes.selectSort}
                    justify="center"
                    alignItems="center"
                    onClick={() => clickSort("create_date")}
                  >
                    作成日
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* データ部 */}
            {resultList.map((row) => (
              <Grid
                container
                justify="center"
                alignItems="center"
                onClick={() => {
                  userLogging(
                    "OneVision", "データ検索結果#詳細表示",
                    JSON.stringify(row)
                  );
                  clickReference(row.tenpo_code, row.doc_id, row.doc_last_id);
                }}
                className={classes.selectLine}
              >
                <Grid item lg={1} md={1} sm={1}>
                  <Grid item className={classes.columnResult}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      {row.jtan_flag === "1" ? "✔" : ""}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                  <Grid item className={classes.columnResult}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      {row.employeeName}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={1} md={1} sm={1}>
                  <Grid item className={classes.columnResult}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      {row.tenpo_code}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                  <Grid item className={classes.columnResult}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      {row.tenpoName}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                  <Grid item className={classes.columnResult}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      {row.zoName}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                  <Grid item className={classes.columnResult}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      {row.doName}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                  <Grid item className={classes.columnResult}>
                    <Grid
                      container
                      className={classes.headerHeight}
                      justify="center"
                      alignItems="center"
                    >
                      {row.create_date}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>

          {/* <Grid container className={classes.tableBottom}>
            <Grid container justify="flex-end" alignItems="flex-end">
              <IconButton
                style={{ color: "#ffffff", backgroundColor: "#FFC042" }}
                onClick={() => returnTop()}
              >
                <KeyboardArrowUp></KeyboardArrowUp>
              </IconButton>
            </Grid>
          </Grid> */}
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid container className={classes.tableBottom}>
          <Grid container justify="center" alignItems="center">
            検索結果が0件です
          </Grid>
        </Grid>
      </>
    );
  }
}
