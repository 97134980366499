import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface TENPO_CHECK_CONDITION {
  jitanFlag: boolean,
  goodExampleFlag: boolean,
  more90PercentFlag: boolean,
  tenpoCode: string,
  tenpoName: string,
  zoCode: string,
  zoName: string,
  doCode: string,
  doName: string,
  ofcMailAddress: string,
  ofcName: string,
  createDateFrom: any,
  createDateTo: any,
}

const initialTenpoCheckCondition: TENPO_CHECK_CONDITION = {
  jitanFlag: false,
  goodExampleFlag: false,
  more90PercentFlag: false,
  tenpoCode: "",
  tenpoName: "",
  zoCode: "",
  zoName: "",
  doCode: "",
  doName: "",
  ofcMailAddress: "",
  ofcName: "",
  createDateFrom: null,
  createDateTo: null,
};

export const tenpoCheckSlice = createSlice({
  name: "tenpoCheckCondition",
  initialState: {
    tenpoCheckCondition: initialTenpoCheckCondition,
    checkDataList: [],
    oneVisionDataList: [],
    tsCheckDataList: [],
    checkFilingWeekList: [],
    checkFilingDataList: [],
    oneVisionFilingWeekList: [],
    oneVisionFilingDataList: [],
    tsCheckFilingWeekList: [],
    tsCheckFilingDataList: [],
    tenpoCheckManagerTab: "",
    tenpoCheckManagerMap: {},
    locationKey: {},
  },
  reducers: {
    replaceTenpoCheckCondition: (state, action) => {
      state.tenpoCheckCondition = Object.assign({}, state, action.payload.replaceData);
    },
    updateJitanFlag: (state, action) => {
      state.tenpoCheckCondition.jitanFlag = action.payload.jitanFlag;
    },
    updateGoodExampleFlag: (state, action) => {
      state.tenpoCheckCondition.goodExampleFlag = action.payload.goodExampleFlag;
    },
    updateMore90PercentFlag: (state, action) => {
      state.tenpoCheckCondition.more90PercentFlag =
        action.payload.more90PercentFlag;
    },
    updateTenpoCode: (state, action) => {
      state.tenpoCheckCondition.tenpoCode =
        action.payload.tenpoCode;
    },
    updateTenpoName: (state, action) => {
      state.tenpoCheckCondition.tenpoName =
        action.payload.tenpoName;
    },
    updateZoCode: (state, action) => {
      state.tenpoCheckCondition.zoCode =
        action.payload.zoCode;
    },
    updateZoName: (state, action) => {
      state.tenpoCheckCondition.zoName =
        action.payload.zoName;
    },
    updateDoCode: (state, action) => {
      state.tenpoCheckCondition.doCode =
        action.payload.doCode;
    },
    updateDoName: (state, action) => {
      state.tenpoCheckCondition.doName =
        action.payload.doName;
    },
    updateOfcMailAddress: (state, action) => {
      state.tenpoCheckCondition.ofcMailAddress =
        action.payload.ofcMailAddress;
    },
    updateOfcName: (state, action) => {
      state.tenpoCheckCondition.ofcName =
        action.payload.ofcName;
    },
    updateCreateDateFrom: (state, action) => {
      state.tenpoCheckCondition.createDateFrom =
        action.payload.createDateFrom;
    },
    updateCreateDateTo: (state, action) => {
      state.tenpoCheckCondition.createDateTo =
        action.payload.createDateTo;
    },
    updateCheckDataList: (state, action) => {
      state.checkDataList = action.payload.checkDataList;
    },
    updateOneVisionDataList: (state, action) => {
      state.oneVisionDataList = action.payload.oneVisionDataList;
    },
    updateTsCheckDataList: (state, action) => {
      state.tsCheckDataList = action.payload.tsCheckDataList;
    },
    updateCheckFilingWeekList: (state, action) => {
      state.checkFilingWeekList = action.payload.checkFilingWeekList;
    },
    updateCheckFilingDataList: (state, action) => {
      state.checkFilingDataList = action.payload.checkFilingDataList;
    },
    updateOneVisionFilingWeekList: (state, action) => {
      state.oneVisionFilingWeekList = action.payload.oneVisionFilingWeekList;
    },
    updateOneVisionFilingDataList: (state, action) => {
      state.oneVisionFilingDataList = action.payload.oneVisionFilingDataList;
    },
    updateTsCheckFilingWeekList: (state, action) => {
      state.tsCheckFilingWeekList = action.payload.tsCheckFilingWeekList;
    },
    updateTsCheckFilingDataList: (state, action) => {
      state.tsCheckFilingDataList = action.payload.tsCheckFilingDataList;
    },
    updateTenpoCheckManagerTab: (state, action) => {
      state.tenpoCheckManagerTab =
        action.payload.tenpoCheckManagerTab;
    },
    updateTenpoCheckManagerMap: (state, action) => {
      state.tenpoCheckManagerMap = action.payload.tenpoCheckManagerMap;
    },
    updateLocationKey: (state, action) => {
      state.locationKey = { ...state.locationKey, ...action.payload.locationKey };
    },
    clearTenpoCheckCondition: (state) => {
      state.tenpoCheckCondition = initialTenpoCheckCondition;
      state.checkDataList = [];
      state.oneVisionDataList = [];
      state.tsCheckDataList = [];
      state.checkFilingWeekList = [];
      state.checkFilingDataList = [];
      state.oneVisionFilingWeekList = [];
      state.oneVisionFilingDataList = [];
      state.tsCheckFilingWeekList = [];
      state.tsCheckFilingDataList = [];
      state.tenpoCheckManagerTab = "";
      state.tenpoCheckManagerMap = {};
    },
  },
});

export const selectTenpoCheckCondition = (state: RootState) => state.tenpoCheckCondition;
export const selectJitanFlag = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.jitanFlag;
export const selectGoodExampleFlag = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.goodExampleFlag;
export const selectMore90PercentFlag = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.more90PercentFlag;
export const selectTenpoCode = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.tenpoCode;
export const selectTenpoName = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.tenpoName;
export const selectZoCode = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.zoCode;
export const selectZoName = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.zoName;
export const selectDoCode = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.doCode;
export const selectDoName = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.doName;
export const selectOfcMailAddress = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.ofcMailAddress;
export const selectOfcName = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.ofcName;
export const selectCreateDateFrom = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.createDateFrom;
export const selectCreateDateTo = (state: RootState) =>
  state.tenpoCheckCondition.tenpoCheckCondition.createDateTo;
export const selectCheckDataList = (state: RootState) =>
  state.tenpoCheckCondition.checkDataList;
export const selectOneVisionDataList = (state: RootState) =>
  state.tenpoCheckCondition.oneVisionDataList;
export const selectTsCheckDataList = (state: RootState) =>
  state.tenpoCheckCondition.tsCheckDataList;
export const selectCheckFilingWeekList = (state: RootState) =>
  state.tenpoCheckCondition.checkFilingWeekList;
export const selectCheckFilingDataList = (state: RootState) =>
  state.tenpoCheckCondition.checkFilingDataList;
export const selectOneVisionFilingWeekList = (state: RootState) =>
  state.tenpoCheckCondition.oneVisionFilingWeekList;
export const selectOneVisionFilingDataList = (state: RootState) =>
  state.tenpoCheckCondition.oneVisionFilingDataList;
export const selectTsCheckFilingWeekList = (state: RootState) =>
    state.tenpoCheckCondition.tsCheckFilingWeekList;
export const selectTsCheckFilingDataList = (state: RootState) =>
    state.tenpoCheckCondition.tsCheckFilingDataList;
export const selectTenpoCheckManagerTab= (state: RootState) =>
    state.tenpoCheckCondition.tenpoCheckManagerTab;
export const selectTenpoCheckManagerMap = (state: RootState) =>
    state.tenpoCheckCondition.tenpoCheckManagerMap;
export const selectLocationKey = (state: RootState) =>
    state.tenpoCheckCondition.locationKey;
export default tenpoCheckSlice.reducer;
export const {
  replaceTenpoCheckCondition,
  updateJitanFlag,
  updateGoodExampleFlag,
  updateMore90PercentFlag,
  updateTenpoCode,
  updateTenpoName,
  updateZoCode,
  updateZoName,
  updateDoCode,
  updateDoName,
  updateOfcMailAddress,
  updateOfcName,
  updateCreateDateFrom,
  updateCreateDateTo,
  updateCheckDataList,
  updateOneVisionDataList,
  updateTsCheckDataList,
  updateCheckFilingWeekList,
  updateCheckFilingDataList,
  updateOneVisionFilingWeekList,
  updateOneVisionFilingDataList,
  updateTsCheckFilingWeekList,
  updateTsCheckFilingDataList,
  updateTenpoCheckManagerTab,
  updateTenpoCheckManagerMap,
  updateLocationKey,
  clearTenpoCheckCondition,
} = tenpoCheckSlice.actions;
