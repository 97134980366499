import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import { hideAllPolygons } from "../MarketArea/PolygonDrawing";
import {
  setUnvisibleMapsStyle,
  initExistingSejStores,
} from "../../Maps/layers/existingSejStoreLayer";
import { selectExclusiveFlg } from "../../../features/marketAreaStatusSlice";
import { setDisplayMarketAreaFlag } from "../../OpView/MarketArea/ShowMarketAreaButton";
import { userLogging } from "../../../apis/userLog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      cursor: "pointer",
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
  })
);
const HideMarketArea: FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const exclusiveFlg = useSelector(selectExclusiveFlg);
  const text = "全ての範囲を非表示にする";

  return (
    <>
      <Tooltip
        title={<span style={{ fontSize: "16px" }}>{text}</span>}
        placement="left"
        disableTouchListener
      >
        <IconButton
          className={classes.button + " map-item-shadow"}
          style={exclusiveFlg ? { pointerEvents: "none" } : {}}
          onClick={() => {
            hideAllPolygons();
            setDisplayMarketAreaFlag(false);
            // 既存店レイヤの初期化
            setUnvisibleMapsStyle();
            initExistingSejStores(dispatch);
            userLogging("OPVIEW", "OPVIEW＃全非表示", "");
          }}
        >
          <CloseIcon fontSize="default" />
        </IconButton>
      </Tooltip>
    </>
  );
};
export default HideMarketArea;
