import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import NearMeIcon from "@material-ui/icons/NearMe";
import { moveMobakuGMapsPoint } from "./SelectMobakuGoogleMaps";
import { getGeolocation } from "../../../apis/geolocationAction";
import { selectExclusiveMobakuFlg } from "../../../features/marketAreaStatusSlice";
import { userLogging } from "../../../apis/userLog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
  })
);

const MobakuMapsGeolocationButton: React.FC<{
  graphType: string;
}> = ({ graphType }) => {
  const classes = useStyles({});
  const exclusiveMobakuFlg = useSelector(selectExclusiveMobakuFlg);
  useEffect(() => {}, []);
  const handleGeolocation = () => {
    getGeolocation()
      .then((position: any) => {
        moveMobakuGMapsPoint(
          position.coords.latitude,
          position.coords.longitude,
          graphType
        );
        userLogging("OPVIEW", "OPVIEW＃現在地", "");
      })
      .catch((err) => {
        alert("現在地情報が取得できませんでした");
        userLogging(
          "OPVIEW",
          "OPVIEW＃現在地表示エラー",
          `code:${err.code},message:${err.message}`
        );
      });
  };

  return (
    <IconButton
      className={classes.button + " map-item-shadow"}
      style={exclusiveMobakuFlg ? { pointerEvents: "none" } : {}}
      onClick={() => handleGeolocation()}
    >
      <NearMeIcon fontSize="small" />
    </IconButton>
  );
};

export default MobakuMapsGeolocationButton;
