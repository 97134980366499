import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Tooltip } from "@material-ui/core";
import CvrGraphAnalysis from "./CvrGraphAnalysis";
import TenpoGraphAnalysis from "./TenpoGraphAnalysis";
import MobakuGraphAnalysis from "./MobakuGraphAnalysis";
import TroubleNotification from "../common/TroubleNotification";
import TenpoAlert from "../common/TenpoAlert";
import BarChartIcon from "@material-ui/icons/BarChart";
import { selectExclusiveFlg } from "../../features/marketAreaStatusSlice";
import { postRequest } from "../../apis/axiosAction";
import { selectInitializeGraphValue } from "../../features/tenpoStatusSlice";
import { userLogging } from "../../apis/userLog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    graphButton: {
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
    backLayer: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 100,
    },
    paper: {
      position: "fixed",
      top: "1%",
      left: "16px",
      width: "95%",
      height: "95%",
      backgroundColor: theme.palette.background.paper,
      // border: "2px solid #000",
      // borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 2, 1),
      "&:focus": {
        outline: "none",
      },
    },
    infoButton: {
      cursor: "pointer",
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "20px",
      width: "20px",
      padding: "10px",
    },
    closeButton: {
      position: "absolute",
      right: "1%",
      "&:hover": {
        backgroundColor: "lightgray",
      },
      zIndex: 5,
    },
    selectText: {
      position: "absolute",
      top: "25px",
      left: "25px",
      fontSize: "14px",
      fontWeight: "bold",
    },
    tabBox: {
      marginBottom: "5px",
    },
    graph: {
      border: "solid 1px lightgray",
      height: "88%",
    },
  })
);

let shortCutOpenGraphs = false;
export function openGraphsShortCut() {
  shortCutOpenGraphs = true;
}

const SelectGraph: FC<{}> = () => {
  const classes = useStyles();
  const [openGraphs, setOpenGraphs] = React.useState(shortCutOpenGraphs);
  const [openTenpoSelect, setOpenTenpoSelect] = React.useState(false);
  const [firstView, setFirstView] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [holiday, setHoliday] = React.useState<string[]>([]);
  const exclusiveFlg = useSelector(selectExclusiveFlg);
  const initializeGraphValue = useSelector(selectInitializeGraphValue);
  const common_api_url: string = process.env.REACT_APP_COMMON_API!;

  useEffect(() => {
    getHoliday();
  }, []);

  const handleOpenGraphs = () => {
    setFirstView(true);
    setOpenGraphs(true);
    userLogging("OPVIEW", "OPVIEW＃グラフ表示", "");
  };
  const handleCloseGraphs = () => {
    setOpenGraphs(false);
    setOpenTenpoSelect(false);
    setTabValue(0);
  };
  const handleOpenTenpoSelect = (toggle: boolean) => {
    setOpenTenpoSelect(toggle);
  };
  const handleChange = (event: any, newValue: number) => {
    setTabValue(newValue);
    selectLog(newValue);
  };
  const handleFirstView = (event: any, newValue: number) => {
    setFirstView(false);
  };

  // カレンダーで表示する休日を取得
  const getHoliday = async () => {
    const param = {
      "api-name": "holiday",
    };
    const res = await postRequest(common_api_url, param);
    const holidayList = res.data.dataList
      ? JSON.parse(res.data.dataList[0]).holiday_list
      : [];
    const holidayArr: Array<string> = [];
    holidayList.forEach((data: any) => {
      holidayArr.push(data.date);
    });
    setHoliday(holidayArr);
  };

  const selectLog = (value: number) => {
    let contentName = "";
    switch (value) {
      case 0:
        contentName = "コンバージョンレート";
        break;
      case 1:
        contentName = "人流×人流";
        break;
      case 2:
        contentName = "客数×客数";
        break;
    }
    userLogging("OPVIEW", "OPVIEW＃グラフ表示切替", contentName);
  };

  return (
    <>
      <TroubleNotification />
      <TenpoAlert />
      <Tooltip
        title={
          <span style={{ fontSize: "16px" }}>{"個店の数値を確認する"}</span>
        }
        placement="left"
        disableTouchListener
        disableFocusListener
      >
        <IconButton
          className={classes.graphButton + " map-item-shadow"}
          style={exclusiveFlg ? { pointerEvents: "none" } : {}}
          onClick={() => {
            handleOpenTenpoSelect(true);
            handleOpenGraphs();
          }}
        >
          <BarChartIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {/* ※Modalコンポーネントで画面を閉じて再度開くとGraphAnalysis系のコンポーネントの値が初期化される為（原因不明）、display:noneで隠すだけにする */}
      <div
        className={classes.backLayer}
        style={{ display: openGraphs ? "" : "none" }}
      >
        <div
          className={classes.paper}
          style={{ display: openGraphs ? "" : "none" }}
        >
          <div>
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                handleCloseGraphs();
              }}
              size={"small"}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.selectText}>分析の仕方を選んでください▶▶</div>
          <div className={classes.tabBox}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                fontWeight: "bold",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "#00CC5B",
                    height: "4px",
                  },
                }}
                sx={{
                  button: {
                    fontWeight: "bold",
                    color: "rgba(102,102,102,0.6)",
                    fontSize: "17px",
                  },
                  "& button.Mui-selected": {
                    color: "#000000",
                  },
                  "& button:hover": {
                    backgroundColor: "rgba(0,204,91,0.05)",
                    color: "#000000",
                  },
                }}
                centered
              >
                <Tab label={"①コンバージョンレートをみる"}></Tab>
                <Tab label={"②人流×人流を比較する"}></Tab>
                <Tab label={"③客数×客数を比較する"}></Tab>
              </Tabs>
            </Box>
          </div>
          <div
            role="tabpanel"
            hidden={tabValue !== 0}
            id={`simple-tabpanel-0`}
            aria-labelledby={`simple-tab-0`}
            className={classes.graph}
          >
            <CvrGraphAnalysis
              firstView={firstView}
              handleFirstView={handleFirstView}
              openTenpoSelect={openTenpoSelect}
              handleOpenTenpoSelect={handleOpenTenpoSelect}
              holiday={holiday}
              key={initializeGraphValue}
            />
          </div>
          <div
            role="tabpanel"
            hidden={tabValue !== 1}
            id={`simple-tabpanel-1`}
            aria-labelledby={`simple-tab-1`}
            className={classes.graph}
          >
            <MobakuGraphAnalysis holiday={holiday} key={initializeGraphValue} />
          </div>
          <div
            role="tabpanel"
            hidden={tabValue !== 2}
            id={`simple-tabpanel-2`}
            aria-labelledby={`simple-tab-2`}
            className={classes.graph}
          >
            <TenpoGraphAnalysis holiday={holiday} key={initializeGraphValue} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectGraph;
