/**
 * モバ空マップ関係のコントローラークラスです。
 * @namespace GoogleMap
 * @class MobakuMap.ts
 * @constructor
 *
 * モバ空マップの名前空間を解決することが困難だったためmobakumapのエラーを無視して、
 * 名前空間エラーを無理やり突破しています
 */

export const createMobakuMap = (mapObject: any) => {
  if (
    process.env.NODE_ENV === "development" ||
    // process.env.REACT_APP_MODE === "develop" ||
    process.env.REACT_APP_MODE === "store-check" ||
    process.env.REACT_APP_MODE === "production-tenpo-check" ||
    process.env.REACT_APP_MODE === "production-stg-tenpo-check" ||
    process.env.REACT_APP_MODE === "production-staging-2"
  ) {
    return;
  }
  // @ts-ignore
  mobakumap.init(mapObject, {
    data_url: "https://sej.mobakumap.jp/data/",
    enable_history_mode: true,
    show_population_heatmap: true,
    show_population_timeline: true,
    show_population_age_gender: true,
    show_population_residence: true,
    show_residence_detail: true,
    show_residence_map: true,
    history_max: "13m",
    reduce_detail: true,
    hide_detail_marker: true,
  });
};

// モバ空表示/非表示切り替え
let enabled = true;
export const toggle = () => {
  enabled = !enabled;
  if (enabled) {
    try {
      // @ts-ignore
      mobakumap.show();
    } catch {}
  } else {
    try {
      // @ts-ignore
      mobakumap.hide();
    } catch {}
  }
};
