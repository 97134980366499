/**
 * conflictStoreLayer.ts
 * 地図上の競合店の表示管理を行います
 */

import { getModalGoogleMapsObject } from "../SelectMobakuGoogleMaps";
import { calcPointsWithinCircle } from "../../../../apis/turfAction";
import {
  unvisibleMapsStyleSettings,
  conflictStoreInfoWindowHtml,
  applyHtmlTemplate,
} from "../MobakuMapStylesSettings";
import { updateFireStoreLayer, manageDisplayLayer } from "../SelectMobakuMapItems";
import { conflictStore } from "../../../Maps/layers/conflictStoreLayer";

export let conflictStoreLayer: google.maps.Data,
  conflictStoreInfoWindow = new Map(),
  conflictStoreData: any;

// 個別項目の型
export interface CONFLICT_STORES_RECORD {
  // 各アプリケーション共通項目
  id: string;
  name: string;
  bland_id: string;
  address: string;
  longitude: string;
  latitude: string;
  business_hours: string;
  handle_category_atm: string;
  handle_category_liquor: string;
  handle_category_parking: string;
  handle_category_tobacco: string;
}

// 個別項目の型のオブジェクトの集まり
export interface INTERFACE_CONFLICT_STORES_DATA {
  [index: string]: CONFLICT_STORES_RECORD;
}

// 競合店の型
export interface INTERFACE_CONFLICT_STORES {
  data: INTERFACE_CONFLICT_STORES_DATA;
  count: number;
}

/**
 * 内部データよりGoogleMapsに表示する
 */
export const innerDataConflictStore = (latLng: google.maps.LatLng, searchLength: number) => {
  const obj: INTERFACE_CONFLICT_STORES_DATA = conflictStore.conflictStore.data;
  const array: any = [];
  // GeoJSONに格納する配列をつくる
  Object.keys(obj).forEach(function (k) {
    array.push(
      turf.point([parseFloat(obj[k].longitude), parseFloat(obj[k].latitude)], {
        id: obj[k].id,
        name: obj[k].name,
        bland_id: obj[k].bland_id,
        address: obj[k].address,
        business_hours: obj[k].business_hours,
        handle_category_atm: obj[k].handle_category_atm,
        handle_category_liquor: obj[k].handle_category_liquor,
        handle_category_parking: obj[k].handle_category_parking,
        handle_category_tobacco: obj[k].handle_category_tobacco,
      })
    );
  });
  return calcPointsWithinCircle(array, latLng.lng(), latLng.lat(), searchLength);
};

// 競合店舗処理
let isApiRequest = false;
export const cbFuncConflictStoresFirst = () => {
  if (isApiRequest) {
    return true;
  }
  isApiRequest = true;
  return false;
};

export const cbFuncConflictStores = (
  geojson: any,
  updateLayer: any,
  conditions: any
) => {
  conflictStoreData = geojson;
  // setExistingSejStore(
  //   replaceNewGeoJson(getExistingSejStore(), geojson, conditions)
  // );
  updateFireStoreLayer(updateLayer);
  isApiRequest = false;
};

// 競合店舗初期処理
export const initConflictStores = (): void => {
  conflictStoreLayer = new google.maps.Data({
    map: getModalGoogleMapsObject(),
    style: {
      visible: false,
    },
  });
  // クリックイベントの登録
  conflictStoreLayer.addListener("click", function (event: any) {
    if (!conflictStoreInfoWindow.has(event.feature)) {
      conflictStoreInfoWindow.set(
        event.feature,
        new google.maps.InfoWindow({
          // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
          maxWidth: 300,
        })
      );
      // 表示位置
      conflictStoreInfoWindow.get(event.feature).setPosition(event.latLng);
      const isExist = (val: string): string => {
        if (val === "1") {
          return "有";
        }
        return "";
      };
      // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
      conflictStoreInfoWindow
        .get(event.feature)
        .setContent(
          applyHtmlTemplate(conflictStoreInfoWindowHtml, [
            event.feature.getProperty("name"),
            event.feature.getProperty("business_hours"),
            isExist(event.feature.getProperty("handle_category_atm")),
            isExist(event.feature.getProperty("handle_category_liquor")),
            isExist(event.feature.getProperty("handle_category_parking")),
            isExist(event.feature.getProperty("handle_category_tobacco")),
          ])
        );

      conflictStoreInfoWindow
        .get(event.feature)
        .setOptions({ pixelOffset: new google.maps.Size(0, -30) });
      // InfowWindowを表示
      conflictStoreInfoWindow.get(event.feature).open(getModalGoogleMapsObject());
      conflictStoreInfoWindow.get(event.feature).addListener("domready", () => {
        // infowindowのZindex変更イベント
        // $(
        //   "#" +
        //     event.feature.getProperty("attribution").tenpo_code +
        //     "-infowindow"
        // ).on("click", () => {
        //   setZIndex(existingSejStoreInfoWindow.get(event.feature));
        // });
        // $("#testButtonInfoWindow")
        //   .off()
        //   .on("click", () => {
        //     // Todo暫定ルート検索
        //     getRoute();
        //   });
      });
      conflictStoreInfoWindow
        .get(event.feature)
        .addListener("closeclick", function () {
          conflictStoreInfoWindow.delete(event.feature);
        });
    } else {
      conflictStoreInfoWindow.get(event.feature).close();
      conflictStoreInfoWindow.delete(event.feature);
    }
  });
  // スタイル指定（最初は非表示）
  conflictStoreLayer.setStyle(unvisibleMapsStyleSettings);
  manageDisplayLayer(conflictStoreLayer, true);
};
