import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  ComposedChart,
  Line,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      padding: "5px",
      border: "1px solid lightgray",
      backgroundColor: "rgba(255,255,255,0.9)",
      pointerEvents: "all",
      borderRadius: "5%",
    },
    customTooltipContent: {
      listStyle: "none",
    },
  })
);
const GraphContentCVR: React.FC<{
  data: any;
  initGraph: boolean;
  baseAxis: number;
  firstDate: string;
  today: string;
}> = ({ data, initGraph, baseAxis, firstDate, today }) => {
  const classes = useStyles({});
  const [activeTooltip, setActiveTooltip] = useState(true);

  const CustomTooltip = ({ active, payload, label }: any) => {
    let unit = "";
    if (baseAxis === 0) {
      unit = "時";
    } else if (baseAxis === 3) {
      unit = "月";
    }
    if (active && payload && payload.length && activeTooltip) {
      return (
        <div className={classes.customTooltip}>
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={() => setActiveTooltip(false)} size={"small"}>
              <HighlightOffIcon />
            </IconButton>
          </div>
          <li className={classes.customTooltipContent}>{`${label} ${unit}`}</li>
          {payload.map((payload: any, index: any) => {
            let newName = "";
            let color;
            switch (payload.name) {
              case "conversionRateForGraph":
                newName = "コンバージョンレート";
                // color = "rgba(255, 102, 0, 1)";
                color = "orange";
                break;
            }
            return (
              <li
                className={classes.customTooltipContent}
                style={{ color: color }}
                key={index.toString()}
              >
                {newName +
                  "：" +
                  new Intl.NumberFormat("en").format(payload.value)}
              </li>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const makeFutureGraph = () => {
    const selectedDate = convertDate(firstDate);
    const twoWeekAgoDate = new Date(
      selectedDate.setDate(selectedDate.getDate() - 14)
    );
    const todayDate = convertDate(today);
    let dateDiff =
      (todayDate.getTime() - convertDate(firstDate).getTime()) / 86400000;
    if (todayDate > twoWeekAgoDate) {
      for (let d = 15 + dateDiff; d < 29; d++) {
        data[d].conversionRateForGraph = "-";
      }
    }
  };

  const convertDate = (date: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const dateStr = `${year}/${month}/${day}`;
    return new Date(dateStr);
  };

  const handleActiveTooltip = () => {
    setActiveTooltip(true);
  };

  if (data) {
    baseAxis === 1 && data.length === 29 && makeFutureGraph();
  }

  return (
    <>
      {initGraph ? (
        <ResponsiveContainer maxHeight={290}>
          <ComposedChart
            data={data}
            margin={{ top: 20, right: 25, left: 60, bottom: -15 }}
          >
            <XAxis
              dataKey="date"
              tickFormatter={() => {
                return "";
              }}
            />
            {/* CVRの時の左Y軸 */}
            <YAxis
              yAxisId={1}
              tickCount={9}
              tick={{ fontSize: 14 }}
              label={{
                value: "コンバージョンレート（％）",
                angle: -90,
                dx: -50,
              }}
            />
            <Tooltip trigger="click" content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="3 3" />
            {/* Y軸と線グラフの間を空けるためのダミー */}
            <Bar yAxisId={100} dataKey="diffForGraph" hide={true} />
            {/* CVRの時の折れ線グラフ */}
            <Line
              yAxisId={1}
              dataKey="conversionRateForGraph"
              // stroke="rgba(255, 102, 0, 1)"
              stroke="orange"
              strokeWidth={3}
              dot={false}
              animationEasing="ease-out"
              animationDuration={300}
              onClick={() => {
                handleActiveTooltip();
              }}
              activeDot={{ onClick: handleActiveTooltip }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer maxHeight={310}>
          <ComposedChart margin={{ top: 20, right: 30, left: 45, bottom: -15 }}>
            <XAxis tick={false} />
            <YAxis
              label={{
                value: "",
                dx: -50,
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default GraphContentCVR;
