import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { updateDeleteMarketAreaFlg } from "../../../features/marketAreaStatusSlice";
import {
  selectDeleteMarketAreaFlg,
  selectTenpoCode,
  selectTenpoName,
} from "../../../features/marketAreaStatusSlice";
import { Typography } from "@material-ui/core";
import { deleteMarketArea } from "../MarketArea/PolygonDrawing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "20%",
      left: "30%",
      width: "35%",
      height: "25%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 4, 3),
      "&:focus": {
        outline: "none",
      },
      borderRadius: "10px",
    },
    closeButton: {
      left: "99.5%",
      width: "5px",
      height: "5px",
      "&:hover": {
        backgroundColor: "lightgray",
      },
    },
    buttonGroup: {
      position: "absolute",
      right: "5%",
      bottom: "6%",
      border: "none",
    },
    OkButton: {
      borderRadius: "10%",
      border: "none",
      marginLeft: "8px",
      height: "40px",
      width: "80px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#00cc5b",
      cursor: "pointer",

      // "&:hover": {
      //   backgroundColor: "#2e8b57",
      // },
    },
    cancelButton: {
      borderRadius: "10%",
      border: "none",
      margin: "2px",
      height: "40px",
      width: "80px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#00cc5b",
      cursor: "pointer",
      // "&:hover": {
      //   backgroundColor: "#2e8b57",
      // },
    },
  })
);

const DeleteMarketAreaModal: React.FC<{ isMobaku: boolean }> = ({
  isMobaku,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles({});
  const displayFlg = useSelector(selectDeleteMarketAreaFlg);
  const tenpoCode = useSelector(selectTenpoCode);
  const tenpoName = useSelector(selectTenpoName);
  const [open, setOpen] = React.useState(true);

  const text = (
    <div>
      {tenpoName}店の範囲を削除してもよろしいですか？
      <br />
      削除すると元に戻せません。
    </div>
  );

  const handleClose = () => {
    dispatch(
      updateDeleteMarketAreaFlg({
        deleteMarketAreaFlg: false,
      })
    );
  };

  const handleCancel = () => {
    dispatch(
      updateDeleteMarketAreaFlg({
        deleteMarketAreaFlg: false,
      })
    );
  };

  const handleDelete = () => {
    deleteMarketArea(tenpoCode, dispatch, isMobaku);
    dispatch(
      updateDeleteMarketAreaFlg({
        deleteMarketAreaFlg: false,
      })
    );
  };

  return displayFlg ? (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="h6" style={{ color: "red" }}>
          {text}
        </Typography>
        <div className={classes.buttonGroup}>
          <button
            className={classes.cancelButton}
            onClick={() => {
              handleCancel();
            }}
          >
            キャンセル
          </button>
          <button
            className={classes.OkButton}
            onClick={() => {
              handleDelete();
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default DeleteMarketAreaModal;
