import { getGoogleMapsObject } from "../../Maps/GoogleMaps";
import { getModalGoogleMapsObject } from "../../OpView/SelectMobakuMap/SelectMobakuGoogleMaps";
import lineIntersect from "@turf/line-intersect";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import centerOfMass from "@turf/center-of-mass";
import {
  updateCompletePolygonFlg,
  updateMarketAreaValue,
} from "../../../features/marketAreaStatusSlice";
import {
  getFirestoreData,
  getFirestoreDocData,
  writeFirestoreDoc,
  deleteFirestoreDoc,
} from "../../../apis/firestoreAction";
import { firestore, getFirebaseUserInfo } from "../../../firebase";
import * as geofirex from "geofirex";
import { toGeoJSON } from "geofirex";
import firebase from "firebase/app";
import { initExistingSejStores } from "../../Maps/layers/existingSejStoreLayer";
import { updateExclusiveFlg } from "../../../features/marketAreaStatusSlice";
import { updateExclusiveMobakuFlg } from "../../../features/marketAreaStatusSlice";
import {
  updateCvrMobakuName,
  updateSecondCvrItem,
  updateSecondMobakuName,
  updateSelectingCvrMobakuItem,
} from "../../../features/tenpoStatusSlice";
import {
  updateFireStoreLayer,
  cvrMobakuLayer,
} from "../SelectMobakuMap/SelectMobakuMapItems";
import { displayMarketAreaFlag } from "../../OpView/MarketArea/ShowMarketAreaButton";
import { userLogging } from "../../../apis/userLog";
import {
  isTrainingUser,
  isTsUser,
  isFTraineeUser,
} from "../../../apis/privilege";
import { getMarketAreaDocId } from "../../OpView/MarketArea/MarketAreaControl";

// ★★★エラー消すのに暫定でanyにしている。型の確認できたら修正
let posPolygonArray: any[] = [];
let startPoint: any;
let polygonLine: any;
let chasePolygonLine: any;
let overlayPolygon: any;
let tenpoCodeParam: string;
let tenpoLatLngParam: number[];
let setEvent: any;
let insertEvent: any;
let newPolygon: any = null;
let selectingPolygon: any = null;
let editingPolygon: any = null;
let previousPolygon: any = null;
let originalPolygon: any = null;
let dispatchRef: any = null;
let tempSelectingPolygon: any;
export let displayPolygon: any = [];
export let displayMobakuPolygon: any = [];

let polygonOptions: any = {
  fillColor: "#ff6600",
  strokeWeight: 1.0,
  strokeColor: "#ff6600",
  fillOpacity: 0.1,
  clickable: false,
  editable: false,
  // モバ空メッシュレイヤ・既存店・競合店レイヤよりも上
  zIndex: 500,
};

let selectedPolygonOptions: any = {
  fillColor: "#00B050",
  strokeWeight: 5.0,
  strokeColor: "#00B050",
  fillOpacity: 0.25,
  clickable: false,
  editable: false,
  // モバ空メッシュレイヤ・既存店・競合店レイヤよりも上
  zIndex: 500,
};

/**
 * ポリゴン描画を開始する
 * @export
 */
export const drawPolygon = (
  dispatch: any,
  tenpoCode: string,
  tenpoLatLng: any,
  isMobaku: boolean
) => {
  const gmObject = judgeGoogleMapObject(isMobaku);
  tenpoCodeParam = tenpoCode;

  // 排他制御開始
  if (isMobaku) {
    dispatch(
      updateExclusiveMobakuFlg({
        exclusiveMobakuFlg: true,
      })
    );
  } else {
    dispatch(
      updateExclusiveFlg({
        exclusiveFlg: true,
      })
    );
  }
  // オブジェクト初期化
  clearPolygonSettings();
  // オブジェクト設定
  newPolygon = new google.maps.Polygon(polygonOptions);
  initPolygonLine(gmObject);
  initChasePolygonLine(gmObject);
  initOverlayPolygon(gmObject);
  // 面積を初期化
  calculateAreaValue(dispatch, true);
  // クリックでポリゴン作成のイベント
  overlayPolygon.addListener("click", (event: any) => {
    addPolygonPoint(
      event.latLng,
      dispatch,
      tenpoCode,
      tenpoLatLng,
      gmObject,
      isMobaku
    );
  });
  // ダブルクリックで作成したポリゴンを確定
  overlayPolygon.addListener("dblclick", (event: any) => {
    // 地図拡大処理の防止
    event.stop();
    // ポリゴンの確定
    confirmPolygon(dispatch, tenpoCode, tenpoLatLng, gmObject, isMobaku);
  });
};

// 透明なポリゴンを配置
const initOverlayPolygon = (gmObject: any) => {
  let japanPoints = [
    new google.maps.LatLng(23.76371428122162, 123.00645985915843),
    new google.maps.LatLng(24.277098587435376, 143.2163593499933),
    new google.maps.LatLng(41.90413564957214, 144.08786830991315),
    new google.maps.LatLng(47.280923147488046, 154.76657924741315),
    new google.maps.LatLng(48.66359222734448, 152.87693080991315),
    new google.maps.LatLng(45.46188216704111, 144.48337612241315),
    new google.maps.LatLng(45.79488945313425, 139.83274206369904),
    new google.maps.LatLng(38.389651501813184, 137.37180456369904),
    new google.maps.LatLng(34.822023562011985, 129.31176512637802),
    new google.maps.LatLng(32.707914240374386, 128.15459262003836),
  ];

  // 地図を覆う透明なポリゴンを描画
  overlayPolygon = new google.maps.Polygon({
    paths: japanPoints,
    strokeColor: "#FF0000",
    strokeOpacity: 0,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0,
    clickable: true,
    zIndex: 100000,
  });

  overlayPolygon.setMap(gmObject);
};

// ポリゴン配列に緯度経度を追加
const addPolygonPoint = (
  latLng: any,
  dispatch: any,
  tenpoCode: string,
  tenpoLatLng: any,
  gmObject: any,
  isMobaku: boolean
) => {
  if (posPolygonArray.length === 0) {
    posPolygonArray.push(latLng);
    // 最初のポイント
    setMarker(posPolygonArray[0], gmObject);
    setChasePolygonLine();
    // 面積初期化
    calculateAreaValue(dispatch, true);
    // 始点をクリックしたらポリゴンを確定
    startPoint.addListener("click", () => {
      confirmPolygon(dispatch, tenpoCode, tenpoLatLng, gmObject, isMobaku);
    });
  } else {
    // 二点目以降
    // 連続で同じ座標をクリックした場合は配列に追加しない
    if (!latLng.equals(posPolygonArray[posPolygonArray.length - 1])) {
      // クリックした点を繋ぐラインを描画
      posPolygonArray.push(latLng);
      setPolygonLine();
      if (posPolygonArray.length >= 3) {
        // 既存の線に交差する場合は１つ戻る
        if (booleanKinksError(false)) {
          removePolygonPoint(isMobaku, dispatch);
        }
        // 面積表示
        calculateAreaValue(dispatch, true);
      }
    }
  }
};

// クリックした地点にマーカーを配置
const setMarker = (lat_lng: any, gmObject: any) => {
  startPoint = new google.maps.Marker(
    polygonStartPointSettings(lat_lng, gmObject)
  );
};

// マウス追従ポリライン作成
const initChasePolygonLine = (gmObject: any) => {
  chasePolygonLine = new google.maps.Polyline(polygonOptions);
  chasePolygonLine.setMap(gmObject);
};
const setChasePolygonLine = () => {
  overlayPolygon.addListener("mousemove", (e: any) => {
    chasePolygonLine.setPath([
      posPolygonArray[posPolygonArray.length - 1],
      e.latLng,
    ]);
  });
};

// ポリライン作成
const initPolygonLine = (gmObject: any) => {
  polygonLine = new google.maps.Polyline(polygonOptions);
  polygonLine.setMap(gmObject);
};
const setPolygonLine = () => {
  // 地図上に置いているオブジェクトに緯度経度を渡すと描画される
  polygonLine.setPath(posPolygonArray);
};

// 新規作成のポリゴンの確定
export const confirmPolygon = (
  dispatch: any,
  tenpoCode: string,
  tenpoLatLng: any,
  gmObject: any,
  isMobaku: boolean
) => {
  if (
    !booleanCheckMarketArea(posPolygonArray, tenpoLatLng) &&
    !booleanKinksError(true) &&
    !booleanWithinAreaValue(true)
  ) {
    const tempDisplayPolygon = isMobaku ? displayMobakuPolygon : displayPolygon;
    // 最初の点とライン、透明ポリゴンを削除
    clearStartPoint();
    clearPolygonLine();
    clearChasePolygonLine(true);
    clearOverlayPolygon();
    // 表示用のポリゴン作成
    tempDisplayPolygon[tenpoCode] = new google.maps.Polygon(polygonOptions);
    tempDisplayPolygon[tenpoCode].setPath(posPolygonArray);
    tempDisplayPolygon[tenpoCode].setMap(gmObject);
    dispatch(
      updateCompletePolygonFlg({
        completePolygonFlg: true,
      })
    );
    // 面積表示
    calculateAreaValue(dispatch, true);
  }
};

// 新規ポリゴン作成のキャンセル
export const cancelDrawingPolygon = (dispatch: any, isMobaku: boolean) => {
  const tempDisplayPolygon = isMobaku ? displayMobakuPolygon : displayPolygon;
  clearPolygonSettings();
  if (tempDisplayPolygon[tenpoCodeParam]) {
    tempDisplayPolygon[tenpoCodeParam].setMap(null);
    delete tempDisplayPolygon[tenpoCodeParam];
  }
  // 排他制御終了
  if (isMobaku) {
    dispatch(
      updateExclusiveMobakuFlg({
        exclusiveMobakuFlg: false,
      })
    );
  } else {
    dispatch(
      updateExclusiveFlg({
        exclusiveFlg: false,
      })
    );
  }
};

// ポリゴンの表示
export const displayPolygons = (
  polygon: any,
  gmObject: any,
  tenpoCode: string,
  isMobaku: boolean
) => {
  if (editingPolygon !== null) {
    editingPolygon.setMap(null);
    editingPolygon = null;
  }
  hidePolygon(tenpoCode, isMobaku);
  const tempDisplayPolygon = isMobaku ? displayMobakuPolygon : displayPolygon;
  tempDisplayPolygon[tenpoCode] = geoJsonPolygon2GoogleMapsPolygon(polygon);
  tempDisplayPolygon[tenpoCode].setOptions(polygonOptions);
  tempDisplayPolygon[tenpoCode].setMap(gmObject);
};

// 任意商圏の選択(グラフ描画に使用)
export const selectMarketArea = async (
  tenpoCode: string,
  dispatch: any,
  tempDisplayPolygon?: any
) => {
  if (displayMobakuPolygon[tenpoCode]) {
    displayMobakuPolygon[tenpoCode].setMap(null);
    delete displayMobakuPolygon[tenpoCode];
  }
  const marketAreaValue = await getFirestoreDocData(
    "market_area",
    getMarketAreaDocId(tenpoCode)
  ).then((response: any) => {
    return response;
  });
  if (tempSelectingPolygon) {
    selectingPolygon = tempDisplayPolygon;
    tempSelectingPolygon = null;
  } else {
    const polygon = JSON.parse(marketAreaValue.feature);
    selectingPolygon = geoJsonPolygon2GoogleMapsPolygon(polygon);
  }
  selectingPolygon.setOptions(selectedPolygonOptions);
  selectingPolygon.setMap(getModalGoogleMapsObject());
  dispatch(
    updateSelectingCvrMobakuItem({
      selectingCvrMobakuItem: ["market-area"],
    })
  );
  dispatch(
    updateCvrMobakuName({
      cvrMobakuName: "店舗の範囲",
    })
  );
  const updateLayer = () => {
    updateFireStoreLayer(cvrMobakuLayer);
  };
  // ipadでレイヤ更新処理がreduxの更新を追い越してしまうため遅延処理
  const sleepTime: number = 100;
  setTimeout(updateLayer, sleepTime);
};

// 任意商圏の選択解除
export const unselectMarketArea = async (dispatch: any) => {
  if (selectingPolygon) {
    selectingPolygon.setMap(null);
    selectingPolygon = null;
  }
};

// 個々のポリゴンを非表示にする
export const hidePolygon = (tenpoCode: string, isMobaku: boolean) => {
  const tempDisplayPolygon = isMobaku ? displayMobakuPolygon : displayPolygon;
  if (tempDisplayPolygon[tenpoCode]) {
    tempDisplayPolygon[tenpoCode].setMap(null);
    delete tempDisplayPolygon[tenpoCode];
  }
};

// 全てのポリゴンを非表示にする
export const hideAllPolygons = () => {
  if (displayMarketAreaFlag === false) return;
  const keys = Object.keys(displayPolygon);
  keys.forEach((value: string) => {
    displayPolygon[value].setMap(null);
  });
  displayPolygon = {};
};

// ポリゴンの編集
export const editPolygon = (
  polygon: any,
  gmObject: any,
  tenpoCode: string,
  tenpoLatLng: any,
  dispatch: any,
  isMobaku: boolean
) => {
  const tempDisplayPolygon = isMobaku ? displayMobakuPolygon : displayPolygon;
  dispatchRef = dispatch;
  if (tempDisplayPolygon[tenpoCode]) {
    tempDisplayPolygon[tenpoCode].setMap(null);
    delete tempDisplayPolygon[tenpoCode];
  }
  if (selectingPolygon) {
    tempSelectingPolygon = selectingPolygon;
    selectingPolygon.setMap(null);
    selectingPolygon = null;
  }
  // 排他制御開始
  if (isMobaku) {
    dispatch(
      updateExclusiveMobakuFlg({
        exclusiveMobakuFlg: true,
      })
    );
  } else {
    dispatch(
      updateExclusiveFlg({
        exclusiveFlg: true,
      })
    );
  }
  tenpoCodeParam = tenpoCode;
  tenpoLatLngParam = tenpoLatLng;
  // 編集可能なポリゴンを表示
  editingPolygon = geoJsonPolygon2GoogleMapsPolygon(polygon);
  editingPolygon.setOptions(polygonOptions);
  editingPolygon.setEditable(true);
  editingPolygon.setMap(gmObject);
  previousPolygon = new google.maps.Polygon();
  previousPolygon.setPath(editingPolygon.getPath().getArray());
  originalPolygon = new google.maps.Polygon();
  originalPolygon.setPath(editingPolygon.getPath().getArray());
  // 面積の初期化
  calculateAreaValue(dispatch, false);
  setEvent = google.maps.event.addListenerOnce(
    editingPolygon.getPath(),
    "set_at",
    editZoneSetAtEventFunc
  );
  insertEvent = google.maps.event.addListener(
    editingPolygon.getPath(),
    "insert_at",
    editZoneInsertAtEventFunc
  );
};

// ポリゴンの編集のやり直し
export const reEditPolygon = (
  isMobaku: boolean,
  tenpoLatLng: any,
  dispatch: any
) => {
  dispatchRef = dispatch;
  const gmObject = judgeGoogleMapObject(isMobaku);
  previousPolygon = new google.maps.Polygon();
  previousPolygon.setPath(editingPolygon.getPath().getArray());
  editingPolygon.setMap(null);
  editingPolygon.setPath(originalPolygon.getPath().getArray());
  editingPolygon.setOptions(polygonOptions);
  editingPolygon.setEditable(true);
  editingPolygon.setMap(gmObject);
  // 面積の初期化
  calculateAreaValue(dispatch, false);
  setEvent = google.maps.event.addListenerOnce(
    editingPolygon.getPath(),
    "set_at",
    editZoneSetAtEventFunc
  );
  insertEvent = google.maps.event.addListener(
    editingPolygon.getPath(),
    "insert_at",
    editZoneInsertAtEventFunc
  );
};

const editZoneSetAtEventFunc = (event: any) => {
  if (
    booleanCheckMarketArea(
      editingPolygon.getPath().getArray(),
      tenpoLatLngParam
    ) ||
    booleanWithinAreaValue(false)
  ) {
    // 店舗がポリゴンの外に出るかポリゴンの面積が制限を超えていないかを判定
    editingPolygon
      .getPath()
      .setAt(event, previousPolygon.getPath().getAt(event));
  } else if (booleanKinksPolygon(editingPolygon, false)) {
    // ポリゴンがよじれが生まれる場合は元に戻す
    editingPolygon
      .getPath()
      .setAt(event, previousPolygon.getPath().getAt(event));
    const msg = "範囲はよじれのないように作成してください";
    showToastMessage(msg);
  }
  previousPolygon.setPath(editingPolygon.getPath().getArray());
  // 面積表示
  calculateAreaValue(dispatchRef, false);
  // 再度イベント設定
  setEvent = google.maps.event.addListenerOnce(
    editingPolygon.getPath(),
    "set_at",
    editZoneSetAtEventFunc
  );
};

const editZoneInsertAtEventFunc = (event: any) => {
  if (
    booleanCheckMarketArea(
      editingPolygon.getPath().getArray(),
      tenpoLatLngParam
    ) ||
    booleanWithinAreaValue(false)
  ) {
    // 店舗がポリゴンの外に出るかポリゴンの面積が制限を超えていないかを判定
    editingPolygon.getPath().removeAt(event);
  } else if (booleanKinksPolygon(editingPolygon, false)) {
    // ポリゴンがよじれが生まれる場合は元に戻す
    editingPolygon.getPath().removeAt(event);
    const msg = "範囲はよじれのないように作成してください";
    showToastMessage(msg);
  }
  previousPolygon.setPath(editingPolygon.getPath().getArray());
  // 面積表示
  calculateAreaValue(dispatchRef, false);
};

// 変更したポリゴンを確定、登録する
export const registEditPolygon = async (
  dispatch: any,
  tenpoCode: string,
  isMobaku: boolean,
  tenpoLatLng: number[],
  user: any
) => {
  if (
    !booleanCheckMarketArea(editingPolygon.getPath().getArray(), tenpoLatLng) &&
    !booleanKinksPolygon(editingPolygon, false) &&
    !booleanWithinAreaValue(false)
  ) {
    const tempDisplayPolygon = isMobaku ? displayMobakuPolygon : displayPolygon;
    const gmObject = judgeGoogleMapObject(isMobaku);
    tempDisplayPolygon[tenpoCode] = new google.maps.Polygon(polygonOptions);
    tempDisplayPolygon[tenpoCode].setPath(editingPolygon.getPath().getArray());
    tempDisplayPolygon[tenpoCode].setMap(gmObject);
    setEvent.remove();
    insertEvent.remove();
    editingPolygon.setEditable(false);
    editingPolygon.setMap(null);
    if (tempSelectingPolygon) {
      // 変更前に範囲を選択していたら、変更後のポリゴンで再選択
      selectMarketArea(tenpoCode, dispatch, tempDisplayPolygon[tenpoCode]);
    }
    // 排他制御終了
    // if (isMobaku) {
    //   dispatch(
    //     updateExclusiveMobakuFlg({
    //       exclusiveMobakuFlg: false,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     updateExclusiveFlg({
    //       exclusiveFlg: false,
    //     })
    //   );
    // }
    userLogging("OPVIEW", "OPVIEW＃商圏設定_編集", "店番：" + tenpoCode);
    await registMarketArea(false, user);
  }
};

// ポリゴンの変更をキャンセルする
export const cancelEditPolygon = (dispatch: any, isMobaku: boolean) => {
  setEvent.remove();
  insertEvent.remove();
  editingPolygon.setEditable(false);
  editingPolygon.setMap(null);
  editingPolygon = null;
  if (tempSelectingPolygon) {
    // 変更前に範囲を選択していたら再選択
    selectingPolygon = tempSelectingPolygon;
    selectingPolygon.setOptions(selectedPolygonOptions);
    selectingPolygon.setMap(getModalGoogleMapsObject());
    tempSelectingPolygon = null;
    dispatch(
      updateSecondCvrItem({
        secondCvrItem: ["market-area"],
      })
    );
    updateCvrMobakuName({
      cvrMobakuName: "店舗の範囲",
    });
  }
  // 排他制御終了
  if (isMobaku) {
    dispatch(
      updateExclusiveMobakuFlg({
        exclusiveMobakuFlg: false,
      })
    );
  } else {
    dispatch(
      updateExclusiveFlg({
        exclusiveFlg: false,
      })
    );
  }
};

// 作成した任意商圏をfirestoreに保存する
export const registMarketArea = async (isNew: boolean = false, user: any) => {
  const registPolygon = isNew ? newPolygon : editingPolygon;
  const geoJsonPolygon = googleMapsPolygon2GeoJsonPolygon(registPolygon);
  const geoJsonPolygonStr = JSON.stringify(geoJsonPolygon);
  const centerLatLng = googleMapsObjects2CenterLatLng(registPolygon);
  const lat = centerLatLng.geometry.coordinates[1],
    lon = centerLatLng.geometry.coordinates[0];
  // @ts-ignore
  const geo = geofirex.init(firebase);
  let center = geo.point(lat, lon);
  let conditions = new Array();
  await getFirestoreData(
    "existing_sej_stores",
    "attribution.doc_id",
    tenpoCodeParam
  ).then((response: any) => {
    conditions = response.attribution.conditions;
  });
  // admin
  if (user.jobCategory === "admin-group") {
    conditions.splice(conditions.indexOf("ALL"), 1);
    conditions.push(user.employeeNo);
  }
  // トレーナー
  else if (isTrainingUser(user)) {
    conditions.splice(conditions.indexOf("ALL"), 1);
    conditions.push("TRAINER_ID_" + user.employeeNo);
  }
  // Fトレ生
  else if (isFTraineeUser(user)) {
    conditions.splice(conditions.indexOf("ALL"), 1);
    conditions.push("FC_TRAINEE_ID_" + user.employeeNo);
  }
  // 直営店社員
  else if (isTsUser(user)) {
    conditions.splice(conditions.indexOf("ALL"), 1);
    conditions.push("TS_TRAINEE_ID_" + user.employeeNo);
  }
  // ofcまたはsofc
  else {
    conditions.push("OFCID_" + user.employeeNo);
  }
  const doc_id = getMarketAreaDocId(tenpoCodeParam);
  const data = {
    doc_id: doc_id,
    feature: geoJsonPolygonStr,
    created_date: isNew ? firestore.FieldValue.serverTimestamp() : "",
    updated_date: firestore.FieldValue.serverTimestamp(),
    geography: {
      geohash: center.geohash,
      geopoint: center.geopoint,
    },
    attribution: {
      conditions: conditions,
    },
  };

  isNew &&
    userLogging("OPVIEW", "OPVIEW＃商圏設定_作成", "店番：" + tenpoCodeParam);
  await writeFirestoreDoc("market_area", doc_id, data);
};

// 作成した任意商圏をfirestoreから削除する
export const deleteMarketArea = async (
  tenpoCode: string,
  dispatch: any,
  isMobaku: boolean
) => {
  const tempDisplayPolygon = isMobaku ? displayMobakuPolygon : displayPolygon;
  if (tempDisplayPolygon[tenpoCode]) {
    tempDisplayPolygon[tenpoCode].setMap(null);
    delete tempDisplayPolygon[tenpoCode];
  }
  if (selectingPolygon) {
    selectingPolygon.setMap(null);
    selectingPolygon = null;
    dispatch(
      updateSecondCvrItem({
        secondCvrItem: [],
      })
    );
    dispatch(
      updateSelectingCvrMobakuItem({
        selectingCvrMobakuItem: [],
      })
    );
    dispatch(
      updateCvrMobakuName({
        cvrMobakuName: "",
      })
    );
  }
  initExistingSejStores(dispatch);
  userLogging("OPVIEW", "OPVIEW＃商圏設定_削除", "店番：" + tenpoCode);
  await deleteFirestoreDoc("market_area", getMarketAreaDocId(tenpoCode));
};

// 作成した任意商圏をfirestoreに保存する
export const updateDsiplayFlgMarketArea = async (
  tenpoCodeParam: string,
  displayFlg: string
) => {
  let data;
  const doc_id = getMarketAreaDocId(tenpoCodeParam);
  await getFirestoreData("market_area", "doc_id", doc_id).then(
    (response: any) => {
      data = response;
      data.isDisplay = displayFlg;
    }
  );
  if (data) {
    await writeFirestoreDoc("market_area", doc_id, data);
  }
};

// ポリゴン配列の現在の面積を計算
const calculateAreaValue = (dispatch: any, isNew: boolean) => {
  let marketAreaValue = 0;
  if (isNew) {
    if (posPolygonArray.length >= 3) {
      newPolygon.setPath(posPolygonArray);
      marketAreaValue = googleMapsObjects2area(newPolygon);
    }
  } else {
    const editPolygon = new google.maps.Polygon(polygonOptions);
    editPolygon.setPath(editingPolygon.getPath().getArray());
    marketAreaValue = googleMapsObjects2area(editPolygon);
  }
  dispatch(
    updateMarketAreaValue({
      marketAreaValue: marketAreaValue.toLocaleString(),
    })
  );
};

// 決定時のポリゴン面積を取得
const getAreaValue = (isNew: boolean) => {
  let targerPolygon;
  if (isNew) {
    newPolygon.setPath(posPolygonArray);
    targerPolygon = newPolygon;
  } else {
    const editPolygon = new google.maps.Polygon(polygonOptions);
    editPolygon.setPath(editingPolygon.getPath().getArray());
    targerPolygon = editPolygon;
  }
  const areaValue = googleMapsObjects2area(targerPolygon);
  return areaValue;
};

// ポリゴン配列の現在の周の長さを計算
// const calculateCircumference = () => {
//   if (posPolygonArray.length >= 2) {
//     let circumference = 0;
//     for (let i = 0; i < posPolygonArray.length - 1; i++) {
//       circumference += point2distance(
//         posPolygonArray[i],
//         posPolygonArray[i + 1]
//       );
//     }
//     return circumference;
//   } else {
//     return "";
//   }
// };

// ポリゴン配列の最後のデータを削除
export const removePolygonPoint = (isMobaku: boolean, dispatch: any) => {
  const gmObject = judgeGoogleMapObject(isMobaku);
  if (posPolygonArray.length === 1) {
    posPolygonArray.pop();
    clearStartPoint();
    clearChasePolygonLine(true);
    initChasePolygonLine(gmObject);
  } else if (posPolygonArray.length >= 2) {
    posPolygonArray.pop();
    setPolygonLine();
    clearChasePolygonLine(false);
    initChasePolygonLine(gmObject);
    // 面積表示
    calculateAreaValue(dispatch, true);
  }
};

const clearPosPolygonArray = () => {
  posPolygonArray = [];
};

export const clearPolygon = () => {
  if (newPolygon != null) {
    newPolygon.setMap(null);
    newPolygon = null;
  }
};

const clearStartPoint = () => {
  if (startPoint != null) {
    startPoint.setMap(null);
    startPoint = null;
  }
};

const clearPolygonLine = () => {
  if (polygonLine != null) {
    polygonLine.setMap(null);
    polygonLine = null;
  }
};

const clearChasePolygonLine = (isClearListener: any) => {
  if (chasePolygonLine != null) {
    if (isClearListener) {
      google.maps.event.clearListeners(overlayPolygon, "mousemove");
    }
    chasePolygonLine.setMap(null);
    chasePolygonLine = null;
  }
};

// const setCompleteEvent = () => {
//   const element = document.getElementById("marketAreaMenu");
//   element?.addEventListener("completePolygon", function (e, polygon) {
//     setPolygonObject(polygon);
//     // initMemoRegist("polygon", polygon, googleMapsObjects2area(polygon));
//   });
// };

const clearOverlayPolygon = () => {
  if (overlayPolygon != null) {
    overlayPolygon.setMap(null);
    overlayPolygon = null;
  }
};

export const clearPolygonSettings = () => {
  clearPosPolygonArray();
  clearPolygon();
  clearStartPoint();
  clearPolygonLine();
  clearChasePolygonLine(true);
  clearOverlayPolygon();
};

export const polygonStartPointSettings = (latLng: any, gmap: any) => {
  return {
    position: latLng,
    map: gmap,
  };
};

// ポリゴン新規作成時にポリゴンによじれがあるかを判定
const booleanKinksError = (isConfirm: boolean) => {
  let msg;
  if (isConfirm) {
    newPolygon.setPath(posPolygonArray);
    // ポリゴン確定時によじれを判定
    if (booleanKinksPolygon(newPolygon, true)) {
      // よじれているポリゴンの場合
      msg = "範囲はよじれのないように作成してください";
      showToastMessage(msg);
    }
  } else {
    // ポリゴンの点追加時に交差するラインを判定
    let currentPolygonLine: any = new google.maps.Polyline();
    let addPolygonLine: any = new google.maps.Polyline();
    let arrayLastPos = posPolygonArray.length - 1;
    currentPolygonLine.setPath(posPolygonArray.slice(0, arrayLastPos));
    addPolygonLine.setPath([
      posPolygonArray[arrayLastPos],
      posPolygonArray[arrayLastPos - 1],
    ]);
    if (lineIntersectPoints(currentPolygonLine, addPolygonLine).length > 1) {
      // よじれているポリゴンの場合
      msg = "範囲はよじれのないように作成してください";
      showToastMessage(msg);
    }
    currentPolygonLine = null;
    addPolygonLine = null;
  }
  return msg !== undefined;
};

/**
 * ポリゴンがよじれているかを判定(よじれていればtrue)
 * @param {*} polygon
 */
const booleanKinksPolygon = (polygon: any, isNew: boolean) => {
  const standard = isNew ? 0 : 1;
  let intersectPoints = turf.kinks(googleMapsPolygon2GeoJsonPolygon(polygon));
  return intersectPoints.features.length > standard;
};

// 商圏内に店舗があるかを判定(入っていなければtrue)
const booleanCheckMarketArea = (polygonArr: any, point: any) => {
  let msg;
  let polygon = new google.maps.Polygon();
  polygon.setPath(polygonArr);
  let latlng = new google.maps.LatLng(point[0], point[1]);
  let marker = new google.maps.Marker({
    position: latlng,
  });
  if (!getBooleanPointInPolygon(polygon, marker)) {
    msg = "店舗が範囲に入るように設定してください";
    showToastMessage(msg);
  }
  return msg !== undefined;
};

// 商圏の面積が制限を超えていないかを判定(超えていればtrue)
const booleanWithinAreaValue = (isNew: boolean) => {
  let msg;
  // const areaLimit = 250000;
  // const areaValue = Number(getAreaValue(isNew));
  // if (areaValue > areaLimit) {
  //   msg = "範囲の面積が25万㎡を超えないように設定してください";
  //   showToastMessage(msg);
  // }
  return msg !== undefined;
};

/**
 * GoogleMapsのオブジェクトの面積（小数点第二位以下は切り捨て）を返します。
 * @export
 */
function googleMapsObjects2area(googleMapsPolygon: any) {
  // turf用のポリゴンに変換
  let polygon = googleMapsPolygon2GeoJsonPolygon(googleMapsPolygon);
  // ポリゴンの面積を計算
  return Math.round(turf.area(polygon));
}

/**
 * GoogleMapsのポリゴンをturfでGeoJSONのポリゴンに変換します。
 */
const googleMapsPolygon2GeoJsonPolygon = (polygon: any) => {
  const polygonPath = polygon.getPath();
  let turfPolygonPath = [];
  // turfのポリゴンは最初と最後に同じパスを設定する必要がある為、最初のパスを保存
  const firstPath = [
    polygonPath.getAt(0)["lng"](),
    polygonPath.getAt(0)["lat"](),
  ];
  polygon.getPath().forEach((value: any, ii: any) => {
    let path = [polygonPath.getAt(ii)["lng"](), polygonPath.getAt(ii)["lat"]()];
    // turf用のポリゴンパスを作成
    turfPolygonPath.push(path);
  });
  turfPolygonPath.push(firstPath);
  // turf用ポリゴンを返す
  return turf.polygon([turfPolygonPath]);
};

/**
 * GoogleMapsのポイントをturfでGeoJSONのポイントに変換します。
 */
export const googleMapsPoint2GeoJsonPoint = (point: any) => {
  // turf用ポイントを返す
  return turf.point([point.getPosition().lng(), point.getPosition().lat()]);
};

/**
 * GeoJSONのポリゴンをGoogleMapsのポリゴンに変換します。
 */
const geoJsonPolygon2GoogleMapsPolygon = (polygon: any) => {
  let lnglat = polygon.geometry.coordinates[0];
  let googleMapsLatLng: any = [];
  lnglat.forEach((value: any) => {
    googleMapsLatLng.push(new google.maps.LatLng(value[1], value[0]));
  });
  return new google.maps.Polygon({ paths: googleMapsLatLng });
};

/**
 * ラインが重なっている場合に重なっている点を返す
 * @param {*} line1
 * @param {*} line2
 */
const lineIntersectPoints = (line1: any, line2: any) => {
  let intersectPoints = lineIntersect(
    googleMapsLine2GeoJsonLine(line1),
    googleMapsLine2GeoJsonLine(line2)
  );
  let points: any[] = [];
  intersectPoints.features.forEach((feature: any) => {
    points.push(
      new google.maps.Marker({
        position: new google.maps.LatLng(
          feature.geometry.coordinates[1],
          feature.geometry.coordinates[0]
        ),
      })
    );
  });
  return points;
};

/**
 * ポリゴン内に指定したポイントが入っているか確認する
 * ポリゴンもポイントもgooglemapsオブジェクト
 * @param {*} polygon
 * @param {*} point
 */
export const getBooleanPointInPolygon = (polygon: any, point: any) => {
  return booleanPointInPolygon(
    googleMapsPoint2GeoJsonPoint(point),
    googleMapsPolygon2GeoJsonPolygon(polygon)
  );
};

/**
 * GoogleMapsのポイントをturfでGeoJSONのラインに変換します。
 */
const googleMapsLine2GeoJsonLine = (line: any) => {
  const linePath = line.getPath();
  let turfLinePath: any[] = [];
  line.getPath().forEach((value: any, ii: any) => {
    let path = [linePath.getAt(ii)["lng"](), linePath.getAt(ii)["lat"]()];
    // turf用のラインパスを作成
    turfLinePath.push(path);
  });
  // turf用ラインを返す
  return turf.lineString(turfLinePath);
};

/**
 * GoogleMapsのポリゴンからGoogleMapsAPI形式の中心座標を返します。
 * @export
 */
const googleMapsObjects2CenterLatLng = (googleMapsPolygon: any) => {
  // turf用のポリゴンに変換
  let polygon = googleMapsPolygon2GeoJsonPolygon(googleMapsPolygon);
  // ポリゴンの中心地点を取得
  const center = centerOfMass(polygon);
  // return new google.maps.LatLng(
  //   center.geometry.coordinates[1],
  //   center.geometry.coordinates[0]
  // );
  return center;
};

const judgeGoogleMapObject = (isMobaku: boolean) => {
  if (isMobaku) {
    return getModalGoogleMapsObject();
  } else {
    return getGoogleMapsObject();
  }
};

// ポリゴンの作成・編集時のエラーメッセージを表示する
export function showToastMessage(msg: string) {
  const displayTime = 2000;
  const target = document.querySelector(".toast");
  const toastMessage = document.getElementById("toastMessage");
  if (target && toastMessage) {
    toastMessage.textContent = msg;
    target.classList.toggle("showToast");
    target.classList.toggle("hideToast");
    setTimeout(function () {
      target.classList.toggle("showToast");
      target.classList.toggle("hideToast");
    }, displayTime);
  }
}
