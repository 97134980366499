import React, { FC } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid, Typography } from "@material-ui/core";
import { selectImageToken } from "../../../features/userSlice";
import InfoTip from "../../common/InfoTip";

function getModalStyle() {
  return {
    top: "5%",
    left: "5%",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      width: "50%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    columnHeight: {
      minHeight: "9vh",
    },
  })
);

const OneVisionModalManual: FC<{
  title: string;
  title2: string;
  filePath?: string[];
}> = ({ title, title2, filePath }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const imageToken = useSelector(selectImageToken);
  const handleOpen = () => {
    if (filePath && filePath.length > 0) {
      setOpen(true);
    }
  };
  let imgFileList: string[] = [];
  if (filePath && filePath.length > 0) {
    for (let i = 0; i < filePath.length; i++) {
      imgFileList.push(
        process.env.REACT_APP_CLOUD_STORAGE_URL +
          filePath[i] +
          "?access_token=" +
          imageToken
      );
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        className={classes.columnHeight}
        container
        alignItems="center"
        style={{ gridColumn: "9vh minmax(9vh, 16vh)" }}
        onClick={handleOpen}
      >
        <Grid item>
          <Typography>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography>{title2 ? "　　" + title2 : ""}</Typography>
        </Grid>
        {imgFileList && imgFileList.length > 0 ? (
          <InfoTip
            iconType="info"
            infoText=""
            placement="top"
            noTitleFlg={true}
          />
        ) : (
          ""
        )}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ height: "95vh", overflowY: "auto" }}
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 id="simple-modal-title">{title}</h3>
          {imgFileList
            ? imgFileList.map((res: any) => (
                <div>
                  <img
                    alt=""
                    src={res}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
              ))
            : ""}
        </div>
      </Modal>
    </>
  );
};
export default OneVisionModalManual;
