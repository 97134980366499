import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { DateTime } from "luxon";
import Header from "../../Header/Header";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Modal, Button } from "@material-ui/core";
import DatePicker from "../../common/DatePicker";
import TSCheckManagerAnalysisResult from "./TSCheckManagerAnalysisResult";
import { selectUser } from "../../../features/userSlice";
import { getAllZone, getAllDo } from "../../../data/existingSejStores";
import SearchZO from "../../organisms/search/searchZO/SearchZO";
import SearchDO from "../../organisms/search/searchDO/SearchDO";
import {
  stylesSearchDO,
  stylesSearchZO,
} from "../Search/StylesTSCheckSearch";
import {
  onlyArea,
  getAuthTenpoCheck,
} from "../../organisms/search/specification/UsageAuthority/AuthTenpoCheck";
import {
  updateTenpoName,
  updateZoCode,
  updateZoName,
  updateDoCode,
  updateDoName,
} from "../../organisms/search/TenpoCheckSearchSlice";
import { isFirebaseAuth } from "../../../firebase";
import { isOpenManagerAnalysisPage } from "../../../apis/privilege";
import { userLogging } from "../../../apis/userLog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
  },
  modalPane: {
    position: "absolute",
    paddingTop: "2%",
    paddingLeft: "15%",
    width: "60vw",
    minWidth: "600px",
    height: "80vh",
  },
  searchArea: {
    border: "solid",
    flex: 1,
    margin: "auto",
    alignItems: "center",
    width: "60vw",
    backgroundColor: "#fff",
    paddingBottom: "15px",
  },
  excuteButton: {
    color: "#ffffff",
    backgroundColor: "#4dc0b2",
    fontSize: "20px",
    marginLeft: "4vw",
    "&:hover": {
      backgroundColor: "#4ab5a1",
    },
  },
  searchItem: {
    marginTop: "5px",
    marginLeft: "10px",
    marginBottom: "10px",
  },
  resultItem: {
    marginTop: "15px",
  },
}));

function setSelectItem(code: string, name: string) {
  return { code, name };
}

const KanrishaAnalysis: React.FC = (props: any) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [click, setClick] = React.useState(false);
  const [updateFlg, setUpdateFlg] = React.useState(true);
  const [selectedBaseDate, setSelectedBaseDate] =
    React.useState<DateTime | null>(DateTime.local());
  const [executeButtonFlg, setExecuteButtonFlg] = React.useState(false);
  const zoneMaster = getAllZone().zone_data;
  const doMaster = getAllDo().do_data;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  function initZoCode() {
    if (user.jobCategory === "zm") {
      if (user.zoCode && user.zoCode.length === 1) {
        return user.zoCode[0];
      }
    }
    return "";
  }
  function initDoCode() {
    if (user.jobCategory === "dm") {
      if (user.doCode) {
        return user.doCode[0];
      }
    }
    return "";
  }
  function initAnalysis() {
    if (user.jobCategory === "admin-group") {
      return 0;
    } else if (user.jobCategory === "zm") {
      return 1;
    } else if (user.jobCategory === "dm") {
      return 2;
    }
    return 0;
  }
  const [analysis, setAnalysis] = React.useState(initAnalysis());
  const [zoCode, setZoCode] = React.useState(initZoCode());
  const [doCode, setDoCode] = React.useState(initDoCode());

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let zoList = [setSelectItem("", "")];
  let doList = [setSelectItem("", "")];
  for (let i = 0; i < user.zoCode.length; i++) {
    if (zoneMaster[user.zoCode[i]]) {
      zoList.push(
        setSelectItem(user.zoCode[i], zoneMaster[user.zoCode[i]].zone_name)
      );
    }
  }
  for (let i = 0; i < user.doCode.length; i++) {
    if (doMaster[user.doCode[i]]) {
      doList.push(
        setSelectItem(user.doCode[i], doMaster[user.doCode[i]].do_name)
      );
    }
  }

  const handleBaseDate = (date: DateTime | null) => {
    setSelectedBaseDate(date);
  };

  const executeCheck = (flag: boolean) => {
    setUpdateFlg(flag);
  };

  const handleClick = () => {
    setClick((prev) => !click);
  };

  // 初期表示処理
  useEffect(() => {
    dispatch(
      updateTenpoName({
        tenpoName: "",
      })
    );
    dispatch(
      updateZoCode({
        zoCode: "",
      })
    );
    dispatch(
      updateZoName({
        zoName: "",
      })
    );
    dispatch(
      updateDoCode({
        doCode: "",
      })
    );
    dispatch(
      updateDoName({
        doName: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // 実行ボタンの制御
  useEffect(() => {
    if (zoCode !== "" || doCode !== "") {
      setExecuteButtonFlg(true);
    } else {
      setExecuteButtonFlg(false);
    }
  }, [zoCode, doCode]);

  // 画面表示可否
  if (!isFirebaseAuth()) {
    return <></>;
  }
  if (!isOpenManagerAnalysisPage(user)) {
    return <Redirect to="/home" />;
  }

  // 実行ボタン押下時処理
  const doExecute = () => {
    userLogging("店舗衛生点検表", "管理者分析#検索ボタン押下", "");
    executeCheck(!updateFlg);
    if (zoCode) {
      setAnalysis(1);
    } else {
      setAnalysis(2);
    }
    handleClose();
    handleClick();
  };

  // 検索ボタン押下時処理
  function saveStatusBottun(
    argZoCode: string,
    argZoName: string,
    argDoCode: string,
    argDoName: string
  ) {
    dispatch(
      updateZoCode({
        zoCode: argZoCode,
      })
    );
    dispatch(
      updateZoName({
        zoName: argZoName,
      })
    );
    dispatch(
      updateDoCode({
        doCode: argDoCode,
      })
    );
    dispatch(
      updateDoName({
        doName: argDoName,
      })
    );
    setZoCode(argZoCode);
    setDoCode(argDoCode);
  }

  function clickSearch(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleOpen();
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalPane}
      >
        <Grid container className={classes.searchArea}>
          {/*検索欄*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#37AB9D",
              fontWeight: "bolder",
              textAlign: "center",
              height: "40px",
            }}
          >
            条件変更
          </Grid>

          <Grid container>
            {/*L1*/}
            {user.jobCategory !== "dm" ? (
              <>
                <Grid container>
                  <Grid
                    style={{
                      paddingTop: "15px",
                      paddingLeft: "15px",
                      fontWeight: "bolder",
                    }}
                  >
                    ZOを選択
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.searchItem}
                  justify="center"
                  alignItems="center"
                >
                  <SearchZO
                    useStyles={stylesSearchZO}
                    disabled={
                      getAuthTenpoCheck(user.jobCategory).search < onlyArea
                    }
                    firstView={false}
                    toggleStatus={false}
                    searchMode={0}
                    onClickFunc={(zoCode: string, zoName: string) => {
                      saveStatusBottun(zoCode, zoName, "", "");
                    }}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {/*L3*/}
            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                DOを選択
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.searchItem}
              justify="center"
              alignItems="center"
            >
              <SearchDO
                useStyles={stylesSearchDO}
                disabled={false}
                firstView={false}
                toggleStatus={false}
                searchMode={getAuthTenpoCheck(user.jobCategory).search}
                onClickFunc={(doCode: string, doName: string) => {
                  saveStatusBottun("", "", doCode, doName);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              borderTop: "1px solid #aaa",
            }}
          >
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                fontWeight: "bolder",
              }}
            >
              基準日を選択
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.searchItem}
            justify="center"
            alignItems="center"
          >
            <DatePicker
              selectedDate={selectedBaseDate}
              dateFunc={(date: DateTime | null) => {
                handleBaseDate(date);
              }}
              maxDate={DateTime.fromFormat("99991231", "yyyyMMdd")}
              mainColor={"#4dc0b2"}
            />
          </Grid>

          <Grid
            container
            className={classes.searchItem}
            justify="center"
            alignItems="center"
          >
            <Grid container justify="center">
              <Button
                className={classes.excuteButton}
                variant="contained"
                color="primary"
                disabled={!executeButtonFlg}
                onClick={() => doExecute()}
              >
                実行
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <Grid container className={classes.root} justify="center">
        <Grid item xs={12}>
          <Header
            titleName={"店舗衛生点検表管理者分析"}
            modalCondition={(e) => clickSearch(e)}
          />
          {/* メインコンテンツ↓ここから */}
          <Grid
            container
            alignItems="flex-start"
            justify={"center"}
            className={classes.resultItem}
          >
            <TSCheckManagerAnalysisResult
              zoCode={zoCode}
              doCode={doCode}
              baseData={selectedBaseDate}
              update={updateFlg}
              analysis={analysis}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default KanrishaAnalysis;
