import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
interface USER {
  uid: string;
  displayName: string;
  jobCategory: string;
  jobCategoryCodeOA: string;
  departmentCodeOA: string;
  mailAddress: string;
  // 担当ZONEコード
  zoCode: string[];
  // 担当DOコード
  doCode: string[];
  deviceType: Boolean;
  ios: Boolean;
  iphone: Boolean;
  tenpoCode: string[];
  // GoogleCloudStorageの画像アクセス用のトークン
  imageToken: string;
  notification: {};
  employeeNo: string;
  opviewSofcFlag: false;
  tenpoCodeSpecialList: string[];
  apps: string[];
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      uid: "",
      displayName: "",
      jobCategory: "",
      jobCategoryCodeOA: "",
      departmentCodeOA: "",
      mailAddress: "",
      zoCode: [],
      doCode: [],
      deviceType: false,
      ios: false,
      iphone: false,
      tenpoCode: [],
      imageToken: "",
      notification: { flag: false, title: "", text: "", term: "" },
      employeeNo: "",
      opviewSofcFlag: false,
      tenpoCodeSpecialList: [],
      apps: [],
    },
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = {
        uid: "",
        displayName: "",
        jobCategory: "",
        jobCategoryCodeOA: "",
        departmentCodeOA: "",
        mailAddress: "",
        zoCode: [],
        doCode: [],
        deviceType: false,
        ios: false,
        iphone: false,
        tenpoCode: [],
        imageToken: "",
        notification: { flag: false, title: "", text: "", term: "" },
        employeeNo: "",
        opviewSofcFlag: false,
        tenpoCodeSpecialList: [],
        apps: [],
      };
    },
    updateUserProfile: (state, action: PayloadAction<USER>) => {
      state.user.uid = action.payload.uid;
      state.user.displayName = action.payload.displayName;
    },
    setIsDeviceType: (state, action) => {
      state.user.deviceType = action.payload.deviceType;
      state.user.ios = action.payload.ios;
      state.user.iphone = action.payload.iphone;
    },
    updateImageToken: (state, action) => {
      state.user.imageToken = action.payload.imageToken;
    },
    updateNotification: (state, action) => {
      state.user.notification = action.payload.notification;
    },
  },
});

export const {
  login,
  logout,
  updateUserProfile,
  setIsDeviceType,
  updateImageToken,
  updateNotification,
} = userSlice.actions;
export const selectUser = (state: RootState) => state.user.user;
export const selectIsDeviceType = (state: RootState) =>
  state.user.user.deviceType;
export const selectIsIos = (state: RootState) => state.user.user.ios;
export const selectIsIphone = (state: RootState) => state.user.user.iphone;
export const selectImageToken = (state: RootState) =>
  state.user.user.imageToken;
export const selectNotification = (state: RootState) =>
  state.user.user.notification;
export default userSlice.reducer;
