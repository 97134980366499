import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Header, {
  getFirstFlg,
  getFixedFlg,
  getInitTenpoCode,
  setFirstFlg,
} from "../../Header/Header";
import OneVisionTab from "./OneVisionTab";
import { Grid } from "@material-ui/core";
import { getTenpoFirestoreData } from "./OneVisionDataOperation";
import { Redirect } from "react-router-dom";
import { isFirebaseAuth } from "../../../firebase";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { isOpenRegistPage } from "../../../apis/privilege";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRoot: {
      flexGrow: 1,
    },
    header: {
      flexGrow: 1,
    },
    headerTitle: {
      backgroundColor: "#008837",
    },
    headerToolbar: {
      // ヘッダーの縦の調整はminHeight を再設定する
      [theme.breakpoints.down("sm")]: {
        minHeight: "36px",
      },
      [theme.breakpoints.up("md")]: {
        minHeight: "48px",
      },
    },
    root: {
      height: "100vh",
    },
    gridMargin: {
      padding: "1%",
    },
  })
);

// 対象店舗確認ID
let initOneVisionId: string = "";
let initLastOneVisionId: string = "";
export function setOneVisionId(id: any) {
  initOneVisionId = id;
}
export function getOneVisionId() {
  return initOneVisionId;
}
export function setLastOneVisionId(id: any) {
  initLastOneVisionId = id;
}
export function getLastOneVisionId() {
  return initLastOneVisionId;
}
const Home: React.FC = (props: any) => {
  const classes = useStyles();

  const [tenpoCode, setTenpoCode] = React.useState(getInitTenpoCode());
  const [oneVisionId, setOneVisionId] = React.useState("");
  const [lastOneVisionId, setLastOneVisionId] = React.useState("");
  const [updateFlg, setUpdateFlg] = React.useState("");
  const user = useSelector(selectUser);

  const tenpoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTenpoCode(event.target.value as string);
  };

  useEffect(() => {
    if (tenpoCode === "" || getFirstFlg()) {
      setFirstFlg(false);
      setTenpoCode("");
      setOneVisionId("");
      setLastOneVisionId("");
      return;
    }
    if (initOneVisionId === "") {
      getTenpoFirestoreData(tenpoCode).then((res: any) => {
        if (res.attribution.one_vision) {
          if (user.jobCategory === "dm") {
            if (res.attribution.one_vision.dm) {
              setOneVisionId(res.attribution.one_vision.dm.next_time_id);
              setLastOneVisionId(res.attribution.one_vision.dm.last_time_id);
            } else {
              setOneVisionId(tenpoCode + "-firstId-dm");
              setLastOneVisionId("");
            }
          } else if (user.jobCategory === "zm") {
            if (res.attribution.one_vision.zm) {
              setOneVisionId(res.attribution.one_vision.zm.next_time_id);
              setLastOneVisionId(res.attribution.one_vision.zm.last_time_id);
            } else {
              setOneVisionId(tenpoCode + "-firstId-zm");
              setLastOneVisionId("");
            }
          } else {
            if (res.attribution.one_vision.ofc) {
              setOneVisionId(res.attribution.one_vision.ofc.next_time_id);
              setLastOneVisionId(res.attribution.one_vision.ofc.last_time_id);
            } else {
              setOneVisionId(res.attribution.one_vision.next_time_id);
              setLastOneVisionId(res.attribution.one_vision.last_time_id);
            }
          }
        }
      });
    } else {
      setOneVisionId(initOneVisionId);
      setLastOneVisionId(initLastOneVisionId);
    }
    setFirstFlg(false);
  }, [tenpoCode, updateFlg, getFirstFlg()]);

  // 画面表示可否
  if (!isFirebaseAuth()) {
    return <></>;
  }
  if (
    (!isOpenRegistPage(user) && !getFixedFlg()) ||
    (tenpoCode === "" && getFixedFlg())
  ) {
    return <Redirect to="/home" />;
  }

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item xs={12}>
        <Header titleName={"One Vision"} tenpoChange={(e) => tenpoChange(e)} />
        <Grid item xs={12} className={classes.gridMargin}>
          {tenpoCode === "" ? (
            <Grid container justify="center">
              右上の店舗名より新規作成する店舗を選択してください。
            </Grid>
          ) : oneVisionId !== "" ? (
            <OneVisionTab
              tenpoCode={tenpoCode}
              oneVisionId={oneVisionId}
              lastOneVisionId={lastOneVisionId}
              setUpdateFlg={setUpdateFlg}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
