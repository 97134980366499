import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Select, Button } from "@material-ui/core";
import { Grid, MenuItem } from "@material-ui/core";
import { FormControl, FormHelperText } from "@material-ui/core";
import { Table, TableCell, TableContainer, TableRow } from "@material-ui/core";
import Header from "../../Header/Header";
import DatePicker from "../../common/DatePicker";
import { DateTime } from "luxon";
import OneVisionManagerAnalysisResult from "./OneVisionManagerAnalysisResult";
import { selectUser } from "../../../../src/features/userSlice";
import { getAllZone, getAllDo } from "../../../data/existingSejStores";
import {
  getAuthTenpoCheck,
  onlyArea,
  onlyAll,
} from "../../organisms/search/specification/UsageAuthority/AuthTenpoCheck";
import SearchZO from "../../organisms/search/searchZO/SearchZO";
import SearchDO from "../../organisms/search/searchDO/SearchDO";
import {
  stylesSearchZO,
  stylesSearchDO,
} from "./StylesOneVisionManagerAnalysis";
import {
  updateZoCode,
  updateZoName,
  updateDoCode,
  updateDoName,
} from "../../../components/organisms/search/TenpoCheckSearchSlice";
import { isFirebaseAuth } from "../../../firebase";
import { Redirect } from "react-router-dom";
import { isOpenManagerAnalysisPage } from "../../../apis/privilege";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
  },
  search: {
    border: "solid",
    flex: 1,
    margin: "auto",
    alignItems: "center",
    width: "50vw",
    backgroundColor: "#fff",
    marginTop: "15px",
  },
}));

function setSelectItem(code: string, name: string) {
  return { code, name };
}

const ManagerAnalysis: React.FC = (props: any) => {
  const classes = useStyles();
  const [click, setClick] = React.useState(false);
  const [zoCode, setZoCode] = React.useState("");
  const [doCode, setDoCode] = React.useState("");
  const [exeFlg, setExeFlg] = React.useState(false);
  const zoneMaster = getAllZone().zone_data;
  const doMaster = getAllDo().do_data;
  const dispatch = useDispatch();

  const [searchZoCode, setSearchZoCode] = React.useState("");
  const [searchDoCode, setSearchDoCode] = React.useState("");
  const [searchDate, setSearchDate] = React.useState<DateTime | null>(
    DateTime.local()
  );
  const [selectedBaseDate, setSelectedBaseDate] =
    React.useState<DateTime | null>(DateTime.local());

  const handleBaseDate = (date: DateTime | null) => {
    setSelectedBaseDate(date);
  };

  const user = useSelector(selectUser);
  let zoList = [setSelectItem("", "")];
  let doList = [setSelectItem("", "")];

  // 画面表示可否
  if (!isFirebaseAuth()) {
    return <></>;
  }
  if (!isOpenManagerAnalysisPage(user)) {
    return <Redirect to="/home" />;
  }

  for (let i = 0; i < user.zoCode.length; i++) {
    if (zoneMaster[user.zoCode[i]]) {
      zoList.push(
        setSelectItem(user.zoCode[i], zoneMaster[user.zoCode[i]].zone_name)
      );
    }
  }
  for (let i = 0; i < user.doCode.length; i++) {
    if (doMaster[user.doCode[i]]) {
      doList.push(
        setSelectItem(user.doCode[i], doMaster[user.doCode[i]].do_name)
      );
    }
  }

  // 押下時処理
  function saveStatusBottun(
    argZoCode: string,
    argZoName: string,
    argDoCode: string,
    argDoName: string
  ) {
    dispatch(
      updateZoCode({
        zoCode: argZoCode,
      })
    );
    dispatch(
      updateZoName({
        zoName: argZoName,
      })
    );
    dispatch(
      updateDoCode({
        doCode: argDoCode,
      })
    );
    dispatch(
      updateDoName({
        doName: argDoName,
      })
    );
    setZoCode(argZoCode);
    setDoCode(argDoCode);
  }

  const handleClick = () => {
    setClick((prev) => !click);
  };

  // 実行ボタン押下時処理
  const doExcute = () => {
    setSearchZoCode(searchZoCode);
    setSearchDoCode(searchDoCode);
    setSearchDate(selectedBaseDate || null);
    setExeFlg(true);
    handleClick();
  };

  return (
    <Grid container className={classes.root} justify="center">
      <Grid container>
        <Grid item xs={12}>
          <Grid>
            <Header titleName={"One Vision管理者分析"} />

            {/*検索欄*/}
            <Grid container justify="center" className={classes.search}>
              <Grid container>
                {/*L1*/}
                <Grid container justify="center">
                  <Grid container>
                    <Grid style={{ fontWeight: "bolder", marginLeft: "10px" }}>
                      絞込み条件
                    </Grid>
                  </Grid>
                </Grid>

                {/*L2*/}
                <Grid container justify="center">
                  <Grid container>
                    <Grid style={{ marginLeft: "10px" }}>
                      <FormHelperText>ZO</FormHelperText>
                      <SearchZO
                        useStyles={stylesSearchZO}
                        disabled={
                          getAuthTenpoCheck(user.jobCategory).search < onlyArea
                        }
                        firstView={false}
                        toggleStatus={false}
                        searchMode={0}
                        onClickFunc={(zoCode: string, zoName: string) => {
                          saveStatusBottun(zoCode, zoName, "", "");
                        }}
                      />
                    </Grid>
                    <Grid style={{ marginLeft: "10px" }}>
                      <FormHelperText>DO</FormHelperText>
                      <SearchDO
                        useStyles={stylesSearchDO}
                        disabled={false}
                        firstView={false}
                        toggleStatus={false}
                        searchMode={getAuthTenpoCheck(user.jobCategory).search}
                        onClickFunc={(doCode: string, doName: string) => {
                          saveStatusBottun("", "", doCode, doName);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*L3*/}
                <Grid container justify="center">
                  <Grid container>
                    <Grid style={{ marginLeft: "10px" }}>
                      <FormHelperText>基準日</FormHelperText>
                      <DatePicker
                        selectedDate={selectedBaseDate}
                        dateFunc={(date: DateTime | null) => {
                          handleBaseDate(date);
                        }}
                        maxDate={DateTime.fromFormat("29991231", "yyyyMMdd")}
                        mainColor={"#4dc0b2"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*L4*/}
                <Grid
                  style={{
                    marginTop: "10px",
                    marginLeft: "35px",
                    marginBottom: "15px",
                  }}
                >
                  <Button
                    style={{ fontSize: "20px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => doExcute()}
                  >
                    実行
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Table style={{ height: "10px" }}></Table>
            {exeFlg ? (
              <Table>
                <TableRow>
                  <OneVisionManagerAnalysisResult
                    zoCode={searchZoCode}
                    doCode={searchDoCode}
                    baseData={searchDate}
                  />
                </TableRow>
              </Table>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManagerAnalysis;
