import React, { useEffect, FC } from "react";
import FormControl from "@material-ui/core/FormControl";
import {
  Checkbox,
  Popover,
  Radio,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { TENPO_CHECK_RECORD, USERNGMAP } from "./TenpoCheckTableDataOperation";
import { getFixedFlg } from "../../Header/Header";
import { stylesTenpoCheckTable } from "./StylesTenpoCheckRegister";

interface reasonMap {
  comment_list: reasons[];
  value: string;
}

interface reasons {
  name: string;
  value: string;
}
const OTHER_VALUE: string = "99";

const TenpoCheckSelect: FC<{
  record: TENPO_CHECK_RECORD;
  reasonChange: (value: TENPO_CHECK_RECORD) => void;
}> = ({ record, reasonChange }) => {
  const classes = stylesTenpoCheckTable();
  const [detailAnchorEl, setDetailAnchorEl] = React.useState(null);
  const [targetAnchorEl, setTargetAnchorEl] = React.useState(null);
  const [checkedTargetValue, setCheckedTargetValue] = React.useState("");
  const [reasonCommentList, setReasonCommentList] = React.useState<any[]>([]);

  const [detailOpen, setDetailOpen] = React.useState(false);
  const [targetOpen, setTargetOpen] = React.useState(false);
  const detailId = detailOpen ? "detail-popover" : undefined;
  const targetId = targetOpen ? "target-popover" : undefined;

  const [openAlert, setOpenAlert] = React.useState(false);

  const alertClick = () => {
    setOpenAlert(!openAlert);
  };

  useEffect(() => {
    if (!detailOpen) {
      organizeNgList();
    }
  }, [detailOpen]);

  const changeRadioComment = (val: string, event?: React.ChangeEvent) => {
    let checkedList: any[] = [];
    let addFlg: boolean = false;
    let delFlg: boolean = false;
    for (let i = 0; i < record.user_ng_list.length; i++) {
      if (checkedTargetValue === record.user_ng_list[i].user_radio_target) {
        if (addFlg && val === record.user_ng_list[i].user_radio_comment) {
          delFlg = true;
          continue;
        }
        addFlg = true;
        checkedList.push(record.user_ng_list[i]);
      } else {
        checkedList.push(record.user_ng_list[i]);
      }
    }
    if (addFlg && !delFlg) {
      let ngMap: USERNGMAP = {
        user_radio_target: checkedTargetValue,
        user_radio_comment: val,
      };
      checkedList.push(ngMap);
    }
    record.user_ng_list = checkedList;
    reasonChange(record);

    // handleDetailClose();
    // handleTargetClose();
  };

  // チェックリストの整理
  const organizeNgList = () => {
    let organizedNgList: any[] = [];
    for (let i = 0; i < record.user_ng_list.length; i++) {
      if (record.user_ng_list[i].user_radio_comment) {
        organizedNgList.push(record.user_ng_list[i]);
      } else if (record.user_ng_list[i].user_radio_target === OTHER_VALUE) {
        organizedNgList.push(record.user_ng_list[i]);
      }
    }
    record.user_ng_list = organizedNgList;
    record.user_ng_list.sort(function (a, b) {
      if (a.user_radio_target > b.user_radio_target) return 1;
      if (a.user_radio_target < b.user_radio_target) return -1;
      return 0;
    });
  };

  // 該当箇所選択時
  const changeCheckTarget = (val: string, event: React.ChangeEvent) => {
    if (detailOpen) {
      return;
    }
    let reasonComment: any[] = [];

    if (
      record.reason_comment_map_list &&
      record.reason_comment_map_list.length > 0
    ) {
      for (let i = 0; i < record.reason_comment_map_list.length; i++) {
        if (record.reason_comment_map_list[i].value === val) {
          let commentList = record.reason_comment_map_list[i].comment_list;
          for (let j = 0; j < commentList.length; j++) {
            reasonComment.push(commentList[j]);
          }
        }
      }
    }
    setReasonCommentList(reasonComment);

    let checkedList: any[] = [];
    let addFlg = true;
    if (record.user_ng_list) {
      for (let i = 0; i < record.user_ng_list.length; i++) {
        if (val === record.user_ng_list[i].user_radio_target) {
          addFlg = false;
        } else {
          checkedList.push(record.user_ng_list[i]);
        }
      }
    }

    if (addFlg) {
      setCheckedTargetValue(val);
      if (val === OTHER_VALUE) {
        let ngMap: USERNGMAP = {
          user_radio_target: OTHER_VALUE,
          user_radio_comment: "0",
        };
        checkedList.push(ngMap);
      } else {
        let ngMap: USERNGMAP = {
          user_radio_target: val,
          user_radio_comment: "",
        };
        checkedList.push(ngMap);
      }
    }
    record.user_ng_list = checkedList;
    reasonChange(record);
    if (addFlg) {
      if (val === OTHER_VALUE) {
        setOpenAlert(true);
      } else {
        setDetailOpen(true);
      }
    }
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };
  const handleDetailCancelClose = () => {
    organizeNgList();
    reasonChange(record);
    setDetailOpen(false);
  };
  const handleTargetClose = () => {
    organizeNgList();
    setTargetOpen(false);
  };
  const handleClick = (event: any) => {
    setDetailAnchorEl(event.currentTarget);
    setTargetAnchorEl(event.currentTarget);
  };
  // ボタン押下処理
  const clickNgButton = (event: any, row: TENPO_CHECK_RECORD) => {
    if (getFixedFlg()) return;

    // 該当箇所、詳細内容一覧がない場合
    if (!record.reason_target_list || record.reason_target_list.length === 0) {
      if (
        (!record.reason_comment_list ||
          record.reason_comment_list.length === 0) &&
        (!record.reason_comment_map_list ||
          record.reason_comment_map_list.length === 0)
      ) {
        reasonChange(record);
      } else {
        setDetailOpen(true);
      }
    } else {
      setTargetOpen(true);
    }
  };

  const isCheckedTarget = (currentTarget: string) => {
    if (record.user_ng_list) {
      for (let i = 0; i < record.user_ng_list.length; i++) {
        if (currentTarget === record.user_ng_list[i].user_radio_target) {
          return true;
        }
      }
    }
    return false;
  };

  const isCheckedComment = (currentComment: string) => {
    if (record.user_ng_list) {
      for (let i = 0; i < record.user_ng_list.length; i++) {
        if (
          checkedTargetValue === record.user_ng_list[i].user_radio_target &&
          currentComment === record.user_ng_list[i].user_radio_comment
        ) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      <Avatar
        className={
          classes.gray +
          " " +
          (record.button_value === "2" ? classes.ngMark : "")
        }
      >
        <IconButton
          onClick={(e) => {
            clickNgButton(e, record);
            handleClick(e);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Avatar>
      {record.reason_target_list ? (
        <>
          <Popover
            open={targetOpen}
            id={targetId}
            onClose={handleTargetClose}
            anchorEl={targetAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography>
              <FormControl style={{ margin: 1, minWidth: 240 }}>
                <h4
                  style={{
                    margin: 0,
                    textAlign: "center",
                    backgroundColor: "sandybrown",
                  }}
                >
                  該当箇所
                </h4>
                {record.reason_target_list.map((reason: reasons) => (
                  <FormControlLabel
                    key={reason.value}
                    control={
                      <Checkbox
                        onChange={(e) => changeCheckTarget(reason.value, e)}
                        checked={isCheckedTarget(reason.value)}
                      />
                    }
                    label={reason.name}
                  />
                ))}
                {record.reason_target_list ? (
                  <FormControlLabel
                    key={OTHER_VALUE}
                    control={
                      <Checkbox
                        onChange={(e) => changeCheckTarget(OTHER_VALUE, e)}
                        checked={isCheckedTarget(OTHER_VALUE)}
                      />
                    }
                    label="その他"
                  />
                ) : (
                  ""
                )}
              </FormControl>
            </Typography>
          </Popover>

          <Popover
            open={detailOpen}
            id={detailId}
            onClose={handleDetailCancelClose}
            anchorEl={detailAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Typography>
              <FormControl style={{ margin: 1, minWidth: 240 }}>
                <h4
                  style={{
                    margin: 0,
                    textAlign: "center",
                    backgroundColor: "lightgreen",
                  }}
                >
                  詳細
                </h4>
                {record.reason_comment_map_list &&
                record.reason_comment_map_list.length > 0
                  ? reasonCommentList.map((reason: reasons) => (
                      <FormControlLabel
                        key={reason.value}
                        control={
                          <Checkbox
                            onChange={(e) =>
                              changeRadioComment(reason.value, e)
                            }
                            checked={isCheckedComment(reason.value)}
                          />
                        }
                        label={reason.name}
                      />
                    ))
                  : record.reason_comment_list.map((reason: reasons) => (
                      <FormControlLabel
                        key={reason.value}
                        control={
                          <Radio
                            onChange={(e) =>
                              changeRadioComment(reason.value, e)
                            }
                            checked={isCheckedComment(reason.value)}
                          />
                        }
                        label={reason.name}
                      />
                    ))}
              </FormControl>
            </Typography>
          </Popover>

          <Snackbar
            open={openAlert}
            autoHideDuration={5000}
            onClose={alertClick}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SnackbarContent
              style={{ backgroundColor: "#333333", color: "white" }}
              message={
                <span style={{ fontSize: "16px" }}>
                  {"その他の内容についてカメラボタンよりコメントに記載ください"}
                </span>
              }
            />
          </Snackbar>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default TenpoCheckSelect;
