import React, { FC, useRef, forwardRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { localizationJapanese } from "../../common/MatelialTableLocalizationJapanese";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    maxWidth: "90%",
    paddingTop: "10px",
  },
  dataTableHead: {
    outlineColor: "#3e3e3e",
    backgroundColor: "#4dc0b2",
    border: "5px",
    padding: "0 0",
  },
  dataTableHeadCell: {
    textAlign: "center",
    border: "0px solid #aaa",
    padding: "0 0",
    fontSize: "12px",
    color: "white",
  },
  dataTableBody: {
    border: "1px solid #aaa",
    backgroundColor: "#ffffff",
  },
  dataTableCell: {
    width: "100px",
    textAlign: "right",
    border: "0px solid #aaa",
    paddingTop: "3px",
    paddingBottom: "3px",
    fontSize: "12px",
  },
}));

const TSCheckManagerAnalysisTable: FC<{
  data: any[];
  averageData: any;
  headerName: string;
  tableHeaderList: any[];
  tabValue: number;
  graphUserList?: any[];
  baseMonth: string;
  onClickMove?: (code: string) => void;
  onClickSelect?: (code: string, name: string) => void;
}> = ({
  data,
  averageData,
  headerName,
  tableHeaderList,
  tabValue,
  graphUserList,
  baseMonth,
  onClickMove,
  onClickSelect,
}) => {
  const tableRef: any = useRef();
  const classes = useStyles();
  const [sortNo, setSortNo] = React.useState(0);
  const [orderDirection, setOrderDirection] = React.useState<
    "desc" | "asc" | undefined
  >(undefined);

  // 項目名クリック
  const moveClick = (code: string) => {
    if (onClickMove) {
      onClickMove(code);
    }
  };

  // 選択をクリック
  const selectClick = (code: string, name: string) => {
    if (onClickSelect) {
      onClickSelect(code, name);
    }
  };

  // 選択中か判定
  const checkedGraphUser = (code: string) => {
    if (graphUserList) {
      for (let i = 0; i < graphUserList.length; i++) {
        if (graphUserList[i].code === code) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid container justify="center" alignItems="center">
        <MaterialTable
          tableRef={tableRef}
          data={JSON.parse(JSON.stringify(data))}
          columns={[
            {
              title: headerName,
              field: "name",
              cellStyle: {
                width: "300px",
              },
              sorting: false,
              render: (rowData: any) => {
                return rowData.code !== "average" && tabValue !== 3 ? (
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => moveClick(rowData.code)}
                    color="primary"
                  >
                    {rowData.name}
                  </Link>
                ) : (
                  rowData.name
                );
              },
            },
            {
              title: baseMonth ? "(基)" + baseMonth : "基準月",
              field: "baseMonthScore",
              align: "center",
              type: "numeric",
              defaultSort:
                sortNo === 1 && orderDirection ? orderDirection : undefined,
            },
            {
              title: tableHeaderList[0],
              field: tableHeaderList[0],
              align: "center",
              type: "numeric",
              defaultSort:
                sortNo === 2 && orderDirection ? orderDirection : undefined,
              customSort: (a, b) => {
                return a["1wDiffBase"] || a["1wDiffBase"] === 0
                  ? Number(a["1wDiffBase"]) - Number(b["1wDiffBase"])
                  : Number(a["1wDiff"]) - Number(b["1wDiff"]);
              },
              render: (rowData: any) => {
                return (
                  rowData[tableHeaderList[0]] +
                  "(" +
                  (rowData["1wDiffBase"] || rowData["1wDiffBase"] === 0
                    ? (Number(rowData["1wDiffBase"]) > 0
                        ? "+"
                        : Number(rowData["1wDiffBase"]) === 0
                        ? "±"
                        : "") + rowData["1wDiffBase"]
                    : (Number(rowData["1wDiff"]) > 0
                        ? "+"
                        : Number(rowData["1wDiff"]) === 0
                        ? "±"
                        : "") + rowData["1wDiff"]) +
                  ")"
                );
              },
            },
            {
              title: tableHeaderList[1],
              field: tableHeaderList[1],
              align: "center",
              type: "numeric",
              defaultSort:
                sortNo === 3 && orderDirection ? orderDirection : undefined,
              customSort: (a, b) => {
                return a["2wDiffBase"] || a["2wDiffBase"] === 0
                  ? Number(a["2wDiffBase"]) - Number(b["2wDiffBase"])
                  : Number(a["2wDiff"]) - Number(b["2wDiff"]);
              },
              render: (rowData: any) => {
                return (
                  rowData[tableHeaderList[1]] +
                  "(" +
                  (rowData["2wDiffBase"] || rowData["2wDiffBase"] === 0
                    ? (Number(rowData["2wDiffBase"]) > 0
                        ? "+"
                        : Number(rowData["2wDiffBase"]) === 0
                        ? "±"
                        : "") + rowData["2wDiffBase"]
                    : (Number(rowData["2wDiff"]) > 0
                        ? "+"
                        : Number(rowData["2wDiff"]) === 0
                        ? "±"
                        : "") + rowData["2wDiff"]) +
                  ")"
                );
              },
            },
            {
              title: tableHeaderList[2],
              field: tableHeaderList[2],
              align: "center",
              type: "numeric",
              defaultSort:
                sortNo === 4 && orderDirection ? orderDirection : undefined,
              customSort: (a, b) => {
                return a["3wDiffBase"] || a["3wDiffBase"] === 0
                  ? Number(a["3wDiffBase"]) - Number(b["3wDiffBase"])
                  : Number(a["3wDiff"]) - Number(b["3wDiff"]);
              },
              render: (rowData: any) => {
                return (
                  rowData[tableHeaderList[2]] +
                  "(" +
                  (rowData["3wDiffBase"] || rowData["3wDiffBase"] === 0
                    ? (Number(rowData["3wDiffBase"]) > 0
                        ? "+"
                        : Number(rowData["3wDiffBase"]) === 0
                        ? "±"
                        : "") + rowData["3wDiffBase"]
                    : (Number(rowData["3wDiff"]) > 0
                        ? "+"
                        : Number(rowData["3wDiff"]) === 0
                        ? "±"
                        : "") + rowData["3wDiff"]) +
                  ")"
                );
              },
            },
            {
              title: tableHeaderList[3],
              field: tableHeaderList[3],
              align: "center",
              type: "numeric",
              defaultSort:
                sortNo === 5 && orderDirection ? orderDirection : undefined,
              customSort: (a, b) => {
                return a["4wDiffBase"] || a["4wDiffBase"] === 0
                  ? Number(a["4wDiffBase"]) - Number(b["4wDiffBase"])
                  : Number(a["4wDiff"]) - Number(b["4wDiff"]);
              },
              render: (rowData: any) => {
                return (
                  rowData[tableHeaderList[3]] +
                  "(" +
                  (rowData["4wDiffBase"] || rowData["4wDiffBase"] === 0
                    ? (Number(rowData["4wDiffBase"]) > 0
                        ? "+"
                        : Number(rowData["4wDiffBase"]) === 0
                        ? "±"
                        : "") + rowData["4wDiffBase"]
                    : (Number(rowData["4wDiff"]) > 0
                        ? "+"
                        : Number(rowData["4wDiff"]) === 0
                        ? "±"
                        : "") + rowData["4wDiff"]) +
                  ")"
                );
              },
            },
            {
              title: tableHeaderList[4],
              field: tableHeaderList[4],
              align: "center",
              type: "numeric",
              defaultSort:
                sortNo === 6 && orderDirection ? orderDirection : undefined,
              customSort: (a, b) => {
                return a["5wDiffBase"] || a["5wDiffBase"] === 0
                  ? Number(a["5wDiffBase"]) - Number(b["5wDiffBase"])
                  : Number(a["5wDiff"]) - Number(b["5wDiff"]);
              },
              render: (rowData: any) => {
                return (
                  rowData[tableHeaderList[4]] +
                  "(" +
                  (rowData["5wDiffBase"] || rowData["5wDiffBase"] === 0
                    ? (Number(rowData["5wDiffBase"]) > 0
                        ? "+"
                        : Number(rowData["5wDiffBase"]) === 0
                        ? "±"
                        : "") + rowData["5wDiffBase"]
                    : (Number(rowData["5wDiff"]) > 0
                        ? "+"
                        : Number(rowData["5wDiff"]) === 0
                        ? "±"
                        : "") + rowData["5wDiff"]) +
                  ")"
                );
              },
            },
            {
              title: "",
              field: "",
              align: "center",
              sorting: false,
              render: (rowData: any) => {
                return (
                  <Button
                    variant="contained"
                    color={
                      checkedGraphUser(rowData.code) ? "secondary" : "primary"
                    }
                    size="small"
                    style={{ padding: "0" }}
                    onClick={() => selectClick(rowData.code, rowData.name)}
                  >
                    選択
                  </Button>
                );
              },
            },
          ]}
          style={{ width: "90%" }}
          components={{
            Pagination: (props) => (
              <TablePagination {...props} rowsPerPageOptions={[5, 10, 15]} />
            ),
          }}
          icons={{
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => (
              <DeleteOutline {...props} ref={ref} />
            )),
            DetailPanel: forwardRef((props, ref) => (
              <ChevronRight {...props} ref={ref} />
            )),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => (
              <SaveAlt {...props} ref={ref} />
            )),
            Filter: forwardRef((props, ref) => (
              <FilterList {...props} ref={ref} />
            )),
            FirstPage: forwardRef((props, ref) => (
              <FirstPage {...props} ref={ref} />
            )),
            LastPage: forwardRef((props, ref) => (
              <LastPage {...props} ref={ref} />
            )),
            NextPage: forwardRef((props, ref) => (
              <ChevronRight {...props} ref={ref} />
            )),
            PreviousPage: forwardRef((props, ref) => (
              <ChevronLeft {...props} ref={ref} />
            )),
            ResetSearch: forwardRef((props, ref) => (
              <Clear {...props} ref={ref} />
            )),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => (
              <ArrowDownward {...props} ref={ref} />
            )),
            ThirdStateCheck: forwardRef((props, ref) => (
              <Remove {...props} ref={ref} />
            )),
            ViewColumn: forwardRef((props, ref) => (
              <ViewColumn {...props} ref={ref} />
            )),
          }}
          onOrderChange={(orderBy: number, orderDirection: "asc" | "desc") => {
            setSortNo(orderBy);
            setOrderDirection(orderDirection);
          }}
          localization={localizationJapanese}
          options={{
            padding: "dense",
            draggable: false,
            search: false,
            showTitle: false,
            toolbar: false,
            filtering: false,
            paging: false,
            // 対象外の場合はcsv出力ボタン非表示
            exportButton: false,
            headerStyle: {
              backgroundColor: "#4dc0b2",
              color: "#ffffff",
              whiteSpace: "nowrap",
              paddingLeft: "14px",
              paddingRight: "14px",
            },
            rowStyle: {
              fontSize: "12px",
              whiteSpace: "nowrap",
            },
          }}
        ></MaterialTable>
      </Grid>

      <Grid container style={{ padding: "10px" }}></Grid>
      <Grid container justify="center" alignItems="center">
        <Table className={classes.dataTable}>
          <TableHead className={classes.dataTableHead}>
            <TableCell
              className={classes.dataTableHeadCell}
              style={{
                width: "300px",
              }}
            >
              {headerName}
            </TableCell>
            <TableCell className={classes.dataTableHeadCell}>
              <Typography style={{ fontSize: "12px" }}>
                {baseMonth ? "(基)" + baseMonth : "基準月"}
              </Typography>
            </TableCell>
            {tableHeaderList.map((row) => (
              <TableCell className={classes.dataTableHeadCell}>{row}</TableCell>
            ))}
            <TableCell className={classes.dataTableHeadCell}></TableCell>
          </TableHead>
          <TableBody className={classes.dataTableBody}>
            <TableRow>
              <TableCell
                className={classes.dataTableCell}
                style={{ textAlign: "left" }}
              >
                {averageData.name}
              </TableCell>
              <TableCell className={classes.dataTableCell}>
                {averageData.baseMonthScore}
              </TableCell>
              {tableHeaderList.map((itemName, i) => (
                <TableCell className={classes.dataTableCell}>
                  {averageData[itemName] +
                    "(" +
                    (averageData[i + 1 + "wDiffBase"] ||
                    averageData[i + 1 + "wDiffBase"] === 0
                      ? (Number(averageData[i + 1 + "wDiffBase"]) > 0
                          ? "+"
                          : Number(averageData[i + 1 + "wDiffBase"]) === 0
                          ? "±"
                          : "") + averageData[i + 1 + "wDiffBase"]
                      : (Number(averageData[i + 1 + "wDiff"]) > 0
                          ? "+"
                          : Number(averageData[i + 1 + "wDiff"]) === 0
                          ? "±"
                          : "") + averageData[i + 1 + "wDiff"]) +
                    ")"}
                </TableCell>
              ))}

              <TableCell className={classes.dataTableCell}>
                <Button
                  variant="contained"
                  color={
                    checkedGraphUser(averageData.code) ? "secondary" : "primary"
                  }
                  size="small"
                  style={{ padding: "0" }}
                  onClick={() =>
                    selectClick(averageData.code, averageData.name)
                  }
                >
                  選択
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default TSCheckManagerAnalysisTable;
