/**
 * 写真関連の処理です。
 * @class photoAction.ts
 * @constructor
 */

 import { storage } from "../firebase";
 import Resizer from "react-image-file-resizer";
 
 const resizeFile = (file : any) =>
   new Promise((resolve) => {
     Resizer.imageFileResizer(
       file,
       300,
       400,
       "PNG",
       100,
       0,
       (uri) => {
         resolve(uri);
       },
       "base64"
     );
   });
 
   function _convertToFile (imgData : any, file : any) {
     // ここでバイナリにしている
     const blob = atob(imgData.replace(/^.*,/, ''));
     let buffer = new Uint8Array(blob.length);
     for (let i = 0; i < blob.length; i++) {
   buffer[i] = blob.charCodeAt(i);
     }
     return new File([buffer.buffer], file.name, {type: file.type});
 }
 
 /**
  * Inputより取得した写真を読み込んで表示します(※ 複数対応可)
  * @param e : Todo any修正
  * @param canvasHtmlId : 写真を設定するCanavasのID
  */
 export const readPhotoFromInput = (e: any, canvasHtmlId: string): void => {
   // canvas情報を取得
   let canvas = document.getElementById(canvasHtmlId) as HTMLCanvasElement;
   // canvasのwidthとheightは、imgと同じ値にする
   canvas.width = 120;
   canvas.height = 120;
   canvas.style.height = "120px";
   canvas.style.width = "120px";
   // 見た目上のサイズは小さめにしておく
   let context = canvas.getContext("2d");
   const fileLength: number = e.target.files.length;
   e.target.files.forEach((file: any, index: number) => {
     // 画像情報を取得できない場合終了する
     if (typeof file === "undefined" || file === null) {
       return;
     }
     let img = new Image();
     let fr = new FileReader();
     fr.onload = function (e) {
       const url = fr.result;
       // onloadでcanvasに描き込む処理を書く
       img.onload = function () {
         if (context !== null) {
           // imgを描き込む
           if (index === 0 && fileLength === 1) {
             context.drawImage(img, 0, 0, 120, 120);
           } else if (index === 0) {
             context.drawImage(img, 0, 0, 100, 100);
           } else if (index === 1) {
             context.drawImage(img, 100, 0, 100, 100);
           } else if (index === 2) {
             context.drawImage(img, 0, 100, 100, 100);
           } else if (index === 3) {
             context.drawImage(img, 100, 100, 100, 100);
           }
         }
       };
       if (typeof url === "string") {
         img.src = url;
       }
     };
     fr.readAsDataURL(file);
   });
 };
 
 /**
  * Inputより取得した写真をGoogleCloudStorageにアップロードします
  * @param e : Todo any修正
  * @param directory : GoogleCloudStorageのフォルダ名
  * @param fileName : ファイル名
  */
 export const uploadPhotoGoogleCloudStorage = (e: any, directory: string, fileName: string) => {
   return new Promise(async (resolve) => {
     const file = e.target.files[0];
     console.log("☆☆☆uploadPhotoGoogleCloudStorage");
     console.log(file);
     let image = null;
     if (!file) {
      resolve(null);
      return;
     }
     try{
       image = await resizeFile(file);
     } catch (r) {
       console.log(r);
       resolve("");
       return;
     }
     const imgFile = _convertToFile(image, file);
     console.log("☆☆☆uploadPhotoGoogleCloudStorage3");
     console.log(imgFile);
     const storageRef = storage.ref(directory).child(fileName);
     storageRef.put(imgFile).then((snapshot) => {
       snapshot.ref.getDownloadURL().then((downloadURL) => {
         resolve(downloadURL);
       });
     });
   });
 };
 