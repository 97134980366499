/**
 * existingSejStoreLayer.ts
 * 地図上の既存店の表示管理を行います
 */

import { getModalGoogleMapsObject } from "../SelectMobakuGoogleMaps";
import { calcPointsWithinCircle } from "../../../../apis/turfAction";
import {
  unvisibleMapsStyleSettings,
  applyHtmlTemplate,
  zIndexOrder,
} from "../MobakuMapStylesSettings";
import {
  updateFireStoreLayer,
  manageDisplayLayer,
  mobakuLayer,
} from "../SelectMobakuMapItems";
import { selectingCvrMobakuItemRef } from "../../SelectMobakuMap/MobakuMapsSelectedButton";
import {
  EXISTING_SEJ_STORES_RECORD,
  getAllExistingSejStores,
  INTERFACE_STORE_DATA,
} from "../../../../data/existingSejStores";
import { cvrTenpoItem } from "../../SelectStoreMenu";
import {
  updateTenpoName,
  updateTenpoCode,
  updateTenpoLatLng,
  updateCreateMarketAreaFlg,
  updateEditMarketAreaFlg,
  updateDeleteMarketAreaFlg,
} from "../../../../features/marketAreaStatusSlice";
import {
  updateSecondCvrItem,
  updateSelectingCvrMobakuItem,
} from "../../../../features/tenpoStatusSlice";
import { userInfo } from "../../../Maps/Maps";
import {
  drawPolygon,
  displayPolygons,
  editPolygon,
  hidePolygon,
  displayMobakuPolygon,
  selectMarketArea,
} from "../../../OpView/MarketArea/PolygonDrawing";
import { getFirestoreDocData } from "../../../../apis/firestoreAction";
import {
  isTrainingUser,
  isTsUser,
  isFTraineeUser,
} from "../../../../apis/privilege";
import { getMarketAreaDocId } from "../../../OpView/MarketArea/MarketAreaControl";

export let existingSejStoreLayer: google.maps.Data,
  cvrExistingSejStoreLayer: google.maps.Data,
  existingSejStoreInfoWindow = new Map(),
  existingSejStoreData: any,
  existingSejStoreLayerEvent: any;

// 既存店舗処理
let isApiRequest = false;
export const cbFuncExistingSejStoresFirst = () => {
  if (isApiRequest) {
    return true;
  }
  isApiRequest = true;
  return false;
};

export const cbFuncExistingSejStores = (
  geojson: any,
  updateLayer: any,
  conditions: any
) => {
  existingSejStoreData = geojson;
  updateFireStoreLayer(updateLayer);
  isApiRequest = false;
};

// 既存店舗初期処理
export const initMobakuExistingSejStores = (
  storeOrder: string,
  dispatch: any,
  graphType: string
): void => {
  if (graphType === "cvr") {
    cvrExistingSejStoreLayer = new google.maps.Data({
      map: getModalGoogleMapsObject(),
      style: {
        visible: false,
      },
    });
  } else if (graphType === "mobaku") {
    existingSejStoreLayer = new google.maps.Data({
      map: getModalGoogleMapsObject(),
      style: {
        visible: false,
      },
    });
  }
  const tempLayer =
    graphType === "cvr" ? cvrExistingSejStoreLayer : existingSejStoreLayer;
  // クリックイベントの登録
  existingSejStoreLayerEvent = tempLayer.addListener(
    "click",
    async (event: any) => {
      if (!existingSejStoreInfoWindow.has(event.feature)) {
        // firestoreから対象の店舗の任意商圏の情報を取得する
        const marketAreaValue = await getFirestoreDocData(
          "market_area",
          getMarketAreaDocId(event.feature.getProperty("tenpo_code"))
        ).then((response: any) => {
          return response;
        });
        // インフォウィンドウの設定
        existingSejStoreInfoWindow.set(
          event.feature,
          new google.maps.InfoWindow({
            // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
            maxWidth: 300,
          })
        );
        // 表示位置
        existingSejStoreInfoWindow.get(event.feature).setPosition(event.latLng);
        // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
        existingSejStoreInfoWindow
          .get(event.feature)
          .setContent(
            applyHtmlTemplate(getExistingSejStoreInfoWindowHtml(), [
              event.feature.getProperty("tenpo_code"),
              event.feature.getProperty("tenpo_name"),
              event.feature.getProperty("original_open_date"),
              event.feature.getProperty("contract_type"),
            ])
          );

        existingSejStoreInfoWindow
          .get(event.feature)
          .setOptions({ pixelOffset: new google.maps.Size(0, -25) });
        // InfowWindowを表示
        existingSejStoreInfoWindow
          .get(event.feature)
          .open(getModalGoogleMapsObject());
        existingSejStoreInfoWindow
          .get(event.feature)
          .addListener("domready", () => {
            const tenpoCode = event.feature.getProperty("tenpo_code");
            if (graphType === "cvr" && tenpoCode === cvrTenpoItem) {
              // インフォウィンドウに表示するボタンを制御
              const tenpoName = event.feature.getProperty("tenpo_name");
              const tenpoLatLng = event.feature.getProperty("lat_lng");
              const selectElement = document.getElementById(
                tenpoCode + "-mselect-market-area"
              );
              const createElement = document.getElementById(
                tenpoCode + "-mcreate-market-area"
              );
              const editElement = document.getElementById(
                tenpoCode + "-medit-market-area"
              );
              const displayElement = document.getElementById(
                tenpoCode + "-mdisplay-market-area"
              );
              const nonDisplayElement = document.getElementById(
                tenpoCode + "-mnon-display-market-area"
              );
              const deleteElement = document.getElementById(
                tenpoCode + "-mdelete-market-area"
              );
              // 作成ボタンを表示
              const addCreateButton = () => {
                if (createElement) {
                  createElement.style.display = "block";
                  createElement.addEventListener("click", function (e) {
                    selectTenpo(
                      tenpoName,
                      tenpoCode,
                      event,
                      dispatch,
                      storeOrder,
                      graphType,
                      true
                    );
                    dispatch(
                      updateCreateMarketAreaFlg({
                        createMarketAreaFlg: true,
                      })
                    );
                    dispatch(
                      updateTenpoLatLng({
                        tenpoLatLng: tenpoLatLng,
                      })
                    );
                    drawPolygon(dispatch, tenpoCode, tenpoLatLng, true);
                  });
                }
              };
              // 選択ボタンを表示
              const addSelectButton = () => {
                if (selectElement) {
                  selectElement.style.display = "block";
                  selectElement.addEventListener("click", function (e) {
                    selectMarketArea(tenpoCode, dispatch);
                    existingSejStoreInfoWindow.get(event.feature).close();
                    existingSejStoreInfoWindow.delete(event.feature);
                  });
                }
              };
              // 編集ボタンを表示
              const addEditButton = (polygon: any) => {
                if (editElement) {
                  editElement.style.display = "block";
                  editElement.addEventListener("click", function (e) {
                    selectTenpo(
                      tenpoName,
                      tenpoCode,
                      event,
                      dispatch,
                      storeOrder,
                      graphType,
                      true
                    );
                    dispatch(
                      updateEditMarketAreaFlg({
                        editMarketAreaFlg: true,
                      })
                    );
                    dispatch(
                      updateTenpoLatLng({
                        tenpoLatLng: tenpoLatLng,
                      })
                    );
                    editPolygon(
                      polygon,
                      getModalGoogleMapsObject(),
                      tenpoCode,
                      tenpoLatLng,
                      dispatch,
                      true
                    );
                  });
                }
              };
              // 削除ボタンを表示
              const addDeleteButton = () => {
                if (deleteElement) {
                  deleteElement.style.display = "block";
                  deleteElement.addEventListener("click", function (e) {
                    selectTenpo(
                      tenpoName,
                      tenpoCode,
                      event,
                      dispatch,
                      storeOrder,
                      graphType
                    );
                    dispatch(
                      updateDeleteMarketAreaFlg({
                        deleteMarketAreaFlg: true,
                      })
                    );
                  });
                }
              };
              // 表示ボタンを表示
              const addDisplayButton = (polygon: any) => {
                if (displayElement) {
                  displayElement.style.display = "block";
                  displayElement.addEventListener("click", function (e) {
                    selectTenpo(
                      tenpoName,
                      tenpoCode,
                      event,
                      dispatch,
                      storeOrder,
                      graphType
                    );
                    displayPolygons(
                      polygon,
                      getModalGoogleMapsObject(),
                      tenpoCode,
                      true
                    );
                    // 既存店レイヤ初期化
                    // setUnvisibleMobakuMapsStyle(tempLayer);
                    // initMobakuExistingSejStores(
                    //   storeOrder,
                    //   dispatch,
                    //   graphType
                    // );
                  });
                }
              };
              // 非表示ボタンを表示
              const addNonDisplayButton = () => {
                if (nonDisplayElement) {
                  nonDisplayElement.style.display = "block";
                  nonDisplayElement.addEventListener("click", function (e) {
                    selectTenpo(
                      tenpoName,
                      tenpoCode,
                      event,
                      dispatch,
                      storeOrder,
                      graphType
                    );
                    hidePolygon(tenpoCode, true);
                    // 既存店レイヤ初期化
                    setUnvisibleMobakuMapsStyle(tempLayer);
                    initMobakuExistingSejStores(
                      storeOrder,
                      dispatch,
                      graphType
                    );
                  });
                }
              };
              // 対象の店舗で任意商圏が設定されているか確認する
              let existMarketArea: boolean;
              if (marketAreaValue === undefined) {
                existMarketArea = false;
              } else {
                existMarketArea = true;
              }
              if (
                userInfo.jobCategory === "admin-group" ||
                isTrainingUser(userInfo)
              ) {
                // admin-groupとトレーニングユーザーの任意商圏ボタン制御
                if (existMarketArea) {
                  const polygon = JSON.parse(marketAreaValue.feature);
                  addEditButton(polygon);
                  addDeleteButton();
                  if (selectingCvrMobakuItemRef[0] !== "market-area") {
                    addSelectButton();
                    if (displayMobakuPolygon.hasOwnProperty(tenpoCode)) {
                      addNonDisplayButton();
                    } else {
                      addDisplayButton(polygon);
                    }
                  }
                } else {
                  addCreateButton();
                }
              } else if (
                // 直営店社員とFトレ生の任意商圏ボタン制御
                (isTsUser(userInfo) || isFTraineeUser(userInfo)) &&
                userInfo.tenpoCode.includes(
                  event.feature.getProperty("tenpo_code")
                )
              ) {
                if (existMarketArea) {
                  const polygon = JSON.parse(marketAreaValue.feature);
                  addEditButton(polygon);
                  addDeleteButton();
                  if (selectingCvrMobakuItemRef[0] !== "market-area") {
                    addSelectButton();
                    if (displayMobakuPolygon.hasOwnProperty(tenpoCode)) {
                      addNonDisplayButton();
                    } else {
                      addDisplayButton(polygon);
                    }
                  }
                } else {
                  addCreateButton();
                }
              } else if (
                userInfo.jobCategory === "ofc" &&
                userInfo.tenpoCode.includes(
                  event.feature.getProperty("tenpo_code")
                )
              ) {
                // ofcの任意商圏ボタン制御
                if (existMarketArea) {
                  const polygon = JSON.parse(marketAreaValue.feature);
                  addEditButton(polygon);
                  addDeleteButton();
                  if (selectingCvrMobakuItemRef[0] !== "market-area") {
                    addSelectButton();
                    if (displayMobakuPolygon.hasOwnProperty(tenpoCode)) {
                      addNonDisplayButton();
                    } else {
                      addDisplayButton(polygon);
                    }
                  }
                } else {
                  addCreateButton();
                }
              } else if (
                userInfo.tenpoCodeSpecialList &&
                userInfo.tenpoCodeSpecialList.includes(
                  event.feature.getProperty("tenpo_code")
                )
              ) {
                // 担当店を持つSOFCの任意商圏ボタン制御
                if (existMarketArea) {
                  const polygon = JSON.parse(marketAreaValue.feature);
                  addEditButton(polygon);
                  addDeleteButton();
                  if (selectingCvrMobakuItemRef[0] !== "market-area") {
                    addSelectButton();
                    if (displayMobakuPolygon.hasOwnProperty(tenpoCode)) {
                      addNonDisplayButton();
                    } else {
                      addDisplayButton(polygon);
                    }
                  }
                } else {
                  addCreateButton();
                }
              } else if (
                userInfo.tenpoCode.includes(
                  event.feature.getProperty("tenpo_code")
                )
              ) {
                // admin-group、ofc、担当店を持つsofc以外の任意商圏ボタン制御
                if (existMarketArea) {
                  const polygon = JSON.parse(marketAreaValue.feature);
                  if (selectingCvrMobakuItemRef[0] !== "market-area") {
                    addSelectButton();
                    if (displayMobakuPolygon.hasOwnProperty(tenpoCode)) {
                      addNonDisplayButton();
                    } else {
                      addDisplayButton(polygon);
                    }
                  }
                }
              }
            }
          });
        existingSejStoreInfoWindow
          .get(event.feature)
          .addListener("closeclick", function () {
            existingSejStoreInfoWindow.delete(event.feature);
          });
      } else {
        existingSejStoreInfoWindow.get(event.feature).close();
        existingSejStoreInfoWindow.delete(event.feature);
      }
    }
  );
  // スタイル指定（最初は非表示）
  tempLayer.setStyle(unvisibleMapsStyleSettings);
  manageDisplayLayer(tempLayer, true, graphType);
};

// 既存店アイコンクリック時の共通処理
const selectTenpo = (
  tenpoName: string,
  tenpoCode: string,
  event: any,
  dispatch: any,
  storeOrder: string,
  graphType: string,
  createFlag: boolean = false
) => {
  dispatch(
    updateTenpoName({
      tenpoName: tenpoName,
    })
  );
  dispatch(
    updateTenpoCode({
      tenpoCode: tenpoCode,
    })
  );
  existingSejStoreInfoWindow.get(event.feature).close();
  existingSejStoreInfoWindow.delete(event.feature);
  // cvrExistingSejStoreLayer.setStyle(unvisibleMapsStyleSettings);
  // initMobakuExistingSejStores(storeOrder, dispatch, graphType);
};

export const setUnvisibleMobakuMapsStyle = (
  layer: google.maps.Data = cvrExistingSejStoreLayer
) => {
  layer.setStyle(unvisibleMapsStyleSettings);
};

/**
 * 内部データよりGoogleMapsに表示する
 */
export const innerDataExistingSejStoreLayer = (
  latLng: google.maps.LatLng,
  searchLength: number
) => {
  const obj: any = getAllExistingSejStores().store_data;
  const array: any = [];
  // GeoJSONに格納する配列をつくる
  for (let [key] of obj) {
    // 店舗レコードを内部データから取得
    let storeRecord = obj.get(key);
    if (storeRecord) {
      array.push(
        turf.point(
          [parseFloat(storeRecord.longitude), parseFloat(storeRecord.latitude)],
          {
            tenpo_code: storeRecord.tenpo_code,
            tenpo_name: storeRecord.tenpo_name,
            original_open_date: storeRecord.original_open_date,
            contract_type: storeRecord.contract_type,
            lat_lng: [
              parseFloat(storeRecord.latitude),
              parseFloat(storeRecord.longitude),
            ],
          }
        )
      );
    }
  }
  return calcPointsWithinCircle(
    array,
    latLng.lng(),
    latLng.lat(),
    searchLength
  );
};

/**
 * GoogleMapsスタイル設定
 */
export const existingSejStoreLayerMapsStyleSettings = function (feature: any) {
  return {
    icon: "./image/icons/sej_normal.png?v=" + new Date().getUTCMilliseconds(),
    visible: true,
    zIndex: zIndexOrder.existingSejStoreIcon,
  };
};
/**
 * CVR GoogleMapsスタイル設定
 */
export const cvrExistingSejStoreLayerMapsStyleSettings = function (
  feature: any
) {
  if (
    feature.getProperty("tenpo_code") !== undefined &&
    feature.getProperty("tenpo_code") === cvrTenpoItem
  ) {
    return {
      icon:
        "./image/icons/sej_selected_3d_b.png?v=" +
        new Date().getUTCMilliseconds(),
      visible: true,
      zIndex: zIndexOrder.existingSejStoreIcon,
    };
  } else {
    return {
      icon: "./image/icons/sej_normal.png?v=" + new Date().getUTCMilliseconds(),
      visible: true,
      zIndex: zIndexOrder.existingSejStoreIcon,
    };
  }
};

const getExistingSejStoreInfoWindowHtml = () => {
  const existingSejStoreInfoWindowHtml =
    '<div class="info-window-padding"' +
    "<div >店番:{0}</div>" +
    "<div >店名:{1}</div>" +
    "<div >開店日:{2}</div>" +
    "<div >タイプ:{3}</div>" +
    '<button class="market-area-button" id="{0}-mselect-market-area">範囲を選択</button>' +
    '<button class="market-area-button" id="{0}-mcreate-market-area">範囲を作成</button>' +
    '<button class="market-area-button" id="{0}-medit-market-area">範囲を変更</button>' +
    '<button class="market-area-button" id="{0}-mdisplay-market-area">範囲を表示</button>' +
    '<button class="market-area-button" id="{0}-mnon-display-market-area">範囲を非表示</button>' +
    '<button class="market-area-delete-button" id="{0}-mdelete-market-area">範囲を削除</button>' +
    "</div>";

  return existingSejStoreInfoWindowHtml;
};
