import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectUser } from "../../../../features/userSlice";
import {
  getAllDo
} from "../../../../data/existingSejStores";
import {
  selectDoName,
} from "../../../../components/organisms/search/TenpoCheckSearchSlice";

import {
  getAuthTenpoCheck, onlyAll
} from "../specification/UsageAuthority/AuthTenpoCheck";

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

const SearchDO: React.FC<{
  useStyles: any;
  disabled: boolean;
  firstView: boolean;
  toggleStatus: boolean;
  searchMode: number;
  onClickFunc: any;
}> = ({
  useStyles,
  disabled,
  firstView,
  toggleStatus,
  searchMode,
  onClickFunc,
}) => {
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = useSelector(selectUser);
  const stateDoName = useSelector(selectDoName);
  
  const MAX_SEARCH_LIST = 150;
  const doMaster = getAllDo().do_data;
  const [doList, setDoList] = React.useState([""]);
  // 検索文字列が更新された際に動く
  useEffect(() => {
    // ボタンのリストを更新
    if (getAuthTenpoCheck(user.jobCategory).search < onlyAll) {
      setDoList(userDoButton());
    }
    else {
      setDoList(allDoButton());
    }
  }, [searchWord]);

  function userDoButton() {
    let doList: any[] = [""];
    let doCode: any = "";
    let doName: any = "";
    let hitCount: number = 0;

    for (let i = 0; i < user.doCode.length; i++) {
      doCode = user.doCode[i];
      if (doCode === "") return doList;
      doName = doMaster[user.doCode[i]].do_name;
      // 検索文字列にマッチしたものだけリストに入れる
      if (doCode.match(searchWord) || doName.match(searchWord)) {
        doList.push(
          <Button
            key={i}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(
                user.doCode[i],
                doMaster[user.doCode[i]].do_name
              );
              setOpen(false);
            }}
          >
            <div  className={classes.selectButton}>{user.doCode[i]}：{doMaster[user.doCode[i]].do_name}</div>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return doList;
  }

  function allDoButton() {
    let doList: any[] = [""];
    let hitCount: number = 0;
    for (let key in doMaster) {
      if (key === "undefined" || searchWord === "") continue;
      // 検索文字列にマッチしたものだけリストに入れる
      if (doMaster[key].do_code.match(searchWord) || doMaster[key].do_name.match(searchWord)) {
        doList.push(
          <Button
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(
                doMaster[key].do_code,
                doMaster[key].do_name
              );
              setOpen(false);
            }}
          >
            <div className={classes.selectButton}>{doMaster[key].do_code}：{doMaster[key].do_name}</div>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return doList;
  }

  return (
    <>
      <button
        className={classes.searchButton}
        onClick={() => {
          handleOpen();
          onClickFunc("", "");
        }}
        disabled = {disabled}
        style={{ WebkitAppearance: "none" }}
      >
        <div className={classes.selectButton}>{ stateDoName === "" ? "ＤＯ名" : stateDoName}</div>
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <TextField
            className={classes.searchTextField}
            value={searchWord}
            label="ＤＯコード、ＤＯ名で探す"
            variant="outlined"
            size="medium"
            fullWidth={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchWord(e.target.value);
            }}
          />
          <div className={classes.listPaper}>
            {doList}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchDO;