/**
 * 共通で使用可能なデータをここに配置します
 * (SEJ社員情報関係)
 *
 */

 import { postRequest } from "../apis/axiosAction";

//社員情報の型
export interface EMPLOYEE_MASTER_RECORD {
  // 各アプリケーション共通項目
  employee_no: string;
  mail_address: string;
  employee_name: string;
  department_name_OA: string;
  job_category_code_OA: string;
  job_category_name_OA: string;
  docode: string;
  doname: string;
  zocode: string;
  zoname: string;
  hq_department_name_OA: string;
}

// 社員情報の型のオブジェクトの集まり
export interface INTERFACE_EMPLOYEE_DATA {
  [index: string]: EMPLOYEE_MASTER_RECORD;
}

// 社員情報の型
export interface INTERFACE_EMPLOYEE {
  employee_data: INTERFACE_EMPLOYEE_DATA;
  count: number;
}

// 検索用EMPLOYEEの型のオブジェクトの集まり
export interface INTERFACE_EMPLOYEE_MAIL_SEARCH_DATA {
  [index: string]: EMPLOYEE_MASTER_RECORD;
}

// 検索用EMPLOYEEの型
export interface INTERFACE_EMPLOYEE_MAIL_SEARCH {
  employee_data: INTERFACE_EMPLOYEE_MAIL_SEARCH_DATA;
  count: number;
}

// 既存店の初期値
const initialEmployee: INTERFACE_EMPLOYEE = {
  employee_data: {},
  count: 0,
};

const employeeData = {
  // SEJ社員情報
  employee: initialEmployee,
};

// 検索用EMPLOYEEの初期値
const initialEmployeeMailSearch: INTERFACE_EMPLOYEE_MAIL_SEARCH = {
  employee_data: {},
  count: 0,
};

const employeeMailSearchData = {
  // employeeMailSearch
  employee: initialEmployeeMailSearch,
};

// SEJ既存店舗情報の全量の情報を返す
export const getAllEmployee = () => {
  return employeeData.employee;
};

// SEJ社員情報のある1社員情報のデータを返す
export const getEmployee = (
  mail_address: string
): EMPLOYEE_MASTER_RECORD => {
  return employeeMailSearchData.employee.employee_data[mail_address];
};

// SEJ社員情報全量を保存する
export const setEmployeeOfc = (data: any) => {
  const recordData = JSON.parse(data.data.dataList[0]).employee; //配列を用意
  const employeeTable: any = {};
  const employeeRecordMailSearchData: any = {};
  let count = 0;
  for (let i = 0; i < recordData.length; i++) {
    const employeeRecord: EMPLOYEE_MASTER_RECORD = {
      employee_no: recordData[i].employee_no,
      mail_address: recordData[i].mail_address,
      employee_name: recordData[i].employee_name,
      department_name_OA: recordData[i].department_name_OA,
      job_category_code_OA: recordData[i].job_category_code_OA.padStart(3, "0"),
      job_category_name_OA: recordData[i].job_category_name_OA,
      docode: recordData[i].docode.padStart(5, "0"),
      doname: recordData[i].doname,
      zocode: recordData[i].zocode.padStart(3, "0"),
      zoname: recordData[i].zoname,
      hq_department_name_OA: recordData[i].hq_department_name_OA,
    };
    employeeTable[i] = employeeRecord;
    employeeRecordMailSearchData[employeeRecord.mail_address] = employeeRecord;
    count++;
  }
  const result: INTERFACE_EMPLOYEE = {
    employee_data: employeeTable,
    count,
  };
  employeeData.employee = result;

  const count_employeeRecordMailSearchData: number = Object.keys(employeeRecordMailSearchData).length;
  const employee: INTERFACE_EMPLOYEE_MAIL_SEARCH = {
    employee_data: employeeRecordMailSearchData,
    count: count_employeeRecordMailSearchData,
  };
  employeeMailSearchData.employee = employee;
  //console.table(result.store_data);
};

export const initializeEmployee = (colname: string, invalues: string[], jobcodes: string[]) => {
    // ユーザー情報APIの呼び出し
    let apiParams = {
      "api-name": "employee-search",
      "colname":colname,
      "invalues":invalues, //[\"311\"]
      "jobcodes":jobcodes, //[\"310\",\"210\",\"120\"]
    };
    postRequest(process.env.REACT_APP_COMMON_API!, apiParams)
    .then((response: any) => {
      setEmployeeOfc(response);
    });
};
