import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GraphButton from "./GraphButton";
import { selectUser } from "../../features/userSlice";
import { getAllExistingSejStores } from "../../data/existingSejStores";
import TextField from "@material-ui/core/TextField";
import {
  updateFirstCvrItem,
  updateSecondCvrItem,
  updateCvrMobakuName,
  updateFirstMobakuName,
  updateSecondMobakuName,
  updateFirstTenpoItem,
  updateSecondTenpoItem,
  updateFirstMobakuItem,
  updateSelectingCvrMobakuItem,
  selectFirstCvrItem,
  updateInitializeGraphValue,
  updateSecondMobakuItem,
  updateSpareMobakuItem,
} from "../../features/tenpoStatusSlice";
import {
  getFirestoreData,
  getFirestoreDocData,
} from "../../apis/firestoreAction";
import { getMarketAreaDocId } from "./MarketArea/MarketAreaControl";

export let cvrTenpoItem: string = "";
// 半角⇒全角変換
export const hankaku2Zenkaku = (str: string): string => {
  return str
    .replace(/[A-Za-z0-9]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
    })
    .replace(/[-﹣‐⁃−]/g, "－");
};

/**
 * 店舗の緯度経度をメッシュコードに変換する。
 * @param tenpoCode
 * @returns
 */
export function latlngToMeshcode(lat: number, lon: number): string {
  //　第1次メッシュ（緯度差40分、経度差1度。1辺の長さ約80km）
  let y1 = Math.floor((lat * 60) / 40);
  let x1 = Math.floor(lon - 100);
  let m1 = y1.toString() + x1.toString();
  //　第2次メッシュ（緯度差5分、経度差7分30秒。1辺の長さ約10km）
  let y2 = Math.floor(((lat * 60) % 40) / 5);
  let x2 = Math.floor(((lon - Math.floor(lon)) * 60) / 7.5);
  let m2 = y2.toString() + x2.toString();
  //　第3次メッシュ（緯度差30秒、経度差45秒。1辺の長さ約1km）
  let y3 = Math.floor(((((lat * 60) % 40) % 5) * 60) / 30);
  let x3 = Math.floor(((((lon - Math.floor(lon)) * 60) % 7.5) * 60) / 45);
  let m3 = y3.toString() + x3.toString();
  //　2分の1地域メッシュ（緯度差15秒、経度差22.5秒。1辺の長さ約500m）
  let m4 =
    Math.floor((((((lat * 60) % 40) % 5) * 60) % 30) / 15) * 2 +
    Math.floor((((((lon - Math.floor(lon)) * 60) % 7.5) * 60) % 45) / 22.5) +
    1;
  return [m1, m2, m3, m4].join("");
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      left: "25%",
      width: "620px",
      height: "84%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 4, 3),
      "&:focus": {
        outline: "none",
      },
      borderRadius: "1%",
    },
    closeButton: {
      left: "99.5%",
      width: "5px",
      height: "5px",
      "&:hover": {
        backgroundColor: "lightgray",
      },
    },
    listPaper: {
      height: "80%",
      overflow: "auto",
      padding: "0px 0px",
      marginTop: "5px",
    },
    searchTextField: {
      padding: "0px 2px",
    },
    howToText: {
      color: "red",
      margin: "2px",
    },
  })
);

const SelectStoreMenu: React.FC<{
  itemOrder: string;
  itemValue: string;
  graphType: string;
  openTenpoSelect?: boolean;
  handleOpenTenpoSelect?: any;
  firstView?: boolean;
  handleFirstView?: any;
}> = ({
  itemOrder,
  itemValue,
  graphType,
  openTenpoSelect,
  handleOpenTenpoSelect,
  firstView,
  handleFirstView,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  const [textDisplay, setTextDisplay] = React.useState(true);
  const existMarketArea = React.useRef(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");
  const user = useSelector(selectUser);
  const cvrFirstItem = useSelector(selectFirstCvrItem);
  const MAX_SEARCH_LIST = 150;
  const store: any = getAllExistingSejStores().store_data;
  const [tenpoList, setTenpoList] = React.useState(userStoreButton());
  const tempFirstView = React.useRef<boolean | undefined>(false);
  const isPartialAuth =
    user.jobCategory === "ofc" ||
    user.jobCategory === "dm" ||
    user.jobCategory === "gm" ||
    user.jobCategory === "zm" ||
    user.jobCategory === "fcTrainee";
  const text = itemValue ? "店番：" + itemValue : "店舗を選択";

  // 初期表示処理
  useEffect(() => {
    if (graphType === "cvr") {
      tempFirstView.current = firstView;
      if (openTenpoSelect) {
        setOpen(true);
        handleOpenTenpoSelect(false);
      }
    }
  }, [openTenpoSelect]);

  useEffect(() => {
    if (graphType === "cvr") {
      tempFirstView.current = firstView;
    }
  }, [firstView]);

  // 検索文字列が更新された際に動く
  useEffect(() => {
    // ボタンのリストを更新
    if (isPartialAuth) {
      setTenpoList(userStoreButton());
    } else {
      setTenpoList(allStoreButton());
    }
  }, [searchWord]);

  // グラフ選択ボタンが更新された際に動く
  useEffect(() => {
    cvrTenpoItem = cvrFirstItem;
  }, [cvrFirstItem]);

  const getText = () => {
    return (
      <div>
        <p className={classes.howToText}>
          分析対象の店舗選択より担当店を選択して下さい。
          <br />
          比較対象は選択した店舗を含むエリアを自動で選択します。
        </p>
      </div>
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (graphType === "cvr") {
      handleFirstView(false);
    }
  };

  const selectStore = async (tenpoCode: string) => {
    let lat: number = Number(store.get(tenpoCode).latitude);
    let lon: number = Number(store.get(tenpoCode).longitude);
    const meshCode = latlngToMeshcode(lat, lon);
    if (itemOrder === "first") {
      if (graphType === "cvr") {
        if (tempFirstView.current) {
          initGraphItems();
        }
        const secondCvrItem = existMarketArea.current
          ? ["market-area"]
          : [meshCode];
        dispatch(
          updateSpareMobakuItem({
            spareMobakuItem: [meshCode],
          })
        );
        dispatch(
          updateFirstCvrItem({
            firstCvrItem: tenpoCode,
          })
        );
        dispatch(
          updateSecondCvrItem({
            secondCvrItem: secondCvrItem,
          })
        );
        dispatch(
          updateSelectingCvrMobakuItem({
            selectingCvrMobakuItem: secondCvrItem,
          })
        );

        setMobakuName(meshCode, graphType, itemOrder);
      } else if (graphType === "tenpo") {
        dispatch(
          updateFirstTenpoItem({
            firstTenpoItem: tenpoCode,
          })
        );
      }
    } else if (itemOrder === "second") {
      dispatch(
        updateSecondTenpoItem({
          secondTenpoItem: tenpoCode,
        })
      );
      setMobakuName(meshCode, graphType, itemOrder);
    }
  };

  // 冗長、要修正
  const setMobakuName = async (
    meshCode: string,
    graphType: string,
    order: string
  ) => {
    let mobakuName: string;
    if (order === "first") {
      if (graphType === "cvr") {
        if (!existMarketArea.current) {
          await getFirestoreData(
            "mesh_500m",
            "attribution.key_code",
            meshCode
          ).then((response: any) => {
            mobakuName = response.attribution.center_address;
            if (mobakuName === undefined || mobakuName === "") {
              mobakuName = hankaku2Zenkaku(meshCode);
            }
            dispatch(
              updateCvrMobakuName({
                cvrMobakuName: mobakuName,
              })
            );
          });
        } else {
          mobakuName = "店舗の範囲";
          dispatch(
            updateCvrMobakuName({
              cvrMobakuName: mobakuName,
            })
          );
        }
        if (tempFirstView.current) {
          await getFirestoreData(
            "mesh_500m",
            "attribution.key_code",
            meshCode
          ).then((response: any) => {
            mobakuName = response.attribution.center_address;
            if (mobakuName === undefined || mobakuName === "") {
              mobakuName = hankaku2Zenkaku(meshCode);
            }
            dispatch(
              updateFirstMobakuName({
                firstMobakuName: mobakuName,
              })
            );
          });
        }
      } else if (graphType === "mobaku") {
        await getFirestoreData(
          "mesh_500m",
          "attribution.key_code",
          meshCode
        ).then((response: any) => {
          mobakuName = response.attribution.center_address;
          if (mobakuName === undefined || mobakuName === "") {
            mobakuName = hankaku2Zenkaku(meshCode);
          }
          dispatch(
            updateFirstMobakuName({
              firstMobakuName: mobakuName,
            })
          );
        });
      }
    } else {
      if (graphType === "mobaku") {
        await getFirestoreData(
          "mesh_500m",
          "attribution.key_code",
          meshCode
        ).then((response: any) => {
          mobakuName = response.attribution.center_address;
          if (mobakuName === undefined || mobakuName === "") {
            mobakuName = hankaku2Zenkaku(meshCode);
          }
          dispatch(
            updateSecondMobakuName({
              secondMobakuName: mobakuName,
            })
          );
        });
      }
    }
  };

  // 初期店舗選択時に既存のグラフの設定を初期化する
  const initGraphItems = () => {
    dispatch(
      updateFirstCvrItem({
        firstCvrItem: "",
      })
    );
    dispatch(
      updateSecondCvrItem({
        secondCvrItem: [],
      })
    );
    dispatch(
      updateFirstTenpoItem({
        firstTenpoItem: "",
      })
    );
    dispatch(
      updateSecondTenpoItem({
        secondTenpoItem: "",
      })
    );
    dispatch(
      updateFirstMobakuItem({
        firstMobakuItem: [],
      })
    );
    dispatch(
      updateSecondMobakuItem({
        secondMobakuItem: [],
      })
    );
    // コンポーネントを初期化した状態で再レンダリングする
    dispatch(
      updateInitializeGraphValue({
        initializeGraphValue: Math.random(),
      })
    );
  };

  const handleSelect = async (tenpoCode: string) => {
    await getExisitMarketArea(tenpoCode);
    selectStore(tenpoCode);
    setOpen(false);
    if (graphType === "cvr") {
      handleFirstView(false);
    }
  };

  function userStoreButton() {
    let tenpoList: any[] = [""];
    let hitCount: number = 0;
    let userTenpoCodeList: any[] = user.tenpoCode;
    for (let [key] of store) {
      // もし店名、店番がないもしくはユーザーの店舗でなければ次の店舗
      if (
        store.get(key).tenpo_name === undefined ||
        store.get(key).tenpo_code === undefined ||
        !userTenpoCodeList.includes(store.get(key).tenpo_code)
      )
        continue;
      // 検索文字列にマッチしたものだけリストに入れる
      if (
        store.get(key).tenpo_code.match(searchWord) ||
        store.get(key).tenpo_name.match(searchWord)
      ) {
        tenpoList.push(
          <Button
            key={itemOrder + "-" + key}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={async () => {
              await handleSelect(store.get(key).tenpo_code);
            }}
          >
            <div
              // ローカル以外でスタイルが効かない（原因不明）ため直書き
              style={{
                width: "100%",
                height: "38px",
                textAlign: "left",
                marginLeft: "10px",
                marginTop: "2px",
                fontSize: "24px",
              }}
            >
              {store.get(key).tenpo_code}：{store.get(key).tenpo_name}
            </div>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return tenpoList;
  }

  function allStoreButton() {
    let tenpoList: any[] = [""];
    let hitCount: number = 0;
    for (let [key] of store) {
      if (
        key === undefined ||
        searchWord === "" ||
        store.get(key) === undefined ||
        store.get(key).tenpo_code === undefined ||
        store.get(key).tenpo_name === undefined
      )
        continue;
      // 検索文字列にマッチしたものだけリストに入れる
      if (
        store.get(key).tenpo_code.match(searchWord) ||
        store.get(key).tenpo_name.match(searchWord)
      ) {
        tenpoList.push(
          <Button
            key={key.toString()}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={async () => {
              await handleSelect(store.get(key).tenpo_code);
            }}
          >
            <div
              // ローカル以外でスタイルが効かない（原因不明）ため直書き
              style={{
                width: "100%",
                height: "38px",
                textAlign: "left",
                marginLeft: "10px",
                marginTop: "2px",
                fontSize: "24px",
              }}
            >
              {store.get(key).tenpo_code}：{store.get(key).tenpo_name}
            </div>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return tenpoList;
  }

  // 分析対象で選択した店舗に任意商圏が設定されているか判定
  const getExisitMarketArea = async (tenpoCode: string) => {
    existMarketArea.current = existMarketArea.current && false;
    if (tenpoCode) {
      const marketAreaValue = await getFirestoreDocData(
        "market_area",
        getMarketAreaDocId(tenpoCode)
      ).then((response: any) => {
        return response;
      });
      if (marketAreaValue !== undefined) {
        existMarketArea.current = true;
      }
    }
  };

  return (
    <div>
      {itemOrder === "first" ? (
        <GraphButton
          onClick={() => {
            handleOpen();
          }}
          text={text}
          color={"blue"}
        ></GraphButton>
      ) : (
        <GraphButton
          onClick={() => {
            handleOpen();
          }}
          text={text}
          color={"green"}
        ></GraphButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <TextField
            className={classes.searchTextField}
            value={searchWord}
            label="店番・店名で探す"
            variant="outlined"
            size="medium"
            fullWidth={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchWord(e.target.value);
              e.target.value ? setTextDisplay(false) : setTextDisplay(true);
            }}
          />
          {textDisplay && getText()}
          <div className={classes.listPaper}>{tenpoList}</div>
        </div>
      </Modal>
    </div>
  );
};

export default SelectStoreMenu;
