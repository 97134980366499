import React, { useEffect, FC } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Bar,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import { DateTime } from "luxon";
import { postRequest } from "../../../apis/axiosAction";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { getFixedFlg } from "../../Header/Header";
import {
  getTSCheckData,
  TS_CHECK_ADD_BIG_RECORD,
  TS_CHECK_BIG_RECORD,
  TS_CHECK_DATA,
} from "./TSCheckTableDataOperation";
import { stylesTSCheckTable } from "./StylesTSCheckRegister";
import { calculationTotalScore } from "./TSCheckCalculation";
import { userLogging } from "../../../apis/userLog";
import TSCheckDialog from "../TSCheckDialog";
import { selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  datePane: {
    backgroundColor: "lightgray",
    padding: 12,
  },
  firstRowCell: {
    [theme.breakpoints.down("md")]: {
      width: "3%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "2.9%",
    },
    padding: "6px 2px",
  },
  secondRowCell: {
    [theme.breakpoints.down("md")]: {
      width: "8%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "8%",
    },
    padding: "6px 1px",
    fontSize: "11px",
    fontWeight: "bold",
  },
  cell: {
    [theme.breakpoints.down("md")]: {
      width: "5%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "9.4%",
    },
    padding: "10px 5px",
    fontSize: "18px",
  },
  spaceCell: {
    [theme.breakpoints.down("md")]: {
      width: "4.5%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "4.5%",
    },
    padding: "0px",
  },
  totalColor: {
    color: "#82ca9d",
    fontSize: "20px",
  },
  score1Color: {
    color: "#8884d8",
    fontSize: "20px",
  },
  score2Color: {
    color: "green",
    fontSize: "20px",
  },
  score3Color: {
    color: "coral",
    fontSize: "20px",
  },
  score4Color: {
    color: "skyblue",
    fontSize: "20px",
  },
  score5Color: {
    color: "peru",
    fontSize: "20px",
  },
  score6Color: {
    color: "plum",
    fontSize: "20px",
  },
  score7Color: {
    color: "gold",
    fontSize: "20px",
  },
  score8Color: {
    color: "pink",
    fontSize: "20px",
  },
  customTooltip: {
    padding: "5px",
    border: "1px solid lightgray",
    backgroundColor: "rgba(255,255,255,0.9)",
    pointerEvents: "all",
    borderRadius: "5%",
  },
  customTooltipContent: {
    listStyle: "none",
  },
}));

// 小数点以下を四捨五入
const roundOffDecimal = (num: any) => {
  let value: number = Number(num);
  if (value) {
    return Math.round(value);
  }
  return 0;
};

const TSCheckResultGraphs: FC<{
  tenpoCode: string;
  tsCheckId: string;
  lastTSCheckId: string;
}> = ({ tenpoCode, tsCheckId, lastTSCheckId }) => {
  const user = useSelector(selectUser);
  const classes = useStyles();
  const stylesClasses = stylesTSCheckTable();

  const [approvalDate, setApprovalDate] = React.useState<DateTime | null>(null);
  const [dataMapList, setdataMapList] = React.useState<any[]>([]);
  // TODO zoCode,taragetDateの初期値を設定
  const [tenpCcode] = React.useState("");
  const [targetDate] = React.useState("");
  const [activeTooltip, setActiveTooltip] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  // ダイアログ表示
  const dialogOK = () => {
    setDialogFlg(false);
  };
  // 検索実行処理
  useEffect(() => {
    setLoading(true);
    userLogging("店舗衛生点検表", "集計#初期表示", tsCheckId);
    getTSCheckData(
      tenpoCode,
      tsCheckId,
      lastTSCheckId,
      getFixedFlg(),
      user.jobCategory
    ).then((res: TS_CHECK_DATA) => {
      let appDate = res.approve_day
        ? DateTime.fromFormat(res.approve_day, "yyyyMMdd")
        : DateTime.local();
      setApprovalDate(appDate);
      const apiParams = {
        "api-name": "summary",
        target_date: appDate.toLocaleString() + " 00:00:00",
        tenpo_code: tenpoCode,
      };

      // 当月分の計算
      const rowItemList: TS_CHECK_BIG_RECORD[] = res.base_value;
      const addRowItemList: TS_CHECK_ADD_BIG_RECORD[] = res.add_value;
      interface LOCAL_SCORE {
        onCount: number;
        allCount: number;
      }
      function getThisWeekData() {
        let data001 = { onCount: 0, allCount: 0 };
        let data002 = { onCount: 0, allCount: 0 };
        let data003 = { onCount: 0, allCount: 0 };
        let data004 = { onCount: 0, allCount: 0 };
        let data005 = { onCount: 0, allCount: 0 };
        let data006 = { onCount: 0, allCount: 0 };
        let data007 = { onCount: 0, allCount: 0 };
        let data008 = { onCount: 0, allCount: 0 };
        let data009 = { onCount: 0, allCount: 0 };
        let resultData: any = {};

        resultData["total"] = calculationTotalScore(
          rowItemList,
          addRowItemList
        );
        for (let i = 0; i < rowItemList.length; i++) {
          for (let j = 0; j < rowItemList[i].rows.length; j++) {
            if (rowItemList[i].rows[j].big_item_id === 1) {
              setScore(data001, Number(rowItemList[i].rows[j].score));
            }
            if (rowItemList[i].rows[j].big_item_id === 2) {
              setScore(data002, Number(rowItemList[i].rows[j].score));
            }
            if (rowItemList[i].rows[j].big_item_id === 3) {
              setScore(data003, Number(rowItemList[i].rows[j].score));
            }
            if (rowItemList[i].rows[j].big_item_id === 4) {
              setScore(data004, Number(rowItemList[i].rows[j].score));
            }
            if (rowItemList[i].rows[j].big_item_id === 5) {
              setScore(data005, Number(rowItemList[i].rows[j].score));
            }
            if (rowItemList[i].rows[j].big_item_id === 6) {
              setScore(data006, Number(rowItemList[i].rows[j].score));
            }
            if (rowItemList[i].rows[j].big_item_id === 7) {
              setScore(data007, Number(rowItemList[i].rows[j].score));
            }
            if (rowItemList[i].rows[j].big_item_id === 8) {
              setScore(data008, Number(rowItemList[i].rows[j].score));
            }
          }
        }
        for (let i = 0; i < addRowItemList.length; i++) {
          for (let j = 0; j < addRowItemList[i].rows.length; j++) {
            setScore(data009, Number(addRowItemList[i].rows[j].score));
          }
        }
        if (!addRowItemList) {
          setScore(data009, 0);
        }
        resultData["score001"] = calcScore(data001);
        resultData["score002"] = calcScore(data002);
        resultData["score003"] = calcScore(data003);
        resultData["score004"] = calcScore(data004);
        resultData["score005"] = calcScore(data005);
        resultData["score006"] = calcScore(data006);
        resultData["score007"] = calcScore(data007);
        resultData["score008"] = calcScore(data008);
        resultData["score009"] = calcScore(data009);
        return resultData;
      }
      function setScore(scoreObj: LOCAL_SCORE, score: number) {
        if (score !== -1) scoreObj.onCount++;
        if (score > 0) scoreObj.allCount++;
      }
      function calcScore(scoreObj: LOCAL_SCORE) {
        if (scoreObj.onCount === 0) {
          return 0;
        }
        return Math.round((scoreObj.allCount / scoreObj.onCount) * 100);
      }

      const url = process.env.REACT_APP_GAE_API_URL + "tscheck/summary";
      postRequest(url, apiParams)
        .then((res) => {
          let dataList: any[] = res.data.dataList;
          let thisWeekDate: any = getThisWeekData();
          if (dataList) {
            for (let i = 0; i < dataList.length; i++) {
              var m = parseInt(dataList[i].week.substr(3, 2));
              var d = parseInt(dataList[i].week.substr(5, 2));
              var dt = m + "/" + d;
              dataList[i].week = dt;
              if (i === 0) {
                dataList[i].total = thisWeekDate.total;
                dataList[i].score001 = thisWeekDate.score001;
                dataList[i].score002 = thisWeekDate.score002;
                dataList[i].score003 = thisWeekDate.score003;
                dataList[i].score004 = thisWeekDate.score004;
                dataList[i].score005 = thisWeekDate.score005;
                dataList[i].score006 = thisWeekDate.score006;
                dataList[i].score007 = thisWeekDate.score007;
                dataList[i].score008 = thisWeekDate.score008;
                dataList[i].score009 = thisWeekDate.score009;
              }
              dataList[i].total = roundOffDecimal(dataList[i].total);
              dataList[i].score001 = roundOffDecimal(dataList[i].score001);
              dataList[i].score002 = roundOffDecimal(dataList[i].score002);
              dataList[i].score003 = roundOffDecimal(dataList[i].score003);
              dataList[i].score004 = roundOffDecimal(dataList[i].score004);
              dataList[i].score005 = roundOffDecimal(dataList[i].score005);
              dataList[i].score006 = roundOffDecimal(dataList[i].score006);
              dataList[i].score007 = roundOffDecimal(dataList[i].score007);
              dataList[i].score008 = roundOffDecimal(dataList[i].score008);
              dataList[i].score009 = roundOffDecimal(dataList[i].score009);
            }
            dataList.reverse();
            setdataMapList(dataList);
          }
        })
        .catch((r) => {
          userLogging(
            "店舗衛生点検表",
            "集計#初期表示エラー",
            tsCheckId + " " + JSON.stringify(r)
          );
          setDialogMessage([String(r)]);
          setDialogFlg(true);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [tenpCcode, targetDate]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length && activeTooltip) {
      return (
        <div className={classes.customTooltip}>
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={() => setActiveTooltip(false)} size={"small"}>
              <HighlightOffIcon />
            </IconButton>
          </div>
          <li className={classes.customTooltipContent}>{`${label}`}</li>
          {payload.map((payload: any, index: any) => {
            let newName = "";
            let color;
            switch (payload.name) {
              case "total":
                newName = "合計";
                color = "#82ca9d";
                break;
              case "score001":
                newName = "個人衛生";
                color = "#8884d8";
                break;
              case "score002":
                newName = "ＦＦ原材料 管理";
                color = "green";
                break;
              case "score003":
                newName = "販売管理";
                color = "coral";
                break;
              case "score004":
                newName = "温度管理";
                color = "skyblue";
                break;
              case "score005":
                newName = "商品鮮度管理";
                color = "peru";
                break;
              case "score006":
                newName = "表示関連 産地表示 アレルゲン表示";
                color = "plum";
                break;
              case "score007":
                newName = "設備・ 備品管理 クリンリネス";
                color = "gold";
                break;
              case "score008":
                newName = "衛生管理";
                color = "pink";
                break;
              case "score009":
                newName = "商品・売場・地区政策等";
                color = "black";
                break;
            }
            return (
              <li
                className={classes.customTooltipContent}
                style={{ color: color }}
                key={index.toString()}
              >
                {newName +
                  "：" +
                  new Intl.NumberFormat("en").format(payload.value) +
                  "%"}
              </li>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const handleActiveTooltip = () => {
    setActiveTooltip(true);
  };

  // ReactStrinctModeでエラーが出ているけどいったん保留 React.StrictMode → React.Fragment にすれば直る
  return (
    <Grid container justify="center">
      {loading && <div className={stylesClasses.overLayer}></div>}
      {loading && (
        <CircularProgress
          className={stylesClasses.circularProgress}
          size={120}
        />
      )}
      <Grid container item xs={12} justify="center">
        <Grid container item xs={12} justify="flex-start">
          <Typography color="textSecondary" gutterBottom>
            ▼個店数値
          </Typography>
        </Grid>
        <Grid container item xs={12} justify="flex-start">
          <>
            <ResponsiveContainer height={400}>
              <ComposedChart
                data={dataMapList}
                margin={{ top: 30, right: 0, bottom: 5, left: 80 }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  dataKey="week"
                  scale="band"
                  label={{
                    value: "(月)",
                    dx: 50,
                    position: "insideBottomRight",
                  }}
                />
                <YAxis
                  yAxisId={1}
                  domain={[0, 100]}
                  tickCount={5}
                  label={{
                    value: "(合計)",
                    position: "insideTopLeft",
                    dy: -30,
                  }}
                />
                <YAxis
                  yAxisId={2}
                  orientation="right"
                  domain={[0, 100]}
                  tickCount={5}
                  tickFormatter={(tickItem) => tickItem + "%"}
                  label={{
                    value: "(得点率)",
                    position: "insideTopLeft",
                    dy: -30,
                  }}
                />
                <Tooltip trigger="click" content={<CustomTooltip />} />
                <Bar
                  dataKey="total"
                  barSize={20}
                  fill="#82ca9d"
                  yAxisId={1}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score001"
                  stroke="#8884d8"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score002"
                  stroke="green"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score003"
                  stroke="coral"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score004"
                  stroke="skyblue"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score005"
                  stroke="peru"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score006"
                  stroke="plum"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score007"
                  stroke="gold"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score008"
                  stroke="pink"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
                <Line
                  type="linear"
                  dataKey="score009"
                  stroke="black"
                  yAxisId={2}
                  onClick={() => {
                    handleActiveTooltip();
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </>
        </Grid>
        <Grid container item xs={12} justify="flex-start">
          <Table aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.totalColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  合計
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.total}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score1Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  個人衛生
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score001}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score2Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  ＦＦ原材料 管理
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score002}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score3Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  販売管理
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score003}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score4Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  温度管理
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score004}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score5Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  商品鮮度管理
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score005}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score6Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  表示関連 産地表示
                  <br />
                  アレルゲン表示
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score006}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score7Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  設備・ 備品管理 <br /> クリンリネス
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score007}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score8Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  衛生管理
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score008}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.score8Color}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  商品・売場・地区政策等
                </TableCell>
                {dataMapList.map((data: any, index: number) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                  >
                    {data.score009}
                  </TableCell>
                ))}
                <TableCell className={classes.spaceCell}>　</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {/* ダイアログ */}
      <TSCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogOK}
        doNo={dialogOK}
        isAlert={true}
      />
    </Grid>
  );
};

export default TSCheckResultGraphs;
