/**
 * existingSejStoreLayer.ts
 * 地図上の既存店の表示管理を行います
 */

import { getGoogleMapsObject } from "../GoogleMaps";
import { calcPointsWithinCircle } from "../../../apis/turfAction";
import {
  unvisibleMapsStyleSettings,
  applyHtmlTemplate,
  zIndexOrder,
} from "../MapStylesSettings";
import { updateFireStoreLayer, manageDisplayLayer } from "../MapItems";
import {
  EXISTING_SEJ_STORES_RECORD,
  getAllExistingSejStores,
  INTERFACE_STORE_DATA,
} from "../../../data/existingSejStores";
import {
  selectTenpoCode,
  updateTenpoName,
  updateTenpoCode,
  updateCreateMarketAreaFlg,
  updateEditMarketAreaFlg,
  updateDeleteMarketAreaFlg,
  updateTenpoLatLng,
} from "../../../features/marketAreaStatusSlice";
import {
  drawPolygon,
  displayPolygons,
  editPolygon,
  hidePolygon,
  displayPolygon,
  updateDsiplayFlgMarketArea,
} from "../../OpView/MarketArea/PolygonDrawing";
import { userInfo } from "../Maps";
import { getFirestoreDocData } from "../../../apis/firestoreAction";
import {
  isTrainingUser,
  isTsUser,
  isFTraineeUser,
} from "../../../apis/privilege";
import { getMarketAreaDocId } from "../../OpView/MarketArea/MarketAreaControl";

export let existingSejStoreLayer: google.maps.Data,
  existingSejStoreInfoWindow = new Map(),
  existingSejStoreData: any,
  existingSejStoreLayerEvent: any,
  selectedTenpoCode = "";

// 既存店舗処理
let isApiRequest = false;
export const cbFuncExistingSejStoresFirst = () => {
  if (isApiRequest) {
    return true;
  }
  isApiRequest = true;
  return false;
};

export const cbFuncExistingSejStores = (
  geojson: any,
  updateLayer: any,
  conditions: any
) => {
  existingSejStoreData = geojson;
  updateFireStoreLayer(updateLayer);
  isApiRequest = false;
};

// 既存店舗初期処理
export const initExistingSejStores = (dispatch: any) => {
  existingSejStoreLayer = new google.maps.Data({
    map: getGoogleMapsObject(),
    style: {
      visible: false,
    },
  });
  // クリックイベントの登録
  existingSejStoreLayerEvent = existingSejStoreLayer.addListener(
    "click",
    async (event: any) => {
      if (!existingSejStoreInfoWindow.has(event.feature)) {
        // firestoreから対象の店舗の任意商圏の情報を取得する
        const marketAreaValue = await getFirestoreDocData(
          "market_area",
          getMarketAreaDocId(event.feature.getProperty("tenpo_code"))
        ).then((response: any) => {
          return response;
        });
        // インフォウィンドウの設定
        existingSejStoreInfoWindow.set(
          event.feature,
          new google.maps.InfoWindow({
            // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
            maxWidth: 300,
          })
        );
        // 表示位置
        existingSejStoreInfoWindow.get(event.feature).setPosition(event.latLng);
        // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
        existingSejStoreInfoWindow
          .get(event.feature)
          .setContent(
            applyHtmlTemplate(existingSejStoreInfoWindowHtml, [
              event.feature.getProperty("tenpo_code"),
              event.feature.getProperty("tenpo_name"),
              event.feature.getProperty("original_open_date"),
              event.feature.getProperty("contract_type"),
            ])
          );

        existingSejStoreInfoWindow
          .get(event.feature)
          .setOptions({ pixelOffset: new google.maps.Size(0, -25), headerDisabled: true });
        // InfowWindowを表示
        existingSejStoreInfoWindow
          .get(event.feature)
          .open(getGoogleMapsObject());
        existingSejStoreInfoWindow
          .get(event.feature)
          .addListener("domready", () => {
            // インフォウィンドウに表示するボタンを制御
            const tenpoCode = event.feature.getProperty("tenpo_code");
            const tenpoName = event.feature.getProperty("tenpo_name");
            const tenpoLatLng = event.feature.getProperty("lat_lng");
            // const tenpoPosition = event.feature.getProperty("tenpo_name");
            const closeElement = document.getElementById(
              tenpoCode + "-close"
            );
            if (closeElement) {
              // 作成ボタンを表示
              closeElement.style.display = "block";
              closeElement.addEventListener("click", function (e) {
                existingSejStoreInfoWindow
                  .get(event.feature)
                  .close();
                existingSejStoreInfoWindow
                  .delete(event.feature);
              });
            }
            const createElement = document.getElementById(
              tenpoCode + "-create-market-area"
            );
            const editElement = document.getElementById(
              tenpoCode + "-edit-market-area"
            );
            const deleteElement = document.getElementById(
              tenpoCode + "-delete-market-area"
            );
            const displayElement = document.getElementById(
              tenpoCode + "-display-market-area"
            );
            const nonDisplayElement = document.getElementById(
              tenpoCode + "-non-display-market-area"
            );
            const addCreateButton = () => {
              if (createElement) {
                // 作成ボタンを表示
                createElement.style.display = "block";
                createElement.addEventListener("click", function (e) {
                  selectTenpo(tenpoName, tenpoCode, event, dispatch, true);
                  dispatch(
                    updateCreateMarketAreaFlg({
                      createMarketAreaFlg: true,
                    })
                  );
                  dispatch(
                    updateTenpoLatLng({
                      tenpoLatLng: tenpoLatLng,
                    })
                  );
                  selectedTenpoCode = tenpoCode;
                  manageDisplayLayer(existingSejStoreLayer, true);
                  drawPolygon(dispatch, tenpoCode, tenpoLatLng, false);
                });
              }
            };
            // 編集ボタンを表示
            const addEditButton = (polygon: any) => {
              if (editElement) {
                editElement.style.display = "block";
                editElement.addEventListener("click", function (e) {
                  selectTenpo(tenpoName, tenpoCode, event, dispatch, true);
                  dispatch(
                    updateEditMarketAreaFlg({
                      editMarketAreaFlg: true,
                    })
                  );
                  dispatch(
                    updateTenpoLatLng({
                      tenpoLatLng: tenpoLatLng,
                    })
                  );
                  selectedTenpoCode = tenpoCode;
                  manageDisplayLayer(existingSejStoreLayer, true);
                  editPolygon(
                    polygon,
                    getGoogleMapsObject(),
                    tenpoCode,
                    tenpoLatLng,
                    dispatch,
                    false
                  );
                });
              }
            };
            // 削除ボタンを表示
            const addDeleteButton = () => {
              if (deleteElement) {
                deleteElement.style.display = "block";
                deleteElement.addEventListener("click", function (e) {
                  selectTenpo(tenpoName, tenpoCode, event, dispatch);
                  dispatch(
                    updateDeleteMarketAreaFlg({
                      deleteMarketAreaFlg: true,
                    })
                  );
                });
              }
            };
            // 表示ボタンを表示
            const addDisplayButton = (polygon: any) => {
              if (displayElement) {
                displayElement.style.display = "block";
                displayElement.addEventListener("click", function (e) {
                  displayPolygons(
                    polygon,
                    getGoogleMapsObject(),
                    tenpoCode,
                    false
                  );
                  updateDsiplayFlgMarketArea(tenpoCode, "1");
                  existingSejStoreInfoWindow.get(event.feature).close();
                  existingSejStoreInfoWindow.delete(event.feature);
                  // selectedTenpoCode = tenpoCode;
                  manageDisplayLayer(existingSejStoreLayer, true);
                });
              }
            };
            // 非表示ボタンを表示
            const addNonDisplayButton = () => {
              if (nonDisplayElement) {
                nonDisplayElement.style.display = "block";
                nonDisplayElement.addEventListener("click", function (e) {
                  hidePolygon(tenpoCode, false);
                  updateDsiplayFlgMarketArea(tenpoCode, "0");
                  existingSejStoreInfoWindow.get(event.feature).close();
                  existingSejStoreInfoWindow.delete(event.feature);
                  // selectedTenpoCode = tenpoCode;
                  manageDisplayLayer(existingSejStoreLayer, true);
                });
              }
            };
            // 対象の店舗で任意商圏が設定されているか確認する
            let existMarketArea: boolean;
            if (marketAreaValue === undefined) {
              existMarketArea = false;
            } else {
              existMarketArea = true;
            }
            if (
              userInfo.jobCategory === "admin-group" ||
              isTrainingUser(userInfo)
            ) {
              // admin-groupとトレーニングユーザーの任意商圏ボタン制御
              if (existMarketArea) {
                const polygon = JSON.parse(marketAreaValue.feature);
                addEditButton(polygon);
                addDeleteButton();
                if (displayPolygon.hasOwnProperty(tenpoCode)) {
                  addNonDisplayButton();
                } else {
                  addDisplayButton(polygon);
                }
              } else {
                addCreateButton();
              }
            } else if (
              // 直営店社員とFトレ生の任意商圏ボタン制御
              (isTsUser(userInfo) || isFTraineeUser(userInfo)) &&
              userInfo.tenpoCode.includes(
                event.feature.getProperty("tenpo_code")
              )
            ) {
              if (existMarketArea) {
                const polygon = JSON.parse(marketAreaValue.feature);
                addEditButton(polygon);
                addDeleteButton();
                if (displayPolygon.hasOwnProperty(tenpoCode)) {
                  addNonDisplayButton();
                } else {
                  addDisplayButton(polygon);
                }
              } else {
                addCreateButton();
              }
            } else if (
              userInfo.jobCategory === "ofc" &&
              userInfo.tenpoCode.includes(
                event.feature.getProperty("tenpo_code")
              )
            ) {
              // ofcの任意商圏ボタン制御
              if (existMarketArea) {
                const polygon = JSON.parse(marketAreaValue.feature);
                addEditButton(polygon);
                addDeleteButton();
                if (displayPolygon.hasOwnProperty(tenpoCode)) {
                  addNonDisplayButton();
                } else {
                  addDisplayButton(polygon);
                }
              } else {
                addCreateButton();
              }
            } else if (
              userInfo.tenpoCodeSpecialList &&
              userInfo.tenpoCodeSpecialList.includes(
                event.feature.getProperty("tenpo_code")
              )
            ) {
              // 担当店を持つsofcの任意商圏ボタン制御
              if (existMarketArea) {
                const polygon = JSON.parse(marketAreaValue.feature);
                addEditButton(polygon);
                addDeleteButton();
                if (displayPolygon.hasOwnProperty(tenpoCode)) {
                  addNonDisplayButton();
                } else {
                  addDisplayButton(polygon);
                }
              } else {
                addCreateButton();
              }
            } else if (
              userInfo.tenpoCode.includes(
                event.feature.getProperty("tenpo_code")
              )
            ) {
              // admin-group、ofc、担当店を持つsofc以外の任意商圏ボタン制御
              if (existMarketArea) {
                if (displayPolygon.hasOwnProperty(tenpoCode)) {
                  addNonDisplayButton();
                } else {
                  const polygon = JSON.parse(marketAreaValue.feature);
                  addDisplayButton(polygon);
                }
              }
            }
          });
        existingSejStoreInfoWindow
          .get(event.feature)
          .addListener("closeclick", function () {
            existingSejStoreInfoWindow.delete(event.feature);
          });
      } else {
        existingSejStoreInfoWindow.get(event.feature).close();
        existingSejStoreInfoWindow.delete(event.feature);
      }
    }
  );
  // スタイル指定（最初は非表示）
  existingSejStoreLayer.setStyle(unvisibleMapsStyleSettings);
  manageDisplayLayer(existingSejStoreLayer, false);
};

// 既存店アイコンクリック時の共通処理
const selectTenpo = (
  tenpoName: string,
  tenpoCode: string,
  event: any,
  dispatch: any,
  createFlag: boolean = false
) => {
  dispatch(
    updateTenpoName({
      tenpoName: tenpoName,
    })
  );
  dispatch(
    updateTenpoCode({
      tenpoCode: tenpoCode,
    })
  );
  // selectedTenpoCode = tenpoCode;
  existingSejStoreInfoWindow.get(event.feature).close();
  existingSejStoreInfoWindow.delete(event.feature);
};

export const setUnvisibleMapsStyle = () => {
  existingSejStoreLayer.setStyle(unvisibleMapsStyleSettings);
  selectedTenpoCode = "";
};
/**
 * 内部データよりGoogleMapsに表示する
 */
export const innerDataExistingSejStoreLayer = (
  latLng: google.maps.LatLng,
  searchLength: number
) => {
  const obj: any = getAllExistingSejStores().store_data;
  const array: any = [];
  // GeoJSONに格納する配列をつくる
  for (let [key] of obj) {
    // 店舗レコードを内部データから取得
    let storeRecord = obj.get(key);
    if (storeRecord) {
      array.push(
        turf.point(
          [parseFloat(storeRecord.longitude), parseFloat(storeRecord.latitude)],
          {
            tenpo_code: storeRecord.tenpo_code,
            tenpo_name: storeRecord.tenpo_name,
            original_open_date: storeRecord.original_open_date,
            contract_type: storeRecord.contract_type,
            lat_lng: [
              parseFloat(storeRecord.latitude),
              parseFloat(storeRecord.longitude),
            ],
          }
        )
      );
    }
  }
  return calcPointsWithinCircle(
    array,
    latLng.lng(),
    latLng.lat(),
    searchLength
  );
};

/**
 * GoogleMapsスタイル設定
 */
export const existingSejStoreLayerMapsStyleSettings = function (feature: any) {
  // 日単位でしかキャッシュが残らないようにする
  const date = new Date();
  const dateParam =
    date.getFullYear() +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    date.getDate().toString().padStart(2, "0");
  let iconUrl = "./image/icons/sej_normal.png?date=";
  // 選択した既存店のアイコンをオレンジにする
  if (feature.getProperty("tenpo_code") === selectedTenpoCode) {
    iconUrl = "./image/icons/sej_selected_3d_o.png?date=";
  }
  return {
    icon: iconUrl + dateParam,
    visible: true,
    zIndex: zIndexOrder.existingSejStoreIcon,
  };
};

const existingSejStoreInfoWindowHtml =
  '<div class="info-window-padding">' +
  '<button class="close-button" id="{0}-close">×</button>' +
  '<div >店番:{0}　</div>' +
  '<div >店名:{1}</div>' +
  '<div >開店日:{2}</div>' +
  '<div >タイプ:{3}</div>' +
  '<button class="market-area-button" id="{0}-create-market-area">範囲を作成</button>' +
  '<button class="market-area-button" id="{0}-edit-market-area">範囲を変更</button>' +
  '<button class="market-area-button" id="{0}-display-market-area">範囲を表示</button>' +
  '<button class="market-area-button" id="{0}-non-display-market-area">範囲を非表示</button>' +
  '<button class="market-area-delete-button" id="{0}-delete-market-area">範囲を削除</button>' +
  "</div>";
