import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import GraphButton from "./GraphButton";
import SelectMobakuMaps from "./SelectMobakuMap/SelectMobakuMaps";
import { getFirestoreDocData } from "../../apis/firestoreAction";
import {
  selectFirstMobakuName,
  selectSecondMobakuName,
  selectCvrMobakuName,
  selectFirstCvrItem,
} from "../../features/tenpoStatusSlice";
import { getMarketAreaDocId } from "./MarketArea/MarketAreaControl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      left: "5%",
      width: "82%",
      height: "80%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 3, 3, 3),
      "&:focus": {
        outline: "none",
      },
    },
  })
);

const SelectMobakuMenu: React.FC<{
  itemOrder: string;
  mobakuItem: string[];
  graphType: string;
}> = ({ itemOrder, mobakuItem, graphType }) => {
  const dispatch = useDispatch();
  const classes = useStyles({});
  const firstMobakuName = useSelector(selectFirstMobakuName);
  const secondMobakuName = useSelector(selectSecondMobakuName);
  const cvrMobakuName = useSelector(selectCvrMobakuName);
  const cvrTenpoItem = useSelector(selectFirstCvrItem);
  const [open, setOpen] = React.useState(false);
  const [existMarketArea, setExistMarketArea] = React.useState(false);

  const getSelectedMobakuName = () => {
    let text;
    let mobakuName: string;
    if (itemOrder === "first") {
      mobakuName = firstMobakuName;
    } else {
      if (graphType === "cvr") {
        mobakuName = cvrMobakuName;
      } else {
        mobakuName = secondMobakuName;
      }
    }
    if (mobakuItem.length < 1) {
      text = "エリアを選択";
    } else if (mobakuItem.length > 1) {
      text = "エリア：複数エリア";
    } else {
      text = "エリア：" + mobakuName;
    }
    return text;
  };

  const handleOpen = async () => {
    if (graphType === "cvr") {
      await getExisitMarketArea();
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 分析対象で選択した店舗に任意商圏が設定されているか判定
  const getExisitMarketArea = async () => {
    existMarketArea && setExistMarketArea(false);
    if (cvrTenpoItem) {
      const marketAreaValue = await getFirestoreDocData(
        "market_area",
        getMarketAreaDocId(cvrTenpoItem)
      ).then((response: any) => {
        return response;
      });
      if (marketAreaValue !== undefined) {
        setExistMarketArea(true);
      }
    }
  };

  return (
    <div>
      {itemOrder === "first" ? (
        <GraphButton
          onClick={() => {
            handleOpen();
          }}
          text={getSelectedMobakuName()}
          color={"blue"}
        ></GraphButton>
      ) : (
        <GraphButton
          onClick={async () => {
            handleOpen();
          }}
          text={getSelectedMobakuName()}
          color={"green"}
        ></GraphButton>
      )}

      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <SelectMobakuMaps
            storeOrder={itemOrder}
            setOpen={setOpen}
            dispatch={dispatch}
            existMarketArea={existMarketArea}
            graphType={graphType}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SelectMobakuMenu;
