import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  ComposedChart,
  Line,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      padding: "5px",
      border: "1px solid lightgray",
      backgroundColor: "rgba(255,255,255,0.9)",
      pointerEvents: "all",
      borderRadius: "5%",
    },
    customTooltipContent: {
      listStyle: "none",
    },
  })
);

const GraphContentZennenhiCVR: React.FC<{
  data: any;
  baseAxis: number;
  firstDate: string;
  today: string;
}> = ({ data, baseAxis, firstDate, today }) => {
  const classes = useStyles({});
  const [activeTooltip, setActiveTooltip] = useState(true);
  let maxLineNum = 0;
  let maxBarNum = 0;
  let barTicksArray: any[] = [];
  let lineTicksArray: any[] = [];

  const calcMaxLineNum = () => {
    let maxNum = 0;
    let tempMax = 0;
    data.forEach((value: any) => {
      if (
        value.conversionRateForGraph !== "-" &&
        value.conversionRateLastYearForGraph !== "-"
      ) {
        tempMax = Math.max(
          value.conversionRateForGraph,
          value.conversionRateLastYearForGraph
        );
      } else if (
        value.conversionRateForGraph === "-" &&
        value.conversionRateLastYearForGraph !== "-"
      ) {
        tempMax = value.conversionRateLastYearForGraph;
      } else if (
        value.conversionRateLastYearForGraph === "-" &&
        value.conversionRateForGraph !== "-"
      ) {
        tempMax = value.conversionRateForGraph;
      }
      maxNum = tempMax ? Math.max(maxNum, tempMax) : maxNum;
    });
    return maxNum;
  };

  const calcMaxBarNum = () => {
    let maxNum = 0;
    data.forEach((value: any) => {
      if (!isNaN(value.ratioDiff)) {
        maxNum = Math.max(maxNum, Math.abs(value.ratioDiff));
      }
    });
    return Math.round(maxNum * 100) / 100;
  };

  const setTicksValues = () => {
    maxLineNum = calcMaxLineNum();
    maxBarNum = calcMaxBarNum();

    barTicksArray = [
      Math.round(-maxBarNum * 100) / 100,
      Math.round(-maxBarNum * 0.75 * 100) / 100,
      Math.round(-maxBarNum * 0.5 * 100) / 100,
      Math.round(-maxBarNum * 0.25 * 100) / 100,
      0,
      Math.round(maxBarNum * 0.25 * 100) / 100,
      Math.round(maxBarNum * 0.5 * 100) / 100,
      Math.round(maxBarNum * 0.75 * 100) / 100,
      Math.round(maxBarNum * 100) / 100,
    ];
    lineTicksArray = [
      -maxLineNum,
      Math.round(-maxLineNum * 0.75 * 100) / 100,
      Math.round(-maxLineNum * 0.5 * 100) / 100,
      Math.round(-maxLineNum * 0.25 * 100) / 100,
      0,
      Math.round(maxLineNum * 0.25 * 100) / 100,
      Math.round(maxLineNum * 0.5 * 100) / 100,
      Math.round(maxLineNum * 0.75 * 100) / 100,
      maxLineNum,
    ];
  };
  const CustomTooltip = ({ active, payload, label }: any) => {
    let unit = "";
    if (baseAxis === 0) {
      unit = "時";
    } else if (baseAxis === 3) {
      unit = "月";
    }
    if (active && payload && payload.length && activeTooltip) {
      return (
        <div className={classes.customTooltip}>
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={() => setActiveTooltip(false)} size={"small"}>
              <HighlightOffIcon />
            </IconButton>
          </div>
          <li className={classes.customTooltipContent}>{`${label} ${unit}`}</li>
          {payload.map((payload: any, index: any) => {
            let newName = "";
            let color;
            switch (payload.name) {
              case "ratioDiff":
                newName = "CVR前年差";
                color = "gray";
                break;
              case "conversionRateForGraph":
                newName = "当年";
                color = "orange";
                break;
              case "conversionRateLastYearForGraph":
                newName = "前年";
                color = "orange";
                break;
            }
            return (
              <li
                className={classes.customTooltipContent}
                style={{ color: color }}
                key={index.toString()}
              >
                {newName + "：" + payload.value}
              </li>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const makeFutureGraph = () => {
    const selectedDate = convertDate(firstDate);
    const twoWeekAgoDate = new Date(
      selectedDate.setDate(selectedDate.getDate() - 14)
    );
    const todayDate = convertDate(today);
    let dateDiff =
      (todayDate.getTime() - convertDate(firstDate).getTime()) / 86400000;
    if (todayDate > twoWeekAgoDate) {
      for (let d = 15 + dateDiff; d < 29; d++) {
        data[d].firstMobakuRatioLastYear = "-";
        data[d].secondMobakuRatioLastYear = "-";
        data[d].firstTenpoRatioLastYear = "-";
        data[d].secondTenpoRatioLastYear = "-";
        data[d].conversionRateForGraph = "-";
        data[d].diffForGraph = "-";
        data[d].ratioDiff = "-";
      }
    }
  };

  const convertDate = (date: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const dateStr = `${year}/${month}/${day}`;
    return new Date(dateStr);
  };

  const handleActiveTooltip = () => {
    setActiveTooltip(true);
  };

  if (data) {
    baseAxis === 1 && data.length === 29 && makeFutureGraph();
    setTicksValues();
  }

  return (
    <ResponsiveContainer maxHeight={290}>
      <ComposedChart
        data={data}
        margin={{ top: 20, right: 20, left: 40, bottom: -15 }}
      >
        <XAxis
          dataKey="date"
          padding={{ right: 15, left: 15 }}
          tickFormatter={() => {
            return "";
          }}
        />
        {/* コンバージョンレートのY軸 */}
        <YAxis
          yAxisId={2}
          domain={[-maxLineNum, maxLineNum]}
          tickCount={9}
          ticks={lineTicksArray}
          tick={{ fontSize: 14 }}
          allowDecimals={true}
          label={{
            value: "コンバージョンレート（％）",
            angle: -90,
            dx: -50,
          }}
        />
        {/* コンバージョンレートの前年比差のY軸 */}
        <YAxis
          yAxisId={4}
          orientation={"right"}
          domain={[-maxBarNum, maxBarNum]}
          tickCount={9}
          ticks={barTicksArray}
          tick={{ fontSize: 14 }}
          label={{
            value: "CVR前年差（％）",
            angle: -90,
            dx: 25,
          }}
        />
        <Legend
          verticalAlign="top"
          payload={[
            {
              value: "当年",
              type: "plainline",
              payload: { strokeDasharray: "0" },
              color: "orange",
            },
            {
              value: "前年",
              type: "plainline",
              payload: { strokeDasharray: "5 5" },
              color: "orange",
            },
          ]}
        />
        <Tooltip trigger="click" content={<CustomTooltip />} />
        <ReferenceLine yAxisId={2} y={0} stroke="black" />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar
          yAxisId={4}
          dataKey="ratioDiff"
          barSize={6}
          fill="#C0C0C0"
          animationEasing="ease-out"
          animationDuration={300}
          onClick={() => {
            setActiveTooltip(true);
          }}
        />
        <Line
          yAxisId={2}
          dataKey="conversionRateForGraph"
          stroke="orange"
          strokeWidth={3}
          dot={false}
          animationEasing="ease-out"
          animationDuration={300}
          onClick={() => {
            setActiveTooltip(true);
          }}
          activeDot={{ onClick: handleActiveTooltip }}
        />
        <Line
          yAxisId={2}
          dataKey="conversionRateLastYearForGraph"
          stroke="orange"
          strokeWidth={3}
          strokeDasharray="5 5"
          dot={false}
          animationEasing="ease-out"
          animationDuration={300}
          onClick={() => {
            setActiveTooltip(true);
          }}
          activeDot={{ onClick: handleActiveTooltip }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default GraphContentZennenhiCVR;
