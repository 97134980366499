import React, { FC, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { getWeatherIcon } from "./WeatherIcon";
import InfoTip from "../../common/InfoTip";
import "../../../index.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
  })
);

const GraphWeather: FC<{
  dayWeather: any;
}> = ({ dayWeather }) => {
  const classes = useStyles();
  const iconDir = `${window.location.origin}/image/icons/weather/`;
  const [iconName, setIconName] = React.useState("");
  const [maxTemp, setMaxTemp] = React.useState("");
  const [minTemp, setMinTemp] = React.useState("");
  const [rainfall, setRainfall] = React.useState("");

  useEffect(() => {
    initWeather();
  });

  const initWeather = () => {
    setIconName(getWeatherIcon(dayWeather.weather_code));
    setMaxTemp(dayWeather?.max_temp);
    setMinTemp(dayWeather?.min_temp);
    if (dayWeather?.rainfall !== 999) {
      setRainfall(dayWeather?.rainfall);
    } else {
      setRainfall("-");
    }
  };

  return (
    <div className={classes.root}>
      {dayWeather.weather_code !== "" ? (
        <a className={"item"} href="#">
          <img
            src={iconDir + "1000.webp"}
            className={"item" + iconName}
            alt={""}
          />
        </a>
      ) : (
        <InfoTip
          iconType="error"
          infoText="複数日または（異なるアメダスの）複数メッシュ選択中は&#13;&#10;天気の表示はされません。&#13;&#10;気温と降水量は平均値となります。"
          placement="top"
        />
      )}

      <p
        style={{
          fontSize: "10px",
          margin: "0px",
        }}
      >
        <span
          style={{
            color: "red",
          }}
        >
          {maxTemp}
        </span>
        {(maxTemp || minTemp) && <span> / </span>}
        <span
          style={{
            color: "blue",
          }}
        >
          {minTemp}
        </span>
      </p>
      <p
        style={{
          fontSize: "10px",
          margin: "0px",
        }}
      >
        {rainfall !== undefined ? rainfall + "㎜" : ""}
      </p>
    </div>
  );
};
export default GraphWeather;
