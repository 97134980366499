import React, { FC, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import OneVisionTabCheck, {
  getChangeFlg,
  getApproveDay,
  getAgreementDay,
  getRegulationDay,
} from "./OneVisionTabCheck";
import OneVisionTabGraphs from "./OneVisionTabGraphs";
import OneVisionTabPrint from "./OneVisionTabPrint";
import OneVisionDialog from "../OneVisionDialog";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
      style={{
        fontSize: "20px",
      }}
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const OneVisionTab: FC<{
  tenpoCode: string;
  oneVisionId: string;
  lastOneVisionId: string;
  setUpdateFlg: any;
}> = ({ tenpoCode, oneVisionId, lastOneVisionId, setUpdateFlg }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  const dialogClose = () => {
    setDialogFlg(false);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (getChangeFlg()) {
      setDialogMessage(["保存されていません。保存ボタンを押してください。"]);
      setDialogFlg(true);
      return;
    }
    if (newValue !== 0) {
      let errorMessage: String[] = [];
      if (!getApproveDay()) {
        errorMessage.push("・作成日");
      }
      if (!getAgreementDay()) {
        errorMessage.push("・三六協定");
      }
      if (!getRegulationDay()) {
        errorMessage.push("・就業規則");
      }
      if (errorMessage && errorMessage.length !==0) {
        let message = ["以下を入力してください"];
        errorMessage.forEach(function (str) {
          message.push(String(str));
        });
        setDialogMessage(message);
        setDialogFlg(true);
        return;
      }
    }
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [tenpoCode]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#37ab9d",
              height: "10px",
            },
          }}
          style={{
            backgroundColor: "#f2f2f2",
            color: "#3e3e3e",
          }}
        >
          <LinkTab label="チェック" href="/check" {...a11yProps(0)} />
          <LinkTab label="集計" href="/summery" {...a11yProps(1)} />
          <LinkTab label="提出（印刷）" href="/print" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OneVisionTabCheck
          tenpoCode={tenpoCode}
          oneVisionId={oneVisionId}
          lastOneVisionId={lastOneVisionId}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OneVisionTabGraphs
          tenpoCode={tenpoCode}
          oneVisionId={oneVisionId}
          lastOneVisionId={lastOneVisionId}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OneVisionTabPrint
          tenpoCode={tenpoCode}
          oneVisionId={oneVisionId}
          lastOneVisionId={lastOneVisionId}
          setUpdateFlg={setUpdateFlg}
        />
      </TabPanel>

      <OneVisionDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </div>
  );
};

export default OneVisionTab;
