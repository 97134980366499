import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LayersIcon from "@material-ui/icons/Layers";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip, Modal, Typography } from "@material-ui/core";
// import { toggle } from "../../Maps/MobakuMap";
import {
  setHybridGoogleMap,
  setRoadGoogleMap,
  setTerrainGoogleMap,
  setTrafficLayerGoogleMap,
  setTransitLayerGoogleMap,
} from "./SelectMobakuGoogleMaps";
import { selectExclusiveMobakuFlg } from "../../../features/marketAreaStatusSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
    paper: {
      position: "absolute",
      top: "5%",
      left: "32.5%",
      width: "32%",
      height: "77%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 2, 1),
      "&:focus": {
        outline: "none",
      },
      borderRadius: "5px",
    },
    closeButton: {
      margin: "0 0 0 auto",
      "&:hover": {
        backgroundColor: "lightgray",
      },
    },
    header: {
      borderBottom: "1px solid #dee2e6",
      padding: "8px",
      display: "flex",
    },
    body: {
      padding: "16px",
    },
    subtitle: {
      margin: "8px 0px",
    },
    formGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    radioButtons: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 0,
    },
    layer: {
      cursol: "pointer",
    },
    label: {
      cursol: "pointer",
    },
    footer: {
      padding: "16px",
      borderTop: "1px solid #dee2e6",
      textAlign: "right",
    },
    footerButton: {
      width: "80px",
      height: "40px",
      // margin: "16px",
      backgroundColor: "#00cc5b",
      border: "none",
      borderRadius: "5px",
      color: "#fff",
    },
  })
);

const SelectMobakuMapLayersButton: React.FC = (props: any) => {
  const classes = useStyles({});
  const exclusiveMobakuFlg = useSelector(selectExclusiveMobakuFlg);
  const [open, setOpen] = React.useState(false);
  const [val2, setVal2] = React.useState("option11");
  const [val3, setVal3] = React.useState("option21");
  let tempMobakuLayer = "option1";

  useEffect(() => {}, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange2 = (e: any) => setVal2(e.target.value);
  const handleChange3 = (e: any) => setVal3(e.target.value);

  const handleSetLayers = () => {
    // 背景地図の表示制御
    if (val2 === "option11") {
      setRoadGoogleMap();
    } else if (val2 === "option12") {
      setHybridGoogleMap();
    } else if (val2 === "option13") {
      setTerrainGoogleMap();
    }
    // 交通レイヤの表示制御
    if (val3 === "option21") {
      setTrafficLayerGoogleMap(false);
      setTransitLayerGoogleMap(false);
    } else if (val3 === "option22") {
      setTrafficLayerGoogleMap(true);
      setTransitLayerGoogleMap(false);
    } else if (val3 === "option23") {
      setTrafficLayerGoogleMap(false);
      setTransitLayerGoogleMap(true);
    }
  };
  return (
    <>
      <Tooltip
        title={
          <span style={{ fontSize: "16px" }}>{"マップレイヤを選択する"}</span>
        }
        placement="left"
        disableTouchListener
      >
        <IconButton
          className={classes.button + " map-item-shadow"}
          style={exclusiveMobakuFlg ? { pointerEvents: "none" } : {}}
          onClick={() => handleOpen()}
        >
          <LayersIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // style={{ height: "95vh" }}
      >
        <div className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h5">地図を選択する</Typography>
            <IconButton
              className={classes.closeButton}
              onClick={() => setOpen(false)}
              size={"small"}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.body}>
            <Typography className={classes.subtitle} variant="h6">
              背景地図
            </Typography>
            {/* MUIのラジオボタンコンポーネントで画像を使った制御が難しかったため素のHTMLで実装 */}
            <div className={classes.formGroup}>
              <div className={classes.radioButtons}>
                <input
                  className={classes.layer}
                  type="radio"
                  id="radioBgIcon1"
                  value="option11"
                  name="radio-bg-icon"
                  checked={val2 === "option11"}
                  onChange={handleChange2}
                  style={{ marginBottom: "10px" }}
                />
                <label className={classes.label} htmlFor="radioBgIcon1">
                  <img
                    src="./image/icons/layers/map1.svg"
                    width="60"
                    height="60"
                  />
                </label>
                <label className={classes.label} htmlFor="radioBgIcon1">
                  標準地図
                </label>
              </div>
              <div className={classes.radioButtons}>
                <input
                  className={classes.layer}
                  type="radio"
                  id="radioBgIcon2"
                  value="option12"
                  name="radio-bg-icon"
                  checked={val2 === "option12"}
                  onChange={handleChange2}
                  style={{ marginBottom: "10px" }}
                />
                <label className={classes.label} htmlFor="radioBgIcon2">
                  <img
                    src="./image/icons/layers/helicopter.svg"
                    width="60"
                    height="60"
                  />
                </label>
                <label className={classes.label} htmlFor="radioBgIcon2">
                  航空写真
                </label>
              </div>
              <div className={classes.radioButtons}>
                <input
                  className={classes.layer}
                  type="radio"
                  id="radioBgIcon3"
                  value="option13"
                  name="radio-bg-icon"
                  checked={val2 === "option13"}
                  onChange={handleChange2}
                  style={{ marginBottom: "10px" }}
                />
                <label className={classes.label} htmlFor="radioBgIcon3">
                  <img
                    src="./image/icons/layers/rivers.svg"
                    width="60"
                    height="60"
                  />
                </label>
                <label className={classes.label} htmlFor="radioBgIcon3">
                  地形地図
                </label>
              </div>
            </div>
            <Typography className={classes.subtitle} variant="h6">
              交通レイヤ
            </Typography>
            <div className={classes.formGroup}>
              <div className={classes.radioButtons}>
                <input
                  className={classes.layer}
                  type="radio"
                  id="radioTrafficIcon1"
                  value="option21"
                  name="radio-traffic-icon"
                  checked={val3 === "option21"}
                  onChange={handleChange3}
                  style={{ marginBottom: "10px" }}
                />
                <label className={classes.label} htmlFor="radioTrafficIcon1">
                  <img
                    src="./image/icons/layers/map2.svg"
                    width="60"
                    height="60"
                  />
                </label>
                <label className={classes.label} htmlFor="radioTrafficIcon1">
                  なし
                </label>
              </div>
              <div className={classes.radioButtons}>
                <input
                  className={classes.layer}
                  type="radio"
                  id="radioTrafficIcon2"
                  value="option22"
                  name="radio-Traffic-icon"
                  checked={val3 === "option22"}
                  onChange={handleChange3}
                  style={{ marginBottom: "10px" }}
                />
                <label className={classes.label} htmlFor="radioTrafficIcon2">
                  <img
                    src="./image/icons/layers/traffic.svg"
                    width="60"
                    height="60"
                  />
                </label>
                <label className={classes.label} htmlFor="radioTrafficIcon2">
                  交通状況
                </label>
              </div>
              <div className={classes.radioButtons}>
                <input
                  className={classes.layer}
                  type="radio"
                  id="radioTrafficIcon3"
                  value="option23"
                  name="radio-traffic-icon"
                  checked={val3 === "option23"}
                  onChange={handleChange3}
                  style={{ marginBottom: "10px" }}
                />
                <label className={classes.label} htmlFor="radioTrafficIcon3">
                  <img
                    src="./image/icons/layers/train.svg"
                    width="60"
                    height="60"
                  />
                </label>
                <label className={classes.label} htmlFor="radioTrafficIcon3">
                  鉄道
                </label>
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <button
              type="button"
              className={classes.footerButton}
              data-dismiss="modal"
              id="SelectMapChanger"
              onClick={() => {
                handleSetLayers();
                handleClose();
              }}
            >
              選択する
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectMobakuMapLayersButton;
