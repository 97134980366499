import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  ComposedChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      padding: "5px",
      border: "1px solid lightgray",
      backgroundColor: "rgba(255,255,255,0.9)",
      pointerEvents: "all",
      borderRadius: "5%",
    },
    customTooltipContent: {
      listStyle: "none",
    },
  })
);

const GraphContentTenpoMobaku: React.FC<{
  data: any;
  graphType: string;
  initGraph: boolean;
  baseAxis: number;
  firstDate: string;
  today: string;
}> = ({ data, graphType, initGraph, baseAxis, firstDate, today }) => {
  const classes = useStyles({});
  const [activeTooltip, setActiveTooltip] = useState(true);
  let maxLineNum = 0;
  let maxBarNum = 0;
  let barTicksArray: any[] = [];
  let lineTicksArray: any[] = [];

  const calcMaxLineNum = () => {
    let maxNum = 0;
    if (data) {
      if (graphType === "tenpo") {
        data.forEach((value: any) => {
          let tempMax = Math.max(
            value.firstTenpoRealNumForGraph,
            value.secondTenpoRealNumForGraph
          );
          maxNum = tempMax ? Math.max(maxNum, tempMax) : maxNum;
        });
      } else if (graphType === "mobaku") {
        data.forEach((value: any) => {
          let tempMax = Math.max(
            value.firstMobakuRealNumForGraph,
            value.secondMobakuRealNumForGraph
          );
          maxNum = tempMax ? Math.max(maxNum, tempMax) : maxNum;
        });
      }
    }
    return maxNum;
  };

  const calcMaxBarNum = () => {
    let maxNum = 0;
    data.forEach((value: any) => {
      if (!isNaN(value.diffForGraph)) {
        maxNum = Math.max(maxNum, Math.abs(value.diffForGraph));
      }
    });
    return maxNum;
  };

  const setTicksValues = () => {
    maxLineNum = calcMaxLineNum();
    maxBarNum = calcMaxBarNum();

    barTicksArray = [
      -maxBarNum,
      Math.round(-maxBarNum * 0.75),
      Math.round(-maxBarNum * 0.5),
      Math.round(-maxBarNum * 0.25),
      0,
      Math.round(maxBarNum * 0.25),
      Math.round(maxBarNum * 0.5),
      Math.round(maxBarNum * 0.75),
      maxBarNum,
    ];
    lineTicksArray = [
      -maxLineNum,
      Math.round(-maxLineNum * 0.75),
      Math.round(-maxLineNum * 0.5),
      Math.round(-maxLineNum * 0.25),
      0,
      Math.round(maxLineNum * 0.25),
      Math.round(maxLineNum * 0.5),
      Math.round(maxLineNum * 0.75),
      maxLineNum,
    ];
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    let unit = "";
    if (baseAxis === 0) {
      unit = "時";
    } else if (baseAxis === 3) {
      unit = "月";
    }
    if (active && payload && payload.length && activeTooltip) {
      return (
        <div className={classes.customTooltip}>
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={() => setActiveTooltip(false)} size={"small"}>
              <HighlightOffIcon />
            </IconButton>
          </div>
          <li className={classes.customTooltipContent}>{`${label} ${unit}`}</li>
          {payload.map((payload: any, index: any) => {
            let newName = "";
            let color;
            switch (payload.name) {
              case "firstMobakuRealNumForGraph":
                newName = "分析対象モバ空実数";
                color = "#0070C0";
                break;
              case "secondMobakuRealNumForGraph":
                newName = "比較対象モバ空実数";
                color = "#00B050";
                break;
              case "firstTenpoRealNumForGraph":
                newName = "分析対象客数実数";
                color = "#0070C0";
                break;
              case "secondTenpoRealNumForGraph":
                newName = "比較対象客数実数";
                color = "#00B050";
                break;
              case "diffForGraph":
                newName = "差";
                color = "gray";
                break;
            }
            return (
              <li
                className={classes.customTooltipContent}
                style={{ color: color }}
                key={index.toString()}
              >
                {newName +
                  "：" +
                  new Intl.NumberFormat("en").format(payload.value)}
              </li>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const makeFutureGraph = () => {
    const selectedDate = convertDate(firstDate);
    const twoWeekAgoDate = new Date(
      selectedDate.setDate(selectedDate.getDate() - 14)
    );
    const todayDate = convertDate(today);
    let dateDiff =
      (todayDate.getTime() - convertDate(firstDate).getTime()) / 86400000;
    if (todayDate > twoWeekAgoDate) {
      for (let d = 15 + dateDiff; d < 29; d++) {
        data[d].firstTenpoRealNumForGraph = "-";
        data[d].secondTenpoRealNumForGraph = "-";
        data[d].firstMobakuRealNumForGraph = "-";
        data[d].secondMobakuRealNumForGraph = "-";
        data[d].diffForGraph = "-";
      }
    }
  };

  const convertDate = (date: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const dateStr = `${year}/${month}/${day}`;
    return new Date(dateStr);
  };

  const handleActiveTooltip = () => {
    setActiveTooltip(true);
  };

  if (data) {
    baseAxis === 1 && data.length === 29 && makeFutureGraph();
    setTicksValues();
  }

  return (
    <>
      {initGraph ? (
        <ResponsiveContainer maxHeight={290}>
          <ComposedChart
            data={data}
            margin={{ top: 20, right: 30, left: 55, bottom: -15 }}
          >
            <XAxis
              dataKey="date"
              tickFormatter={() => {
                return "";
              }}
            />
            {/* 客数×客数、モバ空×モバ空の時の左Y軸 */}
            <YAxis
              yAxisId={1}
              domain={[-maxLineNum, maxLineNum]}
              tickCount={9}
              ticks={lineTicksArray}
              tick={{ fontSize: 14 }}
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
              label={{
                value:
                  graphType === "tenpo"
                    ? "客数当年実数"
                    : "モバイル空間統当年実数",
                angle: -90,
                dx: -65,
              }}
            />
            {/* 客数×客数、モバ空×モバ空の時の右Y軸  */}
            <YAxis
              yAxisId={2}
              orientation="right"
              domain={[-maxBarNum, maxBarNum]}
              tickCount={9}
              ticks={barTicksArray}
              tick={{ fontSize: 14 }}
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
              label={{
                value: "差（分析対象 - 比較対象）",
                angle: -90,
                dx: 40,
              }}
            />
            <Tooltip trigger="click" content={<CustomTooltip />} />
            <ReferenceLine yAxisId={1} y={0} stroke="black" />
            <CartesianGrid strokeDasharray="3 3" />
            {/* 客数×客数、モバ空×モバ空の時の右Y軸  */}
            <Bar
              yAxisId={2}
              dataKey="diffForGraph"
              barSize={6}
              fill="#C0C0C0"
              animationEasing="ease-out"
              animationDuration={300}
              onClick={() => {
                handleActiveTooltip();
              }}
            />
            {/* 客数×客数、モバ空×モバ空の時の左Y軸 */}
            <Line
              yAxisId={1}
              dataKey="firstMobakuRealNumForGraph"
              stroke="#0070C0"
              strokeWidth={3}
              type="monotone"
              animationEasing="ease-out"
              animationDuration={300}
              hide={graphType === "tenpo"}
              onClick={() => {
                handleActiveTooltip();
              }}
              activeDot={{ onClick: handleActiveTooltip }}
            />
            <Line
              yAxisId={1}
              dataKey="secondMobakuRealNumForGraph"
              stroke="#00B050"
              strokeWidth={3}
              type="monotone"
              animationEasing="ease-out"
              animationDuration={300}
              hide={graphType === "tenpo"}
              onClick={() => {
                handleActiveTooltip();
              }}
              activeDot={{ onClick: handleActiveTooltip }}
            />
            <Line
              yAxisId={1}
              dataKey="firstTenpoRealNumForGraph"
              stroke="#0070C0"
              strokeWidth={3}
              type="monotone"
              animationEasing="ease-out"
              animationDuration={300}
              hide={graphType === "mobaku"}
              onClick={() => {
                handleActiveTooltip();
              }}
              activeDot={{ onClick: handleActiveTooltip }}
            />
            <Line
              yAxisId={1}
              dataKey="secondTenpoRealNumForGraph"
              stroke="#00B050"
              strokeWidth={3}
              type="monotone"
              animationEasing="ease-out"
              animationDuration={300}
              hide={graphType === "mobaku"}
              onClick={() => {
                handleActiveTooltip();
              }}
              activeDot={{ onClick: handleActiveTooltip }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer maxHeight={310}>
          <ComposedChart margin={{ top: 20, right: 30, left: 45, bottom: -15 }}>
            <XAxis tick={false} />
            <YAxis
              label={{
                value: "",
                dx: -50,
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default GraphContentTenpoMobaku;
