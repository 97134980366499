/**
 * 共通処理です
 * @class util.ts
 * @constructor
 */
import { useDispatch } from "react-redux";
import { setIsDeviceType } from "../features/userSlice";
import { postRequest } from "./axiosAction";

//=============================================================================
//  制御、その他
//=============================================================================
/**
 * 別タブ遷移
 * @param url : url文字列
 */
export const openWebSite = (url: string) => {
  window.open(url, "_blank", "noreferrer noopener");
};

// デバイス・OSの判定を行う
export const useDeviceType = () => {
  const ua = navigator.userAgent;
  const loerUa = navigator.userAgent.toLowerCase();
  const dispatch = useDispatch();
  if (ua.indexOf("iPhone") > -1) {
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: true,
      })
    );
  } else if (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1) {
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: false,
        iphone: false,
      })
    );
  } else if (ua.indexOf("iPad") > -1) {
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: false,
      })
    );
  } else if (/ipad|macintosh/.test(loerUa) && "ontouchend" in document) {
    // OS判定がMacOSX向けになっているもの
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: false,
      })
    );
  } else if (ua.indexOf("Android") > -1) {
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: false,
        iphone: false,
      })
    );
  } else {
    dispatch(
      setIsDeviceType({
        deviceType: false,
        ios: false,
        iphone: false,
      })
    );
  }
};

//=============================================================================
//  時間・日付
//=============================================================================
/**
 * UnixTimeの数値を返す
 * @returns {number} UNIXTIME  例:1639381897
 */
export const getUnixTime = (): number => {
  // Dateオブジェクトを作成し、UNIXタイムスタンプを取得する (ミリ秒単位)
  const unixTimestamp = new Date().getTime();
  // UNIXタイムスタンプを取得する (秒単位 - PHPのtime()と同じ)
  return Math.floor(unixTimestamp / 1000);
};

export const getNowYMD = (): string => {
  var dt = new Date();
  var y = dt.getFullYear();
  var m = ("00" + (dt.getMonth() + 1)).slice(-2);
  var d = ("00" + dt.getDate()).slice(-2);
  var result = y + m + d;
  return result;
};

// OPViewのAPI種別呼び出し
export const apiRequest = async (
  param: any,
  date: string[],
  yearOnYear: boolean,
  baseAxis: number
) => {
  const common_api_url: string = process.env.REACT_APP_COMMON_API!;
  // const common_api_url =
  //   "http://localhost:19001/outside/call-common-process-api";
  let res: any = {};
  //時間軸・当年モード
  if (baseAxis === 0 && !yearOnYear) {
    param.target_day = date;
  }
  //時間軸・前年比モード
  else if (baseAxis === 0 && yearOnYear) {
    param.target_day_plus_last_year = date;
  }
  //日軸・当年モード
  else if (baseAxis === 1 && !yearOnYear) {
    param.target_axis_daily = date;
  }
  //日軸・前年比モード
  else if (baseAxis === 1 && yearOnYear) {
    param.target_axis_daily_plus_last_year = date;
  }
  //週軸・当年モード
  else if (baseAxis === 2 && !yearOnYear) {
    param.target_axis_weekly = date;
  }
  //週軸・前年比モード
  else if (baseAxis === 2 && yearOnYear) {
    param.target_axis_weekly_plus_last_year = date;
  }
  //月軸・当年モード
  else if (baseAxis === 3 && !yearOnYear) {
    param.target_axis_monthly = date;
  }
  //月軸・前年比モード
  else if (baseAxis === 3 && yearOnYear) {
    param.target_axis_monthly_plus_last_year = date;
  }
  res = await postRequest(common_api_url, param);
  return res;
};
