// @ts-nocheck
import React from "react";
import luxonUtils from "@date-io/luxon";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { createMuiTheme } from "@material-ui/core";

const color = "#4dc0b2";
export const materialTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "0px solid",
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: color,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: color,
        "&:hover": {
          backgroundColor: color,
        },
      },
      dayDisabled: {
        color: color,
      },
      current: {
        color: color,
      },
    },
    MuiPickersYear: {
      root: {
        "&:active": {
          color: color,
        },
      },
      yearSelected: {
        color: color,
        "&:active": {
          color: color,
        },
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: color,
      },
    },
    MuiButton: {
      textPrimary: {
        color: color,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: color,
        "&:active": {
          backgroundColor: color,
        },
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: color,
        "&:active": {
          backgroundColor: color,
        },
      },
      noPoint: {
        backgroundColor: color,
        "&:active": {
          backgroundColor: color,
        },
      },
      thumb: {
        border: "14px solid " + color,
      },
    },

    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: color,
        "&:active": {
          backgroundColor: color,
        },
        "&:before": {
          backgroundColor: color,
        },
        "&:after": {
          backgroundColor: color,
        },
      },
    },
  },
});

class ExtendsDateTime extends luxonUtils {
  //時刻ピッカーツールバー時間表示機能
  getHourText(datetime: DateTime, ampm: boolean) {
    return this.format(datetime, "hh");
  }
  //時刻ピッカーツールバー分表示機能
  getMinuteText(datetime: DateTime) {
    return this.format(datetime, "mm");
  }
  //時刻ピッカーツールバー午前・午後表示機能
  getMeridiemText(ampm: "am" | "pm") {
    return ampm === "am" ? "午前" : "午後";
  }
}

const TimeDatePicker = (props: any) => {
  const { timeFunc, timeValue, width, openTo, format, views, clearable, ampm } =
    props;
  const handleDateForcus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.target.blur();
  };

  return (
    <>
      <MuiPickersUtilsProvider libInstance={DateTime} utils={ExtendsDateTime}>
        <MuiThemeProvider theme={materialTheme}>
          <KeyboardTimePicker
            variant="dialog"
            style={width ? { width: width } : {}}
            openTo={openTo ? openTo : "hours"}
            views={views ? views : ["hours", "minutes"]}
            cancelLabel="閉じる"
            placeholder="--"
            ampm={ampm ? ampm : true}
            value={
              timeValue
                ? DateTime.fromObject({
                    hour: timeValue,
                    minute: 0,
                  })
                : null
            }
            initialFocusedDate={DateTime.fromObject({
              hour: 0,
              minute: 0,
            })}
            format={format ? format : "HH:mm"}
            onChange={timeFunc}
            onFocus={handleDateForcus}
            clearable={clearable ? clearable : false}
            clearLabel="削除"
            okLabel="登録"
          />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
};

TimeDatePicker.propTypes = {
  timeFunc: PropTypes.func.isRequired,
  timeValue: PropTypes.any.isRequired,
  width: PropTypes.any.isRequired | null,
  format: PropTypes.any.isRequired | null,
  views: PropTypes.any.isRequired | null,
  openTo: PropTypes.any.isRequired | null,
  clearable: PropTypes.any.isRequired | null,
  ampm: PropTypes.any.isRequired | null,
};

export default TimeDatePicker;
