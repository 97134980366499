import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // iPad縦
      "@media (min-width: 1000px)": {
        width: "89.5%",
      },
      // iPad横
      "@media (min-width: 1150px)": {
        width: "91.5%",
      },
      // GWPC
      "@media (min-width: 1200px)": {
        width: "92.5%",
      },
      marginBottom: "10px",
    },
    firstRowCell: {
      width: "1%",
      padding: "6px 0px",
    },
    secondRowCell: {
      // iPad縦
      "@media (min-width: 1000px)": {
        width: "13.0%",
      },
      // iPad横
      "@media (min-width: 1150px)": {
        width: "9.5%",
      },
      // GWPC
      "@media (min-width: 1200px)": {
        width: "7.5%",
      },
      padding: "6px 3px",
      fontSize: "11px",
      fontWeight: "bold",
      wordBreak: "keep-all",
    },
    infoTipCell: {
      padding: "0px 3px",
    },
    cell: {
      padding: "2px",
      fontSize: "9px",
    },
    col: {
      padding: "1px",
      backgroundColor: "#ececec",
      whiteSpace: "pre",
    },
    firstTargetColor: {
      color: "#0000FF",
      fontSize: "20px",
    },
    secondTargetColor: {
      color: "#00B050",
      fontSize: "20px",
    },
  })
);

const diffColor = (value: number) => {
  if (value > 0) {
    return "#0000FF";
  } else if (value === 0) {
    return "";
  } else if (value < 0) {
    return "#FF0000";
  }
};

const TableContentZennenhi: React.FC<{
  data: any;
  graphType: string;
  baseAxis: number;
  tableOpen: string;
  firstDate: string;
  holiday: string[];
  today: string;
}> = ({ data, graphType, baseAxis, tableOpen, firstDate, holiday, today }) => {
  const classes = useStyles();
  const dateList = React.useRef<string[]>([]);
  const holidayList = React.useRef<string[]>([]);
  let countItem = 1;
  if (data !== undefined) {
    countItem = data.length;
  }
  const adjustWidth = 84 / countItem + "%";

  const makeDateList = () => {
    const tempDateList = [];
    const tempHolidayList = [];
    const standardDate = convertDate(firstDate);
    const start = new Date(standardDate.setDate(standardDate.getDate() - 14));
    const end = new Date(standardDate.setDate(standardDate.getDate() + 28));
    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      let formatedDate = `${d.getFullYear()}/${
        d.getMonth() + 1
      }/${d.getDate()}`;
      const month = ("0" + (d.getMonth() + 1)).slice(-2);
      const date = ("0" + d.getDate()).slice(-2);
      let formatedHoliday = `${d.getFullYear()}${month}${date}`;
      tempDateList.push(formatedDate);
      tempHolidayList.push(formatedHoliday);
    }
    dateList.current = tempDateList;
    holidayList.current = tempHolidayList;
  };

  const checkHoliday = (index: number) => {
    if (baseAxis !== 1) return;
    const date = new Date(dateList.current[index]);
    if (date.getDay() === 0 || holiday.includes(holidayList.current[index])) {
      if (holiday.includes(holidayList.current[index])) {
      }
      return "red";
    } else if (date.getDay() === 6) {
      return "blue";
    }
  };

  const getWeekDay = (index: number) => {
    const week = ["日", "月", "火", "水", "木", "金", "土"];
    const date = new Date(dateList.current[index]);
    const weekDayNum = date.getDay();
    return week[weekDayNum];
  };

  const makeFutureTable = () => {
    const selectedDateStr = convertDate(firstDate);
    const twoWeekAgoDate = new Date(
      selectedDateStr.setDate(selectedDateStr.getDate() - 14)
    );
    const todayDate = convertDate(today);
    let dateDiff =
      (todayDate.getTime() - convertDate(firstDate).getTime()) / 86400000;
    if (todayDate > twoWeekAgoDate) {
      for (let d = 15 + dateDiff; d < 29; d++) {
        data[d].firstTenpoRealNumForTable = "-";
        data[d].secondTenpoRealNumForTable = "-";
        data[d].firstTenpoRatioLastYear = "-";
        data[d].secondTenpoRatioLastYear = "-";
        data[d].firstMobakuRealNumForTable = "-";
        data[d].secondMobakuRealNumForTable = "-";
        data[d].firstMobakuRatioLastYear = "-";
        data[d].secondMobakuRatioLastYear = "-";
        data[d].firstDiffLastYear = "-";
        data[d].secondDiffLastYear = "-";
        data[d].ratioDiff = "-";
      }
    }
  };

  const convertDate = (date: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const dateStr = `${year}/${month}/${day}`;
    return new Date(dateStr);
  };

  baseAxis === 1 && makeDateList();
  if (data) {
    baseAxis === 1 && data.length === 29 && makeFutureTable();
  }

  return (
    <>
      {data && (
        <Table className={classes.root} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.firstRowCell}></TableCell>
              <TableCell className={classes.infoTipCell}></TableCell>
              {data.map((data: any, index: number) => (
                <TableCell
                  className={classes.col}
                  align="center"
                  key={index.toString()}
                  style={
                    baseAxis === 1 && data.date.length > 2
                      ? { color: checkHoliday(index), lineHeight: "1" }
                      : { lineHeight: "2" }
                  }
                >
                  {data.date}
                  <br />
                  {baseAxis === 1 &&
                    data.date.length > 2 &&
                    `(${getWeekDay(index)})`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {graphType === "tenpo" && (
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.firstTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  客数前年差
                  <br />
                  （人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{
                      color: data.firstDiffLastYear
                        ? diffColor(
                            Number(data.firstDiffLastYear.replace(/,/, ""))
                          )
                        : "-",
                      width: adjustWidth,
                    }}
                  >
                    {data.firstDiffLastYear}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "tenpo" && (
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.secondTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  客数前年差
                  <br />
                  （人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{
                      color: data.secondDiffLastYear
                        ? diffColor(
                            Number(data.secondDiffLastYear.replace(/,/, ""))
                          )
                        : "-",
                      width: adjustWidth,
                    }}
                  >
                    {data.secondDiffLastYear}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "mobaku" && (
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.firstTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  モバ空前年差
                  <br />
                  （千人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{
                      color: data.firstDiffLastYear
                        ? diffColor(
                            Number(data.firstDiffLastYear.replace(/,/, ""))
                          )
                        : "",
                      width: adjustWidth,
                    }}
                  >
                    {isFinite(data.firstDiffLastYear)
                      ? data.firstDiffLastYear
                      : "-"}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "mobaku" && (
              <TableRow>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.secondTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  モバ空前年差
                  <br />
                  （千人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{
                      color: data.secondDiffLastYear
                        ? diffColor(
                            Number(data.secondDiffLastYear.replace(/,/, ""))
                          )
                        : "",
                      width: adjustWidth,
                    }}
                  >
                    {isFinite(data.secondDiffLastYear)
                      ? data.secondDiffLastYear
                      : "-"}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "mobaku" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.firstTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  モバ空前年比
                  <br />
                  （％）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {isFinite(data.firstMobakuRatioLastYear)
                      ? data.firstMobakuRatioLastYear
                      : "-"}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "mobaku" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.secondTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  モバ空前年比
                  <br />
                  （％）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {isFinite(data.secondMobakuRatioLastYear)
                      ? data.secondMobakuRatioLastYear
                      : "-"}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "tenpo" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.firstTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  客数前年比
                  <br />
                  （％）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {isFinite(data.firstTenpoRatioLastYear)
                      ? data.firstTenpoRatioLastYear
                      : "-"}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "tenpo" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.secondTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  客数前年比
                  <br />
                  （％）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {isFinite(data.secondTenpoRatioLastYear)
                      ? data.secondTenpoRatioLastYear
                      : "-"}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "tenpo" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.firstTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  客数実数
                  <br />
                  （人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {data.firstTenpoRealNumForTable}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "tenpo" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.secondTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  客数実数
                  <br />
                  （人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {data.secondTenpoRealNumForTable}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "mobaku" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.firstTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  モバ空実数
                  <br />
                  （千人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {data.firstMobakuRealNumForTable}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "mobaku" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.secondTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  モバ空実数
                  <br />
                  （千人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {data.secondMobakuRealNumForTable}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "tenpo" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.firstTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  客数前年実数
                  <br />
                  （人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {data.firstTenpoRealNumLastYearForTable}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "tenpo" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.secondTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  客数前年実数
                  <br />
                  （人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {data.secondTenpoRealNumLastYearForTable}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "mobaku" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.firstTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  モバ空前年実数
                  <br />
                  （千人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {data.firstMobakuRealNumLastYearForTable}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {graphType === "mobaku" && (
              <TableRow style={{ display: tableOpen }}>
                <TableCell className={classes.firstRowCell}>
                  <span className={classes.secondTargetColor}>●</span>
                </TableCell>
                <TableCell className={classes.secondRowCell} align="left">
                  モバ空前年実数
                  <br />
                  （千人）
                </TableCell>
                {data.map((data: any, index: string) => (
                  <TableCell
                    className={classes.cell}
                    align="center"
                    key={index.toString()}
                    style={{ width: adjustWidth }}
                  >
                    {data.secondMobakuRealNumLastYearForTable}
                  </TableCell>
                ))}
              </TableRow>
            )}
            <TableRow style={{ display: tableOpen }}>
              <TableCell className={classes.firstRowCell}></TableCell>
              <TableCell className={classes.secondRowCell} align="left">
                前年比差（％）
              </TableCell>
              {data.map((data: any, index: string) => (
                <TableCell
                  className={classes.cell}
                  align="center"
                  key={index.toString()}
                  style={{
                    color:
                      data.secondDiffLastYear != null
                        ? diffColor(Number(data.ratioDiff))
                        : "",
                    width: adjustWidth,
                  }}
                >
                  {isFinite(data.ratioDiff) ? data.ratioDiff : "-"}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default TableContentZennenhi;
