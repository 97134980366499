// アメダスの天気コードと天気アイコンのファイル名の紐づけを行う

const sunToRain = ["10130", "10132", "10134", "10136"];
const sunTempRain = [
  "10230",
  "10232",
  "10234",
  "10236",
  "10330",
  "10332",
  "10334",
  "10336",
  "10430",
  "10432",
  "10434",
  "10436",
  "10530",
  "10532",
  "10534",
  "10536",
];
const sunTempCloud = ["10320", "10520"];
const sunTempSnow = ["10240", "10340", "10440", "10540"];
const cloudToRain = ["20130", "20132", "20134", "20136"];
const cloudTempRain = [
  "20230",
  "20232",
  "20234",
  "20236",
  "20330",
  "20332",
  "20334",
  "20336",
  "20430",
  "20432",
  "20434",
  "20436",
  "20530",
  "20532",
  "20534",
  "20536",
];
const cloudTempSun = ["20310", "20510"];
const cloudTempSnow = ["20240", "20340", "20440", "20540"];
const rain = ["30000", "32000", "34000", "36000"];
const rainToSun = ["30110", "32110", "34110", "36110"];
const rainToCloud = ["30120", "32120", "34120", "36120"];
const rainToSnow = ["30140", "32140", "34140", "36140"];
const rainTempSnow = [
  "30240",
  "30340",
  "30540",
  "32240",
  "32340",
  "32540",
  "34240",
  "34340",
  "34540",
  "36240",
  "36340",
  "36540",
];
const rainTempSun = ["30310", "32310", "34310", "36310"];
const rainTempCloud = ["30320", "32320", "34320", "36320"];
const snowToRain = ["40130", "40132", "40134", "40136"];
const snowTempRain = [
  "40230",
  "40232",
  "40234",
  "40236",
  "40330",
  "40332",
  "40334",
  "40336",
  "40530",
  "40532",
  "40534",
  "40536",
];

// 天気コードと対応する画像ファイル名（.pngの前）を返す
export const getWeatherIcon = (weatherCode: string) => {
  if (weatherCode === "10000") {
    return "100";
  } else if (weatherCode === "10120") {
    return "110";
  } else if (weatherCode === "10140") {
    return "115";
  } else if (weatherCode === "20000") {
    return "200";
  } else if (weatherCode === "20110") {
    return "210";
  } else if (weatherCode === "20140") {
    return "215";
  } else if (weatherCode === "40000") {
    return "400";
  } else if (weatherCode === "40110") {
    return "411";
  } else if (weatherCode === "40120") {
    return "413";
  } else if (weatherCode === "40310") {
    return "401";
  } else if (weatherCode === "40320") {
    return "402";
  } else if (sunToRain.includes(weatherCode)) {
    return "112";
  } else if (sunTempRain.includes(weatherCode)) {
    return "102";
  } else if (sunTempCloud.includes(weatherCode)) {
    return "101";
  } else if (sunTempSnow.includes(weatherCode)) {
    return "104";
  } else if (cloudToRain.includes(weatherCode)) {
    return "212";
  } else if (cloudTempRain.includes(weatherCode)) {
    return "202";
  } else if (cloudTempSun.includes(weatherCode)) {
    return "201";
  } else if (cloudTempSnow.includes(weatherCode)) {
    return "204";
  } else if (rain.includes(weatherCode)) {
    return "300";
  } else if (rainToSun.includes(weatherCode)) {
    return "311";
  } else if (rainToCloud.includes(weatherCode)) {
    return "313";
  } else if (rainToSnow.includes(weatherCode)) {
    return "314";
  } else if (rainTempSnow.includes(weatherCode)) {
    return "303";
  } else if (rainTempSun.includes(weatherCode)) {
    return "301";
  } else if (rainTempCloud.includes(weatherCode)) {
    return "302";
  } else if (snowToRain.includes(weatherCode)) {
    return "414";
  } else if (snowTempRain.includes(weatherCode)) {
    return "403";
  } else {
    return "000";
  }
};
