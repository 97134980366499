import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectOfcName,
} from "../../../../components/organisms/search/TenpoCheckSearchSlice";

import { selectUser } from "../../../../features/userSlice";

import {
  getAuthTenpoCheck, onlyAll
} from "../specification/UsageAuthority/AuthTenpoCheck";

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { getAllEmployee } from "../../../../data/employeeOfc"

const SearchOFC: React.FC<{
  useStyles: any;
  disabled: boolean;
  firstView: boolean;
  toggleStatus: boolean;
  searchMode: number;
  onClickFunc: any;
  searchDocode: string;
}> = ({
  useStyles,
  disabled,
  firstView,
  toggleStatus,
  searchMode,
  onClickFunc,
  searchDocode,
}) => {
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = useSelector(selectUser);
  const stateOfcName = useSelector(selectOfcName);
  
  const MAX_SEARCH_LIST = 150;
  const ofcMaster = getAllEmployee();
  const [ofcList, setOfcList] = React.useState([""]);

  // 検索文字列が更新された際に動く
  useEffect(() => {
    setOfcList(createOfcButton());
  }, [searchWord, searchDocode]);

  function selectAuthOfc(docode: string) {
    let doList: string[] = user.doCode;
    // ボタンのリストを更新
    if (user.jobCategory === "admin-group") {
      return false;
    }
    else if (getAuthTenpoCheck(user.jobCategory).search < onlyAll) {
      return doList.indexOf(docode) === -1;
    }
    return true
  }
  
  function searchOfc(argDoCode: string, argSearchDoCode: string, argOfcName: string){
    if (argSearchDoCode) {
      return argDoCode.match(argSearchDoCode) && argOfcName.match(searchWord);
    }
    return argOfcName.match(searchWord);
  }

  function createOfcButton() {
    let ofcList: any[] = [""];
    let docode: any = "";
    let employeeName: any = "";
    let hitCount: number = 0;
    for (let i = 0; i < ofcMaster.count; i++) {
      if (selectAuthOfc(ofcMaster.employee_data[i].docode)) continue;
      docode = ofcMaster.employee_data[i].docode;
      employeeName = ofcMaster.employee_data[i].employee_name;
      // 検索文字列にマッチしたものだけリストに入れる
      if (searchOfc(docode, searchDocode, employeeName)) {
        ofcList.push(
          <Button
            key={i}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(
                ofcMaster.employee_data[i].docode,
                ofcMaster.employee_data[i].doname,
                ofcMaster.employee_data[i].mail_address,
                ofcMaster.employee_data[i].employee_name
              );
              setOpen(false);
            }}
          >
            <div  className={classes.selectButton}>{ofcMaster.employee_data[i].employee_name}</div>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return ofcList;
  }

  return (
    <>
      <button
        className={classes.searchButton}
        onClick={() => {
          handleOpen();
        }}
        disabled = {disabled}
        style={{ WebkitAppearance: "none" }}
      >
        <div className={classes.selectButton}>{ stateOfcName === "" ? "氏名" : stateOfcName}</div>
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <TextField
            className={classes.searchTextField}
            value={searchWord}
            label="氏名で探す"
            variant="outlined"
            size="medium"
            fullWidth={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchWord(e.target.value);
            }}
          />
          <div className={classes.listPaper}>
            {ofcList}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchOFC;