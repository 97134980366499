import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectUser } from "../../../../features/userSlice";
import { EXISTING_SEJ_STORES_RECORD, getAllExistingSejStores } from "../../../../data/existingSejStores";
import {
  getAuthTenpoCheck, onlyAll
} from "../specification/UsageAuthority/AuthTenpoCheck";
import {
  selectTenpoCode,
  selectTenpoName
} from "../../../../components/organisms/search/TenpoCheckSearchSlice";


import {TextField, Modal, Button, Grid, } from "@material-ui/core";

const SearchTenpo: React.FC<{
  useStyles: any;
  disabled: boolean;
  firstView: boolean;
  toggleStatus: boolean;
  searchMode: number;
  onClickFunc: any;
}> = ({
  useStyles,
  firstView,
  disabled,
  toggleStatus,
  searchMode,
  onClickFunc,
}) => {
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = useSelector(selectUser);
  const stateTenpoCode = useSelector(selectTenpoCode);
  const stateTenpoName = useSelector(selectTenpoName);
  
  const MAX_SEARCH_LIST = 150;
  const store: any = getAllExistingSejStores().store_data;
  const [tenpoList, setTenpoList] = React.useState(userStoreButton());
  // 初期表示処理
  useEffect(() => {
      // OFCなら店舗選択メニューを開く
      if (firstView) {
        setOpen(true);
      }
  }, []);
  // 検索文字列が更新された際に動く
  useEffect(() => {
    // ボタンのリストを更新
    if (getAuthTenpoCheck(user.jobCategory).search < onlyAll) {
      setTenpoList(userStoreButton());
    }
    else {
      setTenpoList(allStoreButton());
    }
  }, [searchWord]);

  function userStoreButton() {
    let tenpoList: any[] = [""];
    let hitCount: number = 0;
    let count = 0;
    let userTenpoCodeList: any[] = user.tenpoCode;
    for (let [key] of store) {
      // もし店名、店番がないもしくはユーザーの店舗でなければ次の店舗
      if (store.get(key).tenpo_name === undefined || store.get(key).tenpo_code === undefined || !userTenpoCodeList.includes(store.get(key).tenpo_code)) continue;
      // 検索文字列にマッチしたものだけリストに入れる
      if (store.get(key).tenpo_code.match(searchWord) || store.get(key).tenpo_name.match(searchWord)) {
        tenpoList.push(
          <Button
            key={count}
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              onClickFunc(
                store.get(key).tenpo_code, store.get(key).tenpo_name
              );
              setOpen(false);
            }}
          >
            <Grid item lg={12} md={12} sm={12} className={classes.selectButton}>{store.get(key).tenpo_code}：{store.get(key).tenpo_name}</Grid>
          </Button>
        );
        hitCount++;
      }
      count++;
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return tenpoList;
  }

  function allStoreButton() {
    let tenpoList: any[] = [""];
    let hitCount: number = 0;
    for (let [key] of store) {
      // 店舗レコードを内部データから取得
      let storeRecord = store.get(key);
      if (key === undefined || searchWord === "" || storeRecord === undefined ||
      storeRecord.tenpo_code === undefined || storeRecord.tenpo_name === undefined) continue;
      // 検索文字列にマッチしたものだけリストに入れる
      if (storeRecord.tenpo_code.match(searchWord) || storeRecord.tenpo_name.match(searchWord)) {
        tenpoList.push(
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              onClickFunc(
                storeRecord.tenpo_code, storeRecord.tenpo_name
              );
              setOpen(false);
            }}
          >
            <Grid item lg={12} md={12} sm={12} className={classes.selectButton}>{storeRecord.tenpo_code}：{storeRecord.tenpo_name}</Grid>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return tenpoList;
  }

  return (
    <>
      <button
        className={classes.searchButton}
        onClick={() => {
          handleOpen();
          onClickFunc("", "");
        }}
        disabled = {disabled}
        style={{ WebkitAppearance: "none" }}
      >
        <Grid item lg={12} md={12} sm={12} className={classes.selectButton}>{ stateTenpoName === "" ? "店番：店舗名" : stateTenpoCode + "：" + stateTenpoName}</Grid>
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid item md={12} lg={12} className={classes.paper}>
          <TextField
            className={classes.searchTextField}
            value={searchWord}
            label="店番・店名で探す"
            variant="outlined"
            size="medium"
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchWord(e.target.value);
            }}
          />
          <Grid item lg={12} md={12} sm={12} className={classes.listPaper}>
            {tenpoList}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default SearchTenpo;