import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import {
  Grid,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Table, TableCell } from "@material-ui/core";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import {
  Tooltip,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { selectImageToken } from "../../../features/userSlice";
import { postRequest } from "../../../apis/axiosAction";
import { DateTime } from "luxon";
import {
  getOneVisionData,
  ONEVISIONBIGRECORD,
  ONEVISIONOTHERDATA,
  ONEVISIONTOTALSCORE,
} from "./OneVisionDataOperation";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { openWebSite } from "../../../apis/util";
import {
  getFixedFlg,
  setFixedFlg,
  setInitTenpoCode,
} from "../../Header/Header";
import { EXISTING_SEJ_STORES_RECORD, getAllExistingSejStores } from "../../../data/existingSejStores";
import { roundScore } from "./OneVisionCalculation";
import { setLastOneVisionId, setOneVisionId } from "./OneVision";
import { useHistory } from "react-router-dom";
import { userLogging } from "../../../apis/userLog";
import OneVisionDialog from "../OneVisionDialog";

let pdfUrl = "";
const buttonNamePrint = "印刷（PDFダウンロード）";
const buttonNameExcute = "登録（PDF作成）";

const useStyles = makeStyles({
  card: {
    margin: 3,
  },
  button: {
    color: "#fff",
    backgroundColor: "#ef942a",
    fontSize: "20px",
    width: "40vw",
    height: "5vh",
    margin: "10px",
    "&:hover": {
      background: "#ef942a",
    },
  },
  contentFrame: {
    overflowY: "auto",
    height: "68vh",
    // iPad縦
    "@media (min-width: 830px)": {
      height: "78vh",
    },
    // iPad横
    "@media (min-width: 1100px)": {
      height: "72vh",
    },
    // GWPC
    "@media (min-width: 1300px)": {
      height: "67vh",
    },
  },
  tableBodyCell: {
    padding: "0",
    verticalAlign: "top",
  },
  topSectionTitle: {
    writingMode: "vertical-rl",
    width: "5px",
    paddingTop: "0",
    paddingBottom: "0",
    border: "1px solid #aaa",
    backgroundColor: "whitesmoke",
  },
  tableItemCell: {
    padding: "0",
    border: "1px solid #aaa",
    borderTop: "hidden",
  },

  noCell: {
    paddingTop: "0",
    paddingBottom: "0",
    border: "1px solid #aaa",
    width: "17px",
    minWidth: "17px",
    backgroundColor: "whitesmoke",
  },
  itemCell: {
    padding: "0",
    paddingRight: "5px",
    paddingLeft: "5px",
    width: "100%",
    minWidth: "30vw",
    borderTop: "1px solid #aaa",
  },
  itemCell2: { padding: "0", width: "100vw" },
  okCell: {
    border: "1px solid #aaa",
    paddingTop: "0",
    paddingBottom: "0",
    textAlign: "center",
  },
  pointCell: {
    border: "1px solid #aaa",
    paddingTop: "0",
    paddingBottom: "0",
    textAlign: "center",
    minWidth: "30px",
  },
  minTableCell: {
    paddingTop: "0",
    paddingBottom: "0",
    border: "1px solid #aaa",
  },
  right: {
    textAlign: "right",
  },
  empCell: {
    padding: "0",
    border: "none",
  },
  overLayer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    backgroundColor: "#CCC",
    opacity: "0.5",
    zIndex: 100000,
  },
  circularProgress: {
    position: "absolute",
    top: "35%",
    left: "45%",
    opacity: "0.8",
    color: "#ccc",
  },
});

let pdfFlg = false;
let updateFlg = 0;
export { pdfFlg };

// 曜日の取得
const getDayOfWeekText = (datetime: DateTime | null) => {
  if (!datetime) {
    return;
  }
  let weekDayArray: string[] = [];
  weekDayArray = ["月", "火", "水", "木", "金", "土", "日"];
  const dayOfWeek = datetime.weekday;
  return weekDayArray[dayOfWeek - 1];
};

function setScoreName(workMap: any) {
  let scorePatternList: any[] = workMap["score_pattern"];
  for (let i = 0; i < scorePatternList.length; i++) {
    if (scorePatternList[i].value === String(workMap["score"])) {
      workMap["score_name"] = scorePatternList[i].name;
    }
  }
}

const OneVisionTabPrint: React.FC<{
  tenpoCode: string;
  oneVisionId: string;
  lastOneVisionId: string;
  setUpdateFlg: any;
}> = ({ tenpoCode, oneVisionId, lastOneVisionId, setUpdateFlg }) => {
  const classes = useStyles();

  const [otherData, setOtherData] = React.useState<ONEVISIONOTHERDATA>({});
  const [totalScore, setTotalScore] = React.useState("");
  const [pdfButtonName, setPdfButtonName] = React.useState(
    pdfUrl ? buttonNamePrint : buttonNameExcute
  );
  let myInput: any = React.createRef();
  const [rowItemList, setRowItemList] = React.useState<ONEVISIONBIGRECORD[]>(
    []
  );
  const [scoreItemList, setScoreItemList] = React.useState<
    ONEVISIONTOTALSCORE[]
  >([]);
  const [graphData, setGraphData] = React.useState<any[]>([]);
  const [approvalDate, setApprovalDate] = React.useState<DateTime | null>(
    DateTime.local()
  );
  const [convertedApprovalDate, setConvertedApprovalDate] = React.useState<
    string | null
  >(null);
  const [convertAgreementDate, setConvertAgreementDate] = React.useState("");

  const [convertRegulationDate, setConvertRegulationDate] = React.useState("");
  const storeMaster: Map<String, EXISTING_SEJ_STORES_RECORD> = getAllExistingSejStores().store_data;
  // 店舗レコードを内部データから取得
  const storeRecord = storeMaster.get(tenpoCode);
  const tenpoName = storeRecord
    ? storeRecord.tenpo_name
    : "";
  const [pdfURL, setPdfURL] = React.useState("");
  const [click, setClick] = React.useState(false);
  const [creatWait, setCreatWait] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [alertFlg, setAlertFlg] = React.useState(false);
  const [dialogNum, setDialogNum] = React.useState(0);

  const dialogOK = () => {
    if (!alertFlg) {
      if (dialogNum === 1) {
        setPdfButtonName("PDF作成中…");
        setCreatWait(true);
        handlePdfPrint();
      } else if (dialogNum === 2) {
        updatePdfPrint();
      }
    }
    setDialogFlg(false);
  };
  const dialogCancel = () => {
    if (dialogNum === 2) {
      setPdfButtonName(buttonNameExcute);
      setCreatWait(false);
      handleClick();
      setLoading(false);
    }
    setDialogFlg(false);
  };

  const handleClick = () => {
    setClick((prev) => !click);
  };

  const value = myInput;
  const imageToken = useSelector(selectImageToken);

  function getPdfURL() {
    const dir_pdf: string = process.env.REACT_APP_GCS_DIR_PDF
      ? process.env.REACT_APP_GCS_DIR_PDF + "/"
      : "pdftest/";
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      dir_pdf +
      oneVisionId +
      ".pdf" +
      "?access_token=" +
      imageToken
    );
  }

  useEffect(() => {
    if (!getFixedFlg()) {
      setPdfURL("");
      setPdfButtonName(buttonNameExcute);
    }
  }, [tenpoCode]);

  useEffect(() => {
    setLoading(true);
    userLogging("OneVision", "印刷#初期表示", oneVisionId);
    setApprovalDate(null);
    setConvertedApprovalDate("");
    getOneVisionData(tenpoCode, oneVisionId, lastOneVisionId).then(
      (res: any) => {
        setApprovalDate(
          res.approve_day
            ? DateTime.fromFormat(res.approve_day, "yyyyMMdd")
            : null
        );
        setConvertedApprovalDate(res.approve_day);
        setConvertAgreementDate(
          DateTime.fromFormat(res.agreement_day, "yyyyMMdd").toFormat(
            "yyyy年MM月dd日"
          )
        );
        setConvertRegulationDate(
          DateTime.fromFormat(res.regulation_day, "yyyyMMdd").toFormat(
            "yyyy年MM月dd日"
          )
        );

        let resRows: any[] = res.rows;
        //通しNo追加
        let no = 1;
        for (let i = 0; i < resRows.length; i++) {
          for (let j = 0; j < resRows[i]["rows"].length; j++) {
            resRows[i]["rows"][j]["id"] = no;
            if (resRows[i]["rows"][j]["score_pattern"].length === 2) {
              setScoreName(resRows[i]["rows"][j]);
            }
            no++;
          }
        }
        setRowItemList(resRows);
        setOtherData(res.other_data);
        setScoreItemList(res.score_data.scores);
        setTotalScore(res.score_data.total_score);

        let scoreList: any[] = res.score_data.scores;
        let graphData: any[] = [];
        for (let i = 0; i < scoreList.length; i++) {
          if (Number(scoreList[i].no) && Number(scoreList[i].no) !== 0) {
            graphData.push({
              name: scoreList[i].name,
              点数: roundScore(scoreList[i].score),
            });
          }
        }
        setGraphData(graphData);

        if (getFixedFlg()) {
          setPdfURL(getPdfURL());
          setPdfButtonName(buttonNamePrint);
        }
        setLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVisionId]);

  const history = useHistory();
  function clickReference(
    tenpoCode: string,
    tenpoCheckId: string,
    lastTenpoCheckId: string
  ) {
    setFixedFlg(true);
    setLastOneVisionId(lastTenpoCheckId);
    setOneVisionId(tenpoCheckId);
    setInitTenpoCode(tenpoCode);
    // 画面遷移
    history.push("one-vision");
  }

  function checkInputOneVision() {
    let result = true;
    for (let i = 0; i < rowItemList.length; i++) {
      for (let j = 0; j < rowItemList[i].rows.length; j++) {
        if (!rowItemList[i].rows[j].score) {
          result = false;
        }
      }
    }
    return result;
  }
  function checkManHourDate() {
    let result = true;
    let message: String[] = [];
    // if (
    //   (otherData.necessary_man_hour_date || 0) < 18 ||
    //   (otherData.necessary_man_hour_date || 0) > 90
    // ) {
    //   message.push("・必要人時／日");
    //   result = false;
    // }
    // if (
    //   (otherData.necessary_man_hour_date || 0) <
    //   (otherData.shortage_man_hour_date || 0)
    // ) {
    //   message.push("・不足人時／日");
    //   result = false;
    // }
    if (
      (otherData.necessary_man_hour_week || 0) < 126 ||
      (otherData.necessary_man_hour_week || 0) > 630
    ) {
      message.push("・必要人時／週");
      result = false;
    }
    if (
      (otherData.necessary_man_hour_week || 0) <
      (otherData.shortage_man_hour_week || 0)
    ) {
      message.push("・不足人時／週");
      result = false;
    }
    if (!convertAgreementDate) {
      message.push("・三六協定");
      result = false;
    }
    if (!convertRegulationDate) {
      message.push("・就業規則");
      result = false;
    }
    return { result, message };
  }

  function clickPdfPrint() {
    if (creatWait) {
      return;
    }
    if (pdfURL) {
      openWebSite(getPdfURL());
      return;
    }
    if (!checkInputOneVision()) {
      setDialogMessage([
        "未入力項目があります。",
        "入力内容を確認してください",
      ]);
      setDialogNum(0);
      setAlertFlg(true);
      setDialogFlg(true);
      return;
    }
    let resultCkManHourDate = checkManHourDate();
    if (!resultCkManHourDate.result) {
      let message = ["入力内容に誤りがあります。", "以下を確認してください"];
      resultCkManHourDate.message.forEach(function (str) {
        message.push(String(str));
      });
      setDialogMessage(message);

      setDialogNum(0);
      setAlertFlg(true);
      setDialogFlg(true);
      return;
    }
    if (!convertedApprovalDate) {
      setDialogMessage(["作成日を入力してください"]);
      setDialogNum(0);
      setAlertFlg(true);
      setDialogFlg(true);
      return;
    }
    if (getFixedFlg()) {
      setPdfButtonName("PDF作成中…");
      setCreatWait(true);
      handlePdfPrint();
    } else {
      setDialogMessage([
        "作成日 " +
          (approvalDate ? approvalDate.toFormat("yyyy年MM月dd日") : "") +
          " でPDF作成します。",
        "PDFの出力後に編集は行えません。",
        "よろしいですか？",
      ]);
      setDialogNum(1);
      setAlertFlg(false);
      setDialogFlg(true);
      return;
    }
  }

  const handlePdfPrint = async () => {
    setLoading(true);

    // 次回のOneVisionのデータを作成
    const apiParams = {
      id: oneVisionId,
      approve_day: convertedApprovalDate,
      newFlag: true,
    };

    const url = process.env.REACT_APP_GAE_API_URL + "onevision/data";
    setCreatWait(true);
    await postRequest(url, apiParams)
      .then(async (res) => {
        // 同一店舗同一週有りの場合、上書きの確認メッセージ表示
        if (res.data.dataList[0] === "SameStoreSameWeek") {
          if (getFixedFlg()) {
            updatePdfPrint();
            // 処理を終了する
            return;
          } else {
            setLoading(false);
            setCreatWait(false);
            setDialogMessage(["上書き保存されますがよろしいですか？"]);
            setDialogNum(2);
            setAlertFlg(false);
            setDialogFlg(true);
            // 処理を終了する
            return;
          }
        }
        const obj = JSON.parse(res.data.dataList[0]);
        setPdfURL(obj["url"]);
        setPdfButtonName(buttonNamePrint);
        setCreatWait(false);
        handleClick();
        setLoading(false);
        updateFlg++;
        setUpdateFlg(updateFlg);
        clickReference(tenpoCode, oneVisionId, lastOneVisionId);
      })
      .catch((r) => {
        setPdfButtonName(buttonNameExcute);
        setCreatWait(false);
        handleClick();
        setLoading(false);
        setDialogMessage([String(r)]);
        setDialogNum(0);
        setAlertFlg(true);
        setDialogFlg(true);
      });
  };

  const updatePdfPrint = async () => {
    setLoading(true);
    setCreatWait(true);
    const url = process.env.REACT_APP_GAE_API_URL + "onevision/data";
    const apiParams2 = {
      id: oneVisionId,
      approve_day: convertedApprovalDate,
      newFlag: false,
    };
    await postRequest(url, apiParams2).then((res2) => {
      const obj = JSON.parse(res2.data.dataList[0]);
      setPdfURL(obj["url"]);
      setPdfButtonName(buttonNamePrint);
      setCreatWait(false);
      handleClick();
      setLoading(false);
      updateFlg++;
      setUpdateFlg(updateFlg);

      clickReference(tenpoCode, oneVisionId, lastOneVisionId);
    });
    // 処理を終了する
  };

  return (
    <>
      {loading && <div className={classes.overLayer}></div>}
      {loading && (
        <CircularProgress className={classes.circularProgress} size={120} />
      )}
      {rowItemList && rowItemList.length > 0 ? (
        <>
          <Grid container className={classes.contentFrame}>
            {/* ヘッダー部↓ここから */}
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                backgroundColor: "gray",
                height: "50px",
                color: "white",
              }}
              direction="row"
            >
              <Grid
                container
                justify="flex-start"
                direction="column"
                xs
                lg={3}
                md={3}
                sm={3}
              >
                <Typography variant="h5">One Vision</Typography>
              </Grid>
              <Grid
                container
                justify="center"
                direction="column"
                xs
                lg={5}
                md={5}
                sm={5}
              >
                <Typography variant="h5">{tenpoName}</Typography>
              </Grid>
              <Grid
                container
                justify="flex-end"
                direction="column"
                xs
                lg={3}
                md={3}
                sm={3}
              >
                <Typography variant="h5">
                  {approvalDate
                    ? approvalDate.toFormat("yyyy/MM/dd") +
                      "(" +
                      getDayOfWeekText(approvalDate) +
                      ")"
                    : ""}
                </Typography>
              </Grid>
            </Grid>
            {/* ヘッダー部↑ここまで */}
            {/* 表メイン↓ここから */}
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Table>
                {rowItemList.map((row) => (
                  <TableRow>
                    <TableCell className={classes.topSectionTitle}>
                      {row.big_title}
                    </TableCell>
                    <TableCell className={classes.tableItemCell}>
                      {row.rows.map((rowData: any) => (
                        <>
                          <TableRow>
                            <TableCell className={classes.noCell}>
                              {rowData.id}
                            </TableCell>
                            <TableCell className={classes.itemCell}>
                              <TableRow>{rowData.small_title1}</TableRow>
                              {rowData.small_title2
                                ? "　　" + rowData.small_title2
                                : ""}
                            </TableCell>
                            {rowData.score_name !== "×" &&
                            rowData.score_name !== "◯" ? (
                              ""
                            ) : (
                              <TableCell className={classes.okCell}>
                                {rowData.score_name}
                              </TableCell>
                            )}
                            <TableCell className={classes.pointCell}>
                              {rowData.score || rowData.score === 0
                                ? rowData.score + "点"
                                : "―"}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Grid>
            {/* 表メイン↑ここまで */}

            <Grid style={{ padding: "10px" }}></Grid>

            {/* その他↓ここから */}
            <Grid container direction="row">
              {/* 各項目得点↓ここから */}
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  xs
                  lg={5}
                  md={8}
                  sm={8}
                >
                  <Table>
                    <TableHead
                      style={{
                        border: "1px solid #aaa",
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      <TableCell colSpan={2} className={classes.minTableCell}>
                        各項目の小計（1項目20点満点換算）
                      </TableCell>
                      <TableCell
                        className={classes.minTableCell}
                        style={{ textAlign: "center", minWidth: "40px" }}
                      >
                        点数
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {scoreItemList.map((row) => (
                        <>
                          <TableRow>
                            <TableCell className={classes.minTableCell}>
                              {row.no}
                            </TableCell>
                            <TableCell className={classes.minTableCell}>
                              {row.name}{" "}
                            </TableCell>
                            <TableCell
                              className={
                                classes.minTableCell + " " + classes.right
                              }
                            >
                              {roundScore(row.score)} 点
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                  <Grid style={{ padding: "3px" }}></Grid>
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  xs
                  lg={7}
                  md={10}
                  sm={10}
                  style={{ paddingLeft: "10px" }}
                >
                  <Grid container direction="row">
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                      xs
                      lg={4}
                      md={4}
                      sm={6}
                    >
                      <Table>
                        <TableRow
                          style={{
                            backgroundColor: "whitesmoke",
                          }}
                        >
                          <TableCell
                            className={classes.minTableCell}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            必要人時
                          </TableCell>
                          <TableCell
                            className={classes.minTableCell}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            不足人時
                          </TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className={
                                classes.minTableCell + " " + classes.right
                              }
                            >
                              {otherData.necessary_man_hour_date}人時/日
                            </TableCell>
                            <TableCell
                              className={
                                classes.minTableCell + " " + classes.right
                              }
                            >
                              {otherData.shortage_man_hour_date} 人時/日
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className={
                                classes.minTableCell + " " + classes.right
                              }
                            >
                              {otherData.necessary_man_hour_week} 人時/週
                            </TableCell>
                            <TableCell
                              className={
                                classes.minTableCell + " " + classes.right
                              }
                            >
                              {otherData.shortage_man_hour_week} 人時/週
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                      xs
                      lg={4}
                      md={4}
                      sm={6}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className={classes.minTableCell}
                              style={{
                                border: "1px solid #aaa",
                                backgroundColor: "whitesmoke",
                              }}
                            >
                              従業員人数（直近）
                            </TableCell>
                            <TableCell
                              className={classes.minTableCell}
                              style={{
                                verticalAlign: "bottom",
                              }}
                            >
                              {otherData.employee}人
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ verticalAlign: "middle" }}>
                            <TableCell
                              className={classes.minTableCell}
                              style={{
                                border: "1px solid #aaa",
                                backgroundColor: "whitesmoke",
                              }}
                            >
                              採用人数（一年間累計）
                            </TableCell>
                            <TableCell className={classes.minTableCell}>
                              {otherData.recruit} 人
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ verticalAlign: "middle" }}>
                            <TableCell
                              className={classes.minTableCell}
                              style={{
                                border: "1px solid #aaa",
                                backgroundColor: "whitesmoke",
                              }}
                            >
                              退職人数（一年間累計）
                            </TableCell>
                            <TableCell className={classes.minTableCell}>
                              {otherData.retire} 人{" "}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                      xs
                      lg={4}
                      md={4}
                      sm={12}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              className={classes.minTableCell}
                              style={{
                                border: "1px solid #aaa",
                                backgroundColor: "whitesmoke",
                              }}
                            >
                              三六協定・就業規則
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ verticalAlign: "middle" }}>
                            <TableCell
                              className={classes.minTableCell}
                              style={{
                                border: "1px solid #aaa",
                                backgroundColor: "whitesmoke",
                              }}
                            >
                              三六協定
                            </TableCell>
                            <TableCell className={classes.minTableCell}>
                              {convertAgreementDate}
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ verticalAlign: "middle" }}>
                            <TableCell
                              className={classes.minTableCell}
                              style={{
                                border: "1px solid #aaa",
                                backgroundColor: "whitesmoke",
                              }}
                            >
                              就業規則
                            </TableCell>
                            <TableCell className={classes.minTableCell}>
                              {convertRegulationDate}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Table>
                      <TableCell
                        style={{
                          border: "1px solid #aaa",
                        }}
                      >
                        合計
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #aaa",
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        {totalScore}点
                      </TableCell>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
              {/* 各項目得点↑ここまで */}

              <Grid style={{ padding: "10px" }}></Grid>

              {/* グラフ・課題・対策↓ここから */}
              <Grid container direction="row">
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  xs
                  lg={6}
                  md={12}
                  sm={12}
                >
                  <ResponsiveContainer width={550} height={300}>
                    <RadarChart
                      cx="50%"
                      cy="50%"
                      outerRadius="80%"
                      data={graphData}
                      style={{ fontSize: "13px" }}
                    >
                      <PolarGrid />
                      <PolarAngleAxis dataKey="name" />
                      <PolarRadiusAxis domain={[0, 20]} tickCount={6} />
                      <Radar
                        dataKey="点数"
                        stroke="#5b9bd5"
                        fill="red"
                        fillOpacity={0.5}
                      />
                      <Tooltip />
                    </RadarChart>
                  </ResponsiveContainer>
                </Grid>
                <Grid container direction="column" xs lg={6} md={12} sm={12}>
                  <Grid container>◆課題</Grid>
                  <Grid container>
                    <TextField
                      aria-label="store-input-comment-area"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={otherData.task}
                      fullWidth={true}
                      inputProps={{ readOnly: true }}
                    ></TextField>
                  </Grid>
                  <Grid style={{ padding: "10px" }}></Grid>
                  <Grid container>◆対策</Grid>
                  <Grid container>
                    <TextField
                      aria-label="store-input-comment-area"
                      multiline
                      value={otherData.measures}
                      rows={4}
                      variant="outlined"
                      fullWidth={true}
                      inputProps={{ readOnly: true }}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
              {/* グラフ・課題・対策↑ここまで */}
            </Grid>
            {/* その他↑ここまで */}
          </Grid>
          {/* 印刷ボタン↓ここから */}
          <Grid container justify="center" alignItems="center">
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                userLogging("OneVision", "印刷#帳票出力", "");
                clickPdfPrint();
              }}
            >
              {pdfButtonName}
              {pdfURL ? <DownloadIcon /> : ""}
            </Button>
          </Grid>
          {/* 印刷ボタン↑ここまで */}
        </>
      ) : (
        ""
      )}

      <OneVisionDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogOK}
        doNo={dialogCancel}
        isAlert={alertFlg}
      />
    </>
  );
};

export default OneVisionTabPrint;
