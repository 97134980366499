import React, { FC, useRef, forwardRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { localizationJapanese } from "../../common/MatelialTableLocalizationJapanese";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  Avatar,
  Button,
  Grid,
  GridSize,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { CameraAlt, SmsOutlined } from "@material-ui/icons";
import RemoveIcon from "@material-ui/icons/Remove";
import { red, green } from "@material-ui/core/colors";
import TenpoCheckManagerAnalysisNgButton from "./TenpoCheckManagerAnalysisNgButton";
import TenpoCheckManagerAnalysisOKButton from "./TenpoCheckManagerAnalysisOKButton";
import { DateTime } from "luxon";
import { getFirstDayOfWeek } from "../Register/TenpoCheckTableDataOperation";
import { userLogging } from "../../../apis/userLog";
import { useHistory } from "react-router-dom";
import { setLastTenpoCheckId, setTenpoCheckId } from "../Register/TenpoCheck";
import { setInitTenpoCode, setFixedFlg } from "../../Header/Header";

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    maxWidth: "90%",
    paddingTop: "10px",
  },
  dataTableHead: {
    outlineColor: "#3e3e3e",
    backgroundColor: "#4dc0b2",
    border: "5px",
    padding: "0 0",
  },
  dataTableHeadCell: {
    textAlign: "center",
    border: "0px solid #aaa",
    padding: "0 0",
    fontSize: "12px",
    color: "white",
  },
  dataTableBody: {
    border: "1px solid #aaa",
    backgroundColor: "#ffffff",
  },
  dataTableCell: {
    width: "100px",
    textAlign: "right",
    border: "0px solid #aaa",
    paddingTop: "3px",
    paddingBottom: "3px",
    fontSize: "12px",
  },
  gray: {
    color: "#c0c0c0",
    backgroundColor: "#e6e6e6",
    width: "30px",
    height: "30px",
    boxShadow: "0px 0px 0px 1px #000 inset",
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
    width: "30px",
    height: "30px",
    boxShadow: "0px 0px 0px 1px #000 inset",
  },
  red: {
    color: "#fff",
    backgroundColor: red[500],
    width: "30px",
    height: "30px",
    boxShadow: "0px 0px 0px 1px #000 inset",
  },
}));

const TenpoCheckManagerAnalysisTable: FC<{
  data: any[];
  averageData: any;
  headerName: string;
  tableHeaderList: any[];
  tableSubmissionHeaderList: string[];
  tabValue: number;
  graphUserList?: any[];
  baseWeek: string;
  smallFlg: boolean;
  smallData: any[];
  smallAverageData: any;
  bigItemId: string;
  smallItemId: string;
  selectedTable: string;
  onClickMove?: (code: string) => void;
  onClickSelect?: (code: string, name: string) => void;
}> = ({
  data,
  averageData,
  headerName,
  tableHeaderList,
  tableSubmissionHeaderList,
  tabValue,
  graphUserList,
  baseWeek,
  smallFlg,
  smallData,
  smallAverageData,
  bigItemId,
  smallItemId,
  selectedTable,
  onClickMove,
  onClickSelect,
}) => {
  const tableRef: any = useRef();
  const classes = useStyles();
  const [sortNo, setSortNo] = React.useState(0);
  const [orderDirection, setOrderDirection] = React.useState<
    "desc" | "asc" | undefined
  >(undefined);
  const history = useHistory();

  // 項目名クリック
  const moveClick = (code: string) => {
    if (onClickMove) {
      onClickMove(code);
    }
  };

  const getColumnsList = () => {
    let resultList: any[] = [];
    const ofcColumn = {
      title: "OFC",
      field: "ofcName",
      cellStyle: {
        width: "300px",
      },
      sorting: false,
      render: (rowData: any) => {
        return rowData.ofcName;
      },
    };
    const nameColumn = {
      title: headerName,
      field: "name",
      cellStyle: {
        width: "300px",
      },
      sorting: false,
      render: (rowData: any) => {
        return rowData.code !== "average" && tabValue !== 3 ? (
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => moveClick(rowData.code)}
            color="primary"
          >
            {rowData.name}
          </Link>
        ) : (
          rowData.name
        );
      },
    };
    let baseWeekColumn = {
      title: baseWeek ? "(基)" + baseWeek : "基準週",
      field: "baseWeekScore",
      align: "center",
      type: "numeric",
      defaultSort: sortNo === 1 && orderDirection ? orderDirection : undefined,
      render: (rowData: any) => {
        return !smallFlg ? (
          rowData["baseWeekScore"] === undefined ||
          rowData["baseWeekScore"] === "" ? (
            ""
          ) : selectedTable !== "1" ? (
            rowData["baseWeekScore"] + "%"
          ) : (
            rowData["baseWeekScore"]
          )
        ) : tabValue === 3 ? (
          <Grid container justify="center" alignItems="center">
            {rowData["baseWeekScore"] || rowData["baseWeekScore"] === 0 ? (
              rowData["baseWeekScore"] === -2 ? (
                <></>
              ) : rowData["baseWeekScore"] === -1 ? (
                <Avatar className={classes.gray}>
                  <RemoveIcon style={{ fontSize: "16px" }} />
                </Avatar>
              ) : rowData["baseWeekScore"] === 0 ? (
                <Grid item xs={12}>
                  {addAttachedIcon(rowData, "_base",
                    <Avatar className={classes.red}>
                      <TenpoCheckManagerAnalysisNgButton
                        id={rowData["baseId"]}
                        bigItemId={bigItemId}
                        smallItemId={smallItemId}
                      />
                    </Avatar>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  {addAttachedIcon(rowData, "_base",
                    <Avatar className={classes.green}>
                        <TenpoCheckManagerAnalysisOKButton
                        id={rowData["baseId"]}
                        bigItemId={bigItemId}
                        smallItemId={smallItemId}
                        />
                    </Avatar>
                  )}
                </Grid>
              )
            ) : (
              ""
            )}
          </Grid>
        ) : rowData["baseWeekScore"] || rowData["baseWeekScore"] === 0 ? (
          rowData["baseWeekScore"] + "%"
        ) : (
          ""
        );
      },
    };
    const weekColumn: Array<any> = [];
    for (let i = 0; i < 5; i++) {
      const headerNo = i;
      const diffNo = i + 1;
      const diff = diffNo + "wDiff";
      const diffBase = diffNo + "wDiffBase";
      const tableHedder = tableHeaderList[headerNo];
      weekColumn.push({
        title: tableHedder,
        field: tableHedder,
        align: "center",
        type: "numeric",
        defaultSort:
          sortNo === i + 2 && orderDirection ? orderDirection : undefined,
        customSort: (a: any, b: any) => {
          return !smallFlg
            ? a[diffBase] || a[diffBase] === 0
              ? Number(a[diffBase]) - Number(b[diffBase])
              : Number(a[diff]) - Number(b[diff])
            : Number(a[tableHedder]) - Number(b[tableHedder]);
        },
        render: (rowData: any) => {
          return tabValue !== 3 ||
            (tabValue === 3 && !smallFlg &&
              !rowData[tableSubmissionHeaderList[i] + "_" + "id"]) ? (
            rowData[tableHedder] +
              (selectedTable !== "1" ? "%" : "") +
              "(" +
              (rowData[diffBase] || rowData[diffBase] === 0
                ? (Number(rowData[diffBase]) > 0
                    ? "+"
                    : Number(rowData[diffBase]) === 0
                    ? "±"
                    : "") + rowData[diffBase]
                : (Number(rowData[diff]) > 0
                    ? "+"
                    : Number(rowData[diff]) === 0
                    ? "±"
                    : "") + rowData[diff]) +
              ")"
          ) : !smallFlg ? (
            <Link
              style={{ cursor: "pointer" }}
              onClick={() =>
                clickTenpoScore(
                  rowData[tableSubmissionHeaderList[i] + "_" + "id"],
                  rowData[tableSubmissionHeaderList[i] + "_" + "doc_last_id"],
                  rowData["tenpo_code"]
                )
              }
              color="primary"
            >
              {rowData[tableHedder] +
                (selectedTable !== "1" ? "%" : "") +
                "(" +
                (rowData[diffBase] || rowData[diffBase] === 0
                  ? (Number(rowData[diffBase]) > 0
                      ? "+"
                      : Number(rowData[diffBase]) === 0
                      ? "±"
                      : "") + rowData[diffBase]
                  : (Number(rowData[diff]) > 0
                      ? "+"
                      : Number(rowData[diff]) === 0
                      ? "±"
                      : "") + rowData[diff]) +
                ")"}
            </Link>
          ) : (
            <Grid container justify="center" alignItems="center">
              {rowData[tableHedder] === -2 ? (
                <></>
              ) : rowData[tableHedder] === -1 ? (
                <Avatar className={classes.gray}>
                  <RemoveIcon style={{ fontSize: "16px" }} />
                </Avatar>
              ) : rowData[tableHedder] === 0 ? (
                <Grid item xs={12}>
                  {addAttachedIcon(rowData, String(i + 1),
                      <Avatar className={classes.red}>
                        <TenpoCheckManagerAnalysisNgButton
                          id={rowData["id" + (i + 1)]}
                          bigItemId={bigItemId}
                          smallItemId={smallItemId}
                        />
                      </Avatar>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  {addAttachedIcon(rowData, String(i + 1),
                    <Avatar className={classes.green}>
                        <TenpoCheckManagerAnalysisOKButton
                          id={rowData["id" + (i + 1)]}
                          bigItemId={bigItemId}
                          smallItemId={smallItemId}
                        />
                    </Avatar>
                  )}
                </Grid>
              )}
            </Grid>
          );
        },
      });
    }

    // 提出数カラム
    // 提出数カラムはOFC分析(tabValue === 2)の場合のみ出力
    const submissionColumn: Array<any> = [];
    for (let i = 0; i < 5; i++) {
      submissionColumn.push({
        // title: convertDateTitle(tableSubmissionHeaderList[i]),
        title: "",
        field: tableSubmissionHeaderList[i],
        maxWidth: 300,
        sorting: false,
        align: "left",
        render: (rowData: any) => {
          return rowData[tableSubmissionHeaderList[i]] !== "0" ? (
            <Link
              style={{ cursor: "pointer" }}
              onClick={() =>
                clickSubmissionsCount(
                  tableSubmissionHeaderList[i],
                  rowData["ofc_mailaddress"]
                )
              }
              color="primary"
            >
              {rowData[tableSubmissionHeaderList[i]]}&#047;{rowData["tenpo_count"]}
            </Link>
          ) : (
            <>
              {rowData[tableSubmissionHeaderList[i]]}&#047;{rowData["tenpo_count"]}
            </>
          );
        },
      });
    }

    const selectColumn = {
      title: "",
      field: "",
      align: "center",
      sorting: false,
      render: (rowData: any) => {
        return !smallFlg ? (
          <Button
            variant="contained"
            color={checkedGraphUser(rowData.code) ? "secondary" : "primary"}
            size="small"
            style={{ padding: "0" }}
            onClick={() => selectClick(rowData.code, rowData.name)}
          >
            選択
          </Button>
        ) : (
          ""
        );
      },
    };

    if (tabValue === 3) {
      resultList.push(ofcColumn);
    }
    resultList.push(nameColumn);
    resultList.push(baseWeekColumn);
    if (tabValue === 2) {
      for (let i = 0; i < 5; i++) {
        resultList.push(weekColumn[i]);
        resultList.push(submissionColumn[i]);
      }
    } else {
      for (let i = 0; i < 5; i++) {
        resultList.push(weekColumn[i]);
      }
    }
    resultList.push(selectColumn);

    return resultList;
  };

  /**
   * 添付画像・コメントアイコン追加.
   * @param rowData テーブルの行データ
   * @param suffix 添え字
   * @param element アイコンを付ける元の〇×アイコン
   * @returns 添付画像・コメントアイコンを追加した要素
   */
  const addAttachedIcon = (rowData: any, suffix: string, element: JSX.Element): JSX.Element => {
    const scoreAreaWidth: number = suffix === "_base" ? 4 : 5;
    const attachedIconAreaWidth: number = 8 - scoreAreaWidth;

    return (
      <Grid container justify="center" alignContent="center" spacing={1} style={{ padding: 0 }}>
        <Grid item xs={scoreAreaWidth as GridSize}>
          {element}
        </Grid>
        <Grid item xs={attachedIconAreaWidth as GridSize}>
          <Grid container direction="column" alignItems="flex-start" justify="center">
            <Grid item xs={6}>
              {rowData[`photo_flag${suffix}`] === "1" ? (
                <CameraAlt style={{ fontSize: "small" }} />
              ) : (
                <h6></h6>
              )}
            </Grid>
            <Grid item xs={6}>
              {rowData[`comment_flag${suffix}`] === "1" ? (
                <SmsOutlined style={{ fontSize: "small" }} />
              ) : (
                <h6></h6>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // 選択をクリック
  const selectClick = (code: string, name: string) => {
    if (onClickSelect) {
      onClickSelect(code, name);
    }
  };

  // 選択中か判定
  const checkedGraphUser = (code: string) => {
    if (graphUserList) {
      for (let i = 0; i < graphUserList.length; i++) {
        if (graphUserList[i].code === code) {
          return true;
        }
      }
    }
    return false;
  };

  // 対象OFCの店舗の検索に遷移
  const clickSubmissionsCount = (dateString: any, ofcAddress: string) => {
    const targetDateTime = DateTime.fromFormat(dateString, "yyyy/MM/dd");
    const jsDate = targetDateTime.toJSDate();
    const fromDate: DateTime = DateTime.fromFormat(
      getFirstDayOfWeek(jsDate.toISOString(), 0),
      "yyyyMMdd"
    );
    const toDate: DateTime = DateTime.fromFormat(
      getFirstDayOfWeek(jsDate.toISOString(), 6),
      "yyyyMMdd"
    );

    const paramState = {
      ofcAddress: ofcAddress,
      createdateFrom: fromDate,
      createdateTo: toDate,
    };
    history.push({
      pathname: "tenpo-check-search",
      state: paramState,
    });
    userLogging("店舗確認表", "管理者分析#検索画面へ遷移", "データ検索");
  };

  // 対象年月タイトル表示文字列変換
  const convertDateTitle = (dateString: string): string => {
    const datetime = DateTime.fromFormat(dateString, "yyyy/MM/dd");
    return datetime.toFormat("MM/dd");
  };

  // 対象店舗確認表に遷移
  const clickTenpoScore = (tenpoCheckId: string, lastTenpoCheckId: string, tenpoCode: string) => {
    setFixedFlg(true);
    setLastTenpoCheckId(lastTenpoCheckId);
    setTenpoCheckId(tenpoCheckId);
    setInitTenpoCode(tenpoCode);
    // 画面遷移
    history.push("tenpo-check");
    userLogging("店舗確認表", "管理者分析#検索画面へ遷移", "店舗確認表ID: " + tenpoCheckId);
  };

  return (
    <Grid container justify="center" alignItems="center">
      {!smallFlg ? (
        <>
          <Grid container justify="center" alignItems="center">
            <Table className={classes.dataTable}>
              <TableHead className={classes.dataTableHead}>
                <TableCell
                  className={classes.dataTableHeadCell}
                  style={{
                    width: "300px",
                  }}
                >
                  {headerName}
                </TableCell>
                <TableCell className={classes.dataTableHeadCell}>
                  <Typography style={{ fontSize: "12px" }}>
                    {baseWeek ? "(基)" + baseWeek : "基準週"}
                  </Typography>
                </TableCell>
                {tableHeaderList.map((row) => (
                  <TableCell className={classes.dataTableHeadCell}>
                    {row}
                  </TableCell>
                ))}
                <TableCell className={classes.dataTableHeadCell}></TableCell>
              </TableHead>
              <TableBody className={classes.dataTableBody}>
                <TableRow>
                  <TableCell
                    className={classes.dataTableCell}
                    style={{ textAlign: "left" }}
                  >
                    {averageData.name}
                  </TableCell>
                  <TableCell className={classes.dataTableCell}>
                    {averageData.baseWeekScore === undefined ||
                    averageData.baseWeekScore === ""
                      ? ""
                      : averageData.baseWeekScore +
                        (selectedTable !== "1" ? "%" : "")}
                  </TableCell>
                  {tableHeaderList.map((itemName, i) => (
                    <TableCell className={classes.dataTableCell}>
                      {averageData[itemName] +
                        (selectedTable !== "1" ? "%" : "") +
                        "(" +
                        (averageData[i + 1 + "wDiffBase"] ||
                        averageData[i + 1 + "wDiffBase"] === 0
                          ? (Number(averageData[i + 1 + "wDiffBase"]) > 0
                              ? "+"
                              : Number(averageData[i + 1 + "wDiffBase"]) === 0
                              ? "±"
                              : "") + averageData[i + 1 + "wDiffBase"]
                          : (Number(averageData[i + 1 + "wDiff"]) > 0
                              ? "+"
                              : Number(averageData[i + 1 + "wDiff"]) === 0
                              ? "±"
                              : "") + averageData[i + 1 + "wDiff"]) +
                        ")"}
                    </TableCell>
                  ))}

                  <TableCell className={classes.dataTableCell}>
                    <Button
                      variant="contained"
                      color={
                        checkedGraphUser(averageData.code)
                          ? "secondary"
                          : "primary"
                      }
                      size="small"
                      style={{ padding: "0" }}
                      onClick={() =>
                        selectClick(averageData.code, averageData.name)
                      }
                    >
                      選択
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </>
      ) : (
        <>
          <Grid container justify="center" alignItems="center">
            <Table className={classes.dataTable}>
              <TableHead className={classes.dataTableHead}>
                <TableCell
                  className={classes.dataTableHeadCell}
                  style={{
                    width: "300px",
                  }}
                >
                  {headerName}
                </TableCell>
                <TableCell className={classes.dataTableHeadCell}>
                  <Typography style={{ fontSize: "12px" }}>
                    {baseWeek ? "(基)" + baseWeek : "基準週"}
                  </Typography>
                </TableCell>
                {tableHeaderList.map((row) => (
                  <TableCell className={classes.dataTableHeadCell}>
                    {row}
                  </TableCell>
                ))}
                <TableCell className={classes.dataTableHeadCell}></TableCell>
              </TableHead>
              <TableBody className={classes.dataTableBody}>
                <TableRow>
                  <TableCell
                    className={classes.dataTableCell}
                    style={{ textAlign: "left" }}
                  >
                    {smallAverageData.name}
                  </TableCell>
                  <TableCell className={classes.dataTableCell}>
                    {smallAverageData.baseWeekScore ||
                    smallAverageData.baseWeekScore === 0
                      ? smallAverageData.baseWeekScore + "%"
                      : ""}
                  </TableCell>
                  {tableHeaderList.map((itemName, i) => (
                    <TableCell className={classes.dataTableCell}>
                      {tabValue === 3
                        ? smallAverageData[itemName] ||
                          smallAverageData[itemName] === 0
                          ? smallAverageData[itemName] + "%"
                          : ""
                        : smallAverageData[itemName] ||
                          smallAverageData[itemName] === 0
                        ? smallAverageData[itemName] +
                          "%" +
                          "(" +
                          (smallAverageData[i + 1 + "wDiffBase"] ||
                          smallAverageData[i + 1 + "wDiffBase"] === 0
                            ? (Number(smallAverageData[i + 1 + "wDiffBase"]) > 0
                                ? "+"
                                : Number(
                                    smallAverageData[i + 1 + "wDiffBase"]
                                  ) === 0
                                ? "±"
                                : "") + smallAverageData[i + 1 + "wDiffBase"]
                            : (Number(smallAverageData[i + 1 + "wDiff"]) > 0
                                ? "+"
                                : Number(smallAverageData[i + 1 + "wDiff"]) ===
                                  0
                                ? "±"
                                : "") + smallAverageData[i + 1 + "wDiff"]) +
                          ")"
                        : ""}
                    </TableCell>
                  ))}
                  <TableCell className={classes.dataTableCell}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </>
      )}

      <Grid container style={{ padding: "10px" }}></Grid>
      <Grid container justify="center" alignItems="center">
        <MaterialTable
          tableRef={tableRef}
          data={
            !smallFlg
              ? JSON.parse(JSON.stringify(data))
              : JSON.parse(JSON.stringify(smallData))
          }
          columns={getColumnsList()}
          style={{ width: "90%" }}
          components={{
            Pagination: (props) => (
              <TablePagination {...props} rowsPerPageOptions={[5, 10, 15]} />
            ),
          }}
          icons={{
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => (
              <DeleteOutline {...props} ref={ref} />
            )),
            DetailPanel: forwardRef((props, ref) => (
              <ChevronRight {...props} ref={ref} />
            )),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => (
              <SaveAlt {...props} ref={ref} />
            )),
            Filter: forwardRef((props, ref) => (
              <FilterList {...props} ref={ref} />
            )),
            FirstPage: forwardRef((props, ref) => (
              <FirstPage {...props} ref={ref} />
            )),
            LastPage: forwardRef((props, ref) => (
              <LastPage {...props} ref={ref} />
            )),
            NextPage: forwardRef((props, ref) => (
              <ChevronRight {...props} ref={ref} />
            )),
            PreviousPage: forwardRef((props, ref) => (
              <ChevronLeft {...props} ref={ref} />
            )),
            ResetSearch: forwardRef((props, ref) => (
              <Clear {...props} ref={ref} />
            )),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => (
              <ArrowDownward {...props} ref={ref} />
            )),
            ThirdStateCheck: forwardRef((props, ref) => (
              <Remove {...props} ref={ref} />
            )),
            ViewColumn: forwardRef((props, ref) => (
              <ViewColumn {...props} ref={ref} />
            )),
          }}
          onOrderChange={(orderBy: number, orderDirection: "asc" | "desc") => {
            setSortNo(orderBy);
            setOrderDirection(orderDirection);
          }}
          localization={localizationJapanese}
          options={{
            padding: "dense",
            draggable: false,
            search: false,
            showTitle: false,
            toolbar: false,
            filtering: false,
            paging: false,
            // 対象外の場合はcsv出力ボタン非表示
            exportButton: false,
            headerStyle: {
              backgroundColor: "#4dc0b2",
              color: "#ffffff",
              whiteSpace: "nowrap",
              paddingLeft: "14px",
              paddingRight: "14px",
            },
            rowStyle: {
              fontSize: "12px",
              whiteSpace: "nowrap",
            },
          }}
        ></MaterialTable>
      </Grid>
      <Grid container style={{ padding: "10px" }}></Grid>
    </Grid>
  );
};

export default TenpoCheckManagerAnalysisTable;
