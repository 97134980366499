import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Modal, Button } from "@material-ui/core";
import { selectNotification } from "../../features/userSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: "absolute",
      top: "10%",
      left: "25%",
      width: "50%",
      height: "50%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: "3%",
      "&:focus": {
        outline: "none",
      },
    },
    title: {
      textAlign: "center",
    },
    button: {
      textAlign: "left",
      position: "absolute",
      bottom: "5%",
      right: "5%",
    },
  })
);

const TroubleNotification: FC<{}> = () => {
  const notification = useSelector(selectNotification);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const title = notification?.title !== undefined ? notification?.title : "";
  const text = notification?.text !== undefined ? notification?.text : "";
  const term = notification?.term !== undefined ? notification?.term : "";
  const flag = notification?.flag !== undefined ? notification?.flag : false;
  const getNotification = () => {
    return (
      <div>
        <p>{text}</p>
        <p>
          メンテナンス日時：
          <br />
          {term}
          <br />
        </p>
        <p>上記の時間帯にサービスシステムの停止がございます。</p>
      </div>
    );
  };

  useEffect(() => {
    flag && setOpen(true);
  }, []);

  return (
    <>
      <Modal
        open={open}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="graph-modal-title"
        aria-describedby="graph-modal-description"
      >
        <div className={classes.modal}>
          <h2 className={classes.title}>{title}</h2>
          {getNotification()}
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TroubleNotification;
