import React, { FC } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { selectImageToken } from "../../../features/userSlice";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "5%",
      right: "20%",
      width: "50%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 4, 2),
      "&:focus": {
        outline: "none",
      },
    },
    infoButton: {
      color: "#333333",
      "&:hover": {
        color: "#757575",
      },
      height: "30px",
      width: "30px",
    },
    closeButton: {
      position: "absolute",
      left: "95%",
      "&:hover": {
        backgroundColor: "lightgray",
      },
    },
  })
);

const CalendarManual: FC<{}> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const imageToken = useSelector(selectImageToken);
  const tempFilePath = "opview-manual/calendar.png";
  const handleOpen = () => {
    if (tempFilePath) {
      setOpen(true);
    }
  };
  let imgFileList: string[] = [];
  if (tempFilePath) {
    imgFileList.push(
      process.env.REACT_APP_CLOUD_STORAGE_URL +
        tempFilePath +
        "?access_token=" +
        imageToken
    );
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {imgFileList && imgFileList.length > 0 ? (
        <InfoIcon
          className={classes.infoButton}
          onClick={() => handleOpen()}
          fontSize="small"
        ></InfoIcon>
      ) : (
        ""
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ height: "95vh", overflowY: "auto" }}
      >
        <div className={classes.paper}>
          <div>
            <IconButton
              className={classes.closeButton}
              onClick={() => setOpen(false)}
              size={"small"}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <h3 id="simple-modal-title"></h3>
          {imgFileList
            ? imgFileList.map((res: any, index: number) => (
                <div key={index}>
                  <img alt="" src={res} width="100%" height="100%" />
                </div>
              ))
            : ""}
        </div>
      </Modal>
    </>
  );
};
export default CalendarManual;
