import React, { useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DvrIcon from "@material-ui/icons/Dvr";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import { auth } from "../../../firebase";

function getModalStyle() {
  return {
    top: "5%",
    left: "5%",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      position: "absolute",
      right: "12px",
      bottom: "240px",
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
    modal: {
      position: "absolute",
      width: "90%",
      height: "90%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1),
      overflowX: "scroll",
    },
  })
);

const MapsModalLooker: React.FC = (props: any) => {
  const classes = useStyles({});
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [sUrl, setSUrl] = React.useState("");
  const [count, setCount] = React.useState(0);

  let mailAddress = "";
  if (auth.currentUser != null && auth.currentUser.email != null) {
    mailAddress = auth.currentUser.email;
  }

  const isDevCheck: boolean =
    process.env.REACT_APP_MODE === "develop" &&
    mailAddress === "test1@opview.7view.jp";
  const isFirstRender = useRef(false);

  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);

  // マップを開いた際（初回）と、モーダルが開かれた際に更新
  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false; // もう初回レンダーじゃないよ代入
    } else {
      // isFirstRenderを入れて初回マウント時に実行されないようにしている
      axios
        .get(
          `https://asia-northeast1-seven-central-sej-dev.cloudfunctions.net/looker-sso-dev`
        )
        .then((res) => {
          // 取得したURLをセット
          setSUrl(res.data);
        });
    }
  }, [count]);

  // グラフの表示・非表示
  const handleOpen = () => {
    setOpen(true);
    setCount(count + 1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isDevCheck && (
        <IconButton
          className={classes.button + " map-item-shadow"}
          onClick={() => handleOpen()}
        >
          <DvrIcon fontSize="small" />
        </IconButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="graph-modal-title"
        aria-describedby="graph-modal-description"
      >
        <div style={modalStyle} className={classes.modal}>
          <iframe id="example1" src={sUrl} width="98%" height="98%"></iframe>
        </div>
      </Modal>
    </>
  );
};

export default MapsModalLooker;
