import React, { FC, useEffect } from "react";
import { DateTime } from "luxon";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Table, TableCell, Typography } from "@material-ui/core";
import { TableRow } from "@material-ui/core/";
import { CircularProgress } from "@material-ui/core";
import { Select, MenuItem, Button } from "@material-ui/core";
import { TextField, OutlinedInput, Grid } from "@material-ui/core";
import { getFirstFlg, getFixedFlg } from "../../Header/Header";
import DatePicker from "../../common/DatePicker";
import {
  getOneVisionData,
  updateOneVisionFirestore,
  updateOneVision,
  ONEVISIONRECORD,
  ONEVISIONOTHERDATA,
} from "./OneVisionDataOperation";
import { VerticalAlignTop, VerticalAlignBottom } from "@material-ui/icons";
import { userLogging } from "../../../apis/userLog";
import OneVisionDialog from "../OneVisionDialog";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import OneVisionModalManual from "./OneVisionModalManual";

const useStyles = makeStyles({
  contentFrame: {
    overflowY: "auto",
    height: "68vh",
    // iPad縦
    "@media (min-width: 1000px)": {
      height: "68vh",
    },
    // iPad横
    "@media (min-width: 1150px)": {
      height: "68vh",
    },
    // GWPC
    "@media (min-width: 1300px)": {
      height: "65vh",
    },
  },
  columnData: {
    color: "#3e3e3e",
    backgroundColor: "#ffffff",
    margin: "2px",
  },
  header: {
    paddingTop: "15px",
    paddingLeft: "15px",
    marginLeft: "0px",
    marginBottom: "10px",
    backgroundColor: "#ffffff",
  },
  buttonSpace: {
    paddingRight: "15px",
  },
  tabBase: {
    backgroundColor: "#d9d9d9",
    padding: "2px",
  },
  columnHeader: {
    color: "white",
    outlineColor: "#3e3e3e",
    backgroundColor: "#4dc0b2",
    margin: "2px",
  },
  columnResult: {
    color: "#3e3e3e",
    backgroundColor: "#d8e8e6",
    verticalAlign: "middle",
    margin: "2px",
  },
  columnHeight: {
    minHeight: "9vh",
  },
  headerHeight: {
    height: "7vh",
  },
  footer: {
    paddingTop: "8px",
  },

  tableCell: {
    paddingTop: "0",
    paddingBottom: "0",
    border: "1px solid #aaa",
  },
  inputTableCell: {
    paddingTop: "0",
    paddingBottom: "0",
    border: "1px solid #aaa",
    height: "65px",
  },
  tableHeader: {
    color: "white",
    backgroundColor: "#4dc0b2",
    textAlign: "center",
  },
  weekHeader: {
    color: "white",
    backgroundColor: "#e3bc52",
    textAlign: "center",
  },
  overLayer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    backgroundColor: "#CCC",
    opacity: "0.5",
    zIndex: 100000,
  },
  circularProgress: {
    position: "absolute",
    top: "35%",
    left: "45%",
    opacity: "0.8",
    color: "#ccc",
  },
  upDownButton: {
    color: "white",
    backgroundColor: "#ef942a",
    "&:hover": {
      backgroundColor: "#e38511",
    },
    marginRight: "1vw",
  },
});

// 画面項目の変更フラグ
let changeFlg = false;
export function getChangeFlg() {
  return changeFlg;
}
export function setChangeFlg(flg: boolean) {
  changeFlg = flg;
}

// 曜日の取得
const getDayOfWeekText = (datetime: DateTime | null) => {
  if (!datetime) {
    return;
  }
  let weekDayArray: string[] = [];
  weekDayArray = ["月", "火", "水", "木", "金", "土", "日"];
  const dayOfWeek = datetime.weekday;
  return weekDayArray[dayOfWeek - 1];
};

// 作成日付(旧承認日)
let approveDay: string | null = "";
export function getApproveDay() {
  return approveDay;
}
export function setApproveDay(dayStr: string | null) {
  approveDay =
    dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid ? dayStr : "";
}
let agreementDay: string | null = "";
export function getAgreementDay() {
  return agreementDay;
}
function setAgreementDay(dayStr: string | null) {
  agreementDay =
    dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid ? dayStr : "";
}
let regulationDay: string | null = "";
export function getRegulationDay() {
  return regulationDay;
}
function setRegulationDay(dayStr: string | null) {
  regulationDay =
    dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid ? dayStr : "";
}

// 最初の読み込み時に追い越しが走らないようにするためのフラグ
let initailizeFlag: boolean;

const OneVisionTable: FC<{
  tenpoCode: string;
  oneVisionId: string;
  lastOneVisionId: string;
}> = ({ tenpoCode, oneVisionId, lastOneVisionId }) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [click, setClick] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<any[]>([]);
  const [necessaryManHourDate, setNecessaryManHourDate] = React.useState(0);
  const [necessaryManHourWeek, setNecessaryManHourWeek] = React.useState(0);
  const [shortageManHourDate, setShortageManHourDate] = React.useState(0);
  const [shortageManHourWeek, setShortageManHourWeek] = React.useState(0);
  const [employee, setEmployee] = React.useState(0);
  const [recruit, setRecruit] = React.useState(0);
  const [retire, setRetire] = React.useState(0);

  const [tmpNecessaryManHourWeek, setTmpNecessaryManHourWeek] =
    React.useState(0);
  const [tmpShortageManHourWeek, setTmpShortageManHourWeek] = React.useState(0);
  const [tmpEmployee, setTmpEmployee] = React.useState(0);
  const [tmpRecruit, setTmpRecruit] = React.useState(0);
  const [tmpRetire, setTmpRetire] = React.useState(0);

  const [agreementDate, setAgreementDate] = React.useState<DateTime | null>(
    null
  );
  const [convertAgreementDate, setConvertAgreementDate] = React.useState("");
  const [lastAgreementDate, setLastAgreementDate] = React.useState("");
  const [agreementOldFlg, setAgreementOldFlg] = React.useState(false);

  const [regulationDate, setRegulationDate] = React.useState<DateTime | null>(
    null
  );
  const [convertRegulationDate, setConvertRegulationDate] = React.useState("");
  const [lastRegulationDate, setLastRegulationDate] = React.useState("");

  const [lastNecessaryManHourDate, setLastNecessaryManHourDate] =
    React.useState(0);
  const [lastNecessaryManHourWeek, setLastNecessaryManHourWeek] =
    React.useState(0);
  const [lastShortageManHourDate, setLastShortageManHourDate] =
    React.useState(0);
  const [lastShortageManHourWeek, setLastShortageManHourWeek] =
    React.useState(0);
  const [lastEmployee, setLastEmployee] = React.useState(0);
  const [lastRecruit, setLastRecruit] = React.useState(0);
  const [lastRetire, setLastRetire] = React.useState(0);
  const [task, setTask] = React.useState("");
  const [measures, setMeasures] = React.useState("");
  const [rowItemList, setRowItemList] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);

  const [save, setSave] = React.useState(false);

  const [approvalDate, setApprovalDate] = React.useState<DateTime | null>(null);
  const [convertedApprovalDate, setConvertedApprovalDate] = React.useState<
    string | null
  >(null);
  const [lastApprovalDate, setLastApprovalDate] = React.useState("");
  const [lastDateTitle, setLastDateTitle] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [userPrivilege, setUserPrivilege] = React.useState("");

  useEffect(() => {
    setRowItemList([]);
    setApprovalDate(null);
    setConvertedApprovalDate("");
    setApproveDay("");
    setAgreementDay("");
    setRegulationDay("");
    setChangeFlg(false);
    initailizeFlag = false;
  }, [tenpoCode, getFirstFlg()]);

  useEffect(() => {
    setLoading(true);
    userLogging("OneVision", "新規作成#初期表示", oneVisionId);
    setUserPrivilege(user.jobCategory);
    setRowItemList([]);
    initailizeFlag = false;
    setApprovalDate(null);
    setConvertedApprovalDate("");
    setApproveDay(convertedApprovalDate);
    setAgreementDay(convertAgreementDate);
    setRegulationDay(convertRegulationDate);
    getOneVisionData(tenpoCode, oneVisionId, lastOneVisionId).then(
      (res: any) => {
        setApprovalDate(
          res.approve_day
            ? DateTime.fromFormat(res.approve_day, "yyyyMMdd")
            : null
        );
        setConvertedApprovalDate(res.approve_day);
        setApproveDay(res.approve_day);
        setRowItemList(res.rows);
        setLastApprovalDate(res.last_approve_day);

        // 三六協定
        let nowDate: DateTime = DateTime.local();
        setLastAgreementDate(res.last_agreement_day);

        let initAgreementDate = null;
        let initConvertAgreementDate = null;
        if (res.agreement_day) {
          initAgreementDate = DateTime.fromFormat(
            res.agreement_day,
            "yyyyMMdd"
          );
          initConvertAgreementDate = res.agreement_day;
        } else if (res.last_agreement_day) {
          initAgreementDate = DateTime.fromFormat(
            res.last_agreement_day,
            "yyyyMMdd"
          );
          initConvertAgreementDate = res.last_agreement_day;
        } else {
          initAgreementDate = DateTime.local();
          initConvertAgreementDate = nowDate.toFormat("yyyyMMdd");
        }
        let alertDate = DateTime.local(
          initAgreementDate.year + 1,
          initAgreementDate.month,
          initAgreementDate.day,
          0,
          0
        );
        if (nowDate > alertDate) {
          setAgreementOldFlg(true);
        } else {
          setAgreementOldFlg(false);
        }
        setAgreementDate(initAgreementDate);
        setConvertAgreementDate(initConvertAgreementDate);
        setAgreementDay(initConvertAgreementDate);

        let initRegulationDate = null;
        let initConvertRegulationDate = null;
        if (res.regulation_day) {
          initRegulationDate = DateTime.fromFormat(
            res.regulation_day,
            "yyyyMMdd"
          );
          initConvertRegulationDate = res.regulation_day;
        } else if (res.last_regulation_day) {
          initRegulationDate = DateTime.fromFormat(
            res.last_regulation_day,
            "yyyyMMdd"
          );
          initConvertRegulationDate = res.last_regulation_day;
        } else {
          initRegulationDate = DateTime.local();
          initConvertRegulationDate = nowDate.toFormat("yyyyMMdd");
        }

        setLastRegulationDate(res.last_regulation_day);
        setRegulationDate(initRegulationDate);
        setConvertRegulationDate(initConvertRegulationDate);
        setRegulationDay(initConvertRegulationDate);

        setLastDateTitle(
          res.last_approve_day
            ? DateTime.fromFormat(res.last_approve_day, "yyyyMMdd").toFormat(
                "MM月dd日"
              )
            : "-"
        );
        const others = res.other_data;
        setTask(others.task);
        setMeasures(others.measures);
        setLastNecessaryManHourDate(others.last_necessary_man_hour_date);
        setLastNecessaryManHourWeek(others.last_necessary_man_hour_week);
        setLastShortageManHourDate(others.last_shortage_man_hour_date);
        setLastShortageManHourWeek(others.last_shortage_man_hour_date);
        setLastEmployee(others.last_employee);
        setLastRecruit(others.last_recruit);
        setLastRetire(others.last_retire);
        setNecessaryManHourDate(others.necessary_man_hour_date);
        setNecessaryManHourWeek(others.necessary_man_hour_week);
        setShortageManHourDate(others.shortage_man_hour_date);
        setShortageManHourWeek(others.shortage_man_hour_week);
        setEmployee(others.employee);
        setRecruit(others.recruit);
        setRetire(others.retire);
        setTmpNecessaryManHourWeek(others.necessary_man_hour_week);
        setTmpShortageManHourWeek(others.shortage_man_hour_week);
        setTmpEmployee(others.employee);
        setTmpRecruit(others.recruit);
        setTmpRetire(others.retire);
        initailizeFlag = true;
        setLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVisionId]);

  useEffect(() => {
    if (getFixedFlg()) return;

    // OneVisionの値に変更があった時に呼び出されるReactHooks
    if (!rowItemList || !initailizeFlag) return;
    let others: ONEVISIONOTHERDATA = {
      task: task || "",
      measures: measures || "",
      last_necessary_man_hour_date: Number(lastNecessaryManHourDate) || 0,
      last_necessary_man_hour_week: Number(lastNecessaryManHourWeek) || 0,
      last_shortage_man_hour_date: Number(lastShortageManHourDate) || 0,
      last_shortage_man_hour_week: Number(lastShortageManHourWeek) || 0,
      last_employee: Number(lastEmployee) || 0,
      last_recruit: Number(lastRecruit) || 0,
      last_retire: Number(lastRetire) || 0,
      necessary_man_hour_date: Number(necessaryManHourDate) || 0,
      necessary_man_hour_week: Number(necessaryManHourWeek) || 0,
      shortage_man_hour_date: Number(shortageManHourDate) || 0,
      shortage_man_hour_week: Number(shortageManHourWeek) || 0,
      employee: Number(employee) || 0,
      recruit: Number(recruit) || 0,
      retire: Number(retire) || 0,
    };
    updateOneVisionFirestore(
      rowItemList,
      oneVisionId,
      lastOneVisionId,
      tenpoCode,
      others,
      convertedApprovalDate || "",
      lastApprovalDate || "",
      userPrivilege,
      convertAgreementDate || "",
      convertRegulationDate || "",
      lastAgreementDate || "",
      lastRegulationDate || ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowItemList, save]);

  const handleClick = () => {
    setClick((prev) => !click);
  };
  const handleSave = () => {
    setSave((prev) => !save);
  };

  const handleApprovalDate = (date: DateTime | null) => {
    if (!initailizeFlag) return date;
    setChangeFlg(true);
    setConvertedApprovalDate(date ? date.toFormat("yyyyMMdd") : "");
    setApproveDay(date ? date.toFormat("yyyyMMdd") : "");
    setApprovalDate(date);
    handleSave();
    return date;
  };

  const handleAgreementDate = (date: DateTime | null) => {
    if (!initailizeFlag) return date;

    if (date) {
      let nowDate = DateTime.local();
      let alertDate = DateTime.local(date.year + 1, date.month, date.day, 0, 0);
      if (nowDate > alertDate) {
        setAgreementOldFlg(true);
      } else {
        setAgreementOldFlg(false);
      }
    }
    setChangeFlg(true);
    setAgreementDate(date);
    setConvertAgreementDate(date ? date.toFormat("yyyyMMdd") : "");
    setAgreementDay(date ? date.toFormat("yyyyMMdd") : "");
    handleSave();
    return date;
  };
  const handleRegulationDate = (date: DateTime | null) => {
    if (!initailizeFlag) return date;
    setChangeFlg(true);
    setRegulationDate(date);
    setConvertRegulationDate(date ? date.toFormat("yyyyMMdd") : "");
    setRegulationDay(date ? date.toFormat("yyyyMMdd") : "");
    handleSave();
    return date;
  };

  const initApproveDate = () => {
    if (approvalDate !== null) return approvalDate;
    let date = DateTime.local();
    setChangeFlg(true);
    setConvertedApprovalDate(date ? date.toFormat("yyyyMMdd") : "");
    setApproveDay(date ? date.toFormat("yyyyMMdd") : "");
    setApprovalDate(date);
    return approvalDate;
  };

  const initAgreementDate = () => {
    if (agreementDate !== null) return agreementDate;
    let date = DateTime.local();
    setAgreementDate(date);
    setConvertAgreementDate(date ? date.toFormat("yyyyMMdd") : "");
    setAgreementDay(date ? date.toFormat("yyyyMMdd") : "");
    return agreementDate;
  };

  const initRegulationDate = () => {
    if (regulationDate !== null) return regulationDate;
    let date = DateTime.local();
    setRegulationDate(date);
    setConvertRegulationDate(date ? date.toFormat("yyyyMMdd") : "");
    setRegulationDay(date ? date.toFormat("yyyyMMdd") : "");
    return regulationDate;
  };

  function zenkakuReplace(str: string) {
    return str.replace(/[０-９．]/g, function (s: string) {
      let result = String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      return result;
    });
  }

  function checkNumber(str: string) {
    if (Number(str)) {
      return true;
    } else if (Number(str) === 0) {
      return true;
    }
    return false;
  }

  const blurEmployee = (people: number, changed: boolean) => {
    if (!people && people !== 0) {
      setTmpEmployee(employee);
      return;
    }
    if (changed) {
      setChangeFlg(true);
    }
    setTmpEmployee(people);
    setEmployee(people);
    handleSave();
  };

  const blurRecruit = (people: number, changed: boolean) => {
    if (!people && people !== 0) {
      setTmpRecruit(recruit);
      return;
    }
    if (changed) {
      setChangeFlg(true);
    }
    setTmpRecruit(people);
    setRecruit(people);
    handleSave();
  };

  const blurRetire = (people: number, changed: boolean) => {
    if (!people && people !== 0) {
      setTmpRetire(retire);
      return;
    }
    if (changed) {
      setChangeFlg(true);
    }
    setTmpRetire(people);
    setRetire(people);
    handleSave();
  };

  const blurNecessaryWeek = (date: number, changed: boolean) => {
    if (!date && date !== 0) {
      setTmpNecessaryManHourWeek(necessaryManHourWeek);
      return;
    }
    setTmpNecessaryManHourWeek(date);
    setNecessaryManHourWeek(date);
    if (changed) {
      setChangeFlg(true);
      if (date < 126) {
        let messages = [];
        messages.push("入力内容を確認してください。");
        messages.push("全時間帯2人時の想定で336人時です。");
        messages.push("【下限アラート】：126人時／週");
        messages.push("1時間当たり0.75人程度が働いている想定です。");
        messages.push("問題がない場合は、「OK」を押してから");
        messages.push("再度数値を入力してください。");
        setErrorMessage(messages);
        setOpen(true);
        return;
      }
      if (date > 630) {
        let messages = [];
        messages.push("入力内容を確認してください。");
        messages.push("全時間帯2人時の想定で336人時です。");
        messages.push("【上限アラート】：630人時／週");
        messages.push("1時間当たり3.75人程度が働いている想定です。");
        messages.push("問題がない場合は、「OK」を押してから");
        messages.push("再度数値を入力してください。");
        setErrorMessage(messages);

        setOpen(true);
        return;
      }
    }

    setNecessaryManHourDate(Math.round(date / 7));

    handleSave();
    return;
  };
  const blurShortageWeek = (date: number, changed: boolean) => {
    if (!date && date !== 0) {
      setTmpShortageManHourWeek(shortageManHourWeek);
      return;
    }

    let maxNum: number = Number(necessaryManHourWeek);
    setTmpShortageManHourWeek(date);
    setShortageManHourWeek(date);
    if (changed) {
      setChangeFlg(true);
      if (maxNum < date) {
        let messages = [];
        messages.push("入力内容が間違っています。");
        messages.push("不足人時が必要人時を超えています。");
        messages.push("「OK」を押してから再度数値を入力してください。");
        setErrorMessage(messages);

        setOpen(true);
        return;
      }
    }
    setShortageManHourDate(Math.round(date / 7));
    handleSave();
  };

  const doSelChange = (
    row: ONEVISIONRECORD,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    row.score =
      event.target.value || event.target.value === 0
        ? (event.target.value as string)
        : "";
    setChangeFlg(true);
    handleClick();
    setRowItemList(updateOneVision(rowItemList, row));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const taskBlur = (comment: string, inputChangeFlg: boolean) => {
    if (inputChangeFlg) {
      setChangeFlg(true);
    }
    setTask(comment);
    handleSave();
  };
  const measureBlur = (comment: string, inputChangeFlg: boolean) => {
    if (inputChangeFlg) {
      setChangeFlg(true);
    }
    setMeasures(comment);
    handleSave();
  };

  // テキスト入力フィールド
  const InputTextField: React.FC<{
    comment: string;
    blurEvent: (comment: string, changeFlg: boolean) => void;
    fixedFlg: boolean;
  }> = ({ comment, blurEvent, fixedFlg }) => {
    const [inputComment, setInputComment] = React.useState(String(comment));
    const [inputChangeFlg, setInputChangeFlg] = React.useState(false);
    const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
      let comment = event.target.value as string;
      if (comment && Number(comment.length) > 400) {
        comment = comment.substring(0, 400);
      }
      setInputChangeFlg(true);
      setInputComment(comment);
    };

    const blurComment = () => {
      blurEvent(String(inputComment), inputChangeFlg);
    };

    return (
      <TextField
        multiline
        rows={3}
        variant="outlined"
        fullWidth={true}
        onChange={(e) => changeComment(e)}
        onBlur={() => blurComment()}
        value={inputComment}
        disabled={fixedFlg}
      />
    );
  };

  // 人時入力フィールド
  const InputManHourField: React.FC<{
    manHour: number;
    blurEvent: (manHour: number, changeFlg: boolean) => void;
    fixedFlg: boolean;
  }> = ({ manHour, blurEvent, fixedFlg }) => {
    const [inputManHour, setInputManHour] = React.useState(manHour);
    const [inputChangeFlg, setInputChangeFlg] = React.useState(false);
    const maxInt = 99999999999999;
    const minInt = 0;
    const changeManHour = (event: React.ChangeEvent<{ value: unknown }>) => {
      let changedManHour: number = Number(
        zenkakuReplace(event.target.value as string)
      );
      if (!changedManHour && changedManHour !== 0) {
        return;
      }
      if (changedManHour >= maxInt) {
        changedManHour = maxInt;
      }
      if (changedManHour <= minInt) {
        changedManHour = minInt;
      }
      setInputChangeFlg(true);
      setInputManHour(changedManHour);
    };
    const blurManHour = () => {
      blurEvent(inputManHour, inputChangeFlg);
    };

    return (
      <OutlinedInput
        type="text"
        multiline={false}
        endAdornment={
          <span style={{ width: "80px", fontSize: "12px" }}>人時／週</span>
        }
        value={inputManHour}
        onChange={(e) => changeManHour(e)}
        onBlur={() => blurManHour()}
        disabled={fixedFlg}
      ></OutlinedInput>
    );
  };

  // 人数入力フィールド
  const InputPeopleField: React.FC<{
    people: number;
    blurEvent: (manHour: number, changeFlg: boolean) => void;
    fixedFlg: boolean;
  }> = ({ people, blurEvent, fixedFlg }) => {
    const [inputPeople, setInputPeople] = React.useState(people);
    const [inputChangeFlg, setInputChangeFlg] = React.useState(false);
    const maxInt = 999999999999999;
    const minInt = 0;
    const changePeople = (event: React.ChangeEvent<{ value: unknown }>) => {
      let changedPeople: number = Number(
        zenkakuReplace(event.target.value as string)
      );
      if (!changedPeople && changedPeople !== 0) {
        return;
      }
      if (changedPeople >= maxInt) {
        changedPeople = maxInt;
      }
      if (changedPeople <= minInt) {
        changedPeople = minInt;
      }
      setInputChangeFlg(true);
      setInputPeople(changedPeople);
    };
    const blurPeople = () => {
      blurEvent(inputPeople, inputChangeFlg);
    };

    return (
      <OutlinedInput
        type="text"
        endAdornment="人"
        value={inputPeople}
        onChange={(e) => changePeople(e)}
        onBlur={() => blurPeople()}
        disabled={fixedFlg}
      ></OutlinedInput>
    );
  };

  // 画面スクロール
  const moveScroll = (fieldName: string, bottomFlg?: boolean) => {
    let element = document?.getElementById(fieldName);
    if (element !== null) {
      if (bottomFlg) {
        element?.scrollIntoView(false);
      } else {
        element.scrollIntoView();
      }
    }
  };

  // 保存ボタンクリック処理
  const clickConfirmButton = () => {
    userLogging("OneVision", "新規作成#保存ボタン", "");
    if (getFixedFlg()) return;
    //保存処理
    setChangeFlg(false);
    handleSave();
    handleClick();
  };

  function getCheckRecord(rowItem: any, index: number) {
    return (
      <>
        {Object.keys(rowItem.rows).length !== 0 ? (
          <>
            {/* 大項目名↓ここから */}
            <Grid container justify="center" className={classes.header}>
              <Grid container justify="center">
                <Typography variant="h4">{rowItem.big_title}</Typography>
              </Grid>
            </Grid>
            {/* 大項目名↑ここまで */}
            <Grid container className={classes.tabBase}>
              {/* タイトル行↓ここから */}
              <Grid container>
                <Grid item md={10} sm={9} className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight}
                    justify="center"
                    alignItems="center"
                  >
                    <Typography variant="h6">チェック項目</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight}
                    justify="center"
                    alignItems="center"
                  >
                    <Typography variant="h6">前回</Typography>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.columnHeader}>
                  <Grid
                    container
                    className={classes.headerHeight}
                    justify="center"
                    alignItems="center"
                  >
                    <Typography variant="h6">今回</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* タイトル行↑ここまで */}

              {/* データ行↓ここから */}
              <Grid container>
                {rowItem.rows.map((row: any, indexRowItem: number) => (
                  <>
                    {/* チェック項目↓ここから */}
                    <Grid
                      container
                      className={classes.columnResult}
                      key={row.key + "_caption_A"}
                      md={10}
                      sm={9}
                    >
                      <OneVisionModalManual
                        title={row.small_title1}
                        title2={row.small_title2}
                        filePath={row.photo_filepath_gcs}
                      />
                    </Grid>
                    {/* チェック項目↑ここまで */}
                    {/* 前回↓ここから */}
                    <Grid
                      container
                      xs={1}
                      className={
                        classes.columnHeight + " " + classes.columnResult
                      }
                      justify="center"
                      alignItems="center"
                    >
                      <Typography style={{ textAlign: "center" }}>
                        {row.last_score
                          ? checkNumber(row.score_pattern[0].name)
                            ? row.last_score + "点"
                            : row.score_pattern.map((evl: any) =>
                                evl.value === row.last_score ? evl.name : ""
                              )
                          : ""}
                      </Typography>
                    </Grid>
                    {/* 前回↑ここまで */}
                    {/* 今回↓ここから */}
                    <Grid
                      container
                      xs
                      className={
                        classes.columnData + " " + classes.columnHeight
                      }
                      justify="center"
                      alignItems="center"
                    >
                      <Typography
                        style={{ textAlign: "center", height: "auto" }}
                      >
                        <Select
                          value={row.score}
                          onChange={(event) => doSelChange(row, event)}
                          readOnly={getFixedFlg()}
                        >
                          {row.score_pattern.map((evl: any) => (
                            <MenuItem value={evl.value}>
                              {evl.name || <em>　</em>}
                            </MenuItem>
                          ))}
                        </Select>
                        {checkNumber(row.score_pattern[0].name) ? "点" : ""}
                      </Typography>
                    </Grid>
                    {/* 今回↑ここまで */}
                  </>
                ))}
              </Grid>
              {/* データ行↑ここまで */}
            </Grid>
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <>
      {/* メインコンテンツ↓ここから */}
      <Grid container className={classes.contentFrame}>
        {loading && <div className={classes.overLayer}></div>}
        {loading && (
          <CircularProgress className={classes.circularProgress} size={120} />
        )}

        {rowItemList && rowItemList.length > 0 ? (
          <>
            <Grid id="topField" />
            {/* 作成日↓ここから */}
            <Grid container>
              <Grid item lg={9} md={8} sm={8}>
                <Grid container justify="flex-end" alignItems="center">
                  <Typography variant="h6">作成日</Typography>
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={4}>
                <Grid container justify="center" alignItems="center">
                  {getFixedFlg() ? (
                    approvalDate ? (
                      <Typography variant="h6">
                        {approvalDate.toFormat("yyyy/MM/dd") +
                          "(" +
                          getDayOfWeekText(approvalDate) +
                          ")"}
                      </Typography>
                    ) : (
                      ""
                    )
                  ) : (
                    <DatePicker
                      selectedDate={initApproveDate()}
                      dateFunc={(date: DateTime | null) => {
                        handleApprovalDate(date);
                      }}
                      format={
                        "yyyy年MM月dd日" +
                        "(" +
                        getDayOfWeekText(approvalDate) +
                        ")"
                      }
                      minDate={DateTime.fromFormat(
                        lastApprovalDate,
                        "yyyyMMdd"
                      )}
                      mainColor={"#4dc0b2"}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={9} md={8} sm={8}>
                <Grid container justify="flex-end" alignItems="center">
                  <Typography variant="h6">前回作成日</Typography>
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={4}>
                <Grid container justify="center" alignItems="center">
                  <Typography variant="h6">{lastDateTitle}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* 作成日↑ここまで */}
          </>
        ) : (
          ""
        )}
        {loading && <div className={classes.overLayer}></div>}
        {loading && (
          <CircularProgress className={classes.circularProgress} size={120} />
        )}

        {/* チェック表↓ここから */}
        <Grid container justify="center">
          {/* 項目 */}
          {rowItemList.map((rowItem, index) => getCheckRecord(rowItem, index))}
        </Grid>
        {/* チェック表↑ここまで */}

        <Grid style={{ padding: "10px" }}></Grid>
        {/* 課題対策入力欄↓ここから */}
        {rowItemList && rowItemList.length > 0 ? (
          <>
            <>
              <Grid id="bottomField" />
              <Grid container style={{ padding: "5px" }}>
                <Grid item lg={1} md={1} sm={1}>
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="h6">課題</Typography>
                  </Grid>
                </Grid>
                <Grid item lg={10} md={10} sm={10}>
                  <Grid container justify="center" alignItems="center">
                    <InputTextField
                      comment={task}
                      blurEvent={taskBlur}
                      fixedFlg={getFixedFlg()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ padding: "5px" }}>
                <Grid item lg={1} md={1} sm={1}>
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="h6">対策</Typography>
                  </Grid>
                </Grid>
                <Grid item lg={10} md={10} sm={10}>
                  <Grid container justify="center" alignItems="center">
                    <InputTextField
                      comment={measures}
                      blurEvent={measureBlur}
                      fixedFlg={getFixedFlg()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
            {/* 課題対策入力欄↑ここまで */}

            <Grid style={{ padding: "10px" }}></Grid>

            {/* 前回人時↓ここから */}
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                justify="center"
                alignItems="center"
                lg={5}
                md={5}
                sm={6}
                style={{ paddingRight: "5px" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                    >
                      <Typography variant="inherit">必要/不足人時</Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                    >
                      <Typography variant="inherit">単位：人時/日</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="inherit">(前回)必要人時</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="inherit">
                        {lastNecessaryManHourDate}人時／日
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="inherit">(前回)不足人時</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="inherit">
                        {lastShortageManHourDate}人時／日
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
              <Grid
                item
                justify="center"
                alignItems="center"
                lg={5}
                md={5}
                sm={6}
                style={{ paddingLeft: "5px" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      className={classes.tableCell + " " + classes.weekHeader}
                    >
                      <Typography variant="inherit">必要/不足人時</Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.weekHeader}
                    >
                      <Typography variant="inherit">単位：人時/週</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="inherit">(前回)必要人時</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="inherit">
                        {lastNecessaryManHourWeek}人時／週
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="inherit">(前回)不足人時</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="inherit">
                        {lastShortageManHourWeek}人時／週
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>
            {/* 前回人時ここまで */}

            <Grid style={{ padding: "10px" }}></Grid>

            {/* 今回人時↓ここから */}
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                justify="center"
                alignItems="center"
                lg={5}
                md={5}
                sm={6}
                style={{ paddingRight: "5px" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                    >
                      <Typography variant="inherit">必要/不足人時</Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                    >
                      <Typography variant="inherit">単位：人時/日</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">(今回)必要人時</Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        {necessaryManHourDate}人時／日
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">(今回)不足人時</Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        {shortageManHourDate}人時／日
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
              <Grid
                item
                justify="center"
                alignItems="center"
                lg={5}
                md={5}
                sm={6}
                style={{ paddingLeft: "5px" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      className={classes.tableCell + " " + classes.weekHeader}
                      style={{ minWidth: "120px" }}
                    >
                      <Typography variant="inherit">必要/不足人時</Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.weekHeader}
                    >
                      <Typography variant="inherit">単位：人時/週</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">(今回)必要人時</Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <InputManHourField
                        manHour={tmpNecessaryManHourWeek}
                        blurEvent={blurNecessaryWeek}
                        fixedFlg={getFixedFlg()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">(今回)不足人時</Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <InputManHourField
                        manHour={tmpShortageManHourWeek}
                        blurEvent={blurShortageWeek}
                        fixedFlg={getFixedFlg()}
                      />
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>
            {/* 今回人時ここまで */}

            <Grid style={{ padding: "10px" }}></Grid>
            {/* 従業員人数↓ここから */}
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                justify="center"
                alignItems="center"
                lg={5}
                md={5}
                sm={6}
                style={{ paddingRight: "5px" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                    >
                      <Typography variant="inherit">従業員人数</Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                    >
                      <Typography variant="inherit">単位：人</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        (前回)従業員人数
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        {lastEmployee}人
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">(前回)採用人数</Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">{lastRecruit}人</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">(前回)退職人数</Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">{lastRetire}人</Typography>
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
              <Grid
                item
                justify="center"
                alignItems="center"
                lg={5}
                md={5}
                sm={6}
                style={{ paddingLeft: "5px" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                    >
                      <Typography variant="inherit">従業員人数</Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                    >
                      <Typography variant="inherit">単位：人</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        (直近)従業員人数
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        <Typography variant="inherit">
                          <InputPeopleField
                            people={tmpEmployee}
                            blurEvent={blurEmployee}
                            fixedFlg={getFixedFlg()}
                          />
                        </Typography>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        (年間累計)採用人数
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="h6">
                        <InputPeopleField
                          people={tmpRecruit}
                          blurEvent={blurRecruit}
                          fixedFlg={getFixedFlg()}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        (年間累計)退職人数
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="h6">
                        <InputPeopleField
                          people={tmpRetire}
                          blurEvent={blurRetire}
                          fixedFlg={getFixedFlg()}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>

            <Grid style={{ padding: "10px" }}></Grid>
            {/* 直近の届日↓ここから */}
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                justify="center"
                alignItems="center"
                lg={10}
                md={12}
                sm={12}
                style={{ paddingRight: "5px" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeader}
                      colSpan={3}
                    >
                      <Typography variant="inherit">
                        三六協定・就業規則
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">三六協定</Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography>
                        前回の提出日：
                        {lastAgreementDate
                          ? DateTime.fromFormat(
                              lastAgreementDate,
                              "yyyyMMdd"
                            ).toFormat("yyyy年MM月dd日")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        {getFixedFlg() ? (
                          agreementDate ? (
                            <Typography variant="h6">
                              {agreementDate.toFormat("yyyy/MM/dd")}
                            </Typography>
                          ) : (
                            ""
                          )
                        ) : (
                          <DatePicker
                            selectedDate={initAgreementDate()}
                            dateFunc={(date: DateTime | null) => {
                              handleAgreementDate(date);
                            }}
                            format={"yyyy年MM月dd日"}
                            minDate={DateTime.fromFormat(
                              "20200101",
                              "yyyyMMdd"
                            )}
                            backgroundColor={agreementOldFlg ? "#ffddcf" : ""}
                            mainColor={"#4dc0b2"}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">就業規則</Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography>
                        前回の提出日：
                        {lastRegulationDate
                          ? DateTime.fromFormat(
                              lastRegulationDate,
                              "yyyyMMdd"
                            ).toFormat("yyyy年MM月dd日")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.inputTableCell}>
                      <Typography variant="inherit">
                        {getFixedFlg() ? (
                          regulationDate ? (
                            <Typography variant="h6">
                              {regulationDate.toFormat("yyyy/MM/dd")}
                            </Typography>
                          ) : (
                            ""
                          )
                        ) : (
                          <DatePicker
                            selectedDate={initRegulationDate()}
                            dateFunc={(date: DateTime | null) => {
                              handleRegulationDate(date);
                            }}
                            format={"yyyy年MM月dd日"}
                            minDate={DateTime.fromFormat(
                              "20200101",
                              "yyyyMMdd"
                            )}
                            mainColor={"#4dc0b2"}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
      {/* コンテンツフッター↓ここから */}
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.footer}
      >
        <IconButton
          className={classes.upDownButton}
          style={{ color: "#ffffff", backgroundColor: "#00CC5B" }}
          onClick={() => moveScroll("topField")}
        >
          <VerticalAlignTop></VerticalAlignTop>
        </IconButton>
        <IconButton
          className={classes.upDownButton}
          style={{ color: "#ffffff", backgroundColor: "#00CC5B" }}
          onClick={() => moveScroll("bottomField")}
        >
          <VerticalAlignBottom></VerticalAlignBottom>
        </IconButton>
        {getFixedFlg() ? (
          ""
        ) : (
          <Button
            variant={"contained"}
            color={changeFlg ? "secondary" : "primary"}
            onClick={() => clickConfirmButton()}
          >
            保存
          </Button>
        )}
      </Grid>
      {/* コンテンツフッター↑ここまで */}

      {/* 入力エラーダイアログ↓ここから */}
      <OneVisionDialog
        msg={errorMessage}
        isOpen={open}
        doOK={handleClose}
        doNo={handleClose}
        isAlert={true}
        isClose={true}
      />
      {/* 入力エラーダイアログここまで */}
    </>
  );
};
export default OneVisionTable;
