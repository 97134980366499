import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../Header/Header";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Grid, Modal } from "@material-ui/core";
import { Button, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import DatePicker from "../../common/DatePicker";
import { DateTime } from "luxon";
import TSCheckFilingStatusConfirmResult from "./TSCheckFilingStatusConfirmResult";
import { selectUser } from "../../../features/userSlice";
import { getAllZone, getAllDo } from "../../../data/existingSejStores";
import { postRequest } from "../../../apis/axiosAction";
import SearchZO from "../../organisms/search/searchZO/SearchZO";
import { stylesSearchDO, stylesSearchZO } from "../Search/StylesTSCheckSearch";
import {
  getAuthTenpoCheck,
  onlyArea,
} from "../../organisms/search/specification/UsageAuthority/AuthTenpoCheck";
import {
  updateTenpoName,
  updateZoCode,
  updateZoName,
  updateDoCode,
  updateDoName,
  updateTsCheckFilingWeekList,
  updateTsCheckFilingDataList,
  selectTsCheckFilingWeekList,
  selectTsCheckFilingDataList,
} from "../../organisms/search/TenpoCheckSearchSlice";
import SearchDO from "../../organisms/search/searchDO/SearchDO";
import { userLogging } from "../../../apis/userLog";
import TSCheckDialog from "../TSCheckDialog";
import { Redirect } from "react-router-dom";
import { isFirebaseAuth } from "../../../firebase";
import { isOpenStatusConfirmPage } from "../../../apis/privilege";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
  },
  modalPane: {
    position: "absolute",
    paddingTop: "2%",
    paddingLeft: "15%",
    width: "60vw",
    minWidth: "600px",
    height: "80vh",
  },
  contentFrame: {
    marginTop: "10px",
    height: "85vh",
    overflowY: "auto",
  },
  searchArea: {
    border: "solid",
    flex: 1,
    margin: "auto",
    alignItems: "center",
    width: "60vw",
    backgroundColor: "#fff",
    paddingBottom: "15px",
  },
  searchItem: {
    marginTop: "5px",
    marginLeft: "10px",
    marginBottom: "10px",
  },
  unCheckButton: {
    width: "100px",
    color: "black",
    backgroundColor: "#F2F2F2",
    "&:hover": {},
  },
  checkedButton: {
    width: "100px",
    color: "#ffffff",
    outlineColor: "#3e3e3e",
    backgroundColor: "#4dc0b2",
    "&:hover": {
      backgroundColor: "#4ab5a1",
    },
  },
  excuteButton: {
    color: "#ffffff",
    backgroundColor: "#4dc0b2",
    fontSize: "20px",
    marginLeft: "4vw",
    "&:hover": {
      backgroundColor: "#4ab5a1",
    },
  },
  tableBottom: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: "1px",
    width: "80vw",
  },
}));

function setSelectItem(code: string, name: string) {
  return { code, name };
}

let aggregation = false;

const FilingStatusConfirm: React.FC = (props: any) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const stateWeekList = useSelector(selectTsCheckFilingWeekList);
  const stateDataList = useSelector(selectTsCheckFilingDataList);
  const [click, setClick] = React.useState(false);
  const [aggregationType, setAggregationType] = React.useState(true);
  const [zoCode, setZoCode] = React.useState("");
  const [doCode, setDoCode] = React.useState("");
  const [executeFlg, setExecuteFlg] = React.useState("");
  const [selectedBaseDate, setSelectedBaseDate] =
    React.useState<DateTime | null>(DateTime.local());
  const [convertedFirstValue, setConvertedFirstValue] = React.useState<
    string | null
  >(DateTime.local().toFormat("yyyy/MM/dd HH:mm:ss"));
  const [searchButtonFlg, setsearchButtonFlg] = React.useState(false);
  const zoneMaster = getAllZone().zone_data;
  const doMaster = getAllDo().do_data;
  const dispatch = useDispatch();
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [csvColumnsList, setCsvColumnsList] = React.useState<any[]>([]);

  const dialogClose = () => {
    setDialogFlg(false);
  };

  let zoList = [setSelectItem("", "")];
  let doList = [setSelectItem("", "")];
  for (let i = 0; i < user.zoCode.length; i++) {
    if (zoneMaster[user.zoCode[i]]) {
      zoList.push(
        setSelectItem(user.zoCode[i], zoneMaster[user.zoCode[i]].zone_name)
      );
    }
  }
  for (let i = 0; i < user.doCode.length; i++) {
    if (doMaster[user.doCode[i]]) {
      doList.push(
        setSelectItem(user.doCode[i], doMaster[user.doCode[i]].do_name)
      );
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // matelial-tableの表示を更新
    setOpen(false);
  };
  const handleBaseDate = (date: DateTime | null) => {
    setSelectedBaseDate(date);
    date !== null &&
      setConvertedFirstValue(date.toFormat("yyyy/MM/dd HH:mm:ss"));
  };

  const aggregationCheck = (flag: boolean) => {
    setAggregationType(flag);
  };

  const handleClick = () => {
    setClick((prev) => !click);
  };

  // 初期表示処理
  useEffect(() => {
    userLogging("店舗衛生点検表♯提出状況確認#初期表示", "");
    dispatch(
      updateTenpoName({
        tenpoName: "",
      })
    );
    dispatch(
      updateZoCode({
        zoCode: "",
      })
    );
    dispatch(
      updateZoName({
        zoName: "",
      })
    );
    dispatch(
      updateDoCode({
        doCode: "",
      })
    );
    dispatch(
      updateDoName({
        doName: "",
      })
    );
    if (stateDataList.length === 0) {
      // 初期検索
      if (user.jobCategory === "dm") {
        doExecute(0);
      } else if (user.jobCategory === "zm") {
        doExecute(1);
      }
    } else {
      setExecuteFlg("1");
    }
  }, [dispatch]);

  // 検索ボタンの制御
  useEffect(() => {
    if (zoCode !== "" || doCode !== "") {
      setsearchButtonFlg(true);
    } else {
      setsearchButtonFlg(false);
    }
  }, [zoCode, doCode]);
  
  // 画面表示可否
  if (!isFirebaseAuth()) {
    return <></>;
  }
  if (!isOpenStatusConfirmPage(user)) {
    return <Redirect to="/home" />;
  }

  // コード順に並べ変え
  function sortCode(dataMapList: any[]) {
    let mapList: any[] = dataMapList;
    return mapList.sort(function (first, second) {
      if (first.docode > second.docode) {
        return 1;
      } else if (first.docode < second.docode) {
        return -1;
      } else {
        if (first.code > second.code) {
          return 1;
        } else if (first.code < second.code) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

  function setTargetDateList(weekList: any[]) {
    let columns: any[] = [];
    let csvColumns: any[] = [];
    csvColumns = [
      {
        title: "ZO",
        field: "zoname",
      },
      {
        title: "DO",
        field: "doname",
        type: "numeric",
      },
    ];
    columns = [
      {
        title: weekList[0],
        field: "name",
        cellStyle: {
          width: "300px",
        },
      },
      {
        title: weekList[1],
        field: "tenpo_count",
        type: "numeric",
        align: "center",
      },
    ];
    for (let i = 2; i < weekList.length; i++) {
      let no = weekList.length - i + 1;
      columns.push({
        title: weekList[no],
        field: "result" + Number(no - 1),
        align: "center",
        type: "numeric",
        sorting: false,
      });
    }

    for (let i = 0; i < columns.length; i++) {
      csvColumns.push(columns[i]);
    }
    setCsvColumnsList(csvColumns);

    return columns;
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && searchButtonFlg) {
      doExecute();
    }
  }

  // 実行ボタン押下時処理
  const doExecute = (initType?: number) => {
    if (initType && user.zoCode.length > 1) return;
    aggregation = aggregationType;
    const apiParams = {
      "api-name": "submit-check",
      aggregation_type: aggregationType ? "1" : "0",
      target_date: convertedFirstValue,
      tenpo_code: [""],
      zo_code: zoCode,
      do_code: doCode,
    };
    // 初期検索
    let initAggregationType = true;
    if (initType === 0) {
      // 集計方法は一旦OFCのみ
      // initAggregationType = false;
      // setAggregationType(false);
      // apiParams.aggregation_type = "0";
      apiParams.tenpo_code = user.tenpoCode;
    } else if (initType === 1) {
      // 集計方法は一旦OFCのみ
      // initAggregationType = false;
      // setAggregationType(false);
      // apiParams.aggregation_type = "0";
      apiParams.zo_code = user.zoCode[0];
    }
    const url = process.env.REACT_APP_GAE_API_URL + "tscheck/submit-check";

    userLogging("店舗衛生点検表♯提出状況確認#検索", JSON.stringify(apiParams));
    setExecuteFlg("2");
    postRequest(url, apiParams)
      .then((res) => {
        let dataList: any[] = res.data.dataList;
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let datajsList: any = dataList[i];
            let resWeeks: any = datajsList.week_list[0];
            let resResultList: any[] = datajsList.result_list;
            let resultWeekList: any[] = [];
            let resultDataList: any[] = [];
            if (initType) {
              if (initAggregationType) {
                resultWeekList.push("提出者");
              } else {
                resultWeekList.push("店舗名");
              }
            } else {
              if (aggregationType) {
                resultWeekList.push("提出者");
              } else {
                resultWeekList.push("店舗名");
              }
            }
            resultWeekList.push("店舗数");
            resultWeekList.push(resWeeks.week1);
            resultWeekList.push(resWeeks.week2);
            resultWeekList.push(resWeeks.week3);
            resultWeekList.push(resWeeks.week4);
            resultWeekList.push(resWeeks.week5);
            resultWeekList.push(resWeeks.week6);
            resultWeekList.push(resWeeks.week7);

            for (let j = 0; j < resResultList.length; j++) {
              resultDataList.push({
                zoname: resResultList[j].zoname || "",
                doname: resResultList[j].doname || "",
                name: resResultList[j].name,
                tenpo_count: resResultList[j].tenpo_count,
                result7: resResultList[j].result7,
                result6: resResultList[j].result6,
                result5: resResultList[j].result5,
                result4: resResultList[j].result4,
                result3: resResultList[j].result3,
                result2: resResultList[j].result2,
                result1: resResultList[j].result1,
                code: resResultList[j].code,
                docode: resResultList[j].docode,
              });
            }
            sortCode(resultDataList);

            let checkFilingWeekList = setTargetDateList(resultWeekList);
            dispatch(
              updateTsCheckFilingWeekList({
                tsCheckFilingWeekList: checkFilingWeekList,
              })
            );

            dispatch(
              updateTsCheckFilingDataList({
                tsCheckFilingDataList: resultDataList,
              })
            );
          }
        }
        setExecuteFlg("1");
        handleClick();
      })
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
        setExecuteFlg("0");
      })
      .finally(() => {
        handleClick();
      });

    handleClose();
    handleClick();
  };

  // 検索ボタン押下時処理
  function saveStatusBottun(
    argZoCode: string,
    argZoName: string,
    argDoCode: string,
    argDoName: string
  ) {
    dispatch(
      updateZoCode({
        zoCode: argZoCode,
      })
    );
    dispatch(
      updateZoName({
        zoName: argZoName,
      })
    );
    dispatch(
      updateDoCode({
        doCode: argDoCode,
      })
    );
    dispatch(
      updateDoName({
        doName: argDoName,
      })
    );
    setZoCode(argZoCode);
    setDoCode(argDoCode);
  }
  function clickSearch(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleOpen();
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalPane}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
      >
        <Grid container className={classes.searchArea}>
          {/*検索欄*/}
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#37AB9D",
              fontWeight: "bolder",
              textAlign: "center",
              height: "40px",
            }}
          >
            条件変更
          </Grid>
          {/*L1*/}
          <Grid container>
            {/* 集計方法は一旦OFCのみ
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                集計方法
              </Grid>
               <Grid
                container
                className={classes.searchItem}
                justify="center"
                alignItems="center"
              >
                <Grid
                  container
                  lg={3}
                  sm={4}
                  justify="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    className={
                      aggregationType
                        ? classes.checkedButton
                        : classes.unCheckButton
                    }
                    onClick={() => aggregationCheck(true)}
                    size="large"
                  >
                    OFC
                  </Button>
                </Grid>
                <Grid
                  container
                  lg={3}
                  sm={4}
                  justify="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    className={
                      !aggregationType
                        ? classes.checkedButton
                        : classes.unCheckButton
                    }
                    onClick={() => aggregationCheck(false)}
                    size="large"
                  >
                    店舗
                  </Button>
                </Grid>
              </Grid> */}

            {/*L2*/}
            {user.jobCategory !== "dm" ? (
              <>
                <Grid
                  container
                  style={{
                    borderTop: "1px solid #aaa",
                  }}
                >
                  <Grid
                    style={{
                      paddingTop: "15px",
                      paddingLeft: "15px",
                      fontWeight: "bolder",
                    }}
                  >
                    ZOを選択
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.searchItem}
                  justify="center"
                  alignItems="center"
                >
                  <SearchZO
                    useStyles={stylesSearchZO}
                    disabled={
                      getAuthTenpoCheck(user.jobCategory).search < onlyArea
                    }
                    firstView={false}
                    toggleStatus={false}
                    searchMode={0}
                    onClickFunc={(zoCode: string, zoName: string) => {
                      saveStatusBottun(zoCode, zoName, "", "");
                    }}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {/*L3*/}
            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                DOを選択
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.searchItem}
              justify="center"
              alignItems="center"
            >
              <SearchDO
                useStyles={stylesSearchDO}
                disabled={false}
                firstView={false}
                toggleStatus={false}
                searchMode={getAuthTenpoCheck(user.jobCategory).search}
                onClickFunc={(doCode: string, doName: string) => {
                  saveStatusBottun("", "", doCode, doName);
                }}
              />
            </Grid>

            {/*L4*/}
            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                基準日を選択
              </Grid>

              <Grid item xs={1}>
                <Tooltip title="※基準日の週より過去7週分を表示します">
                  <InfoIcon fontSize="small"></InfoIcon>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.searchItem}
              justify="center"
              alignItems="center"
            >
              <DatePicker
                selectedDate={selectedBaseDate}
                dateFunc={(date: DateTime | null) => {
                  handleBaseDate(date);
                }}
                maxDate={DateTime.fromFormat("99991231", "yyyyMMdd")}
                mainColor={"#4dc0b2"}
              />
            </Grid>

            <Grid
              container
              className={classes.searchItem}
              justify="center"
              alignItems="center"
            >
              <Button
                className={classes.excuteButton}
                variant="contained"
                color="primary"
                disabled={!searchButtonFlg}
                onClick={() => doExecute()}
              >
                実行
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <Grid container className={classes.root} justify="center">
        <Grid item xs={12}>
          <Header
            titleName={"店舗衛生点検表提出状況確認"}
            modalCondition={(e) => clickSearch(e)}
          />
          {/* メインコンテンツ↓ここから */}
          <Grid
            container
            alignItems="flex-start"
            justify={"center"}
            className={classes.contentFrame}
          >
            {executeFlg === "1" ? (
              <TSCheckFilingStatusConfirmResult
                aggregationType={aggregation}
                data={JSON.parse(JSON.stringify(stateDataList))}
                columns={JSON.parse(JSON.stringify(stateWeekList))}
                csvColumns={JSON.parse(JSON.stringify(csvColumnsList))}
                defaultSize={stateDataList.length === 0 ? 5 : 15}
              />
            ) : // 実行中の表示
            executeFlg === "2" ? (
              <Grid container className={classes.tableBottom}>
                <Grid container justify="center" alignItems="center">
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <CircularProgress
                      size={"40vh"}
                      style={{
                        color: "#ccc",
                        top: "30vh",
                        position: "absolute",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container justify="center">
                右上の虫眼鏡アイコンから検索条件を指定してください。
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* ダイアログ */}
      <TSCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </>
  );
};

export default FilingStatusConfirm;
