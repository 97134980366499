import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { Tooltip } from "@material-ui/core";
import { selectExclusiveFlg } from "../../../features/marketAreaStatusSlice";
import { selectUser } from "../../../features/userSlice";
import {
  setUserInfo,
  distributionDisplayMarketArea,
} from "../../OpView/MarketArea/MarketAreaControl";
import { userLogging } from "../../../apis/userLog";

export let displayMarketAreaFlag = false;
export function setDisplayMarketAreaFlag(isDisplay: boolean) {
  displayMarketAreaFlag = isDisplay;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      cursor: "pointer",
      backgroundColor: "#fff",
      "&:hover": {
        background: "#d9d9d9",
      },
      height: "40px",
      width: "40px",
      padding: "10px",
    },
  })
);
const ShowAllMarketAreaButton: FC<{}> = () => {
  const classes = useStyles();
  const exclusiveFlg = useSelector(selectExclusiveFlg);
  const text = "全ての範囲を表示にする";
  const user: any = useSelector(selectUser);

  useEffect(() => {
    setUserInfo(user);
  }, []);

  return (
    <>
      <Tooltip
        title={<span style={{ fontSize: "16px" }}>{text}</span>}
        placement="left"
        disableTouchListener
      >
        <IconButton
          className={classes.button + " map-item-shadow"}
          style={exclusiveFlg ? { pointerEvents: "none" } : {}}
          onClick={() => {
            displayMarketAreaFlag = true;
            distributionDisplayMarketArea(true);
            userLogging("OPVIEW", "OPVIEW＃全表示", "");
          }}
        >
          <RadioButtonUnchecked fontSize="default" />
        </IconButton>
      </Tooltip>
    </>
  );
};
export default ShowAllMarketAreaButton;
