import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { MARKETAREA } from "./interface";

const initialMarketAreaStatus: MARKETAREA = {
  tenpoCode: "",
  tenpoName: "",
  tenpoLatLng: [],
  marketAreaValue: "",
  createMarketAreaFlg: false,
  editMarketAreaFlg: false,
  deleteMarketAreaFlg: false,
  completePolygonFlg: false,
  exclusiveFlg: false,
  exclusiveMobakuFlg: false,
};

export const marketAreaStatusSlice = createSlice({
  name: "marketAreaStatus",
  initialState: {
    marketAreaStatus: initialMarketAreaStatus,
  },
  reducers: {
    updateTenpoName: (state, action) => {
      state.marketAreaStatus.tenpoName = action.payload.tenpoName;
    },
    updateTenpoCode: (state, action) => {
      state.marketAreaStatus.tenpoCode = action.payload.tenpoCode;
    },
    updateTenpoLatLng: (state, action) => {
      state.marketAreaStatus.tenpoLatLng = action.payload.tenpoLatLng;
    },
    updateMarketAreaValue: (state, action) => {
      state.marketAreaStatus.marketAreaValue = action.payload.marketAreaValue;
    },
    updateCreateMarketAreaFlg: (state, action) => {
      state.marketAreaStatus.createMarketAreaFlg =
        action.payload.createMarketAreaFlg;
    },
    updateEditMarketAreaFlg: (state, action) => {
      state.marketAreaStatus.editMarketAreaFlg =
        action.payload.editMarketAreaFlg;
    },
    updateDeleteMarketAreaFlg: (state, action) => {
      state.marketAreaStatus.deleteMarketAreaFlg =
        action.payload.deleteMarketAreaFlg;
    },
    updateCompletePolygonFlg: (state, action) => {
      state.marketAreaStatus.completePolygonFlg =
        action.payload.completePolygonFlg;
    },
    updateExclusiveFlg: (state, action) => {
      state.marketAreaStatus.exclusiveFlg = action.payload.exclusiveFlg;
    },
    updateExclusiveMobakuFlg: (state, action) => {
      state.marketAreaStatus.exclusiveMobakuFlg =
        action.payload.exclusiveMobakuFlg;
    },
  },
});

export default marketAreaStatusSlice.reducer;
export const selectTenpoName = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.tenpoName;
export const selectTenpoCode = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.tenpoCode;
export const selectTenpoLatLng = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.tenpoLatLng;
export const selectMarketAreaValue = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.marketAreaValue;
export const selectCreateMarketAreaFlg = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.createMarketAreaFlg;
export const selectEditMarketAreaFlg = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.editMarketAreaFlg;
export const selectDeleteMarketAreaFlg = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.deleteMarketAreaFlg;
export const selectCompletePolygonFlg = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.completePolygonFlg;
export const selectExclusiveFlg = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.exclusiveFlg;
export const selectExclusiveMobakuFlg = (state: RootState) =>
  state.marketAreaStatus.marketAreaStatus.exclusiveMobakuFlg;
export const {
  updateTenpoName,
  updateTenpoCode,
  updateTenpoLatLng,
  updateMarketAreaValue,
  updateCreateMarketAreaFlg,
  updateEditMarketAreaFlg,
  updateDeleteMarketAreaFlg,
  updateCompletePolygonFlg,
  updateExclusiveFlg,
  updateExclusiveMobakuFlg,
} = marketAreaStatusSlice.actions;
