//=============================================================================
//  コード定義
//=============================================================================
export const JOB_CATEGORY_CODE_OFC = "310";
export const JOB_CATEGORY_CODE_DM = "210";
export const JOB_CATEGORY_CODE_ZM = "120";

//=============================================================================
//  権限
//=============================================================================
//  許可職種判定
export const isAcceptJob = (jobCategory: string) => {
  return jobCategory === "ofc" || jobCategory === "dm";
};

//  管理職判定
export const isMagager = (jobCategory: string) => {
  return (
    jobCategory === "admin-group" ||
    jobCategory === "ops" ||
    jobCategory === "gm" ||
    jobCategory === "zm"
  );
};

export const isOpenRegistPage = (user: any) => {
  const sogoShogaiDeptCodes = [
    "957400",
    "957401",
    "957404",
    "957407",
    "957410",
    "957412",
    "957413",
    "957416",
    "957420",
    "957422",
    "957423",
    "957424",
    "957427",
    "957429",
    "957430",
    "957435",
    "957436",
    "957437",
    "957438",
    "957440",
    "957441",
  ];
  const kyoikuSuishinDeptCodes = [
    "954210",
    "954220",
    "954230",
    "954240",
    "954250",
    "954202",
  ];
  if (kyoikuSuishinDeptCodes.includes(user.departmentCodeOA)) {
    return false;
  } else if (
    user.jobCategory === "ofc" ||
    user.jobCategory === "dm" ||
    (user.jobCategory === "zm" &&
      user.jobCategoryCodeOA !== "316" &&
      !sogoShogaiDeptCodes.includes(user.departmentCodeOA))
  ) {
    return true;
  } else {
    return false;
  }
};
export const isOpenStatusConfirmPage = (user: any) => {
  if (
    user.jobCategory === "admin-group" ||
    user.jobCategory === "gm" ||
    user.jobCategory === "ops" ||
    user.jobCategory === "zm" ||
    user.jobCategory === "dm"
  ) {
    return true;
  } else {
    return false;
  }
};
export const isOpenManagerAnalysisPage = (user: any) => {
  if (
    user.jobCategory === "admin-group" ||
    user.jobCategory === "gm" ||
    user.jobCategory === "ops" ||
    user.jobCategory === "zm" ||
    user.jobCategory === "dm"
  ) {
    return true;
  } else {
    return false;
  }
};
/**
 * 店舗衛生点検表表示許可
 * 北海道ZO及び管理者ユーザーのみ許可
 */
export const isOpenTSCheck = (user: any) => {
  return user.zoCode.includes("001") || user.jobCategory === "admin-group";
};
/**
 * 教育推進部ユーザー
 * OPVIEW表示許可
 */
export const isTrainingUser = (user: any) => {
  const trainingUsers = [
    "takei-hiroki@sej.7andi.co.jp",
    "tsuji-noriaki@sej.7andi.co.jp",
    "kosaka-yasukazu@sej.7andi.co.jp",
    "masuko-tatsuki@sej.7andi.co.jp",
    "ishigami-shinya@sej.7andi.co.jp",
    "kaneyori-yoshiyuki@sej.7andi.co.jp",
  ];
  return trainingUsers.includes(user.mailAddress);
};
/**
 * Fトレユーザー判定
 * @param user
 * @returns
 */
export const isFTraineeUser = (user: any) => {
  if (user.jobCategory === "fcTrainee") {
    return true;
  } else {
    return false;
  }
};
/**
 * 直営店社員ユーザー判定
 * @param user
 * @returns
 */
export const isTsUser = (user: any) => {
  // const trainingUsers = ["340", "450", "510"];
  // if (
  //   trainingUsers.includes(user.jobCategoryCodeOA) &&
  //   user.jobCategory !== "fcTrainee"
  // ) {
  //   return true;
  // }
  return false;
};

/**
 * 。
 */
export const isLocal = () => {
  return window.location.origin.indexOf("localhost") !== -1;
};

export const isDownloadPointData = (user: any) => {
  const kikakuDeptCodes = ["955500", "900A50", "900A60"];
  const kyoikuSuishinDeptCodes = ["954200", "954202"];
  const kojinMailAddresses = ["kondou-masaya@sej.7andi.co.jp"];
  if (
    kikakuDeptCodes.includes(user.departmentCodeOA) ||
    kyoikuSuishinDeptCodes.includes(user.departmentCodeOA) ||
    kojinMailAddresses.includes(user.mailAddress)
  ) {
    return true;
  } else {
    return false;
  }
};
