/**
 * conflictStoreLayer.ts
 * 地図上の競合店の表示管理を行います
 */

import { getGoogleMapsObject } from "../GoogleMaps";
import { calcPointsWithinCircle } from "../../../apis/turfAction";
import { getFromGcsZip } from "../../../apis/depressAction";
import {
  unvisibleMapsStyleSettings,
  conflictStoreInfoWindowHtml,
  applyHtmlTemplate,
} from "../MapStylesSettings";
import { updateFireStoreLayer, manageDisplayLayer } from "../MapItems";

export let conflictStoreLayer: google.maps.Data,
  conflictStoreInfoWindow = new Map(),
  conflictStoreData: any;

// 個別項目の型
export interface CONFLICT_STORES_RECORD {
  // 各アプリケーション共通項目
  id: string;
  name: string;
  bland_id: string;
  address: string;
  longitude: string;
  latitude: string;
  business_hours: string;
  handle_category_atm: string;
  handle_category_liquor: string;
  handle_category_parking: string;
  handle_category_tobacco: string;
}

// 個別項目の型のオブジェクトの集まり
export interface INTERFACE_CONFLICT_STORES_DATA {
  [index: string]: CONFLICT_STORES_RECORD;
}

// 競合店の型
export interface INTERFACE_CONFLICT_STORES {
  data: INTERFACE_CONFLICT_STORES_DATA;
  count: number;
}

// 競合店の初期値
const initialConflictStore: INTERFACE_CONFLICT_STORES = {
  data: {},
  count: 0,
};

export const conflictStore = {
  conflictStore: initialConflictStore,
};

/**
 * 初期処理より呼び出されデータを取得する
 */
 export const initializeInnerData = (token: string): void => {
  getFromGcsZip(token, "/master/conflict_stores.zip", setConflictStore);
};

// 全量を保存する
const setConflictStore = (res: any) => {
  const recordData = []; //配列を用意
  const csvRecords = res.split(/\r\n|\r|\n/); //改行で分割
  const data: any = {};
  let count = 0;
  for (let i = 0; i < csvRecords.length; i++) {
    recordData[i] = csvRecords[i].split(",");
    if (recordData[i].length <= 1) {
      continue;
    }
    const record: CONFLICT_STORES_RECORD = {
      id: recordData[i][0],
      longitude: recordData[i][1],
      latitude: recordData[i][2],
      name: recordData[i][3],
      bland_id: recordData[i][5],
      address: recordData[i][4],
      business_hours: recordData[i][6],
      handle_category_atm: recordData[i][7],
      handle_category_liquor: recordData[i][8],
      handle_category_parking: recordData[i][9],
      handle_category_tobacco: recordData[i][10],
    };
    data[recordData[i][0]] = record;
    count++;
  }
  const result: INTERFACE_CONFLICT_STORES = {
    data,
    count,
  };
  conflictStore.conflictStore = result;
};

/**
 * 内部データよりGoogleMapsに表示する
 */
export const innerDataConflictStore = (latLng: google.maps.LatLng, searchLength: number) => {
  const obj: INTERFACE_CONFLICT_STORES_DATA = conflictStore.conflictStore.data;
  const array: any = [];
  // GeoJSONに格納する配列をつくる
  Object.keys(obj).forEach(function (k) {
    array.push(
      turf.point([parseFloat(obj[k].longitude), parseFloat(obj[k].latitude)], {
        id: obj[k].id,
        name: obj[k].name,
        bland_id: obj[k].bland_id,
        address: obj[k].address,
        business_hours: obj[k].business_hours,
        handle_category_atm: obj[k].handle_category_atm,
        handle_category_liquor: obj[k].handle_category_liquor,
        handle_category_parking: obj[k].handle_category_parking,
        handle_category_tobacco: obj[k].handle_category_tobacco,
      })
    );
  });
  return calcPointsWithinCircle(array, latLng.lng(), latLng.lat(), searchLength);
};

// 競合店舗処理
let isApiRequest = false;
export const cbFuncConflictStoresFirst = () => {
  if (isApiRequest) {
    return true;
  }
  isApiRequest = true;
  return false;
};

export const cbFuncConflictStores = (
  geojson: any,
  updateLayer: any,
  conditions: any
) => {
  conflictStoreData = geojson;
  // setExistingSejStore(
  //   replaceNewGeoJson(getExistingSejStore(), geojson, conditions)
  // );
  updateFireStoreLayer(updateLayer);
  isApiRequest = false;
};

// 競合店舗初期処理
export const initConflictStores = (): void => {
  conflictStoreLayer = new google.maps.Data({
    map: getGoogleMapsObject(),
    style: {
      visible: false,
    },
  });
  // クリックイベントの登録
  conflictStoreLayer.addListener("click", function (event: any) {
    if (!conflictStoreInfoWindow.has(event.feature)) {
      conflictStoreInfoWindow.set(
        event.feature,
        new google.maps.InfoWindow({
          // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
          maxWidth: 300,
        })
      );
      // 表示位置
      conflictStoreInfoWindow.get(event.feature).setPosition(event.latLng);
      const isExist = (val: string): string => {
        if (val === "1") {
          return "有";
        }
        return "";
      };
      // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
      conflictStoreInfoWindow
        .get(event.feature)
        .setContent(
          applyHtmlTemplate(conflictStoreInfoWindowHtml, [
            event.feature.getProperty("name"),
            event.feature.getProperty("business_hours"),
            isExist(event.feature.getProperty("handle_category_atm")),
            isExist(event.feature.getProperty("handle_category_liquor")),
            isExist(event.feature.getProperty("handle_category_parking")),
            isExist(event.feature.getProperty("handle_category_tobacco")),
          ])
        );

      conflictStoreInfoWindow
        .get(event.feature)
        .setOptions({ pixelOffset: new google.maps.Size(0, -30), headerDisabled: true });
      // InfowWindowを表示
      conflictStoreInfoWindow.get(event.feature).open(getGoogleMapsObject());
      conflictStoreInfoWindow.get(event.feature).addListener("domready", () => {
        const closeElement = document.getElementById(
          event.feature.getProperty("name") + "-close"
        );
        if (closeElement) {
          // 作成ボタンを表示
          closeElement.style.display = "block";
          closeElement.addEventListener("click", function (e) {
            conflictStoreInfoWindow
              .get(event.feature)
              .close();
            conflictStoreInfoWindow
              .delete(event.feature);
          });
        }
        // infowindowのZindex変更イベント
        // $(
        //   "#" +
        //     event.feature.getProperty("attribution").tenpo_code +
        //     "-infowindow"
        // ).on("click", () => {
        //   setZIndex(existingSejStoreInfoWindow.get(event.feature));
        // });
        // $("#testButtonInfoWindow")
        //   .off()
        //   .on("click", () => {
        //     // Todo暫定ルート検索
        //     getRoute();
        //   });
      });
      conflictStoreInfoWindow
        .get(event.feature)
        .addListener("closeclick", function () {
          conflictStoreInfoWindow.delete(event.feature);
        });
    } else {
      conflictStoreInfoWindow.get(event.feature).close();
      conflictStoreInfoWindow.delete(event.feature);
    }
  });
  // スタイル指定（最初は非表示）
  conflictStoreLayer.setStyle(unvisibleMapsStyleSettings);
  manageDisplayLayer(conflictStoreLayer, false);
};
