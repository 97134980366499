/**
 * Modal用Google Maps関係のコントローラークラスです。
 * @namespace ModalGoogleMap
 * @class ModalGoogleMaps.ts
 * @constructor
 *
 * React版のGoogle Maps ライブラリではGeoJSONやDrawingManerの利用が困難なため、
 * 素のTypesctriptでコーディングする
 * Modal用
 */
import {
  initMobaku,
  initGoogleMapsLayers,
  callMapItemMove,
} from "./SelectMobakuMapItems";
import { getFirestoreData } from "../../../apis/firestoreAction";

import { selectMarketArea } from "../MarketArea/PolygonDrawing";
import {
  cvrMobakuItemRef,
  mobakuFirstItemRef,
  mobakuSecondItemRef,
} from "./MobakuMapsSelectedButton";

let googleModalMapObject: google.maps.Map;
let mtrafficLayer: any, mtransitLayer: any;
let fittingBounds = false;

export const getModalGoogleMapsObject = (): google.maps.Map => {
  return googleModalMapObject;
};

export async function createModalGoogleMap(
  storeOrder: string,
  dispatch: any,
  existMarketArea: boolean,
  cvrTenpoItem: string,
  cvrMobakuItem: string[],
  // firstMobakuItem: string[],
  // secondMobakuItem: string[],
  graphType: string
) {
  // GoogleMapsAPI用の値
  const googleMapsSettingsValue = {
    INITIAL_CENTER:
      localStorage.getItem("lastCenterPoint") === null
        ? [139.7321702, 35.6856134]
        : [
            JSON.parse(localStorage.getItem("lastCenterPoint") ?? "139.7321702")
              .lon,
            JSON.parse(localStorage.getItem("lastCenterPoint") ?? "35.6856134")
              .lat,
          ],
    INITIAL_ZOOMLV:
      localStorage.getItem("lastZoomLevel") === null
        ? 17
        : parseInt(localStorage.getItem("lastZoomLevel") ?? "17"),
    DEFALUT_MAP_TYPE: "roadmap",
    ZOOM_MIN: 3,
    ZOOM_MAX: 21,

    CENTER_MOVED_ZOOM: 15, // 住所検索などにより中心座標が移動した際のズームレベル変更の境界レベル

    ZOOM_MODE_CHANGE: 2, //拡大、縮小モード時に一度に変更するズームレベルの値
  };

  let googleMapsOptionsSettings = {
    center: new google.maps.LatLng(
      googleMapsSettingsValue.INITIAL_CENTER[1],
      googleMapsSettingsValue.INITIAL_CENTER[0]
    ),
    mapTypeId: googleMapsSettingsValue.DEFALUT_MAP_TYPE,
    zoom: googleMapsSettingsValue.INITIAL_ZOOMLV,
    mapId: "e42bfbd48d30d1a0", // Google Mapsのスタイル
    disableDefaultUI: false,
    keyboardShortcuts: false,
    draggable: true,
    disableDoubleClickZoom: false,
    scrollwheel: true,
    streetViewControl: false,
    maxZoom: googleMapsSettingsValue.ZOOM_MAX,
    minZoom: googleMapsSettingsValue.ZOOM_MIN,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM, //左下配置
    },
    mapTypeControl: false,
    zoomControl: true, // ズームボタンの表示
    scaleControl: true, // 縮尺の表示
    clickableIcons: false, // 地図上に存在するPOIアイコンをクリックできるか否かを指定する
    scaleControlOptions: {
      position: google.maps.ControlPosition.BOTTOM_RIGHT, //右下配置
      style: google.maps.ScaleControlStyle.DEFAULT, //標準のスケール
    },
    fullscreenControl: false,
    gestureHandling: "greedy",
  };

  googleModalMapObject = new google.maps.Map(
    document.getElementById("momap") as HTMLElement,
    googleMapsOptionsSettings
  );
  mtrafficLayer = new google.maps.TrafficLayer();
  mtransitLayer = new google.maps.TransitLayer();

  // 地図上ドラッグイベント管理
  google.maps.event.addListener(googleModalMapObject, "dragend", () => {
    // firebaseAnalyticsSelectContent("地図操作");
    callMapItemMove(graphType);
  });
  // 中心点の移動
  google.maps.event.addListener(googleModalMapObject, "center_changed", () => {
    localStorage.setItem(
      "lastCenterPoint",
      JSON.stringify({
        lon: getGMapsCenterPoint().lng(),
        lat: getGMapsCenterPoint().lat(),
      })
    );
  });
  // ZOOMレベル変更
  google.maps.event.addListener(googleModalMapObject, "zoom_changed", () => {
    // localStorage.setItem("lastZoomLevel", "" + googleModalMapObject.getZoom());
  });
  // //右クリックの処理
  // google.maps.event.addListener(googleModalMapObject, "rightclick", () => {
  //   // controlContextMenu(true, event);
  // });
  // // ロングタップの処理開始(開始)
  // google.maps.event.addListener(googleModalMapObject, "mousedown", () => {
  //   // startLongTap(event);
  // });
  // // ロングタップの処理開始(終了)
  // google.maps.event.addListener(googleModalMapObject, "mouseup", () => {
  //   // endLongTap(event);
  // });
  //createMobakuMap(googleModalMapObject);
  initMobaku(storeOrder, dispatch, graphType,false);
  initGoogleMapsLayers(storeOrder, dispatch, graphType);
  if (
    (existMarketArea && graphType === "cvr" && cvrMobakuItem[0] === "") ||
    (graphType === "cvr" && cvrMobakuItem[0] === "market-area")
  ) {
    selectMarketArea(cvrTenpoItem, dispatch);
  }
  await setBounds(storeOrder, graphType);
}

// // 道路地図のGoogleMapsを表示します
export function setRoadGoogleMap() {
  //   // firebaseAnalyticsSelectContent("道路地図");
  googleModalMapObject.setMapTypeId("roadmap");
}
// 航空写真地図のGoogleMapsを表示します
// export function setSatelliteGoogleMap() {
//   // firebaseAnalyticsSelectContent("航空写真");
//   googleModalMapObject.setMapTypeId("satellite");
// }
// // 地形情報地図のGoogleMapsを表示します
export function setTerrainGoogleMap() {
  googleModalMapObject.setMapTypeId("terrain");
}
// // 衛星写真ハイブリッド地図のGoogleMapsを表示します
export function setHybridGoogleMap() {
  googleModalMapObject.setMapTypeId("hybrid");
}
// // 交通状況のGoogleMapsを表示管理を行います(false:表示しない,true:表示)
export const setTrafficLayerGoogleMap = (isDisplay: boolean) => {
  isDisplay
    ? mtrafficLayer.setMap(googleModalMapObject)
    : mtrafficLayer.setMap(null);
};
// // 鉄道のGoogleMapsを表示管理を行います(false:表示しない,true:表示)
export const setTransitLayerGoogleMap = (isDisplay: boolean) => {
  isDisplay
    ? mtransitLayer.setMap(googleModalMapObject)
    : mtransitLayer.setMap(null);
};
// 地図上の中心座標を取得する
export const getGMapsCenterPoint: any = () => {
  return googleModalMapObject.getCenter();
};
// 指定したGoogleMapsの中心座標に移動する
export const moveMobakuGMapsPoint = (
  lat: number,
  lng: number,
  graphType: string,
  zoomLevel: number = 18,
  isCallGeoFireStore: boolean = true
) => {
  const latlng = new google.maps.LatLng(lat, lng);
  googleModalMapObject.panTo(latlng);
  setGoogleMapsZoom(zoomLevel);
  if (isCallGeoFireStore) {
    callMapItemMove(graphType);
  }
};
// 指定したGoogleMapsの範囲内に移動する
export const moveGMapsBounds = (latLngBounds: any) => {
  googleModalMapObject.panToBounds(latLngBounds);
};
// ズームレベルを設定する
export const setGoogleMapsZoom = (zoomLevel: number) => {
  getModalGoogleMapsObject().setZoom(zoomLevel);
};

// 選択中のメッシュが全て表示されるよう調整する
const setBounds = async (storeOrder: string, graphType: string) => {
  let selectedMobaku: string[] = [];
  if (storeOrder === "first") {
    if (mobakuFirstItemRef.length === 0) return;
    selectedMobaku = mobakuFirstItemRef;
  } else {
    if (graphType === "cvr") {
      if (
        cvrMobakuItemRef.length === 0 ||
        cvrMobakuItemRef[0] === "market-area"
      )
        return;
      selectedMobaku = cvrMobakuItemRef;
    } else if (graphType === "mobaku") {
      if (mobakuSecondItemRef.length === 0) return;
      selectedMobaku = mobakuSecondItemRef;
    }
  }
  let position: any[] = [];
  let bounds = new google.maps.LatLngBounds();
  selectedMobaku.forEach(function (meshCode: string, index: number) {
    // if (meshCode === "") return;
    getFirestoreData("mesh_500m", "attribution.key_code", meshCode)
      .then((response: any) => {
        if (response.hasOwnProperty("geography")) {
          const latLon = response.geography.geopoint;
          position.push(new google.maps.LatLng(latLon._lat, latLon._long));
        }
        position.forEach((value) => {
          bounds.extend(value);
        });
      })
      .then(async () => {
        googleModalMapObject.fitBounds(bounds);
        let zoomLevel = googleModalMapObject.getZoom();
        if (zoomLevel === undefined) {
          return;
        }
        if (zoomLevel > 16) {
          googleModalMapObject.setZoom(16);
        }
      })
      .then(() => {
        callMapItemMove(graphType);
      });
  });
};

// ズームレベルを取得する
export const getGoogleMapsZoom = (): number => {
  return getModalGoogleMapsObject().getZoom() ?? 22;
};
