import React, { FC, useEffect } from "react";

const ToastMessage: FC<{}> = () => {
  useEffect(() => {}, []);

  return (
    <div className="toast hideToast" id="toast">
      <p id="toastMessage"></p>
    </div>
  );
};
export default ToastMessage;
