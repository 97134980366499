import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Header, {
  setFirstFlg,
  setFixedFlg,
  setInitTenpoCode,
} from "../Header/Header";
import {
  isOpenRegistPage,
  isOpenStatusConfirmPage,
  isOpenManagerAnalysisPage,
  isOpenTSCheck,
  isLocal,
  isDownloadPointData,
} from "../../apis/privilege";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  Link,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import {
  setLastTenpoCheckId,
  setTenpoCheckId,
} from "../TenpoCheck/Register/TenpoCheck";
import { setLastTSCheckId, setTSCheckId } from "../TSCheck/Register/TSCheck";
import { selectUser } from "../../../src/features/userSlice";
import {
  setLastOneVisionId,
  setOneVisionId,
} from "../OneVision/Register/OneVision";
import { userLogging } from "../../apis/userLog";
import { isFirebaseAuth } from "../../firebase";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
    },
    rootTable: {
      marginTop: "5vh",
    },
    homeContents: {
      height: "90%",
    },
    homeAccordion: {
      borderRadius: "10px",
      width: "230px",
    },
    homeMainMenu: {
      fontWeight: "bold",
      fontSize: "20px",
    },
    homeMenuIcon: {
      height: "20px",
      paddingRight: "10px",
    },
    gap: {
      height: "15px",
    },
    sectionItem: {
      marginLeft: "20px",
      underline: "hover",
      variant: "caption",
      fontSize: "20px",
      cursor: "pointer",
      color: "#404040",
    },
    accordionHeader: {
      backgroundColor: "#00CC99",
      color: "white",
      borderRadius: "10px",
    },
    homeButton: {
      borderRadius: "10px",
      width: "230px",
      height: "54px",
      backgroundColor: "#00CC99",
      "&:hover": {
        background: "#00CC99",
      },
    },
    homeButtonFont: {
      color: "white",
    },
  })
);

export function clickNewTenpo() {
  setFixedFlg(false);
  setTenpoCheckId("");
  setLastTenpoCheckId("");
  setFirstFlg(true);
}

export function clickNewOneVision() {
  setFixedFlg(false);
  setOneVisionId("");
  setLastOneVisionId("");
  setFirstFlg(true);
}

export function clickNewTs() {
  setFixedFlg(false);
  setTSCheckId("");
  setLastTSCheckId("");
  setFirstFlg(true);
}
export const URL_Q_A = "https://7andihd-my.sharepoint.com/personal/tanaka-kenji01_sej_7andi_co_jp/_layouts/15/Doc.aspx?sourcedoc={962445b0-d12a-4ab2-81bd-e8f7cbcf8d9a}&action=view&wd=target%28iPad%E4%B8%8D%E5%85%B7%E5%90%88%E5%9B%B0%E3%82%8A%E4%BA%8B%E5%AF%BE%E7%AD%96.one%7C5e589c99-c8c7-4f8f-b306-9af8c90efdda%2F%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E3%83%84%E3%83%BC%E3%83%AB%E3%80%80%E5%BA%97%E8%88%97%E7%A2%BA%E8%AA%8D%E8%A1%A8QA%7Cd4894a8b-0a6b-42de-8d12-3537ca6783c8%2F%29&wdorigin=NavigationUrl";
export const URL_ENQUIRY = "https://apps.powerapps.com/play/2faac3af-e8ae-453b-995e-14370f539b3f?tenantId=885a11b2-b987-4122-a6f0-0a9ac75907b1";

const Home: React.FC = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const isTenpoCheck: boolean =
    process.env.REACT_APP_MODE === "store-check" ||
    process.env.REACT_APP_MODE === "production-tenpo-check" ||
    process.env.REACT_APP_MODE === "production-stg-tenpo-check";
  const user: any = useSelector(selectUser);
  
  let titleName = "店舗確認表・OneVision・店舗衛生点検";

  //TODO 店舗衛生点検 が全国リリースされるまでの一時的な処理
  if(process.env.REACT_APP_MODE !=="develop"){
    titleName = "店舗確認表・OneVision"
  }

  const iconDir = `${window.location.origin}/image/icons/`;
  // setInitTenpoCode("");
  if (!isFirebaseAuth()) {
    return <></>;
  }
  return (
    <Grid container className={classes.root} alignItems="flex-start">
      <Grid container>
        <Header titleName={titleName} />
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        justify="center"
        className={classes.homeContents}
      >
        <Grid className={classes.rootTable}>
          <Grid>
            <Accordion className={classes.homeAccordion}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionHeader}
              >
                <Grid container justify="center">
                  <Typography className={classes.homeMainMenu}>
                    <img
                      src={iconDir + "tenpo_check_icon.png"}
                      alt=""
                      className={classes.homeMenuIcon}
                    />
                    店舗確認表
                  </Typography>
                </Grid>
              </AccordionSummary>
              {isOpenRegistPage(user) ? (
                <AccordionDetails>
                  <Typography>
                    <Link
                      className={classes.sectionItem}
                      onClick={() => {
                        clickNewTenpo();
                        history.push("tenpo-check");
                        userLogging("店舗確認表", "Homeメニュー", "新規登録");
                      }}
                    >
                      <img
                        src={iconDir + "new_icon.png"}
                        alt=""
                        className={classes.homeMenuIcon}
                      />
                      新規登録
                    </Link>
                  </Typography>
                </AccordionDetails>
              ) : (
                ""
              )}
              <AccordionDetails>
                <Typography>
                  <Link
                    className={classes.sectionItem}
                    onClick={() => {
                      history.push("tenpo-check-search");
                      userLogging("店舗確認表", "Homeメニュー", "データ検索");
                    }}
                  >
                    <img
                      src={iconDir + "search_icon.png"}
                      alt=""
                      className={classes.homeMenuIcon}
                    />
                    データ検索
                  </Link>
                </Typography>
              </AccordionDetails>
              {isOpenStatusConfirmPage(user) ? (
                <>
                  <AccordionDetails>
                    <Typography>
                      <Link
                        className={classes.sectionItem}
                        onClick={() => {
                          history.push("tenpo-check-filing-status-confirm");
                          userLogging(
                            "店舗確認表",
                            "Homeメニュー",
                            "提出状況確認"
                          );
                        }}
                      >
                        <img
                          src={iconDir + "confirm_icon.png"}
                          alt=""
                          className={classes.homeMenuIcon}
                        />
                        提出状況確認
                      </Link>
                    </Typography>
                  </AccordionDetails>
                  {isOpenManagerAnalysisPage(user) ? (
                    <AccordionDetails>
                      <Typography>
                        <Link
                          className={classes.sectionItem}
                          onClick={() => {
                            history.push("tenpo-check-manager-analysis");
                            userLogging(
                              "店舗確認表",
                              "Homeメニュー",
                              "管理者分析"
                            );
                          }}
                        >
                          <img
                            src={iconDir + "analysis_icon.png"}
                            alt=""
                            className={classes.homeMenuIcon}
                          />
                          管理者分析
                        </Link>
                      </Typography>
                    </AccordionDetails>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Accordion>
          </Grid>
          <Grid className={classes.gap}></Grid>
          <Grid>
            <Accordion className={classes.homeAccordion}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionHeader}
              >
                <Grid container justify="center">
                  <Typography className={classes.homeMainMenu}>
                    <img
                      src={iconDir + "one_vision_icon.png"}
                      alt=""
                      className={classes.homeMenuIcon}
                    />
                    OneVision
                  </Typography>
                </Grid>
              </AccordionSummary>
              {isOpenRegistPage(user) ? (
                <AccordionDetails>
                  <Typography>
                    <Link
                      className={classes.sectionItem}
                      onClick={() => {
                        clickNewOneVision();
                        history.push("one-vision");
                        userLogging("OneVision", "Homeメニュー", "新規登録");
                      }}
                    >
                      <img
                        src={iconDir + "new_icon.png"}
                        alt=""
                        className={classes.homeMenuIcon}
                      />
                      新規登録
                    </Link>
                  </Typography>
                </AccordionDetails>
              ) : (
                ""
              )}
              <AccordionDetails>
                <Typography>
                  <Link
                    className={classes.sectionItem}
                    onClick={() => {
                      history.push("one-vision-search");
                      userLogging("OneVision", "Homeメニュー", "データ検索");
                    }}
                  >
                    <img
                      src={iconDir + "search_icon.png"}
                      alt=""
                      className={classes.homeMenuIcon}
                    />
                    データ検索
                  </Link>
                </Typography>
              </AccordionDetails>
              {isOpenStatusConfirmPage(user) ? (
                <>
                  <AccordionDetails>
                    <Typography>
                      <Link
                        className={classes.sectionItem}
                        onClick={() => {
                          history.push("one-vision-filing-status-confirm");
                          userLogging(
                            "OneVision",
                            "Homeメニュー",
                            "提出状況確認"
                          );
                        }}
                      >
                        <img
                          src={iconDir + "confirm_icon.png"}
                          alt=""
                          className={classes.homeMenuIcon}
                        />
                        提出状況確認
                      </Link>
                    </Typography>
                  </AccordionDetails>
                  {(process.env.REACT_APP_MODE === "develop" || 
                  isDownloadPointData(user))
                  && 
                  isOpenManagerAnalysisPage(user) ? (
                    <AccordionDetails>
                      <Typography>
                        <Link
                          className={classes.sectionItem}
                          onClick={() => {
                            history.push("one-vision-manager-analysis");
                            userLogging(
                              "OneVision",
                              "Homeメニュー",
                              "管理者分析"
                            );
                          }}
                        >
                          <img
                            src={iconDir + "analysis_icon.png"}
                            alt=""
                            className={classes.homeMenuIcon}
                          />
                          管理者分析
                        </Link>
                      </Typography>
                    </AccordionDetails>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Accordion>
          </Grid>
          {process.env.REACT_APP_MODE === "develop" || isOpenTSCheck(user) ? (
            //TODO 北海道ZO及びadmin-groupのみ開放
            <>
              <Grid className={classes.gap}></Grid>
              <Grid>
                <Accordion className={classes.homeAccordion}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.accordionHeader}
                  >
                    <Grid container justify="center">
                      <Typography className={classes.homeMainMenu}>
                        <img
                          src={iconDir + "ts_check_icon.png"}
                          alt=""
                          className={classes.homeMenuIcon}
                        />
                        店舗衛生点検表
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  {isOpenRegistPage(user) ? (
                    <AccordionDetails>
                      <Typography>
                        <Link
                          className={classes.sectionItem}
                          onClick={() => {
                            clickNewTs();
                            history.push("ts-check");
                            userLogging(
                              "店舗衛生点検表",
                              "Homeメニュー",
                              "新規登録"
                            );
                          }}
                        >
                          <img
                            src={iconDir + "new_icon.png"}
                            alt=""
                            className={classes.homeMenuIcon}
                          />
                          新規登録
                        </Link>
                      </Typography>
                    </AccordionDetails>
                  ) : (
                    ""
                  )}
                  <AccordionDetails>
                    <Typography>
                      <Link
                        className={classes.sectionItem}
                        onClick={() => {
                          history.push("ts-check-search");
                          userLogging(
                            "店舗衛生点検表",
                            "Homeメニュー",
                            "データ検索"
                          );
                        }}
                      >
                        <img
                          src={iconDir + "search_icon.png"}
                          alt=""
                          className={classes.homeMenuIcon}
                        />
                        データ検索
                      </Link>
                    </Typography>
                  </AccordionDetails>
                  {isOpenStatusConfirmPage(user) ? (
                    <>
                      <AccordionDetails>
                        <Typography>
                          <Link
                            className={classes.sectionItem}
                            onClick={() => {
                              history.push("ts-check-filing-status-confirm");
                              userLogging(
                                "店舗衛生点検表",
                                "Homeメニュー",
                                "提出状況確認"
                              );
                            }}
                          >
                            <img
                              src={iconDir + "confirm_icon.png"}
                              alt=""
                              className={classes.homeMenuIcon}
                            />
                            提出状況確認
                          </Link>
                        </Typography>
                      </AccordionDetails>
                      {isOpenManagerAnalysisPage(user) ? (
                        <AccordionDetails>
                          <Typography>
                            <Link
                              className={classes.sectionItem}
                              onClick={() => {
                                history.push("ts-check-manager-analysis");
                                userLogging(
                                  "店舗衛生点検表",
                                  "Homeメニュー",
                                  "管理者分析"
                                );
                              }}
                            >
                              <img
                                src={iconDir + "analysis_icon.png"}
                                alt=""
                                className={classes.homeMenuIcon}
                              />
                              管理者分析
                            </Link>
                          </Typography>
                        </AccordionDetails>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Accordion>
              </Grid>
            </>
          ) : (
            ""
          )}
          {!isTenpoCheck ? (
            <>
              <Grid className={classes.gap}></Grid>
              <Grid>
                <Button
                  className={classes.homeButton}
                  onClick={() => {
                    history.push("maps");
                    userLogging("OPVIEW", "Homeメニュー", "OPVIEWに遷移");
                  }}
                >
                  <Typography className={classes.homeMainMenu}>
                    <Link className={classes.homeButtonFont}>
                      <img
                        src={iconDir + "op_view_icon.png"}
                        alt=""
                        className={classes.homeMenuIcon}
                      />
                      OPVIEW
                    </Link>
                  </Typography>
                </Button>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid className={classes.gap}></Grid>
          <Grid container justify="center">
            <Button
              variant="contained"
              onClick={() => {
                window.open(
                  URL_Q_A,
                  "_blank"
                );
                userLogging("その他", "Homeメニュー", "Q＆A");
              }}
              className={classes.homeButton}
            >
              <Typography className={classes.homeButtonFont}
              style ={{fontSize:"20px", fontWeight: "bold",}}>Q＆A</Typography>
            </Button>
          </Grid>

          <Grid className={classes.gap}></Grid>
          <Grid container justify="center">
            <Button
              variant="contained"
              onClick={() => {
                window.open(
                  URL_ENQUIRY,
                  "_blank"
                );
                userLogging("その他", "Homeメニュー", "マニュアル・問い合わせ");
              }}
              className={classes.homeButton}
              style={{ width: "230px", fontSize: "20px" }}
            >
              <Typography className={classes.homeButtonFont} style ={{fontSize:"17px",fontWeight: "bold",}}>
                マニュアル・問い合わせ
              </Typography>
            </Button>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
